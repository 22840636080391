import axios from 'axios';

const API_URL = process.env.REACT_APP_BASE_URL;

const postQuote = (payload: any) => {
    return axios.post(`${API_URL}quote`, payload);
}

const postEmptyQuote = (payload: any) => {
    return axios.post(`${API_URL}quote/empty`, payload);
}

const rejectquote = (payload: any) => {
    return axios.post(`${API_URL}quote/reject`, payload);
}

const approveTaxInfo = (payload: any) => {
    return axios.post(`${API_URL}quote/approve-tax-info`, payload);
}

const getAllQuotes = () => {
    return axios.get(`${API_URL}quote`);
}

const getPaginationQuotes = (page: any) => {
    return axios.get(`${API_URL}quote/pagination/${page}`);
}

const getCredits = (id: string) => {
    return axios.get(`${API_URL}quote/${id}`);
}

const finishFlow = (clientRef: string) => {
    return axios.post(`${API_URL}quote/finish-flow/${clientRef}`);
}

const udpateStatus = (payload: any) => {
    return axios.post(`${API_URL}quote/update-status`, payload);
}

export const APIQ = {
    postQuote,
    postEmptyQuote,
    getCredits,
    getAllQuotes,
    getPaginationQuotes,
    rejectquote,
    approveTaxInfo,
    finishFlow,
    udpateStatus
}