import React, { useEffect, useState, FC, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Card, Modal, ModalBody } from 'react-bootstrap';
import { Box, Grid, Button as ButtonMui, Typography, Container } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { postDocuments, getDocuments } from '../../../flux/actions/documents-actions';
import { documentsDescription } from '../../../constants/documentsDescription';
import Loader from '../../../components/ScreenLoader/ScreenLoader';

interface Props {
    infoRequired: [];
    onSaveForm: () => void | any;
    finishFlow: () => any;
    title: string;
    description: string;
}

const DocumentBuilder: FC<Props> = (p) => {
    const dispatch = useDispatch();

    //local const
    const { client } = useSelector((state: any) => state.clientReducer);
    const flowDocuments = useSelector((state: any) => state.DocumentsReducer.flowDocuments);
    const [payloadArray, setPayloadArray] = useState<any[]>([]);
    const [errors, setErrors] = useState(false);
    const [filesRequestType, setFilesRequestType] = useState(['']);
    const [showLoader, setShowLoader] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showModal, setShowModal] = useState(true);
    const [indexButon, setIndexButton] = useState<number>(0);

    //dropzone config
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        maxFiles: 1,
        noDrag: true,
        accept: filesRequestType
    });

    // get the files
    useEffect(() => {
        let arrayLoader: any = [];

        p.infoRequired.forEach((info: any) => {
            arrayLoader.push({ name: info.name, file: '', fileInfo: {}, type: info.type, model: info.model, register: info.register });
        });

        setPayloadArray(arrayLoader);
        dispatch(getDocuments(client.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //get the documents from the flow
    useEffect(() => {
        if (payloadArray.length !== 0 && flowDocuments.length !== 0) {
            let arrayLoader = [...payloadArray];
            let indexAux = 0;

            // eslint-disable-next-line array-callback-return
            payloadArray.filter((info: any, index: number) => {
                indexAux = index;
                // eslint-disable-next-line array-callback-return
                flowDocuments.filter((file: any, index: number) => {
                    if (info.type === file.type) {
                        arrayLoader[indexAux] = { ...arrayLoader[indexAux], file: file.file, fileInfo: { path: file.name } };
                    }
                });
            });

            setPayloadArray(arrayLoader);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flowDocuments]);

    //function to add the file in the array if the documents are accepted, also save the anme of the document and the information about it
    useEffect(() => {
        setErrors(false);
        const auxFile: any[] = [...payloadArray];

        // eslint-disable-next-line array-callback-return
        acceptedFiles.map((file) => {
            const reader = new FileReader();

            reader.onload = () => {
                if (auxFile.findIndex((file) => file.file === '') !== -1) {
                    auxFile[indexButon] = { ...auxFile[indexButon], file: reader.result, fileInfo: file };
                }
                setPayloadArray(auxFile);
            };

            reader.readAsDataURL(file);

            reader.onerror = (error) => {
                console.log('algo salio mal', error);
            };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acceptedFiles]);

    //Main function to upload the documents if we dont find any error
    const postFiles = () => {
        let auxError = false;
        const auxArray = [...payloadArray];

        // eslint-disable-next-line array-callback-return
        auxArray.filter((file: any) => {
            if (file.file === '') {
                auxError = true;
            }
        });

        setErrors(auxError);
        !auxError && p.finishFlow();
        !auxError && dispatch(postDocuments({ documentPayload: auxArray, clientRef: client.id }));
        !auxError && setShowLoader(true);
        !auxError && setTimeout(() => p.onSaveForm(), 2000);
    };

    // function to Upload all about the document in the right position
    const handleUpload = (e: any) => {
        const index = Number(e?.target?.name);
        setIndexButton(index);
    };

    // function to delete all about the document in the right position
    const handleDelete = (e: any) => {
        const auxFiles = [...payloadArray];
        const index = Number(e.target.name);
        auxFiles[index] = { ...auxFiles[index], file: '', fileInfo: {} };
        setPayloadArray(auxFiles);
    };

    // Render description of the documents in the screen
    const _renderDescription = (type: string) => {
        switch (type) {
            case 'bankReceipt1':
                return documentsDescription.bankReceipt1;
            case 'bankReceipt2':
                return documentsDescription.bankReceipt2;
            case 'bankReceipt3':
                return documentsDescription.bankReceipt3;
            case 'idFront':
                return documentsDescription.idFront;
            case 'idBack':
                return documentsDescription.idBack;
            case 'addressProof':
                return documentsDescription.addressProof;
            case 'bussinessAddressProof':
                return documentsDescription.bussinessAddressProof;
            case 'constitutiveAct':
                return documentsDescription.constitutiveAct;
            case 'actGrantingPowers':
                return documentsDescription.actGrantingPowers;
            case 'taxpayerIDCard':
                return documentsDescription.taxpayerIDCard;
            case 'certificateESignature':
                return documentsDescription.certificateESignature;
        }
    };

    // Render the type of the documents in the screen
    const _renderFileType = (type: string) => {
        switch (type) {
            case 'idFront':
                return '(Imagen .png, .jpeg o .jpg no mayor a 50 MB)';
            case 'idBack':
                return '(Imagen .png, .jpeg o .jpg no mayor a 50 MB)';
            default:
                return '(Archivo .pdf no mayor a 50 MB)';
        }
    };

    // Render the documents in the screen
    const _DocumentInputRender = (doc: any, index: number) => {
        return (
            <Box sx={{ margin: 'auto', width: '100%' }}>
                <Grid container spacing={4} {...getRootProps()} sx={{ mt: 0, minHeight: 100 }}>
                    <Grid item md={8} container direction="row" justifyContent="center" alignItems="center">
                        <input {...getInputProps()} />
                        <Grid item xs={12} container direction="row" justifyContent="flex-start" alignItems="center">
                            {doc.file === '' && (
                                <Box sx={{ display: 'flex', m: 0 }}>
                                    <i style={{ fontSize: 20, color: '#495057' }} className={'feather icon-upload-cloud'} />
                                    <Typography variant="body1" align="center" sx={{ ml: 2 }}>
                                        <b>{_renderDescription(doc.type)}</b>
                                    </Typography>
                                </Box>
                            )}
                        </Grid>
                        {doc.file !== '' ? (
                            <Grid item xs={12} container direction="row" justifyContent="flex-start" alignItems="center">
                                <Box sx={{ display: 'flex', m: 0 }}>
                                    <i style={{ fontSize: 20, color: '#30C39E' }} className={'feather icon-check-circle'} />
                                    <Typography variant="body1" align="center" sx={{ ml: 2 }}>
                                        {doc.fileInfo.path || doc.file}
                                    </Typography>
                                </Box>
                            </Grid>
                        ) : (
                            <>
                                <Grid item xs={12} container direction="row" justifyContent="flex-start" alignItems="center">
                                    <Typography variant="body1" align="center" sx={{ ml: 5 }}>
                                        {_renderFileType(doc.type)}
                                    </Typography>
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <Grid item md={4} container direction="row" justifyContent="center" alignItems="center" sx={{ m: 0, pt: 0 }}>
                        {doc.file !== '' ? (
                            <div className="d-flex"></div>
                        ) : (
                            <>
                                <ButtonMui
                                    variant="contained"
                                    size="large"
                                    className="mt-3 uploadBtn"
                                    name={index.toString()}
                                    onClickCapture={(e) => {
                                        if (doc?.type === 'idBack' || doc?.type === 'idFront') {
                                            handleUpload(e);
                                            setFilesRequestType(['.png', '.jpeg', '.jpg']);
                                        } else {
                                            handleUpload(e);
                                            setFilesRequestType(['application/pdf', '.pdf']);
                                        }
                                    }}
                                >
                                    Agregar Archivo
                                </ButtonMui>
                            </>
                        )}
                        {doc.file !== '' && (
                            <ButtonMui
                                variant="contained"
                                size="large"
                                color="error"
                                className="deleteBtn"
                                name={index.toString()}
                                onClick={handleDelete}
                            >
                                Remplazar archivo
                            </ButtonMui>
                        )}
                    </Grid>
                </Grid>
            </Box>
        );
    };

    //Next Button Styles
    const nextButton = useRef<HTMLButtonElement>(null);

    const changeStylesNextBtn = (button: HTMLButtonElement) => {
        if (client.onBoardingStep >= 1) {
            button.className = 'btn-border-radius onBoarding-button onBoarding-button-next nextBtn';
        }

        if (client.onBoardingStep === 0) {
            button.className = 'btn-border-radius onBoarding-button onBoarding-button-next';
        }
    };

    if (null !== nextButton.current) {
        changeStylesNextBtn(nextButton.current);
    }

    return (
        <Col className="d-flex flex-column justify-content-center onBoarding-form onBoarding-documents">
            <Row>
                <Col sm={12} md={12} className="mt-3">
                    <Card.Title>{p.title}</Card.Title>
                    <div className="divider-title"></div>
                    <Card.Subtitle>{p.description}</Card.Subtitle>
                    <Container maxWidth="md">
                        {payloadArray.length !== 0 &&
                            payloadArray.map((info: any, index: number) => {
                                return _DocumentInputRender(info, index);
                            })}
                    </Container>
                    {errors && <span className="formAlert">Favor de cargar todos los archivos requeridos</span>}
                    <Row className="onBoarding-buttons-content">
                        {showLoader ? (
                            <Modal show={showModal}>
                                <ModalBody>
                                    <p className="onBoarding-loader-text">Subiendo Documentos</p>
                                    <Loader isOpen={true} />
                                </ModalBody>
                            </Modal>
                        ) : (
                            <Col className="d-flex onBoarding-buttons justify-content-center">
                                <Button
                                    className="btn-border-radius onBoarding-button onBoarding-button-next"
                                    variant="third"
                                    ref={nextButton}
                                    onClick={postFiles}
                                >
                                    <dt>
                                        Continuar <i className={'feather icon-arrow-right'} />
                                    </dt>
                                </Button>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
        </Col>
    );
};
export default DocumentBuilder;
