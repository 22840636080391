import { Reducer } from 'redux';
import { ApprovalCriteriaAction } from '../types/approvalCriteria-types'
import APPROVAL_CRITERIA_CONSTANTS from '../constants/approvalCriteria-constants';

export interface BussinessReducerState {
    errorMessage: string 
    errorStatus?: number
    error: boolean
    approvalCriteria: any,
    success: boolean,
    successMessage: string,
}

const initialState: BussinessReducerState = {
    errorMessage: "",
    errorStatus: 0,
    error: false,
    approvalCriteria: {} ,
    success: false,
    successMessage: "",
};


export const approvalCriteriaReducer: Reducer<BussinessReducerState, ApprovalCriteriaAction> = (state = initialState, action: ApprovalCriteriaAction) => {

    switch (action.type) {
        //------------------ POST BUSSINESS INFO -----------------------------
        case APPROVAL_CRITERIA_CONSTANTS.POST_APPROVAL_CRITERIA_BEGIN:
            return {
                ...state,
                errorMessage: "",
                loading: true,
                error: false,
                errorStatus: 0
            }

        case APPROVAL_CRITERIA_CONSTANTS.POST_APPROVAL_CRITERIA_ERROR:

            return {
                ...state,
                errorMessage: action.error.data.errorMessage,
                errorStatus: action.error ? action.error.status : 0,
                error: true,
                loading: false
            }

        case APPROVAL_CRITERIA_CONSTANTS.POST_APPROVAL_CRITERIA_SUCCESS:
            return {
                ...state,
                errorMessage: "",
                error: false,
                successMessage: action.data.message,
                loading: false,
                approvalCriteria: action.data.approvalCriteria,
                errorStatus: 0,
            }
        //------------------ GET BUSSINESS INFORMATION -----------------------------
        case APPROVAL_CRITERIA_CONSTANTS.GET_APPROVAL_CRITERIA_BEGIN:
            return {
                ...state,
                errorMessage: "",
                loading: true,
                error: false,
                errorStatus: 0,
                curpMessage: ""
            }

        case APPROVAL_CRITERIA_CONSTANTS.GET_APPROVAL_CRITERIA_ERROR:
            return {
                ...state,
                errorMessage: action.error.data.errorMessage,
                errorStatus: action.error.status,
                error: true,
                loading: false
            }

        case APPROVAL_CRITERIA_CONSTANTS.GET_APPROVAL_CRITERIA_SUCCESS:
            return {
                ...state,
                errorMessage: "",
                error: false,
                loading: false,
                approvalCriteria: action.data.approvalCriteria,
                errorStatus: 0,
                successMessage:action.data.message,
                success: true
            }

        case APPROVAL_CRITERIA_CONSTANTS.RESET_APPROVAL_CRITERIA_STATUS:
            return {
                ...state,
                loading: false,
                errorMessage: '',
                errorStatus: 0,
                success: false,
                approvalCriteria: {} ,
                successMessage: ''
            }

        default:
            return state
    }
}
