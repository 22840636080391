import React, { FC } from 'react';

interface Props {
    isOpen: boolean;
}

const ScreenLoader: FC<Props> = (p) => {
    return (
        <>
            <div className="d-flex justify-content-center" hidden={p.isOpen}>
                <div className="spinner-border spinner-border-xl m-5 p-5" style={{ width: '4em', height: '4em' }} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </>
    );
};

export default ScreenLoader;
