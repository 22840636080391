import { Reducer } from 'redux';
import roleModel from '../../models/role-model';
import ROLE_CONSTANTS from '../constants/role-constants';
import { RoleAction } from '../types/role-types';

export interface RoleReducerState {
    loading: boolean;
    success: boolean;
    successMessage: string;
    roles: roleModel;
    role: roleModel;
    error: boolean;
    errorMessage?: string;
    errorStatus?: number;
}

const initialState: RoleReducerState = {
    loading: false,
    success: false,
    error: false,
    roles: {} as roleModel,
    role: {} as roleModel,
    successMessage: '',
    errorMessage: '',
    errorStatus: 0
};

export const roleReducer: Reducer<RoleReducerState, RoleAction> = (state = initialState, action: RoleAction) => {
    switch (action.type) {
        //----------CREATE ROLE-----------//
        case ROLE_CONSTANTS.POST_ROLE_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            };

        case ROLE_CONSTANTS.POST_ROLE_ERROR:
            return {
                ...state,
                success: false,
                successMessage: '',
                error: true,
                errorStatus: action.error.status,
                errorMessage: action.error.errors,
                loading: false
            };

        case ROLE_CONSTANTS.POST_ROLE_SUCCESS:
            return {
                ...state,
                success: true,
                successMessage: action.data.message,
                errorMessage: '',
                role: action.data ? action.data.role : {},
                errorStatus: 0,
                error: false,
                loading: false
            };

        //----------UPDATE ROLE INFORMATION-----------//
        case ROLE_CONSTANTS.UPDATE_ROLE_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            };

        case ROLE_CONSTANTS.UPDATE_ROLE_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                errorStatus: action.error.data.status,
                errorMessage: action.error.data.message,
                successMessage: '',
                loading: false
            };

        case ROLE_CONSTANTS.UPDATE_ROLE_SUCCESS:
            return {
                ...state,
                success: true,
                client: action.data.client,
                errorMessage: '',
                errorStatus: 0,
                error: false,
                loading: false
            };

        //------------------ GET ALL ROLES -----------------------------
        case ROLE_CONSTANTS.GET_ALL_ROLES_BEGIN:
            return {
                ...state,
                errorMessage: '',
                loading: true,
                successMessage: '',
                error: false,
                errorStatus: 0
            };

        case ROLE_CONSTANTS.GET_ALL_ROLES_ERROR:
            return {
                ...state,
                errorMessage: action.error.data.message,
                successMessage: '',
                errorStatus: action.error ? action.error.status : 400,
                error: true,
                loading: false
            };

        case ROLE_CONSTANTS.GET_ALL_ROLES_SUCCESS:
            return {
                ...state,
                errorMessage: '',
                error: false,
                successMessage: action.data.message,
                loading: false,
                roles: action.data,
                errorStatus: 0
            };

        //------------------ GET ROLE BY ID -----------------------------
        case ROLE_CONSTANTS.GET_ROLE_BEGIN:
            return {
                ...state,
                errorMessage: '',
                loading: true,
                successMessage: '',
                error: false,
                errorStatus: 0
            };

        case ROLE_CONSTANTS.GET_ROLE_ERROR:
            return {
                ...state,
                errorMessage: action.error?.data.message,
                successMessage: '',
                errorStatus: action.error ? action.error.status : 400,
                error: true,
                loading: false
            };

        case ROLE_CONSTANTS.GET_ROLE_SUCCESS:
            return {
                ...state,
                errorMessage: '',
                error: false,
                successMessage: action.data.message,
                loading: false,
                role: action.data,
                errorStatus: 0
            };

        //------------------ RESET REDUCER STATUS -----------------------------
        case ROLE_CONSTANTS.RESET_ROLE_STATUS:
            return {
                ...state,
                loading: false,
                errorMessage: '',
                errorStatus: 0,
                success: false,
                role: {} as roleModel,
                successMessage: ''
            };

        //------------------ RESET REDUCERR STATUS -----------------------------
        case ROLE_CONSTANTS.RESET_ROLE_MESSAGES:
            return {
                ...state,
                errorMessage: '',
                successMessage: ''
            };

        default:
            return state;
    }
};
