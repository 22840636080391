enum OPT_CONSTANTS {
    POST_OTP_BEGIN = "POST_OTP_BEGIN",
    POST_OTP_ERROR = "POST_OTP_ERROR",
    POST_OTP_SUCCESS = "POST_OTP_SUCCESS",

    SEND_OTP_BEGIN = "SEND_OTP_BEGIN",
    SEND_OTP_ERROR = "SEND_OTP_ERROR",
    SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS",

    SEND_OTP_PASSWORD_BEGIN = "SEND_OTP_PASSWORD_BEGIN",
    SEND_OTP_PASSWORD_ERROR = "SEND_OTP_PASSWORD_ERROR",
    SEND_OTP_PASSWORD_SUCCESS = "SEND_OTP_PASSWORD_SUCCESS",

    GET_OTP_BEGIN = "GET_OTP_BEGIN",
    GET_OTP_ERROR = "GET_OTP_ERROR",
    GET_OTP_SUCCESS = "GET_OTP_SUCCESS",

    RESET_OTP_STATUS = "RESET_OTP_STATUS"
};

export default OPT_CONSTANTS;