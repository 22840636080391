import { Dispatch } from "redux";

import { API } from "../api/flow-api";
import FLOW_CONSTANTS from "../constants/flow-constants"
import * as types from '../types/flow-types';

//---------------------------------- GET MORAL PERSON INFORMATION --------------------------------------------------
const createFlowBegin = (): types.ICreateFlowBegin => {
    return {
        type: FLOW_CONSTANTS.CREATE_FLOW_BEGIN
    };
}

const createFlowError = (e: any): types.ICreateFlowError => {
    return {
        type: FLOW_CONSTANTS.CREATE_FLOW_ERROR,
        error: e
    };
}

const createFlowSuccess = (data: any): types.ICreateFlowSuccess => {
    return {
        type: FLOW_CONSTANTS.CREATE_FLOW_SUCCESS,
        data
    };
}

export const postFlow = (payload: any) => {
    return (dispatch: Dispatch) => {
        dispatch(createFlowBegin());
        API.createFlow(payload)
            .then((response: any) => {
                dispatch(createFlowSuccess(response.data));
            }).catch((error: any) => {
                if(error.status === 401){
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = "/login";
                }
                dispatch(createFlowError(error.response));
            });
    }
}

//---------------------------------- GET ALL THE FLOWS CREATED --------------------------------------------------
const getAllFlowsBegin = (): types.IGetAllFlowsBegin => {
    return {
        type: FLOW_CONSTANTS.GET_ALL_FLOWS_BEGIN
    };
}

const getAllFlowsError = (e: any): types.IGetAllFlowsError => {
    return {
        type: FLOW_CONSTANTS.GET_ALL_FLOWS_ERROR,
        error: e
    };
}

const getAllFlowsSuccess = (data: any): types.IGetAllFlowsSuccess => {
    return {
        type: FLOW_CONSTANTS.GET_ALL_FLOWS_SUCCESS,
        data
    };
}

export const getAllFlows = (page: any) => {
    return (dispatch: Dispatch) => {
        dispatch(getAllFlowsBegin());
        API.getAllFlows(page)
            .then((response: any) => {
                dispatch(getAllFlowsSuccess(response.data));
            }).catch((error: any) => {
                if(error.status === 401){
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = "/login";
                }
                dispatch(getAllFlowsError(error.response));
            });
    }
}

//---------------------------------- GET FLOW BY ID CREATED --------------------------------------------------
const getFlowBegin = (): types.IGetFlowBegin => {
    return {
        type: FLOW_CONSTANTS.GET_FLOW_BEGIN
    };
}

const getFlowError = (e: any): types.IGetFlowError => {
    return {
        type: FLOW_CONSTANTS.GET_FLOW_ERROR,
        error: e
    };
}

const getFlowSuccess = (data: any): types.IGetFlowSuccess => {
    return {
        type: FLOW_CONSTANTS.GET_FLOW_SUCCESS,
        data
    };
}

export const getFlowById = (id: any) => {
    return (dispatch: Dispatch) => {
        dispatch(getFlowBegin());
        API.getFlowById(id)
            .then((response: any) => {
                dispatch(getFlowSuccess(response.data));
            }).catch((error: any) => {
                if(error.status === 401){
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = "/login";
                }
                dispatch(getFlowError(error.response));
            });
    }
}

export const getFlowByName = (name: string) => {
    return (dispatch: Dispatch) => {
        dispatch(getFlowBegin());
        API.getFlowByName(name)
            .then((response: any) => {
                dispatch(getFlowSuccess(response.data));
            }).catch((error: any) => {
                if(error.status === 401){
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = "/login";
                }
                dispatch(getFlowError(error.response));
            });
    }
}

export const getDefaultFlow = () => {
    return (dispatch: Dispatch) => {
        dispatch(getFlowBegin());
        API.getDefaultFlow()
            .then((response: any) => {
                dispatch(getFlowSuccess(response.data));
            }).catch((error: any) => {
                if(error.status === 401){
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = "/login";
                }
                dispatch(getFlowError(error.response));
            });
    }
}
//--------------------------- ACTION TO RESET ALL THE REDUCER STATUS ----------------------------
const resetStatus = () => {
    return {
        type: FLOW_CONSTANTS.RESET_FLOW_STATUS
    }
}

export const resetFlowStatus = () => {
    return (dispatch: Dispatch) => {
        dispatch(resetStatus());
    }
}

//--------------------------- ACTION TO RESET THE MESSAGES IN THE REDUCER ----------------------------
const resetMessages = () => {
    return {
        type: FLOW_CONSTANTS.RESET_FLOW_MESSAGES
    }
}

export const resetFlowMessages = () => {
    return (dispatch: Dispatch) => {
        dispatch(resetMessages());
    }
}