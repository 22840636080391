import axios from 'axios';

const API_URL = process.env.REACT_APP_BASE_URL;

const createFlow = (payload: any) => {
    return axios.post(`${API_URL}flow`, payload);
}

const getAllFlows = (page: string) => {
    return axios.get(`${API_URL}flow/paginated/${page}`);
}

const getDefaultFlow = () => {
    return axios.get(`${API_URL}flow/default`);
}

const getFlowById = (id: string) => {
    return axios.get(`${API_URL}flow/${id}`);
}

const getFlowByName = (name: string) => {
    return axios.get(`${API_URL}flow/name/${name}`);
}

export const API = {
    createFlow,
    getAllFlows,
    getFlowById,
    getFlowByName,
    getDefaultFlow
}