import moment from 'moment';
import React, { useEffect, FC, useState } from 'react';
import { Col, Modal, Row, Button } from 'react-bootstrap';

interface Props {
    showModal: boolean;
    onConfirm?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | any;
    onClose: () => void | any;
    payloadInfo: any[];
}

const ConfirmInfoModal: FC<Props> = (p) => {
    return (
        <Col className="d-flex flex-column justify-content-center">
            <Modal show={p.showModal} onHide={p.onClose} className="onBoarding-modal">
                <Modal.Header closeButton>
                    <Modal.Title as="h5">Confirma tu información</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>En este paso es necesario confirmar la información agregada, favor de verificarla:</p>
                    <ul>
                        {p.payloadInfo.map((info: any) => {
                            return (
                                <>
                                    {info.name !== 'Geolocalización GPS' && (
                                        <li>
                                            <>
                                                <span>{info.name}</span> :{' '}
                                                <span className="onBoarding-item-value">
                                                    {info.type === 'Date' ? moment(info.value).format('DD/MM/YYYY') : info.value}
                                                </span>
                                            </>
                                        </li>
                                    )}
                                </>
                            );
                        })}
                    </ul>
                    <p>¿Deseas continuar?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Row className="mt-3 mb-3">
                        <Button className="btn-border-radius onBoarding-closeBtn" onClick={p.onClose}>
                            Cancelar
                        </Button>
                        <Button className="btn-border-radius onBoarding-approvedBtn" onClick={p.onConfirm}>
                            Aprobar
                        </Button>
                    </Row>
                </Modal.Footer>
            </Modal>
        </Col>
    );
};
export default ConfirmInfoModal;
