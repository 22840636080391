import axios from 'axios';

const API_URL = process.env.REACT_APP_BASE_URL;

const postSaveRole = (payload: string) => {
    return axios.post(`${API_URL}role/`, payload);
};

const putUpdateRole = (payload: string) => {
    return axios.put(`${API_URL}role/updateRole`, payload);
};

const getAllRoles = () => {
    return axios.get(`${API_URL}role/roles`);
};

const getRole = (id: string) => {
    return axios.get(`${API_URL}role/${id}`);
};

export const API = {
    postSaveRole,
    putUpdateRole,
    getAllRoles,
    getRole
};
