import * as React from 'react';
import { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getApprovalProcess, resetApprovalProcessStatus } from '../../../flux/actions/approvalProcess-actions';
import { getCredit, updateStatus } from '../../../flux/actions/quote-actions';
import { getApprovalCriteria } from '../../../flux/actions/approvalCriteria-actions';
import UserModel from '../../../models/user-model';
import DocsApproval from './ApprovalComponents/DocsApproval';
import ListRiskPeopleCard from '../../ApprovalProcess/CreateApprovalCriteria/ListRiskPeople';
import SATInfoApproval from './ApprovalComponents/SATInfoApproval';
import ValidateId from './ApprovalComponents/ValidateId';
import FicoScoreCreditReport from './ApprovalComponents/FicoScoreCreditReport';
import MoralPersonCreditReport from './ApprovalComponents/MoralPersonCreditReport';
import LifeProof from './ApprovalComponents/LifeProof';
import RecordMessage from './ApprovalComponents/recordMessage';
import CreditProposal from './CustomerInfoComponents/CreditProposal';
import ApplicationApproved from './CustomerInfoComponents/ApplicationApproved';
import ActivePortafolio from './CustomerInfoComponents/ActivePortfolio';
import ScheduleDate from './ApprovalComponents/ScheduleDate';
//import ApprovalAlerts from './ApprovalComponents/ApprovalCriteriaAlerts/ApprovalAlerts'

const ApprovalFlow: React.FC = () => {
    //const to get the params
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    const clientRef = (params as any)?.id;

    const user: UserModel = useSelector((state: any) => state.authReducer.user);
    const quote: any = useSelector((state: any) => state.quoteReducer.credit);
    const { approvalProcess } = useSelector((state: any) => state.approvalProcessReducer);
    const { approvalCriteria } = useSelector((state: any) => state.approvalCriteriaReducer);

    useEffect(() => {
        if (Object.keys(user).length === 0) {
            history.push('admin/login');
        }
        dispatch(getCredit(clientRef));
        dispatch(getApprovalProcess(clientRef));
    }, []);

    useEffect(() => {
        if (Object.keys(quote).length !== 0) {
            if (quote.adminStatus === 'inReview' && Object.keys(approvalProcess).length > 0) {
                dispatch(
                    updateStatus({
                        clientRef: clientRef,
                        type: 'adminStatus',
                        status: approvalProcess.steps[0]?.adminStatus ?? 'inReview'
                    })
                );
                setTimeout(() => {
                    dispatch(getCredit(clientRef));
                }, 1000);
            }
        }
    }, [approvalProcess]);

    return (
        <Col className="Demo" xs={12}>
            {/* <ApprovalAlerts /> */}
            {/* {approvalCriteria.listRiskPeople &&}  */}
            {/* <ListRiskPeopleCard /> */}
            {quote && quote.adminStatus === 'validateDocuments' && <DocsApproval />}
            {quote && quote.adminStatus === 'pendingApprovalSATWS' && <SATInfoApproval />}
            {quote && quote.adminStatus === 'confirmIdValidation' && <ValidateId />}
            {quote && quote.adminStatus === 'ConfirmScheduleDate' && <ScheduleDate />}
            {quote && quote.adminStatus === 'pendingApprovalCreditCircle' && (
                <>{quote.planRef === '1' ? <FicoScoreCreditReport /> : <MoralPersonCreditReport />}</>
            )}
            {quote && quote.adminStatus === 'confirmLifeProof' && <LifeProof />}
            {quote && quote.adminStatus === 'confirmRecordMessage' && <RecordMessage />}
            {quote && quote.adminStatus === 'creditProposal' && <CreditProposal />}
            {quote && quote.adminStatus === 'creditProposalPendingApproval' && <ActivePortafolio />}
            {quote && quote.adminStatus === 'activePortfolio' && <ApplicationApproved />}
        </Col>
    );
};
export default ApprovalFlow;
