import React, { FC, useEffect, useState } from 'react';
import { API } from '../../../flux/api/unic-api';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Button, ModalBody, Modal } from 'react-bootstrap';
import { getCredit } from '../../../flux/actions/quote-actions';
import { refreshToken } from '../../../flux/actions/refreshToken-action';

const VideoRecord = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { client } = useSelector((state: any) => state.clientReducer);
    const quote: any = useSelector((state: any) => state.quoteReducer.credit);
    const nubarium = useSelector((state: any) => state.NubariumReferenceReducer);
    const { approvalProcess } = useSelector((state: any) => state.approvalProcessReducer);

    useEffect(() => {
        if (Object.keys(client).length === 0) {
            history.push('/login');
        }
        dispatch(getCredit(client.id));
        dispatch(
            refreshToken({
                token: localStorage.getItem('token'),
                refreshToken: localStorage.getItem('refreshToken')
            })
        );
    }, []);

    const openRecord = async () => {
        let stepIndex = 0;
        let nextStep = '';
        const flowApprovalProcess = await approvalProcess.steps.filter((step: any) => {
            return step.clientStatus !== 'generateCreditCircleNIP';
        });

        if (flowApprovalProcess) {
            flowApprovalProcess.forEach((step: any, index: number) => {
                if (step.clientStatus === quote.status) {
                    stepIndex = index + 1;
                    if (stepIndex < flowApprovalProcess.length) {
                        nextStep = flowApprovalProcess[index].continuousApprovalFlow
                            ? flowApprovalProcess[stepIndex].clientStatus
                            : flowApprovalProcess[index].adminStatus;
                    } else if (stepIndex === flowApprovalProcess.length) {
                        nextStep = 'completionOfTheApprovalFlow';
                    }
                }
            });
        }

        if (localStorage.getItem('nubarium:redirected:RecordMessage') === null) {
            client.id && API.idReference({ id: client.id, flowStatus: nextStep });
            window.open('https://api1.kualinet.com/api/v1/nubariumApi/video-selfi');
            //window.open('http://localhost:3001/api/v1/nubariumApi/video-selfi');
            localStorage.setItem('nubarium:redirected:RecordMessage', 'true');
        }
        dispatch(
            refreshToken({
                token: localStorage.getItem('token'),
                refreshToken: localStorage.getItem('refreshToken')
            })
        );
    };

    return (
        <Card className="record">
            <Card.Title className="record-title">Grabación de mensaje</Card.Title>
            <Card.Subtitle className="record-subtitle">
                Para validación y seguridad en el siguiente paso realizaremos la grabación de un mensaje.{' '}
            </Card.Subtitle>
            <Button className="record-button" onClick={() => openRecord()}>
                Grabar Mensaje
            </Button>
        </Card>
    );
};

export default VideoRecord;
