import React, { FC } from 'react';
import {  useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card, Col, Row, Modal, Button} from 'react-bootstrap';
import { documentsDescription } from '../../../../constants/documentsDescription';


const AddressDocsInfo: React.FC = () => {
    //const to get the params

    const files = useSelector((state: any) => state.DocumentsReducer.flowDocuments);
    //Estados de los modales
    const [showPDFModal, setShowPDFModal] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);
    const [url, setUrl] = useState<any>();

    useEffect(() => {
    }, [url,files]);

    const base64toBlob = (file: any) => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64
        var base64WithoutPrefix = file;
        if (file.includes('dataapplication/pdfbase64')) {
            base64WithoutPrefix = file.substr('dataapplication/pdfbase64'.length);
        }
        if (file.includes('dataapplication/pdfbase64')) {
            base64WithoutPrefix = file.substr('dataapplication/pdfbase64'.length);
        }
        if (file.includes('data:application/pdf;base64,')) {
            base64WithoutPrefix = file.substr('data:application/pdf;base64,'.length);
        }
        const bytes = atob(file);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: 'application/pdf' });
    };

    const _renderDescription = (type: string) => {
        switch(type){
            case 'addressProof':
                return documentsDescription.addressProof;
            case 'bussinessAddressProof':
                return documentsDescription.bussinessAddressProof;
        }
    }

    return (
        <>
        {files[0] !== undefined &&
            <Card>
                <Card.Header className="d-flex align-items-center justify-content-between">
                    <h5 className="mb-0">Comprobantes de domicilio</h5>
                </Card.Header>
                <Card.Body className={'border-top pro-det-edit collapse border-top pro-det-edit show'}>
                    <Row>
                        {files &&
                            files.map((file: any) => {
                                if(file.type === 'addressProof' || file.type === 'bussinessAddressProof') {
                                    return (
                                        <Col className='customerProfile-files'>
                                            {file.name === '' ? ( '' ) : (
                                                <button
                                                    type="button"
                                                    className="btn btn-third btn-sm btn-border-radius float-center mt-2 doc-showBtn"
                                                onClick={() => {
                                                    const blob = base64toBlob(file.file);
                                                    const urlAux = URL.createObjectURL(blob);
                                                    setUrl(urlAux);
                                                        setShowPDFModal(!showPDFModal);
                                                    }}
                                            >
                                                <i className={'feather icon-file-text'} />
                                            </button>
                                        )}
                                        <p className="mt-3 align-middle doc-titleDocument">
                                            {_renderDescription(file.type)}
                                        </p>
                                    </Col>
                                )
                            }
                            })
                        }
                    </Row>
                    <Modal size="xl" show={showPDFModal} onHide={() => setShowPDFModal(!showPDFModal)}>
                        <Modal.Header closeButton className='approvalFlow-docsModal-header'>
                            <Modal.Title as="h5">Documento</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <iframe src={url} title="Documentación" height="800rem" width="100%" />
                        </Modal.Body>
                    </Modal>
                    <Modal size="xl" show={showImageModal} onHide={() => setShowImageModal(!showImageModal)}>
                        <Modal.Header closeButton className='approvalFlow-docsModal-header'>
                            <Modal.Title as="h5">Documento</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='d-flex flex-column approvalFlow-docsModal-body'>
                            <img src={url} style={{ width: '100%', height: 'auto' }} alt="Imagen de documento"/>
                            <div className="d-flex justify-content-end mt-3">
                                <Button className='docs-infoBtn'>
                                    <a className="text-white" download={url + '.jpg'} href={url}>
                                        Descargar
                                    </a>
                                </Button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </Card.Body>
            </Card>
        }
        </>
    );
};
export default AddressDocsInfo;
