enum APPROVAL_PROCESS_CONSTANTS {
    POST_APPROVAL_PROCESS_BEGIN = "POST_APPROVAL_PROCESS_BEGIN",
    POST_APPROVAL_PROCESS_ERROR = "POST_APPROVAL_PROCESS_ERROR",
    POST_APPROVAL_PROCESS_SUCCESS = "POST_APPROVAL_PROCESS_SUCCESS",
    
    GET_APPROVAL_PROCESS_BEGIN = "GET_APPROVAL_PROCESS_BEGIN",
    GET_APPROVAL_PROCESS_ERROR = "GET_APPROVAL_PROCESS_ERROR",
    GET_APPROVAL_PROCESS_SUCCESS = "GET_APPROVAL_PROCESS_SUCCESS",

    GET_APPROVAL_PROCESS_FLOW_BEGIN = "GET_APPROVAL_PROCESS_FLOW_BEGIN",
    GET_APPROVAL_PROCESS_FLOW_ERROR = "GET_APPROVAL_PROCESS_FLOW_ERROR",
    GET_APPROVAL_PROCESS_FLOW_SUCCESS = "GET_APPROVAL_PROCESS_FLOW_SUCCESS",

    RESET_APPROVAL_PROCESS_STATUS = "RESET_APPROVAL_PROCESS_STATUS"
};

export default APPROVAL_PROCESS_CONSTANTS;