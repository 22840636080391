import { Reducer } from 'redux';
import REFRESHTOKEN_CONSTANT from '../constants/refreshToken-constants';
import { RefreshTokenAction } from '../types/refreshToken-types';



export interface RefreshTokenReducerState {
    loading: boolean;
    success: boolean;
    successMessage: string;
    error: boolean;
    errorMessage?: string;
    errorStatus?: number;
}

const initialState: RefreshTokenReducerState = {
    loading: false,
    success: false,
    error: false,
    successMessage: '',
    errorMessage: '',
    errorStatus: 0,
}


export const refreshTokenReducer: Reducer<RefreshTokenReducerState, RefreshTokenAction> = (state = initialState, action: RefreshTokenAction) => {
    switch (action.type) {
        //----------CREATE ORDER-----------//
        case REFRESHTOKEN_CONSTANT.POST_REFRESHTOKEN_CONSTANTS_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            }
        
        case REFRESHTOKEN_CONSTANT.POST_REFRESHTOKEN_CONSTANTS_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                errorStatus: action.error.data,
                errorMessage: action.error.data,
                successMessage: '',
                loading: false
            }
        
        case REFRESHTOKEN_CONSTANT.POST_REFRESHTOKEN_CONSTANTS_SUCCESS:
            return {
                ...state,
                success: true,
                successMessage: action.data,
                errorMessage: '',
                errorStatus: 0,
                error: false,
                loading: false
            }
        
        case REFRESHTOKEN_CONSTANT.RESET_REFRESHTOKEN_CONSTANTS_STATUS:
            return {
                success: false,
                error: false,
                loading: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            }

        default:
            return state;
    }
}