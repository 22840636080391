import axios from 'axios';

const API_URL = process.env.REACT_APP_BASE_URL;

const getZipCode = (zipCode: string) => {
    return axios.get(`${API_URL}zip-code/${zipCode}`);
};

const getEconomicActivities = (activity: string) => {
    return axios.get(`${API_URL}economic-activity/${activity}`);
};

const postOtp = (payload: any) => {
    return axios.post(`${API_URL}otp`, payload);
};

const getOtp = (userRef: any) => {
    return axios.get(`${API_URL}otp/searchUsr/${userRef}`);
};

const getInformationList = () => {
    return axios.get(`${API_URL}information-list`);
};

const postApprovalCriteria = (payload: any) => {
    return axios.post(`${API_URL}approvalCriteria`, payload);
};

const getApprovalCriteria = (flowRef: string) => {
    return axios.get(`${API_URL}approvalCriteria/${flowRef}`);
};

const getlistRiskPeople = (names: String) => {
    return axios.get(`${API_URL}listRiskPeople/${names}`);
};

const getApprovalSteps = () => {
    return axios.get(`${API_URL}approvalProcessStep`);
};

const postApprovalProcess = (payload: any) => {
    return axios.post(`${API_URL}approvalProcess`, payload);
};

const getApprovalProcess = (flowRef: String) => {
    return axios.get(`${API_URL}approvalProcess/${flowRef}`);
};

const idReference = (ref: any) => {
    return axios.post(`${API_URL}nubariumApi/send-reference`, ref);
};

const compareIdSelfie = (ref: any) => {
    return axios.post(`${API_URL}nubariumApi/selfie-compare`, ref);
};
const renewpassword = (data: any) => {
    return axios.put(`${API_URL}renewPassword`, data);
};

const refreshToken = (data: any) => {
    return axios.post(`${API_URL}refreshToken`, data);
};

const downloadMedia = (data: any) => {
    return axios.post(`${API_URL}nubariumApi/dowlnoad-media`, data);
};

export const API = {
    getZipCode,
    getEconomicActivities,
    getInformationList,
    postOtp,
    getOtp,
    postApprovalCriteria,
    getApprovalCriteria,
    getApprovalSteps,
    postApprovalProcess,
    getApprovalProcess,
    getlistRiskPeople,
    renewpassword,
    refreshToken,
    idReference,
    compareIdSelfie,
    downloadMedia
};
