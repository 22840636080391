import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from '../../../store/reducer';
import DEMO from '../../../store/constant';
import * as actionTypes from '../../../store/actions';
import logo from '../../../assets/images/kualinetBlanco.png';


const NavBar = () => {
    const dispatch = useDispatch();
    const headerBackColor = useSelector((state) => state.able.headerBackColor);
    const headerFixedLayout = useSelector((state) => state.able.headerFixedLayout);
    const onToggleNavigation = () => dispatch({ type: actionTypes.COLLAPSE_MENU });
    const [rightToggle, setRightToggle] = useState(false);
    let headerClass = ['navbar', 'pcoded-header', 'navbar-expand-lg', 'header-blue', 'bg-third'];
    document.body.classList.add(headerBackColor);
    if (headerFixedLayout) {
        headerClass = [...headerClass, 'headerpos-fixed'];
    }
    let mainLogo = logo;
    let navHtml: React.ReactNode;

    let navBar = (
        <>
            <div className="panel-header" >
                <a className='mobile-menu' id="mobile-collapse1" href={DEMO.BLANK_LINK} onClick={onToggleNavigation}>
                    <span />
                </a>
                <a href={DEMO.BLANK_LINK} className="b-brand">
                    <img id="main-logo" src={mainLogo} alt="" className="logo" style={{width: '120px', float: 'left'}} />
                </a>
                <a className="mob-toggler" href={DEMO.BLANK_LINK} onClick={() => setRightToggle(!rightToggle)}>
                    <i className="feather icon-more-vertical" />
                </a>
            </div>
            {navHtml}
        </>
    );
    return <header className={headerClass.join(' ')}>{navBar}</header>;
};
export default NavBar;
