enum NUBARIUM_REFERENCE_CONSTANTS {
    POST_NUBARIUM_REFERENCE_BEGIN = 'GET_ZIPCODE_BEGIN',
    POST_NUBARIUM_REFERENCE_ERROR = 'POST_NUBARIUM_REFERENCE_ERROR',
    POST_NUBARIUM_REFERENCE_SUCCESS = 'POST_NUBARIUM_REFERENCE_SUCCESS',

    COMPARE_ID_SELFIE_BEGIN = 'COMPARE_ID_SELFIE_BEGIN',
    COMPARE_ID_SELFIE_ERROR = 'COMPARE_ID_SELFIE_ERROR',
    COMPARE_ID_SELFIE_SUCCESS = 'COMPARE_ID_SELFIE_SUCCESS',

    DOWNLOAD_MEDIA_BEGIN = 'DOWNLOAD_MEDIA_BEGIN',
    DOWNLOAD_MEDIA_ERROR = 'DOWNLOAD_MEDIA_ERROR',
    DOWNLOAD_MEDIA_SUCCESS = 'DOWNLOAD_MEDIA_SUCCESS',

    RESET_NUBARIUM_STATUS = 'RESET_NUBARIUM_STATUS'
}

export default NUBARIUM_REFERENCE_CONSTANTS;
