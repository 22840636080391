import { Dispatch } from 'redux';
import { API } from '../api/unic-api';
import { interceptor } from './../../services/interceptor';
import REFRESHTOKEN_CONSTANTS from '../constants/refreshToken-constants';
import * as types from '../types/refreshToken-types';

const postRefreshTokenBegin = (): types.IPostRefreshTokenBegin => {
    return {
        type: REFRESHTOKEN_CONSTANTS.POST_REFRESHTOKEN_CONSTANTS_BEGIN
    };
};

const postRefreshTokenError = (e: any): types.IPostRefreshTokenError => {
    return {
        type: REFRESHTOKEN_CONSTANTS.POST_REFRESHTOKEN_CONSTANTS_ERROR,
        error: e
    };
};

const postRefreshTokenSuccess = (data: any): types.IPostRefreshTokenSuccess => {
    return {
        type: REFRESHTOKEN_CONSTANTS.POST_REFRESHTOKEN_CONSTANTS_SUCCESS,
        data
    };
};

export const refreshToken = (payload: any) => {
    return (dispatch: Dispatch) => {
        dispatch(postRefreshTokenBegin());
        API.refreshToken(payload)
            .then((response: any) => {
                localStorage.setItem('token', response.data.token);
                interceptor.addToken(response.data.token);
                dispatch(postRefreshTokenSuccess(response.data));
            })
            .catch((error: any) => {
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('persist:authReducer');
                localStorage.removeItem('persist:root');
                localStorage.removeItem('nubarium:redirected:FaceCapture');
                localStorage.removeItem('nubarium:redirected:RecordMessage');
                window.location.href = '/login';
                dispatch(postRefreshTokenError(error.response));
            });
    };
};

const resetStatus = () => {
    return {
        type: REFRESHTOKEN_CONSTANTS.RESET_REFRESHTOKEN_CONSTANTS_STATUS
    };
};

export const resetRefreshTokenStatus = () => {
    return (dispatch: Dispatch) => {
        dispatch(resetStatus());
    };
};
