import * as React from 'react';
import { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getApprovalProcess, resetApprovalProcessStatus } from '../../../flux/actions/approvalProcess-actions';
import { getCredit, updateStatus } from '../../../flux/actions/quote-actions';
import { getApprovalCriteria } from '../../../flux/actions/approvalCriteria-actions';
import UserModel from '../../../models/user-model';
import PromoterCreditProposal from './PromoterFlowComponents/PromoterCreditProposal';
//import ApprovalAlerts from './ApprovalComponents/ApprovalCriteriaAlerts/ApprovalAlerts'

const PromoterFlow: React.FC = () => {
    //const to get the params
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    const clientRef = (params as any)?.id;

    const user: UserModel = useSelector((state: any) => state.authReducer.user);
    const quote: any = useSelector((state: any) => state.quoteReducer.credit);
    const { approvalProcess } = useSelector((state: any) => state.approvalProcessReducer);
    const { approvalCriteria } = useSelector((state: any) => state.approvalCriteriaReducer);

    useEffect(() => {
        if (Object.keys(user).length === 0) {
            history.push('admin/login');
        }
        dispatch(getCredit(clientRef));
        dispatch(getApprovalProcess(clientRef));
    }, []);

    useEffect(() => {
        if (Object.keys(quote).length !== 0) {
            if (quote.adminStatus === 'inReview' && Object.keys(approvalProcess).length > 0) {
                dispatch(
                    updateStatus({
                        clientRef: clientRef,
                        type: 'adminStatus',
                        status: approvalProcess.steps[0]?.adminStatus ?? 'inReview'
                    })
                );
                setTimeout(() => {
                    dispatch(getCredit(clientRef));
                }, 1000);
            }
        }
    }, [approvalProcess]);

    function renderSwitch(status: String) {
        switch (status) {
            case 'promoterGeneratingQuote':
                return <PromoterCreditProposal />;
            default:
                return;
        }
    }

    return (
        <Col className="Demo" xs={12}>
            {renderSwitch(quote?.adminStatus)}
        </Col>
    );
};
export default PromoterFlow;
