import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Table, Card, Form, Button, Modal, Row } from 'react-bootstrap';
import moment from 'moment';
import { getCredit, rejectQuote } from '../../flux/actions/quote-actions';
import { documentsDescription } from '../../constants/documentsDescription';

interface Props {
    files: [];
    approved: boolean[];
    title: string;
    description: string;
    handleSwitch: (e: any) => void | any;
    onApproveDocs?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | any;
    onRequestDocs?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | any;
    requestModal: boolean;
    onCloseRequestModal: () => void;
    approvalModal: boolean;
    onCloseApprovalModal: () => void;
    docType: string;
}

const RequestFilesBuilder: FC<Props> = (p) => {
    //const to get the params
    const dispatch = useDispatch();
    const params = useParams();
    const userRef = (params as any)?.id;
    const { client } = useSelector((state: any) => state.clientReducer);

    const quotes: any = useSelector((state: any) => state.quoteReducer.credits);

    //Estados de los modales
    const [showPDFModal, setShowPDFModal] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);
    const [showCancelRequest, setShowCancelRequest] = useState(false);
    const [files, setFiles] = useState<any>();
    const [url, setUrl] = useState<any>();

    useEffect(() => {
        let aux: any = [];
        p.files.filter((file: any) => {
            if (
                file.type === 'bankReceipt1' ||
                file.type === 'bankReceipt2' ||
                file.type === 'bankReceipt3' ||
                file.type === 'idFront' ||
                file.type === 'idBack' ||
                file.type === 'addressProof' ||
                file.type === 'bussinessAddressProof' ||
                file.type === 'constitutiveAct' ||
                file.type === 'actGrantingPowers'
            ) {
                aux.push(file);
            }
        });
        setFiles(aux);
    }, [p.files]);

    const base64toBlob = (file: any) => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64
        var base64WithoutPrefix = file;
        if (file.includes('dataapplication/pdfbase64')) {
            base64WithoutPrefix = file.substr('dataapplication/pdfbase64'.length);
        }
        if (file.includes('dataapplication/pdfbase64')) {
            base64WithoutPrefix = file.substr('dataapplication/pdfbase64'.length);
        }
        if (file.includes('data:application/pdf;base64,')) {
            base64WithoutPrefix = file.substr('data:application/pdf;base64,'.length);
        }
        const bytes = atob(file);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: 'application/pdf' });
    };

    const _renderDescription = (type: string) => {
        switch (type) {
            case 'bankReceipt1':
                return documentsDescription.bankReceipt1;
            case 'bankReceipt2':
                return documentsDescription.bankReceipt2;
            case 'bankReceipt3':
                return documentsDescription.bankReceipt3;
            case 'idFront':
                return documentsDescription.idFront;
            case 'idBack':
                return documentsDescription.idBack;
            case 'addressProof':
                return documentsDescription.addressProof;
            case 'bussinessAddressProof':
                return documentsDescription.bussinessAddressProof;
            case 'constitutiveAct':
                return documentsDescription.constitutiveAct;
            case 'actGrantingPowers':
                return documentsDescription.actGrantingPowers;
            case 'taxpayerIDCard':
                return documentsDescription.taxpayerIDCard;
            case 'certificateESignature':
                return documentsDescription.certificateESignature;
        }
    };

    function cancelRequest() {
        dispatch(rejectQuote({ client: userRef }));
        setShowCancelRequest(!showCancelRequest);
        dispatch(getCredit(userRef));
    }

    return (
        <>
            <Card>
                {quotes.length !== 0 && (
                    <>
                        <Card.Header className="approvalFlow-docs-header">
                            <Card.Title as="h5">{p.title}</Card.Title>
                            <span className="d-block m-t-5">{p.description}</span>
                            <span className="docs-updateTime">
                                Actualizado hace{' '}
                                {moment().diff(client.updatedAt, 'days') !== 0
                                    ? moment().diff(client.updatedAt, 'days')
                                    : moment().diff(client.updatedAt, 'hours')}
                                {moment().diff(client.updatedAt, 'days') !== 0 ? ' dias' : ' horas'}
                            </span>
                        </Card.Header>
                    </>
                )}
                {Object.keys(quotes).length == 0 ? (
                    <Card.Header className="approvalFlow-lifeProof-header">
                        <Card.Title as="h6">El prospecto aun no ha cargado sus documentos</Card.Title>
                    </Card.Header>
                ) : (
                    <Card.Body className="approvalFlow-docs-body">
                        <Table striped className="table-columned">
                            <thead className="col-10">
                                <tr className="text-center">
                                    <th>Ver</th>
                                    <th>Documento</th>
                                    <th>¿Aprobado?</th>
                                </tr>
                            </thead>
                            <tbody>
                                {files &&
                                    files.map((file: any, index: number) => {
                                        return (
                                            <tr id={index.toString()} className="text-center">
                                                <td className="d-flex- flex-column " align={'center'}>
                                                    {file.name === '' ? (
                                                        ''
                                                    ) : (
                                                        <button
                                                            type="button"
                                                            className="btn btn-third btn-sm btn-border-radius float-center mt-2 doc-showBtn"
                                                            onClick={() => {
                                                                const blob = base64toBlob(file.file);
                                                                const urlAux = URL.createObjectURL(blob);
                                                                setUrl(urlAux);
                                                                if (file.type === 'idFront' || file.type === 'idBack') {
                                                                    setShowImageModal(!showImageModal);
                                                                } else {
                                                                    setShowPDFModal(!showPDFModal);
                                                                }
                                                            }}
                                                        >
                                                            <i className={'feather icon-file-text'} />
                                                        </button>
                                                    )}
                                                </td>
                                                <td>
                                                    {/* Cambio de en encabezado segun el archivo requerido si se cumple la condicion ejecuta los diferentes encabezados */}
                                                    <p className="mt-3 align-middle doc-titleDocument">
                                                        <h6 style={{ fontSize: 14 }}>{_renderDescription(file.type)}</h6>
                                                        {/* Muestra el nombre del Archivo si existe si no envia mensaje de espera de documentos */}
                                                        {file.name === '' ? 'En espera de que el cliente suba sus documentos' : file.name}
                                                    </p>
                                                </td>
                                                {quotes.length !== 0 && (
                                                    <td>
                                                        {file.name === '' ? (
                                                            <Form.Group className="mt-3">
                                                                <div className="switch switch-info d-inline m-r-5 m-l-5">
                                                                    En espera de que el cliente suba sus documentos
                                                                </div>
                                                            </Form.Group>
                                                        ) : (
                                                            <Form.Group>
                                                                <Form.Label>NO</Form.Label>
                                                                <div className="switch switch-info d-inline m-r-5 m-l-5">
                                                                    <Form.Control
                                                                        type="checkbox"
                                                                        id={`unchecked-info-${index}`}
                                                                        name={index.toString()}
                                                                        checked={p.approved[index]}
                                                                        onChange={p.handleSwitch}
                                                                    />
                                                                    <Form.Label htmlFor={`unchecked-info-${index}`} className="cr" />
                                                                </div>
                                                                <Form.Label>SI</Form.Label>
                                                            </Form.Group>
                                                        )}
                                                    </td>
                                                )}
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>
                    </Card.Body>
                )}
                {quotes.length !== 0 && (
                    <Card.Footer className="approvalFlow-docs-footer">
                        <Card.Title as="h5">¿Solicitar documentos a usuario?</Card.Title>
                        <Button className="btn-border-radius docs-successBtn" variant={'success'} onClick={p.onCloseRequestModal}>
                            Solicitar
                        </Button>
                    </Card.Footer>
                )}
                {quotes.length !== 0 && p.approved.indexOf(false) === -1 && (
                    <Card.Footer className="approvalFlow-docs-footer">
                        <Card.Title as="h5">¿El prospecto cumple con la información básica para continuar?</Card.Title>
                        <Row className="mt-3 ml-3">
                            <Button className="btn-border-radius docs-successBtn" variant={'success'} onClick={p.onCloseApprovalModal}>
                                Si
                            </Button>
                            <Button
                                variant={'danger'}
                                className="ml-3 btn-border-radius docs-alertBtn"
                                onClick={() => {
                                    setShowCancelRequest(!showCancelRequest);
                                }}
                            >
                                No
                            </Button>
                        </Row>
                    </Card.Footer>
                )}
            </Card>
            <Modal show={p.requestModal} onHide={p.onCloseRequestModal}>
                <Modal.Header closeButton className="approvalFlow-docsModal-header">
                    <Modal.Title as="h5">Solicitud de Documentos</Modal.Title>
                </Modal.Header>
                <Modal.Body className="approvalFlow-docsModal-body">
                    <p>
                        Los archivos que se seleccionaron como NO aprobados se borrarán permanentemente del sistema
                        <br />
                        <br />
                        ¿Deseas continuar?
                    </p>
                </Modal.Body>
                <Modal.Footer className="approvalFlow-docs-footer">
                    <Row className="mt-3 mb-3">
                        <Button className="btn-border-radius docs-alertBtn" variant="danger" onClick={p.onCloseRequestModal}>
                            Cancelar
                        </Button>
                        <Button className="ml-3 btn-border-radius docs-successBtn" variant="secondary" onClick={p.onRequestDocs}>
                            Solicitar Documentos
                        </Button>
                    </Row>
                </Modal.Footer>
            </Modal>
            <Modal size="xl" show={showPDFModal} onHide={() => setShowPDFModal(!showPDFModal)}>
                <Modal.Header closeButton className="approvalFlow-docsModal-header">
                    <Modal.Title as="h5">Documento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <iframe src={url} title="Documentación" height="800rem" width="100%" />
                </Modal.Body>
            </Modal>
            <Modal size="xl" show={showImageModal} onHide={() => setShowImageModal(!showImageModal)}>
                <Modal.Header closeButton className="approvalFlow-docsModal-header">
                    <Modal.Title as="h5">Documento</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex flex-column approvalFlow-docsModal-body">
                    <img src={url} style={{ width: '100%', height: 'auto' }} alt="Imagen de documento" />
                    <div className="d-flex justify-content-end mt-3">
                        <Button>
                            <a className="text-white docs-infoBtn" download={url + '.jpg'} href={url}>
                                Descargar
                            </a>
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={p.approvalModal} onHide={p.onCloseApprovalModal}>
                <Modal.Header closeButton className="approvalFlow-docsModal-header">
                    <Modal.Title as="h5">El usuario cumple con la documentación inicial</Modal.Title>
                </Modal.Header>
                <Modal.Body className="approvalFlow-docsModal-body">
                    <p>
                        Los archivos que subió el prospecto son los necesarios para CONTINUAR con el proceso del crédito
                        <br />
                        <br />
                        ¿Deseas continuar?
                    </p>
                </Modal.Body>
                <Modal.Footer className="approvalFlow-docs-footer">
                    <Row className="mt-3 mb-3">
                        <Button className="btn-border-radius docs-alertBtn" variant="danger" onClick={p.onCloseApprovalModal}>
                            Cancelar
                        </Button>
                        <Button className="ml-3 btn-border-radius docs-successBtn" variant="success" onClick={p.onApproveDocs}>
                            Aprobar
                        </Button>
                    </Row>
                </Modal.Footer>
            </Modal>
            <Modal show={showCancelRequest} onHide={() => setShowCancelRequest(!showCancelRequest)}>
                <Modal.Header closeButton className="approvalFlow-docsModal-header">
                    <Modal.Title as="h5">El usuario NO cumple con la información para la solicitud del crédito</Modal.Title>
                </Modal.Header>
                <Modal.Body className="approvalFlow-docsModal-body">
                    <p>
                        La información proveída por el cliente no cumple con alguno de los requisitos para ser candidato a un crédito. Esta
                        cotización quedará CANCELADA
                        <br />
                        <br />
                        ¿Deseas continuar?
                    </p>
                </Modal.Body>
                <Modal.Footer className="approvalFlow-docs-footer">
                    <Row className="mt-3 mb-3">
                        <Button
                            className="btn-border-radius docs-alertBtn"
                            variant="danger"
                            onClick={() => setShowCancelRequest(!showCancelRequest)}
                        >
                            Cancelar
                        </Button>
                        <Button className="ml-3 btn-border-radius docs-successBtn" variant="success" onClick={cancelRequest}>
                            Aceptar
                        </Button>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default RequestFilesBuilder;
