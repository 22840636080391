import { Reducer } from 'redux';
import quoteModel from '../../models/quote-model';
import QUOTE_CONSTANTS from '../constants/quote-constants';
import { QuoteAction } from '../types/quote-types';

export interface QuoteReducerState {
    loading: boolean;
    success: boolean;
    successMessage: string;
    pages: any;
    credit: any;
    credits: any;
    users: any;
    error: boolean;
    errorMessage?: string;
    errorStatus?: number;
}

const initialState: QuoteReducerState = {
    loading: false,
    success: false,
    pages: [],
    credit: {},
    credits: [],
    users: [],
    error: false,
    successMessage: '',
    errorMessage: '',
    errorStatus: 0
}

export const quoteReducer: Reducer<QuoteReducerState, QuoteAction> = (state = initialState, action: QuoteAction) => {
    switch (action.type) {
        //----------CREATE ORDER-----------//
        case QUOTE_CONSTANTS.POST_QUOTE_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            }

        case QUOTE_CONSTANTS.POST_QUOTE_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                errorStatus: action.error.data.status,
                errorMessage: action.error.data.message,
                loading: false
            }

        case QUOTE_CONSTANTS.POST_QUOTE_SUCCESS:
            return {
                ...state,
                success: true,
                successMessage: action.data.message,
                error: false,
                errorMessage: '',
                errorStatus: 0,
                loading: false
            }

            //----------CREATE EMPTY ORDER-----------//
        case QUOTE_CONSTANTS.POST_EMPTY_QUOTE_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            }

        case QUOTE_CONSTANTS.POST_EMPTY_QUOTE_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                errorStatus: action.error.data.status,
                errorMessage: action.error.data.message,
                loading: false
            }

        case QUOTE_CONSTANTS.POST_EMPTY_QUOTE_SUCCESS:
            return {
                ...state,
                success: true,
                successMessage: action.data.message,
                error: false,
                errorMessage: '',
                errorStatus: 0,
                loading: false
            }

        //----------GET ALL CREDITS-----------//
        case QUOTE_CONSTANTS.GET_CREDITS_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            }

        case QUOTE_CONSTANTS.GET_CREDIT_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                errorStatus: action.error?.data.status,
                errorMessage: action.error?.data.message,
                loading: false
            }

        case QUOTE_CONSTANTS.GET_CREDIT_SUCCESS:
            return {
                ...state,
                success: true,
                successMessage: '',
                credit: action.data.credits,
                error: false,
                errorMessage: '',
                errorStatus: 0,
                loading: false
            }
        
        //---------- UPDATE QUOTE STATUS ------//
        case QUOTE_CONSTANTS.UPDATE_STATUS_SUCCESS:
            return {
                ...state,
                success: true,
                successMessage: '',
                credit: action.data.credit,
                error: false,
                errorMessage: '',
                errorStatus: 0,
                loading: false
            }

        //---------------GET PAGINATION QUOTES-----------------//
        case QUOTE_CONSTANTS.GET_QUOTES_PAGINATION_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            }

        case QUOTE_CONSTANTS.GET_QUOTES_PAGINATION_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                errorStatus: action.error.data.status,
                errorMessage: action.error.data.message,
                loading: false
            }
            case QUOTE_CONSTANTS.GET_QUOTES_PAGINATION_SUCCESS:
                return {
                    ...state,
                    success: true,
                    credits: action.data.credits.docs,
                    users: action.data.users,
                    pages: action.data.credits,
                    error: false,
                    errorMessage: '',
                    errorStatus: 0,
                    loading: false
                }

        case QUOTE_CONSTANTS.RESET_QUOTE_STATUS:
            return {...state,
                success: false,
                error: false,
                loading: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: '',
                credit: {},
                credits: []
            }

        default:
            return state;
    }
};
