import { Dispatch } from "redux";
import CLIENT_CONSTANTS from '../constants/client-constants';
import * as typeClientSuccess from '../types/client-types';
import { API } from "../api/unic-api";
import OTP_CONSTANTS from "../constants/otp-constants"
import * as types from '../types/otp-types';
import { sendEmail, sendRenewPasswordEmail, sendEmailRequest, sendEmailApprovedDocuments, sendEmailRequestDocuments } from '../../services/email/email';


//---------------------------------- GET AND UPDATE CLIENT REDUX --------------------------------------------------
export const getClientSuccess = (data: any): typeClientSuccess.IGetClientSuccess => {
    return {
        type: CLIENT_CONSTANTS.GET_CLIENT_SUCCESS,
        data
    };
};
//---------------------------------- FETCH ACTIVE CATEGORIES FOR CATALOG --------------------------------------------------
const postOtpBegin = (): types.IPostOtpBegin => {
    return {
        type: OTP_CONSTANTS.POST_OTP_BEGIN
    };
}

const postOtpError = (e: any): types.IPostOtpError => {
    return {
        type: OTP_CONSTANTS.POST_OTP_ERROR,
        error: e
    };
}

const postOtpSuccess = (data: any): types.IPostOtpSuccess => {
    return {
        type: OTP_CONSTANTS.POST_OTP_SUCCESS,
        data
    };
}

export const postOtp = (NIP: number, clientRef: string) => {
    return (dispatch: Dispatch) => {
        dispatch(postOtpBegin());
        const payload = { NIP, clientRef }
        API.postOtp(payload)
            .then((response: any) => {
                dispatch(getClientSuccess(response.data.client));
                dispatch(postOtpSuccess(response.data.otp));
            }).catch((error: any) => {
                if (error.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = "/login";
                }
                dispatch(postOtpError(error.response));
            });
    }
}

//----------------------------------GET OTP OF A CUSTOMER --------------------------------------------------
const getOtpBegin = (): types.IGetOtpBegin => {
    return {
        type: OTP_CONSTANTS.GET_OTP_BEGIN
    };
}

const getOtpError = (e: any): types.IGetOtpError => {
    return {
        type: OTP_CONSTANTS.GET_OTP_ERROR,
        error: e
    };
}

const getOtpSuccess = (data: any): types.IGetOtpSuccess => {
    return {
        type: OTP_CONSTANTS.GET_OTP_SUCCESS,
        data
    };
}

export const getOtp = (userRef: string) => {
    return (dispatch: Dispatch) => {
        dispatch(getOtpBegin());
        API.getOtp(userRef)
            .then((response: any) => {
                dispatch(getOtpSuccess(response.data));
            }).catch((error: any) => {
                if (error.status === 401 || error.message === 'Unauthorized') {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = "/login";
                }
                dispatch(getOtpError(error.response));
            });
    }
}

//---------------------------------- Define steps to send the email --------------------------------------------------
const sendOtpBegin = (): types.ISendOtpBegin => {
    return {
        type: OTP_CONSTANTS.SEND_OTP_BEGIN
    };
}

const sendOtpSuccess = (): types.ISendOtpSuccess => {
    return {
        type: OTP_CONSTANTS.SEND_OTP_SUCCESS
    };
}

export const sendOtp = (NIP: string, email: string, name: string, lastName1: string, lastName2: string) => {
    return (dispatch: Dispatch) => {
        dispatch(sendOtpBegin());
        dispatch(sendOtpSuccess());
        sendEmail(email, NIP, name, lastName1, lastName2).then((response) => {
            return true;
        }).catch((error) => {
            return false;
        })
    }
}

//---------------------------------- Define steps to send the email Renew password --------------------------------------------------
const sendOtpRenewPasswordBegin = (): types.ISendOtpBegin => {
    return {
        type: OTP_CONSTANTS.SEND_OTP_BEGIN
    };
}

const sendOtpRenewPasswordSuccess = (): types.ISendOtpSuccess => {
    return {
        type: OTP_CONSTANTS.SEND_OTP_SUCCESS
    };
}

export const sendOtpPassword = (NIP: string, email: string) => {
    return (dispatch: Dispatch) => {
        dispatch(sendOtpRenewPasswordBegin());
        dispatch(sendOtpRenewPasswordSuccess());
        sendRenewPasswordEmail(email, NIP).then((response) => {
            return true;
        }).catch((error) => {
            return false;
        })
    }
}

const resetStatus = () => {
    return {
        type: OTP_CONSTANTS.RESET_OTP_STATUS
    }
}

export const resetOtpStatus = () => {
    return (dispatch: Dispatch) => {
        dispatch(resetStatus());
    }
}

//---------------------------------- Define steps to send the email Request --------------------------------------------------
const sendOtpBeginRequest = (): types.ISendOtpBegin => {
    return {
        type: OTP_CONSTANTS.SEND_OTP_BEGIN
    };
}

const sendOtpSuccessRequest = (): types.ISendOtpSuccess => {
    return {
        type: OTP_CONSTANTS.SEND_OTP_SUCCESS
    };
}

export const sendOtpEmailRequest = (NIP: string, email: string, name: string, lastName1: string, lastName2: string) => {
    return (dispatch: Dispatch) => {
        dispatch(sendOtpBeginRequest());
        dispatch(sendOtpSuccessRequest());
        sendEmailRequest(email, NIP, name, lastName1, lastName2).then((response) => {
            return true;
        }).catch((error) => {
            return false;
        })
    }
}

//---------------------------------- Define steps to send the email Documents Approved --------------------------------------------------
const sendDocumentsApproved = (): types.ISendOtpBegin => {
    return {
        type: OTP_CONSTANTS.SEND_OTP_BEGIN
    };
}

const sendSuccessDocumentsApproved = (): types.ISendOtpSuccess => {
    return {
        type: OTP_CONSTANTS.SEND_OTP_SUCCESS
    };
}

export const sendEmailDocumentsApproved = (email: string, name: string, lastName1: string, lastName2: string) => {
    return (dispatch: Dispatch) => {
        dispatch(sendDocumentsApproved());
        dispatch(sendSuccessDocumentsApproved());
        sendEmailApprovedDocuments(email, name, lastName1, lastName2).then((response) => {
            return true;
        }).catch((error) => {
            return false;
        })
    }
}

//---------------------------------- Define steps to send the email Documents Request --------------------------------------------------
const sendDocumentsRequest = (): types.ISendOtpBegin => {
    return {
        type: OTP_CONSTANTS.SEND_OTP_BEGIN
    };
}

const sendSuccessDocumentsRequest = (): types.ISendOtpSuccess => {
    return {
        type: OTP_CONSTANTS.SEND_OTP_SUCCESS
    };
}

export const sendEmailDocumentsRequest = (email: string, name: string, lastName1: string, lastName2: string) => {
    return (dispatch: Dispatch) => {
        dispatch(sendDocumentsRequest());
        dispatch(sendSuccessDocumentsRequest());
        sendEmailRequestDocuments(email, name, lastName1, lastName2).then((response) => {
            return true;
        }).catch((error) => {
            return false;
        })
    }
}
