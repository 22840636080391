import * as React from 'react';
import { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Card, Col, Dropdown, Button } from 'react-bootstrap';
import { BsWhatsapp } from 'react-icons/bs';
import NavGroup from './NavGroup';
import DEMO from '../../../../store/constant';
import * as actionTypes from '../../../../store/actions';
import { useSelector } from '../../../../store/reducer';
import { MenuItemType } from '../../../../menu-items';
import whatsappLogo from '../../../../assets/images/whatsappLogo.png';
import { logout } from '../../../../flux/actions/auth-actions';

interface NavContentProps {
    navigation: MenuItemType[];
}
const NavContent = (props: NavContentProps) => {

    const dispatch = useDispatch();
    const user = useSelector((state: any) => state.authReducer.user);
    const client = useSelector((state: any) => state.clientReducer.client);
    const collapseMenu = useSelector((state) => state.able.collapseMenu);
    const layout = useSelector((state) => state.able.layout);
    const rtlLayout = useSelector((state) => state.able.rtlLayout);
    const onNavContentLeave = () => dispatch({ type: actionTypes.NAV_CONTENT_LEAVE });
    const [data, setData] = useState({
        scrollWidth: 0,
        prevDisable: true,
        nextDisable: false
    });

    const scrollPrevHandler = () => {
        const sidenavWrapper = document.getElementById('sidenav-wrapper');
        if (sidenavWrapper) {
            const wrapperWidth = sidenavWrapper.clientWidth;
            let scrollWidth = data.scrollWidth - wrapperWidth;
            if (scrollWidth < 0) {
                setData({ ...data, scrollWidth: 0, prevDisable: true, nextDisable: false });
            } else {
                setData({ ...data, scrollWidth: scrollWidth, prevDisable: false });
            }
        }
    };
    const scrollNextHandler = () => {
        const sidenavWrapper = document.getElementById('sidenav-wrapper');
        const sidenavHorizontal = document.getElementById('sidenav-horizontal');
        if (sidenavWrapper && sidenavHorizontal) {
            const wrapperWidth = sidenavWrapper.clientWidth;
            const contentWidth = sidenavHorizontal.clientWidth;
            let scrollWidth = data.scrollWidth + (wrapperWidth - 80);
            if (scrollWidth > contentWidth - wrapperWidth) {
                scrollWidth = contentWidth - wrapperWidth + 80;
                setData({ ...data, scrollWidth: scrollWidth, prevDisable: false, nextDisable: true });
            } else {
                setData({ ...data, scrollWidth: scrollWidth, prevDisable: false });
            }
        }
    };
    const navItems = props.navigation.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} group={item} />;
            default:
                return false;
        }
    });
    let scrollStyle: { marginLeft?: string; marginRight?: string } = {
        marginLeft: '-' + data.scrollWidth + 'px'
    };
    if (layout === 'horizontal' && rtlLayout) {
        scrollStyle = {
            marginRight: '-' + data.scrollWidth + 'px'
        };
    }
    let mainContent: React.ReactNode;
    if (layout === 'horizontal') {
        let prevClass = ['sidenav-horizontal-prev'];
        if (data.prevDisable) {
            prevClass = [...prevClass, 'disabled'];
        }
        let nextClass = ['sidenav-horizontal-next'];
        if (data.nextDisable) {
            nextClass = [...nextClass, 'disabled'];
        }
        mainContent = (
            <div className="navbar-content sidenav-horizontal" id="layout-sidenav">
                <a href={DEMO.BLANK_LINK} className={prevClass.join(' ')} onClick={scrollPrevHandler}>
                    <span />
                </a>
                <div id="sidenav-wrapper" className="sidenav-horizontal-wrapper">
                    <ul
                        id="sidenav-horizontal"
                        className="nav pcoded-inner-navbar sidenav-inner"
                        onMouseLeave={onNavContentLeave}
                        style={scrollStyle}
                    >
                        {navItems}
                    </ul>
                </div>
                <a href={DEMO.BLANK_LINK} className={nextClass.join(' ')} onClick={scrollNextHandler}>
                    <span />
                </a>
            </div>
        );
    } else {
        if (Object.keys(client ? client : {}).length >= 1) {
            mainContent = (
                <div className="navbar-content next-scroll panel-navbar">
                    <PerfectScrollbar options={{ wheelSpeed: 2, swipeEasing: true }}>
                        <ul className="nav pcoded-inner-navbar" id="nav-ps-next">
                            {!collapseMenu &&
                                <li className="nav-item">
                                    <Col md={12} className="text-center mt-5">
                                        <h5>
                                            {Object.keys(user ? user : {}).length !== 0 ? user.names : Object.keys(client).length !== 0 ? client.names : " "}{" "}
                                            {Object.keys(user ? user : {}).length !== 0 ? user.lastName1 : Object.keys(client).length !== 0 ? client.lastName1 : " "}</h5>
                                    </Col>
                                </li>
                            }
                            {navItems}
                            <li className="nav-item" onClick={() => dispatch(logout())}>
                                <a className="nav-link" target="" href="/login">
                                    <span className="pcoded-micon">
                                        <i className="feather icon-power" />
                                    </span>
                                    <span className="pcoded-mtext">
                                        Cerrar Sesión
                                    </span>
                                </a>
                            </li>
                            <li>
                                <Card className="text-center panel-whatsapp">
                                    <Button
                                        className="btn-border-radius panel-whatsapp-btn"
                                        style={{ background: '#20c997', borderColor: '#20c997' }}
                                        onClick={() => window.open(`https://api.whatsapp.com/send?phone=524461442933&text=%C2%A1Hola!%20Soy%20${client.names}%20${client.lastName1}%20${client.lastName2},%20mi%20correo%20es%20${client.email}%20y%20necesito%20ayuda.`)}
                                    >
                                        <span>Soporte</span>
                                        <b>
                                            <BsWhatsapp className='onBoarding-whatsapp-icon' style={{ marginLeft: 5, marginTop: -2 }} />
                                        </b>
                                    </Button>
                                </Card>
                            </li>
                        </ul>
                    </PerfectScrollbar>
                </div>
            );
        } else {
            mainContent = (
                <div className="navbar-content next-scroll panel-navbar">
                    <PerfectScrollbar options={{ wheelSpeed: 2, swipeEasing: true }}>
                        <ul className="nav pcoded-inner-navbar" id="nav-ps-next">
                            {!collapseMenu &&
                                <li className="nav-item">
                                    <Col md={12} className="text-center mt-5">
                                        <h5>
                                            {Object.keys(user ? user : {}).length !== 0 ? user.names : Object.keys(client).length !== 0 ? client.names : " "}{" "}
                                            {Object.keys(user ? user : {}).length !== 0 ? user.lastName1 : Object.keys(client).length !== 0 ? client.lastName1 : " "}</h5>
                                    </Col>
                                </li>
                            }
                            {navItems}
                            <li className="nav-item" onClick={() => dispatch(logout())}>
                                <a className="nav-link" target="" href="/login">
                                    <span className="pcoded-micon">
                                        <i className="feather icon-power" />
                                    </span>
                                    <span className="pcoded-mtext">
                                        Cerrar Sesión
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </PerfectScrollbar>
                </div>
            );
        }
    }
    return <>{mainContent}</>;
};

export default NavContent;
