enum CREDIT_PROPOSAL {

    POST_CREDIT_PROPOSAL_BEGIN = "POST_CREDIT_PROPOSAL_BEGIN",
    POST_CREDIT_PROPOSAL_ERROR = "POST_CREDIT_PROPOSAL_ERROR",
    POST_CREDIT_PROPOSAL_SUCCESS = "POST_CREDIT_PROPOSAL_SUCCESS",

    UPDATE_CREDIT_PROPOSAL_BEGIN = "UPDATE_CREDIT_PROPOSAL_BEGIN",
    UPDATE_CREDIT_PROPOSAL_ERROR = "UPDATE_CREDIT_PROPOSAL_ERROR",
    UPDATE_CREDIT_PROPOSAL_SUCCESS = "UPDATE_CREDIT_PROPOSAL_SUCCESS",

    GET_CREDIT_PROPOSAL_BEGIN = "GET_CREDIT_PROPOSAL_BEGIN",
    GET_CREDIT_PROPOSAL_ERROR = "GET_CREDIT_PROPOSAL_ERROR",
    GET_CREDIT_PROPOSAL_SUCCESS = "GET_CREDIT_PROPOSAL_SUCCESS",

    CREDIT_PROPOSAL_RESET_STATUS = "CREDIT_PROPOSAL_RESET_STATUS"
}

export default CREDIT_PROPOSAL