import { Dispatch } from 'redux';
import { date } from '../../helpers/validations';
import { APIQ } from '../api/quote-api';
import QUOTE_CONSTANTS from '../constants/quote-constants';
import * as types from '../types/quote-types';

//---------------------------------- FETCH ACTIVE CATEGORIES FOR CATALOG --------------------------------------------------
const postQuoteBegin = (): types.IPostQuoteBegin => {
    return {
        type: QUOTE_CONSTANTS.POST_QUOTE_BEGIN
    };
}

const postQuoteError = (e: any): types.IPostQuoteError => {
    return {
        type: QUOTE_CONSTANTS.POST_QUOTE_ERROR,
        error: e
    };
}

const postQuoteSuccess = (data: any): types.IPostQuoteSuccess => {
    return {
        type: QUOTE_CONSTANTS.POST_QUOTE_SUCCESS,
        data
    };
}

export const postQuote = (payload: any) => {
    return (dispatch: Dispatch) => {
        dispatch(postQuoteBegin());
        APIQ.postQuote(payload)
            .then((response: any) => {
                dispatch(postQuoteSuccess(response.data));
            })
            .catch((error: any) => {
                dispatch(postQuoteError(error.response));
                if (error.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = "/login";
                }
            })
    };
}

//----------------------------------CREATES AN EMPTY QUOTE ----------------------------------
const postEmptyQuoteBegin = (): types.IPostEmptyQuoteBegin => {
    return {
        type: QUOTE_CONSTANTS.POST_EMPTY_QUOTE_BEGIN
    };
}

const postEmptyQuoteError = (e: any): types.IPostEmptyQuoteError => {
    return {
        type: QUOTE_CONSTANTS.POST_EMPTY_QUOTE_ERROR,
        error: e
    };
}

const postEmptyQuoteSuccess = (data: any): types.IPostEmptyQuoteSuccess => {
    return {
        type: QUOTE_CONSTANTS.POST_EMPTY_QUOTE_SUCCESS,
        data
    };
}

export const postEmptyQuote = (payload: any) => {
    return (dispatch: Dispatch) => {
        dispatch(postEmptyQuoteBegin());
        APIQ.postEmptyQuote(payload)
            .then((response: any) => {
                dispatch(postEmptyQuoteSuccess(response.data));
            })
            .catch((error: any) => {
                dispatch(postEmptyQuoteError(error.response));
                if (error.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = "/login";
                }
            })
    };
}

//----------------------------------GET ALL THE CREDITS OF A CUSTOMER --------------------------------------------------
const getCreditBegin = (): types.IGetCreditBegin => {
    return {
        type: QUOTE_CONSTANTS.GET_CREDITS_BEGIN
    };
}

const getCreditError = (e: any): types.IGetCreditError => {
    return {
        type: QUOTE_CONSTANTS.GET_CREDIT_ERROR,
        error: e
    };
}

const getCreditSuccess = (data: any): types.IGetCreditSuccess => {
    return {
        type: QUOTE_CONSTANTS.GET_CREDIT_SUCCESS,
        data
    };
}

export const getCredit = (userRef: string) => {
    return (dispatch: Dispatch) => {
        dispatch(getCreditBegin());
        APIQ.getCredits(userRef)
        .then((response: any) => {
            dispatch(getCreditSuccess(response.data));
        }).catch((error: any) => {
            if(error.status === 401 || error.message === 'Unauthorized'){
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('persist:authReducer');
                localStorage.removeItem('persist:root');
                localStorage.removeItem('nubarium:redirected:FaceCapture');
                localStorage.removeItem('nubarium:redirected:RecordMessage');
                window.location.href = "/login";
            }
            dispatch(getCreditError(error.response));
        });
    }
}

//---------------------------------- UPDATE STATUS OF A QUOTE ---------------------------------------
const updateQuoteSuccess = (data: any): types.IUpdateStatusSuccess => {
    return {
        type: QUOTE_CONSTANTS.UPDATE_STATUS_SUCCESS,
        data
    };
}

export const updateStatus = (payload: any) => {
    return (dispatch: Dispatch) => {
        dispatch(getCreditBegin());
        APIQ.udpateStatus(payload)
        .then((response: any) => {
            dispatch(updateQuoteSuccess(response.data));
        }).catch((error: any) => {
            if(error.status === 401 || error.message === 'Unauthorized'){
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('persist:authReducer');
                localStorage.removeItem('persist:root');
                localStorage.removeItem('nubarium:redirected:FaceCapture');
                localStorage.removeItem('nubarium:redirected:RecordMessage');
                window.location.href = "/login";
            }
            dispatch(getCreditError(error.response));
        });
    }
}

//---------------------------------- WITHOUT LOGIN --------------------------------------------------
const withoutlogin_postQuoteBegin = (): types.IPostQuoteBegin => {
    return {
        type: QUOTE_CONSTANTS.POST_QUOTE_BEGIN
    };
}

export const withoutlogin_postQuote = (payload: any) => {
    return (dispatch: Dispatch) => {
        dispatch(withoutlogin_postQuoteBegin());
        if (typeof Storage !== 'undefined') {
            var quoteInformation = JSON.stringify(payload.quote);
            localStorage.setItem('quoteInformation', quoteInformation);
            window.location.href = '/signup';
        } else {
            alert('LocalStorage no soportado en este navegador');
        }
    };
}

//----------------------------------GET PAGINATION THE QUOTES --------------------------------------------------
const getPaginationQuotesBegin = (): types.IGetPaginationQuotesBegin => {
    return {
        type: QUOTE_CONSTANTS.GET_QUOTES_PAGINATION_BEGIN
    };
}

const getPaginationQuotesError = (e: any): types.IGetPaginationQuotesError => {
    return {
        type: QUOTE_CONSTANTS.GET_QUOTES_PAGINATION_ERROR,
        error: e
    };
}

const getPaginastionQuotesSuccess = (data: any): types.IGetPaginationQuotesSuccess => {
    return {
        type: QUOTE_CONSTANTS.GET_QUOTES_PAGINATION_SUCCESS,
        data
    };
}

export const getPaginationQuotes = (page: any) => {
    return (dispatch: Dispatch) => {
        dispatch(getPaginationQuotesBegin());
        APIQ.getPaginationQuotes(page)
        .then((response: any) => {
            dispatch(getPaginastionQuotesSuccess(response.data));
        }).catch((error: any) => {
            if(error.status === 401){
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('persist:authReducer');
                localStorage.removeItem('persist:root');
                localStorage.removeItem('nubarium:redirected:FaceCapture');
                localStorage.removeItem('nubarium:redirected:RecordMessage');
                window.location.href = "/login";
            }
            dispatch(getPaginationQuotesError(error.response));
        });
    }
}

//---------------------------------- REJECT CREDIT REQUEST --------------------------------------------------
const rejectQuotesBegin = (): types.IRejectQuoteBegin => {
    return {
        type: QUOTE_CONSTANTS.REJECT_QUOTE_BEGIN
    };
}

const rejectQuotesError = (e: any): types.IRejectQuoteError => {
    return {
        type: QUOTE_CONSTANTS.REJECT_QUOTE_ERROR,
        error: e
    };
}

const rejectQuotesSuccess = (data: any): types.IRejectQuoteSuccess => {
    return {
        type: QUOTE_CONSTANTS.REJECT_QUOTE_SUCCESS,
        data
    };
}

export const rejectQuote = (userRef: any) => {
    return (dispatch: Dispatch) => {
        dispatch(rejectQuotesBegin());
        APIQ.rejectquote(userRef)
        .then((response: any) => {
            dispatch(rejectQuotesSuccess(response.data));
        }).catch((error: any) => {
            if(error.status === 401){
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('persist:authReducer');
                localStorage.removeItem('persist:root');
                localStorage.removeItem('nubarium:redirected:FaceCapture');
                localStorage.removeItem('nubarium:redirected:RecordMessage');
                window.location.href = "/login";
            }
            dispatch(rejectQuotesError(error.response));
        });
    }
}

//---------------------------------- ACTIONS TO FINISH THE FLOW AND CHANGE THE STATUS --------------------------------------------------
const finishFlowBegin = (): types.IFinishFlowBegin => {
    return {
        type: QUOTE_CONSTANTS.FINISH_FLOW_BEGIN
    };
}

const finishFlowError = (e: any): types.IFinishFlowError => {
    return {
        type: QUOTE_CONSTANTS.FINISH_FLOW_ERROR,
        error: e
    };
}

const finishFlowSuccess = (data: any): types.IFinishFlowSuccess => {
    return {
        type: QUOTE_CONSTANTS.FINISH_FLOW_SUCCESS,
        data
    };
}

export const finishFlow = (clientRef: any) => {
    return (dispatch: Dispatch) => {
        dispatch(finishFlowBegin());
        APIQ.finishFlow(clientRef)
        .then((response: any) => {
            dispatch(finishFlowSuccess(response.data));
        }).catch((error: any) => {
            if(error.status === 401){
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('persist:authReducer');
                localStorage.removeItem('persist:root');
                localStorage.removeItem('nubarium:redirected:FaceCapture');
                localStorage.removeItem('nubarium:redirected:RecordMessage');
                window.location.href = "/login";
            }
            dispatch(finishFlowError(error.response));
        });
    }
}

//---------------------------------- RESET QUOTE STATUS --------------------------------------------------

const resetStatus = () => {
    return {
        type: QUOTE_CONSTANTS.RESET_QUOTE_STATUS
    }
}

export const resetQuoteStatus = () => {
    return (dispatch: Dispatch) => {
        dispatch(resetStatus());
    }
}
