import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Col, Row, Modal } from 'react-bootstrap';
import { documentsDescription } from '../../../../constants/documentsDescription';

const IdCardAndCertificate: React.FC = () => {
    //const to get the params
    const files = useSelector((state: any) => state.DocumentsReducer.flowDocuments);
    //Estados de los modales
    const [showPDFModal, setShowPDFModal] = useState(false);
    const [url, setUrl] = useState<any>();

    const base64toBlob = (file: any) => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64
        var base64WithoutPrefix = file;
        if (file.includes('dataapplication/pdfbase64')) {
            base64WithoutPrefix = file.substr('dataapplication/pdfbase64'.length);
        }
        if (file.includes('dataapplication/pdfbase64')) {
            base64WithoutPrefix = file.substr('dataapplication/pdfbase64'.length);
        }
        if (file.includes('data:application/pdf;base64,')) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            base64WithoutPrefix = file.substr('data:application/pdf;base64,'.length);
        }
        const bytes = atob(file);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: 'application/pdf' });
    };

    const _renderDescription = (type: string) => {
        switch (type) {
            case 'taxpayerIDCard':
                return documentsDescription.taxpayerIDCard;
            case 'certificateESignature':
                return documentsDescription.certificateESignature;
        }
    };

    return (
        <>
            {files[0] !== undefined && (
                <Card>
                    <Card.Header className="d-flex align-items-center justify-content-between">
                        <h5 className="mb-0">Constancias y Cédulas</h5>
                    </Card.Header>
                    <Card.Body className={'border-top pro-det-edit collapse border-top pro-det-edit show'}>
                        <Row>
                            {files &&
                                // eslint-disable-next-line array-callback-return
                                files.map((file: any) => {
                                    if (file.type === 'taxpayerIDCard' || file.type === 'certificateESignature') {
                                        return (
                                            <Col className="customerProfile-files">
                                                {file.name === '' ? (
                                                    ''
                                                ) : (
                                                    <button
                                                        type="button"
                                                        className="btn btn-third btn-sm btn-border-radius float-center mt-2 doc-showBtn"
                                                        onClick={() => {
                                                            const blob = base64toBlob(file.file);
                                                            const urlAux = URL.createObjectURL(blob);
                                                            setUrl(urlAux);
                                                            setShowPDFModal(!showPDFModal);
                                                        }}
                                                    >
                                                        <i className={'feather icon-file-text'} />
                                                    </button>
                                                )}
                                                <p className="mt-3 align-middle doc-titleDocument">{_renderDescription(file.type)}</p>
                                            </Col>
                                        );
                                    }
                                })}
                        </Row>
                        <Modal size="xl" show={showPDFModal} onHide={() => setShowPDFModal(!showPDFModal)}>
                            <Modal.Header closeButton className="approvalFlow-docsModal-header">
                                <Modal.Title as="h5">Documento</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <iframe src={url} title="Documentación" height="800rem" width="100%" />
                            </Modal.Body>
                        </Modal>
                    </Card.Body>
                </Card>
            )}
        </>
    );
};
export default IdCardAndCertificate;
