import { Dispatch } from 'redux';
import { interceptor } from './../../services/interceptor';
import { API } from '../api/client-api';
import { APIQ } from '../api/quote-api';
import CLIENT_CONSTANTS from '../constants/client-constants';
import * as types from '../types/client-types';
import { postBussinessInfoSuccess } from './bussinessInfo-actions';
import { postAffiliateSuccess } from './affiliate-actions';

//---------------------------------- LOGIN --------------------------------------------------

const loginBegin = (data: any): types.ILoginBegin => {
    return {
        type: CLIENT_CONSTANTS.LOGIN_CLIENT_BEGIN,
        data
    };
};

const loginError = (e: any, data: any): types.ILoginError => {
    return {
        type: CLIENT_CONSTANTS.LOGIN_CLIENT_ERROR,
        error: e,
        data
    };
};

const loginSuccess = (data: any): types.ILoginSuccess => {
    return {
        type: CLIENT_CONSTANTS.LOGIN_CLIENT_SUCCESS,
        data
    };
};

export const login = (data: any) => {
    return (dispatch: Dispatch) => {
        dispatch(loginBegin(data));
        API.login(data)
            .then((response: any) => {
                localStorage.setItem('token', response.data.client.token);
                localStorage.setItem('refreshToken', response.data.client.refreshToken);
                interceptor.addToken(response.data.client.token);
                dispatch(loginSuccess(response.data.client));
            })
            .catch((error: any) => {
                dispatch(loginError(error.response, data));
            });
    };
};

//---------------------------------- SIGHUP --------------------------------------------------

const signupBegin = (): types.ISignupBegin => {
    return {
        type: CLIENT_CONSTANTS.SIGNUP_BEGIN
    };
};

const signupError = (e: any): types.ISignupError => {
    return {
        type: CLIENT_CONSTANTS.SIGNUP_ERROR,
        error: e
    };
};

const signupSuccess = (data: any): types.ISignupSuccess => {
    return {
        type: CLIENT_CONSTANTS.SIGNUP_SUCCESS,
        data
    };
};

export const signup = (data: any) => {
    return (dispatch: Dispatch) => {
        dispatch(signupBegin());
        API.signup(data)
            .then((response: any) => {
                interceptor.addToken(response.data.client.token);
                localStorage.setItem('token', response.data.client.token);
                localStorage.setItem('refreshToken', response.data.client.refreshToken);
                dispatch(signupSuccess(response.data));
                if (localStorage.getItem('quoteInformation')) {
                    let get_Quoter = localStorage.getItem('quoteInformation');
                    const txt_quoter: string = get_Quoter ?? '';
                    let object = JSON.parse(txt_quoter);
                    const payload: any = {
                        quote: {
                            planOption: object.planOption,
                            economicActivity: object.economicActivity.toString(),
                            amount: object.amount,
                            dateRequested: new Date(),
                            userRef: response.data.user.id,
                            monts: object.monts,
                            montlyPayment: object.montlyPayment
                        }
                    };
                    APIQ.postQuote(payload)
                        .then((response: any) => {
                            localStorage.removeItem('quoteInformation');
                        })
                        .catch((error: any) => {});
                }
            })
            .catch((error: any) => {
                dispatch(signupError(error.response));
            });
    };
};

//---------------------------------- LOGOUT --------------------------------------------------

const logoutSuccess = (): types.ILogoutSuccess => {
    return {
        type: CLIENT_CONSTANTS.LOGOUT_SUCCESS
    };
};
export const logout = () => {
    return (dispatch: Dispatch) => {
        dispatch(logoutSuccess());
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('persist:authReducer');
        localStorage.removeItem('persist:root');
        localStorage.removeItem('nubarium:redirected:FaceCapture');
        localStorage.removeItem('nubarium:redirected:RecordMessage');
        window.location.href = '/login';
    };
};

//---------------------------------- GET CLIENT BY ID --------------------------------------------------

const getClientBeggin = (): types.IGetClientBegin => {
    return {
        type: CLIENT_CONSTANTS.GET_CLIENT_BEGIN
    };
};

const getClientError = (e: any): types.IGetClientError => {
    return {
        type: CLIENT_CONSTANTS.GET_CLIENT_ERROR,
        error: e
    };
};

export const getClientSuccess = (data: any): types.IGetClientSuccess => {
    return {
        type: CLIENT_CONSTANTS.GET_CLIENT_SUCCESS,
        data
    };
};

export const getClient = (id: string) => {
    return (dispatch: Dispatch) => {
        dispatch(getClientBeggin());
        API.getClient(id)
            .then((response: any) => {
                dispatch(getClientSuccess(response.data));
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = '/login';
                }
                dispatch(getClientError(error.response));
            });
    };
};

//---------------------------------- UPDATE CLIENT INFORMATION --------------------------------------------------

const updateClientBeggin = (): types.IUpdateClientBegin => {
    return {
        type: CLIENT_CONSTANTS.UPDATE_CLIENT_BEGIN
    };
};

const updateClientError = (e: any): types.IUpdateClientError => {
    return {
        type: CLIENT_CONSTANTS.UPDATE_CLIENT_ERROR,
        error: e
    };
};

const updateClientSuccess = (data: any): types.IUpdateClientSuccess => {
    return {
        type: CLIENT_CONSTANTS.UPDATE_CLIENT_SUCCESS,
        data
    };
};

export const updateClient = (data: any) => {
    return (dispatch: Dispatch) => {
        dispatch(updateClientBeggin());
        API.editClient(data)
            .then((response: any) => {
                dispatch(updateClientSuccess(response.data));
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = '/login';
                }
                dispatch(updateClientError(error.response));
            });
    };
};

//---------------------------------- UPDATE LOCATION INFORMATION --------------------------------------------------

const updateLocationBeggin = (): types.IUpdateLocationBegin => {
    return {
        type: CLIENT_CONSTANTS.UPDATE_LOCATION_BEGIN
    };
};

const updateLocationError = (e: any): types.IUpdateLocationError => {
    return {
        type: CLIENT_CONSTANTS.UPDATE_LOCATION_ERROR,
        error: e
    };
};

const updateLocationSuccess = (data: any): types.IUpdateLocationSuccess => {
    return {
        type: CLIENT_CONSTANTS.UPDATE_LOCATION_SUCCESS,
        data
    };
};

export const updateLocation = (data: any) => {
    return (dispatch: Dispatch) => {
        dispatch(updateLocationBeggin());
        API.updateLocation(data)
            .then((response: any) => {
                dispatch(updateLocationSuccess(response.data));
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = '/login';
                }
                dispatch(updateLocationError(error.response));
            });
    };
};

/////////////// Restore Flow Information ////////////////

export const restoreFlowController = (data: any) => {
    return (dispatch: Dispatch) => {
        dispatch(updateClientBeggin());
        API.restoreFlowInfo(data)
            .then((response: any) => {
                dispatch(updateClientSuccess(response.data));
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = '/login';
                }
                dispatch(updateClientError(error.response));
            });
    };
};

/////////////// STEP BACK ON BOARDING ////////////////

const stepBackBegin = (): types.IStepBackBegin => {
    return {
        type: CLIENT_CONSTANTS.STEP_BACK_BEGIN
    };
};

const stepBackError = (e: any): types.IStepBackError => {
    return {
        type: CLIENT_CONSTANTS.STEP_BACK_ERROR,
        error: e
    };
};

const stepBackSuccess = (data: any): types.IStepBackSuccess => {
    return {
        type: CLIENT_CONSTANTS.STEP_BACK_SUCCESS,
        data
    };
};

export const stepBack = (userRef: any) => {
    return (dispatch: Dispatch) => {
        dispatch(stepBackBegin());
        API.stepBack(userRef)
            .then((response: any) => {
                dispatch(stepBackSuccess(response.data));
            })
            .catch((error: any) => {
                dispatch(stepBackError(error.response));
            });
    };
};

//----------------VALIDATION ID--------------------///
const validationIdBegin = (): types.IValidateDocumentsIDBegin => {
    return {
        type: CLIENT_CONSTANTS.VALIDATION_DOCUMENTS_ID_BEGIN
    };
};

const validationIdError = (e: any): types.IValidateDocumentsIDError => {
    return {
        type: CLIENT_CONSTANTS.VALIDATION_DOCUMENTS_ID_ERROR,
        error: e
    };
};

const validationIdSuccess = (data: any): types.IValidateDocumentsIDSuccess => {
    return {
        type: CLIENT_CONSTANTS.VALIDATION_DOCUMENTS_ID_SUCCESS,
        data
    };
};

export const validationIne = (payload: any) => {
    return (dispatch: Dispatch) => {
        dispatch(validationIdBegin());
        API.validationIne(payload)
            .then((Response: any) => {
                dispatch(validationIdSuccess(Response.data));
            })
            .catch((error: any) => {
                dispatch(validationIdError(error.response));
            });
    };
};

//----------------VALLIDATION ADDRESS--------------------///
const validationAddressBegin = (): types.IValidateDocumentsAddressBegin => {
    return {
        type: CLIENT_CONSTANTS.VALIDATION_DOCUMENTS_ADDRESS_BEGIN
    };
};

const validationAddressError = (e: any): types.IValidateDocumentsAddressError => {
    return {
        type: CLIENT_CONSTANTS.VALIDATION_DOCUMENTS_ADDRESS_ERROR,
        error: e
    };
};

const validationAddressSuccess = (data: any): types.IValidateDocumentsAddressSuccess => {
    return {
        type: CLIENT_CONSTANTS.VALIDATION_DOCUMENTS_ADDRESS_SUCCESS,
        data
    };
};

export const validationAddress = (payload: any) => {
    return (dispatch: Dispatch) => {
        dispatch(validationAddressBegin());
        API.validationAddress(payload)
            .then((Response: any) => {
                dispatch(validationAddressSuccess(Response.data));
            })
            .catch((error: any) => {
                dispatch(validationAddressError(error.response));
            });
    };
};

//----------------SAVE THE INFORMATION GET BY THE FLOW--------------------///
const saveFlowInfoBegin = (): types.ISaveFlowInfoBegin => {
    return {
        type: CLIENT_CONSTANTS.SAVE_FLOW_INFO_BEGIN
    };
};

const saveFlowInfoError = (e: any): types.ISaveFlowInfoError => {
    return {
        type: CLIENT_CONSTANTS.SAVE_FLOW_INFO_ERROR,
        error: e
    };
};

const saveFlowInfoSuccess = (data: any): types.ISaveFlowInfoSuccess => {
    return {
        type: CLIENT_CONSTANTS.SAVE_FLOW_INFO_SUCCESS,
        data
    };
};

export const saveFlowInformation = (payload: any) => {
    return (dispatch: Dispatch) => {
        dispatch(saveFlowInfoBegin());
        API.saveFlowInfo(payload)
            .then((Response: any) => {
                dispatch(saveFlowInfoSuccess(Response.data.client));
                dispatch(postBussinessInfoSuccess(Response.data.bussinessInfo));
                dispatch(postAffiliateSuccess(Response.data.affiliate));
            })
            .catch((error: any) => {
                dispatch(saveFlowInfoError(error.response));
            });
    };
};

//---------------------------------- UPDATE SCHEDULE INFORMATION --------------------------------------------------

const updateScheduleBeggin = (): types.IUpdateScheduleBegin => {
    return {
        type: CLIENT_CONSTANTS.UPDATE_SCHEDULE_BEGIN
    };
};

const updateScheduleError = (e: any): types.IUpdateScheduleError => {
    return {
        type: CLIENT_CONSTANTS.UPDATE_SCHEDULE_ERROR,
        error: e
    };
};

const updateScheduleSuccess = (data: any): types.IUpdateScheduleSuccess => {
    return {
        type: CLIENT_CONSTANTS.UPDATE_SCHEDULE_SUCCESS,
        data
    };
};

export const putUpdateSchedule = (data: any) => {
    return (dispatch: Dispatch) => {
        dispatch(updateScheduleBeggin());
        API.putUpdateSchedule(data)
            .then((response: any) => {
                dispatch(updateScheduleSuccess(response.data));
            })
            .catch((error: any) => {
                dispatch(updateScheduleError(error.response));
            });
    };
};

//----------------RESET CLIENT STATUS --------------------------------------//
const resetStatus = () => {
    return {
        type: CLIENT_CONSTANTS.RESET_CLIENT_STATUS
    };
};

export const resetClientStatus = () => {
    return (dispatch: Dispatch) => {
        dispatch(resetStatus());
    };
};
