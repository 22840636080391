import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

interface props {
    activeStep: number;
    newPerson: () => any;
    newPersonLenght: number;
}

const LegalRepresentatveStepper: FC<props> = (props) => {
    return (
        <MobileStepper
            variant="text"
            steps={props.newPersonLenght}
            position="bottom"
            activeStep={props.activeStep}
            sx={{ justifyContent: 'end', maxWidth: '100%', flexGrow: 1, textAlign: 'center' }}
            nextButton={
                <Button size="large" style={{ paddingLeft: '35px', marginRight: '100px' }} onClick={props.newPerson}>
                    <strong>
                        Agregar apoderado <PersonAddAlt1Icon />
                    </strong>
                </Button>
            }
            backButton={<></>}
        />
    );
};

export default LegalRepresentatveStepper;
