import { Dispatch } from "redux";
import { API } from "../api/creditProposal-api";
import CREDIT_PROPOSAL from "../constants/creditProposal-constants";
import * as types from '../types/creditProposal-types';

//---------------------------------- POST CREDIT_PROPOSAL --------------------------------------------------
const postCreditProposalBegin = (): types.IPostCreditProposalBegin => {
    return {
        type: CREDIT_PROPOSAL.POST_CREDIT_PROPOSAL_BEGIN,
    };
}

const  postCreditProposalError = (e: any): types.IPostCreditProposalError => {
    return {
        type: CREDIT_PROPOSAL.POST_CREDIT_PROPOSAL_ERROR,
        error: e
    };
}

const postCreditProposalSuccess = (data: any): types.IPostCreditProposalSuccess => {
    return {
        type: CREDIT_PROPOSAL.POST_CREDIT_PROPOSAL_SUCCESS,
        data
    };
}

export const creditProposal = (payload: any) => {
    return (dispatch: Dispatch) => {
        dispatch(postCreditProposalBegin());
        API.postCreditProposal(payload)
        .then((response: any) => {
            dispatch(postCreditProposalSuccess(response.data));
        }).catch((error: any) => {
            if(error.status === 401){
                localStorage.removeItem('token');
                localStorage.removeItem('persist:authReducer');
                localStorage.removeItem('persist:root');
                localStorage.removeItem('nubarium:redirected:FaceCapture');
                localStorage.removeItem('nubarium:redirected:RecordMessage');
                window.location.href = "/login";
            }
            dispatch(postCreditProposalError(error.response));
        });
    }
}

//---------------------------------- UPDATE CREDIT_PROPOSAL --------------------------------------------------
const updateCreditProposalBegin = (): types.IUpdateCreditProposalBegin => {
    return {
        type: CREDIT_PROPOSAL.UPDATE_CREDIT_PROPOSAL_BEGIN,
    };
}

const  updateCreditProposalError = (e: any): types.IUpdateCreditProposalError => {
    return {
        type: CREDIT_PROPOSAL.UPDATE_CREDIT_PROPOSAL_ERROR,
        error: e
    };
}

const updateCreditProposalSuccess = (data: any): types.IUpdateCreditProposalSuccess => {
    return {
        type: CREDIT_PROPOSAL.UPDATE_CREDIT_PROPOSAL_SUCCESS,
        data
    };
}

export const updateCreditProposal = (payload: any) => {
    return (dispatch: Dispatch) => {
        dispatch(updateCreditProposalBegin());
        API.updateCreditProposal(payload)
        .then((response: any) => {
            dispatch(updateCreditProposalSuccess(response.data));
        }).catch((error: any) => {
            if(error.status === 401){
                localStorage.removeItem('token');
                localStorage.removeItem('persist:authReducer');
                localStorage.removeItem('persist:root');
                localStorage.removeItem('nubarium:redirected:FaceCapture');
                localStorage.removeItem('nubarium:redirected:RecordMessage');
                window.location.href = "/login";
            }
            dispatch(updateCreditProposalError(error.response));
        });
    }
}

//---------------------------------- GET CREDIT_PROPOSAL --------------------------------------------------
const getCreditProposalBegin = (): types.IGetCreditProposalBegin => {
    return {
        type: CREDIT_PROPOSAL.GET_CREDIT_PROPOSAL_BEGIN,
    };
}

const  getCreditProposalError = (e: any): types.IGetCreditProposalError => {
    return {
        type: CREDIT_PROPOSAL.GET_CREDIT_PROPOSAL_ERROR,
        error: e
    };
}

const getCreditProposalSuccess = (data: any): types.IGetCreditProposalSuccess => {
    return {
        type: CREDIT_PROPOSAL.GET_CREDIT_PROPOSAL_SUCCESS,
        data
    };
}

export const getCreditProposal = (id: string) => {
    return (dispatch: Dispatch) => {
        dispatch(getCreditProposalBegin());
        API.getCreditProposal(id)
        .then((response: any) => {
            dispatch(getCreditProposalSuccess(response.data));
        }).catch((error: any) => {
            if(error.status === 401){
                localStorage.removeItem('token');
                localStorage.removeItem('persist:authReducer');
                localStorage.removeItem('persist:root');
                localStorage.removeItem('nubarium:redirected:FaceCapture');
                localStorage.removeItem('nubarium:redirected:RecordMessage');
                window.location.href = "/login";
            }
            dispatch(getCreditProposalError(error.response));
        });
    }
}

//---------------------------------- ACTIONS TO RESET CREDIT PROPOSAL --------------------------------------------------
const resetStatus = () => {
    return {
        type: CREDIT_PROPOSAL.CREDIT_PROPOSAL_RESET_STATUS
    }
}

export const resetCreditProposal = () => {
    return (dispatch: Dispatch) => {
        dispatch(resetStatus());
    }
}
