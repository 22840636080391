import * as React from 'react';
import { NavLink } from 'react-router-dom';

import '../../assets/scss/style.scss';
import img404 from '../../assets/images/maintenance/404.png';

const Error = () => {
    return (
        <>
            <div className="auth-wrapper maintenance">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="text-center">
                            <img src={img404} alt="" className="img-fluid" />
                            <h5 className="text-muted mb-4">Oops! Pagina no encontrada!</h5>
                            <NavLink to="/usuario/inicio" className="btn btn-primary text-white mb-4 btn-border-radius">
                                <i className="feather icon-refresh-ccw mr-2" />
                                Recargar!
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Error;
