enum FLUX_AUTH_CONSTANTS {
    LOGIN_BEGIN = 'LOGIN_BEGIN',
    LOGIN_ERROR = 'LOGIN_ERROR',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',

    CREATE_USER_BEGIN = 'CREATE_USER_BEGIN',
    CREATE_USER_ERROR = 'CREATE_USER_BEGIN_ERROR',
    CREATE_USER_SUCCESS = 'CREATE_USER_BEGIN_SUCCESS',

    LOGOUT_BEGIN = 'LOGOUT_BEGIN',
    LOGOUT_ERROR = 'LOGOUT_ERROR',
    LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',

    CLEAR_AUTH_LOADING = 'CLEAR_AUTH_LOADING',

    GET_USERS_BEGIN = 'GET_USERS_BEGIN',
    GET_USERS_ERROR = 'GET_USERS_ERROR',
    GET_USERS_SUCCESS = 'GET_USERS_SUCCESS',

    GET_USER_BEGIN = 'GET_USER_BEGIN',
    GET_USER_ERROR = 'GET_USER_ERROR',
    GET_USER_SUCCESS = 'GET_USER_SUCCESS',

    UPDATE_USER_BEGIN = 'UPDATE_USER_BEGIN ',
    UPDATE_USER_ERROR = 'UPDATE_USER_ERROR',
    UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',

    RENEW_PASSWORD_BEGIN = 'RENEW_PASSWORD_BEGIN',
    RENEW_PASSWORD_ERROR = 'RENEW_PASSWORD_ERROR',
    RENEW_PASSWORD_SUCCESS = 'RENEW_PASSWORD_SUCCESS'
}

export default FLUX_AUTH_CONSTANTS;
