import React, { FC, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { ProgressBar, Tooltip, OverlayTrigger, Card, Col, Row, Form } from 'react-bootstrap';
import '../../assets/scss/style.scss';
import validator from 'validator';
import moment from 'moment';
import { useTimer } from 'use-timer';
import { useDispatch, useSelector } from 'react-redux';
import * as Validations from '../../helpers/validations';
import { signup, resetClientStatus } from '../../flux/actions/client-actions';
import { postPreviusData } from '../../flux/actions/PreviusData-action';
import PreviusDataModel from '../../models/previus_data-model';
import { AuthReducerState } from '../../flux/reducers/auth-reducer';
import { sendOtpEmailRequest } from '../../flux/actions/otp-actions';
import logo from '../../assets/images/kualinetLogo.png';
import Loader from '../../components/ScreenLoader/ScreenLoader';
import Slider from '../../components/Slider';
import DateInput from '../../components/DateInput';

const cryptoJS = require('crypto-js');

const SignUp: FC = () => {
    const history = useHistory();
    const dispatcher = useDispatch();
    //Redux variables
    const client = useSelector((state: any) => state.clientReducer.client);
    const clientReducer = useSelector((state: any) => state.clientReducer);

    //Local Variables
    const [names, setNames] = useState('');
    const [lastName1, setlastName1] = useState('');
    const [lastName2, setlastName2] = useState('');
    const [phoneNumber, setPhoneNuber] = useState('');
    const [email, setEmail] = useState('');
    let [date, setDate]: any = useState(' ');
    const [CURP, setCURP] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordConditions, setPasswordConditions] = useState(false);
    const [passwordProgress, setPasswordProgress] = useState(0);
    const [NIP, setNIP] = useState('');
    const [resendNIP, setResendNIP] = useState(false);
    const [isOtpSended, setIsOtpSended] = useState(false);
    const [showSteps, setShowStep] = useState(0);
    const [OTP, setOTP] = useState('');
    const [termnsConditions, setTermnsConditions] = useState(false);
    const [privacityNotice, setPrivacityNotice] = useState(false);

    //Errors to show
    const [errors, setErrors] = useState({
        names: '',
        lastName1: '',
        lastName2: '',
        phoneNumber: '',
        password: '',
        email: '',
        confirmPassword: '',
        birthDate: '',
        CURP: '',
        NIP: '',
        lifeTime: '',
        termnsConditions: '',
        privacityNotice: ''
    });

    useEffect(() => {
        dispatcher(resetClientStatus());
    }, [dispatcher]);

    useEffect(() => {
        if (clientReducer.errorMessage || clientReducer.curpMessage) {
            setErrors({
                ...errors,
                email: clientReducer.errorMessage && 'Este email ya ha sido registrado.',
                CURP: clientReducer.curpMessage && 'Este CURP ya ha sido registrado.'
            });
        } else {
            if (client.id) {
                history.push('/cotizador-principal');
            }
        }
    }, [client, history, clientReducer.curpMessage, clientReducer.errorMessage]);

    // Load Hero Images
    let heroImages = useRef<HTMLDivElement>(null);

    const changeHeight = (images: HTMLDivElement) => {
        if (window.innerHeight < 942) {
            images.style.height = '100%';
        } else {
            images.style.height = '100vh';
        }
    };

    const changeHeightDefault = (images: HTMLDivElement) => {
        if (window.innerHeight < 921) {
            images.style.height = '100%';
        } else {
            images.style.height = '100vh';
        }
    };

    //Function to display the extra information about the password, and handle the password progress to show at the customer how strong it is the password
    useEffect(() => {
        async function fetchData() {
            const validation = await validator.isStrongPassword(password, { returnScore: true });
            setPasswordProgress(Number(validation) * 2);
        }
        if (password !== '') {
            setPasswordConditions(true);
            if (null !== heroImages.current) {
                changeHeight(heroImages.current);
            }
            fetchData();
        } else {
            setPasswordConditions(false);
            if (null !== heroImages.current) {
                changeHeightDefault(heroImages.current);
            }
        }
    }, [password]);

    //local function to validate with a normal expression the CURP
    const validateCURP = (curp: string) => {
        curp.toUpperCase();
        var re =
                /^[A-Z]{4}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/,
            validado = curp.match(re);

        if (!validado)
            //Coincide con el formato general?
            return false;
        return true; //Validado
    };

    //shipping and pin generation
    const generateOTP = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const errorName = names === '' ? 'Campo requerido' : '';
        const errorLastName1 = lastName1 === '' ? 'Campo requerido' : '';
        const errorLastName2 = lastName2 === '' ? 'Campo requerido' : '';
        const emailErrors = validator.isEmail(email) ? '' : 'Correo inválido';
        const errorPhone = validator.isMobilePhone(phoneNumber, 'es-MX') ? '' : 'Teléfono inválido';
        const privacityNoticeError = privacityNotice ? '' : 'No se han aceptado los avisos de privacidad';

        setErrors({
            ...errors,
            email: emailErrors,
            names: errorName,
            phoneNumber: errorPhone,
            lastName1: errorLastName1,
            lastName2: errorLastName2,
            privacityNotice: privacityNoticeError
        });

        if (emailErrors || errorName || errorPhone || errorLastName1 || errorLastName2 || privacityNoticeError) return;

        start();
        var digits = '0123456789';
        let OneTimePassword = '';
        for (let i = 0; i < 6; i++) {
            OneTimePassword += digits[Math.floor(Math.random() * 10)];
        }

        let cipherOTP = cryptoJS.AES.encrypt(OneTimePassword, '9xNPoxyu').toString();

        setOTP(OneTimePassword);
        setIsOtpSended(!isOtpSended);
        setShowStep(1);
        dispatcher(sendOtpEmailRequest(cipherOTP, email, names, lastName1, lastName2));
    };

    //shipping and pin generation
    const resendOTP = async () => {
        start();
        var digits = '0123456789';
        let OneTimePassword = '';
        for (let i = 0; i < 6; i++) {
            OneTimePassword += digits[Math.floor(Math.random() * 10)];
        }

        let cipherOTP = cryptoJS.AES.encrypt(OneTimePassword, '9xNPoxyu').toString();

        setOTP(OneTimePassword);
        dispatcher(sendOtpEmailRequest(cipherOTP, email, names, lastName1, lastName2));
    };

    const { time, start, pause, reset, status } = useTimer({
        initialTime: 120,
        autostart: false,
        endTime: 0,
        onTimeOver: () => {
            setResendNIP(true);
        },
        timerType: 'DECREMENTAL'
    });

    //Function to save previous data
    const createPreviusData = () => {
        const payload = {
            names,
            lastName1,
            lastName2,
            email,
            phoneNumber,
            NIP: NIP
        };
        const NIPError = NIP === OTP ? '' : 'El NIP no coincide';
        const lifeTimeError = !resendNIP ? '' : 'El NIP caducó';
        setErrors({ ...errors, NIP: NIPError, lifeTime: lifeTimeError });

        if (NIPError || lifeTimeError) return;
        dispatcher(postPreviusData(payload));
        setShowStep(2);
    };

    //Main function, we verify each input, validate some specific information like the Phone, the Curp, email, password and others.
    //If we have any error we display it, else we send the petition to our API with the necessary information
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const range = moment().diff(date, 'years');
        const errorName = names === '' ? 'Campo requerido' : '';
        const errorlastName1 = lastName1 === '' ? 'Campo requerido' : '';
        const errorlastName2 = lastName2 === '' ? 'Campo requerido' : '';
        const errorEmail = validator.isEmail(email) ? '' : 'Correo inválido';
        const errorBirthday = range >= 18 ? '' : 'No eres mayor de edad';
        const errorPhone = validator.isMobilePhone(phoneNumber, 'es-MX') ? '' : 'Teléfono inválido';
        const errorPwd = validator.isStrongPassword(password) ? '' : 'Contraseña inválida';
        const errorConfirmPwd = Validations.matchPasswords(password, confirmPassword) ? '' : 'La contraseña no coincide';
        const errorCURP = validateCURP(CURP) ? '' : 'CURP no válida';
        const termsConditionsError = termnsConditions ? '' : 'No se han aceptado los términos y condiciones';

        setErrors({
            ...errors,
            names: errorName,
            email: errorEmail,
            birthDate: errorBirthday,
            phoneNumber: errorPhone,
            password: errorPwd,
            confirmPassword: errorConfirmPwd,
            lastName1: errorlastName1,
            lastName2: errorlastName2,
            CURP: errorCURP,
            termnsConditions: termsConditionsError
        });

        const correctedPassword = password.trim();
        const correctedEmail = email.trim();
        const correctedconfirmPassword = confirmPassword.trim();

        if (
            errorName ||
            errorEmail ||
            errorBirthday ||
            errorPhone ||
            errorConfirmPwd ||
            errorPwd ||
            errorlastName1 ||
            errorlastName2 ||
            errorCURP ||
            termsConditionsError
        )
            return;
        dispatcher(
            signup({
                client: {
                    names,
                    lastName1,
                    lastName2,
                    email: correctedEmail,
                    phone: phoneNumber,
                    password: correctedPassword,
                    confirmPassword: correctedconfirmPassword,
                    birthDate: date,
                    CURP,
                    termnsConditions,
                    role: 'customer'
                }
            })
        );
    };

    //Date Input
    let inputDatePicker = useRef<HTMLInputElement>(null);
    let inputDatePlaceHolder = useRef<HTMLInputElement>(null);

    const hiddenInputDate = (datePicker: HTMLInputElement, datePlaceHolder: HTMLInputElement) => {
        datePlaceHolder.style.display = 'none';
        datePicker.style.display = 'block';
    };

    if (clientReducer.loading) {
        return (
            <div className="auth-wrapper align-items-center">
                <Loader isOpen={clientReducer.loading} />
            </div>
        );
    }

    return (
        <>
            {showSteps === 2 && (
                <div className="signup step2">
                    <div className="signup-hero" ref={heroImages}>
                        <Slider />
                    </div>
                    <div className="signup-container">
                        <div className="signup-content">
                            <img src={logo} alt="logo" className="signup-logo" />
                            <h4 className="signup-title">Crear tu perfil</h4>
                            <form onSubmit={onSubmit} className="signup-form">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="Name"
                                        placeholder="Nombre (s)"
                                        name="disabledCP"
                                        value={names}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setNames(e.target.value);
                                        }}
                                    />
                                    {errors.names && (
                                        <p id="namesHelpBlock" className="form-text text-left text-danger">
                                            {errors.names}
                                        </p>
                                    )}
                                </div>
                                <div className="form-group-flex">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="Lastnname1"
                                            placeholder="Apellido paterno"
                                            name="disabledCP"
                                            value={lastName1}
                                            autoComplete="off"
                                            onChange={(e) => {
                                                setlastName1(e.target.value);
                                                setErrors({ ...errors, lastName1: '' });
                                            }}
                                        />
                                        {errors.lastName1 && (
                                            <p id="lastName1" className="form-text text-left text-danger">
                                                {errors.lastName1}
                                            </p>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="Lastnname2"
                                            placeholder="Apellido materno"
                                            name="disabledCP"
                                            value={lastName2}
                                            autoComplete="off"
                                            onChange={(e) => {
                                                setlastName2(e.target.value);
                                                setErrors({ ...errors, lastName2: '' });
                                            }}
                                        />
                                        {errors.lastName2 && (
                                            <p id="lastName2" className="form-text text-left text-danger">
                                                {errors.lastName2}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        placeholder="Correo electrónico"
                                        name="disabledCP"
                                        value={email}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setEmail(e.target.value.trim());
                                            setErrors({ ...errors, email: '' });
                                        }}
                                    />
                                    {errors.email && (
                                        <p id="passwordHelpBlock" className="form-text text-left text-danger">
                                            {errors.email}
                                        </p>
                                    )}
                                </div>
                                <div
                                    className="form-group-flex"
                                    onClick={() => {
                                        if (null !== inputDatePicker.current && null !== inputDatePlaceHolder.current) {
                                            hiddenInputDate(inputDatePicker.current, inputDatePlaceHolder.current);
                                        }
                                    }}
                                >
                                    <div className="form-group">
                                        <DateInput
                                            label={errors.birthDate ? ' ' : 'Fecha de nacimiento'}
                                            onChange={(e: any) => {
                                                setDate(e.toString());
                                                setErrors({ ...errors, birthDate: '' });
                                            }}
                                        />
                                        {errors.birthDate && (
                                            <p id="passwordHelpBlock" className="form-text text-left text-danger">
                                                {errors.birthDate}
                                            </p>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="cellphone"
                                            placeholder="Teléfono celular"
                                            name="disabledCP"
                                            value={phoneNumber}
                                            maxLength={10}
                                            autoComplete="off"
                                            onChange={(e) => {
                                                setPhoneNuber(e.target.value.trim());
                                                setErrors({ ...errors, phoneNumber: '' });
                                            }}
                                        />
                                        {errors.phoneNumber && (
                                            <p id="passwordHelpBlock" className="form-text text-left text-danger">
                                                {errors.phoneNumber}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="CURP"
                                        placeholder="CURP"
                                        name="disabledCP"
                                        autoComplete="off"
                                        maxLength={18}
                                        style={{ textTransform: 'uppercase' }}
                                        value={CURP}
                                        onChange={(e) => {
                                            setCURP(e.target.value.trim().toLocaleUpperCase());
                                            setErrors({ ...errors, CURP: '' });
                                        }}
                                    />
                                    <OverlayTrigger
                                        placement="left"
                                        overlay={
                                            <Tooltip id={`tooltip-left`} className="text-center">
                                                <h5 className="my-auto text-white">Da clic para consultar tu CURP</h5>
                                            </Tooltip>
                                        }
                                    >
                                        <Card className="bg-info">
                                            <a href="https://www.gob.mx/curp" target="__blank">
                                                <b className="my-auto">?</b>
                                            </a>
                                        </Card>
                                    </OverlayTrigger>
                                    {errors.CURP && <p className="form-text text-left text-danger">{errors.CURP}</p>}
                                </div>
                                <div className="form-group">
                                    <input
                                        type="password"
                                        className="form-control"
                                        name="disabledCP"
                                        id="password"
                                        placeholder="Contraseña"
                                        value={password}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setPassword(e.target.value.trim());
                                            setErrors({ ...errors, password: '' });
                                        }}
                                    />
                                    {errors.password && (
                                        <p id="passwordHelpBlock" className="form-text text-left text-danger">
                                            {errors.password}
                                        </p>
                                    )}
                                    {passwordConditions && (
                                        <div className="signup-success">
                                            <p>Tu contraseña debe contener:</p>
                                            <ProgressBar variant="success" now={passwordProgress} />
                                            <ul>
                                                <li>8 caracteres</li>
                                                <li>Una minúscula (a-z)</li>
                                                <li>Una mayúscula (A-Z)</li>
                                                <li>Un número (0-9)</li>
                                                <li>Un caracter especial (*?$)</li>
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <input
                                        type="password"
                                        className="form-control ml-1"
                                        id="confirm password"
                                        name="disabledCP"
                                        placeholder="Confirmar contraseña"
                                        value={confirmPassword}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setConfirmPassword(e.target.value.trim());
                                            setErrors({ ...errors, confirmPassword: '' });
                                        }}
                                    />
                                    {errors.confirmPassword && (
                                        <p id="passwordHelpBlock" className="form-text text-left text-danger">
                                            {errors.confirmPassword}
                                        </p>
                                    )}
                                </div>
                                <Form.Group className="d-flex flex-row text-center mt-4 justify-content-center onBoarding-otp-termnsConditions">
                                    <Form.Check
                                        custom
                                        type="checkbox"
                                        id="supported-checkbox"
                                        checked={termnsConditions}
                                        onChange={() => {
                                            setTermnsConditions(!termnsConditions);
                                        }}
                                    />
                                    <Form.Label>
                                        Estoy de acuerdo con los
                                        <a
                                            href="https://www.kualinet.com/strongaviso-legal-de-terminos-y-condiciones-de-uso-del-sitio-web-de-kualinet-s-a-p-i-de-c-v-sofom-e-n-r-strong/"
                                            target="__blank"
                                            className="f-w-400 ml-1 mr-1"
                                        >
                                            Términos y Condiciones
                                        </a>
                                        y cláusulas de medios electrónicos tales como NIP.
                                    </Form.Label>
                                    {errors.termnsConditions && (
                                        <p id="passwordHelpBlock" className="form-text text-danger formAlert">
                                            {errors.termnsConditions}
                                        </p>
                                    )}
                                </Form.Group>
                                <button className="signup-button" type="submit">
                                    <b>Crear cuenta</b>
                                </button>
                                <div className="signup-buttonSignup" onClick={() => history.push('/login')}>
                                    <b>Iniciar sesión</b>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
            {showSteps === 0 && (
                <div className="signup step0">
                    <div className="signup-hero">
                        <Slider />
                    </div>
                    <div className="signup-container">
                        <div className="signup-content">
                            <img src={logo} alt="logo" className="signup-logo" />
                            <h4 className="signup-title">Solicitud de crédito</h4>
                            <form className="signup-form" onSubmit={generateOTP}>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="Name"
                                        placeholder="Nombre (s)"
                                        name="disabledCP"
                                        value={names}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setNames(e.target.value);
                                        }}
                                    />
                                    {errors.names && (
                                        <p id="namesHelpBlock" className="form-text text-left text-danger">
                                            {errors.names}
                                        </p>
                                    )}
                                </div>
                                <div className="form-group-flex">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="Lastnname1"
                                            placeholder="Apellido paterno"
                                            name="disabledCP"
                                            value={lastName1}
                                            autoComplete="off"
                                            onChange={(e) => {
                                                setlastName1(e.target.value);
                                                setErrors({ ...errors, lastName1: '' });
                                            }}
                                        />
                                        {errors.lastName1 && (
                                            <p id="lastName1" className="form-text text-left text-danger">
                                                {errors.lastName1}
                                            </p>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="Lastnname2"
                                            placeholder="Apellido materno"
                                            name="disabledCP"
                                            value={lastName2}
                                            autoComplete="off"
                                            onChange={(e) => {
                                                setlastName2(e.target.value);
                                                setErrors({ ...errors, lastName2: '' });
                                            }}
                                        />
                                        {errors.lastName2 && (
                                            <p id="lastName2" className="form-text text-left text-danger">
                                                {errors.lastName2}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        placeholder="Correo electrónico"
                                        name="disabledCP"
                                        value={email}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setEmail(e.target.value.trim());
                                            setErrors({ ...errors, email: '' });
                                        }}
                                    />
                                    {errors.email && (
                                        <p id="passwordHelpBlock" className="form-text text-left text-danger">
                                            {errors.email}
                                        </p>
                                    )}
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="cellphone"
                                        placeholder="Teléfono celular"
                                        name="disabledCP"
                                        value={phoneNumber}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setPhoneNuber(e.target.value.trim());
                                            setErrors({ ...errors, phoneNumber: '' });
                                        }}
                                    />
                                    {errors.phoneNumber && (
                                        <p id="passwordHelpBlock" className="form-text text-left text-danger">
                                            {errors.phoneNumber}
                                        </p>
                                    )}
                                </div>
                                <Form.Group className="d-flex flex-row text-center mt-4 justify-content-left onBoarding-otp-termnsConditions">
                                    <Form.Check
                                        custom
                                        type="checkbox"
                                        id="supported-checkbox"
                                        checked={privacityNotice}
                                        onChange={() => {
                                            setPrivacityNotice(!privacityNotice);
                                            setErrors({ ...errors, privacityNotice: '' });
                                        }}
                                    />
                                    <Form.Label>
                                        Estoy de acuerdo con los
                                        <a
                                            href="https://www.kualinet.com/strongaviso-legal-de-terminos-y-condiciones-de-uso-del-sitio-web-de-kualinet-s-a-p-i-de-c-v-sofom-e-n-r-strong/"
                                            target="__blank"
                                            className="f-w-400 ml-1 mr-1"
                                        >
                                            Términos y Condiciones
                                        </a>
                                        y con el tratamiento de mis datos según el
                                        <a
                                            href="https://www.kualinet.com/avisos-de-privacidad/"
                                            target="__blank"
                                            className="f-w-400 ml-1 mr-1"
                                        >
                                            Aviso de Privacidad.
                                        </a>
                                    </Form.Label>
                                    {errors.privacityNotice && (
                                        <p id="passwordHelpBlock" className="form-text text-danger formAlert">
                                            {errors.privacityNotice}
                                        </p>
                                    )}
                                </Form.Group>
                                <button className="signup-button" type="submit">
                                    <b>Continuar</b>
                                </button>
                                <div className="signup-buttonSignup" onClick={() => history.push('/login')}>
                                    <b>
                                        <i className="feather icon-arrow-left"></i> Volver
                                    </b>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
            {showSteps === 1 && (
                <div className="signup step1">
                    <div className="signup-hero">
                        <Slider />
                    </div>
                    <div className="signup-container">
                        <div className="signup-content">
                            <img src={logo} alt="logo" className="signup-logo" />
                            <h5 className="signup-title">Se envió un NIP a tu correo</h5>
                            <div className="signup-form">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        placeholder="NIP"
                                        maxLength={6}
                                        name="disabledCP"
                                        value={NIP.toString()}
                                        className="form-control"
                                        onChange={(e) => {
                                            setNIP(e.target.value.trim());
                                            setErrors({ ...errors, NIP: '' });
                                        }}
                                    />
                                    <h5 className="signup-text">Ingresa tu NIP</h5>
                                    {errors.NIP && (
                                        <p id="passwordHelpBlock" className="form-text text-left text-danger">
                                            {errors.NIP}
                                        </p>
                                    )}
                                    {errors.lifeTime && (
                                        <p id="passwordHelpBlock" className="form-text text-left text-danger">
                                            {errors.lifeTime}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <button className="mb-4 btn-border-radius border-0 bold signup-button" onClick={createPreviusData}>
                                <b>Continuar</b>
                            </button>
                            <Row className="d-flex flex-row mt-3 mb-4 align-items-center justify-content-center">
                                {!resendNIP ? (
                                    <Col className="d-flex flex-row align-items-center justify-content-center" md={12}>
                                        <p className="signup-text">
                                            0{Math.trunc(time / 60)}:
                                            {time % 60 === 0
                                                ? '00'
                                                : time % 60 > 10
                                                ? (time % 60).toFixed(0)
                                                : '0' + (time % 60).toFixed(0)}
                                        </p>
                                    </Col>
                                ) : (
                                    <div
                                        className=" signup-textButton"
                                        onClick={() => {
                                            setResendNIP(false);
                                            resendOTP();
                                            setErrors({ ...errors, lifeTime: '' });
                                        }}
                                    >
                                        <dt>Solicitar un nuevo NIP</dt>
                                    </div>
                                )}
                            </Row>
                            <a className="signup-buttonSignup" onClick={() => history.push('/login')} href=" ">
                                Volver
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default SignUp;
