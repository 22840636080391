import axios from 'axios';

const API_URL = process.env.REACT_APP_BASE_URL;

const options = {
    headers: {
        'Content-Type': 'application/json',
        'X-Request-ID': '4c15061209f38466230d3faff1cbe847'
    }
};

const createCredentials = (payload: any) => {
    return axios.post(`${API_URL}satws/credentials`, payload, options);
}

const getCredentials = () => {
    return axios.get(`${API_URL}satws/credentials`, options);
}

export const API = {
    createCredentials,
    getCredentials
} 