import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Col, Row, Modal, Button } from 'react-bootstrap';
import validator from 'validator';
import Datetime from 'react-datetime';
import moment from 'moment';
import { postBussinessInfo } from '../../../../flux/actions/bussinessInfo-actions';
import Loader from '../../../../components/ScreenLoader/ScreenLoader';

const MoralPersonInfo: React.FC = () => {
    const dispatch = useDispatch();
    const { bussinessInfo, loading } = useSelector((state: any) => state.bussinessInfoReducer);

    //MORAL PERSON INFORMATION CONST
    const [denomination, setDenomination] = useState('');
    const [constitutionDate, setConstitutionDate] = useState<any>();
    const [RFC, setRFC] = useState('');
    const [bussinessEmail, setBussinessEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [nationality, setNationality] = useState('');

    //Constantes de Edicion sirven para habilitar y deshabilitar partes del código
    const [isMoralPersonEdit, setIsMoralPersonEdit] = useState(false);
    const [showModal, setShowModal] = useState(false);

    //Se crea y se Inicializa un objeto para los posibles errores de persona Moral
    const [errorsMoralPerson, setErrorsMoralPerson] = useState({
        denomination: '',
        constitutionDate: '',
        RFC: '',
        email: '',
        nationality: '',
        phone: ''
    });

    //Se Definen las variables de Información la persona moral cada vez que cambia el estado de moralPerson
    useEffect(() => {
        if (Object.keys(bussinessInfo).length !== 0) {
            setDenomination(bussinessInfo.denomination);
            setConstitutionDate(moment(bussinessInfo.constitutionDate).format('L'));
            setRFC(bussinessInfo.RFC);
            setBussinessEmail(bussinessInfo.email);
            setPhone(bussinessInfo.phone);
            setNationality(bussinessInfo.nationality);
        }
    }, [bussinessInfo]);

    ////////////VALIDATIONS////////////////////////
    // Validacion los los campos de input sencibles
    // Validacion de la curp solo se aceptan caracteres expecificados
    const validateRFC = (rfc: string) => {
        let valid = '^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([A-Z]|[0-9]){2}([A]|[0-9]){1})?';
        let validRfc = new RegExp(valid);
        let matchArray = rfc.match(validRfc);
        if (matchArray === null) {
            return false;
        } else {
            return true;
        }
    };

    //Funcion para actualizar la Información de la persona moral en la base de datos y manejar los posibles errores
    const saveMoralPersonInfo = () => {
        //constantes manejadoras de errrores
        const constitutionDateError = constitutionDate !== '' ? '' : 'Este campo no puede estar vacío';
        const denominationError = denomination === '' ? 'Este campo no puede estar vacío' : '';
        const errorRFC = validateRFC(RFC) ? '' : 'No es un RFC válido';
        const emailError = validator.isEmail(bussinessEmail) ? '' : 'Este no es un correo válido';
        const phoneError = validator.isMobilePhone(phone, 'es-MX') ? '' : 'Ingresar un teléfono válido';
        const nationalityError = nationality === '' ? 'Este campo no puede estar vacío' : '';
        //Se asignan posibles errores en el objeto
        setErrorsMoralPerson({
            denomination: denominationError,
            constitutionDate: constitutionDateError,
            RFC: errorRFC,
            email: emailError,
            phone: phoneError,
            nationality: nationalityError
        });

        if (constitutionDateError || denominationError || emailError || errorRFC || phoneError || nationalityError) return;
        dispatch(
            postBussinessInfo({
                clientRef: bussinessInfo.clientRef,
                denomination,
                constitution_date: constitutionDate,
                nationality: nationality,
                RFC: RFC.toUpperCase(),
                phone,
                email: bussinessEmail
            })
        );
        setShowModal(!showModal);
        setIsMoralPersonEdit(!isMoralPersonEdit);
    };

    return loading ? (
        <div className="auth-wrapper align-items-center">
            <Loader isOpen={true} />
        </div>
    ) : (
        <>
            <Card>
                <Card.Body className="d-flex align-items-center justify-content-between">
                    <h5 className="mb-0">Información Persona Moral</h5>
                    <button
                        type="button"
                        className="btn btn-primary btn-sm btn-border-radius m-0 float-right customerProfile-editBtn"
                        onClick={() => setIsMoralPersonEdit(!isMoralPersonEdit)}
                    >
                        <i className={isMoralPersonEdit ? 'feather icon-x' : 'feather icon-edit'} />
                    </button>
                </Card.Body>
                <Card.Body className={isMoralPersonEdit ? 'border-top pro-det-edit collapse' : 'border-top pro-det-edit collapse show'}>
                    <Row>
                        <Col className="form-group">
                            <label className="font-weight-bolder mt-3">Razón social: </label>
                            <p>{denomination}</p>
                        </Col>
                        <Col className="form-group">
                            <label className="font-weight-bolder mt-3">Fecha de Constitución: </label>
                            <p>{constitutionDate}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="form-group">
                            <label className="font-weight-bolder mt-3">RFC: </label>
                            <p>{RFC}</p>
                        </Col>
                        <Col className="form-group">
                            <label className="font-weight-bolder mt-3">Correo de empresa: </label>
                            <p>{bussinessEmail}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="form-group">
                            <label className="font-weight-bolder mt-3">Teléfono de la empresa: </label>
                            <p>{phone}</p>
                        </Col>
                        <Col className="form-group">
                            <label className="font-weight-bolder mt-3">Nacionalidad: </label>
                            <p>{nationality}</p>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Body className={isMoralPersonEdit ? 'border-top pro-det-edit collapse show' : 'border-top pro-det-edit collapse'}>
                    <Row className="d-flex flex-row form-group">
                        <Col>
                            <label className="font-weight-bolder">Razón social:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="denomination"
                                placeholder="Razón social"
                                value={denomination}
                                onChange={(e: any) => {
                                    setDenomination(e.target.value);
                                    setErrorsMoralPerson({ ...errorsMoralPerson, denomination: '' });
                                }}
                            />
                            {errorsMoralPerson.denomination && (
                                <p className="form-text text-left text-danger">{errorsMoralPerson.denomination}</p>
                            )}
                        </Col>
                        <Col>
                            <label className="font-weight-bolder">Fecha de Constitución: </label>
                            <Datetime
                                timeFormat={false}
                                className="pl-0"
                                inputProps={{ placeholder: 'Fecha de Constitución' }}
                                value={constitutionDate || ''}
                                onChange={(e) => {
                                    setConstitutionDate(moment(e.toString()).format('L'));
                                    setErrorsMoralPerson({ ...errorsMoralPerson, constitutionDate: '' });
                                }}
                            />
                            {errorsMoralPerson.constitutionDate && (
                                <p className="form-text text-left text-danger">{errorsMoralPerson.constitutionDate}</p>
                            )}
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Col>
                            <label className="font-weight-bolder">RFC: </label>
                            <input
                                type="text"
                                className="form-control"
                                id="RFC"
                                placeholder="RFC"
                                value={RFC}
                                maxLength={10}
                                style={{ textTransform: 'uppercase' }}
                                onChange={(e) => {
                                    validateRFC(e.target.value);
                                    setRFC(e.target.value.trim().toLocaleUpperCase());
                                    setErrorsMoralPerson({ ...errorsMoralPerson, RFC: '' });
                                }}
                            />
                            {errorsMoralPerson.RFC && <p className="form-text text-left text-danger">{errorsMoralPerson.RFC}</p>}
                        </Col>
                        <Col>
                            <label className="font-weight-bolder">Correo de la empresa: </label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="Correo Electronico dem"
                                value={bussinessEmail}
                                onChange={(e) => {
                                    setBussinessEmail(e.target.value.trim());
                                    setErrorsMoralPerson({ ...errorsMoralPerson, email: '' });
                                }}
                            />
                            {errorsMoralPerson.email && <p className="form-text text-left text-danger">{errorsMoralPerson.email}</p>}
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Col>
                            <label className="font-weight-bolder">Teléfono de la empresa:</label>
                            <input
                                type="text"
                                className="col-sm-9 form-control"
                                id="phone"
                                placeholder="Teléfono de contacto"
                                value={phone}
                                onChange={(e: any) => {
                                    setPhone(e.target.value);
                                    setErrorsMoralPerson({ ...errorsMoralPerson, phone: '' });
                                }}
                            />
                            {errorsMoralPerson.denomination && <p className="form-text text-left text-danger">{errorsMoralPerson.phone}</p>}
                        </Col>
                        <Col>
                            <label className="font-weight-bolder">Nacionalidad de la empresa:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="nationality"
                                placeholder="Nacionalidad de la persona moral"
                                value={phone}
                                onChange={(e: any) => {
                                    setNationality(e.target.value);
                                    setErrorsMoralPerson({ ...errorsMoralPerson, nationality: '' });
                                }}
                            />
                            {errorsMoralPerson.denomination && (
                                <p className="form-text text-left text-danger">{errorsMoralPerson.nationality}</p>
                            )}
                        </Col>
                    </Row>
                    <Row className="form-group mt-5">
                        <Col className="d-flex justify-content-end">
                            <button
                                type="submit"
                                className="btn btn-primary btn-border-radius customerProfile-saveBtn"
                                onClick={() => setShowModal(!showModal)}
                            >
                                Guardar
                            </button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Modal show={showModal} onHide={() => setShowModal(!showModal)}>
                <Modal.Header closeButton>
                    <Modal.Title as="h5">Actualización de información</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    La información de la persona moral ha sido modificada
                    <br />
                    <br />
                    ¿Deseas continuar con la actualización de la información del usuario?
                </Modal.Body>
                <Modal.Footer>
                    <Row className="mt-3 mb-3">
                        <Button className="btn-border-radius" variant="danger" onClick={() => setShowModal(!showModal)}>
                            Cancelar
                        </Button>
                        <Button className="ml-3 btn-border-radius" variant="secondary" onClick={saveMoralPersonInfo}>
                            Continuar
                        </Button>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default MoralPersonInfo;
