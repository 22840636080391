import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AdminLayout from '../components/home';
import Login from '../views/Login';
import Signup from '../views/Signup';
import Quoter from '../views/Quoter';
import userRoutes from './customerRoutes';
import PageNotFound from '../views/Maintenance/Error';
import RenewPassword from '../views/RenewPassord';
import DisableCopyAndPaste from '../hooks/DisableCopyAndPaste';
import AdminLogin from '../views/Login/AdminLogin';
import PreviewFlow from '../views/onBoarding2/PreviewFlow';
import DefaultFlow from '../views/onBoarding2';
import TestFlow from '../views/onBoarding2/testFlow';

const App = () => {
    return (
        <BrowserRouter>
            <DisableCopyAndPaste />
            <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/admin/login" component={AdminLogin} />
                <Route exact path="/recuperar-contraseña" component={RenewPassword} />
                <Route exact path="/signup" component={Signup} />
                <Route exact path="/flujo/:name" component={PreviewFlow} />
                <Route exact path="/prueba-flujo" component={TestFlow} />
                <Route exact path="/cotizador-principal" component={Quoter} />
                <Route exact path="/on-boarding" component={DefaultFlow} />
                <Route path={userRoutes.map((x) => x.path)}>
                    <AdminLayout />
                </Route>
                <Route component={PageNotFound} />
            </Switch>
        </BrowserRouter>
    );
};
export default App;
