import moment from 'moment';

function AmortizacionTable(months: number, amount: number, interestRate: any) {
    const interest = interestRate !== null ? Number(interestRate): 0.016666;
    const datos = [];
    
    let deuda = (Number(amount) * (interest * Math.pow(1 + interest, Number(months)))) / (Math.pow(1 + interest, Number(months)) - 1);
    let interes = 0;
    let capital = 0;
    let saldo_inicial = Number(amount);
    let saldo_final = Number(amount);
    let startdate = moment().format('DD-MM-YYYY');
    let days = 0;

    for (let cont = 1, index = 0; index < months; index++) {
        startdate = moment()
            .add((days += 30), 'days')
            .calendar();

        interes = saldo_final * interest;
        capital = deuda - interes;
        saldo_inicial = saldo_final;
        saldo_final = saldo_inicial - capital;

        datos.push({
            id: index,
            periodo: cont++,
            vencimiento: startdate,
            deuda: deuda,
            saldo_i: saldo_inicial,
            interes: interes,
            capital: capital,
            saldo_f: saldo_final
        });
    }

    return datos.map((dato) => (
        <tr className="text-center">
            <td key={dato.id}>{dato.periodo}</td>
            <td key={dato.id + 1}>{dato.vencimiento}</td>
            <td key={dato.id + 2}>
                {new Intl.NumberFormat('es-MX', {
                    style: 'currency',
                    currency: 'MXN',
                    minimumFractionDigits: 2
                }).format(dato.deuda)}
            </td>
            <td key={dato.id + 3}>
                {new Intl.NumberFormat('es-MX', {
                    style: 'currency',
                    currency: 'MXN',
                    minimumFractionDigits: 2
                }).format(dato.saldo_i)}
            </td>
            <td key={dato.id + 4}>
                {new Intl.NumberFormat('es-MX', {
                    style: 'currency',
                    currency: 'MXN',
                    minimumFractionDigits: 2
                }).format(dato.interes)}
            </td>
            <td key={dato.id + 5}>
                {new Intl.NumberFormat('es-MX', {
                    style: 'currency',
                    currency: 'MXN',
                    minimumFractionDigits: 2
                }).format(dato.capital)}
            </td>
            <td key={dato.id + 6}>
                {new Intl.NumberFormat('es-MX', {
                    style: 'currency',
                    currency: 'MXN',
                    minimumFractionDigits: 2
                }).format(dato.saldo_f)}
            </td>
        </tr>
    ));
}
export default AmortizacionTable;
