import React, { useState, useEffect } from 'react';
import { Card, Button, Table, Modal, Form, ProgressBar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers, getUser, updateUser, renewPassword } from '../../flux/actions/users-actions';
import { getAllRoles } from '../../flux/actions/role-actions';
import { createUser } from '../../flux/actions/auth-actions';
import validator from 'validator';
import * as Validations from '../../helpers/validations';
import { refreshToken } from '../../flux/actions/refreshToken-action';

const Users: React.FC = () => {
    const dispatch = useDispatch();
    const [showModalNewUser, setShowModalNewUser] = useState(false);
    const [showModalEditUser, setShowModalEditUser] = useState(false);
    const { users, user } = useSelector((state: any) => state.authReducer);
    const { roles } = useSelector((state: any) => state.roleReducer);
    //Local Variables
    const [names, setNames] = useState('');
    const [lastName1, setlastName1] = useState('');
    const [lastName2, setlastName2] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [roleOption, setRoleOption] = useState('');
    const [roleId, setRoleId] = useState('');
    const [activeUser, setActiveUser] = useState(false);
    const [passwordEdit, setPasswordEdit] = useState(false);
    const [passwordConditions, setPasswordConditions] = useState(false);
    const [passwordProgress, setPasswordProgress] = useState(0);

    useEffect(() => {
        dispatch(getUsers());
        dispatch(getAllRoles());
        dispatch(
            refreshToken({
                token: localStorage.getItem('token'),
                refreshToken: localStorage.getItem('refreshToken')
            })
        );
    }, []);

    //Errors to show
    const [errors, setErrors] = useState({
        names: '',
        lastName1: '',
        lastName2: '',
        password: '',
        email: '',
        confirmPassword: '',
        roleOption: '',
        roleId: '',
        emailExist: ''
    });

    //Function to display the extra information about the password, and handle the password progress to show at the customer how strong it is the password
    useEffect(() => {
        async function fetchData() {
            const validation = await validator.isStrongPassword(password, { returnScore: true });
            setPasswordProgress(Number(validation) * 2);
        }

        if (password !== '') {
            setPasswordConditions(true);
            fetchData();
        } else {
            setPasswordConditions(false);
        }
    }, [password]);

    //Clean Form
    const cleanForm = () => {
        setNames('');
        setlastName1('');
        setlastName2('');
        setPassword('');
        setEmail('');
        setConfirmPassword('');
        setRoleOption('');
    };

    //Create User
    const createNewUser = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const errorName = names === '' ? 'Campo requerido' : '';
        const errorlastName1 = lastName1 === '' ? 'Campo requerido' : '';
        const errorlastName2 = lastName2 === '' ? 'Campo requerido' : '';
        const errorEmail = validator.isEmail(email) ? '' : 'Correo inválido';
        const errorEmailExist =
            Array.isArray(user.user) && user.user.some((item: any) => item.email === email) ? 'Este correo ya ha sido registrado' : '';
        const errorPwd = validator.isStrongPassword(password) ? '' : 'Contraseña inválida';
        const errorConfirmPwd = Validations.matchPasswords(password, confirmPassword) ? '' : 'Las contraseñas deben coincidir';
        const errorRole = roleOption === '' ? 'Selecciona un rol' : '';

        setErrors({
            names: errorName,
            lastName1: errorlastName1,
            lastName2: errorlastName2,
            email: errorEmail,
            password: errorPwd,
            confirmPassword: errorConfirmPwd,
            roleOption: errorRole,
            emailExist: errorEmailExist,
            roleId: ''
        });

        const correctedPassword = password.trim();
        const correctedEmail = email.trim();
        const correctedconfirmPassword = confirmPassword.trim();

        if (errorName || errorEmail || errorConfirmPwd || errorPwd || errorlastName1 || errorlastName2 || errorRole || errorEmailExist)
            return;
        dispatch(
            createUser({
                user: {
                    names,
                    lastName1,
                    lastName2,
                    email: correctedEmail,
                    password: correctedPassword,
                    confirmPassword: correctedconfirmPassword,
                    role: roleOption,
                    emailExist: errorEmailExist
                }
            })
        );
        setTimeout(() => {
            dispatch(getUsers());
        }, 1000);
        setShowModalNewUser(false);
    };

    //Update User
    const updateUserInfo = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const errorName = names === '' ? 'Campo requerido' : '';
        const errorlastName1 = lastName1 === '' ? 'Campo requerido' : '';
        const errorlastName2 = lastName2 === '' ? 'Campo requerido' : '';
        const errorEmail = validator.isEmail(email) ? '' : 'Correo inválido';
        const errorRole = roleId === '' ? 'Selecciona un rol' : '';
        const errorPwd = validator.isStrongPassword(password) ? '' : 'Contraseña inválida';
        const errorConfirmPwd = Validations.matchPasswords(password, confirmPassword) ? '' : 'Las contraseñas deben coincidir';
        console.log(passwordEdit);

        setErrors({
            names: errorName,
            lastName1: errorlastName1,
            lastName2: errorlastName2,
            email: errorEmail,
            password: errorPwd,
            confirmPassword: errorConfirmPwd,
            roleOption: '',
            emailExist: '',
            roleId: errorRole
        });
        if (errorName || errorlastName1 || errorlastName1 || errorEmail || errorRole) return;
        dispatch(
            updateUser({
                user: {
                    id: user.user?.id,
                    names,
                    lastName1,
                    lastName2,
                    email,
                    active: activeUser,
                    role: roleId
                }
            })
        );
        if (passwordEdit) {
            if (errorPwd || errorConfirmPwd) return;
            dispatch(
                renewPassword({
                    user: {
                        email,
                        password
                    }
                })
            );
        }
        setTimeout(() => {
            dispatch(getUsers());
        }, 1000);
        setShowModalEditUser(false);
        setRoleId('');
    };

    return (
        <Card className="users">
            <Card.Header className="d-flex justify-content-between align-items-center pl-4 pr-4 pt-4 pb-0 border-0">
                <Card.Title className="users-title">Usuarios</Card.Title>
                <Button
                    className="users-button"
                    onClick={() => {
                        cleanForm();
                        setShowModalNewUser(true);
                    }}
                >
                    Crear Usuario <i className="feather icon-plus-circle" />
                </Button>
            </Card.Header>
            <Card.Body className="p-4">
                <Table className="users-table">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Correo</th>
                            <th>Rol</th>
                            <th>Estado</th>
                            <th>Editar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(users.user) &&
                            users.user.map((itemUser: any) => (
                                <tr>
                                    <td>
                                        {itemUser.names} {itemUser.lastName1} {itemUser.lastName2}
                                    </td>
                                    <td>{itemUser.email}</td>
                                    <td>
                                        {Array.isArray(roles) &&
                                            roles.map((itemRole: any) => (itemUser.role === itemRole._id ? itemRole.role : ''))}
                                    </td>
                                    {itemUser.active ? (
                                        <td className="status-active">Activo</td>
                                    ) : (
                                        <td className="status-inactive">Inactivo</td>
                                    )}
                                    <td>
                                        <i
                                            onClick={(e) => {
                                                e.preventDefault();
                                                dispatch(getUser(itemUser.id));

                                                setShowModalEditUser(true);
                                                setNames(itemUser.names);
                                                setlastName1(itemUser.lastName1);
                                                setlastName2(itemUser.lastName2);
                                                setEmail(itemUser.email);
                                                setActiveUser(itemUser.active);

                                                Array.isArray(roles) &&
                                                    roles.map((itemRole: any) =>
                                                        itemUser.role === itemRole._id ? setRoleOption(itemRole.role) : ''
                                                    );
                                            }}
                                            className="feather icon-edit"
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </Card.Body>
            <Modal
                className="users-modal"
                show={showModalNewUser}
                onHide={() => {
                    setShowModalNewUser(!showModalNewUser);
                    cleanForm();
                }}
            >
                <Modal.Header className="border-0">
                    <Modal.Title className="users-title">Nuevo Usuario</Modal.Title>
                </Modal.Header>
                <Form onSubmit={createNewUser}>
                    <Modal.Body className="pt-0 pb-0 pl-4 pr-4">
                        <Form.Group>
                            <Form.Control
                                type="text"
                                placeholder="Nombre (s)"
                                id="Name"
                                name="disabledCP"
                                value={names}
                                autoComplete="off"
                                onChange={(e) => {
                                    setNames(e.target.value);
                                    setErrors({ ...errors, names: '' });
                                }}
                            />
                            {errors.names && (
                                <p id="namesHelpBlock" className="form-text text-left text-danger">
                                    {errors.names}
                                </p>
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                type="text"
                                placeholder="Apellido paterno"
                                id="Lastnname1"
                                name="disabledCP"
                                value={lastName1}
                                autoComplete="off"
                                onChange={(e) => {
                                    setlastName1(e.target.value);
                                    setErrors({ ...errors, lastName1: '' });
                                }}
                            />
                            {errors.lastName1 && (
                                <p id="lastName1" className="form-text text-left text-danger">
                                    {errors.lastName1}
                                </p>
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                type="text"
                                placeholder="Apellido materno"
                                name="disabledCP"
                                id="Lastnname2"
                                value={lastName2}
                                autoComplete="off"
                                onChange={(e) => {
                                    setlastName2(e.target.value);
                                    setErrors({ ...errors, lastName2: '' });
                                }}
                            />
                            {errors.lastName2 && (
                                <p id="lastName2" className="form-text text-left text-danger">
                                    {errors.lastName2}
                                </p>
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                type="email"
                                placeholder="Correo"
                                id="email"
                                name="disabledCP"
                                autoComplete="off"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value.trim());
                                    setErrors({ ...errors, email: '' });
                                    setErrors({ ...errors, emailExist: '' });
                                }}
                            />
                            {errors.email && (
                                <p id="passwordHelpBlock" className="form-text text-left text-danger">
                                    {errors.email}
                                </p>
                            )}
                            {errors.emailExist && (
                                <p id="passwordHelpBlock" className="form-text text-left text-danger">
                                    {errors.emailExist}
                                </p>
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                as="select"
                                placeholder="Rol"
                                id="select"
                                value={roleOption}
                                autoComplete="off"
                                onChange={(e) => {
                                    setRoleOption(e.target.value);
                                    setErrors({ ...errors, roleOption: '' });
                                }}
                            >
                                <option value={''}>Roles</option>
                                {Array.isArray(roles) &&
                                    roles.map((itemRole: any) => (
                                        <>
                                            <option value={itemRole._id}>{itemRole.role}</option>
                                        </>
                                    ))}
                            </Form.Control>
                            {errors.roleOption && (
                                <p id="roleHelpBlock" className="form-text text-left text-danger">
                                    {errors.roleOption}
                                </p>
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                type="password"
                                placeholder="Contraseña"
                                name="disabledCP"
                                id="password"
                                autoComplete="off"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value.trim());
                                    setErrors({ ...errors, password: '' });
                                }}
                            />
                            {errors.password && (
                                <p id="passwordHelpBlock" className="form-text text-left text-danger">
                                    {errors.password}
                                </p>
                            )}
                            {passwordConditions && (
                                <div className="signupAdmin-success">
                                    <p>Tu contraseña debe contener:</p>
                                    <ProgressBar variant="success" now={passwordProgress} />
                                    <ul>
                                        <li>8 caracteres</li>
                                        <li>Una minúscula (a-z)</li>
                                        <li>Una mayúscula (A-Z)</li>
                                        <li>Un número (0-9)</li>
                                        <li>Un caracter especial (*?$)</li>
                                    </ul>
                                </div>
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                type="password"
                                placeholder="Confirmar contraseña"
                                id="confirm password"
                                name="disabledCP"
                                value={confirmPassword}
                                autoComplete="off"
                                onChange={(e) => {
                                    setConfirmPassword(e.target.value.trim());
                                    setErrors({ ...errors, confirmPassword: '' });
                                }}
                            />
                            {errors.confirmPassword && (
                                <p id="passwordHelpBlock" className="form-text text-left text-danger">
                                    {errors.confirmPassword}
                                </p>
                            )}
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer className="border-0 pb-3 pr-2">
                        <Button
                            className="users-buttonDanger"
                            onClick={() => {
                                setShowModalNewUser(false);
                                cleanForm();
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button type="submit" className="users-button">
                            Crear usuario
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <Modal className="users-modal" show={showModalEditUser}>
                <Modal.Header className="border-0">
                    <Modal.Title className="users-title">Editar Usuario</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0 pb-0 pl-4 pr-4">
                    <Form onSubmit={updateUserInfo}>
                        <Form.Group>
                            <Form.Control
                                type="text"
                                placeholder="Nombre (s)"
                                id="Name"
                                name="disabledCP"
                                value={names}
                                autoComplete="off"
                                onChange={(e) => {
                                    setNames(e.target.value);
                                    setErrors({ ...errors, names: '' });
                                }}
                            />
                            {errors.names && (
                                <p id="namesHelpBlock" className="form-text text-left text-danger">
                                    {errors.names}
                                </p>
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                type="text"
                                placeholder="Apellido paterno"
                                value={lastName1}
                                id="Lastnname1"
                                name="disabledCP"
                                autoComplete="off"
                                onChange={(e) => {
                                    setlastName1(e.target.value);
                                    setErrors({ ...errors, lastName1: '' });
                                }}
                            />
                            {errors.lastName1 && (
                                <p id="namesHelpBlock" className="form-text text-left text-danger">
                                    {errors.lastName1}
                                </p>
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                type="text"
                                placeholder="Apellido materno"
                                value={lastName2}
                                name="disabledCP"
                                id="Lastnname2"
                                autoComplete="off"
                                onChange={(e) => {
                                    setlastName2(e.target.value);
                                    setErrors({ ...errors, lastName2: '' });
                                }}
                            />
                            {errors.lastName2 && (
                                <p id="namesHelpBlock" className="form-text text-left text-danger">
                                    {errors.lastName2}
                                </p>
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                type="email"
                                placeholder="Correo"
                                value={email}
                                id="email"
                                name="disabledCP"
                                autoComplete="off"
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setErrors({ ...errors, email: '' });
                                    setErrors({ ...errors, emailExist: '' });
                                }}
                            />
                            {errors.email && (
                                <p id="passwordHelpBlock" className="form-text text-left text-danger">
                                    {errors.email}
                                </p>
                            )}
                            {errors.emailExist && (
                                <p id="passwordHelpBlock" className="form-text text-left text-danger">
                                    {errors.emailExist}
                                </p>
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                as="select"
                                placeholder="Rol"
                                autoComplete="off"
                                value={roleId}
                                onChange={(e) => {
                                    setRoleId(e.target.value);
                                    setErrors({ ...errors, roleId: '' });
                                }}
                            >
                                <option value={''}>Roles</option>
                                {Array.isArray(roles) &&
                                    roles.map((itemRole: any) => (
                                        <>
                                            <option value={itemRole._id}>{itemRole.role}</option>
                                        </>
                                    ))}
                            </Form.Control>
                            {errors.roleId && (
                                <p id="roleHelpBlock" className="form-text text-left text-danger">
                                    {errors.roleId}
                                </p>
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                as="select"
                                placeholder="Estado"
                                onChange={(e: any) => {
                                    e.target.value === '0' ? setActiveUser(true) : setActiveUser(false);
                                }}
                            >
                                <option value={''}>Estado</option>
                                {activeUser ? (
                                    <>
                                        <option value={'0'} selected>
                                            Activo
                                        </option>
                                        <option value={'1'}>Inactivo</option>
                                    </>
                                ) : (
                                    <>
                                        <option value={'0'}>Activo</option>
                                        <option value={'1'} selected>
                                            Inactivo
                                        </option>
                                    </>
                                )}
                            </Form.Control>
                        </Form.Group>
                        <Button
                            className="editPasswordButton"
                            onClick={() => {
                                passwordEdit ? setPasswordEdit(false) : setPasswordEdit(true);
                            }}
                        >
                            {passwordEdit ? 'Cancelar' : 'Editar Contraseña'}
                        </Button>
                        {passwordEdit && (
                            <>
                                <Form.Group>
                                    <Form.Control
                                        type="password"
                                        value={password}
                                        id="password"
                                        name="disabledCP"
                                        placeholder="Contraseña"
                                        onChange={(e) => {
                                            setPassword(e.target.value.trim());
                                            setErrors({ ...errors, password: '' });
                                        }}
                                    />
                                    {errors.password && (
                                        <p id="roleHelpBlock" className="form-text text-left text-danger">
                                            {errors.password}
                                        </p>
                                    )}
                                    {passwordConditions && (
                                        <div className="signupAdmin-success">
                                            <p>Tu contraseña debe contener:</p>
                                            <ProgressBar variant="success" now={passwordProgress} />
                                            <ul>
                                                <li>8 caracteres</li>
                                                <li>Una minúscula (a-z)</li>
                                                <li>Una mayúscula (A-Z)</li>
                                                <li>Un número (0-9)</li>
                                                <li>Un caracter especial (*?$)</li>
                                            </ul>
                                        </div>
                                    )}
                                </Form.Group>
                                <Form.Group>
                                    <Form.Control
                                        type="password"
                                        placeholder="Confirmar contraseña"
                                        value={confirmPassword}
                                        name="disabledCP"
                                        autoComplete="off"
                                        id="confirm password"
                                        onChange={(e) => {
                                            setConfirmPassword(e.target.value.trim());
                                            setErrors({ ...errors, confirmPassword: '' });
                                        }}
                                    />
                                    {errors.confirmPassword && (
                                        <p id="passwordHelpBlock" className="form-text text-left text-danger">
                                            {errors.confirmPassword}
                                        </p>
                                    )}
                                </Form.Group>
                            </>
                        )}
                        <Modal.Footer className="border-0 pb-3 pr-2">
                            <Button className="users-buttonDanger" onClick={() => setShowModalEditUser(false)}>
                                Cancelar
                            </Button>
                            <Button className="users-button" type="submit">
                                Guardar
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
        </Card>
    );
};
export default Users;