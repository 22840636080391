import * as React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import UserModel from '../../../models/user-model';
import { getApprovalCriteria, postApprovalCriteria, resetApprovalCriteriaStatus } from '../../../flux/actions/approvalCriteria-actions';
import { states } from '../../../constants/states-constants';
import { refreshToken } from '../../../flux/actions/refreshToken-action';

const ApprovalCriteria: React.FC = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    const flowRef = (params as any)?.id;

    const user: UserModel = useSelector((state: any) => state.authReducer.user);
    const { approvalCriteria, successMessage } = useSelector((state: any) => state.approvalCriteriaReducer);

    const [antiquity, setAntiquity] = useState('');
    const [creditScore, setCreditScore] = useState('');
    const [debt, setDebt] = useState('');
    const [location, setLocation] = useState<[{ label: string, value: string }]>();
    const [paymentCapacity, setPaymentCapacity] = useState('');
    const [borrowingCapacity, setBorrowingCapacity] = useState('');

    const [errors, setErrors] = useState({
        antiquity: '',
        creditScore: '',
        debt: '',
        location: '',
        paymentCapacity: '',
        borrowingCapacity: ''
    });

    useEffect(() => {
        if (Object.keys(approvalCriteria).length !== 0) {
            setAntiquity(approvalCriteria.antiquity);
            setCreditScore(approvalCriteria.creditScore);
            setDebt(approvalCriteria.debt);
            setPaymentCapacity(approvalCriteria.paymentCapacity);
            setBorrowingCapacity(approvalCriteria.borrowingCapacity);
            const auxLocation: any = [];
            approvalCriteria.location?.forEach((location: any) => {
                auxLocation.push({ label: location, value: location });
            });
            setLocation(auxLocation)
        }
        dispatch(
            refreshToken({
                token: localStorage.getItem('token'),
                refreshToken: localStorage.getItem('refreshToken')
            })
        );
    }, [approvalCriteria])


    const sendApprovalCriteria = () => {
        const antiquityError = antiquity !== '' ? '' : 'Este campo no puede estar vacío';
        const creditScoreError = creditScore === '' ? 'Este campo no puede estar vacío' : '';
        const debtError = debt === '' ? 'Este campo no puede estar vacío' : '';
        const locationError = location !== undefined ? '' : 'No es un RFC válido';
        const paymentCapacityError = paymentCapacity ? '' : 'Este no es un correo válido';
        const borrowingCapacityError = borrowingCapacity ? '' : 'Ingresar un teléfono válido';

        setErrors({
            antiquity: antiquityError,
            creditScore: creditScoreError,
            debt: debtError,
            location: locationError,
            paymentCapacity: paymentCapacityError,
            borrowingCapacity: borrowingCapacityError
        });

        if (
            antiquityError ||
            creditScoreError ||
            debtError ||
            locationError ||
            paymentCapacityError ||
            borrowingCapacityError
        )
            return;

        const auxLocation: string[] = [];
        location?.filter((location) => { auxLocation.push(location.value); })

        dispatch(
            postApprovalCriteria({
                antiquity,
                creditScore,
                debt,
                location: auxLocation,
                paymentCapacity,
                borrowingCapacity,
                userRef: user.id,
                flowRef
            })
        )
    }

    return (
        <Col md={11} className="order-md-2 user-profile user-card mb-4 mt-2 ml-4">
            <Card.Header className='approvalCriterial-header approvalCriterial-headerSection'>
                <Card.Title className='approvalCriterial-title'>
                    Criterios de Aprobación
                </Card.Title>
                <p className='approvalCriterial-description'>
                    En esta sección debemos definir los criterios mínimos de aceptación para las solicitudes de crédito, esto nos ayudará a realizar un análisis más sencillo de la información
                </p>
            </Card.Header>
            <Card.Body style={{boxShadow: "none"}}>
                <Row className="d-flex flex-row form-group">
                    <Col>
                        <label className="form-text font-weight-bolder approvalCriterial-label">Antigüedad de la empresa:</label>
                        <label className='approvalCriterial-description'>Número mínimo de meses que la empresa lleva operando.</label>
                        <input
                            type="text"
                            className="col-sm-12 form-control approvalCriterial-control"
                            id="denomination"
                            placeholder="Antigüedad"
                            value={antiquity}
                            onChange={(e: any) => {
                                setAntiquity(e.target.value);
                                setErrors({ ...errors, antiquity: '' });
                            }}
                        />
                        {errors.antiquity && (
                            <p className="form-text text-left text-danger">{errors.antiquity}</p>
                        )}
                    </Col>
                </Row>
                <Row className="d-flex flex-row form-group mt-4">
                    <Col>
                        <label className="form-text font-weight-bolder approvalCriterial-label">Score crediticio: </label>
                        <label className='approvalCriterial-description'>Puntaje mínimo registrado en el historial crediticio.</label>
                        <input
                            type="text"
                            className="col-sm-12 form-control approvalCriterial-control"
                            id="creditScore"
                            placeholder="Score Crediticio"
                            value={creditScore}
                            onChange={(e: any) => {
                                setCreditScore(e.target.value);
                                setErrors({ ...errors, creditScore: '' });
                            }}
                        />
                        {errors.creditScore && (
                            <p className="form-text text-left text-danger">{errors.creditScore}</p>
                        )}
                    </Col>
                </Row>
                <Row className="d-flex flex-row form-group mt-4">
                    <Col>
                        <label className="form-text font-weight-bolder approvalCriterial-label">Deuda: </label>
                        <label className='approvalCriterial-description'>Deuda máxima que es permitida para solicitar un crédito.</label>
                        <input
                            type="text"
                            className="col-sm-12 form-control approvalCriterial-control"
                            id="debt"
                            placeholder="debt"
                            value={debt}
                            onChange={(e) => {
                                setDebt(e.target.value.trim());
                                setErrors({ ...errors, debt: '' });
                            }}
                        />
                        {errors.debt && <p className="form-text text-left text-danger">{errors.debt}</p>}
                    </Col>
                </Row>
                <Row className="d-flex flex-row form-group mt-4">
                    <Col>
                        <label className="form-text font-weight-bolder approvalCriterial-label">Ubicación: </label>
                        <label className='approvalCriterial-description'>Estados de la república en los que NO se proporcionaran crédito.</label>
                        <Select
                            isMulti
                            name="colors"
                            value={location}
                            options={states}
                            className="basic-multi-select col-sm-12 approvalCriterial-control approvalCriterial-controlSelect"
                            classNamePrefix="select"
                            placeholder="Estados"
                            onChange={(e: any) => {
                                setLocation(e)
                            }}
                        />
                        {errors.location && <p className="form-text text-left text-danger">{errors.location}</p>}
                    </Col>
                </Row>
                <Row className="d-flex flex-row form-group mt-4">
                    <Col>
                        <label className="form-text font-weight-bolder approvalCriterial-label">Capacidad de pago: </label>
                        <label className='approvalCriterial-description'>Capacidad de pago mínima aceptada para solicitar un crédito.</label>
                        <input
                            type="text"
                            className="col-sm-12 form-control approvalCriterial-control"
                            id="paymentCapacity"
                            placeholder="Capacidad de pago"
                            value={paymentCapacity}
                            onChange={(e: any) => {
                                setPaymentCapacity(e.target.value);
                                setErrors({ ...errors, paymentCapacity: '' });
                            }}
                        />
                        {errors.paymentCapacity && (
                            <p className="form-text text-left text-danger">{errors.paymentCapacity}</p>
                        )}
                    </Col>
                </Row>
                <Row className="d-flex flex-row form-group mt-4">
                    <Col>
                        <label className="form-text font-weight-bolder approvalCriterial-label">Capacidad de endeudamiento: </label>
                        <label className='approvalCriterial-description'>Capacidad de endeudamiento máxima aceptada para solicitar un crédito.</label>
                        <input
                            type="text"
                            className="col-sm-12 form-control approvalCriterial-control"
                            id="borrowingCapacity"
                            placeholder="Capacidad de endeudamiento"
                            value={borrowingCapacity}
                            onChange={(e: any) => {
                                setBorrowingCapacity(e.target.value);
                                setErrors({ ...errors, borrowingCapacity: '' });
                            }}
                        />
                        {errors.borrowingCapacity && (
                            <p className="form-text text-left text-danger">{errors.borrowingCapacity}</p>
                        )}
                    </Col>
                </Row>
                <Row className="form-group mt-4">
                    <Col className='d-flex justify-content-end'>
                        <button type="submit" className="btn btn-primary btn-border-radius approvalCriterial-saveBtn" onClick={sendApprovalCriteria}>
                            Guardar
                        </button>
                    </Col>
                </Row>
            </Card.Body>
        </Col>
    );
};

export default ApprovalCriteria;
