export const estadoMapping: { [key: string]: string } = {
    Aguascalientes: 'AGS',
    Morelos: 'MOR',
    'Baja California': 'BCN',
    Nayarit: 'NAY',
    'Baja California Sur': 'BCS',
    'Nuevo León': 'NL',
    Campeche: 'CAM',
    Oaxaca: 'OAX',
    Chiapas: 'CHS',
    Puebla: 'PUE',
    Chihuahua: 'CHI',
    'Quintana Roo': 'QRO',
    'Coahuila de Zaragoza': 'COA',
    Querétaro: 'QR',
    Colima: 'COL',
    'San Luis Potosí': 'SLP',
    'Distrito Federal': 'DF',
    Sinaloa: 'SIN',
    Durango: 'DGO',
    Sonora: 'SON',
    'Estado de México': 'EM',
    Tabasco: 'TAB',
    Guanajuato: 'GTO',
    Tamaulipas: 'TAM',
    Guerrero: 'GRO',
    Tlaxcala: 'TLAX',
    Hidalgo: 'HGO',
    'Veracruz de Ignacio de la Llave': 'VER',
    Jalisco: 'JAL',
    Yucatán: 'YUC',
    'Michoacán de Ocampo': 'MICH',
    Zacatecas: 'ZAC',
    'Ciudad de Mexico': 'CDMX'
};
