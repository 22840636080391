import { interceptor } from './../../services/interceptor';
import { Dispatch } from 'redux';
import { API } from '../api/auth-api';
import * as types from '../types/auth-types';
import FLUX_AUTH_CONSTANTS from '../constants/auth-constants';

export const clearAuthLoading = (): types.IActionClearAuthLoading => {
    return {
        type: FLUX_AUTH_CONSTANTS.CLEAR_AUTH_LOADING
    };
};

export const resetUserStatus = () => {
    return (dispatch: Dispatch) => {
        dispatch(clearAuthLoading());
    };
};
//---------------------------------- LOGIN --------------------------------------------------

const loginBegin = (data: any): types.ILoginBegin => {
    return {
        type: FLUX_AUTH_CONSTANTS.LOGIN_BEGIN,
        data
    };
};

const loginError = (e: any, data: any): types.ILoginError => {
    return {
        type: FLUX_AUTH_CONSTANTS.LOGIN_ERROR,
        error: e,
        data
    };
};

const loginSuccess = (data: any): types.ILoginSuccess => {
    return {
        type: FLUX_AUTH_CONSTANTS.LOGIN_SUCCESS,
        data
    };
};

export const login = (data: any) => {
    return (dispatch: Dispatch) => {
        dispatch(loginBegin(data));
        API.login(data)
            .then((response: any) => {
                localStorage.setItem('token', response.data.user.token);
                localStorage.setItem('refreshToken', response.data.user.refreshToken);
                interceptor.addToken(response.data.user.token);
                dispatch(loginSuccess(response.data.user));
            })
            .catch((error: any) => {
                dispatch(loginError(error.response, data));
            });
    };
};

//---------------------------------- SIGHUP --------------------------------------------------

const createUserBegin = (): types.ICreateUserBegin => {
    return {
        type: FLUX_AUTH_CONSTANTS.CREATE_USER_BEGIN
    };
};

const createUserError = (e: any): types.ICreateUserError => {
    return {
        type: FLUX_AUTH_CONSTANTS.CREATE_USER_ERROR,
        error: e
    };
};

const createUserSuccess = (data: any): types.ICreateUserSuccess => {
    return {
        type: FLUX_AUTH_CONSTANTS.CREATE_USER_SUCCESS,
        data
    };
};

export const createUser = (data: any) => {
    return (dispatch: Dispatch) => {
        dispatch(createUserBegin());
        API.signup(data)
            .then((response: any) => {
                dispatch(createUserSuccess(response.data));
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = '/login';
                }
                dispatch(createUserError(error.response));
            });
    };
};

//------------------- Logout --------------------

const logoutSuccess = (): types.ILogoutSuccess => {
    return {
        type: FLUX_AUTH_CONSTANTS.LOGOUT_SUCCESS
    };
};
export const logout = () => {
    return (dispatch: Dispatch) => {
        dispatch(logoutSuccess());
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('persist:authReducer');
        localStorage.removeItem('persist:root');
        localStorage.removeItem('nubarium:redirected:FaceCapture');
        localStorage.removeItem('nubarium:redirected:RecordMessage');
        window.location.href = '/login';
    };
};
