enum INFORMATION_LIST_CONSTANTS {
    GET_INFORMATION_LIST_BEGIN = "GET_INFORMATION_LIST_BEGIN",
    GET_INFORMATION_LIST_ERROR = "GET_INFORMATION_LIST_ERROR",
    GET_INFORMATION_LIST_SUCCESS = "GET_INFORMATION_LIST_SUCCESS",

    GET_APPROVAL_STEPS_BEGIN = "GET_APPROVAL_STEPS_BEGIN",
    GET_APPROVAL_STEPS_ERROR = "GET_APPROVAL_STEPS_ERROR",
    GET_APPROVAL_STEPS_SUCCESS = "GET_APPROVAL_STEPS_SUCCESS",

    RESET_INFORMATION_LIST_STATUS = "RESET_INFORMATION_LIST_STATUS"
};

export default INFORMATION_LIST_CONSTANTS;