import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Table, ButtonToolbar, ToggleButtonGroup, Button } from 'react-bootstrap';
import { getAllFlows, resetFlowStatus } from '../../flux/actions/flow-actions';
import UserModel from '../../models/user-model';
import Loader from '../../components/LoaderFile/Loader';
import moment from 'moment';
import { TiArrowUnsorted } from 'react-icons/ti';
import { refreshToken } from '../../flux/actions/refreshToken-action';

const ListFlows: React.FC = () => {
    //redux const
    const history = useHistory();
    const dispatch = useDispatch();
    const user: UserModel = useSelector((state: any) => state.authReducer.user);
    const { page, loading, users, flows }: any = useSelector((state: any) => state.flowReducer);

    useEffect(() => {
        if (Object.keys(user).length === 0) {
            history.push('admin/login');
        }

        dispatch(getAllFlows(page));

        dispatch(
            refreshToken({
                token: localStorage.getItem('token'),
                refreshToken: localStorage.getItem('refreshToken')
            })
        );

        setTimeout(() => {
            let buttonPage = document.querySelectorAll('.buttonPage');

            if (buttonPage.length === 0) {
                //window.location.reload();
            }
        }, 1000);
    }, []);

    //Select Option Table Variables
    let tbodyTable = document.querySelector('tbody');
    let trTable = document.querySelectorAll('.creditTR');
    let selectOption: any = document.querySelector('select');
    let buttonsPagination = document.querySelector('.buttonsPagination');
    let pageNumber = 1;

    //Sume Page
    const sumPage = () => {
        let selectValue = Number(selectOption?.value);
        let pages = trTable.length / selectValue;
        let buttonPage: any = document.querySelectorAll('.buttonPage');
        let buttonPageActive: any = document.querySelector('.activePage');
        pageNumber = Number(buttonPageActive.innerText);

        if (pageNumber < pages) {
            if (null !== buttonPage) {
                buttonPage[pageNumber].click();
            }

            if (pageNumber >= 1) {
                pageNumber = pageNumber + 1;
            } else {
                pageNumber = 1;
            }
        }
    };

    //Rest Page
    const restPage = () => {
        let buttonPage: any = document.querySelectorAll('.buttonPage');
        let buttonPageActive: any = document.querySelector('.activePage');

        if (null !== buttonPageActive) {
            pageNumber = Number(buttonPageActive.innerText);
        }

        if (pageNumber > 1) {
            if (buttonPage[pageNumber] !== undefined || null !== buttonPage) {
                pageNumber = pageNumber - 2;
                buttonPage[pageNumber].click();
            }
        }
    };

    //Change Option Value and Pagination
    const changeValue = () => {
        let selectValue = Number(selectOption?.value);
        let pages = trTable.length / selectValue;
        pageNumber = 1;

        if (null !== tbodyTable) {
            tbodyTable.innerHTML = '';

            for (let i = 0; i < selectValue; i++) {
                if (trTable[i] !== undefined) {
                    tbodyTable.innerHTML += trTable[i].innerHTML;
                }
            }
        }

        //Pagination
        if (null !== buttonsPagination) {
            buttonsPagination.innerHTML = '';

            for (let i = 0; i < pages; i++) {
                buttonsPagination.innerHTML += `<button class="buttonPage">${i + 1}</button>`;
            }
        }

        let buttonPage = document.querySelectorAll('.buttonPage');
        buttonPage.forEach((button) => {
            if (button.textContent === '1') {
                button.className = 'buttonPage activePage';
            }

            button.addEventListener('click', () => {
                let buttonPageClick = Number(button.textContent);
                let activeButton = document.querySelectorAll('.activePage');

                let notFoundSearch = document.querySelector('.notFoundSearch');
                let searchInput: HTMLInputElement | any = document.querySelector('.search');

                searchInput.value = '';
                notFoundSearch?.setAttribute('style', 'display: none');

                button.className = 'buttonPage activePage';

                activeButton.forEach((active) => {
                    if (active.className === 'buttonPage activePage') {
                        active.className = 'buttonPage';
                    } else {
                        active.className = 'buttonPage activePage';
                    }
                });

                if (null !== tbodyTable) {
                    tbodyTable.innerHTML = '';
                    let iterationValue;

                    if (buttonPageClick > 1) {
                        iterationValue = selectValue * buttonPageClick - selectValue;
                    } else {
                        iterationValue = 0;
                    }

                    for (let i = iterationValue; i < selectValue * buttonPageClick; i++) {
                        if (trTable[i] !== undefined) {
                            tbodyTable.innerHTML += trTable[i].innerHTML;
                        }
                    }
                }
            });
        });

        let notFoundSearch = document.querySelector('.notFoundSearch');
        let searchInput: HTMLInputElement | any = document.querySelector('.search');

        searchInput.value = '';
        notFoundSearch?.setAttribute('style', 'display: none');
    };

    //Order Table
    const sortTable = (n: number) => {
        let table: HTMLTableElement | null = document.querySelector('table');
        let rows,
            i,
            tableElement1,
            tableElement2,
            count = 0;
        let switching = true;
        let Switch;

        let direction = 'ascending';

        while (switching) {
            switching = false;

            if (null !== table) {
                let rows = table.rows;

                for (i = 1; i < rows.length - 1; i++) {
                    Switch = false;

                    tableElement1 = rows[i].getElementsByTagName('TD')[n];
                    tableElement2 = rows[i + 1].getElementsByTagName('TD')[n];

                    if (direction === 'ascending') {
                        if (tableElement1.innerHTML.toLowerCase() > tableElement2.innerHTML.toLowerCase()) {
                            Switch = true;
                            break;
                        }
                    } else if (direction === 'descending') {
                        if (tableElement1.innerHTML.toLowerCase() < tableElement2.innerHTML.toLowerCase()) {
                            Switch = true;
                            break;
                        }
                    }
                }

                if (Switch) {
                    rows[i].parentNode?.insertBefore(rows[i + 1], rows[i]);
                    switching = true;
                    count++;
                } else {
                    if (count === 0 && direction === 'ascending') {
                        direction = 'descending';
                        switching = true;
                    }
                }
            }
        }
    };

    //Order Table Date
    const sortTableDate = (n: number) => {
        let table: HTMLTableElement | null = document.querySelector('table');
        let rows,
            i,
            tableElement1,
            tableElement2,
            count = 0;
        let switching = true;
        let Switch;

        let direction = 'ascending';

        while (switching) {
            switching = false;

            if (null !== table) {
                let rows = table.rows;

                for (i = 1; i < rows.length - 1; i++) {
                    Switch = false;

                    tableElement1 = rows[i].getElementsByTagName('TD')[n];
                    tableElement2 = rows[i + 1].getElementsByTagName('TD')[n];

                    let tableElementDate1 = tableElement1.innerHTML.split('/', 3);
                    let tableElementDate2 = tableElement2.innerHTML.split('/', 3);

                    let tableArrayDate1 = tableElementDate1.reverse();
                    let tableArrayDate2 = tableElementDate2.reverse();

                    let tableStringDate1 = tableArrayDate1[0] + tableArrayDate1[1] + tableArrayDate1[2];
                    let tableStringDate2 = tableArrayDate2[0] + tableArrayDate2[1] + tableArrayDate2[2];

                    if (direction === 'ascending') {
                        if (Number(tableStringDate1) > Number(tableStringDate2)) {
                            Switch = true;
                            break;
                        }
                    } else if (direction === 'descending') {
                        if (Number(tableStringDate1) < Number(tableStringDate2)) {
                            Switch = true;
                            break;
                        }
                    }
                }

                if (Switch) {
                    rows[i].parentNode?.insertBefore(rows[i + 1], rows[i]);
                    switching = true;
                    count++;
                } else {
                    if (count === 0 && direction === 'ascending') {
                        direction = 'descending';
                        switching = true;
                    }
                }
            }
        }
    };

    //Order Table Steps
    const sortTableSteps = (n: number) => {
        let table: HTMLTableElement | null = document.querySelector('table');
        let rows,
            i,
            tableElement1,
            tableElement2,
            count = 0;
        let switching = true;
        let Switch;

        let direction = 'ascending';

        while (switching) {
            switching = false;

            if (null !== table) {
                let rows = table.rows;

                for (i = 1; i < rows.length - 1; i++) {
                    Switch = false;

                    tableElement1 = rows[i].getElementsByTagName('TD')[n];
                    tableElement2 = rows[i + 1].getElementsByTagName('TD')[n];

                    if (direction === 'ascending') {
                        if (
                            parseInt(tableElement1.innerHTML.replace(' pasos', '')) >
                            parseInt(tableElement2.innerHTML.replace(' pasos', ''))
                        ) {
                            Switch = true;
                            break;
                        }
                    } else if (direction === 'descending') {
                        if (
                            parseInt(tableElement1.innerHTML.replace(' pasos', '')) <
                            parseInt(tableElement2.innerHTML.replace(' pasos', ''))
                        ) {
                            Switch = true;
                            break;
                        }
                    }
                }

                if (Switch) {
                    rows[i].parentNode?.insertBefore(rows[i + 1], rows[i]);
                    switching = true;
                    count++;
                } else {
                    if (count === 0 && direction === 'ascending') {
                        direction = 'descending';
                        switching = true;
                    }
                }
            }
        }
    };

    //Search Table
    const searchTable = () => {
        let i, txtValue;
        let filter: any;
        let tr: any;
        let td: any;
        let input: HTMLInputElement | any;
        let table: HTMLTableElement | any;

        input = document.querySelector('.search');
        filter = input.value.toUpperCase();
        table = document.querySelector('table');
        tr = table.getElementsByTagName('tr');
        let notFoundSearch = document.querySelector('.notFoundSearch');

        for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName('td')[0];

            if (td) {
                txtValue = td.textContent || td.innerText;

                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = '';
                } else {
                    tr[i].style.display = 'none';
                }
            }

            let trStyle = document.querySelectorAll(`tr[style="display: none;"]`);

            if (trStyle.length + 1 === tr.length) {
                notFoundSearch?.removeAttribute('style');
            } else {
                notFoundSearch?.setAttribute('style', 'display: none');
            }
        }
    };

    //Load Fist Time
    window.onload = async () => {
        await setTimeout(() => changeValue(), 500);
    };

    return (
        <>
            <div className="listFlows">
                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Body>
                                <div className="listFlows-header">
                                    <h2 className="listFlows-title">Flujos</h2>
                                    <div className="listFlows-search">
                                        <input
                                            type="text"
                                            className="form-control search"
                                            onChange={() => searchTable()}
                                            placeholder="Buscar..."
                                        />
                                        <i className="feather icon-search" onClick={() => searchTable()} />
                                    </div>
                                </div>
                                <Table striped responsive className="listFlows-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <button onClick={() => sortTable(0)}>
                                                    Nombre <TiArrowUnsorted />
                                                </button>
                                            </th>
                                            <th className="showSteps">
                                                <button onClick={() => sortTableSteps(1)}>
                                                    Pasos <TiArrowUnsorted />
                                                </button>
                                            </th>
                                            <th className="showCreator">
                                                <button onClick={() => sortTable(2)}>
                                                    Creador <TiArrowUnsorted />
                                                </button>
                                            </th>
                                            <th className="showDate">
                                                <button onClick={() => sortTableDate(3)}>
                                                    Fecha de Creación <TiArrowUnsorted />
                                                </button>
                                            </th>
                                            <th className="showStatus">
                                                <button onClick={() => sortTable(4)}>
                                                    Activo <TiArrowUnsorted />
                                                </button>
                                            </th>
                                            <th className="showDefault">
                                                <button onClick={() => sortTable(5)}>
                                                    Flujo Default <TiArrowUnsorted />
                                                </button>
                                            </th>
                                            <th className="showTaxt">
                                                <button onClick={() => sortTable(6)}>
                                                    Régimen <TiArrowUnsorted />
                                                </button>
                                            </th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {flows.length !== 0 &&
                                            flows.map((flow: any, index: number) => {
                                                let regimenFiscal;
                                                switch (flow.taxRegime) {
                                                    case 'PF':
                                                        regimenFiscal = 'Persona Física';
                                                        break;
                                                    case 'PM':
                                                        regimenFiscal = 'Persona Moral';
                                                        break;
                                                    case 'AM':
                                                        regimenFiscal = 'Ambos';
                                                        break;
                                                }
                                                const userIndex = users.findIndex((object: any) => {
                                                    return object._id === flow.userRef;
                                                });
                                                return (
                                                    <tr className="creditTR">
                                                        <td>
                                                            <div className="d-inline-block align-middle">
                                                                <div className="d-inline-block">
                                                                    <h6 className="mb-0">{flow.name}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="showSteps">
                                                            {flow.steps.length} {' Pasos'}
                                                        </td>
                                                        <td className="showCreator">
                                                            {/* {users[userIndex]?.names + ' ' + users[userIndex]?.lastName1} */}
                                                        </td>
                                                        <td className="showDate">{moment(flow.createdAt).format('L')}</td>
                                                        <td className="showStatus">
                                                            {flow.isActive ? (
                                                                <span className="badge badge-light-success" style={{ fontSize: '0.75rem' }}>
                                                                    ACTIVO
                                                                </span>
                                                            ) : (
                                                                <span className="badge badge-light-danger" style={{ fontSize: '0.75rem' }}>
                                                                    INACTIVO
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td className="showDefault">
                                                            {flow.isDefault ? (
                                                                <span className="badge badge-light-info" style={{ fontSize: '0.75rem' }}>
                                                                    FLUJO PREDETERMINADO
                                                                </span>
                                                            ) : flow.isActive ? (
                                                                <span className="badge badge-light-success" style={{ fontSize: '0.75rem' }}>
                                                                    FLUJO ACCESIBLE
                                                                </span>
                                                            ) : (
                                                                <span className="badge badge-light-danger" style={{ fontSize: '0.75rem' }}>
                                                                    INACTIVO
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td className="showTaxt">{regimenFiscal}</td>
                                                        <td>
                                                            <Col>
                                                                <Row className="listFlow-actions">
                                                                    <Button
                                                                        className="btn-icon"
                                                                        variant={'success'}
                                                                        onClick={() => {
                                                                            dispatch(resetFlowStatus());
                                                                            window.open(
                                                                                `/flujo/${flow.name.replaceAll(' ', '-')}`,
                                                                                '_blank'
                                                                            );
                                                                        }}
                                                                    >
                                                                        <a
                                                                            href={`/flujo/${flow.name.replaceAll(' ', '-')}`}
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            <i className={'feather icon-eye'} />
                                                                        </a>
                                                                    </Button>
                                                                    <Button className="btn-icon" variant={'info'}>
                                                                        <a href={`/admin/editar-flujo/${flow._id}`}>
                                                                            <i className={'feather icon-edit'} />
                                                                        </a>
                                                                    </Button>
                                                                    <Button className="btn-icon" variant={'secondary'}>
                                                                        <a href={`/admin/crear-proceso-aprobacion/${flow._id}`}>
                                                                            <i className={'feather icon-user-check'} />
                                                                        </a>
                                                                    </Button>
                                                                </Row>
                                                            </Col>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                    <tfoot />
                                </Table>
                                <h3 className="notFoundSearch" style={{ display: 'none' }}>
                                    No se encontraron resultados en tu búsqueda...
                                </h3>
                                <Row className="d-flex align-items-end pagination">
                                    <Col className="p-2 col-sm-9 col-md-8 col-xl-9">
                                        <label>Mostrar</label>
                                        <select className="form-control" onChange={() => changeValue()} defaultValue="5">
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                        </select>
                                    </Col>
                                    <Col className="p-2 col-sm-3">
                                        <ButtonToolbar>
                                            <ToggleButtonGroup type="radio" name="options">
                                                <button onClick={() => restPage()}>
                                                    <i className="feather icon-chevron-left" />
                                                </button>
                                                <div className="buttonsPagination"></div>
                                                <button onClick={() => sumPage()}>
                                                    <i className="feather icon-chevron-right" />
                                                </button>
                                            </ToggleButtonGroup>
                                        </ButtonToolbar>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};
export default ListFlows;
