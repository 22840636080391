import axios from 'axios';

const API_URL = process.env.REACT_APP_BASE_URL;

const postCreatePreviusData = (payload: string) => {
    return axios.post(`${API_URL}previus-data/`,payload);
}

const getPreviusData = () => {
    return axios.get(`${API_URL}previus-data`);
}

export const API = {
    postCreatePreviusData,
    getPreviusData
}