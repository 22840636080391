import { Dispatch } from "redux";

import { API } from "../api/unic-api";
import INFORMATION_LIST_CONSTANTS from "../constants/informationList-constants";
import * as types from '../types/informationList-types' ;


const getInformationListBeggin = (): types.IGetinformationListBegin => {
    return {
        type: INFORMATION_LIST_CONSTANTS.GET_INFORMATION_LIST_BEGIN
    };
}

const getInformationListError = (e: any): types.IGetinformationListError => {
    return {
        type: INFORMATION_LIST_CONSTANTS.GET_INFORMATION_LIST_ERROR,
        error: e
    };
}

const getInformationListSuccess = (data: any): types.IGetinformationListSuccess => {
    return {
        type: INFORMATION_LIST_CONSTANTS.GET_INFORMATION_LIST_SUCCESS,
        data
    };
}

export const getInformationList = () => {
    return (dispatch: Dispatch) => {
        dispatch(getInformationListBeggin());
        API.getInformationList()
        .then((response: any) => {
            dispatch(getInformationListSuccess(response.data));
        }).catch((error: any) => {
            if(error.status === 401){
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('persist:authReducer');
                localStorage.removeItem('persist:root');
                localStorage.removeItem('nubarium:redirected:FaceCapture');
                localStorage.removeItem('nubarium:redirected:RecordMessage');
                window.location.href = "/login";
            }
            dispatch(getInformationListError(error.response));
        });
    }
}

//----------------------------- ACTIONS TO GET THE APPROVAL STEPS 
const getApprovalStepsBeggin = (): types.IGetApprovalStepsBegin => {
    return {
        type: INFORMATION_LIST_CONSTANTS.GET_APPROVAL_STEPS_BEGIN
    };
}

const getApprovalStepsError = (e: any): types.IGetApprovalStepsError => {
    return {
        type: INFORMATION_LIST_CONSTANTS.GET_APPROVAL_STEPS_ERROR,
        error: e
    };
}

const getApprovalStepsSuccess = (data: any): types.IGetApprovalStepsSuccess => {
    return {
        type: INFORMATION_LIST_CONSTANTS.GET_APPROVAL_STEPS_SUCCESS,
        data
    };
}

export const getApprovalSteps = () => {
    return (dispatch: Dispatch) => {
        dispatch(getApprovalStepsBeggin());
        API.getApprovalSteps()
        .then((response: any) => {
            dispatch(getApprovalStepsSuccess(response.data));
        }).catch((error: any) => {
            if(error.status === 401){
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('persist:authReducer');
                localStorage.removeItem('persist:root');
                localStorage.removeItem('nubarium:redirected:FaceCapture');
                localStorage.removeItem('nubarium:redirected:RecordMessage');
                window.location.href = "/login";
            }
            dispatch(getApprovalStepsError(error.response));
        });
    }
}

const resetStatus = () => {
    return {
        type: INFORMATION_LIST_CONSTANTS.RESET_INFORMATION_LIST_STATUS
    }
}

export const resetInformationList = () => {
    return (dispatch: Dispatch) => {
        dispatch(resetStatus());
;    }
}