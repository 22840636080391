import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, Form, Table, Modal } from 'react-bootstrap';
import { getPermission } from '../../flux/actions/permission-action';
import { refreshToken } from '../../flux/actions/refreshToken-action';
import { getAllRoles, saveRole, updateRole, getRole } from '../../flux/actions/role-actions';

const Rols: React.FC = () => {
    const dispatch = useDispatch();
    const [edit, setEdit] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [prospectsShow, setProspectShow] = useState(false);
    const [flowsShow, setFlowsShow] = useState(false);
    const [makeFlowShow, setMakeFlowShow] = useState(false);
    const [usersShow, setUsersShow] = useState(false);
    const { permission } = useSelector((state: any) => state.permissionReducer);
    const { roles, role } = useSelector((state: any) => state.roleReducer);

    //Local Variables
    const [name, setName] = useState('');
    const [nameEdit, setNameEdit] = useState('');
    const [active, setActive] = useState(false);
    const [check, setCheck] = useState(true);
    const [editPermissions, setEditPermissions] = useState([]);

    useEffect(() => {
        dispatch(getPermission());
        dispatch(getAllRoles());
        dispatch(
            refreshToken({
                token: localStorage.getItem('token'),
                refreshToken: localStorage.getItem('refreshToken')
            })
        );
    }, []);

    // Errors to show
    const [errors, setErrors] = useState({
        name: ''
    });

    // Create Role
    const createNewRol = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const errorName = name === '' ? 'Coloca un nombre al nuevo rol.' : '';

        setErrors({
            name: errorName
        });

        const payload = {
            role: name,
            active: true,
            permissions: permission
        };

        if (errorName) return;
        dispatch(saveRole(payload));
        setTimeout(() => {
            dispatch(getAllRoles());
        }, 1000);
        setShowModal(false);
    };

    // Update Role
    const updateRoles = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const payload = {
            id: role._id,
            role: nameEdit,
            active: active,
            permissions: editPermissions.length !== 0 ? editPermissions : permission
        };

        dispatch(updateRole(payload));
        setTimeout(() => {
            dispatch(getAllRoles());
        }, 1000);
        setEdit(false);
    };

    return (
        <Card className="rols">
            <Card.Header className="d-flex justify-content-between align-items-center pl-4 pr-4 pt-4 pb-0 border-0">
                <Card.Title className="rols-title">Roles</Card.Title>
                <Button
                    className="rols-button"
                    onClick={() => {
                        setShowModal(true);
                        setName('');
                    }}
                >
                    Crear Rol <i className="feather icon-plus-circle" />
                </Button>
            </Card.Header>
            <Card.Body className="p-4">
                {Array.isArray(roles) &&
                    roles.map((roleItem: any) => (
                        <Card>
                            <Form onSubmit={updateRoles}>
                                <Card.Header className=" pl-4 pr-4 pt-4 pb-3 border-0">
                                    <div className={roleItem.edit ? 'd-none' : 'd-flex justify-content-between align-items-center'}>
                                        <Card.Subtitle className="rols-subtitle">{roleItem.role}</Card.Subtitle>
                                        <i
                                            className="feather icon-edit"
                                            onClick={() => {
                                                roleItem.edit = edit;
                                                roleItem.edit ? setEdit(false) : setEdit(true);
                                                setNameEdit(roleItem.role);
                                                setActive(roleItem.active);
                                                dispatch(getRole(roleItem._id));
                                            }}
                                        />
                                    </div>
                                    <div className={!roleItem.edit ? 'd-none' : 'd-flex justify-content-between align-items-center'}>
                                        <Form.Group className="nameEdit mb-0">
                                            <Form.Control
                                                type="text"
                                                placeholder="Nombre"
                                                value={nameEdit}
                                                onChange={(e) => {
                                                    setNameEdit(e.target.value);
                                                }}
                                            />
                                        </Form.Group>
                                        <Form.Group className="switch">
                                            <Form.Label className="mr-2">Activo</Form.Label>
                                            <Form.Control
                                                type="checkbox"
                                                id={`unchecked-${roleItem.role}`}
                                                checked={active}
                                                onChange={(e: any) => {
                                                    setActive(e.target.checked);
                                                }}
                                            />
                                            <Form.Label htmlFor={`unchecked-${roleItem.role}`} className="cr" />
                                        </Form.Group>
                                    </div>
                                </Card.Header>
                                <Card.Header
                                    className={
                                        roleItem.edit
                                            ? 'border-0 pb-3 pr-4 d-flex justify-content-end'
                                            : 'border-0 pb-3 pr-4  justify-content-end d-none'
                                    }
                                >
                                    <Button
                                        className="rols-buttonDanger mr-2"
                                        onClick={() => {
                                            roleItem.edit = edit;
                                            roleItem.edit ? setEdit(false) : setEdit(true);
                                            dispatch(getAllRoles());
                                        }}
                                    >
                                        Cancelar
                                    </Button>
                                    <Button className="rols-button" type="submit">
                                        Guardar
                                    </Button>
                                </Card.Header>
                                <Card.Body>
                                    <Table className="rols-table">
                                        <thead>
                                            <tr>
                                                <th className="col-4">Permiso</th>
                                                <th className="col-8">Descripción</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(roleItem.permissions) &&
                                                roleItem.permissions.map((item: any) =>
                                                    item.group === 'prospects' || item.category === 'prospects' ? (
                                                        <tr
                                                            className={
                                                                item.category === 'prospects'
                                                                    ? ''
                                                                    : item.group === 'prospects' && !prospectsShow
                                                                    ? 'd-none'
                                                                    : ''
                                                            }
                                                        >
                                                            <td>
                                                                <Form.Group
                                                                    className={
                                                                        item.group && item.subGroup
                                                                            ? 'd-flex align-items-center ml-4 pl-4'
                                                                            : item.group
                                                                            ? 'd-flex align-items-center ml-4 '
                                                                            : 'd-flex align-items-center'
                                                                    }
                                                                >
                                                                    <Form.Check
                                                                        custom
                                                                        disabled={!roleItem.edit}
                                                                        checked={item.state}
                                                                        type="checkbox"
                                                                        id={item._id + roleItem.role}
                                                                        className={
                                                                            item.permissionType === 'critical' ? 'alertPermission' : ''
                                                                        }
                                                                        onChange={() => {
                                                                            item.state = check;
                                                                            item.state ? setCheck(false) : setCheck(true);
                                                                            setEditPermissions(roleItem.permissions);
                                                                        }}
                                                                    />
                                                                    <Form.Label className="mb-0">
                                                                        {item.permission}{' '}
                                                                        {item.icon && (
                                                                            <i
                                                                                className={
                                                                                    prospectsShow
                                                                                        ? 'feather icon-chevron-up'
                                                                                        : 'feather icon-chevron-down'
                                                                                }
                                                                                onClick={() => {
                                                                                    prospectsShow
                                                                                        ? setProspectShow(false)
                                                                                        : setProspectShow(true);
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Form.Label>
                                                                </Form.Group>
                                                            </td>
                                                            <td>
                                                                <p>{item.description}</p>
                                                            </td>
                                                        </tr>
                                                    ) : item.group === 'flows' || item.category === 'flow' ? (
                                                        <tr
                                                            className={
                                                                item.category === 'flow'
                                                                    ? ''
                                                                    : item.group === 'flows' && !flowsShow
                                                                    ? 'd-none'
                                                                    : ''
                                                            }
                                                        >
                                                            <td>
                                                                <Form.Group
                                                                    className={
                                                                        item.group && item.subGroup
                                                                            ? 'd-flex align-items-center ml-4 pl-4'
                                                                            : item.group
                                                                            ? 'd-flex align-items-center ml-4 '
                                                                            : 'd-flex align-items-center'
                                                                    }
                                                                >
                                                                    <Form.Check
                                                                        custom
                                                                        type="checkbox"
                                                                        disabled={!roleItem.edit}
                                                                        checked={item.state}
                                                                        id={item._id + roleItem.role}
                                                                        className={
                                                                            item.permissionType === 'critical' ? 'alertPermission' : ''
                                                                        }
                                                                        onChange={() => {
                                                                            item.state = check;
                                                                            item.state ? setCheck(false) : setCheck(true);
                                                                            setEditPermissions(roleItem.permissions);
                                                                        }}
                                                                    />
                                                                    <Form.Label className="mb-0">
                                                                        {item.permission}{' '}
                                                                        {item.icon && (
                                                                            <i
                                                                                className={
                                                                                    flowsShow
                                                                                        ? 'feather icon-chevron-up'
                                                                                        : 'feather icon-chevron-down'
                                                                                }
                                                                                onClick={() => {
                                                                                    flowsShow ? setFlowsShow(false) : setFlowsShow(true);
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Form.Label>
                                                                </Form.Group>
                                                            </td>
                                                            <td>
                                                                <p>{item.description}</p>
                                                            </td>
                                                        </tr>
                                                    ) : item.group === 'makeFlow' || item.category === 'makeFlow' ? (
                                                        <tr
                                                            className={
                                                                item.category === 'makeFlow'
                                                                    ? ''
                                                                    : item.group === 'makeFlow' && !makeFlowShow
                                                                    ? 'd-none'
                                                                    : ''
                                                            }
                                                        >
                                                            <td>
                                                                <Form.Group
                                                                    className={
                                                                        item.group && item.subGroup
                                                                            ? 'd-flex align-items-center ml-4 pl-4'
                                                                            : item.group
                                                                            ? 'd-flex align-items-center ml-4 '
                                                                            : 'd-flex align-items-center'
                                                                    }
                                                                >
                                                                    <Form.Check
                                                                        custom
                                                                        type="checkbox"
                                                                        disabled={!roleItem.edit}
                                                                        checked={item.state}
                                                                        id={item._id + roleItem.role}
                                                                        className={
                                                                            item.permissionType === 'critical' ? 'alertPermission' : ''
                                                                        }
                                                                        onChange={() => {
                                                                            item.state = check;
                                                                            item.state ? setCheck(false) : setCheck(true);
                                                                            setEditPermissions(roleItem.permissions);
                                                                        }}
                                                                    />
                                                                    <Form.Label className="mb-0">
                                                                        {item.permission}{' '}
                                                                        {item.icon && (
                                                                            <i
                                                                                className={
                                                                                    makeFlowShow
                                                                                        ? 'feather icon-chevron-up'
                                                                                        : 'feather icon-chevron-down'
                                                                                }
                                                                                onClick={() => {
                                                                                    makeFlowShow
                                                                                        ? setMakeFlowShow(false)
                                                                                        : setMakeFlowShow(true);
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Form.Label>
                                                                </Form.Group>
                                                            </td>
                                                            <td>
                                                                <p>{item.description}</p>
                                                            </td>
                                                        </tr>
                                                    ) : item.group === 'users' || item.category === 'users' ? (
                                                        <tr
                                                            className={
                                                                item.category === 'users'
                                                                    ? ''
                                                                    : item.group === 'users' && !usersShow
                                                                    ? 'd-none'
                                                                    : ''
                                                            }
                                                        >
                                                            <td>
                                                                <Form.Group
                                                                    className={
                                                                        item.group && item.subGroup
                                                                            ? 'd-flex align-items-center ml-4 pl-4'
                                                                            : item.group
                                                                            ? 'd-flex align-items-center ml-4 '
                                                                            : 'd-flex align-items-center'
                                                                    }
                                                                >
                                                                    <Form.Check
                                                                        custom
                                                                        type="checkbox"
                                                                        id={item._id + roleItem.role}
                                                                        disabled={!roleItem.edit}
                                                                        checked={item.state}
                                                                        className={
                                                                            item.permissionType === 'critical' ? 'alertPermission' : ''
                                                                        }
                                                                        onChange={() => {
                                                                            item.state = check;
                                                                            item.state ? setCheck(false) : setCheck(true);
                                                                            setEditPermissions(roleItem.permissions);
                                                                        }}
                                                                    />
                                                                    <Form.Label className="mb-0">
                                                                        {item.permission}{' '}
                                                                        {item.icon && (
                                                                            <i
                                                                                className={
                                                                                    usersShow
                                                                                        ? 'feather icon-chevron-up'
                                                                                        : 'feather icon-chevron-down'
                                                                                }
                                                                                onClick={() => {
                                                                                    usersShow ? setUsersShow(false) : setUsersShow(true);
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Form.Label>
                                                                </Form.Group>
                                                            </td>
                                                            <td>
                                                                <p>{item.description}</p>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        <tr>
                                                            <td>
                                                                <Form.Group className={'d-flex align-items-center'}>
                                                                    <Form.Check
                                                                        custom
                                                                        type="checkbox"
                                                                        disabled={!roleItem.edit}
                                                                        checked={item.state}
                                                                        id={item._id + roleItem.role}
                                                                        className={
                                                                            item.permissionType === 'critical' ? 'alertPermission' : ''
                                                                        }
                                                                        onChange={() => {
                                                                            item.state = check;
                                                                            item.state ? setCheck(false) : setCheck(true);
                                                                            setEditPermissions(roleItem.permissions);
                                                                        }}
                                                                    />
                                                                    <Form.Label className="mb-0">{item.permission}</Form.Label>
                                                                </Form.Group>
                                                            </td>
                                                            <td>
                                                                <p>{item.description}</p>
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Form>
                        </Card>
                    ))}
            </Card.Body>
            <Modal
                className="rols-modal"
                show={showModal}
                onHide={() => {
                    setName('');
                    setShowModal(!showModal);
                }}
            >
                <Modal.Header className="border-0">
                    <Modal.Title className="users-title">Nuevo rol</Modal.Title>
                </Modal.Header>
                <Form onSubmit={createNewRol}>
                    <Modal.Body className="pt-0 pb-0 pl-4 pr-4">
                        <Form.Group>
                            <Form.Control
                                type="text"
                                placeholder="Nombre"
                                id="Name"
                                name="disabledCP"
                                value={name}
                                autoComplete="off"
                                onChange={(e) => {
                                    setName(e.target.value);
                                    setErrors({ ...errors, name: '' });
                                }}
                            />
                            {errors.name && (
                                <p id="nameHelpBlock" className="form-text text-left text-danger">
                                    {errors.name}
                                </p>
                            )}
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer className="border-0 pb-3 pr-2">
                        <Button className="rols-buttonDanger" onClick={() => setShowModal(false)}>
                            Cancelar
                        </Button>
                        <Button className="rols-button" type="submit">
                            Crear rol
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Card>
    );
};
export default Rols;