import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Row, Col, Card, Table, Button, Alert, Modal, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import UserModel from '../../models/user-model';
import { getApprovalSteps } from '../../flux/actions/informationList-action';
import { getApprovalFlowrefProcess, postApprovalProcess, resetApprovalProcessStatus } from '../../flux/actions/approvalProcess-actions';
import { states } from '../../constants/states-constants';
import ApprovalCriteria from './CreateApprovalCriteria/ApprovalCriteria';
import Select from 'react-select';
import { approvalCriteriaList } from './CreateApprovalCriteria/approvalCriteriaList';
import { postApprovalCriteria, resetApprovalCriteriaStatus, getApprovalCriteria } from '../../flux/actions/approvalCriteria-actions';
import { refreshToken } from '../../flux/actions/refreshToken-action';

interface stepProvider {
    stepName: string;
    description: string;
    clientStatus: string;
    adminStatus: string;
}

interface criterialList {
    Name: string;
    description: string;
}

const CreateApprovalProcess: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    const flowRef = (params as any)?.id;

    const user: UserModel = useSelector((state: any) => state.authReducer.user);
    const { approvalSteps } = useSelector((state: any) => state.informationListReducer);
    const { approvalCriteria } = useSelector((state: any) => state.approvalCriteriaReducer);
    const { approvalProcess, successMessage } = useSelector((state: any) => state.approvalProcessReducer);
    const { client } = useSelector((state: any) => state.clientReducer);

    const [steps, setSteps] = useState<stepProvider[]>([]);
    const [criterials, setCriterials] = useState<criterialList[]>([]);
    const [criteriaObject, setCriteriaObject] = useState(Object);
    const [approved, setApproved] = useState<boolean[]>([]);
    const [continuousStep, setContinuousStep] = useState(true);
    const [error, setError] = useState('');

    const [errors, setErrors] = useState({
        antiquity: '',
        creditScore: '',
        debt: '',
        location: '',
        paymentCapacity: '',
        borrowingCapacity: '',
        listRiskPeople: ''
    });

    const [criterialsList, setCriterialList] = useState(approvalCriteriaList());
    const [antiquity, setAntiquity] = useState('');
    const [creditScore, setCreditScore] = useState('');
    const [debt, setDebt] = useState('');
    const [location, setLocation] = useState<[{ label: string; value: string }]>();
    const [paymentCapacity, setPaymentCapacity] = useState('');
    const [borrowingCapacity, setBorrowingCapacity] = useState('');

    const [renderApprovalCriteria, setrenderApprovalCriteria] = useState(true);
    const [dataModal, setDataModal] = useState({
        isVertically: false,
        name: '',
        element: {}
    });

    //Show Lists
    const approvalFlowButton = useRef<HTMLButtonElement>(null);
    const approvalFlowList = useRef<HTMLUListElement>(null);
    const approvalCriterialsButton = useRef<HTMLButtonElement>(null);
    const approvalCriterialsList = useRef<HTMLUListElement>(null);

    const showList = (list: HTMLUListElement, button: HTMLButtonElement) => {
        if (list.style.display === 'block') {
            list.style.display = 'none';
            button.style.color = '#272733';
            button.children[0].classList.value = 'feather icon-chevron-down';
        }
        else {
            list.style.display = 'block';
            button.style.color = '#1274E7';
            button.children[0].classList.value = 'feather icon-chevron-up';
        }
    }

    useEffect(() => {
        if (Object.keys(user).length === 0) {
            history.push('admin/login');
        }
        dispatch(getApprovalSteps());
        dispatch(resetApprovalProcessStatus());
        dispatch(getApprovalFlowrefProcess(flowRef));
        dispatch(resetApprovalCriteriaStatus());
        dispatch(getApprovalCriteria(flowRef));
        dispatch(
            refreshToken({
                token: localStorage.getItem('token'),
                refreshToken: localStorage.getItem('refreshToken')
            })
        );
    }, []);

        useEffect(() => {
        (function loadCriterialList() {
            if (Object.keys(approvalCriteria).length > 0) {
                if (criterials.length === 0) {
                    Object.entries(approvalCriteria).forEach(([key, value]) => {
                        const array = [key];
                        if (value != null && value != false) {
                            criterialsList.forEach(async (criterial: any) => {
                                if (criterial.alias == array[0]) {
                                    criterials.unshift(criterial);
                                }
                            });
                        }
                    });
                }
            }
        })()
    },[user]);

    useEffect(() => {
        let aux:any = [];
        Object.keys(approvalProcess).length !== 0 && setSteps(approvalProcess.steps);
        steps.forEach((element:any) => { aux.push(element.continuousApprovalFlow) });
        setApproved(aux);
    }, [approvalProcess]);

    const _renderInfoElement = (step: any, index: Number) => {
        return (
            <li className="list-group-item border-top-0" onClick={() => addStep(step)}>
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <p className="h5 mt-2 font-weight-bold">{step.stepName}</p>
                    <button
                        type="button"
                        name={step.stepName}
                        id={index.toString()}
                        className="btn btn-icon btn-third feather icon-plus"
                        onClick={() => {
                            addStep(step);
                        }}
                    />
                </div>
            </li>
        );
    };

    const _renderCriterialList = (element: any) => {
        return (
            <li className="list-group-item border-top-0 d-flex flex-row justify-content-between align-items-center">
                <p className="h5 mt-2 font-weight-bold">{element.Name}</p>
                <button
                    type="button"
                    name={element.Name}
                    className="btn btn-icon btn-warning feather icon-plus"
                    onClick={async () => {
                        await setDataModal({ name: element.Name, isVertically: true, element: element });
                    }}
                />
            </li>
        );
    };

    //function to add a new criterial in the List and save the array on local storge to keep the persistence
    const addCriterial = (criterial: any) => {
        setError('');
        const auxList = [...criterials];
        if (auxList !== undefined && auxList.filter((aux) => aux.Name === criterial.Name).length === 0) {
            auxList.push(criterial);
        }
        setCriterials(auxList);
    };

    //Function to delete the criterial selected in base a the id of each element
    const deletecriterial = (e: any, criterial: any) => {
        const param: string = criterial.Name;
        const auxList = [...criterials];
        auxList.splice(Number(e.target.id), 1);
        console.log('criterial', criterial);
        setCriterials(auxList);
        switch (criterial.Name) {
            case 'Antiguedad de la Empresa':
                payload('antiquity', null);
                break;
            case 'Score Crediticio':
                payload('creditScore', null);
                break;
            case 'Deuda':
                payload('debt', null);
                break;
            case 'Ubicacion':
                payload('location', null);
                break;
            case 'Capacidad de Pago':
                payload('paymentCapacity', null);
                break;
            case 'Capacidad de Endeudamiento':
                payload('borrowingCapacity', null);
                break;
            case 'Validacion de lista de Riesgo':
                payload('listRiskPeople', false);
                break;
            default:
                break;
        }
    };

    //function to add a new step un the flow and save the array on local storge to keep the persistence
    const addStep = (step: any) => {
        setError('');
        const auxStep = [...steps];
        if (auxStep !== undefined && auxStep.filter((aux) => aux.stepName === step.stepName).length === 0) {
            auxStep.push(step);
        }
        setSteps(auxStep);
    };

    //Function to move a step up in the flow order, base it in the id of each element
    const moveStepUp = (e: any) => {
        const auxStep = [...steps];
        const element = auxStep[e.target.id];

        auxStep[Number(e.target.id)] = auxStep[Number(e.target.id) - 1];
        auxStep[Number(e.target.id) - 1] = element;
        setSteps(auxStep);
    };

    //Function to move a step down in the flow order, base it in the id of each element
    const moveStepDown = (e: any) => {
        const auxStep = [...steps];
        const element = auxStep[e.target.id];

        auxStep[Number(e.target.id)] = auxStep[Number(e.target.id) + 1];
        auxStep[Number(e.target.id) + 1] = element;

        setSteps(auxStep);
    };

    //Function to delete the step selected in base a the id of each element
    const deleteStep = (e: any) => {
        const auxStep = [...steps];
        auxStep.splice(Number(e.target.id), 1);
        setSteps(auxStep);
    };

    const payload = (param: string, value: any) => {
        dispatch(postApprovalCriteria({ flowRef, [param]:value }));
    };

    const saveSteps = () => {
        const auxError = steps.length === 0 && criteriaObject.length === 0 ? 'El proceso de aprobación no puede estar vacío. favor de agregar algun paso' : '';
        setError(auxError);

        if (auxError) return;
        const payload = {
            steps: steps,
            userRef: user.id,
            flowRef
        };
        dispatch(postApprovalProcess(payload));
        dispatch(postApprovalCriteria(criteriaObject));
    };

    const saveOrUpdateApprovalCriteria = () => {
        if (dataModal.name == 'Ubicacion') {
            const locationError = location !== undefined && location.length > 0 ? '' : 'Este campo no puede estar vacío';
            setErrors({ ...errors, location: locationError });
            if (locationError !== '') return;
            const auxLocation: string[] = [];
            location?.filter((location) => {
                auxLocation.push(location.value);
            });
            addCriterial(dataModal.element);
            setCriteriaObject({...criteriaObject, 'location':auxLocation, 'flowRef':flowRef});
            setDataModal({ ...dataModal, isVertically: false });
        }
        if (dataModal.name == 'Capacidad de Pago') {
            const paymentCapacityError = paymentCapacity ? '' : 'Este campo no puede estar vacío';
            setErrors({ ...errors, paymentCapacity: paymentCapacityError });
            if (paymentCapacityError !== '') return;
            addCriterial(dataModal.element);
            setCriteriaObject({...criteriaObject, 'paymentCapacity':paymentCapacity, 'flowRef':flowRef})
            setDataModal({ ...dataModal, isVertically: false });
        }
        if (dataModal.name == 'Score Crediticio') {
            const creditScoreError = creditScore === '' ? 'Este campo no puede estar vacío' : '';
            setErrors({ ...errors, creditScore: creditScoreError });
            if (creditScoreError !== '') return;
            addCriterial(dataModal.element);
            setCriteriaObject({...criteriaObject, 'creditScore':creditScore, 'flowRef':flowRef});
            setDataModal({ ...dataModal, isVertically: false });
        }
        if (dataModal.name == 'Deuda') {
            const debtError = debt === '' ? 'Este campo no puede estar vacío' : '';
            setErrors({ ...errors, debt: debtError });
            if (debtError !== '') return;
            addCriterial(dataModal.element);
            setCriteriaObject({...criteriaObject, 'debt':debt, 'flowRef':flowRef});
            setDataModal({ ...dataModal, isVertically: false });
        }
        if (dataModal.name == 'Capacidad de Endeudamiento') {
            const borrowingCapacityError = borrowingCapacity === '' ? 'Este campo no puede estar vacío' : '';
            setErrors({ ...errors, borrowingCapacity: borrowingCapacityError });
            if (borrowingCapacityError !== '') return;
            addCriterial(dataModal.element);
            setCriteriaObject({...criteriaObject, 'borrowingCapacity':borrowingCapacity, 'flowRef':flowRef });
            setDataModal({ ...dataModal, isVertically: false });
        }
        if (dataModal.name == 'Antiguedad de la Empresa') {
            const antiquityError = antiquity !== '' ? '' : 'Este campo no puede estar vacío';
            setErrors({ ...errors, antiquity:antiquityError });
            if (antiquityError !== '') return;
            addCriterial(dataModal.element);
            setCriteriaObject({...criteriaObject, 'antiquity':antiquity, 'flowRef':flowRef});
            setDataModal({ ...dataModal, isVertically: false });
        }
    };
    
    const handleSwitch = async (stepName: any, index: number) => {   
        let aux = [...approved];
        await steps.forEach((step: any) => {
            if(step.stepName == stepName){
                if(aux[index] == false){
                    step.continuousApprovalFlow = true;
                    aux[index] = true;
                    setApproved(aux);
                }else{
                    step.continuousApprovalFlow = false;
                    aux[index] = false;
                    setApproved(aux);
                }
            }
        })
    };

    return (
        <Row className='approvalCriterial'>
            <Col xl={3} md={12}>
                <Card className="task-board-left approvalCriterial-card">
                    <Card.Header className='approvalCriterial-header'>
                        <Card.Title className='approvalCriterial-title' as="h4">Proceso de aprobación</Card.Title>
                    </Card.Header>
                    <Card.Body className="card-body approvalCriterial-body">
                        <button
                            ref={approvalFlowButton}
                            onClick={() => {
                                if(null !== approvalFlowList.current && null !== approvalFlowButton.current) {
                                    showList(approvalFlowList.current, approvalFlowButton.current);
                                }
                            }}
                            className="approvalCriterial-buttonList"
                        >
                            Flujos de aprobación
                            <i className='feather icon-chevron-down' />
                        </button>
                        <ul className="list-group list-group-flush approvalCriterial-list" ref={approvalFlowList}>
                            {approvalSteps.map((step: any, index: number) => _renderInfoElement(step, index))}
                        </ul>
                        <button
                            ref={approvalCriterialsButton}
                            onClick={() => {
                                if(null !== approvalCriterialsList.current && null !== approvalCriterialsButton.current) {
                                    showList(approvalCriterialsList.current, approvalCriterialsButton.current);
                                }
                            }}
                            className="approvalCriterial-buttonList"
                        >
                            Criterios de aprobación
                            <i className='feather icon-chevron-down' />
                        </button>
                        <ul className="list-group list-group-flush approvalCriterial-list" ref={approvalCriterialsList}>
                            {criterialsList.map((criterial: any, index: number) => _renderCriterialList(criterial))}
                        </ul>
                    </Card.Body>
                </Card>
            </Col>
            <Col xl={9} md={12}>
                <Card className='approvalCriterial-card'>
                    <Card.Header className="d-flex flex-row justify-content-between approvalCriterial-header">
                        <Col>
                            <Card.Title className='approvalCriterial-title text-left' as="h4">Personalización del proceso de aprobación</Card.Title>
                            <p className="mt-3 font-weight-normal approvalCriterial-description">
                                En esta sección personalizarás el proceso de aprobación para los créditos, son los pasos de la información
                                que se mostrarán al administrador o se solicitarán al cliente para la recopilación de esta información
                            </p>
                        </Col>
                        <div className="mt-3">
                            {/* <button
                                type="button"
                                className="btn btn-icon btn-danger feather mr-3 icon-arrow-left"
                                onClick={() => {
                                    dispatch(resetApprovalProcessStatus());
                                    history.push('/admin/flujos');
                                }}
                            /> */}
                            {
                                renderApprovalCriteria ? (
                                    <button
                                    type="button"
                                    className="btn btn-icon btn-secondary mr-3 feather icon-x approvalCriterial-exitBtn"
                                    onClick={() => {
                                        dispatch(resetApprovalProcessStatus());
                                        setrenderApprovalCriteria(false);
                                    }}
                                />
                                ) : (
                                    <button
                                    type="button"
                                    className="btn btn-icon btn-warning mr-3  feather icon-settings approvalCriterial-settingsBtn"
                                    onClick={() => {
                                        dispatch(resetApprovalProcessStatus());
                                        setrenderApprovalCriteria(true);
                                    }}
                                />
                                )
                            }
                        </div>
                    </Card.Header>
                    {renderApprovalCriteria ? (
                        <ApprovalCriteria />
                    ) : (
                        <Card.Body>
                            <Col>
                                <h4 className='approvalCriterial-tableTitle'>Flujos de aprobación</h4>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th className="text-center">Nombre del Paso</th>
                                            <th>Descripción</th>
                                            <th>Paso Continuo</th>
                                            <th className="text-center">Acciones</th>
                                        </tr>
                                    </thead>
                                    {steps.map((step: any, index: number) => {
                                        return (
                                            <tbody>
                                                <tr>
                                                    <td className="align-middle text-center">
                                                        <p className="h5 mt-2 font-weight-bold">{step.stepName}</p>
                                                    </td>
                                                    <td className="">
                                                        <p className="h5 mt-2 text-wrap">{step.description}</p>
                                                    </td>
                                                    <td>
                                                    <Form.Group>
                                                            <Form.Label>NO</Form.Label>
                                                            <div className="switch switch-info d-inline m-r-5 m-l-5">
                                                                <Form.Control
                                                                    type="checkbox"
                                                                    id={`unchecked-info-${index}`}
                                                                    name={index.toString()}
                                                                    checked={approved[index]}
                                                                    onChange={() => {handleSwitch(step.stepName, index)}}
                                                                />
                                                                <Form.Label htmlFor={`unchecked-info-${index}`} className="cr" />
                                                            </div>
                                                            <Form.Label>SI</Form.Label>
                                                        </Form.Group> 
                                                    </td>
                                                    <td className="d-flex">
                                                        {index !== steps.length - 1 && (
                                                            <button
                                                                type="button"
                                                                id={index.toString()}
                                                                className="btn btn-icon btn-info mr-3 feather icon-arrow-down approvalCriterial-rowBtn"
                                                                onClick={moveStepDown}
                                                            />
                                                        )}
                                                        {index !== 0 && (
                                                            <button
                                                                type="button"
                                                                id={index.toString()}
                                                                className="btn btn-icon btn-info mr-3 feather icon-arrow-up approvalCriterial-rowBtn"
                                                                onClick={moveStepUp}
                                                            />
                                                        )}
                                                        <button
                                                            type="button"
                                                            id={index.toString()}
                                                            className="btn btn-icon mr-3 btn-danger feather icon-trash approvalCriterial-trashBtn"
                                                            onClick={deleteStep}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        );
                                    })}
                                </Table>
                                <h4 className='approvalCriterial-tableTitle'>Criterios de aprobación</h4>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th className="text-center">Nombre del Paso</th>
                                            <th>Descripción</th>
                                            <th className="text-center">Acciones</th>
                                        </tr>
                                    </thead>
                                    {Object.keys(criterials).length > 0 && (
                                    criterials.map((criterial: any, index: number) => {
                                        return (
                                            <tbody>
                                                <tr>
                                                    <td className="align-middle text-center">
                                                        <p className="h5 mt-2 font-weight-bold">{criterial.Name}</p>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <p className="h5 mt-2 text-wrap">{criterial.description}</p>
                                                    </td>
                                                    <td className="d-flex">
                                                        <button
                                                            type="button"
                                                            id={index.toString()}
                                                            className="btn btn-icon mr-3 btn-danger feather icon-trash approvalCriterial-trashBtn"
                                                            onClick={(e) => deletecriterial(e, criterial)}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        );
                                    }))}
                                </Table>
                            </Col>
                            <Col className="d-flex flex-row justify-content-end mt-4 mr-5" xl={12}>
                                <Button className="btn-border-radius approvalCriterial-saveBtn" variant="third" size={'lg'} onClick={saveSteps}>
                                    <dt>Guardar</dt>
                                </Button>
                            </Col>
                        </Card.Body>
                    )}
                </Card>
            </Col>
            <Modal size="lg" centered show={dataModal.isVertically} onHide={() => setDataModal({ ...dataModal, isVertically: false })}>
                <Modal.Header closeButton className='approvalCriterial-header'>
                    <Modal.Title as="h5" className='approvalCriterial-title'>Criterio de Aprobación</Modal.Title>
                </Modal.Header>
                <Modal.Body className='approvalCriterial-modalBody'>
                    <p className='approvalCriterial-description'>En esta sección debemos definir los criterios mínimos de aceptación para las solicitudes de crédito, esto nos ayudará a realizar un análisis más sencillo de la información.</p>
                        {dataModal.name === 'Antiguedad de la Empresa' && (
                            <>
                            <div className="input-group" style={{ margin: '5%  0%' }}>
                                <label className="ml-3 approvalCriterial-label">Antigüedad de la empresa:</label>
                                <p  className="col-12 approvalCriterial-description">{criterialsList[0].description}</p>
                                <input
                                    className="form-control approvalCriterial-control"
                                    type="text"
                                    placeholder="ü de la empresa (expresada en años)"
                                    onChange={(e: any) => {
                                        setAntiquity(e.target.value);
                                        setErrors({ ...errors, antiquity: '' });
                                    }}
                                />
                            </div>
                            {errors.antiquity && (
                                <p className="form-text text-left text-danger ml-4">
                                    {errors.antiquity}
                                </p>
                            )}
                            </>
                        )}
                        {dataModal.name === 'Score Crediticio' && (
                            <>
                            <div className="input-group d-block" style={{ margin: '5%  0%' }}>
                                <label className='ml-3 approvalCriterial-label'>Score crediticio:</label>
                                <p className="col-12 approvalCriterial-description">{criterialsList[1].description}</p>
                                <input
                                    className="form-control approvalCriterial-control"
                                    style={{ width: '100%' }}
                                    type="text"
                                    placeholder="Score crediticio"
                                    onChange={(e: any) => {
                                        setCreditScore(e.target.value);
                                        setErrors({ ...errors, creditScore: '' });
                                    }}
                                />
                            </div>
                            {errors.creditScore && (
                                <p className="form-text text-left text-danger ml-4">
                                    {errors.creditScore}
                                </p>
                            )}
                            </>
                        )}
                        {dataModal.name === 'Deuda' && (
                            <>
                                <div className="input-group d-block" style={{ margin: '5%  0%' }}>
                                    <label className="ml-2 ml-md-4 approvalCriterial-label">Deuda máxima permitida:</label>
                                    <p className="col-12 ml-2 approvalCriterial-description">{criterialsList[2].description}</p>
                                    <input
                                        className="form-control approvalCriterial-control"
                                        style={{ width: '100%'}}
                                        type="text"
                                        placeholder="Deuda máxima permitida"
                                        onChange={(e) => {
                                            setDebt(e.target.value.trim());
                                            setErrors({ ...errors, debt: '' });
                                        }}
                                    />
                                </div>
                                {errors.debt && <p className="form-text text-left text-danger ml-4">{errors.debt}</p>}
                            </>
                        )}
                        {dataModal.name === 'Ubicacion' && (
                            <>
                                <div className="input-group d-block" style={{ margin: '5%  0%' }}>
                                    <label className="d-flex ml-3 approvalCriterial-label">Ubicación:</label>
                                    <p className="col-12 approvalCriterial-description">{criterialsList[3].description}</p>
                                    <Select
                                        isMulti
                                        name="colors"
                                        value={location}
                                        options={states}
                                        style={{ width: '100%' }}
                                        className="approvalCriterial-control approvalCriterial-controlSelect"
                                        classNamePrefix="select"
                                        placeholder="Estados"
                                        onChange={(e: any) => {
                                            setLocation(e);
                                            setErrors({ ...errors, location: '' });
                                        }}
                                    />
                                </div>
                                {errors.location && (
                                    <p className="form-text text-left text-danger ml-4">
                                        {errors.location}
                                    </p>
                                )}
                            </>
                        )}
                        {dataModal.name === 'Capacidad de Pago' && (
                            <>
                            <div className="input-group d-block" style={{ margin: '5%  0%' }}>
                                <label className="ml-2 approvalCriterial-label">Capacidad de pago:</label>
                                <p className="col-12 approvalCriterial-description">{criterialsList[4].description}</p>
                                <input
                                    className="form-control  approvalCriterial-control"
                                    style={{ width: '100%' }}
                                    type="text"
                                    placeholder="Capicidad de Pago"
                                    onChange={(e: any) => {
                                        setPaymentCapacity(e.target.value);
                                        setErrors({ ...errors, paymentCapacity: '' });
                                    }}
                                />
                            </div>
                            {errors.paymentCapacity && <p className="form-text text-left text-danger ml-4">{errors.paymentCapacity}</p>}
                            </>
                        )}
                        {dataModal.name === 'Capacidad de Endeudamiento' && (
                            <>
                                <div className="input-group d-block" style={{ margin: '5%  0%' }}>
                                    <label className='approvalCriterial-label'>Capacidad de Endeudamiento:</label>
                                    <p className="col-12 p-0 approvalCriterial-description">{criterialsList[5].description}</p>
                                    <input
                                        className="form-control approvalCriterial-control"
                                        style={{ width: '100%', margin: '5px' }}
                                        type="text"
                                        placeholder="Capicidad de endeudamiento"
                                        onChange={(e: any) => {
                                            setBorrowingCapacity(e.target.value);
                                            setErrors({ ...errors, borrowingCapacity: '' });
                                        }}
                                    />
                                </div>
                                {errors.borrowingCapacity && (
                                    <p className="form-text text-left text-danger ml-4">
                                        {errors.borrowingCapacity}
                                    </p>
                                )}
                            </>
                        )}
                        {dataModal.name === 'Validacion de lista de Riesgo' && (
                            <div className="input-group d-flex " style={{ margin: '5%  0%' }}>
                                <label className='approvalCriterial-label'>Deseas agregar validación de lista de riesgo:</label>
                                <p className="col-12 p-0 approvalCriterial-description">{criterialsList[6].description}</p>
                                <Button
                                    className="secondary approvalCriterial-saveBtn approvalCriterial-saveBtnModal"
                                    variant={'outline-primary'}
                                    onClick={() => {
                                        setCriteriaObject({...criteriaObject, 'listRiskPeople':true, 'flowRef':flowRef});
                                        addCriterial(dataModal.element);
                                        setDataModal({ ...dataModal, isVertically: false });
                                    }}
                                >
                                    Agregar
                                </Button>
                                {errors.borrowingCapacity && (
                                    <p className="form-text text-left text-danger ml-4" style={{ margin: '0px auto 50px' }}>
                                        {errors.borrowingCapacity}
                                    </p>
                                )}
                            </div>
                        )}
                </Modal.Body>
                {dataModal.name !== 'Validacion de lista de Riesgo' && (
                    <Modal.Footer className="m-3 approvalCriterial-footer">
                        <Button
                            variant="secondary"
                            className="m-3 approvalCriterial-saveBtn"
                            onClick={() => {
                                saveOrUpdateApprovalCriteria();
                            }}
                        >
                            Agregar
                        </Button>
                    </Modal.Footer>
                )}
            </Modal>
        </Row>
    );
};

export default CreateApprovalProcess;