export const states = [
    {label: "Aguascalientes", value: 'Aguascalientes'},
    {label: "Baja California", value: 'Baja California'},
    {label: "Baja California Sur", value: 'Baja California Sur'},
    {label: "Campeche", value: 'Campeche'},
    {label: "Chiapas", value: 'Chiapas'},
    {label: "Chihuahua", value: 'Chihuahua'},
    {label: "Ciudad de México", value: 'Ciudad de México'},
    {label: "Coahuila", value: 'Coahuila'},
    {label: "Colima", value: 'Colima'},
    {label: "Durango", value: 'Durango'},
    {label: "Guanajuato", value: 'Guanajuato'},
    {label: "Guerrero", value: 'Guerrero'},
    {label: "Hidalgo", value: 'Hidalgo'},
    {label: "Jalisco", value: 'Jalisco'},
    {label: "México", value: 'México'},
    {label: "Michoacán", value: 'Michoacán'},
    {label: "Morelos", value: 'Morelos'},
    {label: "Nayarit", value: 'Nayarit'},
    {label: "Nuevo León", value: 'Nuevo León'},
    {label: "Oaxaca", value: 'Oaxaca'},
    {label: "Puebla", value: 'Puebla'},
    {label: "Querétaro", value: 'Querétaro'},
    {label: "Quintana Roo", value: 'Quintana Roo'},
    {label: "San Luis Potosí", value: 'San Luis Potosí'},
    {label: "Sinaloa", value: 'Sinaloa'},
    {label: "Sonora", value: 'Sonora'},
    {label: "Tabasco", value: 'Tabasco'},
    {label: "Tamaulipas", value: 'Tamaulipas'},
    {label: "Tlaxcala", value: 'Tlaxcala'},
    {label: "Veracruz", value: 'Veracruz'},
    {label: "Yucatán", value: 'Yucatán'},
    {label: "Zacatecas", value: 'Zacatecas'}
]
