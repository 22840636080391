import { FC, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../assets/scss/style.scss';
import { clearAuthLoading } from '../../flux/actions/auth-actions';
import { renewpassword, resetRenewpasswordStatus } from '../../flux/actions/renewpassword-actions';
import { useHistory, NavLink } from 'react-router-dom';
import logo from '../../assets/images/kualinetLogo.png';
import { Form, ProgressBar, Row, Col, Alert } from 'react-bootstrap';
import validator from 'validator';
import * as Validations from '../../helpers/validations';
import { useTimer } from 'use-timer';
import { sendOtpPassword } from '../../flux/actions/otp-actions';
import Slider from '../../components/Slider';

const cryptoJS = require("crypto-js");

const RenewPassword: FC = () => {
    const { time, start } = useTimer({
        initialTime: 120,
        autostart: false,
        endTime: 0,
        onTimeOver: () => {
            setResendNIP(true);
        },
        timerType: 'DECREMENTAL'
    });

    const history = useHistory();
    const dispatch = useDispatch();

    const { successMessage, errorMessage } = useSelector((state: any) => state.renewpasswordReducer);
    const [OTP, setOTP] = useState('');
    const [NIP, setNIP] = useState('');
    const [resendNIP, setResendNIP] = useState(false);
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({ email: '', password: '', confirmPassword: '', NIP: '', lifeTime: '' });
    const [isOtpSended, setIsOtpSended] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordConditions, setPasswordConditions] = useState(false);
    const [passwordProgress, setPasswordProgress] = useState(0);
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        dispatch(resetRenewpasswordStatus())
    }, []);

    useEffect(() => {
        dispatch(clearAuthLoading());
    }, [dispatch]);

    useEffect(() => {
        const delay = setTimeout(() => {
        if(successMessage.message == 'Contraseña actualizada' && redirect){
            history.push('/login');
            clearTimeout(delay);
        } 
        },2500);
    }, [redirect, successMessage]);
    
    let conditionsPassword = useRef<HTMLDivElement>(null);
    let heroImages = useRef<HTMLDivElement>(null);

    const changeHeight = (images: HTMLDivElement) => {
        if (window.innerHeight < 830) {
            images.style.height = '100%';
        } else {
            images.style.height = '100vh';
        }
    };

    const changeHeightDefault = (images: HTMLDivElement) => {
        if (window.innerHeight < 670) {
            images.style.height = '100%';
        } else {
            images.style.height = '100vh';
        }
    };

    //hook para actualizar la contraseña y aumentar la barra de progreso conforme se cumplen ciertos requerimientos
    useEffect(() => {
        async function fetchData() {
            const validation = await validator.isStrongPassword(password, { returnScore: true });
            setPasswordProgress(Number(validation) * 2);
        }

        if (password !== '') {
            setPasswordConditions(true);
            if (null !== heroImages.current) {
                changeHeight(heroImages.current);
            }
            fetchData();
        } else {
            setPasswordConditions(false);
            if (null !== heroImages.current) {
                changeHeightDefault(heroImages.current);
            }
        }
    }, [password]);

    // Funcion para generar una contraseña aleatoria temporal, esta funcion generra una contraseña de 6 digitos numericos
    // ademas de enviar un correo electronico con dicha contraseña
    const generateOTP = async () => {
        const emailErrors = validator.isEmail(email) ? '' : 'Correo inválido';

        setErrors({ ...errors, email: emailErrors });
        if (emailErrors) return;

        start();
        var digits = '0123456789';
        let OneTimePassword = '';

        for (let i = 0; i < 6; i++) {
            OneTimePassword += digits[Math.floor(Math.random() * 10)];
        }

        let cipherOTP = cryptoJS.AES.encrypt(OneTimePassword, '9xNPoxyu').toString();

        setIsOtpSended(!isOtpSended);
        setOTP(OneTimePassword);
        dispatch(sendOtpPassword(cipherOTP, email));
    };

    const resendOTP = async () => {
        start();
        var digits = '0123456789';
        let OneTimePassword = '';

        for (let i = 0; i < 6; i++) {
            OneTimePassword += digits[Math.floor(Math.random() * 10)];
        }

        let cipherOTP = cryptoJS.AES.encrypt(OneTimePassword, '9xNPoxyu').toString();

        setOTP(OneTimePassword);
        dispatch(sendOtpPassword(cipherOTP, email));
    };

    //Funcion para hacer la verificación y el envio de la nueva contraseña si no ocurre ningun error en las validaciónes de la información
    const ChangePassword = async () => {
        const lifeTimeError = !resendNIP ? '' : 'El tiempo del NIP expiró';
        const emailErrors = validator.isEmail(email) ? '' : 'Correo inválido';
        const passwordErrors = validator.isStrongPassword(password) ? '' : 'Contraseña inválida';
        const errorConfirmPwd = Validations.matchPasswords(password, confirmPassword) ? '' : 'La contraseña no coincide';
        const NIPError = NIP === OTP ? '' : 'El PIN no coincide';

        setErrors({
            email: emailErrors,
            password: passwordErrors,
            confirmPassword: errorConfirmPwd,
            NIP: NIPError,
            lifeTime: lifeTimeError
        });

        const payload = {
                email,
                password
        };

        if (emailErrors || passwordErrors || errorConfirmPwd || NIPError || lifeTimeError) return;
        dispatch(renewpassword(payload));
        setRedirect(true);
    };

    return (
        <>
            {!isOtpSended ? (
                <div className="resetPassword">
                    <div className="resetPassword-hero">
                        <Slider />
                    </div>
                    <div className="resetPassword-container">
                        <div className="resetPassword-content">
                            <img src={logo} alt="Logo" className="resetPassword-logo" />
                            <h4 className="resetPassword-title">Recuperar contraseña</h4>
                            <div className="form-group">
                                <input
                                    type="email"
                                    value={email}
                                    className="form-control"
                                    id="email"
                                    name="disabledCP"
                                    autoComplete="off"
                                    placeholder="Correo electrónico"
                                    onChange={(e) => {
                                        setEmail(e.target.value.trim());
                                        setErrors({ ...errors, email: '' });
                                    }}
                                />
                                {errors.email && (
                                    <p id="emailHelpBlock" className="form-text text-left text-danger">
                                        {errors.email}
                                    </p>
                                )}
                            </div>
                            <button className="resetPassword-button" onClick={generateOTP}>
                                Continuar
                            </button>
                            <NavLink to="/login" className="resetPassword-backbutton">
                                <i className="feather icon-arrow-left"></i> Volver
                            </NavLink>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="newPassword">
                    <div className="newPassword-hero" ref={heroImages}>
                        <Slider />
                    </div>
                    <div className="newPassword-container">
                        <div className="newPassword-content">
                            <img src={logo} alt="Logo" className="newPassword-logo" />
                            <h6 className="newPassword-title">Cambia tu contraseña</h6>
                            <div className="form-group">
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    name="disabledCP"
                                    autoComplete="off"
                                    placeholder="Contraseña"
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value.trim());
                                        setErrors({ ...errors, password: '' });
                                    }}
                                />
                                {errors.password && (
                                    <p id="passwordHelpBlock" className="form-text text-left text-danger">
                                        {errors.password}
                                    </p>
                                )}
                                {passwordConditions && (
                                    <div className="newPassword-success" ref={conditionsPassword}>
                                        <p>Tu contraseña debe contener:</p>
                                        <ProgressBar variant="success" now={passwordProgress} />
                                        <ul>
                                            <li>8 caracteres</li>
                                            <li>Una minúscula (a-z)</li>
                                            <li>Una mayúscula (A-Z)</li>
                                            <li>Un número (0-9)</li>
                                            <li>Un caracter especial (*?$)</li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <div className="form-group">
                                <input
                                    type="password"
                                    className="form-control"
                                    id="confirm password"
                                    name="disabledCP"
                                    placeholder="Confirmar contraseña"
                                    autoComplete="off"
                                    value={confirmPassword}
                                    onChange={(e) => {
                                        setConfirmPassword(e.target.value.trim());
                                        setErrors({ ...errors, confirmPassword: '' });
                                    }}
                                />
                                {errors.confirmPassword && (
                                    <p id="passwordHelpBlock" className="form-text text-left text-danger">
                                        {errors.confirmPassword}
                                    </p>
                                )}
                            </div>
                            <div className="form-group">
                                <Form.Control
                                    type="text"
                                    placeholder="NIP"
                                    maxLength={6}
                                    name="disabledCP"
                                    value={NIP.toString()}
                                    onChange={(e) => {
                                        setNIP(e.target.value.trim());
                                        setErrors({ ...errors, NIP: '' });
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                {errors.lifeTime && (
                                    <p id="passwordHelpBlock" className="form-text text-left text-danger">
                                        {errors.lifeTime}
                                    </p>
                                )}
                                {errors.NIP && (
                                    <p id="passwordHelpBlock" className="form-text text-left text-danger">
                                        {errors.NIP}
                                    </p>
                                )}
                                {successMessage.message && (
                                    <Alert style={{marginTop: '8px'}} variant="success">{successMessage.message}</Alert>
                                )}
                                {errorMessage.message && (
                                    <Alert style={{marginTop: '8px'}} variant="danger">{errorMessage.message}</Alert>
                                )}
                                <p className="newPassword-text">Se envió un NIP de 6 dígitos a tu correo, si el correo ya ha sido previamente registrado.</p>
                                <button className="newPassword-button" onClick={ChangePassword}>
                                    <b>Recuperar contraseña</b>
                                </button>
                            </div>
                            <Row>
                                {!resendNIP ? (
                                    <Col>
                                        <p className="newPassword-subtext">
                                            0{Math.trunc(time / 60)}:
                                            {time % 60 === 0
                                                ? '00'
                                                : time % 60 > 10
                                                ? (time % 60).toFixed(0)
                                                : '0' + (time % 60).toFixed(0)}
                                        </p>
                                    </Col>
                                ) : (
                                    <button
                                        className="newPassword-buttonNip"
                                        onClick={() => {
                                            setResendNIP(false);
                                            resendOTP();
                                            setErrors({ ...errors, lifeTime: '' });
                                        }}
                                    >
                                        <dt>Solicitar NIP</dt>
                                    </button>
                                )}
                            </Row>
                            <NavLink to="/login" className="newPassword-backbutton">
                                <i className="feather icon-arrow-left"></i> Volver
                            </NavLink>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default RenewPassword;
