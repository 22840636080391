import React, { useEffect, useState } from 'react';

export const useGeoLocation = () => {
    const [location, setLocation] = useState({
        loaded: false,
        coordinates: { latitude: '', longitude: '' }
    });

    const onSuccess = (location: any) => {
        setLocation({
            loaded: true,
            coordinates: {
                latitude: location.coords.latitude,
                longitude: location.coords.longitude
            }
        });
    };

    const onError = () => {
        'Geolocation not sopported';
    };

    useEffect(() => {
        if (!('geolocation' in navigator)) {
            onError();
        }
        navigator.geolocation.getCurrentPosition(onSuccess, onError);
    }, []);

    return location;
};
