import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import quoteModel from '../../models/quote-model';
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import { postQuote, getCredit, resetQuoteStatus } from '../../flux/actions/quote-actions';
import Loader from '../../components/ScreenLoader/ScreenLoader';
import { getDefaultFlow } from '../../flux/actions/flow-actions';
import { Modal, Row, Button } from 'react-bootstrap';
import { restoreFlowController } from '../../flux/actions/client-actions';
import logo from '../../assets/images/kualinetLogo.png';
import QuoterBackground from '../../components/QuoterBackground';
import SliderImg from '../../components/Slider';
import { refreshToken } from '../../flux/actions/refreshToken-action';

const economicActivities = [
    { value: 'PF', label: 'Persona física con actividad empresarial' },
    { value: 'PM', label: 'Persona moral' }
];

const Handle = Slider.Handle;

const SliderHandle = (props: any) => {
    const { value, dragging, index, ...restProps } = props;
    return (
        <Tooltip prefixCls="rc-slider-tooltip" overlay={value + ' Meses'} visible={dragging} placement="top" key={index}>
            <Handle value={value as number} {...restProps} />
        </Tooltip>
    );
};

const SliderHandleAmount = (props: any) => {
    const { value, dragging, index, ...restProps } = props;
    const amount = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN', minimumFractionDigits: 2 }).format(value);
    return (
        <Tooltip prefixCls="rc-slider-tooltip" overlay={amount} visible={dragging} placement="top" key={index}>
            <Handle value={value as number} {...restProps} />
        </Tooltip>
    );
};

//Slider Variables
let min = 50000;
let max = 1000000;
let step = 1000;
let defaultValue = 500000;

const Quoter = () => {
    //redux variables
    const history = useHistory();
    const dispatch = useDispatch();
    const { client } = useSelector((state: any) => state.clientReducer);
    const { flow } = useSelector((state: any) => state.flowReducer);
    const { credit, loading } = useSelector((state: any) => state.quoteReducer);

    //Local variables
    const [quoteInformation, setQuoteInformation] = useState({ value: 500000, monts: 12 });
    const [planOption, setPlanOption] = useState(1);
    const [economicActivity, setEconomicActivity] = useState('PF');
    const [quoteResult, setQuoteResult] = useState(0);
    const [destinationCredit, setDestinationCredit] = useState('');
    const [value, setValue] = useState(defaultValue);
    const [showConfirmInfo, setShowConfirmInfo] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [existingQuoter, setExistingQuoter] = useState('');
    const [flowType, setFlowType] = useState(0);

    //State to handle errors
    const [errors, setErrors] = useState({
        amount: '',
        mont: '',
        planOption: '',
        economicActivity: '',
        quoteResult: '',
        destinationCredit: ''
    });

    useEffect(() => {
        dispatch(resetQuoteStatus());
        if (client && client.id) {
            dispatch(getCredit(client.id));
            dispatch(getDefaultFlow());
            dispatch(
                refreshToken({
                    token: localStorage.getItem('token'),
                    refreshToken: localStorage.getItem('refreshToken')
                })
            );
        }
    }, []);

    useEffect(() => {
        if (Object.keys(flow).length > 0) {
            flow?.map((flow: any, index: number) => {
                if (flow.taxRegime == client.taxRegime) {
                    setFlowType(index);
                }
            });
        }
    }, [flow, client]);

    useEffect(() => {
        setExistingQuoter(client.id);
        //function to redirect if the customer have a quoter allready createdl
        if (existingQuoter !== undefined && existingQuoter !== '' && flow) {
            // if (credit.flowRef !== flow[flowType]?._id) {
            //     restoreFlow();
            // } else {
            if (credit._id) {
                if (client.onBoardingStep >= flow[flowType]?.steps.length) {
                    history.push('/usuario/inicio');
                }
                if (client.onBoardingStep < flow[flowType]?.steps.length) {
                    history.push('/on-boarding');
                }
            }
            //}
        }
    }, [credit, flow]);

    useEffect(() => {
        calculateQuote();
    }, [quoteInformation, economicActivity]);

    const restoreFlow = async () => {
        if (credit.length !== 0) {
            await dispatch(restoreFlowController({ quoteRef: credit._id, clientRef: credit.clientRef, taxregime: economicActivity }));
            history.push('/on-boarding');
        }
    };

    //function to calculate amount depending of the selected product
    const calculateQuote = () => {
        const taxes = planOption === 1 ? 0.025 : 0.0166;
        if (economicActivity !== '0') {
            const Ci = quoteInformation.value * taxes;
            const exponential = 1 - Math.pow(1 + taxes, -quoteInformation.monts);
            setQuoteResult(Ci / exponential);
        }
    };

    //Main function to validate the provided information, render the errors if they exist otherwise we send the petition to save in the data base
    const sendQuote = () => {
        const errorAmount = quoteInformation.value <= 50000 && quoteInformation.value >= 1000000 ? 'Rango de monto inválido' : '';
        const errorMonts = quoteInformation.monts < 6 || quoteInformation.monts > 48 ? 'Rango de meses inválido' : '';
        const errorPlanOption = planOption < 1 || planOption > 2 ? 'Plan inválido' : '';
        const errorEconomicActivities = economicActivity === '0' ? 'Actividad económica no válida' : '';
        const errorQuoteResult = quoteResult === 0 ? 'No se ha realizado el cálculo de la mensualidad' : '';
        const errorDestinationCredit = destinationCredit === '' ? 'Este campo no puede estar vacío' : '';

        setErrors({
            amount: errorAmount,
            mont: errorMonts,
            planOption: errorPlanOption,
            economicActivity: errorEconomicActivities,
            quoteResult: errorQuoteResult,
            destinationCredit: errorDestinationCredit
        });
        setShowConfirmInfo(!showConfirmInfo);
        if (errorAmount || errorMonts || errorPlanOption || errorEconomicActivities || errorQuoteResult || errorDestinationCredit) return;

        const payload: quoteModel = {
            planRef: planOption.toString(),
            economicActivity: economicActivity.toString(),
            amount: quoteInformation.value,
            dateRequested: new Date(),
            monts: quoteInformation.monts,
            montlyPayment: quoteResult,
            destinationCredit,
            clientRef: client.id
        };
        dispatch(postQuote(payload));
        history.push('/on-boarding');
    };

    //condition to render a screen loader or the quoter view
    return loading ? (
        <div className="auth-wrapper align-items-center">
            <Loader isOpen={true} />
        </div>
    ) : (
        <div className="quoter">
            <div className="quoter-hero">
                <SliderImg />
                 {/* <QuoterBackground months={quoteInformation.monts} amount={quoteInformation.value} /> */}
            </div>
            <div className="quoter-container">
                <div className="quoter-content">
                    <img src={logo} alt="logo" className="quoter-logo" />
                    <h2 className="quoter-title">Solicitud de crédito</h2>
                    <div className="quoter-form">
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                name="disabledCP"
                                id="destinationCredit"
                                autoComplete="off"
                                placeholder="¿Cuál es el destino de tu crédito?"
                                value={destinationCredit}
                                onChange={(e) => {
                                    setDestinationCredit(e.target.value);
                                    setErrors({ ...errors, destinationCredit: '' });
                                }}
                            />
                            {errors.destinationCredit && (
                                <p id="destinationCredit" className="form-text text-left text-danger">
                                    {errors.destinationCredit}
                                </p>
                            )}
                        </div>
                        <div className="form-group">
                            <p className="quoter-text">¿Qué actividad económica realizas?</p>
                            <select
                                className="form-control"
                                onChange={(e) => {
                                    setEconomicActivity(e.target.value);
                                    setPlanOption(e.target.value === 'PF' ? 1 : e.target.value === 'PM' ? 2 : 1);
                                    calculateQuote();
                                    setErrors({ ...errors, economicActivity: '' });
                                }}
                            >
                                {economicActivities.map((activity) => {
                                    return <option value={activity.value}>{activity.label}</option>;
                                })}
                            </select>
                        </div>
                        <div className="form-group">
                            <div className="form-group-card">
                                <h3>
                                    Selecciona tu monto
                                    <i className="fas fa-money-bill-wave ml-1" />
                                </h3>
                                <div className="form-group-section">
                                    {quoteInformation.value > min ? (
                                        <button
                                            onClick={() => {
                                                setValue((quoteInformation.value -= step));
                                                calculateQuote();
                                            }}
                                        >
                                            -
                                        </button>
                                    ) : (
                                        <button>-</button>
                                    )}
                                    <dt>
                                        {new Intl.NumberFormat('es-MX', {
                                            style: 'currency',
                                            currency: 'MXN',
                                            minimumFractionDigits: 2
                                        }).format(quoteInformation.value)}
                                    </dt>
                                    {quoteInformation.value < max ? (
                                        <button
                                            onClick={() => {
                                                setValue((quoteInformation.value += step));
                                                calculateQuote();
                                            }}
                                        >
                                            +
                                        </button>
                                    ) : (
                                        <button>+</button>
                                    )}
                                </div>
                                <Slider
                                    className="pc-range-slider"
                                    defaultValue={defaultValue}
                                    step={step}
                                    min={min}
                                    max={max}
                                    handle={SliderHandleAmount}
                                    onChange={(e) => {
                                        setQuoteInformation({ ...quoteInformation, value: e });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-group-card">
                                <h3>
                                    Selecciona tu plazo
                                    <i className="fas fa-calendar-check ml-1" />
                                </h3>
                                <div className="form-group-section">
                                    <dt>{quoteInformation.monts} Meses</dt>
                                </div>
                                <Slider
                                    className="pc-range-slider"
                                    min={6}
                                    max={planOption === 1 ? 24 : 48}
                                    defaultValue={12}
                                    handle={SliderHandle}
                                    onChange={(e) => setQuoteInformation({ ...quoteInformation, monts: e })}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-group-card">
                                <h3>Pago mensual estimado</h3>
                                <dt>
                                    {new Intl.NumberFormat('es-MX', {
                                        style: 'currency',
                                        currency: 'MXN',
                                        minimumFractionDigits: 2
                                    }).format(quoteResult)}
                                </dt>
                            </div>
                        </div>
                        {/* <button className="quoter-buttonText" onClick={() => setShowTable(!showTable)}>
                            Ver Tabla de Amortización
                        </button> */}
                        {/* {showTable && <QuoterBackground months={quoteInformation.monts} amount={quoteInformation.value} />} */}
                        <button className="quoter-button" onClick={() => setShowConfirmInfo(!showConfirmInfo)}>
                            Solicitar
                        </button>
                        <Modal show={showConfirmInfo} onHide={() => setShowConfirmInfo(!showConfirmInfo)}>
                            <Modal.Header closeButton className="quoter-modal-header">
                                <Modal.Title as="h5">Confirma tu cotización</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="quoter-modal-body">
                                <p>Esta es la información de tu cotización:</p>
                                <ul>
                                    <li>
                                        Destino del crédito:<b> {destinationCredit}</b>
                                    </li>
                                    <li>
                                        Régimen fiscal: <> </>
                                        <b>
                                            {economicActivity === 'PF'
                                                ? economicActivities[0].label
                                                : economicActivity === 'PM'
                                                ? economicActivities[1].label
                                                : null}
                                        </b>
                                    </li>
                                    <li>
                                        Monto solicitado: <> </>
                                        <b>
                                            {new Intl.NumberFormat('es-MX', {
                                                style: 'currency',
                                                currency: 'MXN',
                                                minimumFractionDigits: 2
                                            }).format(quoteInformation.value)}
                                        </b>
                                    </li>
                                    <li>
                                        Plazo: <b>{quoteInformation.monts} Meses</b>
                                    </li>
                                    <li>
                                        Pago mensual tentativo: <> </>
                                        <b>
                                            {new Intl.NumberFormat('es-MX', {
                                                style: 'currency',
                                                currency: 'MXN',
                                                minimumFractionDigits: 2
                                            }).format(quoteResult)}
                                        </b>
                                    </li>
                                </ul>
                                <p>¿Deseas continuar?</p>
                            </Modal.Body>
                            <Modal.Footer className="quoter-modal-footer">
                                <Row className="mt-3 mb-3">
                                    <Button className="ml-3 btn-border-radius" onClick={sendQuote}>
                                        Aceptar
                                    </Button>
                                </Row>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Quoter;
