enum PERMISSION_CONSTANTS {
    POST_PERMISSION_BEGIN = "POST_PERMISSION_BEGIN",
    POST_PERMISSION_ERROR = "POST_PERMISSION_ERROR",
    POST_PERMISSION_SUCCESS = "POST_PERMISSION_SUCCESS",

    GET_PERMISSION_BEGIN = "GET_PERMISSION_BEGIN",
    GET_PERMISSION_ERROR = "GET_PERMISSION_ERROR",
    GET_PERMISSION_SUCCESS = "GET_PERMISSION_SUCCESS",

    RESET_PERMISSION_STATUS = "RESET_PERMISSION_STATUS"
};

export default PERMISSION_CONSTANTS;