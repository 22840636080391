import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import validator from 'validator';
import '../../assets/scss/style.scss';
import { login, resetClientStatus } from '../../flux/actions/client-actions';
import logo from '../../assets/images/kualinetLogo.png';
import Slider from '../../components/Slider';
import { resetQuoteStatus } from '../../flux/actions/quote-actions';

const Login: FC = () => {
    //Redux vars
    const history = useHistory();
    const dispatcher = useDispatch();
    const client = useSelector((state: any) => state.clientReducer.client);
    const clientReducer = useSelector((state: any) => state.clientReducer);
    //local vars
    const [CURP, setCURP] = useState('');
    const [password, setPassword] = useState('');
    //errors
    const [errors, setErrors] = useState({ password: '', email: '', CURP: '', login: '' });

    useEffect(() => {
        dispatcher(resetClientStatus());
    }, [dispatcher]);

    useEffect(() => {
        if (clientReducer.errorStatus) {
            setErrors({ ...errors, login: 'Usuario o contraseña inválidos' });
        }
    }, [clientReducer.errorStatus]);

    //function to redirect when the client logged in the system
    useEffect(() => {
        if (Object.keys(client).length !== 0) {
            dispatcher(resetQuoteStatus());
            if (client.onBoardingStep >= 9 && client.taxRegime === 'PM') {
                history.push('/usuario/inicio');
            }
            if (client.onBoardingStep >= 8 && client.taxRegime === 'PF') {
                history.push('/usuario/inicio');
            } else {
                history.push('/cotizador-principal');
            }
        }
    }, [client, dispatcher, history]);

    //function to validate the CURP
    const validateCURP = (curp: string) => {
        curp.toUpperCase();
        var re =
                /^[A-Z]{4}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/,
            validado = curp.match(re);

        if (!validado)
            //Coincide con el formato general?
            return false;
        return true; //Validado
    };

    //main function to validate the inputs and the information, once is validated we request the login
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const errorEmail = validator.isEmail(CURP) ? '' : 'Correo inválido';
        const errorCURP = validateCURP(CURP.toLocaleUpperCase()) ? '' : 'CURP inválido';
        const passwordErrors = validator.isStrongPassword(password) ? '' : 'Contraseña inválida';

        setErrors({
            email: errorEmail,
            CURP: errorCURP,
            password: passwordErrors,
            login: ''
        });
        if (errorCURP && passwordErrors) return;
        dispatcher(login({ client: { CURP: CURP.toLocaleUpperCase(), password } }));

        if (errorEmail && passwordErrors) return;
        dispatcher(login({ client: { CURP, password } }));
    };

    return (
        <>
            <div className="login">
                <div className="login-hero">
                    <Slider />
                </div>
                <div className="login-container">
                    <div className="login-content">
                        <img src={logo} alt="Logo" className="login-logo" />
                        <h2 className="login-title">Iniciar Sesión</h2>
                        <form className="login-form" onSubmit={onSubmit}>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="CURP"
                                    placeholder="Correo electrónico o CURP"
                                    name="disabledCP"
                                    autoComplete="off"
                                    value={CURP}
                                    onChange={(e) => {
                                        setCURP(e.target.value.trim());
                                        setErrors({ ...errors, CURP: '', email: '' });
                                    }}
                                />
                                {errors.CURP && errors.email && (
                                    <p className="form-text text-left text-danger">
                                        {errors.CURP} o {errors.email}
                                    </p>
                                )}
                            </div>
                            <div className="form-group">
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    placeholder="Contraseña"
                                    name="disabledCP"
                                    autoComplete="off"
                                    onChange={(e) => {
                                        setPassword(e.target.value.trim());
                                        setErrors({ ...errors, password: '' });
                                    }}
                                />
                                {errors.password && (
                                    <p id="passwordHelpBlock" className="form-text text-left text-danger">
                                        {errors.password}
                                    </p>
                                )}
                                <NavLink to="/recuperar-contraseña" className="login-resetPassword">
                                    ¿Olvidaste tu contraseña?
                                </NavLink>
                            </div>
                            <div className="form-group">
                                {errors.login && (
                                    <p id="loginHelpBlock" className="form-text text-danger">
                                        {errors.login}
                                    </p>
                                )}
                                <button className="login-button" type="submit">
                                    <b>Iniciar sesión</b>
                                </button>
                            </div>
                            <p className="login-text">¿Aún no tienes cuenta con nosotros?</p>
                            <NavLink to="/signup" className="login-buttonSignup">
                                Iniciar solicitud de crédito
                            </NavLink>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Login;
