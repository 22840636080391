import { Card, Row, Table, Button, Modal, Alert, Col } from 'react-bootstrap';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRccPm } from '../../../../flux/actions/circleCredit-actions';
import { getCredit, rejectQuote, updateStatus } from '../../../../flux/actions/quote-actions';
import { getOtp } from '../../../../flux/actions/otp-actions';
import { getApprovalProcess } from '../../../../flux/actions/approvalProcess-actions';
import { sendAuthMail } from '../../../../flux/actions/mail-actions';
import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getBussinessInfo } from '../../../../flux/actions/bussinessInfo-actions';
import { estadoMapping } from '../../../../helpers/statesCatalog';

const MoralPersonCreditReport: React.FC = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const clientRef = (params as any)?.id;
    const { creditReport } = useSelector((state: any) => state.circleCreditReducer);
    const { approvalProcess } = useSelector((state: any) => state.approvalProcessReducer);
    const client = useSelector((state: any) => state.clientReducer.client);
    const quote: any = useSelector((state: any) => state.quoteReducer.credit);
    const [showApprovalModal, setShowApprovalModal] = useState(false);
    const [showCancelRequest, setShowCancelRequest] = useState(false);
    const otp = useSelector((state: any) => state.otpReducer.OTP);
    const [temporality, setTemporality] = useState(false);
    const bussinessInfo = useSelector((state: any) => state.bussinessInfoReducer);
    const [values, setValues] = useState('');

    useEffect(() => {
        dispatch(getApprovalProcess(clientRef));
        dispatch(getOtp(clientRef));
        dispatch(getBussinessInfo(clientRef));
    }, []);

    function ApproveMoralPersonCreditReport() {
        let stepIndex = 0;
        const approvalProcessClient = approvalProcess.steps?.filter((step: any) => {
            return step.clientStatus !== 'generateCreditCircleNIP';
        });
        approvalProcess.steps.filter((step: any, index: number) => {
            if (step.adminStatus === quote.adminStatus) {
                stepIndex = index + 1;
                if (stepIndex < approvalProcess.steps.length) {
                    if (approvalProcess.steps[index].continuousApprovalFlow) {
                        dispatch(
                            updateStatus({
                                clientRef,
                                type: 'adminStatus',
                                status: approvalProcess.steps[stepIndex].adminStatus
                            })
                        );
                    } else {
                        dispatch(
                            updateStatus({
                                clientRef,
                                type: 'updateBothStates',
                                status: approvalProcessClient[stepIndex].clientStatus,
                                adminStatus: approvalProcess.steps[stepIndex].adminStatus
                            })
                        );
                    }
                } else if (stepIndex === approvalProcess.steps.length) {
                    dispatch(
                        updateStatus({
                            clientRef,
                            type: 'adminStatus',
                            status: 'creditProposal'
                        })
                    );
                }
            }
        });
        setTimeout(() => {
            dispatch(getCredit(clientRef));
        }, 1000);
        setShowApprovalModal(!showApprovalModal);
    }

    function cancelRequest() {
        dispatch(rejectQuote({ client: client.id }));
        setShowCancelRequest(!showCancelRequest);
        dispatch(getCredit(clientRef));
        dispatch(
            sendAuthMail({
                mailType: 3,
                email: client.email,
                name: client.names,
                lastName1: client.lastName1,
                lastName2: client.lastName2,
                folio: quote.folio,
                amount: quote.amount
            })
        );
    }

    const ConsultCreditCircleTest = () => {
        return (
            <Card className="mt-3">
                <Button
                    className="btn-border-radius moral-successBtn"
                    variant="secondary"
                    onClick={() => {
                        //DUMMY TEST
                        dispatch(
                            getRccPm({
                                clientRef,
                                folioOtorgante: '1000001',
                                rfc: 'EDC930121E01',
                                nombre: 'RESTAURANTE SA DE CV',
                                direccion: 'AV. PASEO DE LA REFORMA 01',
                                coloniaPoblacion: 'GUERRERO',
                                delegacionMunicipio: 'CUAUHTEMOC',
                                ciudad: 'CIUDAD DE MÉXICO',
                                estado: 'CDMX',
                                cp: '09890',
                                pais: 'MX'
                            })
                        );
                        setTemporality(!temporality);
                    }}
                >
                    Consulta a Círculo de Crédito
                </Button>
            </Card>
        );
    };

    const ConsultCreditCircle = () => {
        const direccion = bussinessInfo?.bussinessInfo?.street + ' ' + bussinessInfo?.bussinessInfo?.numExt;
        let country: string = bussinessInfo?.bussinessInfo?.country === 'México' ? 'MX' : '';
        let state = estadoMapping[bussinessInfo?.bussinessInfo?.state];
        let undefinedValues = '';

        //Verifica que esté completa la información
        let handlePayloadError: any = {
            'Folio Otorgante': otp?.NIP,
            RFC: bussinessInfo?.bussinessInfo?.RFC,
            Nombre: bussinessInfo?.bussinessInfo?.denomination,
            'Direccion del negocio': direccion,
            'Colonia/Poblacion del negocio': bussinessInfo?.bussinessInfo?.suburb,
            'Delegacion/Municipio del negocio': bussinessInfo?.bussinessInfo?.municipality,
            'Ciudad del negocio': bussinessInfo?.bussinessInfo?.city,
            'Estado del negocio': state,
            'Codigo Postal del negocio': bussinessInfo?.bussinessInfo?.zipCode,
            'Pais del negocio': country
        };
        function validatePayload() {
            for (const value in handlePayloadError) {
                if (!handlePayloadError[value]) {
                    undefinedValues += `${value},  `;
                }
            }
            return undefinedValues;
        }

        setValues(validatePayload());
        if (undefinedValues !== '') {
            return (
                <Card className="mt-3">
                    <Row>
                        <Col>
                            <p>
                                <strong>
                                    NIP: <br />
                                    <span className="moral-info">{otp?.NIP}</span>
                                </strong>
                            </p>
                        </Col>
                        <Col>
                            <p>
                                <strong>
                                    Fecha de Creación de NIP: (GMT-0600 hora estándar central)
                                    <br />
                                    <span className="moral-info">
                                        {new Date(otp?.createdAt).toLocaleString('es-MX', { timeZone: 'America/Mexico_City' })}
                                    </span>
                                </strong>
                            </p>
                        </Col>
                    </Row>
                    <Button className="btn-border-radius moral-successBtn" variant="danger">
                        No es posible realizar la Consulta a Círculo de Crédito
                    </Button>
                    {values !== '' && (
                        <Alert variant="danger" className="text-center ml-3 mr-3 text-dark" style={{ fontSize: 16 }}>
                            Favor de revisar los siguientes datos: {values}
                        </Alert>
                    )}
                </Card>
            );
        }
        return (
            <Card className="mt-3">
                <Row>
                        <Col>
                            <p>
                                <strong>
                                    NIP: <br />
                                    <span className="moral-info">{otp?.NIP}</span>
                                </strong>
                            </p>
                        </Col>
                        <Col>
                            <p>
                                <strong>
                                    Fecha de Creación de NIP: (GMT-0600 hora estándar central)
                                    <br />
                                    <span className="moral-info">
                                        {new Date(otp?.createdAt).toLocaleString('es-MX', { timeZone: 'America/Mexico_City' })}
                                    </span>
                                </strong>
                            </p>
                        </Col>
                    </Row>
                <Button
                    className="btn-border-radius moral-successBtn"
                    variant="secondary"
                    onClick={() => {
                        dispatch(
                            getRccPm({
                                clientRef,
                                folioOtorgante: bussinessInfo?.bussinessInfo?.folioOtorgante,
                                rfc: bussinessInfo?.bussinessInfo?.RFC,
                                nombre: bussinessInfo?.bussinessInfo?.denomination,
                                direccion: direccion,
                                coloniaPoblacion: bussinessInfo?.bussinessInfo?.suburb,
                                delegacionMunicipio: bussinessInfo?.bussinessInfo?.municipality,
                                ciudad: bussinessInfo?.bussinessInfo?.city,
                                estado: state,
                                cp: bussinessInfo?.bussinessInfo?.zipCode,
                                pais: country
                            })
                        );
                        setTemporality(!temporality);
                    }}
                >
                    Consulta a Círculo de Crédito
                </Button>
            </Card>
        );
    };

    return (
        <>
            <Card className="approvalFlow-moral">
                {!temporality ? (
                    <ConsultCreditCircle />
                ) : (
                    <>
                        <h1 className="text-center m-2 moral-title">Reporte de Crédito</h1>
                        <h4 className="text-center m-2 moral-subtitle">Personas Morales</h4>
                        <Card.Header className="approvalFlow-moral-header">
                            <h4>Información</h4>
                            <Row>
                                <Col>
                                    <p>
                                        <strong>
                                            NIP: <br />
                                            <span className="moral-info">{otp?.NIP}</span>
                                        </strong>
                                    </p>
                                </Col>
                                <Col>
                                    <p>
                                        <strong>
                                            Fecha de Creación de NIP: (GMT-0600 hora estándar central)
                                            <br />
                                            <span className="moral-info">
                                                {new Date(otp?.createdAt).toLocaleString('es-MX', { timeZone: 'America/Mexico_City' })}
                                            </span>
                                        </strong>
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <strong>
                                            RFC: <br />
                                            <span className="moral-info">{creditReport.MoralPerson?.persona?.rfc}</span>
                                        </strong>
                                    </p>
                                </Col>
                                <Col>
                                    <p>
                                        <strong>
                                            Nombre: <br />
                                            <span className="moral-info">{creditReport.MoralPerson?.persona?.nombre}</span>
                                        </strong>
                                    </p>
                                </Col>
                                <Col>
                                    <p>
                                        <strong>
                                            Nacionalidad: <br />
                                            <span className="moral-info">{creditReport.MoralPerson?.persona?.nacionalidad}</span>
                                        </strong>
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <strong>
                                            Folio de consulta: <br />
                                            <span className="moral-info">{creditReport.MoralPerson?.folioConsulta}</span>
                                        </strong>
                                    </p>
                                </Col>
                                <Col>
                                    <p>
                                        <strong>
                                            Fecha de consulta: <br />
                                            <span className="moral-info">{creditReport.MoralPerson?.fechaConsulta}</span>
                                        </strong>
                                    </p>
                                </Col>
                                <Col>
                                    <p>
                                        <strong>
                                            Folio otorgante: <br />
                                            <span className="moral-info">{creditReport.MoralPerson?.folioOtorgante}</span>
                                        </strong>
                                    </p>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body className="approvalFlow-moral-body">
                            <h4 className="ml2">Domicilio Fiscal</h4>
                            <Table responsive className="table-styling">
                                <thead>
                                    <th>Código postal</th>
                                    <th>Ciudad</th>
                                    <th>Colonia</th>
                                    <th>Municipio</th>
                                    <th>Dirección</th>
                                    <th>Teléfono</th>
                                </thead>
                                <tr>
                                    <td>{creditReport.MoralPerson?.persona?.domicilio?.cp}</td>
                                    <td>{creditReport.MoralPerson?.persona?.domicilio?.ciudad}</td>
                                    <td>{creditReport.MoralPerson?.persona?.domicilio?.coloniaPoblacion}</td>
                                    <td>{creditReport.MoralPerson?.persona?.domicilio?.delegacionMunicipio}</td>
                                    <td>{creditReport.MoralPerson?.persona?.domicilio?.direccion}</td>
                                    <td>{creditReport.MoralPerson?.persona?.domicilio?.telefono}</td>
                                </tr>
                            </Table>
                        </Card.Body>
                        <Card.Body className="approvalFlow-moral-body">
                            <h4 className="ml-2">Cuentas Comerciales</h4>
                            <Table responsive className="table-styling mb-2">
                                <thead className="table-primary">
                                    <tr>
                                        <th className="h6">#</th>
                                        <th className="h6">Otorgante</th>
                                        <th className="h6">RFC</th>
                                        <th className="h6">Saldo Total</th>
                                        <th className="h6">Actualización</th>
                                        <th className="h6">Atraso Mayor</th>
                                        <th className="h6">Moneda</th>
                                        <th className="h6">Vigente</th>
                                    </tr>
                                </thead>
                                {creditReport.MoralPerson?.credito?.cuentasComerciales?.map((credit: any, index: number) => {
                                    return (
                                        <tr>
                                            <td scope="row">{index + 1}</td>
                                            <td>{credit.nombreOtorgante}</td>
                                            <td>{credit.rfc}</td>
                                            <td>{credit.saldoTotal}</td>
                                            <td>{credit.actualizacion}</td>
                                            <td>{credit.atrasoMayor}</td>
                                            <td>{credit.moneda}</td>
                                            <td>{credit.vigente}</td>
                                        </tr>
                                    );
                                })}
                            </Table>
                        </Card.Body>
                        <Card.Body className="approvalFlow-moral-body">
                            <h4 className="ml-2">Cuentas Financieras</h4>
                            <Table responsive className="table-styling">
                                <thead className="table-primary">
                                    <tr>
                                        <th className="h5">#</th>
                                        <th className="h5">Otorgante</th>
                                        <th className="h5">RFC</th>
                                        <th className="h5">Contrato</th>
                                        <th className="h5">Saldo Inicial</th>
                                        <th className="h5">Saldo Total</th>
                                        <th className="h5">Plazo</th>
                                        <th className="h5">Fecha de Apertura</th>
                                        <th className="h5">Fecha de Cierre</th>
                                        <th className="h5">Atraso Mayor</th>
                                        <th className="h5">Quita</th>
                                        <th className="h5">Dación</th>
                                        <th className="h5">Registro Impugnado</th>
                                        <th className="h5">Tipo de Crédito</th>
                                        <th className="h5">Moneda</th>
                                        <th className="h5">Vigente</th>
                                    </tr>
                                </thead>
                                {creditReport.MoralPerson?.credito?.cuentasFinancieras?.map((credit: any, index: number) => {
                                    return (
                                        <tr>
                                            <td scope="row">{index + 1}</td>
                                            <td>{credit.nombreOtorgante}</td>
                                            <td>{credit.rfc}</td>
                                            <td>{credit.contrato}</td>
                                            <td>{credit.saldoInicial}</td>
                                            <td>{credit.saldoTotal}</td>
                                            <td>{credit.plazo} días</td>
                                            <td>{credit.fechaApertura}</td>
                                            <td>{credit.fechaCierre}</td>
                                            <td>{credit.atrasoMayor} días</td>
                                            <td>{credit.quita}</td>
                                            <td>{credit.dacionPago}</td>
                                            <td>{credit.registroImpugnado}</td>
                                            <td>{credit.tipoCredito}</td>
                                            <td>{credit.moneda}</td>
                                            <td>{credit.vigente}</td>
                                        </tr>
                                    );
                                })}
                            </Table>
                        </Card.Body>
                        <Card.Body className="approvalFlow-moral-body">
                            <h4 className="ml-2">Accionistas</h4>
                            <Table responsive className="table-styling">
                                <thead className="table-primary">
                                    <tr>
                                        <th className="h6">#</th>
                                        <th className="h6">Nombre</th>
                                        <th className="h6">Apellido Materno</th>
                                        <th className="h6">Apellido Paterno</th>
                                        <th className="h6">CURP</th>
                                        <th className="h6">RFC</th>
                                        <th className="h6">Participación</th>
                                    </tr>
                                </thead>
                                {creditReport.MoralPerson?.accionistas?.map((shareholder: any, index: number) => {
                                    return (
                                        <tr>
                                            <td scope="row">{index + 1}</td>
                                            <td>{shareholder.nombre}</td>
                                            <td>{shareholder.apellidoMaterno}</td>
                                            <td>{shareholder.apellidoPaterno}</td>
                                            <td>{shareholder.curp}</td>
                                            <td>{shareholder.rfc}</td>
                                            <td>{shareholder.porcentaje}%</td>
                                        </tr>
                                    );
                                })}
                            </Table>
                        </Card.Body>
                        <Card.Body className="approvalFlow-moral-body">
                            <h4 className="ml-2">Avales</h4>
                            <Table responsive className="table-styling">
                                <thead className="table-primary">
                                    <tr>
                                        <th className="h6">#</th>
                                        <th className="h6">Nombre</th>
                                        <th className="h6">Segundo Nombre</th>
                                        <th className="h6">Apellido Materno</th>
                                        <th className="h6">Apellido Paterno</th>
                                        <th className="h6">CURP</th>
                                        <th className="h6">RFC</th>
                                    </tr>
                                </thead>
                                {creditReport.MoralPerson?.avales?.map((affiliate: any, index: number) => {
                                    return (
                                        <tr>
                                            <td scope="row">{index + 1}</td>
                                            <td>{affiliate.nombre}</td>
                                            <td>{affiliate.segundoNombre}</td>
                                            <td>{affiliate.apellidoMaterno}</td>
                                            <td>{affiliate.apellidoPaterno}</td>
                                            <td>{affiliate.curp}</td>
                                            <td>{affiliate.rfc}</td>
                                        </tr>
                                    );
                                })}
                            </Table>
                        </Card.Body>
                        <Card.Body className="approvalFlow-moral-body">
                            <h4 className="ml-2">Prevenciones</h4>
                            <Table responsive className="table-styling">
                                <thead className="table-primary">
                                    <tr>
                                        <th className="h6">#</th>
                                        <th className="h6">Clave</th>
                                        <th className="h6">Otorgante</th>
                                        <th className="h6">Fecha de Reporte</th>
                                        <th className="h6">Contrato</th>
                                    </tr>
                                </thead>
                                <tr>
                                    <td scope="row">{1}</td>
                                    <td>{creditReport.MoralPerson?.clavePrevenciones?.clavePrevencion}</td>
                                    <td>{creditReport.MoralPerson?.clavePrevenciones?.nombreOtorgante}</td>
                                    <td>{creditReport.MoralPerson?.clavePrevenciones?.fechaReporte}</td>
                                    <td>{creditReport.MoralPerson?.clavePrevenciones?.numeroContrato}</td>
                                </tr>
                            </Table>
                        </Card.Body>
                        <Card.Body className="approvalFlow-moral-body">
                            <h4 className="ml-2">Calificación de cartera</h4>
                            <Table responsive className="table-styling">
                                <thead className="table-primary">
                                    <tr>
                                        <th className="h5">#</th>
                                        <th className="h5">Nombre de Otorgante</th>
                                        <th className="h5">Calificación</th>
                                    </tr>
                                </thead>
                                {creditReport.MoralPerson?.calificacionCartera?.map((qualification: any, index: number) => {
                                    return (
                                        <tr>
                                            <td scope="row">{index + 1}</td>
                                            <td>{qualification.nombreOtorgante}</td>
                                            <td>{qualification.calificacion}</td>
                                        </tr>
                                    );
                                })}
                            </Table>
                        </Card.Body>
                        <Card.Body className="approvalFlow-moral-body">
                            <h4 className="ml-2">Declarativas</h4>
                            <Table responsive className="table-styling">
                                <thead className="table-primary">
                                    <tr>
                                        <th className="h5">#</th>
                                        <th className="h5">Declarativa</th>
                                        <th className="h5">Fecha</th>
                                    </tr>
                                </thead>
                                {creditReport.MoralPerson?.declarativas?.map((statement: any, index: number) => {
                                    return (
                                        <tr>
                                            <td scope="row">{index + 1}</td>
                                            <td>{statement.declarativa}</td>
                                            <td>{statement.fechaDeclarativa}</td>
                                        </tr>
                                    );
                                })}
                            </Table>
                            {creditReport.message !== '' && creditReport.message !== undefined && (
                                <Card.Header className="approvalFlow-moral-header text-center">
                                    <Card.Title as="h5" className="moral-alertSuccess">
                                        {creditReport.message}
                                    </Card.Title>
                                </Card.Header>
                            )}
                        </Card.Body>
                        <Card.Footer className="approvalFlow-moral-footer">
                            <Card.Title as="h5">
                                ¿El prospecto cumple con los requerimientos necesarios en Buró de Crédito para continuar?
                            </Card.Title>
                            <Row className="mt-3 ml-3">
                                <Button
                                    className="btn-border-radius moral-successBtn"
                                    variant={'success'}
                                    onClick={() => {
                                        setShowApprovalModal(!showApprovalModal);
                                    }}
                                >
                                    Si
                                </Button>
                                <Button
                                    variant={'danger'}
                                    className="ml-3 btn-border-radius moral-alertBtn"
                                    onClick={() => {
                                        setShowCancelRequest(!showCancelRequest);
                                    }}
                                >
                                    No
                                </Button>
                            </Row>
                        </Card.Footer>
                    </>
                )}
                <Modal show={showApprovalModal} onHide={() => setShowApprovalModal(!showApprovalModal)}>
                    <Modal.Header closeButton className="approvalFlow-moralModal-header">
                        <Modal.Title as="h5">Aprobar estado en Círculo de Crédito</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="approvalFlow-moralModal-body">
                        <p>
                            El prospecto cumple con los requerimirntos necesarios en CÍRCULO DE CRÉDITO para continuar
                            <br />
                            <br />
                            ¿Deseas continuar?
                        </p>
                    </Modal.Body>
                    <Modal.Footer className="approvalFlow-moral-footer">
                        <Row className="mt-3 mb-3">
                            <Button
                                className="btn-border-radius moral-alertBtn"
                                variant="danger"
                                onClick={() => setShowApprovalModal(!showApprovalModal)}
                            >
                                Cancelar
                            </Button>
                            <Button
                                className="ml-3 btn-border-radius moral-successBtn"
                                variant="secondary"
                                onClick={ApproveMoralPersonCreditReport}
                            >
                                Aprobar
                            </Button>
                        </Row>
                    </Modal.Footer>
                </Modal>
                <Modal show={showCancelRequest} onHide={() => setShowCancelRequest(!showCancelRequest)}>
                    <Modal.Header closeButton className="approvalFlow-moralModal-header">
                        <Modal.Title as="h5">
                            El usuario NO cumple con la requerimientos necesarios para la solicitud del crédito
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="approvalFlow-moralModal-body">
                        <p>
                            El prospecto no cumple con los requisitos necesarios en Círculo de Crédito para ser candidato a un crédito. Esta
                            cotización quedará CANCELADA
                            <br />
                            <br />
                            ¿Deseas continuar?
                        </p>
                    </Modal.Body>
                    <Modal.Footer className="approvalFlow-moral-footer">
                        <Row className="mt-3 mb-3">
                            <Button
                                className="btn-border-radius moral-alertBtn"
                                variant="danger"
                                onClick={() => setShowCancelRequest(!showCancelRequest)}
                            >
                                Cancelar
                            </Button>
                            <Button className="ml-3 btn-border-radius moral-successBtn" variant="success" onClick={cancelRequest}>
                                Aceptar
                            </Button>
                        </Row>
                    </Modal.Footer>
                </Modal>
            </Card>
        </>
    );
};

export default MoralPersonCreditReport;
