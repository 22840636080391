import { Dispatch } from "redux";
import { interceptor } from '../../services/interceptor';
import { API } from "../api/mail-api";
import MAIL_CONSTANTS from "../constants/mail-constants";
import * as types from '../types/mail-types';

//---------------------------------- SEND MAIL --------------------------------------------------
const sendMailBegin = (data: any): types.IMailSendBegin => {
    return {
        type: MAIL_CONSTANTS.MAIL_SEND_BEGIN,
    };
}

const sendMailError = (e: any, data: any): types.IMailSendError => {
    return {
        type: MAIL_CONSTANTS.MAIL_SEND_ERROR,
        error: e
    };
}

const sendMailSuccess = (data: any): types.IMailSendSuccess => {
    return {
        type: MAIL_CONSTANTS.MAIL_SEND_SUCCESS,
        data
    };
}

export const sendMail = (data: any) => {
    return (dispatch: Dispatch) => {
        dispatch(sendMailBegin(data));
        API.sendMail(data)
            .then((response: any) => {
                localStorage.setItem('token', response.data.client.token);
                dispatch(sendMailSuccess(response.data.client));
            })
            .catch((error: any) => {
                dispatch(sendMailError(error.response, data));
            });
    };
}

//---------------------------------- SEND AUTH MAIL --------------------------------------------------
const sendAuthMailBegin = (data: any): types.IAuthMailSendBegin => {
    return {
        type: MAIL_CONSTANTS.AUTH_MAIL_SEND_BEGIN,
    };
}

const sendAuthMailError = (e: any, data: any): types.IAuthMailSendError => {
    return {
        type: MAIL_CONSTANTS.AUTH_MAIL_SEND_ERROR,
        error: e,
    };
}

const sendAuthMailSuccess = (data: any): types.IAuthMailSendSuccess => {
    return {
        type: MAIL_CONSTANTS.AUTH_MAIL_SEND_SUCCESS,
        data
    };
}

export const sendAuthMail = (data: any) => {
    return (dispatch: Dispatch) => {
        dispatch(sendAuthMailBegin(data));
        API.sendAuthMail(data)
            .then((response: any) => {
                localStorage.setItem('token', response.data.client.token);
                interceptor.addToken(response.data.client.token);
                dispatch(sendAuthMailSuccess(response.data.client));
            })
            .catch((error: any) => {
                dispatch(sendAuthMailError(error.response, data));
            });
    };
}

//---------------------------------- RESET THE MAIL STATE --------------------------------------------------
const resetStatus = () => {
    return {
        type: MAIL_CONSTANTS.RESET_MAIL_STATUS
    }
}

export const resetMailStatus = () => {
    return (dispatch: Dispatch) => {
        dispatch(resetStatus());
    }
}