import axios from 'axios';

const API_URL = process.env.REACT_APP_BASE_URL;

const options = {
    headers: {
        'Content-Type': 'application/json',
    }
};

const sendMail = (payload: any) => {
    return axios.post(`${API_URL}mail/`, payload)
}

const sendAuthMail = (payload: any) => {
    return axios.post(`${API_URL}mail/logged/`, payload)
}

export const API = {
    sendMail,
    sendAuthMail
}