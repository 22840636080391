import { Reducer } from 'redux';
import ZIPCODE_CONSTANTS from '../constants/zipCode-constants';
import { ZipCodeAction } from '../types/zipCode-types';
import zipCodeModel  from '../../models/zipCode-model'

export interface ZipCodeReducerState {
    loading: boolean;
    success: boolean;
    successMessage: string;
    zipCodeData: zipCodeModel[];
    error: boolean;
    errorMessage?: string;
    errorStatus?: number;
}

const initialState: ZipCodeReducerState = {
    loading: false,
    success: false,
    error: false,
    zipCodeData: [],
    successMessage: '',
    errorMessage: '',
    errorStatus: 0,
}

export const zipCodeReducer: Reducer<ZipCodeReducerState, ZipCodeAction> = (state = initialState, action: ZipCodeAction) => {
    switch (action.type) {
        //----------CREATE ORDER-----------//
        case ZIPCODE_CONSTANTS.GET_ZIPCODE_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            }
        
        case ZIPCODE_CONSTANTS.GET_ZIPCODE_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                errorStatus: action.error.data.status,
                errorMessage: action.error.data.message,
                successMessage: '',
                loading: false
            }
        
        case ZIPCODE_CONSTANTS.GET_ZIPCODE_SUCCESS:
            return {
                ...state,
                success: true,
                zipCodeData: action.data,
                errorMessage: '',
                errorStatus: 0,
                error: false,
                loading: false
            }
        
        case ZIPCODE_CONSTANTS.RESET_ZIPCODE_STATUS:
            return {
                success: false,
                error: false,
                loading: false,
                zipCodeData: [],
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            }

        default:
            return state;
    }
}