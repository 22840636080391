import React, { Component } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persist } from "./flux/redux_setup/store";
import { interceptor } from "./services/interceptor";
// import { renderToStaticMarkup } from 'react-dom/server';
import Routes from "./routes/app";
//Hoal mundo
class App extends Component<any> {
  constructor(props: any) {
    super(props);
    interceptor.addInterceptor();
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate persistor={persist}>
          <Routes />
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
