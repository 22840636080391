import { Dispatch } from "redux";
import { API } from "../api/permission-api";
import PERMISSION_CONSTANTS from "../constants/permission-constants";
import * as types from '../types/permission-types';

//---------------------------------- POSTING THE PERMISSION --------------------------------------------------
const postPermissionBegin = (): types.IPostPermissionBegin => {
    return {
        type: PERMISSION_CONSTANTS.POST_PERMISSION_BEGIN
    };
}

const postPermissionError = (e: any): types.IPostPermissionError => {
    return {
        type: PERMISSION_CONSTANTS.POST_PERMISSION_ERROR,
        error: e
    };
}

const postPermissionSuccess = (data: any): types.IPostPermissionSuccess => {
    return {
        type: PERMISSION_CONSTANTS.POST_PERMISSION_SUCCESS,
        data
    };
}

export const postPermission = (payload: any) => {
    return (dispatch: Dispatch) => {
        dispatch(postPermissionBegin());
        API.postSavePermission(payload)
            .then((response: any) => {
                dispatch(postPermissionSuccess(response.data));
            }).catch((error: any) => {
                dispatch(postPermissionError(error.response));
            });
    }
}

//---------------------------------- GET PERMISSIONS --------------------------------------------------
const getPermissionBegin = (): types.IGetPermissionBegin => {
    return {
        type: PERMISSION_CONSTANTS.GET_PERMISSION_BEGIN
    };
}

const getPermissionError = (e: any): types.IGetPermissionError => {
    return {
        type: PERMISSION_CONSTANTS.GET_PERMISSION_ERROR,
        error: e
    };
}

const getPermissionSuccess = (data: any): types.IGetPermissionSuccess => {
    return {
        type: PERMISSION_CONSTANTS.GET_PERMISSION_SUCCESS,
        data
    };
}

export const getPermission = () => {
    return (dispatch: Dispatch) => {
        dispatch(getPermissionBegin());
        API.getPermissions()
            .then((response: any) => {
                dispatch(getPermissionSuccess(response.data));
            }).catch((error: any) => {
                dispatch(getPermissionError(error.response));
            });
    }
}

//---------------------------------- RESET THE PERMISSION STATE --------------------------------------------------
const resetStatus = () => {
    return {
        type: PERMISSION_CONSTANTS.RESET_PERMISSION_STATUS
    }
}

export const resetPermissionStatus = () => {
    return (dispatch: Dispatch) => {
        dispatch(resetStatus());
    }
}