enum FLOW_CONSTANTS {
    CREATE_FLOW_BEGIN = "CREATE_FLOW_BEGIN",
    CREATE_FLOW_ERROR = "CREATE_FLOW_ERROR",
    CREATE_FLOW_SUCCESS = "CREATE_FLOW_SUCCESS",

    GET_ALL_FLOWS_BEGIN = "GET_ALL_FLOWS_BEGIN",
    GET_ALL_FLOWS_ERROR = "GET_ALL_FLOWS_ERROR",
    GET_ALL_FLOWS_SUCCESS = "GET_ALL_FLOWS_SUCCESS",

    GET_FLOW_BEGIN = "GET_FLOW_BEGIN",
    GET_FLOW_ERROR = "GET_FLOW_ERROR",
    GET_FLOW_SUCCESS = "GET_FLOW_SUCCESS",

    RESET_FLOW_STATUS = "RESET_FLOW_STATUS",
    RESET_FLOW_MESSAGES = "RESET_FLOW_MESSAGES"
};

export default FLOW_CONSTANTS;