import React, { FC, useEffect, useState } from 'react';
import { Button, Col, Card, Row } from 'react-bootstrap';
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { BsDot, BsWhatsapp } from 'react-icons/bs';
import { getFlowByName } from '../../flux/actions/flow-actions';
import UserModel from '../../models/user-model';
import FormBuilderPreview from '../../components/Builders/FormBuilder/FormBuilderPreview';
import DocumentBuilder from '../../components/Builders/FormBuilder/DocumentBuilder';
import { refreshToken } from '../../flux/actions/refreshToken-action';

var mobile = require('is-mobile');

let currentStep = 0;
let totalSteps = 0;

const PreviewFlow: FC = () => {
    //Redux vars
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    const flowName = (params as any)?.name;
    const user: UserModel = useSelector((state: any) => state.authReducer.user);
    const [auxRender, setAuxRender] = useState(currentStep);
    const [arrayBuilder, setArrayBuilder] = useState<any[]>([]);
    const { flow } = useSelector((state: any) => state.flowReducer);

    useEffect(() => {
        if (Object.keys(user).length === 0) {
            history.push('admin/login');
        }
        dispatch(getFlowByName(flowName.replaceAll("-", " ")));
    }, []);

    useEffect(() => {
        if (flow.length !== 0 && flow !== undefined) {
            dispatch(
                refreshToken({
                    token: localStorage.getItem('token'),
                    refreshToken: localStorage.getItem('refreshToken')
                })
            );
            if (!flow.isActive) {
                history.push('/admin/flujos');
            }

            let auxBuilder: any = [];

            flow.steps.map((step: any, index: number) => {
                auxBuilder.push({
                    label: step.name,
                    icon: <BsDot key={index} />,
                    component: step.infoRequired.findIndex((info: any) => info.model === 'documents') === 0 ?
                        <DocumentBuilder
                            key={index}
                            infoRequired={step.infoRequired}
                            title={step.name}
                            description={step.description}
                            finishFlow={() => {return null}}
                            onSaveForm={() =>{
                                if(currentStep < flow.steps.length - 1 ){
                                    currentStep++
                                    setAuxRender(currentStep);
                                }
                            }}
                        /> :
                        <FormBuilderPreview
                            key={index}
                            infoRequired={step.infoRequired}
                            title={step.name}
                            description={step.description}
                            onSaveForm={() =>{
                                if(currentStep < flow.steps.length - 1 ){
                                    currentStep++
                                    setAuxRender(currentStep);
                                }
                            }}
                        />
                })
            })
            setArrayBuilder(auxBuilder);
            return totalSteps = flow.steps.length;
        }

    }, [flow]);

    // Progess Bar steps
    let progressStep  = ( (currentStep + 1) * 100) / totalSteps;

    //Load Styles Next Button

    const loadStylesNextBtn = () => {
        let buttonNext = document.querySelector(".onBoarding-button-next");

        if(buttonNext !== null) {

            if(currentStep >= 1) {
                buttonNext.className = "btn-border-radius onBoarding-button onBoarding-button-next nextBtn";
            }

            if (currentStep === 0) {
                buttonNext.className = "btn-border-radius onBoarding-button onBoarding-button-next";
            }
        }
    }

    useEffect(() => {
        setTimeout(loadStylesNextBtn, 100);
    });

    return (
        <>
            <div className="auth-wrapper align-items-stretch onBoarding">
                <Col  xs={12} className="onBoarding-container">
                    <Card>
                        <Card.Header className={'d-flex'}>
                            <Col md={12} className={'d-flex onBoarding-header-col'}>
                                <Button
                                    className="btn-border-radius color-white"
                                    onClick={() => {
                                        window.close();
                                    }}
                                >
                                    <b>
                                        <i className={'feather icon-log-out'} />
                                    </b>
                                </Button>
                                <Card.Title className="mt-2" as={mobile() ? 'h4' : 'h3'}>
                                    {flow.name}
                                </Card.Title>
                            </Col>
                        </Card.Header>
                        <Card.Body>
                            <div className='progressBar'>
                                <div style={{width: `${progressStep}%` }}>
                                </div>
                            </div>
                            {arrayBuilder.length !== 0 &&
                                <>
                                    {arrayBuilder.length !== 0 && arrayBuilder[auxRender].component}
                                </>
                            }
                            <Button
                                className="btn-border-radius onBoarding-whatsapp-btn"
                                style={{ background: '#20c997', borderColor: '#20c997' }}
                            >
                                <span>Soporte</span>
                                <b>
                                    <BsWhatsapp className='onBoarding-whatsapp-icon' style={{ marginLeft: -9, marginTop: -2 }} />
                                </b>
                            </Button>
                            {currentStep > 0 && (
                                <Button
                                    className="btn-border-radius color-white onBoarding-button backBtn"
                                    variant="third"
                                    onClick={() => {
                                        if (currentStep < flow.steps.length) {
                                            currentStep--;
                                            setAuxRender(currentStep);

                                            if (currentStep === 0) {
                                                window.location.reload();
                                            }
                                        }
                                    }}
                                >
                                    <b><i className={'feather icon-arrow-left'} /> Regresar</b>
                                </Button>
                                )
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        </>
    );
};
export default PreviewFlow;
