import axios from 'axios';

const API_URL = process.env.REACT_APP_BASE_URL;

const login = (data: any) => {
    return axios.post(`${API_URL}users/login`, data);
}

const signup = (data: any) => {
    return axios.post(`${API_URL}users/`, data);
}

export const API = {
    login: login,
    signup
}