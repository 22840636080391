enum MAIL_CONSTANTS {

    MAIL_SEND_BEGIN = "MAIL_SEND_BEGIN",
    MAIL_SEND_ERROR = "MAIL_SEND_ERROR",
    MAIL_SEND_SUCCESS = "MAIL_SEND_SUCCESS",

    AUTH_MAIL_SEND_BEGIN = "AUTH_MAIL_SEND_BEGIN",
    AUTH_MAIL_SEND_ERROR = "AUTH_SEND_ERROR",
    AUTH_MAIL_SEND_SUCCESS = "AUTH_SEND_SUCCESS",

    RESET_MAIL_STATUS = "RESET_MAIL_STATUS",
    RESET_AUTH_MAIL_STATUS = "RESET_AUTH_MAIL_STATUS"
}

export default MAIL_CONSTANTS