import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCredit, rejectQuote, updateStatus } from '../../../../flux/actions/quote-actions';
import { getApprovalProcess } from '../../../../flux/actions/approvalProcess-actions';
import { sendAuthMail } from '../../../../flux/actions/mail-actions';
import { getClient } from '../../../../flux/actions/client-actions';
import { Card, Col, Row, Button, Modal } from 'react-bootstrap';
import moment from 'moment';

const SATInfoApproval: React.FC = () => {
    //const to get the params
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    const clientRef = (params as any)?.id;

    const user = useSelector((state: any) => state.authReducer.user);
    const client = useSelector((state: any) => state.clientReducer.client);
    const quote: any = useSelector((state: any) => state.quoteReducer.credit);
    const { approvalProcess } = useSelector((state: any) => state.approvalProcessReducer);

    const [showApprovalModal, setShowApprovalModal] = useState(false);
    const [showCancelRequest, setShowCancelRequest] = useState(false);

    // Format the above price dollar currency
    let pesosMX = Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
        maximumSignificantDigits: 3
    });

    useEffect(() => {
        if (Object.keys(user).length === 0) {
            history.push('/login');
        }
        dispatch(getClient(clientRef));
        dispatch(getApprovalProcess(clientRef));
    }, []);

    function ApproveSATInfo() {
        let stepIndex = 0;
        const approvalProcessClient = approvalProcess.steps?.filter((step: any) => {
            return step.clientStatus !== "generateCreditCircleNIP";
        });
        approvalProcess.steps.filter((step: any, index: number) => {
            if (step.adminStatus === quote.adminStatus) {
                stepIndex = index + 1;
                if (stepIndex < approvalProcess.steps.length) {
                    if (approvalProcess.steps[index].continuousApprovalFlow) {
                        dispatch(
                            updateStatus({
                                clientRef,
                                type: 'adminStatus',
                                status: approvalProcess.steps[stepIndex].adminStatus
                            })
                        );
                    } else {
                        dispatch(
                            updateStatus({
                                clientRef,
                                type: 'updateBothStates',
                                status: approvalProcessClient[stepIndex].clientStatus,
                                adminStatus: approvalProcess.steps[stepIndex].adminStatus
                            })
                        );
                    }
                } else if (stepIndex === approvalProcess.steps.length) {
                    dispatch(
                        updateStatus({
                            clientRef,
                            type: 'adminStatus',
                            status: 'creditProposal'
                        })
                    );
                }   
            }
        });
        setTimeout(() => { dispatch(getCredit(clientRef)) }, 1000);
        setShowApprovalModal(!showApprovalModal);
    }

    function cancelRequest() {
        dispatch(rejectQuote({ client: client.id }));
        setShowCancelRequest(!showCancelRequest);
        dispatch(sendAuthMail({ mailType: 3, email: client.email, name: client.names, lastName1: client.lastName1, lastName2: client.lastName2, folio: quote.folio, amount: quote.amount }));
    }

    return (
        <>
            <Card>
                <Card.Header className="approvalFlow-sat-header">
                    <Card.Title as="h5">Consulta de Información Fiscal:</Card.Title>
                    <span className="sat-updateTime">
                        Actualizado hace{' '}
                        {moment().diff(client.updatedAt, 'days') !== 0
                            ? moment().diff(client.updatedAt, 'days')
                            : moment().diff(client.updatedAt, 'hours')}
                        {moment().diff(client.updatedAt, 'days') !== 0 ? ' días' : ' horas'}
                    </span>
                </Card.Header>
                {Object.keys(quote)?.length !== 0 && client.netIncome == 0 && client.outFlow.length == 0 && client.inFlow.length == 0 ? (
                    <Card.Header className="approvalFlow-sat-header">
                        <Card.Title as="h6">El prospecto aun no ha realizado el inicio de sesión en SAT WS.</Card.Title>
                    </Card.Header>
                ) : (
                    Object.keys(client).length !== 0 &&
                    client.inFlow.length !== 0 &&
                    client.outFlow.length !== 0 &&
                    client.netIncome !== 0 && (
                        <Card.Body className="approvalFlow-sat-body">
                            <Card.Title as="h5">Esta es la información recaudada por SAT WS</Card.Title>
                            <p>
                                Es necesario realizar el análisis de la información fiscal del prospecto para determinar su elegibilidad a
                                un crédito.
                            </p>
                            <Row className="sat-infoCol">
                                <Col>
                                    <Card>
                                        <Card.Header>
                                            <h5>Ingresos</h5>
                                        </Card.Header>
                                        <ul>
                                            {client.inFlow.map((register: any) => {
                                                return (
                                                    <li>
                                                        <p>{pesosMX.format(register.amount)}</p>
                                                        <p>
                                                            {' '}
                                                            <i className="f-24 m-r-10" /> {register.date}{' '}
                                                        </p>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card>
                                        <Card.Header>
                                            <h5>Egresos</h5>
                                        </Card.Header>
                                        <ul>
                                            {client.outFlow.map((register: any) => {
                                                return (
                                                    <li>
                                                        <p>{pesosMX.format(register.amount)}</p>
                                                        <p>
                                                            {' '}
                                                            <i className="f-24 m-r-10" /> {register.date}{' '}
                                                        </p>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card>
                                        <Card.Header>
                                            <h5>INGRESOS NETOS</h5>
                                            <h4>{pesosMX.format(client.netIncome)}</h4>
                                        </Card.Header>
                                    </Card>
                                </Col>
                            </Row>
                            <Card.Footer className="approvalFlow-sat-footer">
                                <Card.Title as="h5">¿El prospecto cumple con los ingresos necesarios para poder continuar?</Card.Title>
                                <Row className="mt-3 ml-3">
                                    <Button
                                        className="btn-border-radius sat-successBtn"
                                        variant={'success'}
                                        onClick={() => {
                                            setShowApprovalModal(!showApprovalModal);
                                        }}
                                    >
                                        Si
                                    </Button>
                                    <Button
                                        variant={'danger'}
                                        className="ml-3 btn-border-radius sat-alertBtn"
                                        onClick={() => {
                                            setShowCancelRequest(!showCancelRequest);
                                        }}
                                    >
                                        No
                                    </Button>
                                </Row>
                            </Card.Footer>
                        </Card.Body>
                    )
                )}
            </Card>
            <Modal show={showApprovalModal} onHide={() => setShowApprovalModal(!showApprovalModal)}>
                <Modal.Header closeButton className="approvalFlow-satModal-header">
                    <Modal.Title as="h5">El usuario cumple con la documentación inicial</Modal.Title>
                </Modal.Header>
                <Modal.Body className="approvalFlow-satModal-body">
                    <p>
                        Los archivos que subió el prospecto son los necesarios para CONTINUAR con el proceso del crédito
                        <br />
                        <br />
                        ¿Deseas continuar?
                    </p>
                </Modal.Body>
                <Modal.Footer className="approvalFlow-sat-footer">
                    <Row className="mt-3 mb-3">
                        <Button
                            className="btn-border-radius sat-alertBtn"
                            variant="danger"
                            onClick={() => setShowApprovalModal(!showApprovalModal)}
                        >
                            Cancelar
                        </Button>
                        <Button className="ml-3 btn-border-radius sat-successBtn" variant="success" onClick={ApproveSATInfo}>
                            Aprobar
                        </Button>
                    </Row>
                </Modal.Footer>
            </Modal>
            <Modal show={showCancelRequest} onHide={() => setShowCancelRequest(!showCancelRequest)}>
                <Modal.Header closeButton className="approvalFlow-satModal-header">
                    <Modal.Title as="h5">El usuario NO cumple con la información para la solicitud del crédito</Modal.Title>
                </Modal.Header>
                <Modal.Body className="approvalFlow-satModal-body">
                    <p>
                        La información proveída por el cliente no cumple con alguno de los requisitos para ser candidato a un crédito. Esta cotización quedará CANCELADA
                        <br />
                        <br />
                        ¿Deseas continuar?
                    </p>
                </Modal.Body>
                <Modal.Footer className="approvalFlow-sat-footer">
                    <Row className="mt-3 mb-3">
                        <Button
                            className="btn-border-radius sat-alertBt"
                            variant="danger"
                            onClick={() => setShowCancelRequest(!showCancelRequest)}
                        >
                            Cancelar
                        </Button>
                        <Button className="ml-3 btn-border-radius sat-successBtn" variant="success" onClick={cancelRequest}>
                            Aceptar
                        </Button>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default SATInfoApproval;
