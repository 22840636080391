import axios from 'axios';

const API_URL = process.env.REACT_APP_BASE_URL;

const postCreditProposal = (payload: any) => {
    return axios.post(`${API_URL}creditProposal`, payload);
}

const updateCreditProposal = (payload: any) => {
    return axios.patch(`${API_URL}creditProposal`, payload);
}

const getCreditProposal = (id: string) => {
    return axios.get(`${API_URL}creditProposal/${id}`);
}

export const API = {
    postCreditProposal,
    getCreditProposal,
    updateCreditProposal
}