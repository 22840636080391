import React, { useEffect, useState, FC, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Card, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getClient, saveFlowInformation } from '../../../flux/actions/client-actions';
import { getBussinessInfo } from '../../../flux/actions/bussinessInfo-actions';
import { getAffiliate } from '../../../flux/actions/affiliate-actions';
import { getEconomicActivities, resetEconomicActivitiesStatus } from '../../../flux/actions/economicAct-actions';
import LegalRepresentatveStepper from './FramesSteps/LegalRepresentativeFramework';
import SaveIcon from '@mui/icons-material/Save';
import Loader from '../../ScreenLoader/ScreenLoader';
import ConfirmInfoModal from './ConfirmInfoModal';
import ModalGeolocation from '../../ModalWarning';
import DateInput from '../../DateInput';
import moment from 'moment';

interface Props {
    infoRequired: [];
    onSaveForm: () => void | any;
    finishFlow: () => any;
    title: string;
    description: string;
    showModal?: boolean;
    confirmInfo?: boolean;
}

const FormBuilder: FC<Props> = (p) => {
    // Redux Variables
    const dispatch = useDispatch();
    const { loading } = useSelector((state: any) => state.flowReducer);
    const { client } = useSelector((state: any) => state.clientReducer);
    const { bussinessInfo }: any = useSelector((state: any) => state.bussinessInfoReducer);
    const { affiliate }: any = useSelector((state: any) => state.affiliateReducer);
    const { legalRepresentative }: any = useSelector((state: any) => state.legalRepresentativeReducer);
    const economicActivities = useSelector((state: any) => state.economicActivitiesReducer.economicActivitiesData);

    // State Variables
    const [payloadArray, setPayloadArray] = useState<any[]>([]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [errors, setErrors] = useState(false);
    const [economicActivity, setEconomicActivity] = useState('');
    const [dynamicInput, setDynamicInput] = useState('');
    const [addressInputs, setAddressInputs] = useState(false);
    const [legalPersons, setLegalPersons] = useState<any[]>([]);
    const [indexUpdate, setIndexUpdate] = useState(0);
    const [stepType, setStepType] = useState('');
    const [activeStep, setActiveStep] = useState(0);
    const [hideElement, setHideElement] = useState<any>({ 0: false });
    const [updatePerson, setUpdatePerson] = useState<any>({ 0: false });
    const [containerLastPerson, setContainerLastPerson] = useState<any[]>();
    let [date, setDate] = useState<Date>();
    //Constantes de Edicion sirven para habilitar y deshabilitar partes del codigo
    const [showActivity, setShowActivity] = useState(false);

    useEffect(() => {
        dispatch(getClient(client.id));
        dispatch(getBussinessInfo(client.id));
        //dispatch(getAffiliate(client.id));
        loadInfo();
    }, []);

    useLayoutEffect(() => {
        loadInfo();
    }, [client, bussinessInfo, activeStep, updatePerson]);

    //Se Definen las variables de Informacion personal del cliente
    useEffect(() => {
        if (Object.keys(client).length !== 0) {
            setEconomicActivity(client.economicActivity || '');
        }
    }, [client]);

    //Se obtienen la Informacion de la Actividad económica si se cumple la condicion si no se restablecen sus valores
    useEffect(() => {
        economicActivity.length >= 3 && showActivity
            ? dispatch(getEconomicActivities(economicActivity))
            : dispatch(resetEconomicActivitiesStatus());
    }, [economicActivity]);

    const inputSpecification = (e: any) => {
        if (e.target.name === 'Actividad Económica') {
            setEconomicActivity(e.target.value);
        } else {
            setDynamicInput(e.target.value);
        }
    };

    const loadInfo = () => {
        let arrayLoader: any = [];

        if (Object.keys(p.infoRequired).length !== 0) {
            p.infoRequired.forEach((info: any, index: number) => {
                switch (info.model) {
                    case 'client':
                        if (Object.keys(client).length !== 0) {
                            const valueIndex = Object.keys(client).indexOf(info.register);
                            const value = Object.values(client)[valueIndex];
                            arrayLoader[index] = {
                                name: info.name,
                                value: value,
                                type: info.type,
                                options: info.options,
                                model: info.model,
                                register: info.register,
                                isRequired: info.isRequired,
                                tooltip: info.tooltip
                            };
                        }
                        break;

                    case 'bussinessInfo':
                        if (Object.keys(bussinessInfo).length !== 0) {
                            const bussinessValueIndex = Object.keys(bussinessInfo).indexOf(info.register);
                            const valueBussiness = Object.values(bussinessInfo)[bussinessValueIndex];
                            arrayLoader[index] = {
                                name: info.name,
                                value: valueBussiness,
                                type: info.type,
                                options: info.options,
                                model: info.model,
                                register: info.register,
                                isRequired: info.isRequired,
                                tooltip: info.tooltip
                            };
                        }
                        break;

                    case 'affiliate':
                        if (Object.keys(affiliate).length !== 0) {
                            const affiliateValueIndex = Object.keys(affiliate).indexOf(info.register);
                            const valueAffiliate = Object.values(affiliate)[affiliateValueIndex];
                            arrayLoader[index] = {
                                name: info.name,
                                value: valueAffiliate,
                                type: info.type,
                                options: info.options,
                                model: info.model,
                                register: info.register,
                                isRequired: info.isRequired,
                                tooltip: info.tooltip
                            };
                        }
                        break;

                    case 'Apoderado':
                        if (Object.keys(legalRepresentative).length == 0) {
                            setStepType(info.model);
                            const legalRepresentativeValueIndex = Object.keys(legalRepresentative).indexOf(info.register);
                            const valueLegalRepresentative = Object.values(legalRepresentative)[legalRepresentativeValueIndex];
                            arrayLoader[index] = {
                                name: info.name,
                                value: valueLegalRepresentative,
                                type: info.type,
                                options: info.options,
                                model: info.model,
                                register: info.register,
                                isRequired: info.isRequired,
                                tooltip: info.tooltip,
                                update: info.update
                            };
                        }
                        break;
                }
            });
            setPayloadArray(arrayLoader);
            activeStep === 0 && setLegalPersons([[...arrayLoader]]);
        }
    };

    const updateForm = (persons: any) => {
        setTimeout(() => {
            setLegalPersons(persons);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setIndexUpdate((preIndexUpdate) => preIndexUpdate + 1);
        }, 300);
    };

    const addNewPerson = () => {
        let auxStep = [...payloadArray];
        legalPersons.push(auxStep);
        hideElement[activeStep] = false;
        updatePerson[activeStep] = false;
        updateForm(legalPersons);
    };
    //function to handle the input, change it in base a the id of each element
    const inputHandler = (e: any) => {
        if (stepType === 'Apoderado') {
            const auxStep = [...legalPersons[activeStep]];
            auxStep[e.target.id] = { ...auxStep[e.target.id], value: e.target.value };
            legalPersons[activeStep] = auxStep;
            setLegalPersons(legalPersons);
        } else {
            const auxStep = [...payloadArray];
            auxStep[e.target.id] = { ...auxStep[e.target.id], value: e.target.value };
            setPayloadArray(auxStep);
        }
    };

    //function to handle the select label, change it in base a the selected element
    const selectHandler = (e: any) => {
        if (stepType === 'Apoderado') {
            const auxStep = [...legalPersons[activeStep]];
            auxStep[e.target.id] = { ...auxStep[e.target.id], value: e.target.value };
            legalPersons[activeStep] = auxStep;
            setLegalPersons(legalPersons);
        } else {
            const auxStep = [...payloadArray];
            auxStep[e.target.id] = { ...auxStep[e.target.id], value: e.target.value };
            setPayloadArray(auxStep);
        }
    };

    //Date Input
    let inputDatePicker = useRef<HTMLInputElement>(null);
    let inputDatePlaceHolder = useRef<HTMLInputElement>(null);

    const hiddenInputDate = (datePicker: HTMLInputElement, datePlaceHolder: HTMLInputElement) => {
        datePlaceHolder.style.display = 'none';
        datePicker.style.display = 'block';
    };

    // ------- Multiple renders inputs compnents ------- //
    const renderingMultiplePeople = () => {
        return (
            <>
                {legalPersons?.map((element: any, index: number) => {
                    return (
                        <div className="multi-person-render mt-2">
                            <Row className="align-items-center justify-content-end">
                                <div className="col-11 ml-5">
                                    <div className="divider-title"></div>
                                    <Card.Subtitle className="mr-3">
                                        {p.description} ({index + 1})
                                    </Card.Subtitle>
                                </div>
                                {index < activeStep && (
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-sm btn-border-radius m-0 float-right customerProfile-editBtn mr-1"
                                        onClick={() => {
                                            if (updatePerson[index]) {
                                                legalPersons[index] = legalPersons[activeStep];
                                                legalPersons[activeStep] = containerLastPerson;
                                                setIndexUpdate(activeStep);
                                                setUpdatePerson({ ...updatePerson, [index]: !updatePerson[index] });
                                            } else {
                                                setContainerLastPerson(legalPersons[activeStep]);
                                                setUpdatePerson({ ...updatePerson, [index]: !updatePerson[index] });
                                                setIndexUpdate(index);
                                            }
                                        }}
                                    >
                                        {!updatePerson[index] ? (
                                            <i className="feather icon-edit" />
                                        ) : (
                                            <h6 className="text-center text-white mr-1">
                                                Guardar
                                                <SaveIcon />
                                            </h6>
                                        )}
                                    </button>
                                )}
                                <button
                                    type="button"
                                    className="btn btn-primary btn-sm btn-border-radius m-0 float-right customerProfile-editBtn mr-3"
                                    onClick={() => {
                                        setHideElement({ ...hideElement, [index]: !hideElement[index] });
                                    }}
                                >
                                    <i className={!hideElement[index] ? 'feather icon-x' : 'feather icon-eye'} />
                                </button>
                            </Row>
                            <Row className="d-flex flex-row align-items-center justify-content-center step-form">
                                {payloadArray?.map((info: any, childIndex: number) => {
                                    return (
                                        <Col
                                            className={!hideElement[index] ? `d-flex flex-column mt-4 ${element.type}` : `d-none`}
                                            sm={12}
                                            md={6}
                                        >
                                            {_renderInput(info, childIndex, index)}
                                        </Col>
                                    );
                                })}
                                {/* {errors.names && <p className="form-text text-left ">{errors.names}</p>} */}
                            </Row>
                        </div>
                    );
                })}
            </>
        );
    };

    const _renderInput = (info: any, index: number, parentIndex?: any) => {
        switch (info.type) {
            case 'text':
                return (
                    <>
                        <Form.Group controlId="formBasicEmail1">
                            <input
                                type={info.type}
                                className="form-control"
                                id={index.toString()}
                                autoComplete="off"
                                maxLength={info.name === 'CURP' ? 18 : info.name === 'RFC' ? 13 : 9999999}
                                key={index}
                                name={info.name}
                                placeholder={info.isRequired ? `${info.name}*` : info.name}
                                disabled={stepType !== 'Apoderado' ? false : parentIndex == indexUpdate ? false : true}
                                value={
                                    (info.name === 'CURP' && info.value !== undefined) ||
                                    (info.name === 'RFC' && info.value !== undefined) ||
                                    (info.name === 'Actividad Económica' && info.value !== undefined) ||
                                    (info.name === 'RFC del Apoderado' && info.value !== undefined)
                                        ? info.value.toUpperCase()
                                        : info.name === 'Actividad Económica' && info.value !== undefined && economicActivity !== undefined
                                        ? (info.value = economicActivity)
                                        : addressInputs === true && info.name === 'Código Postal (Negocio)'
                                        ? (info.value = client.zipCode)
                                        : addressInputs === true && info.name === 'Estado (Negocio)'
                                        ? (info.value = client.state)
                                        : addressInputs === true && info.name === 'Municipio (Negocio)'
                                        ? (info.value = client.municipality)
                                        : addressInputs === true && info.name === 'Ciudad (Negocio)'
                                        ? (info.value = client.city)
                                        : addressInputs === true && info.name === 'Colonia (Negocio)'
                                        ? (info.value = client.suburb)
                                        : addressInputs === true && info.name === 'Calle (Negocio)'
                                        ? (info.value = client.street)
                                        : addressInputs === true && info.name === 'Número Exterior (Negocio)'
                                        ? (info.value = client.numExt)
                                        : addressInputs === true && info.name === 'Número Interior (Negocio)'
                                        ? (info.value = client.numInt)
                                        : info.value
                                }
                                onChange={(e: any) => {
                                    inputHandler(e);
                                    inputSpecification(e);
                                    setShowActivity(true);
                                    setAddressInputs(false);
                                }}
                            />
                            {info.name === 'Ingresos Mensuales' ? (
                                <OverlayTrigger
                                    placement="left"
                                    overlay={
                                        <Tooltip id={`tooltip-left`} className="text-center">
                                            <h5 className="my-auto text-white">
                                                Ingresos promedio que tienes mensualmente por ventas generadas de tu negocio.
                                            </h5>
                                        </Tooltip>
                                    }
                                >
                                    <Card className="bg-info">
                                        <b className="my-auto text-white">?</b>
                                    </Card>
                                </OverlayTrigger>
                            ) : info.name === 'Gastos Mensuales' ? (
                                <OverlayTrigger
                                    placement="left"
                                    overlay={
                                        <Tooltip id={`tooltip-left`} className="text-center">
                                            <h5 className="my-auto text-white">
                                                Gastos generados mensualmente por la operación de tu negocio.
                                            </h5>
                                        </Tooltip>
                                    }
                                >
                                    <Card className="bg-info">
                                        <b className="my-auto text-white">?</b>
                                    </Card>
                                </OverlayTrigger>
                            ) : info.name === 'Antigüedad de la Empresa' ? (
                                <OverlayTrigger
                                    placement="left"
                                    overlay={
                                        <Tooltip id={`tooltip-left`} className="text-center">
                                            <h5 className="my-auto text-white">Meses que llevas operando tu negocio.</h5>
                                        </Tooltip>
                                    }
                                >
                                    <Card className="bg-info">
                                        <b className="my-auto text-white">?</b>
                                    </Card>
                                </OverlayTrigger>
                            ) : info.name === 'Número de empleados' ? (
                                <OverlayTrigger
                                    placement="left"
                                    overlay={
                                        <Tooltip id={`tooltip-left`} className="text-center">
                                            <h5 className="my-auto text-white">Número de empleados que tienes en tu negocio.</h5>
                                        </Tooltip>
                                    }
                                >
                                    <Card className="bg-info">
                                        <b className="my-auto text-white">?</b>
                                    </Card>
                                </OverlayTrigger>
                            ) : info.name === 'Actividad Económica' ? (
                                <OverlayTrigger
                                    placement="left"
                                    overlay={
                                        <Tooltip id={`tooltip-left`} className="text-center">
                                            <h5 className="my-auto text-white">
                                                Selecciona la actividad que más se relacione con el giro de tu negocio.
                                            </h5>
                                        </Tooltip>
                                    }
                                >
                                    <Card className="bg-info">
                                        <b className="my-auto text-white">?</b>
                                    </Card>
                                </OverlayTrigger>
                            ) : (
                                <></>
                            )}
                            {economicActivities &&
                                showActivity &&
                                info.register === 'economicActivity' &&
                                economicActivities.map((economicActivity: any, i: number) => (
                                    <>
                                        <div className="col-sm-3" />
                                        <div
                                            className="suggestion col-sm-9 justify-content-left"
                                            onClick={async (e: any) => {
                                                setEconomicActivity(economicActivity.activity);
                                                setShowActivity(false);
                                                dispatch(resetEconomicActivitiesStatus());
                                                info.value = economicActivity.activity;
                                            }}
                                        >
                                            {economicActivity.activity}
                                        </div>
                                    </>
                                ))}
                        </Form.Group>
                    </>
                );
            case 'Date':
                return (
                    <Form.Group>
                        <DateInput
                            disabled={stepType !== 'Apoderado' ? false : parentIndex == indexUpdate ? false : true}
                            label={info.isRequired ? `${info.name}*` : info.name}
                            onChange={(e: any) => {
                                info.value = e.toString();
                                setDate(e.toString());
                            }}
                            value={moment(info.value)}
                        />
                    </Form.Group>
                );
            case 'Select':
                return (
                    <Form.Group>
                        <Form.Control
                            as="select"
                            key={index}
                            value={info.value}
                            id={index.toString()}
                            placeholder={info.isRequired ? `${info.name}*` : info.name}
                            disabled={stepType !== 'Apoderado' ? false : parentIndex == indexUpdate ? false : true}
                            onChange={selectHandler}
                        >
                            <option value={'0'}>{info.name}</option>
                            {info.options.map((option: string) => {
                                return <option value={option}>{option}</option>;
                            })}
                        </Form.Control>
                    </Form.Group>
                );
            case 'location':
                return <ModalGeolocation />;
            default:
                break;
        }
    };

    const saveData = (e: any) => {
        let clientPayload = {},
            affiliatePayload = { clientRef: client.id },
            bussinessPayload = { clientRef: client.id },
            legalRepresatativePayload = { clientRef: client.id },
            auxError = false;
        if (stepType == 'Apoderado') {
        } else {
            payloadArray.forEach((info: any) => {
                if ((info.value === '' || info.value === undefined) && info.isRequired) {
                    auxError = true;
                } else {
                    switch (info.model) {
                        case 'client':
                            clientPayload = {
                                ...clientPayload,
                                [info.register]: info.value
                            };
                            break;

                        case 'bussinessInfo':
                            bussinessPayload = {
                                ...bussinessPayload,
                                [info.register]: info.value
                            };
                            break;

                        case 'affiliate':
                            affiliatePayload = {
                                ...affiliatePayload,
                                [info.register]: info.value
                            };
                            break;
                        case 'Apoderado':
                            legalRepresatativePayload = {
                                ...legalRepresatativePayload,
                                [info.register]: info.value
                            };
                            break;
                    }
                }
            });
        }

        setErrors(auxError);
        p.finishFlow();
        dispatch(
            saveFlowInformation({
                clientRef: client.id,
                clientPayload,
                bussinessPayload,
                affiliatePayload,
                legalRepresatativePayload: legalPersons,
                changeStep: auxError ? false : true
            })
        );
        p.onSaveForm();
        setShowConfirmModal(!showConfirmModal);
    };

    //Next Button Styles
    const nextButton = useRef<HTMLButtonElement>(null);

    const changeStylesNextBtn = (button: HTMLButtonElement) => {
        if (client.onBoardingStep >= 1) {
            button.className = 'btn-border-radius onBoarding-button onBoarding-button-next nextBtn';
        }

        if (client.onBoardingStep === 0) {
            button.className = 'btn-border-radius onBoarding-button onBoarding-button-next';
        }
    };

    if (null !== nextButton.current) {
        changeStylesNextBtn(nextButton.current);
    }

    if (loading) {
        return (
            <div className="auth-wrapper align-items-center">
                <Loader isOpen={true} />
            </div>
        );
    }

    return (
        <Col className="d-flex flex-column justify-content-center onBoarding-form">
            <Row>
                <Col sm={12} md={12} className="mt-3">
                    <Card.Title>{p.title}</Card.Title>
                    {stepType === 'Apoderado' ? (
                        // ------- Multiple inputs compnents ------- //
                        <>
                            <LegalRepresentatveStepper newPersonLenght={activeStep} activeStep={activeStep} newPerson={addNewPerson} />
                            {renderingMultiplePeople()}
                        </>
                    ) : (
                        <>
                            <div className="divider-title"></div>
                            <Card.Subtitle>{p.description}</Card.Subtitle>
                            {p.title === 'Domicilio de negocio' && (
                                <div className="d-flex flex-column justify-content-center align-items-center m-2 onBoarding-address">
                                    <p>Si el domicilio de tu negocio es el mismo que tu domicilio personal, da clic en el botón:</p>
                                    <Button variant="third" onClick={() => setAddressInputs(true)}>
                                        Utilizar domicilio personal
                                    </Button>
                                </div>
                            )}
                            <Row className="d-flex flex-row align-items-center justify-content-center step-form">
                                {payloadArray.map((info: any, index: number) => {
                                    return (
                                        <Col className={`d-flex flex-column mt-4 ${info.type}`} sm={12} md={6}>
                                            {_renderInput(info, index)}
                                            {/* {errors.names && <p className="form-text text-left ">{errors.names}</p>} */}
                                        </Col>
                                    );
                                })}
                            </Row>
                        </>
                    )}
                    {errors && <span className="formAlert">Aún falta información requerida por llenar</span>}
                    <Row className="onBoarding-buttons-content">
                        <Col className="d-flex onBoarding-buttons justify-content-center">
                            <Button
                                className="btn-border-radius onBoarding-button onBoarding-button-next"
                                variant="third"
                                ref={nextButton}
                                onClick={
                                    p.confirmInfo
                                        ? () => {
                                              setShowConfirmModal(!showConfirmModal);
                                          }
                                        : saveData
                                }
                            >
                                <dt>
                                    Continuar <i className={'feather icon-arrow-right'} />
                                </dt>
                            </Button>
                        </Col>
                    </Row>
                    <ConfirmInfoModal
                        showModal={showConfirmModal}
                        onConfirm={saveData}
                        onClose={() => setShowConfirmModal(!showConfirmModal)}
                        payloadInfo={stepType != 'Apoderado' ? payloadArray : legalPersons[activeStep]}
                    />
                </Col>
            </Row>
        </Col>
    );
};
export default FormBuilder;
