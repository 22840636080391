import { useEffect, useState, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Card, Modal } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { updateSchedulePhotos, getDocuments } from '../../flux/actions/documents-actions';
import { documentsDescription } from '../../constants/documentsDescription';
import { getCredit, updateStatus } from '../../flux/actions/quote-actions';
import { putUpdateSchedule } from '../../flux/actions/client-actions';
import { useParams } from 'react-router-dom';
import Loader from '../../components/ScreenLoader/ScreenLoader';

const SchedulePhotos: React.FC = () => {
    //redux const
    const dispatch = useDispatch();
    const params = useParams();
    const clientRef = (params as any)?.id;
    const { approvalProcess } = useSelector((state: any) => state.approvalProcessReducer);
    const files = useSelector((state: any) => state.DocumentsReducer.flowDocuments);

    //local const
    const [documents, setDocuments] = useState([...files]);
    const [errors, setErrors] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showModal, setShowModal] = useState(true);
    const [showScheduleModal, setShowScheduleModal] = useState(false);
    const [showRescheduleModal, setShowRescheduleModal] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [indexButon, setIndexButton] = useState<number>(0);

    //dropzone config
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        maxFiles: 1,
        noDrag: true,
        accept: ['.png', '.jpeg', '.jpg']
    });
    useEffect(() => {
        dispatch(getDocuments(clientRef));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /*Validates if the document is empty and if it is empty adds null to each element 
    otherwise it splits the string and assigns the file information to the new array*/
    useLayoutEffect(() => {
        files.length !== 0 && setDocuments(files);
    }, [files]);

    /*Add new files every time a new file is uploaded*/
    useEffect(() => {
        const auxFile: any[] = [...documents];

        // eslint-disable-next-line array-callback-return
        acceptedFiles?.map((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (auxFile.findIndex((file) => file.file === '') !== -1) {
                    auxFile[indexButon] = { ...auxFile[indexButon], file: reader.result, name: file.name };
                }
                setDocuments(auxFile);
            };

            reader.readAsDataURL(file);

            reader.onerror = (error) => {
                console.log('algo salio mal', error);
            };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acceptedFiles]);

    /*Validates that all documents are uploaded*/
    const validateDocuments = () => {
        const auxFiles = [...documents];
        const auxErrors = auxFiles.filter((file) => file.file === '');
        if (auxErrors.length === 0) {
            setShowScheduleModal(true);
            setErrors(false);
        } else {
            setErrors(true);
        }
    };

    /*Update voucher files of address statement of account and photograph of the ine in the database and it show the modal*/
    const postDocuments = async () => {
        let step = approvalProcess.steps.findIndex((step: any) => step.adminStatus === 'ConfirmScheduleDate');
        let nextStep = approvalProcess.steps[step + 1]?.adminStatus;

        if (nextStep === null || nextStep === undefined) {
            nextStep = 'creditProposal';
        }

        const payload = {
            documents,
            clientRef: clientRef
        };

        dispatch(updateSchedulePhotos(payload));
        setShowLoader(true);
        dispatch(
            updateStatus({
                clientRef: clientRef,
                type: 'adminStatus',
                status: nextStep
            })
        );
        dispatch(getCredit(clientRef));
    };

    // function to Upload all about the document in the right position
    const handleUpload = (e: any) => {
        const index = Number(e?.target?.name);
        setIndexButton(index);
    };

    /*Delete a file from the system*/
    const handleDelete = (e: any) => {
        const auxFiles = [...documents];
        const index = Number(e.target.name);
        auxFiles[index] = { ...auxFiles[index], name: '', file: '' };
        setDocuments(auxFiles);
    };

    // Render description of the documents in the screen
    const _renderDescription = (type: string) => {
        switch (type) {
            case 'bussinessPhoto1':
                return documentsDescription.bussinessPhoto1;
            case 'bussinessPhoto2':
                return documentsDescription.bussinessPhoto2;
            case 'bussinessPhoto3':
                return documentsDescription.bussinessPhoto3;
        }
    };

    // reschedule change
    const handleSchedule = () => {
        dispatch(
            putUpdateSchedule({
                clientRef: clientRef,
                scheduledDate: '',
                scheduledUser: '',
                scheduleStatus: false,
                bussinessPhoto1: '',
                bussinessPhoto2: '',
                bussinessPhoto3: ''
            })
        );
        setTimeout(() => {
            dispatch(getCredit(clientRef));
        }, 1000);
    };

    // render the card to upload the file
    const _renderCardToUploadFile = (document: any, index: number) => {
        if (document.file.includes('data:') || document.name === '')
            return (
                <Row style={{ width: '100%' }}>
                    <Col xs={12} className="mt-4 mt-md-4 customerProfile-files" {...getRootProps()}>
                        <div className="schedule-addressDocs">
                            <input {...getInputProps()} />
                            {document.file === '' && (
                                <p>
                                    <i style={{ fontSize: 20, color: '#495057' }} className={'feather icon-upload-cloud'} />
                                    {_renderDescription(document.type)}
                                </p>
                            )}
                            {document.name !== '' ? (
                                <p>
                                    <i style={{ fontSize: 20, color: '#30C39E' }} className={'feather icon-check-circle'} />
                                    {document.name}
                                </p>
                            ) : (
                                <>
                                    <Button
                                        name={index.toString()}
                                        className="btn btn-third btn-sm btn-border-radius float-center mt-2 doc-showBtn"
                                        onClickCapture={handleUpload}
                                    >
                                        Agregar Fotografía
                                    </Button>
                                </>
                            )}
                            {document.name !== '' && (
                                <Button variant="danger" className="deleteBtn" name={index.toString()} onClick={handleDelete}>
                                    Eliminar Fotografía
                                </Button>
                            )}
                        </div>
                    </Col>
                </Row>
            );
    };

    return (
        <>
            <Row>
                {documents.length !== 0 &&
                    documents?.map((document: any, index: number) => {
                        return _renderCardToUploadFile(document, index);
                    })}
                <Card.Footer className="approvalFlow-schedule-footer col-md-12">
                    {errors && (
                        <p id="roleHelpBlock" className="form-text text-center text-danger">
                            Sube todas las fotografías para poder continuar
                        </p>
                    )}
                    {showLoader && (
                        <Modal show={showModal}>
                            <Modal.Body>
                                <p className="onBoarding-loader-text">Subiendo Documentos</p>
                                <Loader isOpen={true} />
                            </Modal.Body>
                        </Modal>
                    )}
                    <Card.Title as="h5">¿Quieres guardar las fotografías de la visita al prospecto y continuar el proceso?</Card.Title>
                    <Button className="btn-border-radius schedule-successBtn" onClick={validateDocuments}>
                        Continuar
                    </Button>
                    <Button
                        className="btn-border-radius schedule-alertBtn ml-3"
                        onClick={() => {
                            setShowRescheduleModal(true);
                        }}
                    >
                        Reagendar Cita
                    </Button>
                </Card.Footer>
                <Modal
                    show={showScheduleModal}
                    onHide={() => {
                        setShowScheduleModal(!showScheduleModal);
                    }}
                >
                    <Modal.Header closeButton className="approvalFlow-scheduleModal-header">
                        <Modal.Title as="h5">Fotografías de negocio</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="approvalFlow-scheduleModal-body">
                        <p>¿Deseas continuar y guardar las fotografías del negocio?</p>
                    </Modal.Body>
                    <Modal.Footer className="approvalFlow-schedule-footer">
                        <Row className="mt-3 mb-3">
                            <Button
                                className="btn-border-radius schedule-alertBtn"
                                variant="danger"
                                onClick={() => setShowScheduleModal(!showScheduleModal)}
                            >
                                Cancelar
                            </Button>
                            <Button
                                className="ml-3 btn-border-radius schedule-successBtn"
                                variant="success"
                                onClick={() => {
                                    setShowScheduleModal(!showScheduleModal);
                                    postDocuments();
                                }}
                            >
                                Aceptar
                            </Button>
                        </Row>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={showRescheduleModal}
                    onHide={() => {
                        setShowRescheduleModal(!showRescheduleModal);
                    }}
                >
                    <Modal.Header closeButton className="approvalFlow-scheduleModal-header">
                        <Modal.Title as="h5">Reagendar cita</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="approvalFlow-scheduleModal-body">
                        <p>¿Deseas reagendar una cita con el prospecto?</p>
                    </Modal.Body>
                    <Modal.Footer className="approvalFlow-schedule-footer">
                        <Row className="mt-3 mb-3">
                            <Button
                                className="btn-border-radius schedule-alertBtn"
                                variant="danger"
                                onClick={() => setShowRescheduleModal(!showRescheduleModal)}
                            >
                                Cancelar
                            </Button>
                            <Button
                                className="ml-3 btn-border-radius schedule-successBtn"
                                variant="success"
                                onClick={() => {
                                    handleSchedule();
                                }}
                            >
                                Aceptar
                            </Button>
                        </Row>
                    </Modal.Footer>
                </Modal>
            </Row>
        </>
    );
};

export default SchedulePhotos;
