import React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { esES } from '@mui/x-date-pickers';
import 'moment/locale/es';

type DateProps = {
    onChange?: any;
    disabled?: boolean;
    inputRef?: React.RefObject<HTMLInputElement>;
    sx?: object;
    value?: any;
    label?: string;
    placeholder?: string;
    error?: boolean;
    helperText?: string;
    required?: boolean;
};

const Date = ({ onChange, disabled, inputRef, sx, value, label, placeholder, error, helperText, required }: DateProps) => {
    return (
        <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale="es"
            localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
        >
            <DemoContainer components={['DatePicker']}>
                <DatePicker
                    format="DD/MM/YYYY"
                    onChange={onChange}
                    disabled={disabled}
                    inputRef={inputRef}
                    sx={sx}
                    value={value}
                    label={label}
                    slotProps={{
                        textField: {
                            placeholder: placeholder,
                            error: error,
                            helperText: helperText,
                            required: required,
                            variant: 'standard'
                        }
                    }}
                />
            </DemoContainer>
        </LocalizationProvider>
    );
};

export default Date;
