import { Dispatch } from "redux";

import { API } from "../api/circle-credit";
import CIRCLE_CREDIT_CONSTANTS from "../constants/circleCredit-constants"
import * as types from '../types/circleCredit-types';

//---------------------------------- FETCH GET CONSOLIDATED CREDIT REPORT --------------------------------------------------
const getRccPmBegin = (): types.IGetRccPmBeggin => {
    return {
        type: CIRCLE_CREDIT_CONSTANTS.GET_RCC_PM_BEGIN
    };
}

const getRccPmError = (e: any): types.IGetRccPmError => {
    return {
        type: CIRCLE_CREDIT_CONSTANTS.GET_RCC_PM_ERROR,
        error: e
    };
}

const getRccPmSuccess = (data: any): types.IGetRccPmSuccess => {
    return {
        type: CIRCLE_CREDIT_CONSTANTS.GET_RCC_PM_SUCCESS,
        data
    };
}

export const getRccPm = (payload: any) => {
    return (dispatch: Dispatch) => {
        dispatch(getRccPmBegin());
        API.getRccPm(payload)
        .then((response: any) => {
            dispatch(getRccPmSuccess(response.data));
        }).catch((error: any) => {
            if(error.status === 401){
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('persist:authReducer');
                localStorage.removeItem('persist:root');
                localStorage.removeItem('nubarium:redirected:FaceCapture');
                localStorage.removeItem('nubarium:redirected:RecordMessage');
                window.location.href = "/login";
            }
            dispatch(getRccPmError(error.response));
        });
    }
}

//---------------------------------- FETCH GET CONSOLIDATED CREDIT REPORT --------------------------------------------------
const getRccFicoScoreBegin = (): types.IGetRccFicoScoreBeggin => {
    return {
        type: CIRCLE_CREDIT_CONSTANTS.GET_RCC_FICOSCORE_BEGIN
    };
}

const getRccFicoScoreError = (e: any): types.IGetRccFicoScoreError => {
    return {
        type: CIRCLE_CREDIT_CONSTANTS.GET_RCC_FICOSCORE_ERROR,
        error: e
    };
}

const getRccFicoScoreSuccess = (data: any): types.IGetRccFicoScoreSuccess => {
    return {
        type: CIRCLE_CREDIT_CONSTANTS.GET_RCC_FICOSCORE_SUCCESS,
        data
    };
}

export const getRccFicoScore = (payload: any) => {
    return (dispatch: Dispatch) => {
        dispatch(getRccFicoScoreBegin());
        API.getRccFicoScore(payload)
        .then((response: any) => {
            dispatch(getRccFicoScoreSuccess(response.data));
        }).catch((error: any) => {
            if(error.status === 401){
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('persist:authReducer');
                localStorage.removeItem('persist:root');
                localStorage.removeItem('nubarium:redirected:FaceCapture');
                localStorage.removeItem('nubarium:redirected:RecordMessage');
                window.location.href = "/login";
            }
            dispatch(getRccFicoScoreError(error.response));
        });
    }
}

//---------------------------------- RESET STATUS OF THE STATE --------------------------------------------------

const resetStatus = () => {
    return {
        type: CIRCLE_CREDIT_CONSTANTS.RESET_CIRCLE_CREDIT
    }
}

export const resetCircleCreditStatus = () => {
    return (dispatch: Dispatch) => {
        dispatch(resetStatus());
    }
} 