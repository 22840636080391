import React, { FC, useEffect, useState } from 'react';
import { Button, Col, Card, Row } from 'react-bootstrap';
import BuildForm from './BuildForm';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch} from 'react-redux';
import { BsWhatsapp } from 'react-icons/bs';
import Loader from '../../components/LoaderFile/Loader';
import { getDefaultFlow, getFlowById, getFlowByName } from '../../flux/actions/flow-actions';
import { getCredit } from '../../flux/actions/quote-actions';
import { getClient } from '../../flux/actions/client-actions';
import { refreshToken } from '../../flux/actions/refreshToken-action';

var mobile = require('is-mobile');

let currentStep = 0;

const TestFlow: FC = () => {
    //Redux vars
    const dispatch = useDispatch();
    const client = useSelector((state: any) => state.clientReducer.client);
    const { flow, loading } = useSelector((state: any) => state.flowReducer);
    const [auxRender, setAuxRender] = useState(currentStep);

    useEffect(() => {
        if (client && client.id) {
            dispatch(getCredit(client.id));
            dispatch(getFlowByName("Mi segundo flujo"));
            dispatch(
                refreshToken({
                    token: localStorage.getItem('token'),
                    refreshToken: localStorage.getItem('refreshToken')
                })
            );
        } else {
            //history.push('/login');
        }
    }, []);

    if (loading) {
        return (
            <div className="auth-wrapper align-items-center">
                <Loader />
            </div>
        );
    }
    return (
        <>
            <div className="auth-wrapper align-items-stretch">
                <Col xs={12}>
                    <Card>
                        <Card.Header className={'d-flex justify-content-center'}>
                            <Col md={12} className={'d-flex justify-content-center'}>
                                <Card.Title className="mt-2" as={mobile() ? 'h4' : 'h3'} style={{ color: 'white' }}>
                                    {flow.name}
                                </Card.Title>
                            </Col>
                        </Card.Header>
                        <Card.Body>
                            { flow.length !== 0 &&
                                <BuildForm
                                    onContinue={() =>{
                                        if (currentStep < flow.steps.length - 1 ) {
                                            currentStep++;
                                            setAuxRender(currentStep);
                                        }
                                    }}
                                    stepController={auxRender}
                                />
                            }
                        </Card.Body>
                        <Card.Footer>
                            <Row sm={12} md={8} xl={5} className={'d-flex justify-content-center'}>
                                {currentStep > 0 && (
                                    <Button
                                        className="btn-border-radius color-white col-7 col-sm-7 col-md-3 m-md-1 mr-xl-2 ml-xl-2"
                                        variant="third"
                                        size={mobile() ? 'sm' : 'lg'}
                                        onClick={() => {
                                            if(currentStep < flow.steps.length) {
                                                currentStep--;
                                                setAuxRender(currentStep);
                                            }
                                        }}
                                    >
                                        <i className={'feather icon-arrow-left'} />
                                        <b> Regresar</b>
                                    </Button>
                                )}
                                <Button
                                    className="btn-border-radius col-7 col-sm-7 col-md-3 mt-1 m-md-1 mr-xl-2"
                                    size={mobile() ? 'sm' : 'lg'}
                                    style={{ background: '#20c997', borderColor: '#20c997' }}
                                >
                                    <b>
                                        Soporte <BsWhatsapp style={{ marginTop: '-3px' }} />
                                    </b>
                                </Button>
                                <Button
                                    className="btn-border-radius color-white col-7 col-sm-7 col-md-3 m-md-1 mt-1"
                                    variant="third"
                                    size={mobile() ? 'sm' : 'lg'}
                                >
                                    <b>
                                        Cerrar Sesión <i className={'feather icon-log-out'} />
                                    </b>
                                </Button>
                            </Row>
                        </Card.Footer>
                    </Card>
                </Col>
            </div>
        </>
    );
};
export default TestFlow;
