import React, { FC } from 'react';
import { Card } from 'react-bootstrap';

const ApplicationApproved: React.FC = () => {
    return (
        <Card>
            <h3 className="text-center">COTIZACIÓN APROBADA.</h3>
        </Card>
    );
};
export default ApplicationApproved;
