import moment from 'moment';

let email_regex = new RegExp(/^[A-Z0-9._%+-]{1,64}@[A-Z0-9.-]+\.[A-Z]{1,4}$/i);
var phone_regex = new RegExp(/^[0-9]{10}$/);
var country_code_regex = new RegExp(/^[+][0-9]{1,2}(\s[0-9]{3}){3}[0-9]$/);
let whiteSpace = new RegExp(/^\s|\s$/);
export const number_regex = /^\d+$/;
export const currency_regex = /^\d+(?:\.\d{0,2})?$/;

export const whiteSpaces = (text: string) => {
    return whiteSpace.test(text);
}

export const email = (email: string) => {
    return email && email_regex.test(email);
}

export const validateInteger = (value: any, min: number, max: number) => {
    let val: number = Number(value);
    return Number.isInteger(val) && (val >= min && val <= max);
}

export const emailText = (value: any) => {
    return value && email_regex.test(value)  ? ""  : "Introduce un correo electrónico válido";
  };

export const phone = (phone: string | null | undefined) => {
    return phone && phone_regex.test(phone);
}

export const countryCode = (phone: string) => {
    return phone && country_code_regex.test(phone)
}

export const required = (value: any) => {
    return value  ? "" : "Este campo es requerido";
};

export const dateRequired = (value: any) => {
    return value ? '' : "Este campo es requerido";
}

export const realNumber = (value: any) => {
    return value && value > 0 ? '' : "El número debe ser mayor a 0";
}

export const currencyFormat = (value: string) => {
    if (!value || !value.trim()) return ''
    return currency_regex.test(value) ? '' : "Ingrese un número válido"
}

export const date = (value: any) => {
    return value && value.length && moment(value).isValid()
        ? '' : "Introduce una fecha válida";
}

export const dateBefore = (dates: any) => {
    const initialDate = dates.startDate;
    const endDate = dates.endDate;
    return ((initialDate && endDate) &&
        (moment(initialDate).startOf('day').isBefore(moment(endDate).endOf('day'))))
        ? '' : "La fecha inicial no puede ser una fecha superior a la fecha de fin."
}

export const dateBeforeToday = (date: any) => {
    return (date && moment(date).startOf('day').isBefore(moment().endOf('day')))
        ? '' : "La fecha de compra tiene que ser menor o igual al día actual";
}

export const matchPasswords = (a: string, b: string) => {
    return a === b ? true : false;
}

const maxLength = (max: number, value: any) => {
    return value && value.trim().length > max ?
        `${"Deben ser "}${max}${" caracteres o menos"}`
        : '';
}

const minLength = (min: number, value: any) => {
    return value && value.trim().length < min ?
        `${"La contraseña debe contener al menos "}${min}${ " caracteres"}`
        : '';
}

const minLengthPhone = (min: number, value: any) => {
    return value && value.trim().length < min ?
        `${"El teléfono debe contener al menos "}${min}${ " caracteres"}`
        : '';
}

export const minLength6 = (value: any) => minLength(6, value);
export const minLength10 = (value: any) => minLengthPhone(10, value);
export const maxLength10 = (value: any) => maxLength(10, value);
export const maxLength15 = (value: any) => maxLength(15, value);
export const maxLength25 = (value: any) => maxLength(25, value);
export const maxLength50 = (value: any) => maxLength(50, value);
export const maxLength100 = (value: any) => maxLength(100, value);
export const maxLength250 = (value: any) => maxLength(250, value);
export const maxLength255 = (value: any) => maxLength(255, value);
export const maxLength320 = (value: any) => maxLength(320, value);
export const maxLength500 = (value: any) => maxLength(500, value);

export const validate = (validations: Array<any>, value: any) => {
    let error = '';
    for (let i = 0; i < validations.length; i++) {
        error = validations[i](value);
        if (error.length) {
            return error;
        }
    }
    return error;
}

/**
 * Checks if the value is a valid currency string.
 * 
 * @param value The string or number to be tested
 * @returns The formatted string if it's valid or false otherwise
 */
export const formatInteger = (value: string) => {
    const isValidValue = number_regex.test(value)
    if (isValidValue || value === '') {
        if (value.length > 1 && value.startsWith('0') && value[1] !== '.') {
            value = value.substr(1)
        }
        return value
    }
    return false
}

/**
 * Checks if the value is a valid currency string.
 * 
 * @param value The string to be tested
 * @returns The formatted string if it's valid or false otherwise
 */
export const formatCurrency = (value: string) => {
    const isValidValue = currency_regex.test(value)
    if (isValidValue || value === '') {
        if (value.length > 1 && value.startsWith('0') && value[1] !== '.') {
            value = value.substr(1)
        }
        return value
    }
    return false
}

export const dateToUnixTime = (date: any, startOf?: any) => {
    if (startOf) {
        return Number(moment(date).startOf(startOf).format('X'));
    }

    return Number(moment(date).format('X'));
}