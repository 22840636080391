import { Reducer } from 'redux';
import LISTRISKPEOPLE_CONSTANTS from '../constants/listRiskPeople-constants';
import { listRiskPeopleAction } from '../types/listRiskPeople-types';

export interface LiskRiskPeopleState {
    loading: boolean;
    success: boolean;
    successMessage: string;
    listRiskPeople: [];
    error: boolean;
    errorMessage?: string;
    errorStatus?: number;
}

const initialState: LiskRiskPeopleState = {
    loading: false,
    success: false,
    error: false,
    listRiskPeople: [],
    successMessage: '',
    errorMessage: '',
    errorStatus: 0,
}

export const listRiskPeopleReducer: Reducer<LiskRiskPeopleState, listRiskPeopleAction> = (state = initialState, action: listRiskPeopleAction) => {
    switch (action.type) {
        //----------GET NAME LIST RISK PEOPLE-----------//
        case LISTRISKPEOPLE_CONSTANTS.GET_LISTRISKPEOPLE_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            }
        
        case LISTRISKPEOPLE_CONSTANTS.GET_LISTRISKPEOPLE_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                errorStatus: action.error?.status,
                errorMessage: action.error?.data.message,
                successMessage: '',
                loading: false
            }
        
        case LISTRISKPEOPLE_CONSTANTS.GET_LISTRISKPEOPLE_SUCCESS:
            return {
                ...state,
                success: true,
                listRiskPeople: action.data,
                errorMessage: '',
                errorStatus: 0,
                error: false,
                loading: false,
                successMessage:'El nombre del prospecto se encuentra en la lista de riesgo' 
            }
        
        case LISTRISKPEOPLE_CONSTANTS.RESET_LISTRISKPEOPLE_STATUS:
            return {
                success: false,
                error: false,
                loading: false,
                listRiskPeople: [],
                errorStatus: 0,
                errorMessage: '',
                successMessage: '',
            }

        default:
            return state;
    }
}