import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useEffect, useState } from 'react';
import { Card, Row, Button, Modal, Col } from 'react-bootstrap';
import { documentsDescription } from '../../../../constants/documentsDescription';

const IdInfo: React.FC = () => {
    //const to get the params
    const history = useHistory();

    const user = useSelector((state: any) => state.authReducer.user);
    const files = useSelector((state: any) => state.DocumentsReducer.flowDocuments);
    const [url, setUrl] = useState<any>();
    const [showImageModal, setShowImageModal] = useState(false)

    useEffect(() => {
        if (Object.keys(user).length === 0) {
            history.push('/login');
        }
    }, []);

    const base64toBlob = (file: any) => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64
        var base64WithoutPrefix = file;
        if (file.includes('dataapplication/pdfbase64')) {
            base64WithoutPrefix = file.substr('dataapplication/pdfbase64'.length);
        }
        if (file.includes('dataapplication/pdfbase64')) {
            base64WithoutPrefix = file.substr('dataapplication/pdfbase64'.length);
        }
        if (file.includes('data:application/pdf;base64,')) {
            base64WithoutPrefix = file.substr('data:application/pdf;base64,'.length);
        }
        const bytes = atob(file);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: 'application/pdf' });
    };

    const _renderDescription = (type: string) => {
        switch (type) {
            case 'idFront':
                return documentsDescription.idFront;
            case 'idBack':
                return documentsDescription.idBack;
        }
    }

    return (
        <>
        {files[0] !== undefined &&
            <Card>
                <Card.Header className="d-flex align-items-center justify-content-between">
                    <h5 className="mb-0">Identificación Oficial (INE)</h5>
                </Card.Header>
                <Card.Body className={'border-top pro-det-edit collapse border-top pro-det-edit show'}>
                    <Row>
                        {files &&
                            files.map((file: any) => {
                                if(file.type === "idFront" || file.type === "idBack") {
                                    return (
                                        <Col className='customerProfile-files'>
                                            {file.name === '' ? ( '' ) : (
                                                <button
                                                    type="button"
                                                    className="btn btn-third btn-sm btn-border-radius float-center mt-2 doc-showBtn"
                                                    onClick={() => {
                                                        const blob = base64toBlob(file.file);
                                                        const urlAux = URL.createObjectURL(blob);
                                                        setUrl(urlAux);
                                                        setShowImageModal(!showImageModal);
                                                    }}
                                                >
                                                    <i className={'feather icon-file-text'} />
                                                </button>
                                            )}
                                            <p className="mt-3 align-middle doc-titleDocument">
                                                {_renderDescription(file.type)}
                                            </p>
                                        </Col>
                                    )
                                }
                            })
                        }
                    </Row>
                    <Modal size="xl" show={showImageModal} onHide={() => setShowImageModal(!showImageModal)}>
                        <Modal.Header closeButton className='approvalFlow-docsModal-header'>
                            <Modal.Title as="h5">Documento</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='d-flex flex-column approvalFlow-docsModal-body'>
                            <img src={url} style={{ width: '100%', height: 'auto' }} alt="Imagen de documento"/>
                            <div className="d-flex justify-content-end mt-3">
                                <Button className='docs-infoBtn'>
                                    <a className="text-white" download={url + '.jpg'} href={url}>
                                        Descargar
                                    </a>
                                </Button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </Card.Body>
            </Card>
        }
        </>
    );
};
export default IdInfo;
