enum CIRCLE_CREDIT_CONSTANTS {
    GET_RCC_PM_BEGIN = "GET_RCC_PM_BEGIN",
    GET_RCC_PM_ERROR = "GET_RCC_PM_ERROR",
    GET_RCC_PM_SUCCESS = "GET_RCC_PM_SUCCESS",

    GET_RCC_FICOSCORE_BEGIN = "GET_RCC_FICOSCORE_BEGIN",
    GET_RCC_FICOSCORE_ERROR = "GET_RCC_FICOSCORE_ERROR",
    GET_RCC_FICOSCORE_SUCCESS = "GET_RCC_FICOSCORE_SUCCESS",

    RESET_CIRCLE_CREDIT = "RESET_CIRCLE_CREDIT"
};

export default CIRCLE_CREDIT_CONSTANTS; 