import { Reducer } from 'redux';
import { BussinessInfoAction } from '../types/bussinessInfo-types'
import bussinessInfoModel from '../../models/bussinessInformation-model';
import BUSSINESS_INFO_CONSTANTS from '../constants/bussinessInfo-constants';

export interface BussinessReducerState {
    backTo?: string
    errorMessage: string 
    errorStatus?: number
    error: boolean
    bussinessInfo: bussinessInfoModel,
    success: boolean,
    successMessage: string,
}

const initialState: BussinessReducerState = {
    backTo: undefined,
    errorMessage: "",
    errorStatus: 0,
    error: false,
    bussinessInfo: {} as bussinessInfoModel,
    success: false,
    successMessage: "",
};


export const bussinessInfoReducer: Reducer<BussinessReducerState, BussinessInfoAction> = (state = initialState, action: BussinessInfoAction) => {

    switch (action.type) {
        //------------------ POST BUSSINESS INFO -----------------------------
        case BUSSINESS_INFO_CONSTANTS.POST_BUSSINESS_INFO_BEGIN:
            return {
                ...state,
                errorMessage: "",
                loading: true,
                error: false,
                errorStatus: 0
            }

        case BUSSINESS_INFO_CONSTANTS.POST_BUSSINESS_INFO_ERROR:

            return {
                ...state,
                errorMessage: action.error.data.errorMessage,
                errorStatus: action.error ? action.error.status : 0,
                error: true,
                loading: false
            }

        case BUSSINESS_INFO_CONSTANTS.POST_BUSSINESS_INFO_SUCCESS:
            return {
                ...state,
                errorMessage: "",
                error: false,
                successMessage: action.data.message,
                loading: false,
                bussinessInfo: action.data.bussinessInfo || action.data,
                errorStatus: 0,
            }
        //------------------ GET BUSSINESS INFORMATION -----------------------------
        case BUSSINESS_INFO_CONSTANTS.GET_BUSSINESS_INFO_BEGIN:
            return {
                ...state,
                errorMessage: "",
                loading: true,
                error: false,
                errorStatus: 0,
                curpMessage: ""
            }

        case BUSSINESS_INFO_CONSTANTS.GET_BUSSINESS_INFO_ERROR:
            return {
                ...state,
                errorMessage: action.error.data.errorMessage,
                errorStatus: action.error.status,
                error: true,
                loading: false
            }

        case BUSSINESS_INFO_CONSTANTS.GET_BUSSINESS_INFO_SUCCESS:
            return {
                ...state,
                errorMessage: "",
                error: false,
                loading: false,
                bussinessInfo: action.data.bussinessInfo,
                errorStatus: 0,
                successMessage:'',
                success: true
            }

        case BUSSINESS_INFO_CONSTANTS.RESET_BUSSINESS_INFO_STATUS:
            return {
                ...state,
                loading: false,
                errorMessage: '',
                errorStatus: 0,
                success: false,
                bussinessInfo: {} as bussinessInfoModel,
                successMessage: ''
            }

        default:
            return state
    }
}
