import { Reducer } from 'redux';
import CREDIT_PROPOSAL from '../constants/creditProposal-constants';
import { CreditProposal } from '../types/creditProposal-types';

export interface CreditProposalReducerState {
    loading: boolean;
    success: boolean;
    successMessage: string;
    creditProposal: {};
    error: boolean;
    errorMessage?: string;
    errorStatus?: number;
}

const initialState: CreditProposalReducerState = {
    loading: false,
    success: false,
    creditProposal: {},
    error: false,
    successMessage: '',
    errorMessage: '',
    errorStatus: 0
};

export const creditProposalReducer: Reducer<CreditProposalReducerState, CreditProposal> = (state = initialState, action: CreditProposal) => {
    switch (action.type) {
        //----------CREATE CREDIT PROPOSAL-----------//
        case CREDIT_PROPOSAL.POST_CREDIT_PROPOSAL_BEGIN:
            return {
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                creditProposal: {},
                errorMessage: '',
                successMessage: ''
            };

        case CREDIT_PROPOSAL.POST_CREDIT_PROPOSAL_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                creditProposal: {},
                errorStatus: action.error.data.status,
                errorMessage: action.error.data.message,
                loading: false
            };

        case CREDIT_PROPOSAL.POST_CREDIT_PROPOSAL_SUCCESS:
            return {
                ...state,
                success: true,
                successMessage: action.data.message,
                error: false,
                errorMessage: '',
                errorStatus: 0,
                loading: false
            };

        //----------UPDATE CREDIT PROPOSAL-----------//
        case CREDIT_PROPOSAL.UPDATE_CREDIT_PROPOSAL_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            };

        case CREDIT_PROPOSAL.UPDATE_CREDIT_PROPOSAL_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                errorStatus: action.error.data.status,
                errorMessage: action.error.data.message,
                loading: false
            };

        case CREDIT_PROPOSAL.UPDATE_CREDIT_PROPOSAL_SUCCESS:
            return {
                ...state,
                success: true,
                successMessage: action.data.message,
                creditProposal: action.data,
                error: false,
                errorMessage: '',
                errorStatus: 0,
                loading: false
            };

        //----------GET CREDIT PROPOSAL-----------//
        case CREDIT_PROPOSAL.GET_CREDIT_PROPOSAL_BEGIN:
            return {
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                creditProposal: {},
                errorMessage: '',
                successMessage: ''
            };

        case CREDIT_PROPOSAL.GET_CREDIT_PROPOSAL_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                creditProposal: {},
                errorStatus: action.error?.data.status,
                errorMessage: action.error.data.message,
                loading: false
            };

        case CREDIT_PROPOSAL.GET_CREDIT_PROPOSAL_SUCCESS:
            return {
                ...state,
                success: true,
                successMessage: action.data.message,
                creditProposal: action.data,
                error: false,
                errorMessage: '',
                errorStatus: 0,
                loading: false
            };

        case CREDIT_PROPOSAL.CREDIT_PROPOSAL_RESET_STATUS:
            return {
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                creditProposal: {},
                errorMessage: '',
                successMessage: ''
            };

        default:
            return state;
    }
}