import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { refreshToken } from '../../flux/actions/refreshToken-action';

const Dashboard: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            refreshToken({
                token: localStorage.getItem('token'),
                refreshToken: localStorage.getItem('refreshToken')
            })
        );
    }, []);

    return (
        <div>
            <h1>Dashboard</h1>
        </div>
    );
};

export default Dashboard;
