/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useGeoLocation } from '../hooks/useGeoLocation';
import { useSelector, useDispatch } from 'react-redux';
import { updateLocation } from '../flux/actions/client-actions';

const ModalGeolocation: React.FC = () => {
    const { client } = useSelector((state: any) => state.clientReducer);
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(true);
    const location = useGeoLocation();

    const uploadLocation = () => {
        const data = {
            clientRef: client?.id,
            latitude: `${location.coordinates.latitude}`,
            longitude: `${location.coordinates.longitude}`
        };

        dispatch(updateLocation(data));
    };

    return (
        <>
            <Modal
                show={location.loaded ? !showModal : showModal}
                className="d-flex flex-column align-items-center justify-content-center onBoarding-geolocationModal"
                onExited={uploadLocation}
            >
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <i className="feather icon-map-pin mb-4" />
                    <h4 className="text-center mb-4">Por favor activa tu ubicación.</h4>
                    <p className="text-center">Es necesario que actives tu ubicación desde tu dispositivo móvil o computadora.</p>
                    <p className="text-center">
                        Tienes problemas para activar tu localización, ingresa a: <br />
                        <a href="https://www.kualinet.com/geolocalizacion" target="_blank" rel="noreferrer">
                            kualinet.com/geolocalizacion
                        </a>
                    </p>
                </div>
            </Modal>
        </>
    );
};
export default ModalGeolocation;
