import { Reducer } from 'redux';
import { FlowAction } from '../types/flow-types'
import FLOW_CONSTANTS from '../constants/flow-constants';

export interface BussinessReducerState {
    backTo?: string,
    errorMessage: string,
    errorStatus?: number,
    error: boolean,
    page: number,
    flow: any,
    users: any,
    flows: any,
    success: boolean,
    successMessage: string,
}

const initialState: BussinessReducerState = {
    backTo: undefined,
    errorMessage: "",
    errorStatus: 0,
    error: false,
    page: 1,
    flow: [],
    flows: [],
    users: [],
    success: false,
    successMessage: "",
};


export const flowReducer: Reducer<BussinessReducerState, FlowAction> = (state = initialState, action: FlowAction) => {

    switch (action.type) {
        //------------------ CREATE FLOW -----------------------------
        case FLOW_CONSTANTS.CREATE_FLOW_BEGIN:
            return {
                ...state,
                errorMessage: "",
                loading: true,
                successMessage: "",
                error: false,
                errorStatus: 0
            }

        case FLOW_CONSTANTS.CREATE_FLOW_ERROR:

            return {
                ...state,
                errorMessage: action.error.data.message,
                successMessage: "",
                errorStatus: action.error ? action.error.status : 400,
                error: true,
                loading: false
            }

        case FLOW_CONSTANTS.CREATE_FLOW_SUCCESS:
            return {
                ...state,
                errorMessage: "",
                error: false,
                successMessage: action.data.message,
                loading: false,
                flow: action.data.response,
                errorStatus: 0,
            }

        //------------------ GET ALL FLOWS PAGINATED -----------------------------
        case FLOW_CONSTANTS.GET_ALL_FLOWS_BEGIN:
            return {
                ...state,
                errorMessage: "",
                loading: true,
                successMessage: "",
                error: false,
                errorStatus: 0
            }

        case FLOW_CONSTANTS.GET_ALL_FLOWS_ERROR:

            return {
                ...state,
                errorMessage: action.error.data.message,
                successMessage: "",
                errorStatus: action.error ? action.error.status : 400,
                error: true,
                loading: false
            }

        case FLOW_CONSTANTS.GET_ALL_FLOWS_SUCCESS:
            return {
                ...state,
                errorMessage: "",
                error: false,
                successMessage: action.data.message,
                loading: false,
                flows: action.data.flows.docs,
                users: action.data.users,
                page: action.data.flows,
                errorStatus: 0,
            }

        //------------------ GET FLOW BY ID PAGINATED -----------------------------
        case FLOW_CONSTANTS.GET_FLOW_BEGIN:
            return {
                ...state,
                errorMessage: "",
                loading: true,
                successMessage: "",
                error: false,
                errorStatus: 0
            }

        case FLOW_CONSTANTS.GET_FLOW_ERROR:

            return {
                ...state,
                errorMessage: action.error?.data.message,
                successMessage: "",
                errorStatus: action.error ? action.error.status : 400,
                error: true,
                loading: false
            }

        case FLOW_CONSTANTS.GET_FLOW_SUCCESS:
            return {
                ...state,
                errorMessage: "",
                error: false,
                successMessage: action.data.message,
                loading: false,
                flow: action.data.flow,
                errorStatus: 0,
            }

        //------------------ RESET REDUCERR STATUS -----------------------------
        case FLOW_CONSTANTS.RESET_FLOW_STATUS:
            return {
                ...state,
                loading: false,
                errorMessage: '',
                errorStatus: 0,
                success: false,
                flow: [],
                successMessage: ''
            }

        //------------------ RESET REDUCERR STATUS -----------------------------
        case FLOW_CONSTANTS.RESET_FLOW_MESSAGES:
            return {
                ...state,
                errorMessage: '',
                successMessage: ''
            }

        default:
            return state
    }
}
