import React, { useEffect, useState } from 'react';
import { Card, Form, Button, Modal, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { esES } from '@mui/x-date-pickers';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { getUsers } from '../../../../flux/actions/users-actions';
import { putUpdateSchedule } from '../../../../flux/actions/client-actions';
import { getCredit } from '../../../../flux/actions/quote-actions';
import ScheduleDocuments from './ScheduleDocuments';
import moment from 'moment';
import { sendEmailScheduledVisit } from '../../../../services/email/email';
import { email } from '../../../../helpers/validations';

const ScheduleDate: React.FC = () => {
    //redux const
    const dispatch = useDispatch();
    const params = useParams();
    const clientRef = (params as any)?.id;
    const { client } = useSelector((state: any) => state.clientReducer);
    const { users } = useSelector((state: any) => state.authReducer);

    //local const
    const [showScheduleModal, setShowScheduleModal] = useState(false);
    const [userSelect, setUserSelect] = useState('');
    const [dateValue, setDateValue] = useState('');
    const [saveError, setSaveError] = useState(false);
    const dateOptions = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };

    useEffect(() => {
        dispatch(getUsers());
    }, [dispatch]);

    const handleSchedule = () => {
        if (userSelect === '' || dateValue === '') {
            setShowScheduleModal(false);
            setSaveError(true);
        } else {
            setTimeout(() => {
                updateSchedule();
                dispatch(getCredit(clientRef));
            }, 1000);
        }
    };

    // Update Schedule
    const updateSchedule = () => {
        if (userSelect === '' || dateValue === '') {
            setShowScheduleModal(false);
            setSaveError(true);
        } else {
            dispatch(
                putUpdateSchedule({
                    clientRef: clientRef,
                    scheduledDate: dateValue,
                    scheduledUser: userSelect,
                    scheduleStatus: true,
                    bussinessPhoto1: '',
                    bussinessPhoto2: '',
                    bussinessPhoto3: ''
                })
            );

            sendEmailScheduledVisit(client.email, client.names, client.lastName1, client.lastName2, userSelect, dateValue)
                .then((response) => {
                    return true;
                })
                .catch((error) => {
                    return false;
                });
        }
        setShowScheduleModal(false);
    };

    return (
        <>
            {client?.scheduleStatus === false ? (
                <Card>
                    <Card.Header className="approvalFlow-schedule-header">
                        <Card.Title as="h5">Agendar Visitas:</Card.Title>
                        <p className="mt-2">
                            Asigna una fecha para visitar la ubicación del prospecto. Al hacerlo, un correo le indicará al prospecto la
                            fecha y hora que seleccionaste.
                        </p>
                        <span className="schedule-updateTime">
                            Actualizado hace{' '}
                            {moment().diff(client.updatedAt, 'days') !== 0
                                ? moment().diff(client.updatedAt, 'days')
                                : moment().diff(client.updatedAt, 'hours')}
                            {moment().diff(client.updatedAt, 'days') !== 0 ? ' días' : ' horas'}
                        </span>
                    </Card.Header>
                    <Card.Body className="approvalFlow-schedule-body">
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <Card.Title as="h5">¿Quién realizará la vista?</Card.Title>
                            <Form>
                                <Form.Control
                                    as={'select'}
                                    value={userSelect}
                                    onChange={(e) => {
                                        setUserSelect(e.target.value);
                                    }}
                                >
                                    <option value={''}>Selecciona el usuario que realizará la visita</option>
                                    {Array.isArray(users?.user) &&
                                        users?.user?.map((itemUser: any) => (
                                            <option value={itemUser.names + ' ' + itemUser.lastName1 + ' ' + itemUser.lastName2}>
                                                {itemUser.names} {itemUser.lastName1} {itemUser.lastName1}
                                            </option>
                                        ))}
                                </Form.Control>
                            </Form>
                        </div>
                        <div className="d-flex flex-column align-items-center justify-content-center scheduleCalendar mt-2">
                            <LocalizationProvider
                                dateAdapter={AdapterMoment}
                                localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
                            >
                                <StaticDateTimePicker
                                    orientation="landscape"
                                    onChange={(newValue: any) => {
                                        setDateValue(`${newValue._d.toLocaleTimeString('es-ES', dateOptions)}`);
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                    </Card.Body>
                    <Card.Footer className="approvalFlow-schedule-footer">
                        {saveError ? (
                            <p id="roleHelpBlock" className="form-text text-center text-danger">
                                Selecciona la persona que realizará la visita y asigna una fecha.
                            </p>
                        ) : (
                            <></>
                        )}
                        <Card.Title as="h5">¿Quieres agendar la visita al prospecto?</Card.Title>
                        <Button
                            className="btn-border-radius schedule-successBtn"
                            onClick={() => {
                                setShowScheduleModal(true);
                            }}
                        >
                            Agendar
                        </Button>
                    </Card.Footer>
                    <Modal
                        show={showScheduleModal}
                        onHide={() => {
                            setShowScheduleModal(!showScheduleModal);
                        }}
                    >
                        <Modal.Header closeButton className="approvalFlow-scheduleModal-header">
                            <Modal.Title as="h5">Agendar visita a prospecto</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="approvalFlow-scheduleModal-body">
                            <p>
                                Prospecto a visitar: <span>{`${client.names} ${client.lastName1} ${client.lastName2}`}</span>
                            </p>
                            <p>
                                Visita por: <span>{userSelect}</span>
                            </p>

                            <p>
                                Fecha de visita: <span>{`${dateValue}`}</span>
                            </p>
                            <p>¿Deseas continuar?</p>
                        </Modal.Body>
                        <Modal.Footer className="approvalFlow-schedule-footer">
                            <Row className="mt-3 mb-3">
                                <Button
                                    className="btn-border-radius schedule-alertBtn"
                                    variant="danger"
                                    onClick={() => setShowScheduleModal(!showScheduleModal)}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    className="ml-3 btn-border-radius schedule-successBtn"
                                    variant="success"
                                    onClick={() => handleSchedule()}
                                >
                                    Aceptar
                                </Button>
                            </Row>
                        </Modal.Footer>
                    </Modal>
                </Card>
            ) : (
                <>
                    <ScheduleDocuments />
                </>
            )}
        </>
    );
};

export default ScheduleDate;
