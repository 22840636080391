import * as React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import DEMO from '../../store/constant';
import UserModel from '../../models/user-model';
import ApprovalFlow from './CustomerInformation/ApprovalFlow';
import { getBussinessInfo } from '../../flux/actions/bussinessInfo-actions';
import { resetApprovalProcessStatus } from '../../flux/actions/approvalProcess-actions';
import { getCredit } from '../../flux/actions/quote-actions';
import { getClient } from '../../flux/actions/client-actions';
import RegisterData from './CustomerInformation/RegisterData';
import PromoterFlow from './CustomerInformation/PromoterFlow';
import { refreshToken } from '../../flux/actions/refreshToken-action';

const CustomerProfile: React.FC = () => {
    //const to get the params
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    const userRef = (params as any)?.id;

    const user: UserModel = useSelector((state: any) => state.authReducer.user);
    const client = useSelector((state: any) => state.clientReducer.client);
    const quote: any = useSelector((state: any) => state.quoteReducer.credit);

    useEffect(() => {
        if (Object.keys(user).length === 0) {
            history.push('/admin/login');
        }
        if (user.role === 'customer') {
            history.push('/usuario/inicio');
        }
        getInfo();
        dispatch(
            refreshToken({
                token: localStorage.getItem('token'),
                refreshToken: localStorage.getItem('refreshToken')
            })
        );
    }, []);

    const [data, setData] = useState({
        activeProfileTab: 'approvalFlow',
        isPersonalEdit: false,
        isContactEdit: false,
        isOtherEdit: false
    });

    const profileTabClass = 'nav-link text-reset customerProfile-tab';
    const profileTabActiveClass = 'nav-link text-reset active customerProfile-tab--active';
    const profilePanClass = 'tab-pane fade';
    const profilePanActiveClass = 'tab-pane customerProfile-tab-pane fade show active';

    const getInfo = () => {
        dispatch(getClient(userRef));
        dispatch(getBussinessInfo(userRef));
        dispatch(getCredit(userRef));
    };

    return (
        <Card>
            <div className="user-profile user-card mb-4 mt-4 customerProfile">
                <Card.Body className="customerProfile-body">
                    <div className="user-about-block m-0">
                        <Row className="customerProfile-header">
                            <Col md={12}>
                                <ul className="nav nav-tabs nav-fill customerProfile-tabs" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a
                                            className={data.activeProfileTab === 'creditRequest' ? profileTabActiveClass : profileTabClass}
                                            onClick={() => {
                                                setData({ ...data, activeProfileTab: 'creditRequest' });
                                            }}
                                            id="creditRequest-tab"
                                            href={DEMO.BLANK_LINK}
                                        >
                                            <i className="feather icon-file-text mr-2" />
                                            {client.names} {client.lastname1}
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className={data.activeProfileTab === 'approvalFlow' ? profileTabActiveClass : profileTabClass}
                                            onClick={() => {
                                                setData({ ...data, activeProfileTab: 'approvalFlow' });
                                            }}
                                            id="approvalFlow-tab"
                                            href={DEMO.BLANK_LINK}
                                        >
                                            <i className="feather icon-zap mr-2" />
                                            Flujo de Aprobación
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className={data.activeProfileTab === 'data' ? profileTabActiveClass : profileTabClass}
                                            onClick={async () => {
                                                setData({ ...data, activeProfileTab: 'data' });
                                                dispatch(getBussinessInfo(userRef));
                                            }}
                                            id="data-tab"
                                            href={DEMO.BLANK_LINK}
                                        >
                                            <i className="feather icon-user mr-2" />
                                            Expediente
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </Card.Body>
            </div>
            <Row>
                <Col md={12} className="order-md-2">
                    <div className="tab-content">
                        <div
                            className={data.activeProfileTab === 'creditRequest' ? profilePanActiveClass : profilePanClass}
                            id="creditRequest"
                        >
                            <PromoterFlow />
                        </div>
                        <div
                            className={data.activeProfileTab === 'approvalFlow' ? profilePanActiveClass : profilePanClass}
                            id="approvalFlow"
                        >
                            <ApprovalFlow />
                        </div>
                        <div className={data.activeProfileTab === 'data' ? profilePanActiveClass : profilePanClass} id="data">
                            <RegisterData />
                        </div>
                    </div>
                </Col>
            </Row>
        </Card>
    );
};
export default CustomerProfile;
