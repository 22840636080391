import { Reducer } from 'redux';
import SAT_WS_CONSTANTS from '../constants/satws-constants';
import { satwsAction } from '../types/satws-types';

export interface satwsReducerState {
    loading: boolean;
    success: boolean;
    successMessage: string;
    error: boolean;
    errorMessage?: string;
    errorStatus?: number;
}

const initialState: satwsReducerState = {
    loading: false,
    success: false,
    error: false,
    successMessage: '',
    errorMessage: '',
    errorStatus: 0,
}

export const satwsReducer: Reducer<satwsReducerState, satwsAction> = (state = initialState, action: satwsAction) => {
    switch (action.type) {
        //----------CREATE ORDER-----------//
        case SAT_WS_CONSTANTS.CREATE_CREDENTIALS_SAT_WS_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            }

        case SAT_WS_CONSTANTS.CREATE_CREDENTIALS_SAT_WS_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                errorStatus: action.error?.message?.status ?? 500,
                errorMessage: action.error?.message?.detail ?? action.error?.message,
                loading: false
            }

        case SAT_WS_CONSTANTS.CREATE_CREDENTIALS_SAT_WS_SUCCESS:
            return {
                ...state,
                success: true,
                successMessage: action.data.message,
                error: false,
                errorMessage: '',
                errorStatus: 0,
                loading: false
            }

        case SAT_WS_CONSTANTS.RESET_SAT_WS_STATUS:
            return {
                success: false,
                error: false,
                loading: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            }

        default:
            return state;
    }
} 