import axios from 'axios';

const API_URL = process.env.REACT_APP_BASE_URL;

const getUser = (id: string) => {
    return axios.get(`${API_URL}users/${id}`);
};

const getUsers = () => {
    return axios.get(`${API_URL}users/allUsers`);
};

const updateUser = (data: any) => {
    return axios.put(`${API_URL}users/updateUser`, data);
};

const renewPassword = (data: any) => {
    return axios.put(`${API_URL}users/renewPassword`, data);
};

export const API = {
    getUsers,
    getUser,
    updateUser,
    renewPassword
};
