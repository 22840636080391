import { Console } from 'console';
import { Dispatch } from 'redux';
import { APIQ } from '../api/quote-api';
import { API } from '../api/unic-api';
import APPROVAL_PROCESS_CONSTANTS from '../constants/approvalProcess-constants';
import * as types from '../types/approvalProcess-types';

//---------------------------------- POST APPROVAL PROCESS --------------------------------------------------
const postApprovalProcessBegin = (): types.IPostApprovalProcessBegin => {
    return {
        type: APPROVAL_PROCESS_CONSTANTS.POST_APPROVAL_PROCESS_BEGIN
    };
};

const postApprovalProcessError = (e: any): types.IPostApprovalProcessError => {
    return {
        type: APPROVAL_PROCESS_CONSTANTS.POST_APPROVAL_PROCESS_ERROR,
        error: e
    };
};

export const postApprovalProcessSuccess = (data: any): types.IPostApprovalProcessSuccess => {
    return {
        type: APPROVAL_PROCESS_CONSTANTS.POST_APPROVAL_PROCESS_SUCCESS,
        data
    };
};

export const postApprovalProcess = (payload: any) => {
    return (dispatch: Dispatch) => {
        dispatch(postApprovalProcessBegin());
        API.postApprovalProcess(payload)
            .then((response: any) => {
                dispatch(postApprovalProcessSuccess(response.data));
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = '/login';
                }
                dispatch(postApprovalProcessError(error.response));
            });
    };
};

//---------------------------------- GET APPROVAL PROCESS --------------------------------------------------
const getApprovalProcessBegin = (): types.IGetApprovalProcessBegin => {
    return {
        type: APPROVAL_PROCESS_CONSTANTS.GET_APPROVAL_PROCESS_BEGIN
    };
};

const getApprovalProcessError = (e: any): types.IGetApprovalProcessError => {
    return {
        type: APPROVAL_PROCESS_CONSTANTS.GET_APPROVAL_PROCESS_ERROR,
        error: e
    };
};

const getApprovalProcessSuccess = (data: any): types.IGetApprovalProcessSuccess => {
    return {
        type: APPROVAL_PROCESS_CONSTANTS.GET_APPROVAL_PROCESS_SUCCESS,
        data
    };
};

export const getApprovalProcess = (clientRef: string) => {
    return (dispatch: Dispatch) => {
        dispatch(getApprovalProcessBegin());
        APIQ.getCredits(clientRef)
            .then((response: any) => {
                API.getApprovalProcess(response.data.credits.flowRef)
                    .then((approvalProcess: any) => {
                        dispatch(getApprovalProcessSuccess(approvalProcess.data));
                    })
                    .catch((error: any) => {
                        if (error.status === 401) {
                            localStorage.removeItem('token');
                            localStorage.removeItem('refreshToken');
                            localStorage.removeItem('persist:authReducer');
                            localStorage.removeItem('persist:root');
                            localStorage.removeItem('nubarium:redirected:FaceCapture');
                            localStorage.removeItem('nubarium:redirected:RecordMessage');
                            window.location.href = '/login';
                        }
                        dispatch(getApprovalProcessError(error.response));
                    });
            })
            .catch((err) => console.log(err));
    };
};

//---------------------------------- GET APPROVAL PROCESS --------------------------------------------------
const getApprovalProcessFlowrefBegin = (): types.IGetApprovalProcessBegin => {
    return {
        type: APPROVAL_PROCESS_CONSTANTS.GET_APPROVAL_PROCESS_BEGIN
    };
};

const getApprovalProcessFlowrefError = (e: any): types.IGetApprovalProcessError => {
    return {
        type: APPROVAL_PROCESS_CONSTANTS.GET_APPROVAL_PROCESS_ERROR,
        error: e
    };
};

const getApprovalProcessFlowrefSuccess = (data: any): types.IGetApprovalProcessSuccess => {
    return {
        type: APPROVAL_PROCESS_CONSTANTS.GET_APPROVAL_PROCESS_SUCCESS,
        data
    };
};

export const getApprovalFlowrefProcess = (flowRef: string) => {
    return (dispatch: Dispatch) => {
        dispatch(getApprovalProcessFlowrefBegin());
        API.getApprovalProcess(flowRef)
            .then((response: any) => {
                dispatch(getApprovalProcessFlowrefSuccess(response.data));
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = '/login';
                }
                dispatch(getApprovalProcessFlowrefError(error.response));
            });
    };
};

//---------------------------------- REST APPROVAL PROCESS --------------------------------------------------
const resetStatus = () => {
    return {
        type: APPROVAL_PROCESS_CONSTANTS.RESET_APPROVAL_PROCESS_STATUS
    };
};

export const resetApprovalProcessStatus = () => {
    return (dispatch: Dispatch) => {
        dispatch(resetStatus());
    };
};
