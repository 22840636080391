import { Dispatch } from "redux";

import { API } from "../api/affiliate-api";
import AFFILIATE_CONSTANTS from "../constants/affiliate-constants"
import * as types from '../types/affiliate-types';

//---------------------------------- GET MORAL PERSON INFORMATION --------------------------------------------------
const postAffiliateBegin = (): types.IPostAffiliateBegin => {
    return {
        type: AFFILIATE_CONSTANTS.POST_AFFILIATE_BEGIN
    };
}

const postAffiliateError = (e: any): types.IPostAffiliateError => {
    return {
        type: AFFILIATE_CONSTANTS.POST_AFFILIATE_ERROR,
        error: e
    };
}

export const postAffiliateSuccess = (data: any): types.IPostAffiliateSuccess => {
    return {
        type: AFFILIATE_CONSTANTS.POST_AFFILIATE_SUCCESS,
        data
    };
}

export const postAffiliate = (payload: any) => {
    return (dispatch: Dispatch) => {
        dispatch(postAffiliateBegin());
        API.postAffiliate(payload)
            .then((response: any) => {
                dispatch(postAffiliateSuccess(response.data));
            }).catch((error: any) => {
                if (error.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = "/login";
                } else {
                }
                dispatch(postAffiliateError(error.response));
            });
    }
}


//---------------------------------- GET MORAL PERSON INFORMATION --------------------------------------------------
const getAffiliateBegin = (): types.IGetAffiliateBegin => {
    return {
        type: AFFILIATE_CONSTANTS.GET_AFFILIATE_BEGIN
    };
}

const getAffiliateError = (e: any): types.IGetAffiliateError => {
    return {
        type: AFFILIATE_CONSTANTS.GET_AFFILIATE_ERROR,
        error: e
    };
}

const getAffiliateSuccess = (data: any): types.IGetAffiliateSuccess => {
    return {
        type: AFFILIATE_CONSTANTS.GET_AFFILIATE_SUCCESS,
        data
    };
}

export const getAffiliate = (clientRef: string) => {
    return (dispatch: Dispatch) => {
        dispatch(getAffiliateBegin());
        API.getAffiliate(clientRef)
            .then((response: any) => {
                dispatch(getAffiliateSuccess(response.data));
            }).catch((error: any) => {
                if (error.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = "/login";
                }
                dispatch(getAffiliateError(error.response));
            });
    }
}

const resetStatus = () => {
    return {
        type: AFFILIATE_CONSTANTS.RESET_AFFILIATE_STATUS
    }
}

export const resetAffiliateStatus = () => {
    return (dispatch: Dispatch) => {
        dispatch(resetStatus());
        ;
    }
}