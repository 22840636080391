import * as React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Col, Row, Modal, Button } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { getEconomicActivities, resetEconomicActivitiesStatus } from '../../../../flux/actions/economicAct-actions';
import { resetClientStatus, updateClient } from '../../../../flux/actions/client-actions';
import { resetBussinessInfoStatus, postBussinessInfo } from '../../../../flux/actions/bussinessInfo-actions';
import Loader from '../../../../components/ScreenLoader/ScreenLoader';

const BussinessInfo: React.FC = () => {
    //const to get the params
    const dispatch = useDispatch();
    const params = useParams();
    const userRef = (params as any)?.id;
    const { client, loading, successMessage } = useSelector((state: any) => state.clientReducer);
    const { bussinessInfo, biLoading, biSuccesMessage } = useSelector((state: any) => state.bussinessInfoReducer);
    const economicActivities = useSelector((state: any) => state.economicActivitiesReducer.economicActivitiesData);

    //BUSSINESS INFORMATION CONST
    const [economicActivity, setEconomicActivity] = useState('');
    const [antiquity, setAntiquity] = useState('');
    const [employees, setEmployees] = useState('');
    const [monthlyExpenses, setMonthlyExpenses] = useState('');
    const [monthlyIncome, setMonthlyIncome] = useState('');

    //Se crea y se Inicializa un objeto para los posibles errores de Información del Negocion
    const [errorsBussinesInfo, setErrorsBussinesInfo] = useState({
        monthlyIncome: '',
        monthlyExpenses: '',
        antiquity: '',
        employeeNumber: '',
        economicActivity: ''
    });

    //Constantes de Edicion sirven para habilitar y deshabilitar partes del código
    const [isBussinesInfoEdit, setIsBussinessInfoEdit] = useState(false);
    const [showActivity, setShowActivity] = useState(false);
    const [showModal, setShowModal] = useState(false);

    //Se Definen las variables de Información del negocio
    useEffect(() => {
        if (Object.keys(bussinessInfo).length !== 0) {
            setMonthlyExpenses(bussinessInfo.monthlyExpenses || '');
            setMonthlyIncome(bussinessInfo.monthlyIncome || '');
            setEconomicActivity(bussinessInfo.economicActivity || '');
            setAntiquity(bussinessInfo.antiquity || '');
            setEmployees(bussinessInfo.employeeNumber || '');
        }
    }, [bussinessInfo]);

    //Se obtienen la I  nformación de la Actividad económica si se cumple la condicion si no se restablecen sus valores
    useEffect(() => {
        economicActivity.length >= 3 && showActivity
            ? dispatch(getEconomicActivities(economicActivity))
            : dispatch(resetEconomicActivitiesStatus());
    }, [economicActivity]);

    //Funcion para actualizar la Información de el negocio en la base de datos y manejar los posibles errores
    const saveBussinessInfo = () => {
        //constantes manejadoras de errrores
        const errorIncome = monthlyIncome !== '' ? '' : 'Este campo no puede estar vacío';
        const errorExpenses = monthlyExpenses === '' ? 'Este campo no puede estar vacío' : '';
        const errorAntiquity = antiquity === '' ? 'Este campo no puede estar vacío' : '';
        const errorEmployeeNumber = employees === '' ? 'Este campo no puede estar vacío' : '';
        const errorEconomicActivity = economicActivity === '' ? 'Este campo no puede estar vacío' : '';
        //Se asignan posibles errores en el objeto
        setErrorsBussinesInfo({
            monthlyIncome: errorIncome,
            monthlyExpenses: errorExpenses,
            antiquity: errorAntiquity,
            employeeNumber: errorEmployeeNumber,
            economicActivity: errorEconomicActivity
        });

        if (errorIncome || errorExpenses || errorAntiquity || errorEmployeeNumber || errorEconomicActivity) return;

        //Actualiza la Información del cliente si no exite ningun error
        dispatch(
            updateClient({
                id: userRef,
                monthlyIncome,
                monthlyExpenses
            })
        );

        //Actualiza la Información del negocio si no exite ningun error
        dispatch(
            postBussinessInfo({
                clientRef: userRef,
                antiquity,
                employeeNumber: employees,
                economicActivity
            })
        );

        setShowModal(!showModal);
        setIsBussinessInfoEdit(!isBussinesInfoEdit); //Oculta la opcion de edicion
    };

    return loading ? (
        <div className="auth-wrapper align-items-center">
            <Loader isOpen={true} />
        </div>
    ) : (
        <>
            <Card>
                <Card.Body className="d-flex align-items-center justify-content-between">
                    <h5 className="mb-0">Información del Negocio</h5>
                    <button
                        type="button"
                        className="btn btn-primary btn-sm btn-border-radius m-0 float-right customerProfile-editBtn"
                        onClick={() => {
                            setIsBussinessInfoEdit(!isBussinesInfoEdit);
                        }}
                    >
                        <i className={isBussinesInfoEdit ? 'feather icon-x' : 'feather icon-edit'} />
                    </button>
                </Card.Body>
                <Card.Body className={isBussinesInfoEdit ? 'border-top pro-det-edit collapse' : 'border-top pro-det-edit collapse show'}>
                    <Row>
                        <Col className="form-group">
                            <label className="font-weight-bolder mt-3">Actividad Económica: </label>
                            <p>{economicActivity}</p>
                        </Col>
                        <Col className="form-group">
                            <label className="font-weight-bolder mt-3">Antigüedad: </label>
                            <p>{antiquity}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="form-group">
                            <label className="font-weight-bolder mt-3">Número de Empleados: </label>
                            <p>{employees}</p>
                        </Col>
                        <Col className="form-group">
                            <label className="font-weight-bolder mt-3">Ingresos Mensuales: </label>
                            <p>{monthlyIncome}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="form-group">
                            <label className="font-weight-bolder mt-3">Gastos Mensuales: </label>
                            <p>{monthlyExpenses}</p>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Body className={isBussinesInfoEdit ? 'border-top pro-det-edit collapse show' : 'border-top pro-det-edit collapse'}>
                    <Row className="form-group">
                        <Col>
                            <label className="font-weight-bolder mt-3">Actividad Económica: </label>
                            <input
                                type="text"
                                className="form-control"
                                id="economic activities"
                                placeholder="Actividad Económica"
                                value={economicActivity}
                                style={{ textTransform: 'uppercase' }}
                                onChange={(e: any) => {
                                    setEconomicActivity(e.target.value);
                                    setShowActivity(true);
                                    setErrorsBussinesInfo({ ...errorsBussinesInfo, economicActivity: '' });
                                }}
                                autoComplete="off"
                            />

                            {economicActivities &&
                                showActivity &&
                                economicActivities.map((economicActivity: any, i: number) => (
                                    <>
                                        <div className="col-sm-3" />
                                        <div
                                            className="suggestion col-sm-9 justify-content-left"
                                            onClick={async (e: any) => {
                                                setEconomicActivity(economicActivity.activity);
                                                setShowActivity(false);
                                                dispatch(resetEconomicActivitiesStatus());
                                            }}
                                        >
                                            {economicActivity.activity}
                                        </div>
                                    </>
                                ))}
                            {errorsBussinesInfo.economicActivity && (
                                <p className="form-text text-left text-danger">{errorsBussinesInfo.economicActivity}</p>
                            )}
                        </Col>
                        <Col>
                            <label className="font-weight-bolder">Antigüedad:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="Antiquity"
                                placeholder="Antigüedad de la empresa"
                                value={antiquity}
                                onChange={(e: any) => {
                                    setAntiquity(e.target.value);
                                    setErrorsBussinesInfo({ ...errorsBussinesInfo, antiquity: '' });
                                }}
                            />
                            {errorsBussinesInfo.antiquity && (
                                <p className="form-text text-left text-danger">{errorsBussinesInfo.antiquity}</p>
                            )}
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Col>
                            <label className="font-weight-bolder">Número de empleados:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="employees"
                                placeholder="Número de empleados"
                                value={employees}
                                onChange={(e: any) => {
                                    setEmployees(e.target.value);
                                    setErrorsBussinesInfo({ ...errorsBussinesInfo, employeeNumber: '' });
                                }}
                            />
                            {errorsBussinesInfo.employeeNumber && (
                                <p className="form-text text-left text-danger">{errorsBussinesInfo.employeeNumber}</p>
                            )}
                        </Col>
                        <Col>
                            <label className="font-weight-bolder">Ingresos Mensuales:</label>
                            <NumberFormat
                                className="form-control"
                                id="Monthly Income"
                                placeholder="Promedio de Ingresos Mensuales"
                                value={monthlyIncome}
                                thousandSeparator
                                prefix="$ "
                                onChange={(e: any) => {
                                    setMonthlyIncome(e.target.value.trim());
                                    setErrorsBussinesInfo({ ...errorsBussinesInfo, monthlyIncome: '' });
                                }}
                            />
                            {errorsBussinesInfo.monthlyIncome && (
                                <p className="form-text text-left text-danger">{errorsBussinesInfo.monthlyIncome}</p>
                            )}
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Col>
                            <label className="font-weight-bolder">Gastos Mensuales:</label>
                            <NumberFormat
                                className="form-control"
                                id="Monthly Expenses"
                                placeholder="Promedio de Gastos Mensuales"
                                value={monthlyExpenses}
                                thousandSeparator
                                prefix="$ "
                                onChange={(e: any) => {
                                    setMonthlyExpenses(e.target.value.trim());
                                    setErrorsBussinesInfo({ ...errorsBussinesInfo, monthlyExpenses: '' });
                                }}
                            />
                            {errorsBussinesInfo.monthlyExpenses && (
                                <p className="form-text text-left text-danger">{errorsBussinesInfo.monthlyExpenses}</p>
                            )}
                        </Col>
                    </Row>
                    <Row className="form-group mt-5">
                        <Col className="d-flex justify-content-end">
                            <button
                                type="submit"
                                className="btn btn-primary customerProfile-saveBtn"
                                onClick={() => setShowModal(!showModal)}
                            >
                                Guardar
                            </button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Modal show={showModal} onHide={() => setShowModal(!showModal)}>
                <Modal.Header closeButton>
                    <Modal.Title as="h5">Actualización de información</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    La información del negocio ha sido modificada
                    <br />
                    <br />
                    ¿Deseas continuar con la actualización de la información del usuario?
                </Modal.Body>
                <Modal.Footer>
                    <Row className="mt-3 mb-3">
                        <Button className="btn-border-radius" variant="danger" onClick={() => setShowModal(!showModal)}>
                            Cancelar
                        </Button>
                        <Button className="ml-3 btn-border-radius" variant="secondary" onClick={saveBussinessInfo}>
                            Continuar
                        </Button>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default BussinessInfo;
