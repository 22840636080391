import React, { useEffect, useState, FC } from 'react';
import { useHistory } from 'react-router-dom';
import { BsDot } from 'react-icons/bs';
import { Col } from 'react-bootstrap';
import FormBuilder from '../../components/Builders/FormBuilder/FormBuilder';
import { useDispatch, useSelector } from 'react-redux';
import ScreenLoader from '../../components/ScreenLoader/ScreenLoader';
import DocumentBuilder from '../../components/Builders/FormBuilder/DocumentBuilder';
import OTPBuilder from '../../components/Builders/FormBuilder/OTPBuilder';
import { finishFlow } from '../../flux/actions/quote-actions';

var mobile = require('is-mobile');
let totalSteps = 0;

interface Props {
    onContinue: () => void | any;
    stepController: number;
}

const BuildForm: FC<Props> = (p) => {
    //Redux vars
    const history = useHistory();
    const dispatch = useDispatch();
    const [arrayBuilder, setArrayBuilder] = useState<any[]>([]);
    const client = useSelector((state: any) => state.clientReducer.client);
    const { flow, loading } = useSelector((state: any) => state.flowReducer);

    useEffect(() => {
        flow.filter((flow: any) => {
            if (flow.taxRegime === 'AM') {
                if (p.stepController >= flow.steps.length) {
                    history.push('/usuario/inicio');
                }
                if (flow.length !== 0 && flow !== undefined) {
                    if (!flow.isActive) {
                        history.push('/no-hay-flujo');
                    }
                    let auxBuilder: any = [];
                    flow.steps.map((step: any, index: number) => {
                        if (step.name === 'Autorización OTP') {
                            auxBuilder.push({
                                label: step.name,
                                icon: <BsDot key={index} />,
                                component: (
                                    <OTPBuilder
                                        description={step.description}
                                        finishFlow={() => {index == flow.steps.length - 1 && dispatch(finishFlow(client.id))}}
                                        onSaveForm={() => {index !== flow.steps.length - 1 && p.onContinue()}}
                                    />
                                )
                            });
                        } else {
                            if (step.taxRegime === client.taxRegime || step.taxRegime === 'AM') {
                                auxBuilder.push({
                                    label: step.name,
                                    icon: <BsDot key={index} />,
                                    component:
                                        step.infoRequired.findIndex((info: any) => info.model === 'documents') >= 0 ? (
                                            <DocumentBuilder
                                                key={index}
                                                infoRequired={step.infoRequired}
                                                title={step.name}
                                                description={step.description}
                                                finishFlow={() => {index == flow.steps.length - 1 && dispatch(finishFlow(client.id))}}
                                                onSaveForm={() => {index !== flow.steps.length - 1 && p.onContinue()}}
                                            />
                                        ) : (
                                            <FormBuilder
                                                key={index}
                                                infoRequired={step.infoRequired}
                                                title={step.name}
                                                description={step.description}
                                                finishFlow={() => {index == flow.steps.length - 1 && dispatch(finishFlow(client.id))}}
                                                onSaveForm={() => {index !== flow.steps.length - 1 && p.onContinue()}}
                                                confirmInfo={step.confirmInfo}
                                            />
                                        )
                                });
                            }
                        }
                    });
                    setArrayBuilder(auxBuilder);
                    return (totalSteps = flow.steps.length);
                }
            }
        });
    }, [flow, client]);

    // Progess Bar steps
    let progressStep = ((p.stepController + 1) * 100) / totalSteps;

    if (loading) {
        return (
            <div className="auth-wrapper align-items-center">
                <ScreenLoader isOpen={loading} />
            </div>
        );
    }

    return (
        <Col className="Demo" xs={12}>
            <div className="progressBar">
                <div style={{ width: `${progressStep}%` }}></div>
            </div>
            {arrayBuilder?.length !== 0 && (
                <>
                    {arrayBuilder?.length !== 0 && p.stepController >= arrayBuilder?.length
                        ? history.push('/usuario/inicio')
                        : arrayBuilder[p.stepController]?.component}
                </>
            )}
        </Col>
    );
};
export default BuildForm;
