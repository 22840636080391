import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
//import { rejectQuote, updateStatus, getCredit } from '../../../src/flux/actions/quote-actions';
import { getClient } from '../../flux/actions/client-actions';
import { Card, Col, Row, Button, Modal } from 'react-bootstrap';
import { getDocuments } from '../../flux/actions/documents-actions';
import { downloadMedia } from '../../flux/actions/nubariumApi-action';
import Loader from '../LoaderFile/Loader';
import { refreshToken } from '../../flux/actions/refreshToken-action';

const VideoRecording: React.FC = () => {
    //const to get the params
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    const clientRef = (params as any)?.id;
    const FileSaver = require('file-saver');

    const user = useSelector((state: any) => state.authReducer.user);
    const { loading, client } = useSelector((state: any) => state.clientReducer);
    const quote: any = useSelector((state: any) => state.quoteReducer.credit);
    let downloadedMedia = useSelector((state: any) => state.NubariumReferenceReducer);

    const { approvalProcess } = useSelector((state: any) => state.approvalProcessReducer);

    const [requestModal, setRequestModal] = useState(false);
    const [showApprovalModal, setShowApprovalModal] = useState(false);
    const [showCancelRequest, setShowCancelRequest] = useState(false);
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [status, setStatus] = useState('');

    let similarityValue = client?.recordMessage?.result.similarity * 100;

    const handleClickDownloadMedia = async (filePath: String, fileName: String) => {
        dispatch(
            refreshToken({
                token: localStorage.getItem('token'),
                refreshToken: localStorage.getItem('refreshToken')
            })
        );
        dispatch(
            downloadMedia({
                filePath: filePath,
                fileName: fileName
            })
        );
    };

    useEffect(() => {
        if (Object.keys(user).length === 0) {
            history.push('/login');
        }
        dispatch(getClient(clientRef));
        dispatch(getDocuments(clientRef));
    }, []);

    // useEffect(() => {
    //     setStatus(quote.status);
    //     if (Object.keys(client).length === 0) return;
    //     if (clientRef === client.id){
    //         setTimeout(() => {
    //             if ((client.recordMessage === undefined) && quote.status !== 'recordMessagePending') {
    //                 dispatch(
    //                     updateStatus({
    //                         clientRef: client.id,
    //                         status: 'recordMessagePending'
    //                     })
    //                 );
    //             }
    //         },1000);
    //     }
    // }, [quote]);

    // function ApproveRecordMessage() {
    //     let stepIndex = 0;
    //     approvalProcess.steps.filter((step: any, index: number) => {
    //         if (step.adminStatus === quote.status) {
    //             stepIndex = index + 1;
    //             if (stepIndex < approvalProcess.steps.length) {
    //                 dispatch(
    //                     updateStatus({
    //                         clientRef,
    //                         status: approvalProcess.steps[stepIndex].adminStatus
    //                     })
    //                 );
    //                 dispatch(getCredit(clientRef));
    //             }
    //         }
    //     });
    //     setShowApprovalModal(!showApprovalModal);
    // }

    // const requestTest = () => {
    //     dispatch(
    //         updateStatus({
    //             clientRef,
    //             status: 'recordMessagePending'
    //         })
    //     )
    //     setRequestModal(!requestModal);
    //     dispatch(getCredit(clientRef));
    // };

    // function cancelRequest() {
    //     dispatch(rejectQuote({ clientRef: client.id }));
    //     setShowCancelRequest(!showCancelRequest);
    //     dispatch(getCredit(clientRef));
    // }

    function downloadNubariumImage() {
        if (!downloadedMedia.successMessage?.base64) {
            return;
        }
        const byteCharacters = atob(downloadedMedia.successMessage?.base64);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: 'image/*' });
        FileSaver.saveAs(blob, downloadedMedia?.successMessage?.fileName);
    }

    function downloadNubariumVideo() {
        if (!downloadedMedia.successMessage?.base64) {
            return;
        }
        const byteCharacters = atob(downloadedMedia.successMessage?.base64);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: 'video/*' });
        FileSaver.saveAs(blob, downloadedMedia?.successMessage?.fileName);
    }

    return (
        <>
            <Card>
                {Object.keys(quote).length !== 0 && status == 'recordMessagePending' ? (
                    <Card.Header className="approvalFlow-record-header">
                        <Card.Title as="h6">El prospecto está realizando la grabación de mensaje</Card.Title>
                    </Card.Header>
                ) : (
                    <>
                        <Card.Body className="approvalFlow-record-body">
                            <Card.Title as="h5">Test grabación de mensaje</Card.Title>
                            <Card.Subtitle as="h6">Grabación de mensaje cargada por el prospecto</Card.Subtitle>
                            <p className="record-text">Es necesario validar los datos.</p>
                            {loading ? (
                                <div className="auth-wrapper align-items-center">
                                    <Loader />
                                </div>
                            ) : (
                                <>
                                    {Object.keys(client).length !== 0 && (
                                        <Row className="d-flex justify-content-around m-5">
                                            <p>
                                                Diferencias: <strong>{client?.recordMessage?.result.difference}</strong>
                                            </p>
                                            <p>
                                                Similitud: <strong>{!isNaN(similarityValue) ? Math.ceil(similarityValue) : 0}%</strong>
                                            </p>
                                            <p>
                                                Texto Grabado: <strong>{client?.recordMessage?.result.recordedText}</strong>
                                            </p>
                                        </Row>
                                    )}
                                    <Card.Subtitle as="h6">Descargar documentos generados por el prospecto</Card.Subtitle>
                                    {Object.keys(client).length !== 0 && (
                                        <Row className="d-flex justify-content-around m-5">
                                            <Col className="customerProfile-files">
                                                <button
                                                    type="button"
                                                    className="btn btn-third btn-sm btn-border-radius float-center mt-2 doc-showBtn"
                                                    onClick={() => {
                                                        if (client?.nubFaceCapture) {
                                                            handleClickDownloadMedia(
                                                                client?.nubFaceCapture,
                                                                `${client.names}_${client.lastName1}_face_capture.jpg`
                                                            );
                                                            setShowDownloadModal(!showDownloadModal);
                                                        }
                                                    }}
                                                >
                                                    <i className={'feather icon-download'} />
                                                </button>
                                                <p className="mt-3 align-middle doc-titleDocument"> Captura de Rostro</p>
                                            </Col>
                                            <Col className="customerProfile-files">
                                                <button
                                                    type="button"
                                                    className="btn btn-third btn-sm btn-border-radius float-center mt-2 doc-showBtn"
                                                    onClick={() => {
                                                        if (client?.nubIdFront) {
                                                            handleClickDownloadMedia(
                                                                client?.nubIdFront,
                                                                `${client.names}_${client.lastName1}_id_capture_front.jpg`
                                                            );
                                                            setShowDownloadModal(!showDownloadModal);
                                                        }
                                                    }}
                                                >
                                                    <i className={'feather icon-download'} />
                                                </button>
                                                <p className="mt-3 align-middle doc-titleDocument"> Frente de INE</p>
                                            </Col>
                                            <Col className="customerProfile-files">
                                                <button
                                                    type="button"
                                                    className="btn btn-third btn-sm btn-border-radius float-center mt-2 doc-showBtn"
                                                    onClick={() => {
                                                        if (client?.nubIdBack) {
                                                            handleClickDownloadMedia(
                                                                client?.nubIdBack,
                                                                `${client.names}_${client.lastName1}_id_capture_back.jpg`
                                                            );
                                                            setShowDownloadModal(!showDownloadModal);
                                                        }
                                                    }}
                                                >
                                                    <i className={'feather icon-download'} />
                                                </button>
                                                <p className="mt-3 align-middle doc-titleDocument"> Reverso de INE</p>
                                            </Col>
                                            <Col className="customerProfile-files">
                                                <button
                                                    type="button"
                                                    className="btn btn-third btn-sm btn-border-radius float-center mt-2 doc-showBtn"
                                                    onClick={() => {
                                                        if (client?.nubVideoSpeech) {
                                                            handleClickDownloadMedia(
                                                                client?.nubVideoSpeech,
                                                                `${client.names}_${client.lastName1}_video_speech.mp4`
                                                            );
                                                            setShowDownloadModal(!showDownloadModal);
                                                        }
                                                    }}
                                                >
                                                    <i className={'feather icon-download'} />
                                                </button>
                                                <p className="mt-3 align-middle doc-titleDocument"> Grabación de Mensaje</p>
                                            </Col>
                                            <Col className="customerProfile-files">
                                                <button
                                                    type="button"
                                                    className="btn btn-third btn-sm btn-border-radius float-center mt-2 doc-showBtn"
                                                    onClick={() => {
                                                        if (client?.nubVideoFull) {
                                                            handleClickDownloadMedia(
                                                                client?.nubVideoFull,
                                                                `${client.names}_${client.lastName1}_video_full.mp4`
                                                            );
                                                            setShowDownloadModal(!showDownloadModal);
                                                        }
                                                    }}
                                                >
                                                    <i className={'feather icon-download'} />
                                                </button>
                                                <p className="mt-3 align-middle doc-titleDocument"> Grabación completa</p>
                                            </Col>
                                        </Row>
                                    )}
                                </>
                            )}
                        </Card.Body>
                        {/* <Card.Footer className='approvalFlow-record-footer'>
                            <Card.Title as="h5">¿Solicitar al prospecto realizar nuevamente la grabación de mensaje?</Card.Title>
                            <Button className="btn-border-radius record-successBtn" variant={'success'} onClick={() => setRequestModal(!requestModal)}>
                                Solicitar
                            </Button>
                        </Card.Footer> */}

                        {/* <Card.Footer className='approvalFlow-record-footer'>
                            <Card.Title as="h5">¿El resultado de la prueba grabación de mensaje es adecuada para continuar?</Card.Title>
                            <Row className="mt-3 ml-3">
                                <Button
                                    className="btn-border-radius record-successBtn"
                                    variant={'success'}
                                    onClick={() => setShowApprovalModal(!showApprovalModal)}
                                >
                                    Si
                                </Button>
                                <Button
                                    variant={'danger'}
                                    className="ml-3 btn-border-radius record-alertBtn"
                                    onClick={() => {
                                        setShowCancelRequest(!showCancelRequest);
                                    }}
                                >
                                    No
                                </Button>
                            </Row>
                        </Card.Footer> */}
                    </>
                )}
            </Card>
            {/* <Modal show={requestModal} onHide={() => setRequestModal(!requestModal)}>
                <Modal.Header className='approvalFlow-recordModal-header' closeButton>
                    <Modal.Title as="h5">Grabación de Mensaje</Modal.Title>
                </Modal.Header>
                <Modal.Body className='approvalFlow-recordModal-body'>
                    <p>La información del test grabación de mensaje se borrará permanentemente del sistema<br /><br />¿Deseas continuar?</p>
                </Modal.Body>
                <Modal.Footer className='approvalFlow-record-footer'>
                    <Row className="mt-3 mb-3">
                        <Button className="btn-border-radius record-alertBtn" variant="danger" onClick={() => setRequestModal(!requestModal)}>
                            Cancelar
                        </Button>
                        <Button className="ml-3 btn-border-radius record-successBtn" variant="secondary" onClick={requestTest}>
                            Solicitar grabación de mensaje
                        </Button>
                    </Row>
                </Modal.Footer>
            </Modal>
            <Modal show={showApprovalModal} onHide={() => setShowApprovalModal(!showApprovalModal)}>
                <Modal.Header className='approvalFlow-recordModal-header' closeButton>
                    <Modal.Title as="h5">Grabación de mensaje</Modal.Title>
                </Modal.Header>
                <Modal.Body className='approvalFlow-recordModal-body'>
                    <p>El Test de grabación de mensaje del prospecto es adecuado para CONTINUAR con el proceso del crédito<br /><br />¿Deseas continuar?</p>
                </Modal.Body>
                <Modal.Footer className='approvalFlow-record-footer'>
                    <Row className="mt-3 mb-3">
                        <Button className="btn-border-radius record-alertBtn" variant="danger" onClick={() => setShowApprovalModal(!showApprovalModal)}>
                            Cancelar
                        </Button>
                        <Button className="ml-3 btn-border-radius record-successBtn" variant="success" onClick={ApproveRecordMessage}>
                            Aprobar
                        </Button>
                    </Row>
                </Modal.Footer>
            </Modal>
            <Modal show={showCancelRequest} onHide={() => setShowCancelRequest(!showCancelRequest)}>
                <Modal.Header className='approvalFlow-recordModal-header' closeButton>
                    <Modal.Title as="h5">
                        El test grabación de mensaje NO cumple con la información para la solicitud del crédito
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='approvalFlow-recordModal-body'>
                    <p>La grabación de mensaje proveída por el prospecto no cumple con los requisitos para ser candidato a un crédito. Esta cotización quedará CANCELADA<br /><br />¿Deseas continuar?</p>
                </Modal.Body>
                <Modal.Footer className='approvalFlow-record-footer'>
                    <Row className="mt-3 mb-3">
                        <Button className="btn-border-radius record-alertBtn" variant="danger" onClick={() => setShowCancelRequest(!showCancelRequest)}>
                            Cancelar
                        </Button>
                        <Button className="ml-3 btn-border-radius record-successBtn" variant="success" onClick={cancelRequest}>
                            Aceptar
                        </Button>
                    </Row>
                </Modal.Footer>
            </Modal> */}
            <Modal show={showDownloadModal} onHide={() => setShowDownloadModal(!showDownloadModal)}>
                <Modal.Header className="approvalFlow-recordModal-header" closeButton>
                    <Modal.Title as="h5">Descarga de información generada por Nubarium</Modal.Title>
                </Modal.Header>
                <Modal.Body className="approvalFlow-recordModal-body">
                    <p>
                        Este documento se descargará en su equipo
                        <br />
                        <br />
                        ¿Deseas continuar?
                    </p>
                </Modal.Body>
                <Modal.Footer className="approvalFlow-record-footer">
                    <Row className="mt-3 mb-3">
                        <Button
                            className="btn-border-radius record-alertBtn"
                            variant="danger"
                            onClick={() => setShowDownloadModal(!showDownloadModal)}
                        >
                            Cancelar
                        </Button>
                        <Button className="ml-3 btn-border-radius record-successBtn" variant="success" onClick={downloadNubariumImage}>
                            Descargar
                        </Button>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default VideoRecording;
