import React, { FC  } from 'react';
import imgSlider1 from '../assets/images/slider/slide1.jpg';
import imgSlider2 from '../assets/images/slider/slide2.jpg';
import imgSlider3 from '../assets/images/slider/slide3.jpg';
import imgSlider4 from '../assets/images/slider/slide4.jpg';
import { Carousel } from 'react-bootstrap';

const Slider: FC = () => {
    return (
        <>
            <Carousel>
                <Carousel.Item>
                    <img className="slider-img" src={imgSlider1} alt="Slide1" />
                </Carousel.Item>
                <Carousel.Item>
                    <img className="slider-img" src={imgSlider2} alt="Slide2" />
                </Carousel.Item>
                <Carousel.Item>
                    <img className="slider-img" src={imgSlider3} alt="Slide3" />
                </Carousel.Item>
                <Carousel.Item>
                    <img className="slider-img" src={imgSlider4} alt="Slide4" />
                </Carousel.Item>
            </Carousel>
        </>
    )
}

export default Slider;