import React, { FC, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import '../../../assets/scss/style.scss';
import { Col, Modal, Card, Alert, Row, Table } from 'react-bootstrap';
import { getCreditProposal, updateCreditProposal } from './../../../flux/actions/creditProposal-action';
import AmortizacionTable from '../../../components/Amortization-table';
import { refreshToken } from '../../../flux/actions/refreshToken-action';
import { sendAuthMail } from '../../../flux/actions/mail-actions';
import { getCredit, updateStatus } from '../../../flux/actions/quote-actions';
import { useTimer } from 'use-timer';
import moment from 'moment';
import { TextField, Button, OutlinedInput, InputLabel, MenuItem, FormControl, ListItemText, Select, Checkbox } from '@mui/material';
const cryptoJS = require('crypto-js');

const ConfirmationCreditProposal = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const creditProposal = useSelector((state: any) => state.creditProposalReducer.creditProposal);
    const quote: any = useSelector((state: any) => state.quoteReducer);
    const { client } = useSelector((state: any) => state.clientReducer);

    const [errors, setErrors] = useState({ lifeTime: '', NIP: '' });
    const [resendNIP, setResendNIP] = useState(false);
    const [OTP, setOTP] = useState('');
    const [NIP, setNIP] = useState('');
    const [disabledButton, setDisabledButton] = useState(true);
    const [disabledApprovalButton, setDisabledApprovalButton] = useState(true);
    const [showModalCreditProposal, setShowModalCreditProposal] = useState(false);
    const [showModalRejecCreditProposal, setShowModalRejecCreditProposal] = useState(false);
    const [reasonInput, setreasonInput] = useState<string[]>([]);
    const [otherReasons, setOtherReasons] = useState('');

    const reasons: any = [
        'La cantidad propuesta es insuficiente',
        'La cantidad propuesta es demasiado elevada',
        'El numero de periodos de pago es muy corto',
        'El numero de periodos de pago es muy largo',
        'El pago mensual es muy alto',
        'El pago mensual es muy bajo'
    ];

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250
            }
        }
    };

    useEffect(() => {
        if (Object.keys(client).length === 0) {
            history.push('/login');
        }
        dispatch(getCreditProposal(client.id));
        generateOTP();
    }, []);

    useEffect(() => {
        reasonInput.length > 0 || otherReasons.length > 0 ? setDisabledApprovalButton(false) : setDisabledApprovalButton(true);
    }, [reasonInput, otherReasons]);

    useEffect(() => {
        NIP.length === 6 && pinVerification();
    }, [NIP]);

    const { time, start, pause, reset, status } = useTimer({
        initialTime: 86400,
        autostart: false,
        endTime: 0,
        onTimeOver: () => {
            setResendNIP(true);
        },
        timerType: 'DECREMENTAL'
    });

    // Funcion para generar una contraseña aleatoria temporal, esta funcion generra una contraseña de 6 digitos numericos
    // ademas de enviar un correo electronico con dicha contraseña
    const generateOTP = () => {
        var digits = '0123456789';
        let OneTimePassword = '';

        start();
        for (let i = 0; i < 6; i++) {
            OneTimePassword += digits[Math.floor(Math.random() * 10)];
        }

        let cipherOTP = cryptoJS.AES.encrypt(OneTimePassword, '9xNPoxyu').toString();

        setOTP(OneTimePassword);
        dispatch(
            sendAuthMail({
                mailType: 5,
                email: client.email,
                name: client.names,
                lastName1: client.lastName1,
                lastName2: client.lastName2,
                nip: cipherOTP
            })
        );
    };

    const resendOTP = async () => {
        var digits = '0123456789';
        let OneTimePassword = '';
        for (let i = 0; i < 6; i++) {
            OneTimePassword += digits[Math.floor(Math.random() * 10)];
        }

        let cipherOTP = cryptoJS.AES.encrypt(OneTimePassword, '9xNPoxyu').toString();
        reset();
        setResendNIP(false);
        setOTP(OneTimePassword);
        setNIP('');
        start();
        dispatch(
            sendAuthMail({
                mailType: 5,
                email: client.email,
                name: client.names,
                lastName1: client.lastName1,
                lastName2: client.lastName2,
                nip: cipherOTP
            })
        );
    };

    const pinVerification = () => {
        const NipError = NIP === OTP ? '' : 'El NIP no coincide';
        const lifeTimeError = !resendNIP ? '' : 'El NIP caducó';
        setErrors({ ...errors, NIP: NipError, lifeTime: lifeTimeError });
        if (NipError || lifeTimeError) return;
        setDisabledButton(false);
    };

    const calculateQuote = () => {
        const taxes = creditProposal?.interest;
        const Ci = creditProposal?.amount * taxes;
        const exponential = 1 - Math.pow(1 + taxes, -creditProposal?.months);
        return Ci / exponential;
    };

    const handleChange = (event: any) => {
        const {
            target: { value }
        } = event;
        setreasonInput(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
    };

    const rejectReasons = () => {
        dispatch(
            updateCreditProposal({
                clientRef: client.id,
                reasons: { reasonInput, otherReasons }
            })
        );
        dispatch(
            updateStatus({
                clientRef: client.id,
                type: 'updateBothStates',
                status: 'completionOfTheApprovalFlow',
                adminStatus: 'rejected'
            })
        );
        setTimeout(() => {
            dispatch(getCredit(client.id));
        }, 800);
        setShowModalRejecCreditProposal(!showModalRejecCreditProposal);
    };

    return (
        <Card>
            <div className="signup-content">
                <Card.Title className="record-title">PROPUESTA DE CRÉDITO</Card.Title>
                <Card.Subtitle className="record-subtitle pl-5 pr-5">
                    <h5 className="record-subtitle">
                        Estimad@
                        <strong className="signup-textButton ml-2">
                            {client.names} {client.lastName1} {client.lastName2}
                        </strong>
                        , hemos revisado su solicitud de crédito, pero creemos que esta opción es más atractiva para usted. Consideramos que
                        esta es una alternativa que beneficiará a ambas partes y esperamos su respuesta para proceder con los trámites
                        necesarios.
                    </h5>
                </Card.Subtitle>
                <Card className="mt-3 text-center" style={{ width: '98%' }}>
                    <Card.Body className="border border-black">
                        <Row>
                            <Col className="from-group">
                                <label className="font-weight-bolder">
                                    <h4>Cantidad:</h4>
                                </label>
                                <h4 className="mt-3 record-title text-opacity">
                                    {new Intl.NumberFormat('es-MX', {
                                        style: 'currency',
                                        currency: 'MXN',
                                        minimumFractionDigits: 2
                                    }).format(creditProposal?.amount)}
                                </h4>
                            </Col>
                            <Col className="from-group">
                                <label className="font-weight-bolder">
                                    <h4>Plazo a pagar:</h4>
                                </label>
                                <h4 className="mt-3 record-title text-opacity">{creditProposal?.months} meses</h4>
                            </Col>
                            <Col className="from-group">
                                <label className="font-weight-bolder">
                                    <h4>Pago mensual:</h4>
                                </label>
                                <h4 className="mt-3 record-title text-opacity">
                                    {new Intl.NumberFormat('es-MX', {
                                        style: 'currency',
                                        currency: 'MXN',
                                        minimumFractionDigits: 2
                                    }).format(calculateQuote())}
                                </h4>
                            </Col>
                            <Col className="from-group">
                                <label className="font-weight-bolder">
                                    <h4>Fecha de creación:</h4>
                                </label>
                                <h4 className="mt-3 record-title text-opacity">{moment(creditProposal?.createdAt).format('L')}</h4>
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Body className="approvalFlow-offer-body">
                        {creditProposal !== undefined && (
                            <>
                                <Card.Title as="h5">Tabla de Amortizacion</Card.Title>
                                <Table striped responsive hover>
                                    <thead className="text-center">
                                        <tr>
                                            <th key={1} className="text-white">
                                                PERIODO
                                            </th>
                                            <th key={2} className="text-white">
                                                VENCIMIENTO
                                            </th>
                                            <th key={3} className="text-white">
                                                CUOTA
                                            </th>
                                            <th key={4} className="text-white">
                                                SALDO INICIAL
                                            </th>
                                            <th key={5} className="text-white">
                                                INTERES
                                            </th>
                                            <th key={6} className="text-white">
                                                CAPITAL
                                            </th>
                                            <th className="text-white">SALDO FINAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {AmortizacionTable(creditProposal?.months, creditProposal?.amount, creditProposal?.interest)}
                                    </tbody>
                                </Table>
                            </>
                        )}
                        <div className="signup-form">
                            <div className="form-group" style={{ width: '20%' }}>
                                <Row className="d-flex flex-row mt-3 mb-4 align-items-center justify-content-center">
                                    <h5>
                                        Se envió un NIP a <strong className="signup-textButton ml-2">{client.email}</strong>.
                                    </h5>
                                    <dt className="signup-textButton m-2" onClick={resendOTP}>
                                        Solicitar un nuevo NIP
                                    </dt>
                                </Row>
                            </div>
                        </div>
                        <Card.Footer className="approvalFlow-offer-footer">
                            <Card.Title as="h5" className="text-left">
                                Ingrese el Nip para Aprobar la propuesta de crédito o da clic en rechazar para rechazar la propuesta de
                                crédito.
                            </Card.Title>
                            <Row className="mt-3 ml-1">
                                <div>
                                    <TextField
                                        type="number"
                                        placeholder="NIP"
                                        size="small"
                                        label="NIP"
                                        color="primary"
                                        value={NIP.toString()}
                                        onChange={(e: any) => {
                                            NIP.length < 6 ? setNIP(e.target.value.trim()) : setNIP('');
                                            setErrors({ ...errors, NIP: '', lifeTime: '' });
                                        }}
                                    />
                                    <Button
                                        className="offer-successBtn"
                                        variant="contained"
                                        disabled={disabledButton}
                                        onClick={() => {
                                            setShowModalCreditProposal(!showModalCreditProposal);
                                        }}
                                    >
                                        Aceptar propuesta de crédito
                                    </Button>
                                    {errors.NIP && (
                                        <p id="passwordHelpBlock" className="form-text text-left text-danger">
                                            {errors.NIP}
                                        </p>
                                    )}
                                    {errors.lifeTime && (
                                        <p id="passwordHelpBlock" className="form-text text-left text-danger">
                                            {errors.lifeTime}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <Button
                                        variant="contained"
                                        className="btn-border-radius offer-alertBtn ml-4"
                                        onClick={() => {
                                            setShowModalRejecCreditProposal(!showModalRejecCreditProposal);
                                        }}
                                    >
                                        Rechazar propuesta de crédito
                                    </Button>
                                </div>
                            </Row>
                            {quote.error && (
                                <Alert className="m-2 d-flex justify-content-between" style={{ position: 'relative' }} variant={'warning'}>
                                    <strong className="m-auto">Error! {quote.errorMessage}</strong>
                                </Alert>
                            )}
                        </Card.Footer>
                    </Card.Body>
                </Card>
                <Modal show={showModalCreditProposal} onHide={() => setShowModalCreditProposal(!showModalCreditProposal)}>
                    <Modal.Header closeButton>
                        <Modal.Title as="h5">Confirmar propuesta de crédito.</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Se aceptará la propuesta de crédito.
                        <br />
                        <br />
                        ¿Deseas continuar?
                    </Modal.Body>
                    <Modal.Footer>
                        <Row className="mt-3 mb-3">
                            <Button
                                className="btn-border-radius"
                                variant="contained"
                                color="error"
                                onClick={() => {
                                    setShowModalCreditProposal(!showModalCreditProposal);
                                }}
                            >
                                Cancelar
                            </Button>
                            <Button
                                className="ml-3 btn-border-radius"
                                variant="contained"
                                onClick={() => {
                                    dispatch(
                                        updateStatus({
                                            clientRef: client.id,
                                            type: 'updateBothStates',
                                            status: 'completionOfTheApprovalFlow',
                                            adminStatus: 'creditProposalPendingApproval'
                                        })
                                    );
                                    setTimeout(() => {
                                        dispatch(getCredit(client.id));
                                    }, 800);
                                    setShowModalCreditProposal(!showModalCreditProposal);
                                }}
                            >
                                Aceptar
                            </Button>
                        </Row>
                    </Modal.Footer>
                </Modal>
                <Modal
                    size={'lg'}
                    show={showModalRejecCreditProposal}
                    onHide={() => setShowModalRejecCreditProposal(!showModalRejecCreditProposal)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title as="h5">Rechazar propuesta de crédito.</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        ¿Cuál es el motivo por el cual no estás de acuerdo con la propuesta de crédito?
                        <br />
                        <div>
                            <FormControl sx={{ mt: 1, width: '100%' }}>
                                <InputLabel id="demo-multiple-checkbox-label">Motivos:</InputLabel>
                                <Select
                                    labelId="multiple-checkbox-label"
                                    id="multiple-checkbox"
                                    multiple
                                    value={reasonInput}
                                    onChange={handleChange}
                                    input={<OutlinedInput label="Razones:" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {reasons.map((reason: any) => (
                                        <MenuItem key={reason} value={reason}>
                                            <Checkbox checked={reasonInput.indexOf(reason) > -1} />
                                            <ListItemText primary={reason} />
                                        </MenuItem>
                                    ))}
                                </Select>
                                <br />
                                <label className="form-label">Otro Motivo</label>
                                <div className="form-outline">
                                    <textarea
                                        className="form-control border border-dark"
                                        placeholder="Motivo"
                                        id="textAreaExample1"
                                        rows={4}
                                        value={otherReasons}
                                        onChange={(e) => {
                                            setOtherReasons(e.target.value);
                                        }}
                                    ></textarea>
                                </div>
                            </FormControl>
                        </div>
                        <br />
                        ¿Deseas continuar?
                    </Modal.Body>
                    <Modal.Footer>
                        <Row className="mt-3 mb-3">
                            <Button
                                className="btn-border-radius"
                                variant="contained"
                                color="error"
                                onClick={() => setShowModalRejecCreditProposal(!showModalRejecCreditProposal)}
                            >
                                Cancelar
                            </Button>
                            <Button
                                className="ml-3 btn-border-radius"
                                variant="contained"
                                disabled={disabledApprovalButton}
                                onClick={rejectReasons}
                            >
                                Aceptar
                            </Button>
                        </Row>
                    </Modal.Footer>
                </Modal>
            </div>
        </Card>
    );
};
export default ConfirmationCreditProposal;
