import { Reducer } from 'redux';
import { AffiliateAction } from '../types/affiliate-types'
import AFFILIATE_CONSTANTS from '../constants/affiliate-constants';

export interface AffiliateReducerState {
    backTo?: string
    errorMessage: string 
    errorStatus?: number
    error: boolean
    affiliate: {},
    success: boolean,
    successMessage: string,
}

const initialState: AffiliateReducerState = {
    backTo: undefined,
    errorMessage: "",
    errorStatus: 0,
    error: false,
    affiliate: {},
    success: false,
    successMessage: "",
};


export const affiliateReducer: Reducer<AffiliateReducerState, AffiliateAction> = (state = initialState, action: AffiliateAction) => {

    switch (action.type) {
        //------------------ POST AFFILIATE INFO -----------------------------
        case AFFILIATE_CONSTANTS.POST_AFFILIATE_BEGIN:
            return {
                ...state,
                errorMessage: "",
                loading: true,
                error: false,
                errorStatus: 0
            }

        case AFFILIATE_CONSTANTS.POST_AFFILIATE_ERROR:
            return {
                ...state,
                errorMessage: action.error.data.errorMessage,
                errorStatus: action.error ? action.error.status : 0,
                error: true,
                loading: false
            }

        case AFFILIATE_CONSTANTS.POST_AFFILIATE_SUCCESS:
            return {
                ...state,
                errorMessage: "",
                error: false,
                successMessage: action.data.message,
                loading: false,
                affiliate: action.data.affiliate || action.data ,
                errorStatus: 0,
            }
        //------------------ GET AFFILIATE INFORMATION -----------------------------
        case AFFILIATE_CONSTANTS.GET_AFFILIATE_BEGIN:
            return {
                ...state,
                errorMessage: "",
                loading: true,
                error: false,
                errorStatus: 0,
                curpMessage: ""
            }

        case AFFILIATE_CONSTANTS.GET_AFFILIATE_ERROR:
            return {
                ...state,
                //errorMessage: action.error.data.errorMessage,
                //errorStatus: action.error.status,
                error: true,
                loading: false
            }

        case AFFILIATE_CONSTANTS.GET_AFFILIATE_SUCCESS:
            return {
                ...state,
                errorMessage: "",
                error: false,
                loading: false,
                affiliate: action.data,
                errorStatus: 0,
                successMessage:'',
                success: true
            }
        //------------------ RESET STATUS OF THE AFFILIATE -----------------------------
        case AFFILIATE_CONSTANTS.RESET_AFFILIATE_STATUS:
            return {
                ...state,
                loading: false,
                errorMessage: '',
                errorStatus: 0,
                success: false,
                affiliate: {} ,
                successMessage: ''
            }

        default:
            return state
    }
}
