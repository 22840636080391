import { API } from '../../flux/api/mail-api';
import { email } from '../../helpers/validations';
import DateCustomPicker from '../../components/DatePicker';

export async function sendEmailRequest(email: string, OTP: string, name: string, lastName1: string, lastName2: string) {
    const datos = {
        mailType: 0,
        email: email,
        nip: OTP,
        name: name,
        lastName1: lastName1,
        lastName2: lastName2
    };

    API.sendMail(datos);
}

export async function sendRenewPasswordEmail(email: string, OTP: string) {
    const datos = {
        mailType: 1,
        email: email,
        nip: OTP,
        name: '',
        lastName1: '',
        lastName2: ''
    };

    API.sendMail(datos);
}

export async function sendEmail(email: string, OTP: string, name: string, lastName1: string, lastName2: string) {
    const datos = {
        mailType: 0,
        email: email,
        nip: OTP,
        name: name,
        lastName1: lastName1,
        lastName2: lastName2
    };

    API.sendAuthMail(datos);
}

export async function sendEmailApprovedDocuments(email: string, name: string, lastName1: string, lastName2: string) {
    const datos = {
        mailType: 1,
        email: email,
        name: name,
        lastName1: lastName1,
        lastName2: lastName2
    };

    API.sendAuthMail(datos);
}

export async function sendEmailRequestDocuments(email: string, name: string, lastName1: string, lastName2: string) {
    const datos = {
        mailType: 2,
        email: email,
        name: name,
        lastName1: lastName1,
        lastName2: lastName2
    };

    API.sendAuthMail(datos);
}

export async function sendEmailScheduledVisit(
    email: string,
    name: string,
    lastName1: string,
    lastName2: string,
    scheduledUser: string,
    scheduledDate: string
) {
    const datos = {
        mailType: 7,
        email: email,
        name: name,
        lastName1: lastName1,
        lastName2: lastName2,
        scheduledUser: scheduledUser,
        scheduledDate: scheduledDate
    };
    API.sendAuthMail(datos);
}
