import { Reducer } from 'redux';
import { ApprovalProcessAction } from '../types/approvalProcess-types'
import APPROVAL_PROCESS_CONSTANTS from '../constants/approvalProcess-constants';

export interface BussinessReducerState {
    errorMessage: string 
    errorStatus?: number
    error: boolean
    approvalProcess: any,
    success: boolean,
    successMessage: string,
}

const initialState: BussinessReducerState = {
    errorMessage: "",
    errorStatus: 0,
    error: false,
    approvalProcess: {} ,
    success: false,
    successMessage: "",
};

export const approvalProcessReducer: Reducer<BussinessReducerState, ApprovalProcessAction> = (state = initialState, action: ApprovalProcessAction) => {

    switch (action.type) {
        //------------------ POST APPROVAL PROCESS CONFIGURATION-----------------------------
        case APPROVAL_PROCESS_CONSTANTS.POST_APPROVAL_PROCESS_BEGIN:
            return {
                ...state,
                errorMessage: "",
                loading: true,
                error: false,
                errorStatus: 0
            }

        case APPROVAL_PROCESS_CONSTANTS.POST_APPROVAL_PROCESS_ERROR:

            return {
                ...state,
                errorMessage: action.error.data.errorMessage,
                errorStatus: action.error ? action.error.status : 0,
                error: true,
                loading: false
            }

        case APPROVAL_PROCESS_CONSTANTS.POST_APPROVAL_PROCESS_SUCCESS:
            return {
                ...state,
                errorMessage: "",
                error: false,
                successMessage: action.data.message,
                loading: false,
                approvalProcess: action.data.approvalProcess,
                errorStatus: 0,
            }
        //------------------ GET APPROVAL PROCESS CONFIGURATION -----------------------------
        case APPROVAL_PROCESS_CONSTANTS.GET_APPROVAL_PROCESS_BEGIN:
            return {
                ...state,
                errorMessage: "",
                loading: true,
                error: false,
                errorStatus: 0,
                curpMessage: ""
            }

        case APPROVAL_PROCESS_CONSTANTS.GET_APPROVAL_PROCESS_ERROR:
            return {
                ...state,
                errorMessage: action.error.data.errorMessage,
                errorStatus: action.error.status,
                error: true,
                loading: false
            }

        case APPROVAL_PROCESS_CONSTANTS.GET_APPROVAL_PROCESS_SUCCESS:
            return {
                ...state,
                errorMessage: "",
                error: false,
                loading: false,
                approvalProcess: action.data.approvalProcess,
                errorStatus: 0,
                successMessage:action.data.message,
                success: true
            }

        case APPROVAL_PROCESS_CONSTANTS.RESET_APPROVAL_PROCESS_STATUS:
            return {
                ...state,
                loading: false,
                errorMessage: '',
                errorStatus: 0,
                success: false,
                approvalProcess: [],
                successMessage: ''
            }

        default:
            return state
    }
}
