import { Card, Modal, Alert, Row, Table, Button, Col } from 'react-bootstrap';
import React from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCredit, rejectQuote, updateStatus } from '../../../../flux/actions/quote-actions';
import { getOtp } from '../../../../flux/actions/otp-actions';
import { getRccFicoScore, getRccPm } from '../../../../flux/actions/circleCredit-actions';
import { updateClient } from '../../../../flux/actions/client-actions';
import { getApprovalProcess } from '../../../../flux/actions/approvalProcess-actions';
import { sendAuthMail } from '../../../../flux/actions/mail-actions';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import GaugeChart from 'react-gauge-chart';
import { estadoMapping } from '../../../../helpers/statesCatalog';
import { validate } from '../../../../helpers/validations';

const FicoScoreCreditReport: React.FC = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const clientRef = (params as any)?.id;
    const { creditReport } = useSelector((state: any) => state.circleCreditReducer);
    const { approvalProcess } = useSelector((state: any) => state.approvalProcessReducer);
    const client = useSelector((state: any) => state.clientReducer.client);
    const quote: any = useSelector((state: any) => state.quoteReducer.credit);
    const otp = useSelector((state: any) => state.otpReducer.OTP);
    const [temporality, setTemporality] = useState(false);
    const count = useRef(0);

    const [showApprovalModal, setShowApprovalModal] = useState(false);
    const [showCancelRequest, setShowCancelRequest] = useState(false);
    const [values, setValues] = useState('');

    useEffect(() => {
        dispatch(getApprovalProcess(clientRef));
        dispatch(getOtp(clientRef));
    }, []);

    function ApproveFicoScoreCreditReport() {
        let stepIndex = 0;
        const approvalProcessClient = approvalProcess.steps?.filter((step: any) => {
            return step.clientStatus !== 'generateCreditCircleNIP';
        });
        approvalProcess.steps.filter((step: any, index: number) => {
            if (step.adminStatus === quote.adminStatus) {
                stepIndex = index + 1;
                if (stepIndex < approvalProcess.steps.length) {
                    if (approvalProcess.steps[index].continuousApprovalFlow) {
                        dispatch(
                            updateStatus({
                                clientRef,
                                type: 'adminStatus',
                                status: approvalProcess.steps[stepIndex].adminStatus
                            })
                        );
                    } else {
                        dispatch(
                            updateStatus({
                                clientRef,
                                type: 'updateBothStates',
                                status: approvalProcessClient[stepIndex].clientStatus,
                                adminStatus: approvalProcess.steps[stepIndex].adminStatus
                            })
                        );
                    }
                } else if (stepIndex === approvalProcess.steps.length) {
                    dispatch(
                        updateStatus({
                            clientRef,
                            type: 'adminStatus',
                            status: 'creditProposal'
                        })
                    );
                }
            }
        });
        setTimeout(() => {
            dispatch(getCredit(clientRef));
        }, 1000);
        setShowApprovalModal(!showApprovalModal);
    }

    function cancelRequest() {
        dispatch(rejectQuote({ client: client.id }));
        setShowCancelRequest(!showCancelRequest);
        dispatch(getCredit(clientRef));
        dispatch(
            sendAuthMail({
                mailType: 3,
                email: client.email,
                name: client.names,
                lastName1: client.lastName1,
                lastName2: client.lastName2,
                folio: quote.folio,
                amount: quote.amount
            })
        );
    }

    const ConsultCreditCircleTest = () => {
        return (
            <Card className="mt-3">
                <Button
                    className="btn-border-radius moral-successBtn"
                    variant="secondary"
                    onClick={() => {
                        dispatch(
                            //DUMMY TEST
                            getRccFicoScore({
                                clientRef,
                                apellidoPaterno: 'SESENTAYDOS',
                                apellidoMaterno: 'PRUEBA',
                                primerNombre: 'JUAN',
                                fechaNacimiento: '1965-08-19',
                                rfc: 'SEPJ650809JG1',
                                nacionalidad: 'MX',
                                direccion: 'PASADISO ENCONTRADO 58',
                                coloniaPoblacion: 'MONTEVIDEO',
                                delegacionMunicipio: 'GUSTAVO A MADERO',
                                ciudad: 'CIUDAD DE MÉXICO',
                                estado: 'CDMX',
                                cp: '07730'
                            })
                        );
                        setTemporality(!temporality);
                    }}
                >
                    Consulta a Círculo de Crédito
                </Button>
            </Card>
        );
    };

    const ConsultCreditCircle = () => {
        const direccion = client?.street + ' ' + client?.numExt;
        let country: string = client?.countryBirth === 'México' ? 'MX' : '';
        let fechaNac = client?.birthDate;
        let state = estadoMapping[client?.state];
        let undefinedValues = '';

        const date = new Date(fechaNac);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        // Formatear la fecha
        const formattedFechaNac = `${year}-${month}-${day}`;
        //Verifica que esté completa la información
        let handlePayloadError: any = {
            NIP: otp?.NIP,
            Pais: country,
            Estado: state,
            Direccion: direccion,
            'Primer apellido': client?.lastName1,
            'Segundo apellido': client?.lastName2,
            Nombre: client?.names,
            'Fecha de Nacimiento': formattedFechaNac,
            RFC: client?.RFC,
            Colonia: client?.suburb,
            Muncipio: client?.municipality,
            Ciudad: client?.city,
            'Codigo Postal': client?.zipCode
        };

        function validatePayload() {
            for (const value in handlePayloadError) {
                if (!handlePayloadError[value]) {
                    undefinedValues += `${value},  `;
                }
            }
            return undefinedValues;
        }

        setValues(validatePayload());
        if (undefinedValues !== '') {
            return (
                <Card className="mt-3">
                    <Row>
                        <Col>
                            <p>
                                <strong>
                                    NIP: <br />
                                    <span className="moral-info">{otp?.NIP}</span>
                                </strong>
                            </p>
                        </Col>
                        <Col>
                            <p>
                                <strong>
                                    Fecha de Creación de NIP: (GMT-0600 hora estándar central)
                                    <br />
                                    <span className="moral-info">
                                        {new Date(otp?.createdAt).toLocaleString('es-MX', { timeZone: 'America/Mexico_City' })}
                                    </span>
                                </strong>
                            </p>
                        </Col>
                    </Row>
                    <Button className="btn-border-radius moral-successBtn" variant="danger">
                        No es posible realizar la Consulta a Círculo de Crédito
                    </Button>
                    {values !== '' && (
                        <Alert variant="danger" className="text-center ml-3 mr-3 text-dark" style={{ fontSize: 16 }}>
                            Favor de revisar los siguientes datos: {values}
                        </Alert>
                    )}
                </Card>
            );
        }
        return (
            <Card className="mt-3">
                <Row>
                        <Col>
                            <p>
                                <strong>
                                    NIP: <br />
                                    <span className="moral-info">{otp?.NIP}</span>
                                </strong>
                            </p>
                        </Col>
                        <Col>
                            <p>
                                <strong>
                                    Fecha de Creación de NIP: (GMT-0600 hora estándar central)
                                    <br />
                                    <span className="moral-info">
                                        {new Date(otp?.createdAt).toLocaleString('es-MX', { timeZone: 'America/Mexico_City' })}
                                    </span>
                                </strong>
                            </p>
                        </Col>
                    </Row>
                <Button
                    className="btn-border-radius moral-successBtn"
                    variant="secondary"
                    onClick={() => {
                        dispatch(
                            getRccFicoScore({
                                clientRef,
                                apellidoPaterno: client?.lastName1,
                                apellidoMaterno: client?.lastName2,
                                primerNombre: client?.names,
                                fechaNacimiento: formattedFechaNac,
                                rfc: client?.RFC,
                                nacionalidad: country,
                                direccion: direccion,
                                coloniaPoblacion: client?.suburb,
                                delegacionMunicipio: client?.municipality,
                                ciudad: client?.city,
                                estado: state,
                                cp: client?.zipCode
                            })
                        );
                        setTemporality(!temporality);
                    }}
                >
                    Consulta a Círculo de Crédito
                </Button>
            </Card>
        );
    };

    const chartStyle = {
        width: 300
    };

    return (
        <>
            <Card className="approvalFlow-fico">
                {!temporality ? (
                    <ConsultCreditCircle />
                ) : (
                    <>
                        <h2 className="text-center m-2 fico-title">Reporte de Crédito</h2>
                        <h4 className="text-center m-2 fico-subtitle">Persona Física</h4>
                        <Card.Header className="approvalFlow-fico-header">
                            <h4>Información</h4>
                            <Row>
                                <Col>
                                    <p>
                                        <strong>
                                            NIP: <br />
                                            <span className="moral-info">{otp?.NIP}</span>
                                        </strong>
                                    </p>
                                </Col>
                                <Col>
                                    <p>
                                        <strong>
                                            Fecha de Creación de NIP: (GMT-0600 hora estándar central)
                                            <br />
                                            <span className="moral-info">
                                                {new Date(otp?.createdAt).toLocaleString('es-MX', { timeZone: 'America/Mexico_City' })}
                                            </span>
                                        </strong>
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <strong>
                                            RFC: <br />
                                            <span className="fico-info">{creditReport.ficoScore?.persona?.rfc}</span>
                                        </strong>
                                    </p>
                                </Col>
                                <Col>
                                    <p>
                                        <strong>
                                            Nombre(s): <br />
                                            <span className="fico-info">{creditReport.ficoScore?.persona?.nombres}</span>
                                        </strong>
                                    </p>
                                </Col>
                                <Col>
                                    <p>
                                        <strong>
                                            Apellido Paterno: <br />
                                            <span className="fico-info">{creditReport.ficoScore?.persona?.apellidoPaterno}</span>
                                        </strong>
                                    </p>
                                </Col>
                                <Col>
                                    <p>
                                        <strong>
                                            Apellido Materno: <br />
                                            <span className="fico-info">{creditReport.ficoScore?.persona?.apellidoMaterno}</span>
                                        </strong>
                                    </p>
                                </Col>
                                <Col>
                                    <p>
                                        <strong>
                                            Folio de Consulta: <br />
                                            <span className="fico-info">{creditReport.ficoScore?.folioConsulta}</span>
                                        </strong>
                                    </p>
                                </Col>
                            </Row>
                            <Row></Row>
                        </Card.Header>
                        <Card.Header className="approvalFlow-fico-header">
                            <h4>FICO Score</h4>
                            <Row>
                                {creditReport.ficoScore?.scores?.map((score: any) => {
                                    const valorTotalScore = (score.valor * 100) / 850 / 100;
                                    return (
                                        <>
                                            <Col>
                                                <div className="fico-bar">
                                                    <GaugeChart
                                                        id="gauge-chart6"
                                                        animate={false}
                                                        colors={['#EA5756', '#F5CD19', '#1DD1A2']}
                                                        percent={valorTotalScore}
                                                        style={chartStyle}
                                                    />
                                                    {score.valor > 0 && score.valor <= 580 ? (
                                                        <p style={{ color: '#EA5756' }}>
                                                            Score Bajo <br />
                                                            {score.valor}
                                                        </p>
                                                    ) : score.valor > 580 && score.valor <= 640 ? (
                                                        <p style={{ color: '#F5CD19' }}>
                                                            Score Regular <br />
                                                            {score.valor}
                                                        </p>
                                                    ) : score.valor > 640 && score.valor <= 700 ? (
                                                        <p style={{ color: '#F5CD19' }}>
                                                            Score Bueno <br />
                                                            {score.valor}
                                                        </p>
                                                    ) : score.valor > 700 && score.valor <= 750 ? (
                                                        <p style={{ color: '#1DD1A2' }}>
                                                            Score Muy Bueno <br />
                                                            {score.valor}
                                                        </p>
                                                    ) : score.valor > 750 && score.valor <= 1000 ? (
                                                        <p style={{ color: '#1DD1A2' }}>
                                                            Score Excelente <br />
                                                            {score.valor}
                                                        </p>
                                                    ) : (
                                                        <p style={{ color: '#1DD1A2' }}>
                                                            Score <br />
                                                            {score.valor}
                                                        </p>
                                                    )}
                                                </div>
                                            </Col>
                                        </>
                                    );
                                })}
                            </Row>
                            <Row>
                                {creditReport.ficoScore?.scores?.map((score: any) => {
                                    return (
                                        <>
                                            {score.razones?.map((razon: String) => {
                                                return (
                                                    <Col>
                                                        <p>
                                                            <strong>
                                                                Razones de Score:<span className="fico-info"> {razon}</span>
                                                            </strong>
                                                        </p>
                                                    </Col>
                                                );
                                            })}
                                        </>
                                    );
                                })}
                            </Row>
                        </Card.Header>
                        <Card.Body className="approvalFlow-fico-body">
                            <h4 className="ml-2">Créditos</h4>
                            <Table responsive className="table-styling">
                                <thead className="table-primary">
                                    <tr>
                                        <th>#</th>
                                        <th>Otorgante</th>
                                        <th>Cuenta Actual</th>
                                        <th>Fecha de apertura de cuenta</th>
                                        <th>Fecha de Último Pago</th>
                                        <th>Fecha de Actualización</th>
                                        <th>Fecha de Reporte</th>
                                        <th>Frecuencia de pagos</th>
                                        <th>Registro Impugnado</th>
                                        <th>Saldo Actual</th>
                                        <th>Saldo Vencido</th>
                                        <th>Número de pagos</th>
                                        <th>Pago actual</th>
                                        <th>Monto a pagar</th>
                                        <th>Número de pagos vencidos</th>
                                        <th>Histórico de pagos</th>
                                    </tr>
                                </thead>
                                {creditReport.ficoScore?.creditos?.map((credit: any, index: number) => {
                                    return (
                                        <tr>
                                            <td scope="row">{index + 1}</td>
                                            <td>{credit.nombreOtorgante}</td>
                                            <td>{credit.cuentaActual}</td>
                                            <td>{credit.fechaAperturaCuenta}</td>
                                            <td>{credit.fechaUltimoPago}</td>
                                            <td>{credit.fechaActualizacion}</td>
                                            <td>{credit.fechaReporte}</td>
                                            <td>{credit.frecuenciaPagos}</td>
                                            <td>{credit.registroImpugnado}</td>
                                            <td>{credit.saldoActual}</td>
                                            <td>{credit.saldoVencido}</td>
                                            <td>{credit.numeroPagos}</td>
                                            <td>{credit.pagoActual}</td>
                                            <td>{credit.montoPagar}</td>
                                            <td>{credit.numeroPagosVencidos}</td>
                                            <td>{credit.historicoPagos}</td>
                                        </tr>
                                    );
                                })}
                            </Table>
                        </Card.Body>
                        <Card.Body className="approvalFlow-fico-body">
                            <h4 className="ml-2">Domicilio</h4>
                            <Table responsive className="table-styling">
                                <thead className="table-primary">
                                    <tr>
                                        <th className="h6">#</th>
                                        <th className="h6">Dirección</th>
                                        <th className="h6">Colonia</th>
                                        <th className="h6">Delegación o Municipio</th>
                                        <th className="h6">Ciudad</th>
                                        <th className="h6">Estado</th>
                                        <th className="h6">Código Postal</th>
                                        <th className="h6">Fecha de residencia</th>
                                    </tr>
                                </thead>
                                {creditReport.ficoScore?.domicilios?.map((recidence: any, index: number) => {
                                    return (
                                        <tr>
                                            <td scope="row">{index + 1}</td>
                                            <td>{recidence.direccion}</td>
                                            <td>{recidence.coloniaPoblacion}</td>
                                            <td>{recidence.delegacionMunicipio}</td>
                                            <td>{recidence.ciudad}</td>
                                            <td>{recidence.estado}</td>
                                            <td>{recidence.cp}</td>
                                            <td>{recidence.fechaResidencia}</td>
                                        </tr>
                                    );
                                })}
                            </Table>
                        </Card.Body>
                        <Card.Body className="approvalFlow-fico-body">
                            <h4 className="ml-2">Empleos</h4>
                            <Table responsive className="table-styling">
                                <thead className="table-primary">
                                    <tr>
                                        <th className="h6">#</th>
                                        <th className="h6">Puesto</th>
                                        <th className="h6">Salario Mensual</th>
                                        <th className="h6">Fecha de contratación</th>
                                        <th className="h6">Fecha último día de empleo</th>
                                        <th className="h6">Nombre de la empresa</th>
                                        <th className="h6">Teléfono</th>
                                    </tr>
                                </thead>
                                {creditReport.ficoScore?.empleos?.map((employment: any, index: number) => {
                                    return (
                                        <tr>
                                            <td scope="row">{index + 1}</td>
                                            <td>{employment.puesto}</td>
                                            <td>$ {employment.salarioMensual}</td>
                                            <td>{employment.fechaContratacion}</td>
                                            <td>{employment.fechaUltimoDiaEmpleo}</td>
                                            <td>{employment.nombreEmpresa}</td>
                                            <td>{employment.numeroTelefono}</td>
                                        </tr>
                                    );
                                })}
                            </Table>
                        </Card.Body>
                        <Card.Body className="approvalFlow-fico-body">
                            <h4>Mensajes</h4>
                            <Table responsive className="table-styling">
                                <thead className="table-primary">
                                    <th className="h6">#</th>
                                    <th className="h6">Mensaje</th>
                                    <th className="h6">Tipo</th>
                                </thead>
                                {creditReport.ficoScore?.mensajes?.map((message: any, index: number) => {
                                    return (
                                        <tr>
                                            <td scope="row">{index + 1}</td>
                                            <td>{message.leyenda}</td>
                                            <td>{message.tipoMensaje}</td>
                                        </tr>
                                    );
                                })}
                            </Table>
                            {creditReport.message !== '' && creditReport.message !== undefined && (
                                <Card.Header className="approvalFlow-fico-header text-center">
                                    <Card.Title as="h5" className="fico-alertSuccess">
                                        {creditReport.message}
                                    </Card.Title>
                                </Card.Header>
                            )}
                        </Card.Body>
                        <Card.Footer className="approvalFlow-fico-footer">
                            <Card.Title as="h5">
                                ¿El prospecto cumple con los requerimientos necesarios en Buró de Crédito para continuar?
                            </Card.Title>
                            <Row className="mt-3 ml-3">
                                <Button
                                    className="btn-border-radius fico-successBtn"
                                    variant={'success'}
                                    onClick={() => {
                                        setShowApprovalModal(!showApprovalModal);
                                    }}
                                >
                                    Si
                                </Button>
                                <Button
                                    variant={'danger'}
                                    className="ml-3 btn-border-radius fico-alertBtn"
                                    onClick={() => {
                                        setShowCancelRequest(!showCancelRequest);
                                    }}
                                >
                                    No
                                </Button>
                            </Row>
                        </Card.Footer>
                    </>
                )}
                <Modal show={showApprovalModal} onHide={() => setShowApprovalModal(!showApprovalModal)}>
                    <Modal.Header closeButton className="approvalFlow-ficoModal-header">
                        <Modal.Title as="h5">Aprobar estado en buró de crédito</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="approvalFlow-ficoModal-body">
                        <p>
                            El prospecto cumple con los requerimientos necesarios en BURÓ DE CRÉDITO para continuar
                            <br />
                            <br />
                            ¿Deseas continuar?
                        </p>
                    </Modal.Body>
                    <Modal.Footer className="approvalFlow-fico-footer">
                        <Row className="mt-3 mb-3">
                            <Button
                                className="btn-border-radius fico-alertBtn"
                                variant="danger"
                                onClick={() => setShowApprovalModal(!showApprovalModal)}
                            >
                                Cancelar
                            </Button>
                            <Button
                                className="ml-3 btn-border-radius fico-successBtn"
                                variant="secondary"
                                onClick={ApproveFicoScoreCreditReport}
                            >
                                Aprobar
                            </Button>
                        </Row>
                    </Modal.Footer>
                </Modal>
                <Modal show={showCancelRequest} onHide={() => setShowCancelRequest(!showCancelRequest)}>
                    <Modal.Header closeButton className="approvalFlow-ficoModal-header">
                        <Modal.Title as="h5">
                            El usuario NO cumple con la requerimientos necesarios para la solicitud del crédito
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="approvalFlow-ficoModal-body">
                        <p>
                            El prospecto no cumple con los requisitos necesarios en Círculo de Crédito para ser candidato a un crédito. Esta
                            cotización quedará CANCELADA <br />
                            <br /> ¿Deseas continuar?
                        </p>
                    </Modal.Body>
                    <Modal.Footer className="approvalFlow-fico-footer">
                        <Row className="mt-3 mb-3">
                            <Button
                                className="btn-border-radius fico-alertBtn"
                                variant="danger"
                                onClick={() => setShowCancelRequest(!showCancelRequest)}
                            >
                                Cancelar
                            </Button>
                            <Button className="ml-3 btn-border-radius fico-successBtn" variant="success" onClick={cancelRequest}>
                                Aceptar
                            </Button>
                        </Row>
                    </Modal.Footer>
                </Modal>
            </Card>
        </>
    );
};

export default FicoScoreCreditReport;
