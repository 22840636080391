interface Badge {
    title: string;
    type: string;
}
export interface MenuItemType {
    id: string;
    title: string;
    type: string;
    icon?: string;
    children?: MenuItemType[];
    breadcrumbs?: boolean;
    url?: string;
    badge?: Badge;
    target?: boolean;
    classes?: string;
    external?: boolean;
}
//Here we define the menu that is going to show at the customer and the admin, if we need to defina another menu we can create it here
const chartData: { users: MenuItemType[]; admin: MenuItemType[] } = {
    users: [
        {
            id: 'menu',
            title: 'menu',
            type: 'group',
            children: [
                {
                    id: 'inicio',
                    title: 'Inicio',
                    type: 'item',
                    url: '/usuario/inicio',
                    classes: 'nav-item',
                    icon: 'feather icon-home'
                }
            ]
        }
    ],
    admin: [
        {
            id: 'menu',
            title: 'menu',
            type: 'group',
            children: [
                {
                    id: 'Dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/admin/dashboard',
                    classes: 'nav-item',
                    icon: 'feather icon-home'
                },
                {
                    id: 'Prospects',
                    title: 'Prospectos',
                    type: 'item',
                    url: '/admin/prospectos',
                    classes: 'nav-item',
                    icon: 'feather icon-users'
                },
                {
                    id: 'Previus data',
                    title: 'Consultas',
                    type: 'item',
                    url: '/admin/Consultas',
                    classes: 'nav-item',
                    icon: 'feather icon-user-plus'
                },
                {
                    id: 'Flows',
                    title: 'Flujos',
                    type: 'collapse',
                    classes: 'nav-item',
                    icon: 'feather icon-grid',
                    url: '/admin/flujos',
                    children: [
                        {
                            id: 'list flows',
                            title: 'Ver flujos',
                            type: 'item',
                            classes: 'nav-item',
                            url: '/admin/flujos'
                        },
                        {
                            id: 'create flow',
                            title: 'Crear flujo',
                            type: 'item',
                            classes: 'nav-item',
                            url: '/admin/crear-flujo'
                        }
                    ]
                },
                {
                    id: 'Admins',
                    title: 'Administración',
                    type: 'collapse',
                    classes: 'nav-item',
                    icon: 'feather icon-lock',
                    url: '/admin/flujos',
                    children: [
                        {
                            id: 'pld',
                            title: 'PLD',
                            type: 'item',
                            classes: 'nav-item',
                            url: '/admin/pld'
                        },
                        {
                            id: 'list',
                            title: 'Listas',
                            type: 'item',
                            classes: 'nav-item',
                            url: '/admin/listas'
                        },
                        {
                            id: 'users',
                            title: 'Usuarios',
                            type: 'item',
                            classes: 'nav-item',
                            url: '/admin/usuarios'
                        },
                        {
                            id: 'rols',
                            title: 'Roles',
                            type: 'item',
                            classes: 'nav-item',
                            url: '/admin/roles'
                        }
                    ]
                }
            ]
        }
    ]
};
export default chartData;
