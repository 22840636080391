
export function approvalCriteriaList(){
    return [
        {
            Name: "Antigüedad de la Empresa",
            alias: "antiquity",
            description: "Número mínimo de años que la empresa debe llevar operando para solicitar crédito."
        },
        {
            Name: "Score Crediticio",
            alias: "creditScore",
            description: "Puntaje mínimo registrado en el historial crediticio para solicitar crédito."
        },
        {
            Name: "Deuda",
            alias: "debt",
            description: "Deuda máxima que es permitida para solicitar un crédito."
        },
        {
            Name: "Ubicación",
            alias: "location",
            description: "Estados de la república en los que NO se proporcionaran créditos."
        },
        {
            Name: "Capacidad de Pago",
            alias: "paymentCapacity",
            description: "Capacidad de pago mínima aceptada para solicitar un crédito."
        },
        {
            Name: "Capacidad de Endeudamiento",
            alias: "borrowingCapacity",
            description: "Capacidad de endeudamiento máxima aceptada para solicitar un crédito."
        },
        {
            Name: "Validación de lista de Riesgo",
            alias: "listRiskPeople",
            description: "Se compara el nombre del Solicitante con la lista de riesgo de existir coicidencia se notificará al administrador."
        }
    ];
}
