enum FLUX_CLIENT_CONSTANTS {
    LOGIN_CLIENT_BEGIN = 'LOGIN_CLIENT_BEGIN',
    LOGIN_CLIENT_ERROR = 'LOGIN_CLIENT_ERROR',
    LOGIN_CLIENT_SUCCESS = 'LOGIN_CLIENT_SUCCESS',

    SIGNUP_BEGIN = 'SIGNUP_BEGIN',
    SIGNUP_ERROR = 'SIGNUP_ERROR',
    SIGNUP_SUCCESS = 'SIGNUP_SUCCESS',

    LOGOUT_BEGIN = 'LOGOUT_BEGIN',
    LOGOUT_ERROR = 'LOGOUT_ERROR',
    LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',

    GET_CLIENT_BEGIN = 'GET_CLIENT_BEGIN',
    GET_CLIENT_ERROR = 'GET_CLIENT_ERROR',
    GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS',

    UPDATE_CLIENT_BEGIN = 'UPDATE_CLIENT_BEGIN',
    UPDATE_CLIENT_ERROR = 'UPDATE_CLIENT_ERROR',
    UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS',

    STEP_BACK_BEGIN = 'STEP_BACK_BEGIN',
    STEP_BACK_ERROR = 'STEP_BACK_ERROR',
    STEP_BACK_SUCCESS = 'STEP_BACK_SUCCESS',

    VALIDATION_DOCUMENTS_ID_BEGIN = 'VALIDATION_DOCUMENTS_ID_BEGIN',
    VALIDATION_DOCUMENTS_ID_ERROR = 'VALIDATION_DOCUMENTS_ID_ERROR',
    VALIDATION_DOCUMENTS_ID_SUCCESS = 'VALIDATION_DOCUMENTS_ID_SUCCESS',

    VALIDATION_DOCUMENTS_ADDRESS_BEGIN = 'VALIDATION_DOCUMENTS_ADDRESS_BEGIN',
    VALIDATION_DOCUMENTS_ADDRESS_ERROR = 'VALIDATION_DOCUMENTS_ADDRESS_ERROR',
    VALIDATION_DOCUMENTS_ADDRESS_SUCCESS = 'VALIDATION_DOCUMENTS_ADDRESS_SUCCESS',

    SAVE_FLOW_INFO_BEGIN = 'SAVE_FLOW_INFO_BEGIN',
    SAVE_FLOW_INFO_ERROR = 'SAVE_FLOW_INFO_ERROR',
    SAVE_FLOW_INFO_SUCCESS = 'SAVE_FLOW_INFO_SUCCESS',

    UPDATE_SCHEDULE_BEGIN = 'SAVE_FLOW_BEGIN',
    UPDATE_SCHEDULE_ERROR = 'UPDATE_SCHEDULE_ERROR',
    UPDATE_SCHEDULE_SUCCESS = 'UPDATE_SCHEDULE_SUCCESS',

    UPDATE_LOCATION_BEGIN = 'UPDATE_LOCATION_BEGIN',
    UPDATE_LOCATION_ERROR = 'UPDATE_LOCATION_ERROR',
    UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS',

    RESET_CLIENT_STATUS = 'RESET_CLIENT_STATUS'
}

export default FLUX_CLIENT_CONSTANTS;
