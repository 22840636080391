import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { persistRules } from './persistRulesReducer';
import { quoteReducer } from '../reducers/quote-reducer';
import { authReducer } from './../reducers/auth-reducer';
import { zipCodeReducer } from './../reducers/zipCode-reducer';
import { economicActivitiesReducer } from './../reducers/economicActivities-reducer';
import { otpReducer } from '../reducers/otp-reducer';
import { clientReducer } from '../reducers/client-reducer';
import { satwsReducer } from '../reducers/satws-reducer';
import { bussinessInfoReducer } from '../reducers/bussinessInfo-reducer';
import { flowReducer } from '../reducers/flow-reducer';
import { informationListReducer } from '../reducers/informationList-reducer';
import { affiliateReducer } from '../reducers/affiliate-reducer';
import { DocumentsReducer } from '../reducers/documents-reducer';
import { approvalCriteriaReducer } from '../reducers/approvalCriteria-reducer';
import { approvalProcessReducer } from '../reducers/approvalProcess-reducer';
import { listRiskPeopleReducer } from '../reducers/listRiskPeople-reducer';
import { renewpasswordReducer } from '../reducers/renewpassword-reducer';
import { circleCreditReducer } from '../reducers/circleCredit-reducer';
import { previusDataReducer } from '../reducers/previusData-reducer';
import { creditProposalReducer } from '../reducers/creditProposal-reducer';
import { mailReducer } from '../reducers/mail-reducer';
import { permissionReducer } from '../reducers/permission-reducer';
import { roleReducer } from '../reducers/role-reducer';
import { refreshTokenReducer } from '../reducers/refreshToken-reducer';
import { NubariumReferenceReducer } from '../reducers/nubarium-reducer';
import { legalRepresentativeReducer } from '../reducers/legalrepresentative-reducer';
import ableReducer, { initialState as AbleState } from '../../store/ableReducer';
import demoReducer, { initialState as DemoState } from '../../store/demoReducer';

const appReducer = combineReducers({
    authReducer: persistReducer(persistRules.authPersistConfig, authReducer),
    quoteReducer,
    zipCodeReducer,
    economicActivitiesReducer,
    otpReducer,
    clientReducer,
    flowReducer,
    bussinessInfoReducer,
    able: ableReducer,
    demo: demoReducer,
    satwsReducer,
    informationListReducer,
    affiliateReducer,
    DocumentsReducer,
    approvalCriteriaReducer,
    approvalProcessReducer,
    listRiskPeopleReducer,
    circleCreditReducer,
    previusDataReducer,
    creditProposalReducer,
    renewpasswordReducer,
    mailReducer,
    permissionReducer,
    roleReducer,
    refreshTokenReducer,
    NubariumReferenceReducer,
    legalRepresentativeReducer
});

const rootReducer = (state: any, action: any) => {
    return appReducer(state, action);
};

export default rootReducer;
