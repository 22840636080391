enum LISTRISKPEOPLE_CONSTANTS {
    GET_LISTRISKPEOPLE_BEGIN = "GET_LISTRISKPEOPLE_BEGIN",
    GET_LISTRISKPEOPLE_ERROR = "GET_LISTRISKPEOPLE_ERROR",
    GET_LISTRISKPEOPLE_SUCCESS = "GET_LISTRISKPEOPLE_SUCCESS",

    RESET_LISTRISKPEOPLE_STATUS = "RESET_LISTRISKPEOPLE_STATUS"
};

export default LISTRISKPEOPLE_CONSTANTS;

