import React, { FC } from 'react';
import { Card, Col, Row, Modal, Button } from 'react-bootstrap';
import { rejectQuote, getCredit, updateStatus } from '../../../../flux/actions/quote-actions';
import { useSelector, useDispatch } from 'react-redux';
import { sendAuthMail } from '../../../../flux/actions/mail-actions';
import { useParams } from 'react-router-dom';

const ActivePortfolio: React.FC = () => {
    const params = useParams();
    const clientRef = (params as any)?.id;
    const dispatch = useDispatch();
    const { client } = useSelector((state: any) => state.clientReducer);

    function applicationApproved() {
        dispatch(
            updateStatus({
                clientRef,
                type: 'updateBothStates',
                adminStatus: 'activePortfolio',
                status: 'completionOfTheApprovalFlow'
            })
        );
        setTimeout(() => {dispatch(getCredit(client.id));},1000);
    }

    function cancelRequest() {
        dispatch(rejectQuote({ client: clientRef }));
        dispatch(
            sendAuthMail({ mailType: 3, email: client.email, name: client.names, lastName1: client.lastName1, lastName2: client.lastName2 })
        );
    }

    return (
        <Card>
            <h3 className="text-center">PROPUESTA DE CRÉDITO APROBADA POR PARTE DEL CLIENTE.</h3>
            <Card.Footer className="approvalFlow-offer-footer">
                <Row className="d-flex justify-content-center">
                    <Button className="btn-border-radius offer-successBtn" variant={'success'} onClick={applicationApproved}>
                        Aprobar Crédito
                    </Button>
                    <Button variant={'danger'} className="ml-3 btn-border-radius offer-alertBtn" onClick={cancelRequest}>
                        Rechazar Crédito
                    </Button>
                </Row>
            </Card.Footer>
        </Card>
    );
};
export default ActivePortfolio;