enum PERMISSION_CONSTANTS {
    POST_ROLE_BEGIN = "POST_ROLE_BEGIN",
    POST_ROLE_ERROR = "POST_ROLE_ERROR",
    POST_ROLE_SUCCESS = "POST_ROLE_SUCCESS",

    UPDATE_ROLE_BEGIN = "UPDATE_ROLE_BEGIN ",
    UPDATE_ROLE_ERROR = "UPDATE_ROLE_ERROR",
    UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS",

    GET_ALL_ROLES_BEGIN = "GET_ALL_ROLES_BEGIN",
    GET_ALL_ROLES_ERROR = "GET_ALL_ROLES_ERROR",
    GET_ALL_ROLES_SUCCESS = "GET_ALL_ROLES_SUCCESS",

    GET_ROLE_BEGIN = "GET_ROLE_BEGIN",
    GET_ROLE_ERROR = "GET_ROLE_ERROR",
    GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS",

    RESET_ROLE_STATUS = "RESET_ROLE_STATUS",
    RESET_ROLE_MESSAGES = "RESET_ROLE_MESSAGES"
};

export default PERMISSION_CONSTANTS;