import { Reducer } from 'redux';
import ECONOMICACTIVITIES_CONSTANTS from '../constants/economicAct-constants';
import { EconomicActivitiesAction } from '../types/economicAct-types';

export interface EconomicActivitiesReducerState {
    loading: boolean;
    success: boolean;
    successMessage: string;
    economicActivitiesData: [];
    error: boolean;
    errorMessage?: string;
    errorStatus?: number;
}

const initialState: EconomicActivitiesReducerState = {
    loading: false,
    success: false,
    error: false,
    economicActivitiesData: [],
    successMessage: '',
    errorMessage: '',
    errorStatus: 0,
}

export const economicActivitiesReducer: Reducer<EconomicActivitiesReducerState, EconomicActivitiesAction> = (state = initialState, action: EconomicActivitiesAction) => {
    switch (action.type) {
        //----------CREATE ORDER-----------//
        case ECONOMICACTIVITIES_CONSTANTS.GET_ECONOMICACTIVITY_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            }
        
        case ECONOMICACTIVITIES_CONSTANTS.GET_ECONOMICACTIVITY_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                errorStatus: action.error.data.status,
                errorMessage: action.error.data.message,
                successMessage: '',
                loading: false
            }
        
        case ECONOMICACTIVITIES_CONSTANTS.GET_ECONOMICACTIVITY_SUCCESS:
            return {
                ...state,
                success: true,
                economicActivitiesData: action.data,
                errorMessage: '',
                errorStatus: 0,
                error: false,
                loading: false,
                successMessage:'Datos del Negocio guardados correctamente' 
            }
        
        case ECONOMICACTIVITIES_CONSTANTS.RESET_ECONOMICACTIVITY_STATUS:
            return {
                success: false,
                error: false,
                loading: false,
                economicActivitiesData: [],
                errorStatus: 0,
                errorMessage: '',
                successMessage: '',
            }

        default:
            return state;
    }
}