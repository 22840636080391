import { Reducer } from 'redux';
import permissionModel from '../../models/permission-model';
import PERMISSION_CONSTANTS from '../constants/permission-constants';
import { PermissionAction } from '../types/permission-types';

export interface PermissionReducerState {
    loading: boolean;
    success: boolean;
    successMessage: string;
    permission: permissionModel;
    error: boolean;
    errorMessage?: string;
    errorStatus?: number;
}

const initialState: PermissionReducerState = {
    loading: false,
    success: false,
    error: false,
    permission: {} as permissionModel,
    successMessage: '',
    errorMessage: '',
    errorStatus: 0
};

export const permissionReducer: Reducer<PermissionReducerState, PermissionAction> = (state = initialState, action: PermissionAction) => {
    switch (action.type) {
        //----------CREATE PERMISSION-----------//
        case PERMISSION_CONSTANTS.POST_PERMISSION_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            };

        case PERMISSION_CONSTANTS.POST_PERMISSION_ERROR:
            return {
                ...state,
                success: false,
                successMessage: '',
                error: true,
                errorStatus: action.error.data.status,
                errorMessage: action.error.data.message,
                loading: false
            };

        case PERMISSION_CONSTANTS.POST_PERMISSION_SUCCESS:
            return {
                ...state,
                success: true,
                successMessage: action.data.message,
                errorMessage: '',
                errorStatus: 0,
                error: false,
                loading: false
            };

        //----------GET PERMISSIONS-----------//
        case PERMISSION_CONSTANTS.GET_PERMISSION_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            };

        case PERMISSION_CONSTANTS.GET_PERMISSION_ERROR:
            return {
                ...state,
                success: false,
                successMessage: '',
                error: true,
                errorStatus: action.error.data.status,
                errorMessage: action.error.data.message,
                loading: false
            };

        case PERMISSION_CONSTANTS.GET_PERMISSION_SUCCESS:
            return {
                ...state,
                success: true,
                permission: action.data,
                errorMessage: '',
                errorStatus: 0,
                error: false,
                loading: false
            };

        // ------------- RESET PERMISSION -------------//
        case PERMISSION_CONSTANTS.RESET_PERMISSION_STATUS:
            return {
                success: false,
                error: false,
                loading: false,
                errorStatus: 0,
                permission: {} as permissionModel,
                errorMessage: '',
                successMessage: ''
            };

        default:
            return state;
    }
};
