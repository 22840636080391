enum PREVIUS_DATA_CONSTANTS {
    POST_PREVIUS_DATA_BEGIN = "POST_PREVIUS_DATA_BEGIN",
    POST_PREVIUS_DATA_ERROR = "POST_PREVIUS_DATA_ERROR",
    POST_PREVIUS_DATA_SUCCESS = "POST_PREVIUS_DATA_SUCCESS",

    GET_PREVIUS_DATA_BEGIN = "GET_PREVIUS_DATA_BEGIN",
    GET_PREVIUS_DATA_ERROR = "GET_PREVIUS_DATA_ERROR",
    GET_PREVIUS_DATA_SUCCESS = "GET_PREVIUS_DATA_SUCCESS",

    RESET_PREVIUS_DATA_STATUS = "RESET_PREVIUS_DATA_STATUS"
};

export default PREVIUS_DATA_CONSTANTS;