import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Form, Col, Row, Modal, Button } from 'react-bootstrap';
import { getZipCode } from '../../../../flux/actions/zipCode-actions';
import zipCodeModel from '../../../../models/zipCode-model';
import { updateClient } from '../../../../flux/actions/client-actions';
import Loader from '../../../../components/ScreenLoader/ScreenLoader';

const PersonalAddress: React.FC = () => {
    //const to get the params
    const dispatch = useDispatch();
    const zipCodes: zipCodeModel[] = useSelector((state: any) => state.zipCodeReducer.zipCodeData);
    const { error, errorMessage } = useSelector((state: any) => state.zipCodeReducer);
    const { client, loading } = useSelector((state: any) => state.clientReducer);

    //PERSONAL ADDRESS INFORMATION CONST
    const [country, setCountry] = useState('');
    const [zipCode, setZipCode] = React.useState('');
    const [municipality, setMunicipality] = useState('');
    const [city, setCity] = React.useState('');
    const [state, setState] = useState('');
    const [suburb, setSuburb] = useState('');
    const [street, setStreet] = useState('');
    const [numInt, setNumInt] = useState('');
    const [numExt, setNumExt] = useState('');

    //Constantes de Edicion sirven para habilitar y deshabilitar partes del código
    const [isPersonalAdreesEdit, setIsPersonalAddressEdit] = useState(false);
    const [showModal, setShowModal] = useState(false);

    //Se crea y se Inicializa un objeto para los posibles errores de Direcion Personal
    const [errorsAddressPersonal, setErrorsAddressPersonal] = useState({
        country: '',
        zipCode: '',
        city: '',
        state: '',
        municipality: '',
        suburb: '',
        street: '',
        numExt: ''
    });

    // useEffect(() => {
    //     zipCode && zipCode.length === 5 && dispatch(getZipCode(zipCode));
    // }, [zipCode]);

    useEffect(() => {
        if (Object.keys(client).length !== 0) {
            setCountry(client.country || '');
            setZipCode(client.zipCode || '');
            setMunicipality(client.municipality || '');
            setState(client.state || '');
            setCity(client.city || '');
            setSuburb(client.suburb || '');
            setStreet(client.street || '');
            setNumInt(client.numInt || '');
            setNumExt(client.numExt || '');
        }
    }, [client]);

    //se asignan lios valores de ciudad,estado,municipio, y colonia sise cumple la condicion
    useEffect(() => {
        if (zipCodes.length !== 0 && isPersonalAdreesEdit) {
            setCity(zipCodes[0].d_ciudad || '');
            if (!zipCodes[0].d_ciudad) {
                setErrorsAddressPersonal({ ...errorsAddressPersonal, city: 'No hay registro de ciudad, escribir manualmente' });
            }
            setState(zipCodes[0].d_estado);
            setMunicipality(zipCodes[0].D_mnpio);
            setSuburb(zipCodes[0].d_asenta);
        }
    }, [zipCodes]);

    //Funcion para actualizar la Información en la base de datos y manejar los posibles errores
    const saveAddress = () => {
        //constantes manejadoras de errrores
        const countryError = country !== '0' ? '' : 'Favor de seleccionar un País';
        const zipCodeError = !error && zipCode.length == 5 ? '' : 'Hay un error con el código postal';
        const cityError = city.length !== 0 && city !== null ? '' : 'Este campo no puede estar vacío';
        const stateError = state.length !== 0 ? '' : 'Este campo no puede estar vacío';
        const municipalityError = municipality.length !== 0 ? '' : 'Este campo no puede estar vacío';
        const suburbError = suburb !== '0' ? '' : 'Favor de seleccionar una Colonia';
        const streetError = street.length !== 0 ? '' : 'Este campo no puede estar vacío';
        const numExtError = numExt.length !== 0 ? '' : 'Este campo no puede estar vacío';
        //Se asignan posibles errores en el objeto
        setErrorsAddressPersonal({
            country: countryError,
            zipCode: zipCodeError,
            city: cityError,
            street: streetError,
            municipality: municipalityError,
            suburb: suburbError,
            state: stateError,
            numExt: numExtError
        });

        if (zipCodeError || cityError || stateError || municipalityError || suburbError || streetError || numExtError || countryError)
            return;
        //Actualiza la información si no exite ningun error
        dispatch(
            updateClient({
                country,
                zipCode,
                city,
                state,
                municipality,
                suburb,
                street,
                numExt,
                numInt,
                id: client.id
            })
        );
        setShowModal(!showModal);
        setIsPersonalAddressEdit(!isPersonalAdreesEdit);
    };

    return loading ? (
        <div className="auth-wrapper align-items-center">
            <Loader isOpen={true} />
        </div>
    ) : (
        <>
            <Card>
                <Card.Body className="d-flex align-items-center justify-content-between">
                    <h5 className="mb-0">Domicilio Personal</h5>
                    <button
                        type="button"
                        className="btn btn-primary btn-sm btn-border-radius m-0 float-right customerProfile-editBtn"
                        onClick={() => {
                            setIsPersonalAddressEdit(!isPersonalAdreesEdit);
                        }}
                    >
                        <i className={isPersonalAdreesEdit ? 'feather icon-x' : 'feather icon-edit'} />
                    </button>
                </Card.Body>
                <Card.Body className={isPersonalAdreesEdit ? 'border-top pro-det-edit collapse' : 'border-top pro-det-edit collapse show'}>
                    <Row>
                        <Col className="form-group">
                            <label className="font-weight-bolder mt-3">Código postal: </label>
                            <p>{zipCode}</p>
                        </Col>
                        <Col className="form-group">
                            <label className="font-weight-bolder mt-3">Estado: </label>
                            <p>{state}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="form-group">
                            <label className="font-weight-bolder mt-3">Ciudad: </label>
                            <p>{city}</p>
                        </Col>
                        <Col className="form-group">
                            <label className="font-weight-bolder mt-3">Municipio: </label>
                            <p>{municipality}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="form-group">
                            <label className="font-weight-bolder mt-3">Colonia: </label>
                            <p>{suburb}</p>
                        </Col>
                        <Col className="form-group">
                            <label className="font-weight-bolder mt-3">Calle: </label>
                            <p>{street}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="form-group">
                            <label className="font-weight-bolder mt-3">Número Exterior: </label>
                            <p>{numExt}</p>
                        </Col>
                        <Col className="form-group">
                            <label className="font-weight-bolder mt-3">Número Interior: </label>
                            <p>{numInt || 'Sin Número'}</p>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Body className={isPersonalAdreesEdit ? 'border-top pro-det-edit collapse show' : 'border-top pro-det-edit collapse'}>
                    <Row className="form-group">
                        <Col>
                            <label className="font-weight-bolder mt-3">Código Postal: </label>
                            <input
                                type="text"
                                className="form-control"
                                id="cp"
                                placeholder="Código postal"
                                value={zipCode}
                                maxLength={5}
                                onChange={(e: any) => {
                                    setZipCode(e.target.value);
                                    setErrorsAddressPersonal({ ...errorsAddressPersonal, zipCode: '' });
                                }}
                            />
                            {errorMessage && <p className="form-text text-left text-danger">{errorMessage}</p>}
                            {errorsAddressPersonal.zipCode && (
                                <p className="form-text text-left text-danger">{errorsAddressPersonal.zipCode}</p>
                            )}
                        </Col>
                        <Col>
                            <label className="font-weight-bolder">Estado:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="state"
                                placeholder="Estado"
                                value={state}
                                onChange={(e: any) => {
                                    setState(e.target.value);
                                    setErrorsAddressPersonal({ ...errorsAddressPersonal, state: '' });
                                }}
                            />

                            {errorsAddressPersonal.state && (
                                <p className="form-text text-left text-danger">{errorsAddressPersonal.state}</p>
                            )}
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Col>
                            <label className="font-weight-bolder">Ciudad:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="city"
                                placeholder="Ciudad"
                                value={city}
                                onChange={(e: any) => {
                                    setCity(e.target.value);
                                    setErrorsAddressPersonal({ ...errorsAddressPersonal, city: '' });
                                }}
                            />

                            {errorsAddressPersonal.city && <p className="form-text text-left text-danger">{errorsAddressPersonal.city}</p>}
                        </Col>
                        <Col>
                            <label className="font-weight-bolder">Municipio:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="municipaliti"
                                placeholder="Municipio"
                                value={municipality}
                                onChange={(e: any) => {
                                    setMunicipality(e.target.value);
                                    setErrorsAddressPersonal({ ...errorsAddressPersonal, municipality: '' });
                                }}
                            />

                            {errorsAddressPersonal.municipality && (
                                <p className="form-text text-left text-danger">{errorsAddressPersonal.municipality}</p>
                            )}
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Col>
                            <label className="font-weight-bolder">Colonia:</label>
                            <Form.Group className="pl-0">
                                <Form.Control
                                    as="select"
                                    value={suburb}
                                    onChange={(e) => {
                                        setSuburb(e.target.value);
                                    }}
                                    autoComplete="off"
                                >
                                    {zipCodes.length === 0 && <option value={'0'}>Colonia</option>}
                                    {zipCodes &&
                                        zipCodes.map((zipCode) => {
                                            return <option value={zipCode.d_asenta}>{zipCode.d_asenta}</option>;
                                        })}
                                </Form.Control>
                            </Form.Group>

                            {errorsAddressPersonal.suburb && (
                                <p className="form-text text-left text-danger">{errorsAddressPersonal.suburb}</p>
                            )}
                        </Col>
                        <Col>
                            <label className="font-weight-bolder">Calle:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="street"
                                placeholder="Calle"
                                value={street}
                                onChange={(e: any) => {
                                    setStreet(e.target.value);
                                    setErrorsAddressPersonal({ ...errorsAddressPersonal, street: '' });
                                }}
                            />

                            {errorsAddressPersonal.street && (
                                <p className="form-text text-left text-danger">{errorsAddressPersonal.street}</p>
                            )}
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Col>
                            <label className="font-weight-bolder">Número Exterior:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="numExt"
                                placeholder="# Exterior"
                                value={numExt}
                                onChange={(e: any) => {
                                    setNumExt(e.target.value);
                                    setErrorsAddressPersonal({ ...errorsAddressPersonal, numExt: '' });
                                }}
                            />

                            {errorsAddressPersonal.numExt && (
                                <p className="form-text text-left text-danger">{errorsAddressPersonal.numExt}</p>
                            )}
                        </Col>
                        <Col>
                            <label className="font-weight-bolder">Número Interior:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="numInt"
                                placeholder="# Interior"
                                value={numInt}
                                onChange={(e: any) => {
                                    setNumInt(e.target.value);
                                    //    setErrors({ ...errors, antiquity: "" });
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="form-group mt-5">
                        <Col className="d-flex justify-content-end">
                            <button
                                type="submit"
                                className="btn btn-primary customerProfile-saveBtn"
                                onClick={() => {
                                    setShowModal(!showModal);
                                }}
                            >
                                Guardar
                            </button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Modal show={showModal} onHide={() => setShowModal(!showModal)}>
                <Modal.Header closeButton>
                    <Modal.Title as="h5">Actualización de información</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    La información de la dirección personal del usuario ha sido modificada
                    <br />
                    <br />
                    ¿Deseas continuar con la actualización de la información del usuario?
                </Modal.Body>
                <Modal.Footer>
                    <Row className="mt-3 mb-3">
                        <Button className="btn-border-radius" variant="danger" onClick={() => setShowModal(!showModal)}>
                            Cancelar
                        </Button>
                        <Button className="ml-3 btn-border-radius" variant="secondary" onClick={saveAddress}>
                            Continuar
                        </Button>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default PersonalAddress;
