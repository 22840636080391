enum DOCUMENTS_CONSTANTS {
    POST_DOCUMENTS_BEGIN = 'POST_DOCUMENTS_BEGIN',
    POST_DOCUMENTS_ERROR = 'POST_DOCUMENTS_ERROR',
    POST_DOCUMENTS_SUCCESS = 'POST_DOCUMENTS_SUCCESS',

    UPDATE_DOCUMENTS_SUCCESS = 'UPDATE_DOCUMENTS_SUCCESS',
    UPDATE_ID_SUCCESS = 'UPDATE_ID_SUCCESS',
    UPDATE_SCHEDULE_SUCCESS = 'UPDATE_SCHEDULE_SUCCESS',

    GET_DOCUMENTS_BEGIN = 'GET_DOCUMENTS_BEGIN',
    GET_DOCUMENTS_ERROR = 'GET_DOCUMENTS_ERROR',
    GET_DOCUMENTS_SUCCESS = 'GET_DOCUMENTS_SUCCESS',
    GET_ID_SUCCESS = 'GET_ID_SUCCESS',

    REQUEST_DOCUMENTS_BEGIN = 'REQUEST_DOCUMENTS_BEGIN',
    REQUEST_DOCUMENTS_ERROR = 'REQUEST_DOCUMENTS_ERROR',
    REQUEST_DOCUMENTS_SUCCESS = 'REQUEST_DOCUMENTS_SUCCESS',
    REQUEST_ID_SUCCESS = 'REQUEST_ID_SUCCESS',

    RESET_DOCUMENTS_STATUS = 'RESET_DOCUMENTS_STATUS'
}

export default DOCUMENTS_CONSTANTS;
