import { Reducer } from 'redux';
import RENEWPASSWORD_CONSTANTS from '../constants/renewpassword-constants';
import { RenewpasswordAction } from '../types/renewpassword-types';

export interface RenewpasswordReducerState {
    loading: boolean;
    success: boolean;
    successMessage: string;
    error: boolean;
    errorMessage?: string;
    errorStatus?: number;
}

const initialState: RenewpasswordReducerState = {
    loading: false,
    success: false,
    error: false,
    successMessage: '',
    errorMessage: '',
    errorStatus: 0,
}

export const renewpasswordReducer: Reducer<RenewpasswordReducerState, RenewpasswordAction> = (state = initialState, action: RenewpasswordAction) => {
    switch (action.type) {
        //----------CREATE ORDER-----------//
        case RENEWPASSWORD_CONSTANTS.POST_RENEWPASSWORD_CONSTANTS_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            }
        
        case RENEWPASSWORD_CONSTANTS.POST_RENEWPASSWORD_CONSTANTS_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                errorStatus: action.error.data.status,
                errorMessage: action.error.data.errors,
                successMessage: '',
                loading: false
            }
        
        case RENEWPASSWORD_CONSTANTS.POST_RENEWPASSWORD_CONSTANTS_SUCCESS:
            return {
                ...state,
                success: true,
                successMessage: action.data,
                errorMessage: '',
                errorStatus: 0,
                error: false,
                loading: false
            }
        
        case RENEWPASSWORD_CONSTANTS.RESET_RENEWPASSWORD_CONSTANTS_STATUS:
            return {
                success: false,
                error: false,
                loading: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            }

        default:
            return state;
    }
}