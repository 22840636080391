import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import UploadFilesBuilder from '../../../components/Builders/UploadFilesBuilder';
import { getId } from '../../../flux/actions/documents-actions';
import { refreshToken } from '../../../flux/actions/refreshToken-action';

const IdRequest = () => {
    //redux const
    const history = useHistory();
    const dispatch = useDispatch();
    const { client } = useSelector((state: any) => state.clientReducer);
    const files = useSelector((state: any) => state.DocumentsReducer.flowDocuments);

    /*Validates if the user is logged in and if he is logged in, he obtains the user information and his files, otherwise it redirects you to the login*/
    useEffect(() => {
        if (Object.keys(client).length === 0) {
            history.push('/login');
        } else {
            dispatch(getId(client.id));
        }
        dispatch(
            refreshToken({
                token: localStorage.getItem('token'),
                refreshToken: localStorage.getItem('refreshToken')
            })
        );
    }, []);

    return (
        <UploadFilesBuilder
            files={files}
            title={'Carga de Identificación oficial'}
            description={
                'Nuestro equipo sigue revisando su solicitud y al parecer nos hace faltan IMÁGENES de tu identificación oficial, por delante y por detrás'
            }
            model={'idRequested'}
        />
    );
};
export default IdRequest;
