import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import PersonalInfo from './CustomerInfoComponents/PersonalInfo';
import PersonalAddress from './CustomerInfoComponents/PersonalAddress';
import BussinessAddress from './CustomerInfoComponents/BussinessAddress';
import BussinessInfo from './CustomerInfoComponents/BussinessInfo';
import MoralPersonInfo from './CustomerInfoComponents/MoralPersonInfo';
import ScheduleInfo from './CustomerInfoComponents/ScheduleInfo';
import QuoteInformation from './QuoteInformation';
import { getDocuments, resetDocumentsStatus } from '../../../flux/actions/documents-actions';
import DocumentsInfo from './CustomerInfoComponents/DocumentsInfo';
import { Row, Col } from 'react-bootstrap';
import IdInfo from './CustomerInfoComponents/IdInfo';
import AddressDocsInfo from './CustomerInfoComponents/AddressDocsInfo';
import FicoScoreCreditReport from './ApprovalComponents/FicoScoreCreditReport';
import MoralPersonCreditReport from './ApprovalComponents/MoralPersonCreditReport';
import SatInfo from '../../../components/temporaryComponents/SatInfo';
import VideoRecording from '../../../components/temporaryComponents/VideoRecording';
import ActsDocuments from './CustomerInfoComponents/ActsDocuments';
import IdCardAndCertificate from './CustomerInfoComponents/IdCardAndCertificate';

const RegisterData: React.FC = () => {
    const client = useSelector((state: any) => state.clientReducer.client);
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    const clientRef = (params as any)?.id;
    const user = useSelector((state: any) => state.authReducer.user);
    const files = useSelector((state: any) => state.DocumentsReducer.flowDocuments);

    useEffect(() => {
        dispatch(resetDocumentsStatus());
        if (Object.keys(user).length === 0) {
            history.push('/login');
        }
        dispatch(getDocuments(clientRef));
    }, []);

    return (
        <>
            <QuoteInformation />
            <PersonalInfo />
            <Row>
                {client && client.taxRegime === 'PM' && (
                    <Col>
                        <MoralPersonInfo />
                    </Col>
                )}
                <Col>
                    <BussinessInfo />
                </Col>
            </Row>
            <Row>
                <Col>
                    <PersonalAddress />
                </Col>
                <Col>
                    <BussinessAddress />
                </Col>
            </Row>
            <IdInfo />
            <DocumentsInfo />
            <AddressDocsInfo />
            {client.taxRegime === 'PF' ? <FicoScoreCreditReport /> : <MoralPersonCreditReport />}
            <SatInfo />
            <VideoRecording />
            <ScheduleInfo />
            <ActsDocuments />
            <IdCardAndCertificate />
        </>
    );
};
export default RegisterData;
