export enum documentsDescription {
    bankReceipt1 = 'Estado de cuenta con un mes de antigüedad',
    bankReceipt2 = 'Estado de cuenta con dos meses de antigüedad',
    bankReceipt3 = 'Estado de cuenta con tres meses de antigüedad',
    idFront = 'Parte frontal de la identificación oficial',
    idBack = 'Parte trasera de la identificación oficial',
    addressProof = 'Comprobante de domicilio Personal',
    bussinessAddressProof = 'Comprobante de domicilio del Negocio',
    bussinessPhoto1 = 'Fotografía de Fachada',
    bussinessPhoto2 = 'Fotografía del interior',
    bussinessPhoto3 = 'Fotografía del cliente en el negocio',
    faceCapture = 'Fotografía del Prospecto',
    constitutiveAct = 'Acta constitutiva',
    actGrantingPowers = 'Acta de otorgamiento de poderes',
    taxpayerIDCard = 'Cédula de identificación fiscal',
    certificateESignature = 'Constancia de Firma Electrónica Avanzada'
}
