import React, { useEffect, useState, FC, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Datetime from 'react-datetime';
import moment from 'moment';
import { Row, Col, Button, Card, Alert, Modal, Form } from 'react-bootstrap';
import Loader from '../../ScreenLoader/ScreenLoader';
import { getClient } from '../../../flux/actions/client-actions';
import { getBussinessInfo } from '../../../flux/actions/bussinessInfo-actions';
import ModalGeolocation from '../../ModalWarning';

var mobile = require('is-mobile');

interface Props {
    infoRequired: [];
    onSaveForm?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | any;
    title: string;
    description: string;
    showModal?: boolean;
}

let currentStep = 0;

const FormBuilderPreview: FC<Props> = (p) => {
    const { loading } = useSelector((state: any) => state.flowReducer);
    const [payloadArray, setPayloadArray] = useState<any[]>([]);
    const { client } = useSelector((state: any) => state.clientReducer);
    const [auxRender, setAuxRender] = useState(currentStep);

    useEffect(() => {
        let arrayLoader: any = [];

        p.infoRequired.forEach((info: any) => {
            arrayLoader.push({ name: info.name, value: '', type: info.type, options: info.options });
        });

        setPayloadArray(arrayLoader);
    }, []);

    //function to handle the input, change it in base a the id of each element
    const inputHandler = (e: any) => {
        const auxStep = [...payloadArray];
        auxStep[e.target.id] = { name: e.target.name, value: e.target.value, type: 'text', model: auxStep[e.target.id].model };
        setPayloadArray(auxStep);
    };

    //function to handle the select label, change it in base a the selected element
    const selectHandler = (e: any) => {
        const auxStep = [...payloadArray];
        auxStep[e.target.id] = {
            name: auxStep[e.target.id].name,
            value: e.target.value,
            type: 'Select',
            options: auxStep[e.target.id].options,
            model: auxStep[e.target.id].model
        };
        setPayloadArray(auxStep);
    };

    const _renderInput = (info: any, index: number) => {
        switch (info.type) {
            case 'text':
                return (
                    <input
                        type={info.type}
                        className="form-control"
                        id={index.toString()}
                        autoComplete="off"
                        key={index}
                        name={info.name}
                        placeholder={info.name}
                        value={info.value}
                        onChange={inputHandler}
                    />
                );
            case 'Date':
                return (
                    <Datetime
                        timeFormat={false}
                        inputProps={{ placeholder: info.name }}
                        value={moment(info.value).format('L').toString()}
                        key={index}
                        onChange={(e: any) => {
                            const auxStep = [...payloadArray];
                            auxStep[index] = {
                                name: info.name,
                                value: moment(e).format('L').toString(),
                                type: 'Date',
                                model: auxStep[index].model
                            };
                            setPayloadArray(auxStep);
                        }}
                    />
                );

            case 'Select':
                return (
                    <Form.Group style={{ height: 27 }}>
                        <Form.Control
                            as="select"
                            key={index}
                            value={info.value}
                            id={index.toString()}
                            placeholder={info.name}
                            onChange={selectHandler}
                        >
                            <option value={'0'}>{info.name}</option>
                            {info.options.map((option: string) => {
                                return <option value={option}>{option}</option>;
                            })}
                        </Form.Control>
                    </Form.Group>
                );
            case 'location':
                return (
                    <>
                        <ModalGeolocation />
                    </>
                );
            default:
        }
    };

    console.log(loading);

    //Next Button Styles
    const nextButton = useRef<HTMLButtonElement>(null);

    const changeStylesNextBtn = (button: HTMLButtonElement) => {
        button.className = 'btn-border-radius onBoarding-button onBoarding-button-next nextBtn';
    };

    if (loading) {
        return (
            <div className="auth-wrapper align-items-center">
                <Loader isOpen={true} />
            </div>
        );
    }

    return (
        <Col className="d-flex flex-column justify-content-center onBoarding-form">
            <Row>
                <Col sm={12} md={12} className="mt-3">
                    <Card.Title>{p.title}</Card.Title>
                    <div className="divider-title"></div>
                    <Card.Subtitle title={p.title}>{p.description}</Card.Subtitle>
                    <Row className="d-flex flex-row align-items-center justify-content-center step-form">
                        {payloadArray.map((info: any, index: number) => {
                            return (
                                <Col className="d-flex flex-column mt-4" sm={12} md={6}>
                                    {_renderInput(info, index)}
                                    {/* {errors.names && <p className="form-text text-left text-danger">{errors.names}</p>} */}
                                </Col>
                            );
                        })}
                    </Row>
                    <Row className="onBoarding-buttons-content">
                        <Col className="d-flex onBoarding-buttons justify-content-center">
                            <Button
                                className="btn-border-radius onBoarding-button onBoarding-button-next"
                                variant="third"
                                ref={nextButton}
                                onClick={p.onSaveForm}
                            >
                                <dt
                                    onClick={() => {
                                        if (null !== nextButton.current) {
                                            changeStylesNextBtn(nextButton.current);
                                        }
                                    }}
                                >
                                    Continuar <i className={'feather icon-arrow-right'} />
                                </dt>
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    );
};
export default FormBuilderPreview;
