import { Reducer } from 'redux';
import INFORMATION_LIST_CONSTANTS from '../constants/informationList-constants';
import { InformationListAction } from '../types/informationList-types';

export interface InformationListReducerState {
    loading: boolean;
    success: boolean;
    informationList: {};
    approvalSteps: [];
    error: boolean;
    errorStatus?: number;
}

const initialState: InformationListReducerState = {
    loading: false,
    success: false,
    error: false,
    informationList: {},
    approvalSteps: [],
    errorStatus: 0
}

export const informationListReducer: Reducer<InformationListReducerState, InformationListAction> = (state = initialState, action: InformationListAction) => {
    switch (action.type) {
        //----------GET THE INFORMATION LIST OF THE FLOW PROCESS-----------//
        case INFORMATION_LIST_CONSTANTS.GET_INFORMATION_LIST_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0
            }
        
        case INFORMATION_LIST_CONSTANTS.GET_INFORMATION_LIST_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                errorStatus: action.error.data.status,
                loading: false
            }
        
        case INFORMATION_LIST_CONSTANTS.GET_INFORMATION_LIST_SUCCESS:
            return {
                ...state,
                success: true,
                informationList: action.data.informationList,
                errorStatus: 0,
                error: false,
                loading: false,
            }

        //----------GET THE APPROVAL STEPS FOR THE APPROVAL PROCESS-----------//
        case INFORMATION_LIST_CONSTANTS.GET_APPROVAL_STEPS_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0
            }
        
        case INFORMATION_LIST_CONSTANTS.GET_APPROVAL_STEPS_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                errorStatus: action.error.data.status,
                loading: false
            }
        
        case INFORMATION_LIST_CONSTANTS.GET_APPROVAL_STEPS_SUCCESS:
            return {
                ...state,
                success: true,
                approvalSteps: action.data.approvalProcessStep,
                errorStatus: 0,
                error: false,
                loading: false,
            }    
        
        case INFORMATION_LIST_CONSTANTS.RESET_INFORMATION_LIST_STATUS:
            return {
                success: false,
                error: false,
                loading: false,
                informationList: {},
                approvalSteps: [],
                errorStatus: 0,
            }

        default:
            return state;
    }
}