import React from 'react';

function DisableCopyAndPaste() {
  React.useEffect(() => {
    const handleCopyAndPaste = (evt : any) => {
      if (['disabledCP'].includes(evt.target.name)) {
        evt.preventDefault();
      }
    };

    document.addEventListener('copy', handleCopyAndPaste, false);
    document.addEventListener('paste', handleCopyAndPaste, false);
    return () => {
      document.removeEventListener('copy paste', handleCopyAndPaste);
      document.removeEventListener('paste', handleCopyAndPaste);
    };
  }, []);

  return null;
}

export default DisableCopyAndPaste;