import { Dispatch } from "redux";

import { API } from "../api/satws-api";
import SAT_WS_CONSTANTS from "../constants/satws-constants"
import * as types from '../types/satws-types';

//---------------------------------- FETCH ACTIVE CATEGORIES FOR CATALOG --------------------------------------------------
const createCredentialsBegin = (): types.ICreateCredentialsBeggin => {
    return {
        type: SAT_WS_CONSTANTS.CREATE_CREDENTIALS_SAT_WS_BEGIN
    };
}

const createCredentialsError = (e: any): types.ICreateCredentialsError => {
    return {
        type: SAT_WS_CONSTANTS.CREATE_CREDENTIALS_SAT_WS_ERROR,
        error: e
    };
}

const createCredentialsSuccess = (data: any): types.ICreateCredentialsSuccess => {
    return {
        type: SAT_WS_CONSTANTS.CREATE_CREDENTIALS_SAT_WS_SUCCESS,
        data
    };
}

export const createCredentials = (payload: any) => {
    return (dispatch: Dispatch) => {
        dispatch(createCredentialsBegin());
        API.createCredentials(payload)
        .then((response: any) => {
            console.log('data',response.data)
            dispatch(createCredentialsSuccess(response.data));
        }).catch((error: any) => {
            if(error.status === 401){
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('persist:authReducer');
                localStorage.removeItem('persist:root');
                localStorage.removeItem('nubarium:redirected:FaceCapture');
                localStorage.removeItem('nubarium:redirected:RecordMessage');
                window.location.href = "/login";
            }
            dispatch(createCredentialsError(error.response.data));
        });
    }
}

//---------------------------------- OBTAINING CREDENTIALS CREATED --------------------------------------------------

export const getCredentials = () => {
    return (dispatch: Dispatch) => {
        dispatch(createCredentialsBegin());
        API.getCredentials()
        .then((response: any) => {
            dispatch(createCredentialsSuccess(response.data));
        }).catch((error: any) => {
            if(error.status === 401){
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('persist:authReducer');
                localStorage.removeItem('persist:root');
                localStorage.removeItem('nubarium:redirected:FaceCapture');
                localStorage.removeItem('nubarium:redirected:RecordMessage');
                window.location.href = "/login";
            }
            dispatch(createCredentialsError(error.response));
        });
    }
}


//---------------------------------- RESET STATUS OF THE STATE --------------------------------------------------

const resetStatus = () => {
    return {
        type: SAT_WS_CONSTANTS.RESET_SAT_WS_STATUS
    }
}

export const resetUrlTaxInfoStatus = () => {
    return (dispatch: Dispatch) => {
        dispatch(resetStatus());
    }
} 