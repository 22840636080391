import { Dispatch } from 'redux';

import { API } from '../api/documents-api';
import DOCUMENTS_CONSTANTS from '../constants/documents-constants';
import * as types from '../types/documents-types';
import { getClientSuccess } from './client-actions';

//---------------------------------- POST DOCUMENTS INFORMATION --------------------------------------------------
const postDocumentsBegin = (): types.IPostDocumentsBegin => {
    return {
        type: DOCUMENTS_CONSTANTS.POST_DOCUMENTS_BEGIN
    };
};

const postDocumentsError = (e: any): types.IPostDocumentsError => {
    return {
        type: DOCUMENTS_CONSTANTS.POST_DOCUMENTS_ERROR,
        error: e
    };
};

export const postDocumentsSuccess = (data: any): types.IPostDocumentsSuccess => {
    return {
        type: DOCUMENTS_CONSTANTS.POST_DOCUMENTS_SUCCESS,
        data
    };
};

export const postDocuments = (payload: any) => {
    return (dispatch: Dispatch) => {
        dispatch(postDocumentsBegin());
        API.postDocuments(payload)
            .then((response: any) => {
                dispatch(postDocumentsSuccess(response.data));
                dispatch(getClientSuccess(response.data.client));
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = '/login';
                }
                dispatch(postDocumentsError(error.response));
            });
    };
};

//---------------------------------- UPDATE DOCUMENTS INFORMATION --------------------------------------------------
export const updateDocumentsSuccess = (data: any): types.IUpdateDocumentsSuccess => {
    return {
        type: DOCUMENTS_CONSTANTS.UPDATE_DOCUMENTS_SUCCESS,
        data
    };
};

export const updateDocuments = (payload: any) => {
    return (dispatch: Dispatch) => {
        dispatch(postDocumentsBegin());
        API.updateDocuments(payload)
            .then((response: any) => {
                dispatch(updateDocumentsSuccess(response.data));
                dispatch(getClientSuccess(response.data.client));
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = '/login';
                }
                dispatch(postDocumentsError(error.response));
            });
    };
};

//---------------------------------- UPDATE ID DOCUMENTS --------------------------------------------------
export const updateIdSuccess = (data: any): types.IUpdateDocumentsSuccess => {
    return {
        type: DOCUMENTS_CONSTANTS.UPDATE_DOCUMENTS_SUCCESS,
        data
    };
};

export const uploadId = (payload: any) => {
    return (dispatch: Dispatch) => {
        dispatch(postDocumentsBegin());
        API.updateIdDocs(payload)
            .then((response: any) => {
                dispatch(updateIdSuccess(response.data));
                dispatch(getClientSuccess(response.data.client));
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = '/login';
                }
                dispatch(postDocumentsError(error.response));
            });
    };
};

//---------------------------------- UPDATE SCHEDULE PHOTOS --------------------------------------------------
export const updateSchedulePhotosSuccess = (data: any): types.IUpdateDocumentsSuccess => {
    return {
        type: DOCUMENTS_CONSTANTS.UPDATE_DOCUMENTS_SUCCESS,
        data
    };
};

export const updateSchedulePhotos = (payload: any) => {
    return (dispatch: Dispatch) => {
        dispatch(postDocumentsBegin());
        API.updateSchedulePhotos(payload)
            .then((response: any) => {
                dispatch(updateSchedulePhotosSuccess(response.data));
                dispatch(getClientSuccess(response.data.client));
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = '/login';
                }
                dispatch(postDocumentsError(error.response));
            });
    };
};

//---------------------------------- GET DOCUMENTS INFORMATION --------------------------------------------------
const getDocumentsBegin = (): types.IGetDocumentsBegin => {
    return {
        type: DOCUMENTS_CONSTANTS.GET_DOCUMENTS_BEGIN
    };
};

const getDocumentsError = (e: any): types.IGetDocumentsError => {
    return {
        type: DOCUMENTS_CONSTANTS.GET_DOCUMENTS_ERROR,
        error: e
    };
};

const getDocumentsSuccess = (data: any): types.IGetDocumentsSuccess => {
    return {
        type: DOCUMENTS_CONSTANTS.GET_DOCUMENTS_SUCCESS,
        data
    };
};

export const getDocuments = (userRef: string) => {
    return (dispatch: Dispatch) => {
        dispatch(getDocumentsBegin());
        API.getDocuments(userRef)
            .then((response: any) => {
                dispatch(getDocumentsSuccess(response.data));
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = '/login';
                }
                dispatch(getDocumentsError(error.response));
            });
    };
};

//---------------------------------- GET ID --------------------------------------------------
const getIdSuccess = (data: any): types.IGetIdSuccess => {
    return {
        type: DOCUMENTS_CONSTANTS.GET_ID_SUCCESS,
        data
    };
};

export const getId = (clientRef: string) => {
    return (dispatch: Dispatch) => {
        dispatch(getDocumentsBegin());
        API.getId(clientRef)
            .then((response: any) => {
                dispatch(getIdSuccess(response.data));
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = '/login';
                }
                dispatch(getDocumentsError(error.response));
            });
    };
};
//----------------------------------    ACTIONS TO REQUEST DOCUMENTS --------------------------------------------------
const requestDocumentsBegin = (): types.IRequestDocumentsBegin => {
    return {
        type: DOCUMENTS_CONSTANTS.REQUEST_DOCUMENTS_BEGIN
    };
};

const requestDocumentsError = (e: any): types.IRequestDocumentsError => {
    return {
        type: DOCUMENTS_CONSTANTS.REQUEST_DOCUMENTS_ERROR,
        error: e
    };
};

const requestDocumentsSuccess = (data: any): types.IRequestDocumentsSuccess => {
    return {
        type: DOCUMENTS_CONSTANTS.REQUEST_DOCUMENTS_SUCCESS,
        data
    };
};

export const requestDocuments = (payload: any) => {
    return (dispatch: Dispatch) => {
        dispatch(requestDocumentsBegin());
        API.requestDocuments(payload)
            .then((response: any) => {
                dispatch(requestDocumentsSuccess(response.data));
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = '/login';
                }
                dispatch(requestDocumentsError(error.response));
            });
    };
};

//----------------------------------    ACTIONS TO REQUEST DOCUMENTS --------------------------------------------------
const requestIdSuccess = (data: any): types.IRequestIdSuccess => {
    return {
        type: DOCUMENTS_CONSTANTS.REQUEST_ID_SUCCESS,
        data
    };
};

export const requestId = (payload: any) => {
    return (dispatch: Dispatch) => {
        dispatch(requestDocumentsBegin());
        API.requestId(payload)
            .then((response: any) => {
                dispatch(requestIdSuccess(response.data));
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = '/login';
                }
                dispatch(requestDocumentsError(error.response));
            });
    };
};

//----------------------------------    ACTIONS TO RESET DOCUMENTS --------------------------------------------------
const resetStatus = () => {
    return {
        type: DOCUMENTS_CONSTANTS.RESET_DOCUMENTS_STATUS
    };
};

export const resetDocumentsStatus = () => {
    return (dispatch: Dispatch) => {
        dispatch(resetStatus());
    };
};
