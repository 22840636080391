enum BUSSINESS_INFO_CONSTANTS {
    POST_BUSSINESS_INFO_BEGIN = "POST_BUSSINESS_INFO_BEGIN",
    POST_BUSSINESS_INFO_ERROR = "POST_BUSSINESS_INFO_ERROR",
    POST_BUSSINESS_INFO_SUCCESS = "POST_BUSSINESS_INFO_SUCCESS",
    
    GET_BUSSINESS_INFO_BEGIN = "GET_BUSSINESS_INFO_BEGIN",
    GET_BUSSINESS_INFO_ERROR = "GET_BUSSINESS_INFO_ERROR",
    GET_BUSSINESS_INFO_SUCCESS = "GET_BUSSINESS_INFO_SUCCESS",

    RESET_BUSSINESS_INFO_STATUS = "RESET_BUSSINESS_INFO_STATUS"
};

export default BUSSINESS_INFO_CONSTANTS;