import React, { useEffect, useRef } from 'react';
import { Row, Col, Card } from 'react-bootstrap';

const Listas: React.FC = () => {

    return (
        <>
            <div className="prospects-custom">
                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Body></Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};
export default Listas;
