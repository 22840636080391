import axios from 'axios';

const API_URL = process.env.REACT_APP_BASE_URL;

const postBussinesInfo = (payload: any) => {
    return axios.post(`${API_URL}bussiness-information`, payload);
}

const getBussinessInfo = (id: string) => {
    return axios.get(`${API_URL}bussiness-information/${id}`);
}

export const API = {
    postBussinesInfo,
    getBussinessInfo
}