import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Table, ButtonToolbar, Button, Modal, Form, ToggleButtonGroup, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { TiArrowUnsorted } from 'react-icons/ti';
import { getPaginationQuotes, postEmptyQuote } from '../../flux/actions/quote-actions';
import moment from 'moment';
import UserModel from '../../models/user-model';
import validator from 'validator';
import { QuoteStatus } from '../../constants/quoteStatus-constants';
import { signup, resetClientStatus } from '../../flux/actions/client-actions';
import { refreshToken } from '../../flux/actions/refreshToken-action';
import Loader from '../../components/LoaderFile/Loader';
import { TextField, Button as ButtonMui, Stack } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider, esES } from '@mui/x-date-pickers';

const Prospects: React.FC = () => {
    //redux const
    const history = useHistory();
    const dispatch = useDispatch();
    const [showModalNewClient, setShowModalNewClient] = useState(false);
    const client = useSelector((state: any) => state.clientReducer.client);
    const clientReducer = useSelector((state: any) => state.clientReducer);
    let [date, setDateValue] = useState('');
    const user: UserModel = useSelector((state: any) => state.authReducer.user);
    const users: any = useSelector((state: any) => state.quoteReducer.users);
    const credits: any = useSelector((state: any) => state.quoteReducer.credits);
    const dateOptions = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
    let { page, loading }: any = useSelector((state: any) => state.quoteReducer.pages);

    //Local Variables
    const [names, setNames] = useState('');
    const [lastName1, setlastName1] = useState('');
    const [lastName2, setlastName2] = useState('');
    const [phoneNumber, setPhoneNuber] = useState('');
    const [email, setEmail] = useState('');
    const [CURP, setCURP] = useState('');

    //Errors to show
    const [errors, setErrors] = useState({
        names: '',
        lastName1: '',
        lastName2: '',
        phoneNumber: '',
        email: '',
        birthDate: '',
        CURP: ''
    });

    const theme = {
        spacing: 8
    };

    useEffect(() => {
        if (Object.keys(user).length === 0) {
            history.push('admin/login');
        }
        dispatch(getPaginationQuotes(page));
        dispatch(resetClientStatus());
        dispatch(
            refreshToken({
                token: localStorage.getItem('token'),
                refreshToken: localStorage.getItem('refreshToken')
            })
        );

        setTimeout(() => {
            let buttonPage = document.querySelectorAll('.buttonPage');

            // if (buttonPage.length === 0) {
            //     window.location.reload();
            // }
        }, 1000);
    }, [dispatch, history, page, user]);

    useEffect(() => {
        if (clientReducer.errorMessage || clientReducer.curpMessage) {
            setErrors({
                ...errors,
                email: clientReducer.errorMessage && 'Este email ya ha sido registrado.',
                CURP: clientReducer.curpMessage && 'Este CURP ya ha sido registrado.'
            });
        } else {
            if (client.id) {
                //Generating new empty Quote for new Client.
                const payload = {
                    clientRef: client.id,
                    planRef: 0,
                    promotedBy: user.id
                };
                dispatch(postEmptyQuote(payload));
                setTimeout(() => {
                    dispatch(getPaginationQuotes(page));
                    dispatch(resetClientStatus());
                    setShowModalNewClient(false);
                    cleanForm();
                    history.push('/admin/prospectos');
                }, 1000);
            }
        }
    }, [client, clientReducer.curpMessage, clientReducer.errorMessage]);

    //Select Option Table Variables
    let tbodyTable = document.querySelector('tbody');
    let trTable = document.querySelectorAll('.creditTR');
    let selectOption: any = document.querySelector('select');
    let buttonsPagination = document.querySelector('.buttonsPagination');
    let pageNumber = 1;

    //Sume Page
    const sumPage = () => {
        let selectValue = Number(selectOption?.value);
        let pages = trTable.length / selectValue;
        let buttonPage: any = document.querySelectorAll('.buttonPage');
        let buttonPageActive: any = document.querySelector('.activePage');
        pageNumber = Number(buttonPageActive?.innerText);

        if (pageNumber < pages) {
            if (null !== buttonPage) {
                buttonPage[pageNumber].click();
            }

            if (pageNumber >= 1) {
                pageNumber = pageNumber + 1;
            } else {
                pageNumber = 1;
            }
        }
    };

    //Rest Page
    const restPage = () => {
        let buttonPage: any = document.querySelectorAll('.buttonPage');
        let buttonPageActive: any = document.querySelector('.activePage');

        if (null !== buttonPageActive) {
            pageNumber = Number(buttonPageActive.innerText);
        }

        if (pageNumber > 1) {
            if (buttonPage[pageNumber] !== undefined || null !== buttonPage) {
                pageNumber = pageNumber - 2;
                buttonPage[pageNumber].click();
            }
        }
    };

    //Change Option Value and Pagination
    const changeValue = () => {
        let selectValue = Number(selectOption?.value);
        let pages = trTable.length / selectValue;
        pageNumber = 1;

        if (null !== tbodyTable) {
            tbodyTable.innerHTML = '';

            for (let i = 0; i < selectValue; i++) {
                if (trTable[i] !== undefined) {
                    tbodyTable.innerHTML += trTable[i].innerHTML;
                }
            }
        }

        //Pagination
        if (null !== buttonsPagination) {
            buttonsPagination.innerHTML = '';

            for (let i = 0; i < pages; i++) {
                buttonsPagination.innerHTML += `<button class="buttonPage">${i + 1}</button>`;
            }
        }

        let buttonPage = document.querySelectorAll('.buttonPage');
        buttonPage.forEach((button) => {
            if (button.textContent === '1') {
                button.className = 'buttonPage activePage';
            }

            button.addEventListener('click', () => {
                let buttonPageClick = Number(button.textContent);
                let activeButton = document.querySelectorAll('.activePage');

                let notFoundSearch = document.querySelector('.notFoundSearch');
                let searchInput: HTMLInputElement | any = document.querySelector('.search');

                searchInput.value = '';
                notFoundSearch?.setAttribute('style', 'display: none');

                button.className = 'buttonPage activePage';

                activeButton.forEach((active) => {
                    if (active.className === 'buttonPage activePage') {
                        active.className = 'buttonPage';
                    } else {
                        active.className = 'buttonPage activePage';
                    }
                });

                if (null !== tbodyTable) {
                    tbodyTable.innerHTML = '';
                    let iterationValue;

                    if (buttonPageClick > 1) {
                        iterationValue = selectValue * buttonPageClick - selectValue;
                    } else {
                        iterationValue = 0;
                    }

                    for (let i = iterationValue; i < selectValue * buttonPageClick; i++) {
                        if (trTable[i] !== undefined) {
                            tbodyTable.innerHTML += trTable[i].innerHTML;
                        }
                    }
                }
            });
        });

        let notFoundSearch = document.querySelector('.notFoundSearch');
        let searchInput: HTMLInputElement | any = document.querySelector('.search');

        searchInput.value = '';
        notFoundSearch?.setAttribute('style', 'display: none');
    };

    //find and return the constant of the quote status and show to the admin a little description of the status
    let descriptionsStatus = {
        inReview:
            'Está pendiente la validación de documentos de estados de cuenta del prospecto. El administrador debe revisar y validar que sean correctos los documentos.',
        approvedBankInfo:
            'Está pendiente la validación de los documentos de comprobantes de domicilio del prospecto.  El administrador debe revisar y validar que sean correctos los documentos.',
        approvedTaxInfo: 'El administrador aprobó y validó la información fiscal que el prospecto proporcionó.',
        approvedAddressInfo:
            'Está pendiente la validación de la identificación oficial (INE) del prospecto.  El administrador debe revisar y validar que sea correcta la identificación del prospecto.',
        approvedIdInfo: 'El administrador aprobó y validó la identificación oficial (INE) que el prospecto proporcionó.',
        pendingUploadBankFiles: 'El prospecto aún no ha subido los documentos de estados de cuenta que se le solicitan.',
        pendingUploadAddressFiles: 'El prospecto aún no ha subido los documentos comprobantes de domicilio que se le solicitan.',
        pendingUploadIdFiles: 'El prospecto aún no ha subido su identificación oficial (INE) que se le solicita.',
        requestTaxInfoAutomatic:
            'Al prospecto se le solicita la información fiscal automáticamente. El prospecto debe iniciar sesión en SATWS para cagar su información.',
        requestTaxInfoManual:
            'Al prospecto se le solicita la información fiscal manualmente. El prospecto debe subir la información solicitada.',
        reviewTaxInfo: 'El administrador está revisando y validando la información fiscal que se le solicitó al prospecto.',
        reviewTaxInfoAutomatic: 'El prospecto concluyó la sesión en SATWS, su información fiscal se cargó automáticamente al sistema.',
        rejected: 'Al prospecto no le fue autorizada la solicitud de crédito por parte del administrador.',
        onBoarding:
            'El prospecto continúa en el proceso de llenado de información que es requerida para el trámite de su solicitud en el Onboarding.'
    };

    let descriptionStatus: any;
    const getStatus = (quoteStatus: string) => {
        switch (quoteStatus) {
            case 'inReview':
                descriptionStatus = descriptionsStatus.inReview;
                return QuoteStatus.inReview;
            case 'approvedBankInfo':
                descriptionStatus = descriptionsStatus.approvedBankInfo;
                return QuoteStatus.approvedBankInfo;
            case 'approvedTaxInfo':
                descriptionStatus = descriptionsStatus.approvedTaxInfo;
                return QuoteStatus.approvedTaxInfo;
            case 'approvedAddressInfo':
                descriptionStatus = descriptionsStatus.approvedAddressInfo;
                return QuoteStatus.approvedAddressInfo;
            case 'approvedIdInfo':
                descriptionStatus = descriptionsStatus.approvedIdInfo;
                return QuoteStatus.approvedIdInfo;
            case 'pendingUploadBankFiles':
                descriptionStatus = descriptionsStatus.pendingUploadBankFiles;
                return QuoteStatus.pendingUploadBankFiles;
            case 'pendingUploadAddressFiles':
                descriptionStatus = descriptionsStatus.pendingUploadAddressFiles;
                return QuoteStatus.pendingUploadAddressFiles;
            case 'pendingUploadIdFiles':
                descriptionStatus = descriptionsStatus.pendingUploadIdFiles;
                return QuoteStatus.pendingUploadIdFiles;
            case 'requestTaxInfoAutomatic':
                descriptionStatus = descriptionsStatus.requestTaxInfoAutomatic;
                return QuoteStatus.requestTaxInfoAutomatic;
            case 'requestTaxInfoManual':
                descriptionStatus = descriptionsStatus.requestTaxInfoManual;
                return QuoteStatus.requestTaxInfoManual;
            case 'reviewTaxInfo':
                descriptionStatus = descriptionsStatus.reviewTaxInfo;
                return QuoteStatus.reviewTaxInfo;
            case 'reviewTaxInfoAutomatic':
                descriptionStatus = descriptionsStatus.reviewTaxInfoAutomatic;
                return QuoteStatus.reviewTaxInfoAutomatic;
            case 'rejected':
                descriptionStatus = descriptionsStatus.rejected;
                return QuoteStatus.rejected;
            case 'onBoarding':
                descriptionStatus = descriptionsStatus.onBoarding;
                return QuoteStatus.onBoarding;
            default:
                descriptionStatus = 'Estatus pendiente de asignar';
                return 'Estatus pendiente de asignar';
        }
    };

    //Order Table
    const sortTable = (n: number) => {
        let table: HTMLTableElement | null = document.querySelector('table');
        let rows,
            i,
            tableElement1,
            tableElement2,
            count = 0;
        let switching = true;
        let Switch;

        let direction = 'ascending';

        while (switching) {
            switching = false;

            if (null !== table) {
                let rows = table.rows;

                for (i = 1; i < rows.length - 1; i++) {
                    Switch = false;

                    tableElement1 = rows[i].getElementsByTagName('TD')[n];
                    tableElement2 = rows[i + 1].getElementsByTagName('TD')[n];

                    if (direction === 'ascending') {
                        if (tableElement1.innerHTML.toLowerCase() > tableElement2.innerHTML.toLowerCase()) {
                            Switch = true;
                            break;
                        }
                    } else if (direction === 'descending') {
                        if (tableElement1.innerHTML.toLowerCase() < tableElement2.innerHTML.toLowerCase()) {
                            Switch = true;
                            break;
                        }
                    }
                }

                if (Switch) {
                    rows[i].parentNode?.insertBefore(rows[i + 1], rows[i]);
                    switching = true;
                    count++;
                } else {
                    if (count === 0 && direction === 'ascending') {
                        direction = 'descending';
                        switching = true;
                    }
                }
            }
        }
    };

    //Order Table Number
    const sortTableNumber = (n: number) => {
        let table: HTMLTableElement | null = document.querySelector('table');
        let rows,
            i,
            tableElement1,
            tableElement2,
            count = 0;
        let switching = true;
        let Switch;

        let direction = 'ascending';

        while (switching) {
            switching = false;

            if (null !== table) {
                let rows = table.rows;

                for (i = 1; i < rows.length - 1; i++) {
                    Switch = false;

                    tableElement1 = rows[i].getElementsByTagName('TD')[n];
                    tableElement2 = rows[i + 1].getElementsByTagName('TD')[n];

                    if (direction === 'ascending') {
                        if (parseInt(tableElement1.innerHTML) > parseInt(tableElement2.innerHTML)) {
                            Switch = true;
                            break;
                        }
                    } else if (direction === 'descending') {
                        if (parseInt(tableElement1.innerHTML) < parseInt(tableElement2.innerHTML)) {
                            Switch = true;
                            break;
                        }
                    }
                }

                if (Switch) {
                    rows[i].parentNode?.insertBefore(rows[i + 1], rows[i]);
                    switching = true;
                    count++;
                } else {
                    if (count === 0 && direction === 'ascending') {
                        direction = 'descending';
                        switching = true;
                    }
                }
            }
        }
    };

    //Order Table Currency Number
    const sortTableCurrency = (n: number) => {
        let table: HTMLTableElement | null = document.querySelector('table');
        let rows,
            i,
            tableElement1,
            tableElement2,
            count = 0;
        let switching = true;
        let Switch;

        let direction = 'ascending';

        while (switching) {
            switching = false;

            if (null !== table) {
                let rows = table.rows;

                for (i = 1; i < rows.length - 1; i++) {
                    Switch = false;

                    tableElement1 = rows[i].getElementsByTagName('TD')[n];
                    tableElement2 = rows[i + 1].getElementsByTagName('TD')[n];

                    if (direction === 'ascending') {
                        if (
                            Number(tableElement1.innerHTML.replace(/[^0-9.-]+/g, '')) >
                            Number(tableElement2.innerHTML.replace(/[^0-9.-]+/g, ''))
                        ) {
                            Switch = true;
                            break;
                        }
                    } else if (direction === 'descending') {
                        if (
                            Number(tableElement1.innerHTML.replace(/[^0-9.-]+/g, '')) <
                            Number(tableElement2.innerHTML.replace(/[^0-9.-]+/g, ''))
                        ) {
                            Switch = true;
                            break;
                        }
                    }
                }

                if (Switch) {
                    rows[i].parentNode?.insertBefore(rows[i + 1], rows[i]);
                    switching = true;
                    count++;
                } else {
                    if (count === 0 && direction === 'ascending') {
                        direction = 'descending';
                        switching = true;
                    }
                }
            }
        }
    };

    //Order Table Date
    const sortTableDate = (n: number) => {
        let table: HTMLTableElement | null = document.querySelector('table');
        let rows,
            i,
            tableElement1,
            tableElement2,
            count = 0;
        let switching = true;
        let Switch;

        let direction = 'ascending';

        while (switching) {
            switching = false;

            if (null !== table) {
                let rows = table.rows;

                for (i = 1; i < rows.length - 1; i++) {
                    Switch = false;

                    tableElement1 = rows[i].getElementsByTagName('TD')[n];
                    tableElement2 = rows[i + 1].getElementsByTagName('TD')[n];

                    let tableElementDate1 = tableElement1.innerHTML.split('/', 3);
                    let tableElementDate2 = tableElement2.innerHTML.split('/', 3);

                    let tableArrayDate1 = tableElementDate1.reverse();
                    let tableArrayDate2 = tableElementDate2.reverse();

                    let tableStringDate1 = tableArrayDate1[0] + tableArrayDate1[1] + tableArrayDate1[2];
                    let tableStringDate2 = tableArrayDate2[0] + tableArrayDate2[1] + tableArrayDate2[2];

                    if (direction === 'ascending') {
                        if (Number(tableStringDate1) > Number(tableStringDate2)) {
                            Switch = true;
                            break;
                        }
                    } else if (direction === 'descending') {
                        if (Number(tableStringDate1) < Number(tableStringDate2)) {
                            Switch = true;
                            break;
                        }
                    }
                }

                if (Switch) {
                    rows[i].parentNode?.insertBefore(rows[i + 1], rows[i]);
                    switching = true;
                    count++;
                } else {
                    if (count === 0 && direction === 'ascending') {
                        direction = 'descending';
                        switching = true;
                    }
                }
            }
        }
    };

    //Search Table
    const searchTable = () => {
        let i, txtValue, txtValue2;
        let filter: any;
        let tr: any;
        let td: any;
        let td2: any;
        let input: HTMLInputElement | any;
        let table: HTMLTableElement | any;

        input = document.querySelector('.search');
        filter = input.value.toUpperCase();
        table = document.querySelector('table');
        tr = table.getElementsByTagName('tr');
        let notFoundSearch = document.querySelector('.notFoundSearch');

        for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName('td')[1];
            td2 = tr[i].getElementsByTagName('td')[2];

            if (td && td2) {
                txtValue = td.textContent || td.innerText;
                txtValue2 = td2.textContent || td2.innerText;

                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = '';
                } else if (txtValue2.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = '';
                } else {
                    tr[i].style.display = 'none';
                }
            }

            let trStyle = document.querySelectorAll(`tr[style="display: none;"]`);

            if (trStyle.length + 1 === tr.length) {
                notFoundSearch?.removeAttribute('style');
            } else {
                notFoundSearch?.setAttribute('style', 'display: none');
            }
        }
    };

    //Show Hidden Column
    const productCheck = useRef<HTMLInputElement>(null);
    const taxtCheck = useRef<HTMLInputElement>(null);
    const dateCheck = useRef<HTMLInputElement>(null);
    const requestCheck = useRef<HTMLInputElement>(null);
    const monthsCheck = useRef<HTMLInputElement>(null);
    const monthlyCheck = useRef<HTMLInputElement>(null);
    const statusCheck = useRef<HTMLInputElement>(null);

    let products = document.querySelectorAll('.showProduct');
    let taxts = document.querySelectorAll('.showTaxt');
    let dates = document.querySelectorAll('.showDate');
    let requests = document.querySelectorAll('.showRequest');
    let months = document.querySelectorAll('.showMonth');
    let monthly = document.querySelectorAll('.showMonthly');
    let statusAll = document.querySelectorAll('.showStatus');

    const showHidden = async (checkboxInput: HTMLInputElement, showElement: NodeListOf<Element>) => {
        if (checkboxInput.checked) {
            showElement.forEach((element) => {
                element.setAttribute('style', 'display: none');
            });
        } else {
            showElement.forEach((element) => {
                element.removeAttribute('style');
            });
        }
        await changeValue();
    };

    // Format the above price dollar currency
    let pesosMX = Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
        useGrouping: true,
        maximumSignificantDigits: 3
    });

    //local function to validate with a normal expression the CURP
    const validateCURP = (curp: string) => {
        curp.toUpperCase();
        var re =
                /^[A-Z]{4}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/,
            validado = curp.match(re);

        if (!validado)
            //Coincide con el formato general?
            return false;
        return true; //Validado
    };

    function generatePassword(length: number): string {
        const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let pwd = '';

        for (let i = 0, n = charset.length; i < length; i++) {
            pwd += charset.charAt(Math.floor(Math.random() * n));
        }

        return pwd;
    }

    //Clean Form
    const cleanForm = () => {
        setNames('');
        setlastName1('');
        setlastName2('');
        setPhoneNuber('');
        setEmail('');
        setCURP('');
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const dateMoment = moment(date, 'dddd, D [de] MMM [de] YYYY, H:mm:ss');
        const bDate = dateMoment.toDate();
        const range = moment().diff(dateMoment, 'years');
        const errorName = names === '' ? 'Campo requerido' : '';
        const errorlastName1 = lastName1 === '' ? 'Campo requerido' : '';
        const errorlastName2 = lastName2 === '' ? 'Campo requerido' : '';
        const errorEmail = validator.isEmail(email) ? '' : 'Correo inválido';
        const errorBirthday = range >= 18 ? '' : 'No eres mayor de edad';
        const errorPhone = validator.isMobilePhone(phoneNumber, 'es-MX') ? '' : 'Teléfono inválido';
        const errorCURP = validateCURP(CURP) ? '' : 'CURP no válida';
        const password = generatePassword(8);
        const confirmPassword = password;

        setErrors({
            ...errors,
            names: errorName,
            email: errorEmail,
            birthDate: errorBirthday,
            phoneNumber: errorPhone,
            lastName1: errorlastName1,
            lastName2: errorlastName2,
            CURP: errorCURP
        });

        const correctedPassword = password.trim();
        const correctedEmail = email.trim();
        const correctedconfirmPassword = confirmPassword.trim();

        if (errorName || errorEmail || errorBirthday || errorPhone || errorlastName1 || errorlastName2 || errorCURP) return;
        dispatch(
            signup({
                client: {
                    names,
                    lastName1,
                    lastName2,
                    email: correctedEmail,
                    phone: phoneNumber,
                    password: correctedPassword,
                    confirmPassword: correctedconfirmPassword,
                    birthDate: new Date(bDate),
                    CURP,
                    role: 'customer',
                    promotedBy: user.id
                }
            })
        );
    };
    //Load Fist Time
    // window.onload = async () => {
    //     await setTimeout(() => changeValue(), 500);
    // }

    return (
        <>
            <div className="prospects">
                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Body>
                                <div className="prospects-header">
                                    <h2 className="prospects-title">Total de prospectos</h2>
                                    <div className="prospects-show">
                                        <button>
                                            <i className="feather icon-eye" /> Ocultar
                                        </button>
                                        <div className="prospects-show-card">
                                            <div className="prospects-show-item">
                                                <input
                                                    type="checkbox"
                                                    ref={productCheck}
                                                    onClick={() => {
                                                        if (null !== productCheck.current) {
                                                            showHidden(productCheck.current, products);
                                                        }
                                                    }}
                                                />
                                                <label>Producto</label>
                                            </div>
                                            <div className="prospects-show-item">
                                                <input
                                                    type="checkbox"
                                                    ref={taxtCheck}
                                                    onClick={() => {
                                                        if (null !== taxtCheck.current) {
                                                            showHidden(taxtCheck.current, taxts);
                                                        }
                                                    }}
                                                />
                                                <label>Persona</label>
                                            </div>
                                            <div className="prospects-show-item">
                                                <input
                                                    type="checkbox"
                                                    ref={dateCheck}
                                                    onClick={() => {
                                                        if (null !== dateCheck.current) {
                                                            showHidden(dateCheck.current, dates);
                                                        }
                                                    }}
                                                />
                                                <label>Fecha</label>
                                            </div>
                                            <div className="prospects-show-item">
                                                <input
                                                    type="checkbox"
                                                    ref={requestCheck}
                                                    onClick={() => {
                                                        if (null !== requestCheck.current) {
                                                            showHidden(requestCheck.current, requests);
                                                        }
                                                    }}
                                                />
                                                <label>Solicitado</label>
                                            </div>
                                            <div className="prospects-show-item">
                                                <input
                                                    type="checkbox"
                                                    ref={monthsCheck}
                                                    onClick={() => {
                                                        if (null !== monthsCheck.current) {
                                                            showHidden(monthsCheck.current, months);
                                                        }
                                                    }}
                                                />
                                                <label>Plazos</label>
                                            </div>
                                            <div className="prospects-show-item">
                                                <input
                                                    type="checkbox"
                                                    ref={monthlyCheck}
                                                    onClick={() => {
                                                        if (null !== monthlyCheck.current) {
                                                            showHidden(monthlyCheck.current, monthly);
                                                        }
                                                    }}
                                                />
                                                <label>Mensualidad</label>
                                            </div>
                                            <div className="prospects-show-item">
                                                <input
                                                    type="checkbox"
                                                    ref={statusCheck}
                                                    onClick={() => {
                                                        if (null !== statusCheck.current) {
                                                            showHidden(statusCheck.current, statusAll);
                                                        }
                                                    }}
                                                />
                                                <label>Status</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="prospects-search">
                                        <input
                                            type="text"
                                            className="form-control search"
                                            onChange={() => searchTable()}
                                            placeholder="Buscar..."
                                        />
                                        <i className="feather icon-search" onClick={() => searchTable()} />
                                    </div>
                                    <div className="client-">
                                        <Button
                                            className="users-button"
                                            onClick={() => {
                                                setShowModalNewClient(true);
                                            }}
                                        >
                                            Nuevo Prospecto <i className="feather icon-plus-circle" />
                                        </Button>
                                    </div>
                                </div>
                                <Table striped responsive className="prospects-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <button onClick={() => sortTableNumber(0)}>
                                                    Folio
                                                    <TiArrowUnsorted />
                                                </button>
                                            </th>
                                            <th>
                                                <button onClick={() => sortTable(1)}>
                                                    Prospecto
                                                    <TiArrowUnsorted />
                                                </button>
                                            </th>
                                            <th>
                                                <button onClick={() => sortTable(2)}>
                                                    CURP
                                                    <TiArrowUnsorted />
                                                </button>
                                            </th>
                                            <th className="showProduct">
                                                <button onClick={() => sortTable(3)}>
                                                    Producto
                                                    <TiArrowUnsorted />
                                                </button>
                                            </th>
                                            <th className="showTaxt">
                                                <button onClick={() => sortTable(4)}>
                                                    Persona
                                                    <TiArrowUnsorted />
                                                </button>
                                            </th>
                                            <th className="showDate">
                                                <button onClick={() => sortTableDate(5)}>
                                                    Fecha
                                                    <TiArrowUnsorted />
                                                </button>
                                            </th>
                                            <th className="showRequest">
                                                <button onClick={() => sortTableCurrency(6)}>
                                                    Solicitado
                                                    <TiArrowUnsorted />
                                                </button>
                                            </th>
                                            <th className="showMonth">
                                                <button onClick={() => sortTableNumber(7)}>
                                                    Plazos
                                                    <TiArrowUnsorted />
                                                </button>
                                            </th>
                                            <th className="showMonthly">
                                                <button onClick={() => sortTableCurrency(8)}>
                                                    Mensualidad
                                                    <TiArrowUnsorted />
                                                </button>
                                            </th>
                                            <th className="showStatus">
                                                <button onClick={() => sortTable(9)}>
                                                    Status
                                                    <TiArrowUnsorted />
                                                </button>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {credits.length !== 0 &&
                                            users.length !== 0 &&
                                            credits.map((quote: any) => {
                                                const userIndex = users.findIndex((object: any) => {
                                                    return object._id === quote.clientRef;
                                                });
                                                return (
                                                    <tr className="creditTR">
                                                        <td>{quote?.folio}</td>
                                                        <td>
                                                            <div className="d-inline-block align-middle">
                                                                <div className="d-inline-block">
                                                                    <h3 className="table-name">
                                                                        {users[userIndex] !== undefined && users[userIndex].names}
                                                                        <a href={`/admin/perfil-cliente/${quote.clientRef}`}>
                                                                            <i className="feather icon-external-link" />
                                                                        </a>
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{users[userIndex] !== undefined && users[userIndex].CURP}</td>
                                                        <td className="showProduct">
                                                            {quote.planRef === '1' ? 'Kualinet Express' : 'Kualinet Plus'}
                                                        </td>
                                                        <td className="showTaxt">
                                                            {users[userIndex]?.taxRegime === 'PF' ? 'Física' : 'Moral'}
                                                        </td>
                                                        <td className="showDate">{moment(quote.dateRequested).format('L')}</td>
                                                        <td className="showRequest">{pesosMX.format(Number(quote.amount))}</td>
                                                        <td className="showMonth">{quote.monts}</td>
                                                        <td className="showMonthly">{pesosMX.format(Number(quote.montlyPayment))}</td>
                                                        <td className="showStatus">
                                                            {quote.status === 'rejected' ? (
                                                                <span
                                                                    className="badge badge-light-danger tooltip-status"
                                                                    style={{ fontSize: '0.75rem' }}
                                                                >
                                                                    {getStatus(quote.status)}
                                                                    <i className="feather icon-help-circle" />
                                                                    <div className="tooltip-statusDescription">
                                                                        <h5>{descriptionStatus}</h5>
                                                                    </div>
                                                                </span>
                                                            ) : quote.status === 'approvedBankInfo' ||
                                                              quote.status === 'approvedTaxInfo' ||
                                                              quote.status === 'approvedAddressInfo' ||
                                                              quote.status === 'approvedIdInfo' ? (
                                                                <span
                                                                    className="badge badge-light-success tooltip-status"
                                                                    style={{ fontSize: '0.75rem' }}
                                                                >
                                                                    {getStatus(quote.status)}
                                                                    <i className="feather icon-help-circle" />
                                                                    <div className="tooltip-statusDescription">
                                                                        <h5>{descriptionStatus}</h5>
                                                                    </div>
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    className="badge badge-light-info tooltip-status"
                                                                    style={{ fontSize: '0.75rem' }}
                                                                >
                                                                    {getStatus(quote.status)}
                                                                    <i className="feather icon-help-circle" />
                                                                    <div className="tooltip-statusDescription">
                                                                        <h5>{descriptionStatus}</h5>
                                                                    </div>
                                                                </span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                    <tfoot />
                                </Table>
                                <h3 className="notFoundSearch" style={{ display: 'none' }}>
                                    No se encontraron resultados en tu búsqueda...
                                </h3>
                                <Row className="d-flex align-items-center pagination">
                                    <Col className="p-2 col-sm-9 col-md-8 col-xl-9">
                                        <label>Mostrar</label>
                                        <select className="form-control" onChange={() => changeValue()} defaultValue="15">
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="15">15</option>
                                            <option value="20">20</option>
                                            <option value="25">25</option>
                                            <option value="30">30</option>
                                        </select>
                                    </Col>
                                    <Col className="p-2  col-sm-3">
                                        <ButtonToolbar>
                                            <ToggleButtonGroup type="radio" name="options">
                                                <button onClick={() => restPage()}>
                                                    <i className="feather icon-chevron-left" />
                                                </button>
                                                <div className="buttonsPagination"></div>
                                                <button onClick={() => sumPage()}>
                                                    <i className="feather icon-chevron-right" />
                                                </button>
                                            </ToggleButtonGroup>
                                        </ButtonToolbar>
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Modal
                                className="users-modal"
                                show={showModalNewClient}
                                onHide={() => {
                                    setShowModalNewClient(!showModalNewClient);
                                }}
                            >
                                <Modal.Header className="border-0">
                                    <Modal.Title className="users-title">Nuevo Prospecto</Modal.Title>
                                </Modal.Header>
                                <Form onSubmit={onSubmit}>
                                    <Modal.Body className="pt-0 pb-0 pl-4 pr-4">
                                        <TextField
                                            fullWidth
                                            id="Name"
                                            label="Nombre (s)"
                                            variant="outlined"
                                            margin="normal"
                                            value={names}
                                            error={!!errors.names}
                                            helperText={errors.names}
                                            autoComplete="off"
                                            onChange={(e) => {
                                                setNames(e.target.value);
                                            }}
                                        />

                                        <TextField
                                            fullWidth
                                            id="Lastnname1"
                                            label="Apellido paterno"
                                            variant="outlined"
                                            margin="normal"
                                            value={lastName1}
                                            error={!!errors.lastName1}
                                            helperText={errors.lastName1}
                                            autoComplete="off"
                                            onChange={(e) => {
                                                setlastName1(e.target.value);
                                                setErrors({ ...errors, lastName1: '' });
                                            }}
                                        />

                                        <TextField
                                            fullWidth
                                            id="Lastnname2"
                                            label="Apellido materno"
                                            variant="outlined"
                                            margin="normal"
                                            value={lastName2}
                                            error={!!errors.lastName2}
                                            helperText={errors.lastName2}
                                            autoComplete="off"
                                            onChange={(e) => {
                                                setlastName2(e.target.value);
                                                setErrors({ ...errors, lastName2: '' });
                                            }}
                                        />

                                        <TextField
                                            fullWidth
                                            id="email"
                                            inputProps={{
                                                type: 'email'
                                            }}
                                            label="Correo electrónico"
                                            variant="outlined"
                                            margin="normal"
                                            value={email}
                                            error={!!errors.email}
                                            helperText={errors.email}
                                            autoComplete="off"
                                            onChange={(e) => {
                                                setEmail(e.target.value.trim());
                                                setErrors({ ...errors, email: '' });
                                            }}
                                        />

                                        <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                            localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
                                        >
                                            <DatePicker
                                                sx={{ mt: 2, mb: 1, width: 1 }}
                                                label="Fecha de nacimiento"
                                                onChange={(newValue: any) => {
                                                    setDateValue(`${newValue._d.toLocaleTimeString('es-ES', dateOptions)}`);
                                                    setErrors({ ...errors, birthDate: '' });
                                                }}
                                            />
                                            {errors.birthDate && (
                                                <p id="passwordHelpBlock" className="text-left text-danger">
                                                    {errors.birthDate}
                                                </p>
                                            )}
                                        </LocalizationProvider>

                                        <TextField
                                            fullWidth
                                            id="cellphone"
                                            label="Teléfono celular"
                                            variant="outlined"
                                            margin="normal"
                                            value={phoneNumber}
                                            error={!!errors.phoneNumber}
                                            helperText={errors.phoneNumber}
                                            inputProps={{
                                                maxLength: 10
                                            }}
                                            autoComplete="off"
                                            onChange={(e) => {
                                                setPhoneNuber(e.target.value.trim());
                                                setErrors({ ...errors, phoneNumber: '' });
                                            }}
                                        />

                                        <TextField
                                            fullWidth
                                            id="CURP"
                                            label="CURP"
                                            variant="outlined"
                                            margin="normal"
                                            autoComplete="off"
                                            inputProps={{
                                                maxLength: 18
                                            }}
                                            style={{ textTransform: 'uppercase' }}
                                            value={CURP}
                                            error={!!errors.CURP}
                                            helperText={errors.CURP}
                                            onChange={(e) => {
                                                setCURP(e.target.value.trim().toLocaleUpperCase());
                                                setErrors({ ...errors, CURP: '' });
                                            }}
                                        />
                                        <a href="https://www.gob.mx/curp" target="__blank">
                                            <b>Da clic para consultar CURP</b>
                                        </a>
                                    </Modal.Body>
                                    <Modal.Footer className="border-0 pb-3 pr-2 mt-3">
                                        <Stack spacing={2} direction="row">
                                            <ButtonMui
                                                variant="contained"
                                                size="large"
                                                color="error"
                                                className="prospects-buttonDanger"
                                                onClick={() => {
                                                    setShowModalNewClient(false);
                                                }}
                                            >
                                                Cancelar
                                            </ButtonMui>
                                            <ButtonMui variant="contained" type="submit" size="large" className="prospects-button">
                                                Crear Prospecto
                                            </ButtonMui>
                                        </Stack>
                                    </Modal.Footer>
                                </Form>
                            </Modal>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};
export default Prospects;
