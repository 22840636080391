import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import RequestFilesBuilder from '../../../../components/Builders/RequestFilesBuilder';
import { getDocuments, requestDocuments } from '../../../../flux/actions/documents-actions';
import { getCredit, updateStatus } from '../../../../flux/actions/quote-actions';
import { getApprovalProcess } from '../../../../flux/actions/approvalProcess-actions';
import { sendEmailDocumentsApproved, sendEmailDocumentsRequest } from '../../../../flux/actions/otp-actions';

const DocsApproval: React.FC = () => {
    //const to get the params
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    const clientRef = (params as any)?.id;
    const user = useSelector((state: any) => state.authReducer.user);
    const quote: any = useSelector((state: any) => state.quoteReducer.credit);
    const { client } = useSelector((state: any) => state.clientReducer);
    const { approvalProcess } = useSelector((state: any) => state.approvalProcessReducer);
    const files = useSelector((state: any) => state.DocumentsReducer.flowDocuments);
    const [approved, setApproved] = useState<boolean[]>([]);
    //Estados de los modales
    const [requestModal, setRequestModal] = useState(false);
    const [showApproveBankInfo, setShowApproveBankInfo] = useState(false);

    useEffect(() => {
        if (Object.keys(user).length === 0) {
            history.push('/login');
        }
        dispatch(getDocuments(clientRef));
    }, []);

    useEffect(() => {
        Object.keys(quote).length !== 0 && dispatch(getApprovalProcess(clientRef));
    }, [quote]);

    useEffect(() => {
        let aux = [...approved];
        files.forEach((doc: any, index: number) => {
            if (
                doc.type === 'bankReceipt1' ||
                doc.type === 'bankReceipt2' ||
                doc.type === 'bankReceipt3' ||
                doc.type === 'idFront' ||
                doc.type === 'idBack' ||
                doc.type === 'addressProof' ||
                doc.type === 'bussinessAddressProof' ||
                doc.type === 'constitutiveAct' ||
                doc.type === 'actGrantingPowers'
            ) {
                aux[index] = false;
            }
        });
        setApproved(aux);
    }, [files]);

    const handleSwitch = (e: any) => {
        let aux = [...approved];
        aux[Number(e.target.name)] = !aux[Number(e.target.name)];
        setApproved(aux);
    };

    const requestDocs = () => {
        let auxDocs = [...files];
        approved.forEach((isApproved, index) => {
            if (!isApproved) auxDocs[index].file = '';
        });
        const payload = {
            clientRef,
            approved: auxDocs
        };
        dispatch(requestDocuments(payload));
        dispatch(sendEmailDocumentsRequest(client.email, client.names, client.lastName1, client.lastName2));
        setRequestModal(!requestModal);
    };

    function approveDocs() {
        let stepIndex = 0;
        const approvalProcessClient = approvalProcess.steps?.filter((step: any) => {
            return step.clientStatus !== 'generateCreditCircleNIP';
        });
        approvalProcess.steps.filter((step: any, index: number) => {
            if (step.adminStatus === quote.adminStatus) {
                stepIndex = index + 1;
                if (stepIndex < approvalProcess.steps.length) {
                    if (approvalProcess.steps[index].continuousApprovalFlow) {
                        dispatch(
                            updateStatus({
                                clientRef,
                                type: 'adminStatus',
                                status: approvalProcess.steps[stepIndex].adminStatus
                            })
                        );
                    } else {
                        dispatch(
                            updateStatus({
                                clientRef,
                                type: 'updateBothStates',
                                status: approvalProcessClient[stepIndex].clientStatus,
                                adminStatus: approvalProcess.steps[stepIndex].adminStatus
                            })
                        );
                    }
                } else if (stepIndex === approvalProcess.steps.length) {
                    dispatch(
                        updateStatus({
                            clientRef,
                            type: 'adminStatus',
                            status: 'creditProposal'
                        })
                    );
                }
            }
        });
        dispatch(sendEmailDocumentsApproved(client.email, client.names, client.lastName1, client.lastName2));
        setTimeout(() => {
            dispatch(getCredit(clientRef));
        }, 1000);
        setShowApproveBankInfo(!showApproveBankInfo);
    }

    return (
        <>
            <RequestFilesBuilder
                files={files}
                title={'Verificar documentos proporcionados por el prospecto'}
                description="El prospecto ha realizado una solicitud de crédito, es necesario verificar la información acorde al Manual de Normas y Políticas de Crédito. Valoración Aceptable (continua el proceso), No aceptable (se notifica al prospecto no es posible continuar con su solicitud)"
                handleSwitch={handleSwitch}
                onApproveDocs={approveDocs}
                onRequestDocs={requestDocs}
                approved={approved}
                requestModal={requestModal}
                onCloseRequestModal={() => setRequestModal(!requestModal)}
                approvalModal={showApproveBankInfo}
                onCloseApprovalModal={() => setShowApproveBankInfo(!showApproveBankInfo)}
                docType="image"
            />
        </>
    );
};
export default DocsApproval;
