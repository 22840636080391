import { Dispatch } from "redux";
import { API } from "../api/previus-data";
import PREVIUS_DATA_CONSTANTS from "../constants/previusData-constants";
import * as types from '../types/PreviusData-types';

//---------------------------------- POSTING THE PREVIUS DATA --------------------------------------------------
const postPreviusDataBegin = (): types.IPostPreviusDataBegin => {
    return {
        type: PREVIUS_DATA_CONSTANTS.POST_PREVIUS_DATA_BEGIN
    };
}

const postPreviusDataError = (e: any): types.IPostPreviusDataError => {
    return {
        type: PREVIUS_DATA_CONSTANTS.POST_PREVIUS_DATA_ERROR,
        error: e
    };
}

const postPreviusDataSuccess = (data: any): types.IPostPreviusDataSuccess => {
    return {
        type: PREVIUS_DATA_CONSTANTS.POST_PREVIUS_DATA_SUCCESS,
        data
    };
}

export const postPreviusData = (payload: any) => {
    return (dispatch: Dispatch) => {
        dispatch(postPreviusDataBegin());
        API.postCreatePreviusData(payload)
        .then((response: any) => {
            dispatch(postPreviusDataSuccess(response.data));
        }).catch((error: any) => {
            dispatch(postPreviusDataError(error.response));
        });
    }
}

//---------------------------------- GET PERSONAL ADDRESS --------------------------------------------------
const getPreviusDataBegin = (): types.IGetPreviusDataBegin => {
    return {
        type: PREVIUS_DATA_CONSTANTS.GET_PREVIUS_DATA_BEGIN
    };
}

const getPreviusDataError = (e: any): types.IGetPreviusDataError => {
    return {
        type: PREVIUS_DATA_CONSTANTS.GET_PREVIUS_DATA_ERROR,
        error: e
    };
}

const getPreviusDataSuccess = (data: any): types.IGetPreviusDataSuccess => {
    return {
        type: PREVIUS_DATA_CONSTANTS.GET_PREVIUS_DATA_SUCCESS,
        data
    };
}

export const getPreviusData = () => {
    return (dispatch: Dispatch) => {
        dispatch(getPreviusDataBegin());
        API.getPreviusData()
        .then((response: any) => {
            dispatch(getPreviusDataSuccess(response.data));
        }).catch((error: any) => {
            dispatch(getPreviusDataError(error.response));
        });
    }
}

//---------------------------------- RESET THE ADDRESS STATE --------------------------------------------------
const resetStatus = () => {
    return {
        type: PREVIUS_DATA_CONSTANTS.RESET_PREVIUS_DATA_STATUS
    }
}

export const resetPreviusDataStatus = () => {
    return (dispatch: Dispatch) => {
        dispatch(resetStatus());
    }
}