import { Dispatch } from "redux";

import { API } from "../api/unic-api";
import ECONOMICACTIVITIES_CONSTANTS from "../constants/economicAct-constants";
import * as types from '../types/economicAct-types';


const getEconomicActivitiesBeggin = (): types.IGetEconomicActivitiesBegin => {
    return {
        type: ECONOMICACTIVITIES_CONSTANTS.GET_ECONOMICACTIVITY_BEGIN
    };
}

const getEconomicActivitiesError = (e: any): types.IGetEconomicActivitiesError => {
    return {
        type: ECONOMICACTIVITIES_CONSTANTS.GET_ECONOMICACTIVITY_ERROR,
        error: e
    };
}

const getEconomicActivitiesSuccess = (data: any): types.IGetEconomicActivitiesSuccess => {
    return {
        type: ECONOMICACTIVITIES_CONSTANTS.GET_ECONOMICACTIVITY_SUCCESS,
        data
    };
}

export const getEconomicActivities = (activity: string) => {
    return (dispatch: Dispatch) => {
        dispatch(getEconomicActivitiesBeggin());
        API.getEconomicActivities(activity)
        .then((response: any) => {
            dispatch(getEconomicActivitiesSuccess(response.data));
        }).catch((error: any) => {
            if(error.status === 401){
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('persist:authReducer');
                localStorage.removeItem('persist:root');
                localStorage.removeItem('nubarium:redirected:FaceCapture');
                localStorage.removeItem('nubarium:redirected:RecordMessage');
                window.location.href = "/login";
            }
            dispatch(getEconomicActivitiesError(error.response));
        });
    }
}

const resetStatus = () => {
    return {
        type: ECONOMICACTIVITIES_CONSTANTS.RESET_ECONOMICACTIVITY_STATUS
    }
}

export const resetEconomicActivitiesStatus = () => {
    return (dispatch: Dispatch) => {
        dispatch(resetStatus());
;    }
}