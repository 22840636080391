enum APPROVAL_CRITERIA_CONSTANTS {
    POST_APPROVAL_CRITERIA_BEGIN = "POST_APPROVAL_CRITERIA_BEGIN",
    POST_APPROVAL_CRITERIA_ERROR = "POST_APPROVAL_CRITERIA_ERROR",
    POST_APPROVAL_CRITERIA_SUCCESS = "POST_APPROVAL_CRITERIA_SUCCESS",
    
    GET_APPROVAL_CRITERIA_BEGIN = "GET_APPROVAL_CRITERIA_BEGIN",
    GET_APPROVAL_CRITERIA_ERROR = "GET_APPROVAL_CRITERIA_ERROR",
    GET_APPROVAL_CRITERIA_SUCCESS = "GET_APPROVAL_CRITERIA_SUCCESS",

    RESET_APPROVAL_CRITERIA_STATUS = "RESET_APPROVAL_CRITERIA_STATUS"
};

export default APPROVAL_CRITERIA_CONSTANTS;