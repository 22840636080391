import axios from 'axios';

export const interceptor = {
    addInterceptor,
    getToken,
    addToken
};
let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': ''
};
function addInterceptor() {
    getToken()
    axios.interceptors.request.use(
        config => {
            if (!navigator.onLine) {
                Promise.reject({error: "DISCONNECTED"})
            }
            config.headers = headers;
            return config;
        },
        error => Promise.reject(error)
    );
    axios.interceptors.response.use(response => {
        return response;
    },
        error => {
            return new Promise((resolve, reject) => {
                if (error.response.status === 401) {
                    reject({ status: 401, message: 'Unauthorized' });
                }
                reject(error);
            });
        })
}


function getToken() {
    const token = localStorage.getItem('token');
    if (token !== null) {
        headers.Authorization = 'Bearer ' + token;
    }
}

function addToken(token: string) {
    headers.Authorization = 'Bearer ' + token;
    addInterceptor();
}