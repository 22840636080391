enum QUOTE_CONSTANTS {
    POST_QUOTE_BEGIN = "POST_QUOTE_BEGIN",
    POST_QUOTE_ERROR = "POST_QUOTE_ERROR",
    POST_QUOTE_SUCCESS = "POST_QUOTE_SUCCESS",

    POST_EMPTY_QUOTE_BEGIN = "POST_EMPTY_QUOTE_BEGIN",
    POST_EMPTY_QUOTE_ERROR = "POST_EMPTY_QUOTE_ERROR",
    POST_EMPTY_QUOTE_SUCCESS = "POST_EMPTY_QUOTE_SUCCESS",

    GET_CREDITS_BEGIN = "GET_CREDIT_BEGIN",
    GET_CREDIT_ERROR = "GET_CREDIT_ERROR",
    GET_CREDIT_SUCCESS = "GET_CREDIT_SUCCESS",

    GET_QUOTES_PAGINATION_BEGIN = "GET_QUOTES_PAGINATION_BEGIN",
    GET_QUOTES_PAGINATION_ERROR = "GET_QUOTES_PAGINATION_ERROR",
    GET_QUOTES_PAGINATION_SUCCESS = "GET_QUOTES_PAGINATION_SUCCESS",

    REJECT_QUOTE_BEGIN = "REJECT_QUOTE_BEGIN",
    REJECT_QUOTE_ERROR = "REJECT_QUOTE_ERROR",
    REJECT_QUOTE_SUCCESS = "REJECT_QUOTE_SUCCESS",

    FINISH_FLOW_BEGIN = "FINISH_FLOW_BEGIN",
    FINISH_FLOW_ERROR = "FINISH_FLOW_ERROR",
    FINISH_FLOW_SUCCESS = "FINISH_FLOW_SUCCESS",

    UPDATE_STATUS_SUCCESS = "UPDATE_STATUS_SUCCESS",

    RESET_QUOTE_STATUS = "RESET_QUOTE_STATUS"
};

export default QUOTE_CONSTANTS;