import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { sendAuthMail } from '../../../../flux/actions/mail-actions';
import { rejectQuote, updateStatus, getCredit } from '../../../../flux/actions/quote-actions';
import { getClient } from '../../../../flux/actions/client-actions';
import { Card, Table, Row, Button, Modal, Form } from 'react-bootstrap';
import { getDocuments, requestId } from '../../../../flux/actions/documents-actions';
import { sendEmailDocumentsRequest } from '../../../../flux/actions/otp-actions';
import Loader from '../../../../components/LoaderFile/Loader';

const LifeProof: React.FC = () => {
    //const to get the params
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    const clientRef = (params as any)?.id;

    const user = useSelector((state: any) => state.authReducer.user);
    const { loading, client } = useSelector((state: any) => state.clientReducer);
    const quote: any = useSelector((state: any) => state.quoteReducer.credit);

    const files = useSelector((state: any) => state.DocumentsReducer.flowDocuments);
    const { approvalProcess } = useSelector((state: any) => state.approvalProcessReducer);

    const [fileLifeProof, setFileLifeProof] = useState<any>();
    const [approved, setApproved] = useState(false);
    const [faceCaptureIndex, setfaceCaptureIndex] = useState<number>(0);
    const [url, setUrl] = useState<any>();

    const [requestModal, setRequestModal] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);
    const [showApprovalModal, setShowApprovalModal] = useState(false);
    const [showCancelRequest, setShowCancelRequest] = useState(false);

    useEffect(() => {
        if (Object.keys(user).length === 0) {
            history.push('/login');
        }
        dispatch(getClient(clientRef));
        dispatch(getDocuments(clientRef));
    }, []);

    useEffect(() => {
        if (Object.keys(client).length === 0) return;
        if (Object.entries(files).length !== 0) {
            setFileLifeProof(
                files.filter((file: any, index: number) => {
                    if (file.type == 'faceCapture') {
                        setfaceCaptureIndex(index);
                    }
                    return file.type == 'faceCapture';
                })[0]
            );
        }
    }, [client, files]);

    function ApprovefaceCapture() {
        let stepIndex = 0;
        const approvalProcessClient = approvalProcess.steps?.filter((step: any) => {
            return step.clientStatus !== "generateCreditCircleNIP";
        });
        approvalProcess.steps.filter((step: any, index: number) => {
            if (step.adminStatus === quote.adminStatus) {
                stepIndex = index + 1;
                if (stepIndex < approvalProcess.steps.length) {
                    if (approvalProcess.steps[index].continuousApprovalFlow) {
                        dispatch(
                            updateStatus({
                                clientRef,
                                type: 'adminStatus',
                                status: approvalProcess.steps[stepIndex].adminStatus
                            })
                        );
                    } else {
                        dispatch(
                            updateStatus({
                                clientRef,
                                type: 'updateBothStates',
                                status: approvalProcessClient[stepIndex].clientStatus,
                                adminStatus: approvalProcess.steps[stepIndex].adminStatus
                            })
                        );
                    }
                } else if (stepIndex === approvalProcess.steps.length) {
                    dispatch(
                        updateStatus({
                            clientRef,
                            type: 'adminStatus',
                            status: 'creditProposal'
                        })
                    );
                }
            }
        });
        setTimeout(() => {
            dispatch(getCredit(clientRef));
        }, 1000);
        setShowApprovalModal(!showApprovalModal);
    }

    const handleSwitch = () => {
        setApproved(!approved);
    };

    const base64toBlob = (file: any) => {
        const bytes = atob(file);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }
        return new Blob([out], { type: 'image/*' });
    };

    const requestDocs = () => {
        if (!approved) files[faceCaptureIndex].file = '';
        const payload = {
            clientRef,
            status: 'lifeProofPending',
            approved: files
        };
        dispatch(requestId(payload));
        setRequestModal(!requestModal);
        dispatch(getCredit(clientRef));
        dispatch(sendEmailDocumentsRequest(client.email, client.names, client.lastName1, client.lastName2));
    };

    function cancelRequest() {
        dispatch(rejectQuote({ client: client.id }));
        setShowCancelRequest(!showCancelRequest);
        dispatch(getCredit(clientRef));
        dispatch(sendAuthMail({ mailType: 3, email: client.email, name: client.names, lastName1: client.lastName1, lastName2: client.lastName2, folio: quote.folio, amount: quote.amount }));
    }

    return (
        <>
            <Card>
                {Object.keys(quote).length !== 0 && client.faceCapture ? (
                    <Card.Header className="approvalFlow-lifeProof-header">
                        <Card.Title as="h6">El prospecto está realizando la prueba de vida</Card.Title>
                    </Card.Header>
                ) : (
                    <Card.Body className="approvalFlow-lifeProof-body">
                        <Card.Title as="h5">Prueba de vida</Card.Title>
                        <Card.Subtitle as="h6" className="mt-4">
                            Esta es la prueba de vida cargada por el prospecto
                        </Card.Subtitle>
                        <p className="lifeProof-text mt-2">Es necesario validar la Imagen del prospecto.</p>
                        {loading ? (
                            <div className="auth-wrapper align-items-center">
                                <Loader />
                            </div>
                        ) : (
                            <Row className="d-flex flex-nowrap">
                                <Table striped className="table-columned ml-1 mr-2">
                                    <thead>
                                        <tr className="text-center">
                                            <th>Ver</th>
                                            <th>Documento</th>
                                            <th>¿Aprobado?</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fileLifeProof?.type == 'faceCapture' && (
                                            <tr id="0" className="text-center">
                                                <td className="d-flex- flex-column " align={'center'}>
                                                    {fileLifeProof?.name === '' ? (
                                                        ''
                                                    ) : (
                                                        <button
                                                            type="button"
                                                            className="btn btn-third btn-sm btn-border-radius float-center mt-2 doc-showBtn"
                                                            onClick={() => {
                                                                const blob = base64toBlob(fileLifeProof.file);
                                                                const urlAux = URL.createObjectURL(blob);
                                                                setUrl(urlAux);
                                                                setShowImageModal(!showImageModal);
                                                            }}
                                                        >
                                                            <i className={'feather icon-file-text'} />
                                                        </button>
                                                    )}
                                                </td>
                                                <td>
                                                    {/* Cambio de en encabezado segun el archivo requerido si se cumple la condicion ejecuta los diferentes encabezados */}
                                                    <p className="mt-3 align-middle doc-titleDocument">
                                                        <h6 style={{ fontSize: 14 }}>Face Capture</h6>
                                                        {/* Muestra el nombre del Archivo si existe si no envia mensaje de espera de documentos */}
                                                        {fileLifeProof?.name === ''
                                                            ? 'En espera de que el cliente suba sus documentos'
                                                            : fileLifeProof.name}
                                                    </p>
                                                </td>
                                                <td>
                                                    {fileLifeProof.name === '' ? (
                                                        <Form.Group className="mt-3">
                                                            <div className="switch switch-info d-inline m-r-5 m-l-5">
                                                                En espera de que el prospecto realice su prueba de vida
                                                            </div>
                                                        </Form.Group>
                                                    ) : (
                                                        <Form.Group>
                                                            <Form.Label>NO</Form.Label>
                                                            <div className="switch switch-info d-inline m-r-5 m-l-5">
                                                                <Form.Control
                                                                    type="checkbox"
                                                                    id={`unchecked-info-1`}
                                                                    name="7"
                                                                    onChange={handleSwitch}
                                                                />
                                                                <Form.Label htmlFor={`unchecked-info-1`} className="cr" />
                                                            </div>
                                                            <Form.Label>SI</Form.Label>
                                                        </Form.Group>
                                                    )}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Row>
                        )}
                    </Card.Body>
                )}
                {!approved && quote.status === 'confirmLifeProof' && (
                    <Card.Footer className="approvalFlow-lifeProof-footer">
                        <Card.Title as="h5">¿Solicitar al prospecto realizar nuevamente la prueba de vida?</Card.Title>
                        <Button
                            className="btn-border-radius lifeProof-successBtn"
                            variant={'success'}
                            onClick={() => setRequestModal(!requestModal)}
                        >
                            Solicitar
                        </Button>
                    </Card.Footer>
                )}
                {approved && (
                    <Card.Footer className="approvalFlow-lifeProof-footer">
                        <Card.Title as="h5">¿La foto del prospecto es adecuada para continuar?</Card.Title>
                        <Row className="mt-3 ml-3">
                            <Button
                                className="btn-border-radius lifeProof-successBtn"
                                variant={'success'}
                                onClick={() => setShowApprovalModal(!showApprovalModal)}
                            >
                                Si
                            </Button>
                            <Button
                                variant={'danger'}
                                className="ml-3 btn-border-radius lifeProof-alertBtn"
                                onClick={() => {
                                    setShowCancelRequest(!showCancelRequest);
                                }}
                            >
                                No
                            </Button>
                        </Row>
                    </Card.Footer>
                )}
            </Card>
            <Modal show={requestModal} onHide={() => setRequestModal(!requestModal)}>
                <Modal.Header closeButton className="approvalFlow-lifeProofModal-header">
                    <Modal.Title as="h5">Prueba de vida</Modal.Title>
                </Modal.Header>
                <Modal.Body className="approvalFlow-lifeProofModal-body">
                    <p>
                        El archivo seleccionado como NO aprobado se borrará permanentemente del sistema
                        <br />
                        <br />
                        ¿Deseas continuar?
                    </p>
                </Modal.Body>
                <Modal.Footer className="approvalFlow-lifeProof-footer">
                    <Row className="mt-3 mb-3">
                        <Button
                            className="btn-border-radius lifeProof-alertBtn"
                            variant="danger"
                            onClick={() => setRequestModal(!requestModal)}
                        >
                            Cancelar
                        </Button>
                        <Button className="ml-3 btn-border-radius lifeProof-successBtn" variant="secondary" onClick={requestDocs}>
                            Solicitar prueba de vida
                        </Button>
                    </Row>
                </Modal.Footer>
            </Modal>
            <Modal show={showApprovalModal} onHide={() => setShowApprovalModal(!showApprovalModal)}>
                <Modal.Header closeButton className="approvalFlow-lifeProofModal-header">
                    <Modal.Title as="h5">Prueba de vieda</Modal.Title>
                </Modal.Header>
                <Modal.Body className="approvalFlow-lifeProofModal-body">
                    <p>
                        La prueba de vida cargada por el prospecto es adecuada para CONTINUAR con el proceso del crédito
                        <br />
                        <br />
                        ¿Deseas continuar?
                    </p>
                </Modal.Body>
                <Modal.Footer className="approvalFlow-lifeProof-footer">
                    <Row className="mt-3 mb-3">
                        <Button
                            className="btn-border-radius lifeProof-alertBtn"
                            variant="danger"
                            onClick={() => setShowApprovalModal(!showApprovalModal)}
                        >
                            Cancelar
                        </Button>
                        <Button className="ml-3 btn-border-radius lifeProof-successBtn" variant="success" onClick={ApprovefaceCapture}>
                            Aprobar
                        </Button>
                    </Row>
                </Modal.Footer>
            </Modal>
            <Modal show={showCancelRequest} onHide={() => setShowCancelRequest(!showCancelRequest)}>
                <Modal.Header closeButton className="approvalFlow-lifeProofModal-header">
                    <Modal.Title as="h5">
                        La prueba de vida del prospecto NO cumple con la información para la solicitud del crédito
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="approvalFlow-lifeProofModal-body">
                    <p>
                        La prueba de vida proveída por el prospecto no cumple con los requisitos para ser candidato a un crédito. Esta
                        cotización quedará CANCELADA
                        <br />
                        <br />
                        ¿Deseas continuar?
                    </p>
                </Modal.Body>
                <Modal.Footer className="approvalFlow-lifeProof-footer">
                    <Row className="mt-3 mb-3">
                        <Button
                            className="btn-border-radius lifeProof-alertBtn"
                            variant="danger"
                            onClick={() => setShowCancelRequest(!showCancelRequest)}
                        >
                            Cancelar
                        </Button>
                        <Button className="ml-3 btn-border-radius lifeProof-successBtn" variant="success" onClick={cancelRequest}>
                            Aceptar
                        </Button>
                    </Row>
                </Modal.Footer>
            </Modal>
            <Modal size="xl" show={showImageModal} onHide={() => setShowImageModal(!showImageModal)}>
                <Modal.Header closeButton className="approvalFlow-lifeProofModal-header">
                    <Modal.Title as="h5">Prueba de vida</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex flex-column approvalFlow-lifeProofModal-body">
                    <img src={url} style={{ width: '100%', height: 'auto' }} alt="Prueba de vida imagen" />
                    <div className="d-flex justify-content-end mt-3">
                        <Button className="docs-infoBtn">
                            <a className="text-white" download={url + '.jpg'} href={url}>
                                Descargar
                            </a>
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default LifeProof;
