enum LEGAL_REPRESENTATIVE {

    UPDATE_LEGAL_REPRESENTATIVE_BEGIN = "UPDATE_LEGAL_REPRESENTATIVE_BEGIN",
    UPDATE_LEGAL_REPRESENTATIVE_ERROR = "UPDATE_LEGAL_REPRESENTATIVE_ERROR",
    UPDATE_LEGAL_REPRESENTATIVE_SUCCESS = "UPDATE_LEGAL_REPRESENTATIVE_SUCCESS",

    GET_LEGAL_REPRESENTATIVE_BEGIN = "GET_LEGAL_REPRESENTATIVE_BEGIN",
    GET_LEGAL_REPRESENTATIVE_ERROR = "GET_LEGAL_REPRESENTATIVE_ERROR",
    GET_LEGAL_REPRESENTATIVE_SUCCESS = "GET_LEGAL_REPRESENTATIVE_SUCCESS",

    LEGAL_REPRESENTATIVE_RESET_STATUS = "LEGAL_REPRESENTATIVE_RESET_STATUS"
}

export default LEGAL_REPRESENTATIVE;