import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import UploadFilesBuilder from '../../../components/Builders/UploadFilesBuilder';
import { getCredit, updateStatus } from '../../../flux/actions/quote-actions';
import { getDocuments } from '../../../flux/actions/documents-actions';
import { refreshToken } from '../../../flux/actions/refreshToken-action';

const RequestBankDocuments = () => {
    //redux const
    const history = useHistory();
    const dispatch = useDispatch();
    const {client} = useSelector((state: any) => state.clientReducer);
    const files = useSelector((state: any) => state.DocumentsReducer.flowDocuments);

    /*Validates if the user is logged in and if he is logged in, he obtains the user information and his files, otherwise it redirects you to the login*/
    useEffect(() => {
        if (Object.keys(client).length === 0) {
            history.push('/login');
        } else {
            dispatch(getDocuments(client.id));
        }
        dispatch(
            refreshToken({
                token: localStorage.getItem('token'),
                refreshToken: localStorage.getItem('refreshToken')
            })
        );
    }, [])

    return (
        <UploadFilesBuilder
            files={files}
            title={"Actualización Documentos"}
            description={"Nuestro equipo sigue revisando su solicitud y encontro que algunos archivos necesitan ser actualizados ya que se encontro algun problema con ellos, es necesario subir de nuevo estos archivos"}
            model="docsRequested"
        />
    );
};
export default RequestBankDocuments;
