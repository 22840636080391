import { Dispatch } from 'redux';
import { API } from '../api/role-api';
import ROLE_CONSTANTS from '../constants/role-constants';
import * as types from '../types/role-types';

//---------------------------------- POSTING THE ROLE --------------------------------------------------
const postRoleBegin = (): types.IPostRoleBegin => {
    return {
        type: ROLE_CONSTANTS.POST_ROLE_BEGIN
    };
};

const postRoleError = (e: any): types.IPostRoleError => {
    return {
        type: ROLE_CONSTANTS.POST_ROLE_ERROR,
        error: e
    };
};

const postRoleSuccess = (data: any): types.IPostRoleSuccess => {
    return {
        type: ROLE_CONSTANTS.POST_ROLE_SUCCESS,
        data
    };
};

export const saveRole = (payload: any) => {
    return (dispatch: Dispatch) => {
        dispatch(postRoleBegin());
        API.postSaveRole(payload)
            .then((response: any) => {
                dispatch(postRoleSuccess(response.data));
            })
            .catch((error: any) => {
                dispatch(postRoleError(error.response));
            });
    };
};

//---------------------------------- UPDATE ROLE INFORMATION --------------------------------------------------

const updateRoleBeggin = (): types.IUpdateRoleBegin => {
    return {
        type: ROLE_CONSTANTS.UPDATE_ROLE_BEGIN
    };
};

const updateRoleError = (e: any): types.IUpdateRoleError => {
    return {
        type: ROLE_CONSTANTS.UPDATE_ROLE_ERROR,
        error: e
    };
};

const updateRoleSuccess = (data: any): types.IUpdateRoletSuccess => {
    return {
        type: ROLE_CONSTANTS.UPDATE_ROLE_SUCCESS,
        data
    };
};

export const updateRole = (data: any) => {
    return (dispatch: Dispatch) => {
        dispatch(updateRoleBeggin());
        API.putUpdateRole(data)
            .then((response: any) => {
                dispatch(updateRoleSuccess(response.data));
            })
            .catch((error: any) => {
                dispatch(updateRoleError(error.response));
            });
    };
};

//---------------------------------- GET ALL THE FLOWS CREATED --------------------------------------------------
const getAllRolesBegin = (): types.IGetAllRolesBegin => {
    return {
        type: ROLE_CONSTANTS.GET_ALL_ROLES_BEGIN
    };
};

const getAllRolesError = (e: any): types.IGetAllRolesError => {
    return {
        type: ROLE_CONSTANTS.GET_ALL_ROLES_ERROR,
        error: e
    };
};

const getAllRolesSuccess = (data: any): types.IGetAllRolesSuccess => {
    return {
        type: ROLE_CONSTANTS.GET_ALL_ROLES_SUCCESS,
        data
    };
};

export const getAllRoles = () => {
    return (dispatch: Dispatch) => {
        dispatch(getAllRolesBegin());
        API.getAllRoles()
            .then((response: any) => {
                dispatch(getAllRolesSuccess(response.data));
            })
            .catch((error: any) => {
                dispatch(getAllRolesError(error.response));
            });
    };
};

//---------------------------------- GET ROLE BY ID  --------------------------------------------------
const getRoleBegin = (): types.IGetRoleBegin => {
    return {
        type: ROLE_CONSTANTS.GET_ROLE_BEGIN
    };
};

const getRoleError = (e: any): types.IGetRoleError => {
    return {
        type: ROLE_CONSTANTS.GET_ROLE_ERROR,
        error: e
    };
};

const getRoleSuccess = (data: any): types.IGetRoleSuccess => {
    return {
        type: ROLE_CONSTANTS.GET_ROLE_SUCCESS,
        data
    };
};

export const getRole = (id: any) => {
    return (dispatch: Dispatch) => {
        dispatch(getRoleBegin());
        API.getRole(id)
            .then((response: any) => {
                dispatch(getRoleSuccess(response.data));
            })
            .catch((error: any) => {
                dispatch(getRoleError(error.response));
            });
    };
};
//--------------------------- ACTION TO RESET ALL THE REDUCER STATUS ----------------------------
const resetStatus = () => {
    return {
        type: ROLE_CONSTANTS.RESET_ROLE_STATUS
    };
};

export const resetRoleStatus = () => {
    return (dispatch: Dispatch) => {
        dispatch(resetStatus());
    };
};

//--------------------------- ACTION TO RESET THE MESSAGES IN THE REDUCER ----------------------------
const resetMessages = () => {
    return {
        type: ROLE_CONSTANTS.RESET_ROLE_MESSAGES
    };
};

export const resetRoleMessages = () => {
    return (dispatch: Dispatch) => {
        dispatch(resetMessages());
    };
};
