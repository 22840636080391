import { Reducer } from 'redux';
import userModel from '../../models/user-model';
import CLIENT_CONSTANTS from '../constants/client-constants';
import { ClientAction } from '../types/client-types';

export interface ClientReducerState {
    loading: boolean;
    success: boolean;
    successMessage: string;
    client: userModel;
    bankFiles: [];
    addressFiles: [];
    idFiles: [];
    taxFiles: [];
    error: boolean;
    errorMessage?: string;
    errorStatus?: number;
    documentValidation?: any;
}

const initialState: ClientReducerState = {
    loading: false,
    success: false,
    error: false,
    bankFiles: [],
    addressFiles: [],
    idFiles: [],
    taxFiles: [],
    client: {} as userModel,
    successMessage: '',
    errorMessage: '',
    errorStatus: 0,
    documentValidation: {}
};

export const clientReducer: Reducer<ClientReducerState, ClientAction> = (state = initialState, action: ClientAction) => {
    switch (action.type) {
        //------------------ LOGIN -----------------------------
        case CLIENT_CONSTANTS.LOGIN_CLIENT_BEGIN:
            return {
                ...state,
                errorMessage: '',
                loading: true,
                loginError: false,
                errorStatus: 0
            };

        case CLIENT_CONSTANTS.LOGIN_CLIENT_ERROR:
            return {
                ...state,
                errorMessage: action.error.errorMessage,
                errorStatus: action.error ? action.error.status : 0,
                loginError: true,
                loading: false
            };

        case CLIENT_CONSTANTS.LOGIN_CLIENT_SUCCESS:
            return {
                ...state,
                errorMessage: '',
                loginError: false,
                successMessage: '',
                loading: false,
                client: action.data,
                errorStatus: 0
            };
        //------------------ SIGNUP -----------------------------
        case CLIENT_CONSTANTS.SIGNUP_BEGIN:
            return {
                ...state,
                errorMessage: '',
                loading: true,
                loginError: false,
                errorStatus: 0,
                curpMessage: ''
            };

        case CLIENT_CONSTANTS.SIGNUP_ERROR:
            return {
                ...state,
                errorMessage: action.error.data.errors?.email,
                errorStatus: action.error.status,
                curpMessage: action.error.data.errors?.CURP,
                loginError: true,
                loading: false
            };

        case CLIENT_CONSTANTS.SIGNUP_SUCCESS:
            return {
                ...state,
                errorMessage: '',
                loginError: false,
                loading: false,
                client: action.data.client,
                errorStatus: 0,
                successMessage: '',
                success: true
            };

        //------------------ LOGOUT -----------------------------
        case CLIENT_CONSTANTS.LOGOUT_SUCCESS:
            return {
                ...initialState
            };
        //----------GET CLIENT INFORMATION-----------//
        case CLIENT_CONSTANTS.GET_CLIENT_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            };

        case CLIENT_CONSTANTS.GET_CLIENT_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                errorStatus: action.error.data.status,
                errorMessage: action.error.data.message,
                successMessage: '',
                loading: false
            };

        case CLIENT_CONSTANTS.GET_CLIENT_SUCCESS:
            return {
                ...state,
                success: true,
                client: action.data,
                errorMessage: '',
                errorStatus: 0,
                error: false,
                loading: false
            };

        //----------UPDATE CLIENT INFORMATION-----------//
        case CLIENT_CONSTANTS.UPDATE_CLIENT_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            };

        case CLIENT_CONSTANTS.UPDATE_CLIENT_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                errorStatus: action.error.data.status,
                errorMessage: action.error.data.message,
                successMessage: '',
                loading: false
            };

        case CLIENT_CONSTANTS.UPDATE_CLIENT_SUCCESS:
            return {
                ...state,
                success: true,
                client: action.data.client,
                errorMessage: '',
                errorStatus: 0,
                error: false,
                loading: false
            };

        //----------UPDATE LOCATION INFORMATION-----------//
        case CLIENT_CONSTANTS.UPDATE_LOCATION_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            };

        case CLIENT_CONSTANTS.UPDATE_LOCATION_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                errorStatus: action.error.data.status,
                errorMessage: action.error.data.message,
                successMessage: '',
                loading: false
            };

        case CLIENT_CONSTANTS.UPDATE_LOCATION_SUCCESS:
            return {
                ...state,
                success: true,
                client: action.data.client,
                errorMessage: '',
                errorStatus: 0,
                error: false,
                loading: false
            };

        //---------- STEP BACK IN ON BOARDING-----------//
        case CLIENT_CONSTANTS.STEP_BACK_BEGIN:
            return {
                ...state,
                errorMessage: '',
                loading: true,
                loginError: false,
                errorStatus: 0
            };

        case CLIENT_CONSTANTS.STEP_BACK_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                client: action.data.client,
                successMessage: 'Paso atras correctametne'
            };

        case CLIENT_CONSTANTS.STEP_BACK_ERROR:
            return {
                ...state,
                errorMessage: action.error.data.message,
                errorStatus: action.error.data.status,
                loginError: true,
                loading: false
            };

        //----------VALIDATION DOCUMENTS INE----------------///
        case CLIENT_CONSTANTS.VALIDATION_DOCUMENTS_ID_BEGIN:
            return {
                ...state,
                success: false,
                error: false,
                errorMessage: '',
                errorStatus: 0,
                loading: true
            };

        case CLIENT_CONSTANTS.VALIDATION_DOCUMENTS_ID_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                errorStatus: action,
                errorMessage: action.error.data.message,
                loading: false
            };

        case CLIENT_CONSTANTS.VALIDATION_DOCUMENTS_ID_SUCCESS:
            return {
                ...state,
                success: true,
                documentValidation: action.data,
                error: false,
                errorMessage: '',
                errorStatus: 0,
                loading: false
            };

        //----------VALIDATION DOCUMENTS ADDRESS----------------///
        case CLIENT_CONSTANTS.VALIDATION_DOCUMENTS_ADDRESS_BEGIN:
            return {
                ...state,
                success: false,
                error: false,
                errorMessage: '',
                errorStatus: 0,
                loading: true
            };

        case CLIENT_CONSTANTS.VALIDATION_DOCUMENTS_ADDRESS_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                errorStatus: action.error.data.status,
                errorMessage: action.error.data.message,
                loading: false
            };

        case CLIENT_CONSTANTS.VALIDATION_DOCUMENTS_ADDRESS_SUCCESS:
            return {
                ...state,
                success: true,
                documentValidation: action.data,
                error: false,
                errorMessage: '',
                errorStatus: 0,
                loading: false
            };

        //----------UPDATE CLIENT IN THE FLOW INFORMATION-----------//
        case CLIENT_CONSTANTS.SAVE_FLOW_INFO_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            };

        case CLIENT_CONSTANTS.SAVE_FLOW_INFO_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                errorStatus: action.error?.data.status,
                errorMessage: action.error?.data.message,
                successMessage: '',
                loading: false
            };

        case CLIENT_CONSTANTS.SAVE_FLOW_INFO_SUCCESS:
            return {
                ...state,
                success: true,
                client: action.data.client || action.data,
                errorMessage: '',
                errorStatus: 0,
                error: false,
                loading: false
            };

        case CLIENT_CONSTANTS.RESET_CLIENT_STATUS:
            return {
                success: false,
                error: false,
                loading: false,
                bankFiles: [],
                addressFiles: [],
                idFiles: [],
                taxFiles: [],
                client: {} as userModel,
                documentValidation: {},
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            };

        //----------UPDATE SCHEDULE-----------//
        case CLIENT_CONSTANTS.UPDATE_SCHEDULE_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            };

        case CLIENT_CONSTANTS.UPDATE_SCHEDULE_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                errorStatus: action.error.data.status,
                errorMessage: action.error.data.message,
                successMessage: '',
                loading: false
            };

        case CLIENT_CONSTANTS.UPDATE_SCHEDULE_SUCCESS:
            return {
                ...state,
                success: true,
                client: action.data.client,
                errorMessage: '',
                errorStatus: 0,
                error: false,
                loading: false
            };

        default:
            return state;
    }
};
