import { Dispatch } from "redux";

import { API } from "../api/bussinessInfo-api";
import BUSSINESS_INFO_CONSTANTS from "../constants/bussinessInfo-constants"
import * as types from '../types/bussinessInfo-types';

//---------------------------------- GET MORAL PERSON INFORMATION --------------------------------------------------
const postBussinessInfoBegin = (): types.IPostBussinessInfoBegin => {
    return {
        type: BUSSINESS_INFO_CONSTANTS.POST_BUSSINESS_INFO_BEGIN
    };
}

const postBussinessInfoError = (e: any): types.IPostBussinessInfoError => {
    return {
        type: BUSSINESS_INFO_CONSTANTS.POST_BUSSINESS_INFO_ERROR,
        error: e
    };
}

 export const postBussinessInfoSuccess = (data: any): types.IPostBussinessInfoSuccess => {
    return {
        type: BUSSINESS_INFO_CONSTANTS.POST_BUSSINESS_INFO_SUCCESS,
        data
    };
}

export const postBussinessInfo = (payload: any) => {
    return (dispatch: Dispatch) => {
        dispatch(postBussinessInfoBegin());
        API.postBussinesInfo(payload)
            .then((response: any) => {
                dispatch(postBussinessInfoSuccess(response.data));
            }).catch((error: any) => {
                if(error.status === 401){
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = "/login";
                }
                dispatch(postBussinessInfoError(error.response));
            });
    }
}


//---------------------------------- GET MORAL PERSON INFORMATION --------------------------------------------------
const getBussinessInfoBegin = (): types.IGetBussinessInfoBegin => {
    return {
        type: BUSSINESS_INFO_CONSTANTS.GET_BUSSINESS_INFO_BEGIN
    };
}

const getBussinessInfoError = (e: any): types.IGetBussinessInfoError => {
    return {
        type: BUSSINESS_INFO_CONSTANTS.GET_BUSSINESS_INFO_ERROR,
        error: e
    };
}

const getBussinessInfoSuccess = (data: any): types.IGetBussinessInfoSuccess => {
    return {
        type: BUSSINESS_INFO_CONSTANTS.GET_BUSSINESS_INFO_SUCCESS,
        data
    };
}

export const getBussinessInfo = (userRef: string) => {
    return (dispatch: Dispatch) => {
        dispatch(getBussinessInfoBegin());
        API.getBussinessInfo(userRef)
            .then((response: any) => {
                dispatch(getBussinessInfoSuccess(response.data));
            }).catch((error: any) => {
                if(error.status === 401){
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = "/login";
                }
                dispatch(getBussinessInfoError(error.response));
            });
    }
}

const resetStatus = () => {
    return {
        type: BUSSINESS_INFO_CONSTANTS.RESET_BUSSINESS_INFO_STATUS
    }
}

export const resetBussinessInfoStatus = () => {
    return (dispatch: Dispatch) => {
        dispatch(resetStatus());
        ;
    }
}