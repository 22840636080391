import * as React from 'react';
import MainPage from '../views/Customer';
//import RequestDocuments from '../views/Customer/RequestedFiles/RequestBankDocuments';
import CustomerProfile from '../views/Admin/CustomerProfile';
import Prospects from '../views/Admin/Prospects';
import Dashboard from '../views/Admin/Dashboard';
import PDL from '../views/Admin/PLD';
import Listas from '../views/Admin/Listas';
import Flows from '../views/Flows';
import ListFlows from '../views/Flows/ListFlows';
import ApprovalCriteria from '../views/ApprovalProcess/CreateApprovalCriteria/ApprovalCriteria';
import CreateApprovalProcess from '../views/ApprovalProcess/CreateApprovalProcess';
import Querys from '../views/Admin/Querys';
import Users from '../views/Admin/Users';
import Rols from '../views/Admin/Rols';

interface RouteObject {
    path: string;
    exact?: boolean;
    name?: string;
    component: any;
}

const userRoutes: RouteObject[] = [
    { path: '/usuario/inicio', exact: true, name: 'Pagina de inicio', component: MainPage },
    //{ path: '/usuario/solicitud-documentos', exact: true, name: 'Subir documentos', component: RequestDocuments },
    { path: '/admin/perfil-cliente/:id', exact: true, name: 'Perfil de cliente', component: CustomerProfile },
    { path: '/admin/prospectos', exact: true, name: 'Prospectos', component: Prospects },
    { path: '/admin/crear-flujo', exact: true, name: 'Perfil de cliente', component: Flows },
    { path: '/admin/editar-flujo/:id', exact: true, name: 'Prospectos', component: Flows },
    { path: '/admin/flujos', exact: true, name: 'Prospectos', component: ListFlows },
    { path: '/admin/criterios-aprobacion/:id', exact: true, name: 'Prospectos', component: ApprovalCriteria },
    { path: '/admin/crear-proceso-aprobacion/:id', exact: true, name: 'Prospectos', component: CreateApprovalProcess },
    { path: '/admin/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
    { path: '/admin/pld', exact: true, name: 'PLD', component: PDL },
    { path: '/admin/listas', exact: true, name: 'Listas', component: Listas },
    { path: '/admin/consultas', exact: true, name: 'Consultas', component: Querys },
    { path: '/admin/usuarios', exact: true, name: 'Usuarios', component: Users },
    { path: '/admin/roles', exact: true, name: 'Roles', component: Rols }
];

export default userRoutes;
