import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Form, Col, Row, Modal, Button } from 'react-bootstrap';
import { getZipCode } from '../../../../flux/actions/zipCode-actions';
import zipCodeModel from '../../../../models/zipCode-model';
import { postBussinessInfo } from '../../../../flux/actions/bussinessInfo-actions';
import Loader from '../../../../components/ScreenLoader/ScreenLoader';

const BussinessAddress: React.FC = () => {
    //const to get the params
    const dispatch = useDispatch();
    const zipCodes: zipCodeModel[] = useSelector((state: any) => state.zipCodeReducer.zipCodeData);
    const { error, errorMessage } = useSelector((state: any) => state.zipCodeReducer);
    const { bussinessInfo, loading } = useSelector((state: any) => state.bussinessInfoReducer);
    const clientId = useSelector((state: any) => state.clientReducer.client.id);

    //BUSINESS ADDRESS INFORMATION CONST
    const [country, setCountry] = useState(bussinessInfo.country || '');
    const [zipCode, setZipCode] = useState(bussinessInfo.zipCode || '');
    const [municipalityBussiness, setMunicipalityBussiness] = useState(bussinessInfo.municipality || '');
    const [cityBussiness, setCityBussiness] = useState(bussinessInfo.state || '');
    const [stateBussiness, setStateBussiness] = useState(bussinessInfo.state || '');
    const [suburbBussiness, setSuburbBussiness] = useState(bussinessInfo.suburb || '');
    const [streetBussiness, setStreetBussiness] = useState(bussinessInfo.street || '');
    const [numIntBussiness, setNumIntBussiness] = useState(bussinessInfo.numInt || '');
    const [numExtBussiness, setNumExtBussiness] = useState(bussinessInfo.numExt || '');

    //Constantes de Edicion sirven para habilitar y deshabilitar partes del código
    const [isBussinessAddressEdit, setIsBussinessAddressEdit] = useState(false);
    const [showModal, setShowModal] = useState(false);

    //Se crea y se Inicializa un objeto para los posibles errores de Direcion de Negocio
    const [errorsAddressBussiness, setErrorsAddressBussiness] = useState({
        country: '',
        zipCode: '',
        city: '',
        state: '',
        municipality: '',
        suburb: '',
        street: '',
        numExt: ''
    });

    // useEffect(() => {
    //     zipCode && zipCode.length === 5 && dispatch(getZipCode(zipCode));
    // }, [zipCode]);

    //se asignan lios valores de ciudad,estado,municipio, y colonia sise cumple la condicion
    useEffect(() => {
        if (zipCodes.length !== 0 && isBussinessAddressEdit) {
            setCityBussiness(zipCodes[0].d_ciudad || '');
            if (!zipCodes[0].d_ciudad) {
                setErrorsAddressBussiness({ ...errorsAddressBussiness, city: 'No hay registro de ciudad, escribir manualmente' });
            }
            setStateBussiness(zipCodes[0].d_estado);
            setMunicipalityBussiness(zipCodes[0].D_mnpio);
            setSuburbBussiness(zipCodes[0].d_asenta);
        }
    }, [zipCodes]);

    //Se guardan los index de la domicilio personal y la del negocio, ademas se asigna la información de la direccion segun el tipo de direccion
    useEffect(() => {
        if (Object.keys(bussinessInfo).length !== 0) {
            setCountry(bussinessInfo.country || '');
            setZipCode(bussinessInfo.zipCode || '');
            setMunicipalityBussiness(bussinessInfo.municipality || '');
            setStateBussiness(bussinessInfo.state || '');
            setCityBussiness(bussinessInfo.city || '');
            setSuburbBussiness(bussinessInfo.suburb || '');
            setStreetBussiness(bussinessInfo.street || '');
            setNumIntBussiness(bussinessInfo.numInt || '');
            setNumExtBussiness(bussinessInfo.numExt || '');
        }
    }, [bussinessInfo]);

    //Funcion para actualizar la Información en la base de datos y manejar los posibles errores
    const saveAddress = () => {
        //Si se cumple la condicion ejecuta la funcion
        //constantes manejadoras de errrores
        const countryError = country !== '0' ? '' : 'Favor de seleccionar un País';
        const zipCodeError = !error && zipCode.length == 5 ? '' : 'Hay un error con el código postal';
        const cityError = cityBussiness.length !== 0 && cityBussiness !== null ? '' : 'Este campo no puede estar vacío';
        const stateError = stateBussiness.length !== 0 ? '' : 'Este campo no puede estar vacío';
        const municipalityError = municipalityBussiness.length !== 0 ? '' : 'Este campo no puede estar vacío';
        const suburbError = suburbBussiness !== '0' ? '' : 'Favor de seleccionar una Colonia';
        const streetError = streetBussiness.length !== 0 ? '' : 'Este campo no puede estar vacío';
        const numExtError = numExtBussiness.length !== 0 ? '' : 'Este campo no puede estar vacío';
        //Se asignan posibles errores en el objeto
        setErrorsAddressBussiness({
            country: countryError,
            zipCode: zipCodeError,
            city: cityError,
            street: streetError,
            municipality: municipalityError,
            suburb: suburbError,
            state: stateError,
            numExt: numExtError
        });

        if (zipCodeError || cityError || stateError || municipalityError || suburbError || streetError || numExtError || countryError)
            return;
        //Actualiza la información si no exite ningun error
        dispatch(
            postBussinessInfo({
                country,
                zipCode,
                city: cityBussiness,
                state: stateBussiness,
                municipality: municipalityBussiness,
                suburb: suburbBussiness,
                street: streetBussiness,
                numExt: numExtBussiness,
                numInt: numIntBussiness,
                clientRef: clientId
            })
        );
        setShowModal(!showModal);
        setIsBussinessAddressEdit(!isBussinessAddressEdit);
    };

    return loading ? (
        <div className="auth-wrapper align-items-center">
            <Loader isOpen={true} />
        </div>
    ) : (
        <>
            <Card>
                <Card.Body className="d-flex align-items-center justify-content-between">
                    <h5 className="mb-0">Domicilio del Negocio</h5>
                    <button
                        type="button"
                        className="btn btn-primary btn-sm btn-border-radius m-0 float-right customerProfile-editBtn"
                        onClick={() => {
                            setIsBussinessAddressEdit(!isBussinessAddressEdit);
                        }}
                    >
                        <i className={isBussinessAddressEdit ? 'feather icon-x' : 'feather icon-edit'} />
                    </button>
                </Card.Body>
                <Card.Body
                    className={isBussinessAddressEdit ? 'border-top pro-det-edit collapse' : 'border-top pro-det-edit collapse show'}
                >
                    <Row>
                        <Col className="form-group">
                            <label className="font-weight-bolder mt-3">País de residencia: </label>
                            <p>{country}</p>
                        </Col>
                        <Col className="form-group">
                            <label className="font-weight-bolder mt-3">Código postal: </label>
                            <p>{zipCode}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="form-group">
                            <label className="font-weight-bolder mt-3">Estado: </label>
                            <p>{stateBussiness}</p>
                        </Col>
                        <Col className="form-group">
                            <label className="font-weight-bolder mt-3">Ciudad: </label>
                            <p>{cityBussiness}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="form-group">
                            <label className="font-weight-bolder mt-3">Municipio: </label>
                            <p>{municipalityBussiness}</p>
                        </Col>
                        <Col className="form-group">
                            <label className="font-weight-bolder mt-3">Colonia: </label>
                            <p>{suburbBussiness}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="form-group">
                            <label className="font-weight-bolder mt-3">Calle: </label>
                            <p>{streetBussiness}</p>
                        </Col>
                        <Col className="form-group">
                            <label className="font-weight-bolder mt-3">Número Exterior: </label>
                            <p>{numExtBussiness}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="form-group">
                            <label className="font-weight-bolder mt-3">Número Interior: </label>
                            <p>{numIntBussiness || 'Sin Número'}</p>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Body
                    className={isBussinessAddressEdit ? 'border-top pro-det-edit collapse show' : 'border-top pro-det-edit collapse'}
                >
                    <Row className="form-group">
                        <Col>
                            <label className="font-weight-bolder mt-3">Código Postal: </label>
                            <input
                                type="text"
                                className="form-control"
                                id="cp"
                                placeholder="Código postal"
                                maxLength={5}
                                value={zipCode}
                                onChange={(e: any) => {
                                    setZipCode(e.target.value.trim());
                                    setErrorsAddressBussiness({ ...errorsAddressBussiness, zipCode: '' });
                                }}
                            />
                            {errorMessage && <p className="form-text text-left text-danger"> {errorMessage} </p>}
                            {errorsAddressBussiness.zipCode && (
                                <p className="form-text text-left text-danger">{errorsAddressBussiness.zipCode}</p>
                            )}
                        </Col>
                        <Col>
                            <label className="font-weight-bolder">Estado:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="stateBussiness"
                                placeholder="Estado"
                                value={stateBussiness}
                                onChange={(e: any) => {
                                    setStateBussiness(e.target.value);
                                    setErrorsAddressBussiness({ ...errorsAddressBussiness, state: '' });
                                }}
                            />

                            {errorsAddressBussiness.state && (
                                <p className="form-text text-left text-danger">{errorsAddressBussiness.state}</p>
                            )}
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Col>
                            <label className="font-weight-bolder">Ciudad:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="city"
                                placeholder="Ciudad"
                                value={cityBussiness}
                                onChange={(e: any) => {
                                    setCityBussiness(e.target.value);
                                    setErrorsAddressBussiness({ ...errorsAddressBussiness, city: '' });
                                }}
                            />

                            {errorsAddressBussiness.city && (
                                <p className="form-text text-left text-danger">{errorsAddressBussiness.city}</p>
                            )}
                        </Col>
                        <Col>
                            <label className="font-weight-bolder">Municipio:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="municipaliti"
                                placeholder="Municipio"
                                value={municipalityBussiness}
                                onChange={(e: any) => {
                                    setMunicipalityBussiness(e.target.value);
                                    setErrorsAddressBussiness({ ...errorsAddressBussiness, municipality: '' });
                                }}
                            />

                            {errorsAddressBussiness.municipality && (
                                <p className="form-text text-left text-danger">{errorsAddressBussiness.municipality}</p>
                            )}
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Col>
                            <label className="font-weight-bolder">Colonia:</label>
                            <Form.Group className="pl-0">
                                <Form.Control
                                    as="select"
                                    value={suburbBussiness}
                                    onChange={(e) => {
                                        setSuburbBussiness(e.target.value);
                                        setErrorsAddressBussiness({ ...errorsAddressBussiness, suburb: '' });
                                    }}
                                    autoComplete="off"
                                >
                                    {zipCodes.length === 0 && <option value={'0'}>Colonia</option>}
                                    {zipCodes &&
                                        zipCodes.map((zipCode) => {
                                            return <option value={zipCode.d_asenta}>{zipCode.d_asenta}</option>;
                                        })}
                                </Form.Control>
                            </Form.Group>
                            {errorsAddressBussiness.suburb && (
                                <p className="form-text text-left text-danger">{errorsAddressBussiness.suburb}</p>
                            )}
                        </Col>
                        <Col>
                            <label className="font-weight-bolder">Calle:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="street"
                                placeholder="Calle"
                                value={streetBussiness}
                                onChange={(e: any) => {
                                    setStreetBussiness(e.target.value);
                                    setErrorsAddressBussiness({ ...errorsAddressBussiness, street: '' });
                                }}
                            />
                            {errorsAddressBussiness.street && (
                                <p className="form-text text-left text-danger">{errorsAddressBussiness.street}</p>
                            )}
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Col>
                            <label className="font-weight-bolder">Número Exterior:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="numExt"
                                placeholder="# Exterior"
                                value={numExtBussiness}
                                onChange={(e: any) => {
                                    setNumExtBussiness(e.target.value);
                                    setErrorsAddressBussiness({ ...errorsAddressBussiness, numExt: '' });
                                }}
                            />
                            {errorsAddressBussiness.numExt && (
                                <p className="form-text text-left text-danger">{errorsAddressBussiness.numExt}</p>
                            )}
                        </Col>
                        <Col>
                            <label className="font-weight-bolder">Número Interior:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="numInt"
                                placeholder="# Interior"
                                value={numIntBussiness}
                                onChange={(e: any) => {
                                    setNumIntBussiness(e.target.value);
                                    //    setErrors({ ...errors, antiquity: "" });
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="form-group mt-5">
                        <Col className="d-flex justify-content-end">
                            <button
                                type="submit"
                                className="btn btn-primary customerProfile-saveBtn"
                                onClick={() => setShowModal(!showModal)}
                            >
                                Guardar
                            </button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Modal show={showModal} onHide={() => setShowModal(!showModal)}>
                <Modal.Header closeButton>
                    <Modal.Title as="h5">Actualización de información</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    La información de la dirección del negocio del usuario ha sido modificada
                    <br />
                    <br />
                    ¿Deseas continuar con la actualización de la información del usuario?
                </Modal.Body>
                <Modal.Footer>
                    <Row className="mt-3 mb-3">
                        <Button className="btn-border-radius" variant="danger" onClick={() => setShowModal(!showModal)}>
                            Cancelar
                        </Button>
                        <Button className="ml-3 btn-border-radius" variant="secondary" onClick={saveAddress}>
                            Continuar
                        </Button>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default BussinessAddress;
