import React, { useEffect, useState, FC, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Card, Form } from 'react-bootstrap';
import { useTimer } from 'use-timer';
import { useHistory } from 'react-router-dom';
import { postOtp, sendOtp } from '../../../flux/actions/otp-actions';
import Loader from '../../../components/ScreenLoader/ScreenLoader';
import { getClient } from '../../../flux/actions/client-actions';

var mobile = require('is-mobile');
const cryptoJS = require('crypto-js');

interface Props {
    description: string;
    onSaveForm: () => void | any;
    finishFlow: () => any;
}

const OTPBuilder: FC<Props> = (p) => {
    //Timer to validate OTP and life time
    const { time, start } = useTimer({
        initialTime: 120,
        autostart: true,
        endTime: 0,
        onTimeOver: () => {
            setResendNIP(true);
        },
        timerType: 'DECREMENTAL'
    });
    //redux vars
    const history = useHistory();
    const dispatch = useDispatch();
    const { client, loading } = useSelector((state: any) => state.clientReducer);
    const otpResponse = useSelector((state: any) => state.otpReducer);

    //local vars
    const [OTP, setOTP] = useState('');
    const [NIP, setNIP] = useState('');
    const [resendNIP, setResendNIP] = useState(false);
    const [termnsConditions, setTermnsConditions] = useState(false);

    //Error handler
    const [errors, setErrors] = useState({
        NIP: '',
        lifeTime: '',
        termnsConditions: ''
    });

    useEffect(() => {
        if (Object.keys(client).length === 1) {
            history.push('/login');
        } else {
            generateOTP();
            dispatch(getClient(client.id));
        }
    }, []);

    // Funcion para generar una contraseña aleatoria temporal, esta funcion genera un Nip de 6 digitos numericos
    // ademas de enviar un correo electronico con dicha contraseña
    const generateOTP = () => {
        var digits = '0123456789';
        let OneTimePassword = '';

        for (let i = 0; i < 6; i++) {
            OneTimePassword += digits[Math.floor(Math.random() * 10)];
        }

        let cipherOTP = cryptoJS.AES.encrypt(OneTimePassword, '9xNPoxyu').toString();

        setOTP(OneTimePassword);
        dispatch(sendOtp(cipherOTP, client.email, client.names, client.lastName1, client.lastName2));
    };

    //Funcion para hacer la verificación y el envio del NIP si no ocurre ningun error en las validaciónes de la información
    const verifyNip = (e: any) => {
        const lifeTimeError = !resendNIP ? '' : 'El tiempo de vida del NIP expiró';
        const NIPError = NIP === OTP ? '' : 'El PIN no coincide';
        const termsConditionsError = termnsConditions ? '' : 'No se han aceptado los términos y condiciones';

        setErrors({
            NIP: NIPError,
            lifeTime: lifeTimeError,
            termnsConditions: termsConditionsError
        });

        if (lifeTimeError || termsConditionsError || NIPError) return;
        p.finishFlow();
        dispatch(postOtp(Number(NIP), client.id));
        p.onSaveForm();
    };

    //Next Button Styles
    const nextButton = useRef<HTMLButtonElement>(null);

    const changeStylesNextBtn = (button: HTMLButtonElement) => {
        if (client.onBoardingStep >= 1) {
            button.className = 'btn-border-radius onBoarding-button onBoarding-button-next nextBtn';
        }

        if (client.onBoardingStep === 0) {
            button.className = 'btn-border-radius onBoarding-button onBoarding-button-next';
        }
    };

    if (null !== nextButton.current) {
        changeStylesNextBtn(nextButton.current);
    }

    if (otpResponse.loading && loading) {
        return (
            <div className="auth-wrapper align-items-center">
                <Loader isOpen={true} />
            </div>
        );
    }

    return (
        <Col className="d-flex flex-column justify-content-center onBoarding-form onBoarding-otp">
            <Row className="step-form">
                <Col sm={12} md={12} className="mt-3">
                    <Card.Title>Autorización para solicitar Reportes de Crédito</Card.Title>
                    <div className="divider-title"></div>
                    <Card.Text className="text-justify ">{p.description}</Card.Text>
                    <Form.Group as={Row} className="d-flex justify-content-center align-items-center flex-column">
                        <Form.Label column sm={5} className="text-center">
                            Ingresa el NIP que mandamos a tu correo
                        </Form.Label>
                        <Col sm={3}>
                            <Form.Control
                                type="text"
                                placeholder="# NIP"
                                maxLength={6}
                                autoComplete="off"
                                name="disabledCP"
                                value={NIP.toString()}
                                onChange={(e) => {
                                    setNIP(e.target.value.trim());
                                    setErrors({ ...errors, NIP: '', lifeTime: '' });
                                }}
                            />
                        </Col>
                        {errors.NIP && (
                            <p id="passwordHelpBlock" className="form-text text-danger formAlert">
                                {errors.NIP}
                            </p>
                        )}
                        {errors.lifeTime && (
                            <p id="passwordHelpBlock" className="form-text text-danger formAlert">
                                {errors.lifeTime}
                            </p>
                        )}
                    </Form.Group>
                    <Row className="d-flex flex-column mt-4 mb-3 align-items-center justify-content-center">
                        <Col md={4}>
                            <p className="mt-3 mr-3">Este NIP tiene una duración de 2 minutos.</p>
                        </Col>
                        {!resendNIP ? (
                            <Col className="d-flex flex-row align-items-center justify-content-center" md={4}>
                                <p>
                                    0{Math.trunc(time / 60)}:
                                    {time % 60 === 0 ? '00' : time % 60 > 10 ? (time % 60).toFixed(0) : '0' + (time % 60).toFixed(0)}
                                </p>
                            </Col>
                        ) : (
                            <Button
                                className="mt-4 mb-4 ml-4 onBoarding-otp-requestNIP"
                                onClick={() => {
                                    start();
                                    setResendNIP(false);
                                    generateOTP();
                                }}
                            >
                                <dt>Solicitar NIP</dt>
                            </Button>
                        )}
                    </Row>
                    <Form.Group className="d-flex flex-row text-center mt-4 justify-content-center onBoarding-otp-termnsConditions">
                        <Form.Check
                            custom
                            type="checkbox"
                            id="supported-checkbox"
                            checked={termnsConditions}
                            onChange={() => {
                                setTermnsConditions(!termnsConditions);
                            }}
                        />
                        <Form.Label>
                            Acepto
                            <a
                                href="https://www.kualinet.com/strongaviso-legal-de-terminos-y-condiciones-de-uso-del-sitio-web-de-kualinet-s-a-p-i-de-c-v-sofom-e-n-r-strong/"
                                target="__blank"
                                className="f-w-400 ml-1 mr-1"
                            >
                                Términos y Condiciones
                            </a>
                            y cláusulas de medios electrónicos tales como NIP.
                        </Form.Label>
                    </Form.Group>
                    {errors.termnsConditions && (
                        <p id="passwordHelpBlock" className="form-text text-danger formAlert">
                            {errors.termnsConditions}
                        </p>
                    )}
                    <Row className="onBoarding-buttons-content">
                        <Col className="d-flex onBoarding-buttons justify-content-center">
                            <Button
                                className="btn-border-radius onBoarding-button onBoarding-button-next"
                                variant="third"
                                ref={nextButton}
                                onClick={verifyNip}
                            >
                                <dt>
                                    Autorizo <i className={'feather icon-arrow-right'} />
                                </dt>
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    );
};

export default OTPBuilder;
