import { Dispatch } from "redux";
import { API } from "../api/unic-api";
import RENEWPASSWORD_CONSTANTS from "../constants/renewpassword-constants";
import * as types from '../types/renewpassword-types';


const postRenewpasswordBeggin = (): types.IPostRenewpasswordBegin => {
    return {
        type: RENEWPASSWORD_CONSTANTS.POST_RENEWPASSWORD_CONSTANTS_BEGIN
    };
}

const postRenewpasswordError = (e: any): types.IPostRenewpasswordError => {
    return {
        type: RENEWPASSWORD_CONSTANTS.POST_RENEWPASSWORD_CONSTANTS_ERROR,
        error: e
    };
}

const postRenewpasswordSuccess = (data: any): types.IPostRenewpasswordSuccess => {
    return {
        type: RENEWPASSWORD_CONSTANTS.POST_RENEWPASSWORD_CONSTANTS_SUCCESS,
        data
    };
}

export const renewpassword = (payload: any) => {
    return (dispatch: Dispatch) => {
        dispatch(postRenewpasswordBeggin());
        API.renewpassword(payload)
        .then((response: any) => {
            dispatch(postRenewpasswordSuccess(response.data));
        }).catch((error: any) => {
            dispatch(postRenewpasswordError(error.response));
        });
    }
}

const resetStatus = () => {
    return {
        type: RENEWPASSWORD_CONSTANTS.RESET_RENEWPASSWORD_CONSTANTS_STATUS
    }
}

export const resetRenewpasswordStatus = () => {
    return (dispatch: Dispatch) => {
        dispatch(resetStatus());
    }
}