import { Reducer } from 'redux';
import { DocumentsAction } from '../types/documents-types'
import DOCUMENT_CONSTANTS from '../constants/documents-constants';

export interface BussinessReducerState {
    backTo?: string
    errorMessage: string
    errorStatus?: number
    error: boolean
    flowDocuments: any,
    success: boolean,
    successMessage: string,
}

const initialState: BussinessReducerState = {
    backTo: undefined,
    errorMessage: "",
    errorStatus: 0,
    error: false,
    flowDocuments: [],
    success: false,
    successMessage: "",
};


export const DocumentsReducer: Reducer<BussinessReducerState, DocumentsAction> = (state = initialState, action: DocumentsAction) => {

    switch (action.type) {
        //------------------ POST BUSSINESS INFO -----------------------------
        case DOCUMENT_CONSTANTS.POST_DOCUMENTS_BEGIN:
            return {
                ...state,
                errorMessage: "",
                loading: true,
                error: false,
                errorStatus: 0
            }

        case DOCUMENT_CONSTANTS.POST_DOCUMENTS_ERROR:

            return {
                ...state,
                errorMessage: action.error.data.message,
                errorStatus: action.error ? action.error.status : 0,
                error: true,
                loading: false
            }

        case DOCUMENT_CONSTANTS.POST_DOCUMENTS_SUCCESS:
            return {
                ...state,
                errorMessage: "",
                error: false,
                successMessage: action.data.message,
                loading: false,
                //flowDocuments: action.data.flowDocuments,
                errorStatus: 0,
            }

        //------------------ POST BUSSINESS INFO -----------------------------
        case DOCUMENT_CONSTANTS.UPDATE_DOCUMENTS_SUCCESS:
            return {
                ...state,
                errorMessage: "",
                error: false,
                successMessage: action.data.message,
                loading: false,
                //flowDocuments: action.data.flowDocuments,
                errorStatus: 0,
            }
        
        //------------------ GET BUSSINESS INFORMATION -----------------------------
        case DOCUMENT_CONSTANTS.GET_DOCUMENTS_BEGIN:
            return {
                ...state,
                errorMessage: "",
                loading: true,
                error: false,
                errorStatus: 0,
                curpMessage: ""
            }

        case DOCUMENT_CONSTANTS.GET_DOCUMENTS_ERROR:
            return {
                ...state,
                errorMessage: action.error.data.errorMessage,
                errorStatus: action.error.status,
                error: true,
                loading: false
            }

        case DOCUMENT_CONSTANTS.GET_DOCUMENTS_SUCCESS:
            return {
                ...state,
                errorMessage: "",
                error: false,
                loading: false,
                flowDocuments: action.data.files,
                errorStatus: 0,
                successMessage: action.data.message,
                success: true
            }

        //------------------ GET ID DOCUMENTS -----------------------------
        case DOCUMENT_CONSTANTS.GET_ID_SUCCESS:
            return {
                ...state,
                errorMessage: "",
                error: false,
                loading: false,
                flowDocuments: action.data.files,
                errorStatus: 0,
                successMessage: action.data.message,
                success: true
            }
        //------------------ REQUEST DOCUMENTATION FROM CLIENT -----------------------------
        case DOCUMENT_CONSTANTS.REQUEST_DOCUMENTS_BEGIN:
            return {
                ...state,
                errorMessage: "",
                loading: true,
                error: false,
                errorStatus: 0,
                curpMessage: ""
            }

        case DOCUMENT_CONSTANTS.REQUEST_DOCUMENTS_ERROR:
            return {
                ...state,
                errorMessage: action.error.data.errorMessage,
                errorStatus: action.error.status,
                error: true,
                loading: false
            }

        case DOCUMENT_CONSTANTS.REQUEST_DOCUMENTS_SUCCESS:
            return {
                ...state,
                errorMessage: "",
                error: false,
                loading: false,
                flowDocuments: action.data.files,
                errorStatus: 0,
                successMessage: action.data.message,
                success: true
            }
        //------------------ REQUEST ID SUCCESS -----------------------------
        case DOCUMENT_CONSTANTS.REQUEST_ID_SUCCESS:
            return {
                ...state,
                errorMessage: "",
                error: false,
                loading: false,
                flowDocuments: action.data.files,
                errorStatus: 0,
                successMessage: action.data.message,
                success: true
            }
            
        //------------------ RESET DOCUMENT STATUS -----------------------------
        case DOCUMENT_CONSTANTS.RESET_DOCUMENTS_STATUS:
            return {
                ...state,
                loading: false,
                errorMessage: '',
                errorStatus: 0,
                success: false,
                flowDocuments: [],
                successMessage: ''
            }

        default:
            return state
    }
}
