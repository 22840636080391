import { Reducer } from 'redux';
import NUBARIUM_REFERENCE_CONSTANTS from '../constants/nubarium-constant';
import { numbariumAction } from '../types/nubarium-types';

export interface NubariumIdReducerState {
    loading: boolean;
    success: boolean;
    successMessage: string;
    error: boolean;
    errorMessage?: string;
    errorStatus?: number;
    comparision?: any;
}

const initialState: NubariumIdReducerState = {
    loading: false,
    success: false,
    error: false,
    successMessage: '',
    errorMessage: '',
    errorStatus: 0
};

export const NubariumReferenceReducer: Reducer<NubariumIdReducerState, numbariumAction> = (
    state = initialState,
    action: numbariumAction
) => {
    switch (action.type) {
        //----------CREATE ORDER-----------//
        case NUBARIUM_REFERENCE_CONSTANTS.POST_NUBARIUM_REFERENCE_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            };

        case NUBARIUM_REFERENCE_CONSTANTS.POST_NUBARIUM_REFERENCE_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                errorStatus: action.error.data.status,
                errorMessage: action.error.data,
                successMessage: '',
                loading: false
            };

        case NUBARIUM_REFERENCE_CONSTANTS.POST_NUBARIUM_REFERENCE_SUCCESS:
            return {
                ...state,
                success: true,
                successMessage: action.data,
                errorMessage: '',
                errorStatus: 0,
                error: false,
                loading: false
            };
        //----------VALIDATION DOCUMENTS INE----------------///
        case NUBARIUM_REFERENCE_CONSTANTS.COMPARE_ID_SELFIE_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            };

        case NUBARIUM_REFERENCE_CONSTANTS.COMPARE_ID_SELFIE_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                errorStatus: action.error.data.status,
                errorMessage: action.error.data,
                successMessage: '',
                loading: false
            };

        case NUBARIUM_REFERENCE_CONSTANTS.COMPARE_ID_SELFIE_SUCCESS:
            return {
                ...state,
                success: true,
                successMessage: action.data,
                errorMessage: '',
                errorStatus: 0,
                error: false,
                loading: false
            };
        //----------MEDIA DOWNLOAD----------------///
        case NUBARIUM_REFERENCE_CONSTANTS.DOWNLOAD_MEDIA_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            };

        case NUBARIUM_REFERENCE_CONSTANTS.DOWNLOAD_MEDIA_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                errorStatus: action.error.data.status,
                errorMessage: action.error.data,
                successMessage: '',
                loading: false
            };

        case NUBARIUM_REFERENCE_CONSTANTS.DOWNLOAD_MEDIA_SUCCESS:
            return {
                ...state,
                success: true,
                successMessage: action.data,
                errorMessage: '',
                errorStatus: 0,
                error: false,
                loading: false
            };

        case NUBARIUM_REFERENCE_CONSTANTS.RESET_NUBARIUM_STATUS:
            return {
                loading: false,
                success: false,
                error: false,
                successMessage: '',
                errorMessage: '',
                errorStatus: 0
            };
        default:
            return state;
    }
};
