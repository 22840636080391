import { Reducer } from 'redux';
import OTP_CONSTANTS from '../constants/otp-constants';
import { OtpAction } from '../types/otp-types';

export interface OtpReducerState {
    loading: boolean;
    success: boolean;
    successMessage: string;
    OTP: {};
    error: boolean;
    errorMessage?: string;
    errorStatus?: number;
}

const initialState: OtpReducerState = {
    loading: false,
    success: false,
    OTP: {},
    error: false,
    successMessage: '',
    errorMessage: '',
    errorStatus: 0,
}

export const otpReducer: Reducer<OtpReducerState, OtpAction> = (state = initialState, action: OtpAction) => {
    switch (action.type) {
        //----------CREATE ORDER-----------//
        case OTP_CONSTANTS.POST_OTP_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                OTP: {},
                errorMessage: '',
                successMessage: ''
            }

        case OTP_CONSTANTS.POST_OTP_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                OTP: {},
                errorStatus: action.error.data.status,
                errorMessage: action.error.data.message,
                loading: false
            }

        case OTP_CONSTANTS.POST_OTP_SUCCESS:
            return {
                ...state,
                success: true,
                successMessage: action.data.message,
                error: false,
                OTP: action.data.otp,
                errorMessage: '',
                errorStatus: 0,
                loading: false
            }

        case OTP_CONSTANTS.GET_OTP_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                OTP: {},
                errorMessage: '',
                successMessage: ''
            }

        case OTP_CONSTANTS.GET_OTP_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                OTP: {},
                errorStatus: action.error.data.status,
                errorMessage: action.error.data.message,
                loading: false
            }

        case OTP_CONSTANTS.GET_OTP_SUCCESS:
            return {
                ...state,
                success: true,
                successMessage: action.data.message,
                error: false,
                OTP: action.data.otp || action.data,
                errorMessage: '',
                errorStatus: 0,
                loading: false
            }

        case OTP_CONSTANTS.RESET_OTP_STATUS:
            return {
                success: false,
                error: false,
                loading: false,
                errorStatus: 0,
                OTP: {},
                errorMessage: '',
                successMessage: ''
            }

        default:
            return state;
    }
}