import axios from 'axios';

const API_URL = process.env.REACT_APP_BASE_URL;

const postSavePermission = (payload: string) => {
    return axios.post(`${API_URL}permission/`, payload);
}

const getPermissions = () => {
    return axios.get(`${API_URL}permission`);
}

export const API = {
    postSavePermission,
    getPermissions
}