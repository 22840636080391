import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Card } from 'react-bootstrap';
import { refreshToken } from '../../../flux/actions/refreshToken-action';
import { getClient } from '../../../flux/actions/client-actions';

const ScheduleDateCostumerView = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { client } = useSelector((state: any) => state.clientReducer);

    useEffect(() => {
        if (Object.keys(client).length === 0) {
            history.push('/login');
        }
        dispatch(
            refreshToken({
                token: localStorage.getItem('token'),
                refreshToken: localStorage.getItem('refreshToken')
            })
        );
        dispatch(getClient(client.id));
    }, []);

    return (
        <Card className="record">
            <Card.Title className="record-title">
                {client?.scheduledDate ? <p>Se ha agendado una cita</p> : <p>Una cita está por agendarse</p>}
            </Card.Title>
            <Card.Subtitle className="record-subtitle">
                {client?.scheduledDate ? (
                    <p>
                        Se ha programado una visita, la cual será realizada por {client?.scheduledUser} el próximo {client?.scheduledDate}
                    </p>
                ) : (
                    <p>Una visita está por realizarse, cuando esto se realice, te mostraremos la información correspondiente</p>
                )}
                .
            </Card.Subtitle>
        </Card>
    );
};
export default ScheduleDateCostumerView;
