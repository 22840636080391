import axios from 'axios';

const API_URL = process.env.REACT_APP_BASE_URL;

const postDocuments = (payload: any) => {
    return axios.post(`${API_URL}client/save-flow-documents`, payload);
};

const updateDocuments = (payload: any) => {
    return axios.post(`${API_URL}client/update-flow-documents`, payload);
};

const getDocuments = (clientRef: string) => {
    return axios.get(`${API_URL}client/get-flow-documents/${clientRef}`);
};

const getId = (clientRef: string) => {
    return axios.get(`${API_URL}client/get-id/${clientRef}`);
};

const requestDocuments = (payload: any) => {
    return axios.post(`${API_URL}client/request-flow-documents`, payload);
};

const requestId = (payload: any) => {
    return axios.post(`${API_URL}client/request-id-documents`, payload);
};

const updateIdDocs = (payload: any) => {
    return axios.post(`${API_URL}client/update-id-documents`, payload);
};

const updateSchedulePhotos = (payload: any) => {
    return axios.post(`${API_URL}client/updateSchedulePhotos`, payload);
};

export const API = {
    postDocuments,
    getDocuments,
    requestDocuments,
    updateDocuments,
    getId,
    updateIdDocs,
    updateSchedulePhotos,
    requestId
};
