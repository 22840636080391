import React, { FC, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import '../../../assets/scss/style.scss';
import { Col, Modal, Button, Card, Alert } from 'react-bootstrap';
import { createCredentials, getCredentials, resetUrlTaxInfoStatus } from '../../../flux/actions/satws-actions';
import { getCredit } from '../../../flux/actions/quote-actions';
import { refreshToken } from '../../../flux/actions/refreshToken-action';

const SATLogin = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    const { approvalProcess } = useSelector((state: any) => state.approvalProcessReducer);
    const quote: any = useSelector((state: any) => state.quoteReducer.credit);
    const { client } = useSelector((state: any) => state.clientReducer);
    const satws: any = useSelector((state: any) => state.satwsReducer);

    const [authType, setAuthType] = useState('ciec');
    const [RFC, setRFC] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({ password: '', RFC: '', certificate: '', privateKey: '', login: '' });
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (Object.keys(client).length === 0) {
            history.push('/login');
        }
        dispatch(resetUrlTaxInfoStatus());
        dispatch(
            refreshToken({
                token: localStorage.getItem('token'),
                refreshToken: localStorage.getItem('refreshToken')
            })
        );
    }, []);

    //Function to validate the RFC when we try to post a credential in the sat ws api
    const validateRFC = (rfc: string) => {
        let valid;
        if (rfc.length === 9) {
            valid = '^(([A-Z]|[a-z]){3})([0-9]{6})';
        } else {
            valid = '^[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]$';
        }
        let validRfc = new RegExp(valid);
        let matchArray = rfc.match(validRfc);
        if (matchArray === null) {
            return false;
        } else {
            return true;
        }
    };

    //m,ain function to validate and send the information at the sat ws API
    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const errorRFC = validateRFC(RFC) ? '' : 'No es un RFC válido';
        const passwordErrors = password !== '' ? '' : 'La contraseña no puede estar vacía';

        let stepIndex = 0;
        let nextStep = '';
        const flowApprovalProcess = await approvalProcess.steps.filter((step: any) => {
            return step.clientStatus !== 'generateCreditCircleNIP';
        });

        if (flowApprovalProcess) {
            flowApprovalProcess.forEach((step: any, index: number) => {
                if (step.clientStatus === quote.status) {
                    stepIndex = index + 1;
                    if (stepIndex < flowApprovalProcess.length) {
                        nextStep = flowApprovalProcess[index].continuousApprovalFlow
                            ? flowApprovalProcess[stepIndex].clientStatus
                            : flowApprovalProcess[index].adminStatus;
                    } else if (stepIndex === flowApprovalProcess.length) {
                        nextStep = 'completionOfTheApprovalFlow';
                    }
                }
            });
        }

        setErrors({
            ...errors,
            RFC: errorRFC,
            password: passwordErrors,
            login: ''
        });

        if (errorRFC || passwordErrors) return;

        const payload = {
            type: authType,
            rfc: RFC,
            password,
            flowStatus: nextStep,
            clientRef: client.id
        };
        setShowModal(true);
        dispatch(createCredentials(payload));
    };

    return (
        <Card>
            <Col>
                <div className="satLogin">
                    <div className="col-md-12">
                        <form onSubmit={onSubmit}>
                            <div className="card-body">
                                <h4 className="mb-3 f-w-400 mt-3">Solicitud de información del SAT</h4>
                                <h6 className="mb-3 f-w-400 mt-3 text-muted">
                                    Registra tu credencial del Servicio de Administración Tributaria (SAT)
                                </h6>
                                <div className="form-group fill">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="RFC"
                                        placeholder="RFC"
                                        maxLength={13}
                                        style={{ textTransform: 'uppercase' }}
                                        autoComplete="off"
                                        value={RFC}
                                        onChange={(e) => {
                                            setRFC(e.target.value.trim().toLocaleUpperCase());
                                            setErrors({ ...errors, RFC: '' });
                                        }}
                                    />
                                    {errors.RFC && <p className="form-text text-left text-danger">{errors.RFC}</p>}
                                </div>
                                <div className="form-group fill mb-3 text-left d-flex flex-row">
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        placeholder="Contraseña"
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setPassword(e.target.value.trim());
                                            setErrors({ ...errors, password: '' });
                                        }}
                                    />
                                </div>
                                {errors.password && (
                                    <p id="passwordHelpBlock" className="form-text text-left text-danger">
                                        {errors.password}
                                    </p>
                                )}
                                {errors.login && (
                                    <p id="loginHelpBlock" className="form-text text-left text-danger">
                                        {errors.login}
                                    </p>
                                )}
                                <a href="https://satid.sat.gob.mx/" target="__blank" className="satLogin-link">
                                    Da clic para consultar tu CIEC
                                </a>
                                <button className="btn mb-4 btn-primary btn-border-radius border-0 bold satLogin-button" type="submit">
                                    <b>Acceder</b>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Col>
            {satws.success && (
                <Alert className="m-2 d-flex justify-content-between" style={{ position: 'relative' }} variant={'success'}>
                    <strong className="m-auto">{satws.successMessage}.</strong>
                </Alert>
            )}
            {satws.error && (
                <Alert className="m-2 d-flex justify-content-between" style={{ position: 'relative' }} variant={'danger'}>
                    <strong className="m-auto">{satws.errorMessage}.</strong>
                </Alert>
            )}
            <Col md={6} />
            <Modal show={showModal} onHide={() => setShowModal(!showModal)}>
                <Modal.Header closeButton>
                    <Modal.Title as="h5"></Modal.Title>
                </Modal.Header>
                <Modal.Body className="satLogin-modal-body">
                    <br />
                    <p>Información enviada correctamente</p>
                    <br />
                </Modal.Body>
                <Modal.Footer className="satLogin-modal-footer">
                    <Button
                        className="ml-3 btn-border-radius mt-3 mb-3"
                        onClick={() => {
                            setShowModal(!showModal);
                            dispatch(getCredit(client.id));
                            dispatch(
                                refreshToken({
                                    token: localStorage.getItem('token'),
                                    refreshToken: localStorage.getItem('refreshToken')
                                })
                            );
                        }}
                    >
                        Aceptar
                    </Button>
                </Modal.Footer>
            </Modal>
        </Card>
    );
};
export default SATLogin;
