import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import Datetime from 'react-datetime';
import validator from 'validator';
import { Card, Form, Col, Row, Modal, Button } from 'react-bootstrap';
import moment from 'moment';
import { updateClient, resetClientStatus } from '../../../../flux/actions/client-actions';
import Loader from '../../../../components/ScreenLoader/ScreenLoader';
import { MapContainer, TileLayer, useMapEvents, Marker } from 'react-leaflet';

const genders = [
    { value: 1, label: 'Femenino' },
    { value: 2, label: 'Masculino' },
    { value: 3, label: 'No Binario' },
    { value: 4, label: 'No Deseo decirlo' }
];

const civilStatusOptions = [
    { value: 1, label: 'Soltero' },
    { value: 2, label: 'Casado' }
];

const taxRegimes = [
    { value: 'PF', label: 'Persona Física' },
    { value: 'PM', label: 'Persona Moral' }
];

const countrys = [
    { value: '1', label: 'México' },
    { value: '2', label: 'Afganistán' },
    { value: '3', label: 'Albania' },
    { value: '4', label: 'Alemania' },
    { value: '5', label: 'Andorra' },
    { value: '6', label: 'Angola' },
    { value: '7', label: 'Antigua y Barbuda' },
    { value: '8', label: 'Arabia Saudita' },
    { value: '9', label: 'Argelia' },
    { value: '10', label: 'Argentina' },
    { value: '11', label: 'Armenia' },
    { value: '12', label: 'Australia' },
    { value: '13', label: 'Austria' },
    { value: '14', label: 'Azerbaiyán' },
    { value: '15', label: 'Bahamas' },
    { value: '16', label: 'Bangladés' },
    { value: '17', label: 'Barbados' },
    { value: '18', label: 'Baréin' },
    { value: '19', label: 'Bélgica' },
    { value: '20', label: 'Belice' },
    { value: '21', label: 'Benín' },
    { value: '22', label: 'Bielorrusia' },
    { value: '23', label: 'Birmania' },
    { value: '24', label: 'Bolivia' },
    { value: '25', label: 'Bosnia y Herzegovina' },
    { value: '26', label: 'Botsuana' },
    { value: '27', label: 'Brasil' },
    { value: '28', label: 'Brunéi' },
    { value: '29', label: 'Bulgaria' },
    { value: '30', label: 'Burkina Faso' },
    { value: '31', label: 'Burundi' },
    { value: '32', label: 'Bután' },
    { value: '33', label: 'Cabo Verde' },
    { value: '34', label: 'Camboya' },
    { value: '35', label: 'Camerún' },
    { value: '36', label: 'Canadá' },
    { value: '37', label: 'Catar' },
    { value: '38', label: 'Chad' },
    { value: '39', label: 'Chile' },
    { value: '40', label: 'China' },
    { value: '41', label: 'Chipre' },
    { value: '42', label: 'Ciudad del Vaticano' },
    { value: '43', label: 'Colombia' },
    { value: '44', label: 'Comoras' },
    { value: '45', label: 'Corea del Norte' },
    { value: '46', label: 'Corea del Sur' },
    { value: '47', label: 'Costa de Marfil' },
    { value: '48', label: 'Costa Rica' },
    { value: '49', label: 'Croacia' },
    { value: '50', label: 'Cuba' },
    { value: '51', label: 'Dinamarca' },
    { value: '52', label: 'Dominica' },
    { value: '53', label: 'Ecuador' },
    { value: '54', label: 'Egipto' },
    { value: '55', label: 'El Salvador' },
    { value: '56', label: 'Emiratos Árabes Unidos' },
    { value: '57', label: 'Eritrea' },
    { value: '58', label: 'Eslovaquia' },
    { value: '59', label: 'Eslovenia' },
    { value: '60', label: 'España' },
    { value: '61', label: 'Estados Unidos' },
    { value: '62', label: 'Estonia' },
    { value: '63', label: 'Etiopía' },
    { value: '64', label: 'Filipinas' },
    { value: '65', label: 'Finlandia' },
    { value: '66', label: 'Fiyi' },
    { value: '67', label: 'Francia' },
    { value: '68', label: 'Gabón' },
    { value: '69', label: 'Gambia' },
    { value: '70', label: 'Georgia' },
    { value: '71', label: 'Ghana' },
    { value: '72', label: 'Granada' },
    { value: '73', label: 'Grecia' },
    { value: '74', label: 'Guatemala' },
    { value: '75', label: 'Guyana' },
    { value: '76', label: 'Guinea' },
    { value: '77', label: 'Guinea ecuatorial' },
    { value: '78', label: 'Guinea-Bisáu' },
    { value: '79', label: 'Haití' },
    { value: '80', label: 'Honduras' },
    { value: '81', label: 'Hungría' },
    { value: '82', label: 'India' },
    { value: '83', label: 'Indonesia' },
    { value: '84', label: 'Irak' },
    { value: '85', label: 'Irán' },
    { value: '86', label: 'Irlanda' },
    { value: '87', label: 'Islandia' },
    { value: '88', label: 'Islas Marshall' },
    { value: '89', label: 'Islas Salomón' },
    { value: '90', label: 'Israel' },
    { value: '91', label: 'Italia' },
    { value: '92', label: 'Jamaica' },
    { value: '93', label: 'Japón' },
    { value: '94', label: 'Jordania' },
    { value: '95', label: 'Kazajistán' },
    { value: '96', label: 'Kenia' },
    { value: '97', label: 'Kirguistán' },
    { value: '98', label: 'Kiribati' },
    { value: '99', label: 'Kuwait' },
    { value: '100', label: 'Laos' },
    { value: '101', label: 'Lesoto' },
    { value: '102', label: 'Letonia' },
    { value: '103', label: 'Líbano' },
    { value: '104', label: 'Liberia' },
    { value: '105', label: 'Libia' },
    { value: '106', label: 'Liechtenstein' },
    { value: '107', label: 'Lituania' },
    { value: '108', label: 'Luxemburgo' },
    { value: '109', label: 'Madagascar' },
    { value: '110', label: 'Malasia' },
    { value: '111', label: 'Malaui' },
    { value: '112', label: 'Maldivas' },
    { value: '113', label: 'Malí' },
    { value: '114', label: 'Malta' },
    { value: '115', label: 'Marruecos' },
    { value: '116', label: 'Mauricio' },
    { value: '117', label: 'Mauritania' },
    { value: '118', label: 'Micronesia' },
    { value: '119', label: 'Moldavia' },
    { value: '120', label: 'Mónaco' },
    { value: '121', label: 'Mongolia' },
    { value: '122', label: 'Montenegro' },
    { value: '123', label: 'Mozambique' },
    { value: '124', label: 'Namibia' },
    { value: '125', label: 'Nauru' },
    { value: '126', label: 'Nepal' },
    { value: '127', label: 'Nicaragua' },
    { value: '128', label: 'Níger' },
    { value: '129', label: 'Nigeria' },
    { value: '130', label: 'Noruega' },
    { value: '131', label: 'Nueva Zelanda' },
    { value: '132', label: 'Omán' },
    { value: '133', label: 'Países Bajos' },
    { value: '134', label: 'Pakistán' },
    { value: '135', label: 'Palaos' },
    { value: '136', label: 'Palestina' },
    { value: '137', label: 'Panamá' },
    { value: '138', label: 'Papúa Nueva Guinea' },
    { value: '139', label: 'Paraguay' },
    { value: '140', label: 'Perú' },
    { value: '141', label: 'Polonia' },
    { value: '142', label: 'Portugal' },
    { value: '143', label: 'Reino Unido' },
    { value: '144', label: 'República Centroafricana' },
    { value: '145', label: 'República Checa' },
    { value: '146', label: 'República de Macedonia' },
    { value: '147', label: 'República del Congo' },
    { value: '148', label: 'República Democrática del Congo' },
    { value: '149', label: 'República Dominicana' },
    { value: '150', label: 'República Sudafricana' },
    { value: '151', label: 'Ruanda' },
    { value: '152', label: 'Rumanía' },
    { value: '153', label: 'Rusia' },
    { value: '154', label: 'Samoa' },
    { value: '155', label: 'San Cristóbal y Nieves' },
    { value: '156', label: 'San Marino' },
    { value: '157', label: 'San Vicente y las Granadinas' },
    { value: '158', label: 'Santa Lucía' },
    { value: '159', label: 'Santo Tomé y Príncipe' },
    { value: '160', label: 'Senegal' },
    { value: '161', label: 'Serbia' },
    { value: '162', label: 'Seychelles' },
    { value: '163', label: 'Sierra Leona' },
    { value: '164', label: 'Singapur' },
    { value: '165', label: 'Siria' },
    { value: '166', label: 'Somalia' },
    { value: '167', label: 'Sri Lanka' },
    { value: '168', label: 'Suazilandia' },
    { value: '169', label: 'Sudán' },
    { value: '170', label: 'Sudán del Sur' },
    { value: '171', label: 'Suecia' },
    { value: '172', label: 'Suiza' },
    { value: '173', label: 'Surinam' },
    { value: '174', label: 'Tailandia' },
    { value: '175', label: 'Tanzania' },
    { value: '176', label: 'Tayikistán' },
    { value: '177', label: 'Timor Oriental' },
    { value: '178', label: 'Togo' },
    { value: '179', label: 'Tonga' },
    { value: '180', label: 'Trinidad y Tobago' },
    { value: '181', label: 'Túnez' },
    { value: '182', label: 'Turkmenistán' },
    { value: '183', label: 'Turquía' },
    { value: '184', label: 'Tuvalu' },
    { value: '185', label: 'Ucrania' },
    { value: '186', label: 'Uganda' },
    { value: '187', label: 'Uruguay' },
    { value: '188', label: 'Uzbekistán' },
    { value: '189', label: 'Vanuatu' },
    { value: '190', label: 'Venezuela' },
    { value: '191', label: 'Vietnam' },
    { value: '192', label: 'Yemen' },
    { value: '193', label: 'Yibuti' },
    { value: '194', label: 'Zambia' },
    { value: '195', label: 'Zimbabue' }
];

const states = [
    { value: '1', label: 'Aguascalientes' },
    { value: '2', label: 'Baja California' },
    { value: '3', label: 'Baja California Sur' },
    { value: '4', label: 'Campeche' },
    { value: '5', label: 'Chiapas' },
    { value: '6', label: 'Chihuahua' },
    { value: '7', label: 'Coahuila de Zaragoza' },
    { value: '8', label: 'Colima' },
    { value: '9', label: 'Distrito Federal' },
    { value: '10', label: 'Durango' },
    { value: '11', label: 'Guanajuato' },
    { value: '12', label: 'Guerrero' },
    { value: '13', label: 'Hidalgo' },
    { value: '14', label: 'Jalisco' },
    { value: '15', label: 'México' },
    { value: '16', label: 'Michoacán de Ocampo' },
    { value: '17', label: 'Morelos' },
    { value: '18', label: 'Nayarit' },
    { value: '19', label: 'Nuevo León' },
    { value: '20', label: 'Oaxaca' },
    { value: '21', label: 'Puebla' },
    { value: '22', label: 'Querétaro' },
    { value: '23', label: 'Quintana Roo' },
    { value: '24', label: 'San Luis Potosí' },
    { value: '25', label: 'Sinaloa' },
    { value: '26', label: 'Sonora' },
    { value: '27', label: 'Tabasco' },
    { value: '28', label: 'Tamaulipas' },
    { value: '29', label: 'Tlaxcala' },
    { value: '30', label: 'Veracruz de Ignacio de la Llave' },
    { value: '31', label: 'Yucatán' },
    { value: '32', label: 'Zacatecas' }
];

const PersonalInfo: React.FC = () => {
    //const to get the params
    const dispatch = useDispatch();
    const { client, loading } = useSelector((state: any) => state.clientReducer);

    //PERSONAL INFORMATION CONST
    const [names, setNames] = useState('');
    const [lastName1, setlastName1] = useState('');
    const [lastName2, setlastName2] = useState('');
    const [gender, setGender] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [nationality, setNationality] = useState('');
    const [countryBirth, setCountryBirth] = useState('');
    const [placeBirth, setPlaceBirth] = useState('');
    const [country, setCountry] = useState('');
    const [phone, setPhone] = useState('');
    const [civilStatus, setCivilStatus] = useState('');
    const [profesion, setProfesion] = useState('');
    const [taxRegime, setTaxRegime] = useState('');
    const [CURP, setCURP] = useState('');
    const [RFC, setRFC] = useState('');
    const [email, setEmail] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    //Constantes de Edicion sirven para habilitar y deshabilitar partes del código
    const [isPersonalEdit, setIsPersonalEdit] = useState(false);
    const [showModal, setShowModal] = useState(false);

    //Se crea y se Inicializa un objeto para los posibles errores de Información personal
    const [errorsPersonalInfo, setErrorsPersonalInfo] = useState({
        names: '',
        lastName1: '',
        lastName2: '',
        phoneNumber: '',
        birthdate: '',
        gender: '',
        CURP: '',
        RFC: '',
        profesion: '',
        civilStatus: '',
        email: '',
        nationality: '',
        countryBirth: '',
        country: '',
        placeBirth: ''
    });

    //Se Definen las variables de Información personal del cliente
    useEffect(() => {
        if (Object.keys(client).length !== 0) {
            setNames(client.names || '');
            setlastName1(client.lastName1 || '');
            setlastName2(client.lastName2 || '');
            setGender(client.gender || '');
            setPhone(client.phone || '');
            setBirthDate(moment(client.birthDate).format('L'));
            setNationality(client.nationality || '');
            setCountryBirth(client.countryBirth || '');
            setCountry(client.country || '');
            setPlaceBirth(client.placeBirth || '');
            setCivilStatus(client.civilStatus || '');
            setProfesion(client.profesion || '');
            setCURP(client.CURP || '');
            setTaxRegime(client.taxRegime || '');
            setRFC(client.RFC || '');
            setEmail(client.email || '');
            setLatitude(client.latitude || '');
            setLongitude(client.longitude || '');
        }
    }, [client]);

    ////////////VALIDATIONS////////////////////////
    // Validacion los los campos de input sensibles
    // Validacion de la curp solo se caracteres especificados
    const validateCURP = (curp: string) => {
        curp.toUpperCase();
        var re =
                /^[A-Z]{4}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/,
            validado = curp.match(re);

        if (!validado)
            //Coincide con el formato general?
            return false;
        return true; //Validado
    };

    // Validacion de la curp solo se aceptan caracteres expecificados
    //validate the RFC with a normal expression
    const validateRFC = (rfc: string) => {
        let valid = '^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([A-Z]|[0-9]){2}([A]|[0-9]){1})?';
        let validRfc = new RegExp(valid);
        let matchArray = rfc.match(validRfc);
        if (matchArray === null) {
            return false;
        } else {
            return true;
        }
    };

    function LocationMarker() {
        const map = useMapEvents({
            click() {
                map.flyTo([Number(latitude), Number(longitude)], 17);
            }
        });

        setTimeout(function () {
            map.invalidateSize(true);
        }, 100);

        return <Marker position={[Number(latitude), Number(longitude)]}></Marker>;
    }

    //Funcion para actualizar la Información en la base de datos y manejar los posibles errores
    const savePersonalInfo = () => {
        const range = moment().diff(moment(birthDate, 'DD/MM/YYYY'), 'years');
        const errorName = names === '' ? 'Campo requerido' : '';
        //constantes manejadoras de errrores
        const errorlastName1 = lastName1 === '' ? 'Campo requerido' : '';
        const errorlastName2 = lastName2 === '' ? 'Campo requerido' : '';
        const errorBirthday = range >= 18 ? '' : 'Necesitas la mayoria de edad para poder continuar';
        const errorGender = gender === '0' ? 'Favor de seleccionar una opción' : '';
        const errorCURP = validateCURP(CURP || '') ? '' : 'No es una CURP válida';
        const errorRFC = validateRFC(RFC || '') ? '' : 'No es un RFC válido';
        const errorPhone = validator.isMobilePhone(phone || '', 'es-MX') ? '' : 'Ingresar un teléfono válido';
        const errorprofesion = profesion === '' ? 'Este campo no puede estar vacío' : '';
        const errorCivilStatus = civilStatus === '0' ? 'Favor de seleccionar una opción' : '';
        const errorEmail = email === '' ? 'Favor de seleccionar una opción' : '';
        const errorCountryBirth = countryBirth === '0' ? 'Favor de seleccionar una opción' : '';
        const errorCountry = country === '0' ? 'Favor de seleccionar una opción' : '';
        const errorNationality = nationality === '' ? 'Campo requerido' : '';
        const errorPlaceBirth = placeBirth === '0' ? 'Favor de seleccionar una opción' : '';

        //Se asignan posibles errores en el objeto
        setErrorsPersonalInfo({
            birthdate: errorBirthday,
            gender: errorGender,
            CURP: errorCURP,
            RFC: errorRFC,
            profesion: errorprofesion,
            civilStatus: errorCivilStatus,
            names: errorName,
            lastName1: errorlastName1,
            lastName2: errorlastName2,
            phoneNumber: errorPhone,
            email: errorEmail,
            nationality: errorNationality,
            countryBirth: errorCountryBirth,
            country: errorCountry,
            placeBirth: errorPlaceBirth
        });

        if (
            errorBirthday ||
            errorGender ||
            errorCURP ||
            errorRFC ||
            errorprofesion ||
            errorCivilStatus ||
            errorPhone ||
            errorEmail ||
            errorCountryBirth ||
            errorCountry ||
            errorNationality ||
            errorPlaceBirth
        )
            return;

        //Actualiza la Información si no exite ningun error
        dispatch(
            updateClient({
                id: client.id,
                names,
                lastName1,
                lastName2,
                phone,
                birthDate: new Date(moment(birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD[T]HH:mm:ss')),
                gender,
                CURP: CURP.toLocaleUpperCase(),
                RFC: RFC.toLocaleUpperCase(),
                profesion,
                taxRegime,
                civilStatus,
                email,
                nationality,
                countryBirth,
                country,
                placeBirth
            })
        );

        setShowModal(!showModal);
        setIsPersonalEdit(!isPersonalEdit); //Oculta la opcion de edicion
    };

    return loading ? (
        <div className="auth-wrapper align-items-center">
            <Loader isOpen={true} />
        </div>
    ) : (
        <>
            <Card>
                <Card.Body className="d-flex align-items-center justify-content-between">
                    <h5 className="mb-0">Datos Personales</h5>
                    <button
                        type="button"
                        className="btn btn-primary btn-sm btn-border-radius m-0 float-right customerProfile-editBtn"
                        onClick={() => {
                            dispatch(resetClientStatus);
                            setIsPersonalEdit(!isPersonalEdit);
                        }}
                    >
                        <i className={isPersonalEdit ? 'feather icon-x' : 'feather icon-edit'} />
                    </button>
                </Card.Body>
                <Card.Body className={isPersonalEdit ? 'border-top pro-det-edit collapse' : 'border-top pro-det-edit collapse show'}>
                    <Row>
                        <Col className="form-group" lg={4}>
                            <label className="font-weight-bolder mt-3">Nombre(s):</label>
                            <p className="mt-3">{names}</p>
                        </Col>
                        <Col className="form-group ">
                            <label className="font-weight-bolder mt-3">Apellido Paterno: </label>
                            <p className="mt-3">{lastName1}</p>
                        </Col>
                        <Col className="form-group" lg={4}>
                            <label className="font-weight-bolder mt-3">Apellido Materno: </label>
                            <p className="mt-3">{lastName2}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="form-group" lg={4}>
                            <label className="font-weight-bolder mt-3">Fecha de Nacimiento: </label>
                            <p className="mt-3">{birthDate}</p>
                        </Col>
                        <Col className="form-group" lg={4}>
                            <label className="font-weight-bolder mt-3">Nacionalidad: </label>
                            <p className="mt-3">{nationality}</p>
                        </Col>
                        <Col className="form-group" lg={4}>
                            <label className="font-weight-bolder mt-3">País de nacimiento: </label>
                            <p className="mt-3">{countryBirth}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="form-group" lg={4}>
                            <label className="font-weight-bolder mt-3">Entidad Federativa de nacimiento: </label>
                            <p className="mt-3">{placeBirth}</p>
                        </Col>
                        <Col className="form-group" lg={4}>
                            <label className="font-weight-bolder mt-3">País de residencia: </label>
                            <p className="mt-3">{country}</p>
                        </Col>
                        <Col className="form-group" lg={4}>
                            <label className="font-weight-bolder mt-3">Correo: </label>
                            <p className="mt-3">{email}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="form-group" lg={4}>
                            <label className="font-weight-bolder mt-3">Teléfono: </label>
                            <p className="mt-3">{phone}</p>
                        </Col>
                        <Col className="form-group" lg={4}>
                            <label className="font-weight-bolder mt-3">Género: </label>
                            <p className="mt-3">{gender}</p>
                        </Col>
                        <Col className="form-group" lg={4}>
                            <label className="font-weight-bolder mt-3">Estado Civil: </label>
                            <p className="mt-3">{civilStatus}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="form-group" lg={4}>
                            <label className="font-weight-bolder mt-3">Profesión: </label>
                            <p className="mt-3">{profesion}</p>
                        </Col>
                        <Col className="form-group" lg={4}>
                            <label className="font-weight-bolder mt-3">CURP: </label>
                            <p className="mt-3">{CURP}</p>
                        </Col>
                        <Col className="form-group" lg={4}>
                            <label className="font-weight-bolder mt-3">Régimen Fiscal: </label>
                            <p className="mt-3">{taxRegime === 'PM' ? 'P. Moral' : 'P. Física'}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="form-group" lg={4}>
                            <label className="font-weight-bolder mt-3">RFC: </label>
                            <p className="mt-3">{RFC}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="form-group" lg={12}>
                            <label className="font-weight-bolder mt-3">Ubicación donde realizó la solicitud: </label>
                            {(Number(latitude) !== 0 || Number(longitude) !== 0) && (
                                <MapContainer center={[Number(latitude), Number(longitude)]} zoom={5} scrollWheelZoom={false}>
                                    <TileLayer
                                        attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <Marker position={[Number(latitude), Number(longitude)]}></Marker>
                                    <LocationMarker />
                                </MapContainer>
                            )}
                            {Number(latitude) == 0 && Number(longitude) == 0 && (
                                <p className="mt-3">
                                    Información no disponible | No se solicitó Geolocalización al momento de llenar la Solicitud.
                                </p>
                            )}
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Body className={isPersonalEdit ? 'border-top pro-det-edit collapse show' : 'border-top pro-det-edit collapse'}>
                    <Row className="form-group">
                        <Col lg={4}>
                            <label className="font-weight-bolder">Nombre(s):</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Nombres"
                                value={names}
                                onChange={(e) => {
                                    setNames(e.target.value);
                                    setErrorsPersonalInfo({ ...errorsPersonalInfo, names: '' });
                                }}
                            />
                            {errorsPersonalInfo.names && (
                                <p id="lastName1" className="form-text text-left text-danger">
                                    {errorsPersonalInfo.names}
                                </p>
                            )}
                        </Col>
                        <Col lg={4}>
                            <label className="font-weight-bolder">Apellido Paterno:</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Apellido Paterno"
                                value={lastName1}
                                onChange={(e) => {
                                    setlastName1(e.target.value);
                                    setErrorsPersonalInfo({ ...errorsPersonalInfo, lastName1: '' });
                                }}
                            />

                            {errorsPersonalInfo.lastName1 && (
                                <p id="lastName1" className="form-text text-left text-danger">
                                    {errorsPersonalInfo.lastName1}
                                </p>
                            )}
                        </Col>
                        <Col lg={4}>
                            <label className="font-weight-bolder">Apellido Materno:</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Apellido Materno"
                                value={lastName2}
                                onChange={(e) => {
                                    setlastName2(e.target.value);
                                    setErrorsPersonalInfo({ ...errorsPersonalInfo, lastName2: '' });
                                }}
                            />
                            {errorsPersonalInfo.lastName2 && (
                                <p id="lastName1" className="form-text text-left text-danger">
                                    {errorsPersonalInfo.lastName2}
                                </p>
                            )}
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Col lg={4}>
                            <label className="font-weight-bolder">Fecha de Nacimiento: </label>
                            <Datetime
                                timeFormat={false}
                                className="pl-0"
                                inputProps={{ placeholder: 'Fecha de Nacimiento' }}
                                value={birthDate}
                                onChange={(e) => {
                                    setBirthDate(moment(e).format('L').toString());
                                    setErrorsPersonalInfo({ ...errorsPersonalInfo, birthdate: '' });
                                }}
                            />
                            {errorsPersonalInfo.birthdate && (
                                <p id="lastName1" className="form-text text-left text-danger">
                                    {errorsPersonalInfo.birthdate}
                                </p>
                            )}
                        </Col>
                        <Col lg={4}>
                            <label className="font-weight-bolder">Nacionalidad:</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Nacionalidad"
                                value={nationality}
                                onChange={(e) => {
                                    setNationality(e.target.value);
                                    setErrorsPersonalInfo({ ...errorsPersonalInfo, nationality: '' });
                                }}
                            />

                            {errorsPersonalInfo.nationality && (
                                <p id="nationality" className="form-text text-left text-danger">
                                    {errorsPersonalInfo.nationality}
                                </p>
                            )}
                        </Col>
                        <Col lg={4}>
                            <label className="font-weight-bolder">País de Nacimiento: </label>
                            <Form.Group className="pl-0">
                                <Form.Control
                                    as="select"
                                    defaultValue={countryBirth}
                                    onChange={(e) => {
                                        setCountryBirth(e.target.value);
                                        setErrorsPersonalInfo({ ...errorsPersonalInfo, countryBirth: '' });
                                    }}
                                >
                                    {countrys.map((countryBirth) => {
                                        return <option value={countryBirth.label}>{countryBirth.label}</option>;
                                    })}
                                </Form.Control>
                            </Form.Group>
                            {errorsPersonalInfo.countryBirth && (
                                <p id="countryBirth" className="form-text text-left text-danger">
                                    {errorsPersonalInfo.countryBirth}
                                </p>
                            )}
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Col lg={4}>
                            <label className="font-weight-bolder">Entidad Federativa de nacimiento: </label>
                            <Form.Group className="pl-0">
                                <Form.Control
                                    as="select"
                                    defaultValue={placeBirth}
                                    onChange={(e) => {
                                        setPlaceBirth(e.target.value);
                                        setErrorsPersonalInfo({ ...errorsPersonalInfo, placeBirth: '' });
                                    }}
                                >
                                    {states.map((placeBirth) => {
                                        return <option value={placeBirth.label}>{placeBirth.label}</option>;
                                    })}
                                </Form.Control>
                            </Form.Group>
                            {errorsPersonalInfo.placeBirth && (
                                <p id="lastName1" className="form-text text-left text-danger">
                                    {errorsPersonalInfo.placeBirth}
                                </p>
                            )}
                        </Col>
                        <Col lg={4}>
                            <label className="font-weight-bolder">País de residencia: </label>
                            <Form.Group className="pl-0">
                                <Form.Control
                                    as="select"
                                    defaultValue={country}
                                    onChange={(e) => {
                                        setCountry(e.target.value);
                                        setErrorsPersonalInfo({ ...errorsPersonalInfo, country: '' });
                                    }}
                                >
                                    {countrys.map((country) => {
                                        return <option value={country.label}>{country.label}</option>;
                                    })}
                                </Form.Control>
                            </Form.Group>
                            {errorsPersonalInfo.country && (
                                <p id="country" className="form-text text-left text-danger">
                                    {errorsPersonalInfo.country}
                                </p>
                            )}
                        </Col>
                        <Col lg={4}>
                            <label className="font-weight-bolder">Correo: </label>
                            <Form.Group className="pl-0">
                                <Form.Control
                                    type="text"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        setErrorsPersonalInfo({ ...errorsPersonalInfo, email: '' });
                                    }}
                                ></Form.Control>
                            </Form.Group>
                            {errorsPersonalInfo.phoneNumber && (
                                <p id="lastName1" className="form-text text-left text-danger">
                                    {errorsPersonalInfo.phoneNumber}
                                </p>
                            )}
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Col lg={4}>
                            <label className="font-weight-bolder">Teléfono: </label>
                            <Form.Group className="pl-0">
                                <Form.Control
                                    type="text"
                                    defaultValue={phone}
                                    onChange={(e) => {
                                        setPhone(e.target.value);
                                        setErrorsPersonalInfo({ ...errorsPersonalInfo, phoneNumber: '' });
                                    }}
                                ></Form.Control>
                            </Form.Group>
                            {errorsPersonalInfo.phoneNumber && (
                                <p id="lastName1" className="form-text text-left text-danger">
                                    {errorsPersonalInfo.phoneNumber}
                                </p>
                            )}
                        </Col>
                        <Col lg={4}>
                            <label className="font-weight-bolder">Género: </label>
                            <Form.Group className="pl-0">
                                <Form.Control
                                    as="select"
                                    defaultValue={gender}
                                    onChange={(e) => {
                                        setGender(e.target.value);
                                        setErrorsPersonalInfo({ ...errorsPersonalInfo, gender: '' });
                                    }}
                                >
                                    {genders.map((gender) => {
                                        return <option value={gender.label}>{gender.label}</option>;
                                    })}
                                </Form.Control>
                            </Form.Group>
                            {errorsPersonalInfo.gender && (
                                <p id="lastName1" className="form-text text-left text-danger">
                                    {errorsPersonalInfo.gender}
                                </p>
                            )}
                        </Col>
                        <Col lg={4}>
                            <label className="font-weight-bolder">Estado Civil: </label>
                            <Form.Group className="pl-0">
                                <Form.Control
                                    as="select"
                                    onChange={(e) => {
                                        setCivilStatus(e.target.value);
                                        setErrorsPersonalInfo({ ...errorsPersonalInfo, civilStatus: '' });
                                    }}
                                >
                                    {civilStatusOptions.map((civilStatus) => {
                                        return <option value={civilStatus.label}>{civilStatus.label}</option>;
                                    })}
                                </Form.Control>
                            </Form.Group>
                            {errorsPersonalInfo.civilStatus && (
                                <p id="lastName1" className="form-text text-left text-danger">
                                    {errorsPersonalInfo.civilStatus}
                                </p>
                            )}
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Col lg={4}>
                            <label className="font-weight-bolder">Profesión:</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Profesión"
                                value={profesion}
                                onChange={(e) => {
                                    setProfesion(e.target.value);
                                    setErrorsPersonalInfo({ ...errorsPersonalInfo, profesion: '' });
                                }}
                            />
                            {errorsPersonalInfo.profesion && (
                                <p id="lastName1" className="form-text text-left text-danger">
                                    {errorsPersonalInfo.profesion}
                                </p>
                            )}
                        </Col>
                        <Col lg={4}>
                            <label className="font-weight-bolder">CURP:</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="CURP"
                                value={CURP}
                                maxLength={18}
                                style={{ textTransform: 'uppercase' }}
                                onChange={(e) => {
                                    setCURP(e.target.value.trim().toLocaleUpperCase());
                                    setErrorsPersonalInfo({ ...errorsPersonalInfo, CURP: '' });
                                }}
                            />
                            {errorsPersonalInfo.CURP && (
                                <p id="lastName1" className="form-text text-left text-danger">
                                    {errorsPersonalInfo.CURP}
                                </p>
                            )}
                        </Col>
                        <Col lg={4}>
                            <label className="font-weight-bolder">Régimen Fiscal: </label>
                            <Form.Group className="pl-0">
                                <Form.Control
                                    as="select"
                                    value={taxRegime}
                                    onChange={(e) => {
                                        setTaxRegime(e.target.value.trim());
                                        setErrorsPersonalInfo({ ...errorsPersonalInfo, civilStatus: '' });
                                    }}
                                >
                                    {taxRegimes.map((taxRegime) => {
                                        return <option value={taxRegime.value}>{taxRegime.label}</option>;
                                    })}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Col lg={4}>
                            <label className="font-weight-bolder">RFC:</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="RFC"
                                maxLength={10}
                                value={RFC}
                                style={{ textTransform: 'uppercase' }}
                                onChange={(e) => {
                                    setRFC(e.target.value.trim().toLocaleUpperCase());
                                    setErrorsPersonalInfo({ ...errorsPersonalInfo, RFC: '' });
                                }}
                            />
                            {errorsPersonalInfo.RFC && (
                                <p id="lastName1" className="form-text text-left text-danger">
                                    {errorsPersonalInfo.RFC}
                                </p>
                            )}
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Col className="d-flex justify-content-end">
                            <button
                                type="submit"
                                className="btn btn-primary btn-border-radius customerProfile-saveBtn"
                                onClick={() => setShowModal(!showModal)}
                            >
                                Guardar
                            </button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Modal show={showModal} onHide={() => setShowModal(!showModal)}>
                <Modal.Header closeButton>
                    <Modal.Title as="h5">Actualización de información</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    La información del usuario ha sido modificada
                    <br />
                    <br />
                    ¿Deseas continuar con la actualización de la información del usuario?
                </Modal.Body>
                <Modal.Footer>
                    <Row className="mt-3 mb-3">
                        <Button className="btn-border-radius" variant="danger" onClick={() => setShowModal(!showModal)}>
                            Cancelar
                        </Button>
                        <Button className="ml-3 btn-border-radius" variant="secondary" onClick={savePersonalInfo}>
                            Continuar
                        </Button>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default PersonalInfo;
