import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Table } from 'react-bootstrap';
import { getPreviusData } from '../../flux/actions/PreviusData-action';
import { refreshToken } from '../../flux/actions/refreshToken-action';

const Querys: React.FC = () => {
    const dispatch = useDispatch();
    const { previusData, success } = useSelector((state: any) => state.previusDataReducer);

    useEffect(() => {
        dispatch(getPreviusData());
        dispatch(
            refreshToken({
                token: localStorage.getItem('token'),
                refreshToken: localStorage.getItem('refreshToken')
            })
        );
    }, []);

    return (
        <Row className='querys'>
            <Col sm={12}>
                <Card className='querys-card'>
                    <Card.Body>
                        <Card.Title className='querys-title' as="h4">Consultas</Card.Title>
                        <Table striped responsive className='querys-table'>
                            <thead>
                                <tr>
                                    <th>NOMBRE</th>
                                    <th>TELÉFONO</th>
                                    <th>CORREO</th>
                                </tr>
                            </thead>
                            <tbody>
                                {previusData[0] &&
                                    success &&
                                    previusData.map((data: any) => {
                                        return (
                                            <tr className="mt-5 mb-5">
                                                <th className="mt-5" key={1}>
                                                    <h5>
                                                        {data.names} {data.lastName1} {data.lastName2}
                                                    </h5>
                                                </th>
                                                <th className="mt-5" key={3}>
                                                    <a href={`tel:${data.phone}`}>{data.phone}</a>
                                                </th>
                                                <th className="mt-5" key={2}>
                                                    <a href={`mailto:${data.email}`}>{data.email}</a>
                                                </th>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default Querys;