import { Step } from '@mui/material';
import React, { FC, useState } from 'react';
import { Card, Row, Col, Button, Form, Table } from 'react-bootstrap';

var mobile = require('is-mobile');
const taxRegimes = [
    { value: 'PF', label: 'Persona Física' },
    { value: 'PM', label: 'Persona Moral' },
    { value: 'AM', label: 'Ambos' }
];

interface Props {
    steps: Object[];
    activeStep: Boolean[];
    finalStep: Boolean[];
    onAddStep?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | any;
    upStep?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | any;
    downStep?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | any;
    upInfo?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | any;
    downInfo?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | any;
    onDelete?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | any;
    onDeleteInfo?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | any;
    nameInputHandler?: (e: React.ChangeEvent<HTMLInputElement>, data?: any) => void;
    descriptionInputHandler?: (e: React.ChangeEvent<HTMLInputElement>, data?: any) => void;
    descriptionOTPHandler?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    taxRegimeInputHandler?: (e: React.ChangeEvent<HTMLInputElement>, data?: any) => void;
    switchHandler?: (e: any) => void | any;
    finalStepSwitchHandler?: (e: any) => void | any;
    isRequiredHandler?: (e: any) => void | any;
    confirmInfoHandler?: (e: any) => void | any;
}

const FlowBuilder: FC<Props> = (p) => {
    const regime: any = p.steps[0];
    const _RenderCardHeader = (step: any, index: number) => {
        return (
            <Card.Header className="flow-card-header">
                <Col className="d-flex flex-column align-items-center">
                    <label className="font-weight-bolder align-self-start input-title">Nombre: </label>
                    <input
                        type="text"
                        className="form-control"
                        id={index.toString()}
                        autoComplete="off"
                        name="Step Name"
                        placeholder="Nombre del paso"
                        value={step.name}
                        onChange={p.nameInputHandler}
                    />
                </Col>
                <div className="d-flex flex-row">
                    <Col className="d-flex flex-column justify-content-center ml-3">
                        <Form.Group>
                            <div className="switch switch-info d-flex flex-column align-items-center">
                                <label className="input-title">Activo</label>
                                <Form.Control
                                    type="checkbox"
                                    id={`unchecked-info-${index}`}
                                    name={index.toString()}
                                    checked={p.activeStep[index] && true}
                                    onChange={p.switchHandler}
                                />
                                <Form.Label htmlFor={`unchecked-info-${index}`} className="cr" />
                            </div>
                        </Form.Group>
                    </Col>
                    <Col className="d-flex flex-column justify-content-center ml-3">
                        <Form.Group>
                            <div className="switch switch-info d-flex flex-column align-items-center">
                                <label className="input-title">Confirmar</label>
                                <Form.Control
                                    type="checkbox"
                                    id={`info-${index}`}
                                    name={index.toString()}
                                    checked={step.confirmInfo}
                                    onChange={p.confirmInfoHandler}
                                />
                                <Form.Label htmlFor={`info-${index}`} className="cr" />
                            </div>
                        </Form.Group>
                    </Col>
                    <Col className="d-flex flex-column justify-content-center ml-3">
                        <Form.Group>
                            <div className="switch switch-info d-flex flex-column align-items-center">
                                <label className="input-title">Paso Final</label>
                                <Form.Control
                                    type="radio"
                                    id={`finalStep-${index}`}
                                    value={index.toString()}
                                    name={'rbFinalStep'}
                                    checked={step.isFinalStep}
                                    onChange={p.finalStepSwitchHandler}
                                />
                                <Form.Label htmlFor={`finalStep-${index}`} className="cr" />
                            </div>
                        </Form.Group>
                    </Col>
                </div>
                <Col className="d-flex flex-row justify-content-end action-buttons">
                    {index !== p.steps.length - 1 && (
                        <button
                            type="button"
                            id={index.toString()}
                            className="btn btn-icon btn-info mr-3 feather icon-arrow-down arrow"
                            onClick={p.downStep}
                        />
                    )}
                    {index !== 0 && (
                        <button
                            type="button"
                            id={index.toString()}
                            className="btn btn-icon btn-info mr-3 feather icon-arrow-up arrow"
                            onClick={p.upStep}
                        />
                    )}
                    <button
                        type="button"
                        id={index.toString()}
                        className="btn btn-icon btn-danger feather icon-trash-2"
                        onClick={p.onDelete}
                    />
                </Col>
            </Card.Header>
        );
    };

    const _RenderHeaderCardOTP = (step: any, index: number) => {
        return (
            <Card.Header className="d-flex flex-row justify-content-around align-items-center mt-3">
                <Col className="d-flex flex-row align-items-center">
                    <label className="font-weight-bolder label-title">{' \t' + step.name}</label>
                </Col>
                <div className="d-flex flex-row">
                    <Col className="d-flex flex-column justify-content-center ml-3">
                        <Form.Group>
                            <div className="switch switch-info d-flex flex-column align-items-center">
                                <label className="input-title">Paso Final</label>
                                <Form.Control
                                    type="radio"
                                    id={`finalStep-${index}`}
                                    value={index.toString()}
                                    name={'rbFinalStep'}
                                    checked={step.isFinalStep}
                                    onChange={p.finalStepSwitchHandler}
                                />
                                <Form.Label htmlFor={`finalStep-${index}`} className="cr" />
                            </div>
                        </Form.Group>
                    </Col>
                </div>
                <Col className="d-flex flex-row justify-content-end action-buttons">
                    {index !== 0 && (
                        <button
                            type="button"
                            id={index.toString()}
                            className="btn btn-icon btn-info mr-3 feather icon-arrow-up arrow"
                            onClick={p.upStep}
                        />
                    )}
                    {index !== p.steps.length - 1 && (
                        <button
                            type="button"
                            id={index.toString()}
                            className="btn btn-icon btn-info mr-3 feather icon-arrow-down arrow"
                            onClick={p.downStep}
                        />
                    )}
                    <button
                        type="button"
                        id={index.toString()}
                        className="btn btn-icon btn-danger feather icon-trash-2"
                        onClick={p.onDelete}
                    />
                </Col>
            </Card.Header>
        );
    };
    const _RenderCardBody = (step: any, index: number) => {
        return (
            <Card.Body>
                <Col className="d-flex flex-column align-items-left mb-3" lg={4}>
                    <label className="font-weight-bolder mt-3 align-self-start input-title">Paso para Régimen Fiscal: </label>
                    <Form.Group>
                        <Form.Control
                            as="select"
                            className="form-control"
                            id={index.toString()}
                            autoComplete="off"
                            name="taxRegime"
                            value={step.taxRegime}
                            onChange={p.taxRegimeInputHandler}
                        >
                            {taxRegimes.map((taxRegime) => {
                                return <option value={taxRegime.value}>{taxRegime.label}</option>;
                            })}
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col className="d-flex flex-column align-items-center mb-3" lg={12}>
                    <label className="font-weight-bolder mt-3 align-self-start input-title">Descripción: </label>
                    <input
                        type="text"
                        className="form-control"
                        id={index.toString()}
                        autoComplete="off"
                        name="Step Description"
                        placeholder="Descripción del paso"
                        value={step.description}
                        onChange={p.descriptionInputHandler}
                    />
                </Col>
                <Col>
                    <Card.Title className="font-weight-bold table-input">Selecciona la información a solicitar en este paso</Card.Title>
                    <Table responsive className="table">
                        <thead>
                            <tr>
                                <th>Obligatorio</th>
                                <th>Información</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        {step.infoRequired.map((info: any, index: number) => {
                            return (
                                <tbody>
                                    <tr>
                                        <td className="d-flex justify-content-center">
                                            <label className="mt-2 check-task custom-control custom-checkbox d-flex justify-content-center done-task">
                                                {info.name === 'Geolocalización GPS' ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        <input
                                                            type="checkbox"
                                                            id={index.toString()}
                                                            className="custom-control-input"
                                                            value={info.model !== 'documents' ? info.isRequired : true}
                                                            checked={info.model !== 'documents' ? info.isRequired : true}
                                                            onChange={
                                                                info.model !== 'documents'
                                                                    ? p.isRequiredHandler
                                                                    : () => console.log('Los documentos siempre seran Obligatorios')
                                                            }
                                                        />
                                                        <span className="custom-control-label" />
                                                    </>
                                                )}
                                            </label>
                                        </td>
                                        <td>
                                            <p className="h5 mt-2">{info.name}</p>
                                        </td>
                                        <td>
                                            <div className="d-flex justify-content-end action-btns">
                                                {index !== 0 ? (
                                                    <button
                                                        type="button"
                                                        id={index.toString()}
                                                        className="btn btn-icon btn-info mr-3 feather icon-arrow-up arrow"
                                                        onClick={p.upInfo}
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                                {index !== step.infoRequired.length - 1 ? (
                                                    <button
                                                        type="button"
                                                        id={index.toString()}
                                                        className="btn btn-icon btn-info mr-3 feather icon-arrow-down arrow"
                                                        onClick={p.downInfo}
                                                    />
                                                ) : (
                                                    <span></span>
                                                )}
                                                <button
                                                    type="button"
                                                    id={index.toString()}
                                                    className="btn btn-icon btn-danger feather icon-x-circle"
                                                    onClick={p.onDeleteInfo}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            );
                        })}
                    </Table>
                </Col>
            </Card.Body>
        );
    };

    const _RenderBodyCardOTP = (step: any, index: number) => {
        return (
            <Card.Body>
                <Row className="d-flex flex-row align-items-center" lg={12}>
                    <Col>
                        <Card.Title className="font-weight-bold mt-3 ml-4 input-title">Agregar el Texto de autorización:</Card.Title>
                        <Card.Subtitle className="font-weight-bold mt-3 ml-4 input-subtitle">
                            (El texto de ejemplo es con base en los lineamientos de Círculo de Crédito)
                        </Card.Subtitle>
                    </Col>
                    <textarea
                        className="form-control col-xl-11 ml-3 mt-3"
                        id={index.toString()}
                        rows={15}
                        placeholder="Términos de la Autorización"
                        value={step.description}
                        onChange={p.descriptionOTPHandler}
                    />
                </Row>
            </Card.Body>
        );
    };

    return (
        <>
            <Card.Body className="card-body flow-step">
                <Row lg={12} className="d-flex flex-row justify-content-around">
                    <Col className="d-flex flex-column" lg={12}>
                        {p.steps &&
                            p.steps.map((step: any, index: number) => {
                                if (step.name !== 'Autorización OTP') {
                                    return (
                                        <Card className="flow-step-card" key={index}>
                                            {_RenderCardHeader(step, index)}
                                            {_RenderCardBody(step, index)}
                                        </Card>
                                    );
                                } else {
                                    return (
                                        <Card className="flow-step-card" key={index}>
                                            {_RenderHeaderCardOTP(step, index)}
                                            {_RenderBodyCardOTP(step, index)}
                                        </Card>
                                    );
                                }
                            })}
                    </Col>
                    <Col className="d-flex flex-column addStep-section" lg={12}>
                        <Button className="btn-border-radius addStep-btn" onClick={p.onAddStep}>
                            <dt>
                                Agregar Paso <i className="feather icon-plus-circle"></i>
                            </dt>
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
        </>
    );
};
export default FlowBuilder;
