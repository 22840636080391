import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Button, Form } from 'react-bootstrap';
import UserModel from '../../models/user-model';
import Loader from '../../components/LoaderFile/Loader';
import RequestedInfo from '../../components/Builders/FlowBuilder/RequestedInformation';
import FlowBuilder from '../../components/Builders/FlowBuilder/FlowBuilder';
import { getFlowById, postFlow, resetFlowMessages, resetFlowStatus } from '../../flux/actions/flow-actions';
import { getInformationList } from '../../flux/actions/informationList-action';
import { refreshToken } from '../../flux/actions/refreshToken-action';

var mobile = require('is-mobile');

const taxRegimes = [
    { value: 'PF', label: 'Persona Física' },
    { value: 'PM', label: 'Persona Moral' },
    { value: 'AM', label: 'Ambos' }
];

const Flows: React.FC = () => {
    //redux const
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    const flowRef = (params as any)?.id;
    const user: UserModel = useSelector((state: any) => state.authReducer.user);
    const { informationList } = useSelector((state: any) => state.informationListReducer);
    const { successMessage, errorMessage, loading, flow } = useSelector((state: any) => state.flowReducer);

    //Variable locals to handle the creation of the Flow
    const [name, setName] = useState('');
    const [taxRegime, setTaxRegime] = useState('');
    const [steps, setSteps] = useState([
        {
            name: 'Nuevo Paso',
            description: '',
            taxRegime: 'AM',
            confirmInfo: false,
            isFinalStep: false,
            infoRequired: [
                {
                    name: '',
                    docsRequired: 0,
                    type: '',
                    model: '',
                    importance: 0,
                    register: '',
                    isRequired: false,
                    options: [] as string[]
                }
            ]
        }
    ]);
    const [activeStep, setActiveStep] = useState<Boolean[]>([]);
    const [isStepActive, setIsStepActive] = useState(false);
    const [isFlowDefault, setIsFlowDefault] = useState(false);
    const [finalStep, setFinalStep] = useState<Boolean[]>([]);

    //var to display errors
    const [errors, setErrors] = useState({
        name: '',
        steps: '',
        isDefault: ''
    });

    //verification of token or user to access at this view
    useEffect(() => {
        if (Object.keys(user).length === 0) {
            history.push('admin/login');
        }

        dispatch(getInformationList());
        dispatch(resetFlowMessages());

        if (flowRef) {
            dispatch(getFlowById(flowRef));
        }
        dispatch(
            refreshToken({
                token: localStorage.getItem('token'),
                refreshToken: localStorage.getItem('refreshToken')
            })
        );
    }, []);

    //Full the name of the flow if it all ready exist one
    useEffect(() => {
        setName(flow.name);
        setIsStepActive(flow.isActive);
        setIsFlowDefault(flow.isDefault);
        setTaxRegime(flow.taxRegime);

        if (Object.keys(flow).length !== 0) {
            setSteps(flow.steps);
        }
    }, [flow]);

    //If we dont have a flow chosen this will create a new flow
    const createFlow = () => {
        const errorName = name === '' ? 'El nombre del flujo no puede estar vacío' : '';

        setErrors({ ...errors, name: errorName });

        if (errorName) return;

        const payload = {
            name,
            userRef: user.id,
            isActive: isStepActive,
            taxRegime: 'PF'
        };

        dispatch(postFlow(payload));
    };

    //If we have a flow chosen this will update this flow
    const saveFlow = () => {
        const errorName = name === '' ? 'El nombre del flujo no puede estar vacío' : '';
        const isDefaultError = isStepActive ? '' : isFlowDefault ? 'Es necesario activar el flujo para poder usarlo como default' : '';
        let stepError = '';

        steps.map((step: any) => {
            if (step.infoRequired.length === 0) stepError = 'Se recomienda no dejar pasos del flujo vacíos';
        });

        setErrors({ name: errorName, steps: stepError, isDefault: isDefaultError });

        if (errorName || stepError || isDefaultError) return;

        const payload = {
            name,
            steps,
            taxRegime,
            userRef: user.id,
            isActive: isStepActive,
            isDefault: isFlowDefault
        };

        dispatch(postFlow(payload));
    };

    //function to add a new step un the flow and save the array on local storge to keep the persistence
    const addStep = () => {
        const auxStep = [...steps];
        auxStep.push({
            name: 'Nuevo Paso',
            description: '',
            taxRegime: 'AM',
            confirmInfo: false,
            isFinalStep: false,
            infoRequired: []
        });
        setSteps(auxStep);
        successMessage && dispatch(resetFlowMessages());
    };

    const addStepOTP = () => {
        const auxStep = [...steps];
        if (auxStep.filter((step) => step.name === 'Autorización OTP').length === 0)
            auxStep.push({
                name: 'Autorización OTP',
                description:
                    'Autorizo expresamente a (Nombre de la SOFOM), para que lleve a cabo investigaciones de: 1) mi \u{0020}' +
                    'comportamiento crediticio; 2) obtención de información sobre cuentas corrientes; ahorro de pago financiero,\u{0020}' +
                    'telecomunicaciones, comerciales o servicios y otros productos (de tarjetas de valores, de fondos de\u{0020}' +
                    'inversión, de planes de pensiones, de préstamo, entre otros).' +
                    '\n\n' +
                    'Manifiesto que conozco la naturaleza y alcance de la información que se solicitará, del uso que se le dará y\u{0020}' +
                    'que se podrán realizar consultas periódicas de mi historial crediticio, así como de cualquier otra\u{0020}' +
                    'información necesaria de conformidad con esta autorización para (Nombre de la SOFOM) Consiento\u{0020}' +
                    'que esta autorización tenga una vigencia de 3 años contados a partir del día de hoy, y en su caso mientras\u{0020}' +
                    'se mantenga relación jurídica.' +
                    '\n\n' +
                    'Acepto que este documento quede bajo propiedad de (Nombre de la SOFOM) para efectos de control y\u{0020}' +
                    'cumplimiento del artículo 28 de la Ley para Regular las Sociedades de Información Crediticia (LRSIC).',
                taxRegime: 'AM',
                confirmInfo: false,
                isFinalStep: false,
                infoRequired: [
                    {
                        name: '',
                        docsRequired: 0,
                        type: '',
                        model: '',
                        importance: 0,
                        register: '',
                        isRequired: false,
                        options: [] as string[]
                    }
                ]
            });
        setSteps(auxStep);
        successMessage && dispatch(resetFlowMessages());
    };

    //function to handle the input name, change it in base a the id of each element
    const nameInputHandler = (e: any) => {
        const auxStep = [...steps];
        auxStep[e.target.id] = { ...auxStep[e.target.id], name: e.target.value };
        setSteps(auxStep);
        successMessage && dispatch(resetFlowMessages());
    };

    //function to handle the input description, change it in base a the id of each element
    const descriptionInputHandler = (e: any) => {
        const auxStep = [...steps];
        successMessage && dispatch(resetFlowMessages());
        auxStep[e.target.id] = { ...auxStep[e.target.id], description: e.target.value };
        setSteps(auxStep);
    };
    //function to handle the input description, change it in base a the id of each element
    const taxRegimeInputHandler = (e: any) => {
        const auxStep = [...steps];
        successMessage && dispatch(resetFlowMessages());
        auxStep[e.target.id] = { ...auxStep[e.target.id], taxRegime: e.target.value };
        setSteps(auxStep);
    };

    //function to handle the input description, change it in base a the id of each element
    const descriptionOTPHandler = (e: any) => {
        const auxStep = [...steps];
        successMessage && dispatch(resetFlowMessages());
        auxStep[e.target.id] = { ...auxStep[e.target.id], description: e.target.value };
        setSteps(auxStep);
    };

    //function to handle the switch to confirm information, change it in base a the id of each element
    const confirmInfoHandler = (e: any) => {
        const auxStep = [...steps];
        successMessage && dispatch(resetFlowMessages());
        auxStep[e.target.name] = { ...auxStep[e.target.name], confirmInfo: !auxStep[e.target.name].confirmInfo };
        setSteps(auxStep);
    };

    //Function to delete the step selected in base a the id of each element
    const onDelete = (e: any) => {
        const auxStep = [...steps];
        successMessage && dispatch(resetFlowMessages());
        auxStep.splice(Number(e.target.id), 1);
        setSteps(auxStep);
    };

    //Function to delete the information selected in base a the id of each element and the active step
    const onDeleteInfo = (e: any) => {
        if (activeStep.indexOf(true) !== -1) {
            setErrors({ ...errors, steps: '' });
            const auxStep = [...steps];
            auxStep[activeStep.indexOf(true)].infoRequired.splice(Number(e.target.id), 1);
            setSteps(auxStep);
        } else {
            setErrors({ ...errors, steps: 'Activar el paso para poder modificar' });
        }

        successMessage && dispatch(resetFlowMessages());
    };

    //Function to move a step up in the flow order, base it in the id of each element
    const moveStepUp = (e: any) => {
        const auxStep = [...steps];
        const element = auxStep[e.target.id];

        auxStep[Number(e.target.id)] = auxStep[Number(e.target.id) - 1];
        auxStep[Number(e.target.id) - 1] = element;

        setSteps(auxStep);
        successMessage && dispatch(resetFlowMessages());
    };

    //Function to move a step down in the flow order, base it in the id of each element
    const moveStepDown = (e: any) => {
        const auxStep = [...steps];
        const element = auxStep[e.target.id];

        auxStep[Number(e.target.id)] = auxStep[Number(e.target.id) + 1];
        auxStep[Number(e.target.id) + 1] = element;

        setSteps(auxStep);
        successMessage && dispatch(resetFlowMessages());
    };

    //Function to move the info up in the information order, base it in the id of each element
    const isRequiredHandler = (e: any) => {
        if (activeStep.indexOf(true) !== -1) {
            setErrors({ ...errors, steps: '' });
            const auxStep = [...steps];
            const stepIndex = activeStep.indexOf(true);
            const infoIndex = Number(e.target.id);

            auxStep[stepIndex].infoRequired[infoIndex] = {
                ...auxStep[stepIndex].infoRequired[infoIndex],
                isRequired: !auxStep[stepIndex].infoRequired[infoIndex].isRequired
            };

            setSteps(auxStep);
        } else {
            setErrors({ ...errors, steps: 'Activar el paso para poder modificar' });
        }
        successMessage && dispatch(resetFlowMessages());
    };

    //Function to move the info up in the information order, base it in the id of each element
    const moveInfoUp = (e: any) => {
        if (activeStep.indexOf(true) !== -1) {
            setErrors({ ...errors, steps: '' });
            const auxStep = [...steps];
            const element = auxStep[activeStep.indexOf(true)].infoRequired[e.target.id];

            auxStep[activeStep.indexOf(true)].infoRequired[Number(e.target.id)] =
                auxStep[activeStep.indexOf(true)].infoRequired[Number(e.target.id) - 1];
            auxStep[activeStep.indexOf(true)].infoRequired[Number(e.target.id) - 1] = element;

            setSteps(auxStep);
        } else {
            setErrors({ ...errors, steps: 'Activar el paso para poder modificar' });
        }
        successMessage && dispatch(resetFlowMessages());
    };

    //Function to move a step down in the flow order, base it in the id of each element
    const moveInfoDown = (e: any) => {
        if (activeStep.indexOf(true) !== -1) {
            setErrors({ ...errors, steps: '' });
            const auxStep = [...steps];
            const element = auxStep[activeStep.indexOf(true)].infoRequired[e.target.id];

            auxStep[activeStep.indexOf(true)].infoRequired[Number(e.target.id)] =
                auxStep[activeStep.indexOf(true)].infoRequired[Number(e.target.id) + 1];
            auxStep[activeStep.indexOf(true)].infoRequired[Number(e.target.id) + 1] = element;

            setSteps(auxStep);
        } else {
            setErrors({ ...errors, steps: 'Activar el paso para poder modificar' });
        }
        successMessage && dispatch(resetFlowMessages());
    };

    //Function to add information on the step that is allready active, the selected info is given by the id of each element
    const onAddInfo = (e: any) => {
        if (activeStep.indexOf(true) !== -1) {
            setErrors({ ...errors, steps: '' });
            const auxSteps = [...steps];
            const infoStep = steps[activeStep.indexOf(true)];
            const indexInfo = e.target.id;
            const model = e.target.name;

            switch (model) {
                case 'client':
                    if (infoStep.infoRequired.length === 0) {
                        infoStep.infoRequired.push(informationList.clientInformation[indexInfo]);
                    } else {
                        if (
                            infoStep.infoRequired.indexOf(informationList.clientInformation[indexInfo]) === -1 &&
                            infoStep.infoRequired.findIndex((info: any) => info.model === 'documents') === -1
                        ) {
                            infoStep.infoRequired.push(informationList.clientInformation[indexInfo]);
                        } else {
                            setErrors({ ...errors, steps: 'solo se pueden solicitar documentos en el mismo paso' });
                        }
                    }
                    break;
                case 'bussinessInfo':
                    if (infoStep.infoRequired.length === 0) {
                        infoStep.infoRequired.push(informationList.bussinessInformation[indexInfo]);
                    } else {
                        if (
                            infoStep.infoRequired.indexOf(informationList.bussinessInformation[indexInfo]) === -1 &&
                            infoStep.infoRequired.findIndex((info: any) => info.model === 'documents') === -1
                        ) {
                            infoStep.infoRequired.push(informationList.bussinessInformation[indexInfo]);
                        } else {
                            setErrors({ ...errors, steps: 'solo se pueden solicitar documentos en el mismo paso' });
                        }
                    }
                    break;
                case 'affiliate':
                    if (infoStep.infoRequired.length === 0) {
                        infoStep.infoRequired.push(informationList.affiliateInformation[indexInfo]);
                    } else {
                        if (
                            infoStep.infoRequired.indexOf(informationList.affiliateInformation[indexInfo]) === -1 &&
                            infoStep.infoRequired.findIndex((info: any) => info.model === 'documents') === -1
                        ) {
                            infoStep.infoRequired.push(informationList.affiliateInformation[indexInfo]);
                        } else {
                            setErrors({ ...errors, steps: 'solo se pueden solicitar documentos en el mismo paso' });
                        }
                    }
                    break;
                case 'Apoderado':
                    if (infoStep.infoRequired.length === 0) {
                        infoStep.infoRequired.push(informationList.apoderadoInformation[indexInfo]);
                    } else {
                        if (
                            infoStep.infoRequired.indexOf(informationList.apoderadoInformation[indexInfo]) === -1 &&
                            infoStep.infoRequired.findIndex((info: any) => info.model === 'documents') === -1
                        ) {
                            infoStep.infoRequired.push(informationList.apoderadoInformation[indexInfo]);
                        } else {
                            setErrors({ ...errors, steps: 'solo se pueden solicitar documentos en el mismo paso' });
                        }
                    }
                    break;
                case 'documents':
                    if (infoStep.infoRequired.length === 0) {
                        infoStep.infoRequired.push(informationList.documents[indexInfo]);
                    } else {
                        if (
                            infoStep.infoRequired.indexOf(informationList.documents[indexInfo]) === -1 &&
                            infoStep.infoRequired.findIndex((info: any) => info.model === 'documents') === 0
                        ) {
                            infoStep.infoRequired.push(informationList.documents[indexInfo]);
                        } else {
                            setErrors({ ...errors, steps: 'solo se pueden solicitar documentos en el mismo paso' });
                        }
                    }
                    break;
            }
            auxSteps[activeStep.indexOf(true)] = infoStep;
            setSteps(auxSteps);
        } else {
            setErrors({ ...errors, steps: 'Activar el paso para poder modificar' });
        }
        successMessage && dispatch(resetFlowMessages());
    };

    //Function that gets the info of all cards and sets finalStep
    const getFinalStep = () => {
        finalStep.splice(0, finalStep.length);
        const auxStep = [...steps];
        for (let i = 0; i < auxStep.length; i++) {
            if (auxStep[i].isFinalStep === true) {
                finalStep.push(true);
            } else {
                finalStep.push(false);
            }
        }
    };

    const verifyFinalStep = () => {
        //Calling getFinalStep(); so we can retrieve Step marked as finalStep
        getFinalStep();
        //Check if there's a card with finalStep assigned
        if (finalStep.indexOf(true) !== -1) {
            //Verify position of the card, it needs to be last card
            if (finalStep.indexOf(true) == steps.length - 1) {
                console.log('Saving Flow...');
                flow.length === 0 ? createFlow() : saveFlow();
            } else {
                console.log('Por favor, ubica esta carta al final de la lista para establecerla como el paso final');
                const errorName =
                    name === '' ? 'Por favor, ubica esta carta al final de la lista para establecerla como el paso final' : '';
            }
        } else {
            const errorName = name === '' ? 'Para continuar, es necesario indicar el paso final' : '';
            console.log('Para continuar, es necesario indicar el paso final');
        }
    };

    //function to handle the switch buttons to active an step, we can't active mora than one step at the time
    //if we try to change a step allready active, it will not make any changes
    const switchHandler = (e: any) => {
        setErrors({ ...errors, steps: '' });

        const auxActive = [...activeStep];
        while (auxActive.indexOf(true) >= 0) {
            auxActive[auxActive.indexOf(true)] = !auxActive[auxActive.indexOf(true)];
        }
        if (!auxActive[e.target.name]) {
            auxActive[e.target.name] = !auxActive[e.target.name];
            setActiveStep(auxActive);
        }
        successMessage && dispatch(resetFlowMessages());
    };

    //function that disables all finalStep value on unselected Steps and sets the finalStep value as true on a selected Step
    const finalStepSwitchHandler = (e: any) => {
        setErrors({ ...errors, steps: '' });
        const auxStep = [...steps];
        //Setting all values to false
        for (let i = 0; i < auxStep.length; i++) auxStep[i].isFinalStep = false;
        //Setting checked one to true
        auxStep[e.target.value].isFinalStep = true;
        successMessage && dispatch(resetFlowMessages());
    };

    if (loading) {
        return (
            <div className="auth-wrapper align-items-center">
                <Loader />
            </div>
        );
    }

    return (
        <>
            <Row className="flow">
                {Object.keys(informationList).length !== 0 && (
                    <Col lg={4} md={12}>
                        {flow.length !== 0 && (
                            <Card>
                                <div>
                                    <h4 className="mt-3 flow-title">{name}</h4>
                                </div>
                                <div className="flow-name">
                                    <div className="flow-item">
                                        {flow.length === 0 ? (
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="Name"
                                                autoComplete="off"
                                                name="Flow Name"
                                                placeholder="Nombre del flujo"
                                                value={name}
                                                disabled={flow.length !== 0}
                                                onChange={(e) => {
                                                    setName(e.target.value);
                                                    setErrors({ ...errors, name: '' });
                                                }}
                                            />
                                        ) : (
                                            ''
                                        )}
                                        {errors.name && <p className="form-text text-left text-danger">{errors.name}</p>}
                                    </div>
                                </div>
                                <Card.Header className="d-flex flex-column justify-content-between flow-header">
                                    {flow.length !== 0 && (
                                        <>
                                            <div className="d-flex flex-row">
                                                <div className="d-flex flex-column flow-header-item">
                                                    <div className="switch switch-info d-flex flex-column align-items-center">
                                                        <p className="h5 font-weight-bold flow-custom-text">Predeterminado:</p>
                                                        <Form.Group>
                                                            <Form.Control
                                                                type="checkbox"
                                                                id={`unchecked-info-default`}
                                                                checked={isFlowDefault}
                                                                onChange={() => {
                                                                    setIsFlowDefault(!isFlowDefault);
                                                                    setErrors({ ...errors, isDefault: '' });
                                                                }}
                                                            />
                                                            <Form.Label htmlFor={`unchecked-info-default`} className="cr" />
                                                        </Form.Group>
                                                        <label />
                                                    </div>
                                                    {errors.isDefault && (
                                                        <p className="form-text text-left text-danger">{errors.isDefault}</p>
                                                    )}
                                                </div>
                                                {flow.length !== 0 && (
                                                    <div className="switch switch-info d-flex flex-column align-items-center flow-header-item">
                                                        <p className="h5 font-weight-bold flow-custom-text">Activar flujo:</p>
                                                        <Form.Control
                                                            type="checkbox"
                                                            id={`unchecked-info`}
                                                            checked={isStepActive}
                                                            onChange={() => {
                                                                setIsStepActive(!isStepActive);
                                                                setErrors({ ...errors, isDefault: '' });
                                                            }}
                                                        />
                                                        <Form.Label htmlFor={`unchecked-info`} className="cr" />
                                                        <label />
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </Card.Header>
                                <div className="flow-item">
                                    <Button onClick={verifyFinalStep} className={flow.length === 0 ? '' : 'flow-btn-absolute'}>
                                        <dt>Guardar</dt>
                                    </Button>
                                    <button
                                        type="button"
                                        className="flow-deteleBtn"
                                        onClick={() => {
                                            dispatch(resetFlowStatus());
                                            setName('');
                                            history.push('/admin/crear-flujo');
                                        }}
                                    >
                                        Eliminar
                                    </button>
                                </div>
                            </Card>
                        )}
                        <RequestedInfo
                            personalList={informationList.clientInformation}
                            bussinessList={informationList.bussinessInformation}
                            affiliateList={informationList.affiliateInformation}
                            documentsList={informationList.documents}
                            legalRepresentative={informationList.apoderadoInformation}
                            onAddStep={onAddInfo}
                            addStepOTP={addStepOTP}
                        />
                    </Col>
                )}
                <Col lg={8} md={12}>
                    <Card>
                        {flow.length === 0 && (
                            <div>
                                {flow.length === 0 ? (
                                    <h4 className="mt-3 flow-title">Personalizar Flujo</h4>
                                ) : (
                                    <h4 className="mt-3 flow-title">{name}</h4>
                                )}
                                <div className="flow-name">
                                    <div className="flow-item">
                                        {flow.length === 0 ? (
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="Name"
                                                autoComplete="off"
                                                name="Flow Name"
                                                placeholder="Nombre del flujo"
                                                value={name}
                                                disabled={flow.length !== 0}
                                                onChange={(e) => {
                                                    setName(e.target.value);
                                                    setErrors({ ...errors, name: '' });
                                                }}
                                            />
                                        ) : (
                                            ''
                                        )}
                                        {errors.name && <p className="form-text text-left text-danger">{errors.name}</p>}
                                    </div>
                                    <div className="flow-item">
                                        <Button
                                            onClick={flow.length === 0 ? createFlow : saveFlow}
                                            className={flow.length === 0 ? '' : 'flow-btn-absolute'}
                                        >
                                            <dt>Guardar</dt>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {flow.length !== 0 && (
                            <FlowBuilder
                                steps={steps}
                                onAddStep={addStep}
                                nameInputHandler={nameInputHandler}
                                taxRegimeInputHandler={taxRegimeInputHandler}
                                descriptionInputHandler={descriptionInputHandler}
                                onDelete={onDelete}
                                onDeleteInfo={onDeleteInfo}
                                upStep={moveStepUp}
                                downStep={moveStepDown}
                                activeStep={activeStep}
                                finalStep={activeStep}
                                switchHandler={switchHandler}
                                finalStepSwitchHandler={finalStepSwitchHandler}
                                upInfo={moveInfoUp}
                                downInfo={moveInfoDown}
                                confirmInfoHandler={confirmInfoHandler}
                                isRequiredHandler={isRequiredHandler}
                                descriptionOTPHandler={descriptionOTPHandler}
                            />
                        )}
                    </Card>
                </Col>
            </Row>
        </>
    );
};
export default Flows;
