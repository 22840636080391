import * as React from 'react';
import { useState } from 'react';
import { Card, Row, Col, Modal, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { documentsDescription } from '../../../../constants/documentsDescription';

const ScheduleInfo: React.FC = () => {
    //redux const
    const { client } = useSelector((state: any) => state.clientReducer);
    const files = useSelector((state: any) => state.DocumentsReducer.flowDocuments);

    //state const
    const [url, setUrl] = useState<any>();
    const [showImageModal, setShowImageModal] = useState(false);

    const base64toBlob = (file: any) => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64
        var base64WithoutPrefix = file;
        if (file.includes('dataapplication/pdfbase64')) {
            base64WithoutPrefix = file.substr('dataapplication/pdfbase64'.length);
        }
        if (file.includes('dataapplication/pdfbase64')) {
            base64WithoutPrefix = file.substr('dataapplication/pdfbase64'.length);
        }
        if (file.includes('data:application/pdf;base64,')) {
            base64WithoutPrefix = file.substr('data:application/pdf;base64,'.length);
        }
        const bytes = atob(file);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: 'application/pdf' });
    };

    const _renderDescription = (type: string) => {
        switch (type) {
            case 'bussinessPhoto1':
                return documentsDescription.bussinessPhoto1;
            case 'bussinessPhoto2':
                return documentsDescription.bussinessPhoto2;
            case 'bussinessPhoto3':
                return documentsDescription.bussinessPhoto3;
        }
    };

    return (
        <>
            <Card className="mt-3">
                <Card.Body className="d-flex align-items-center">
                    <h5 className="mb-0">Información de visita a cliente</h5>
                </Card.Body>
                <Card.Body className="border-top pro-det-edit collapse show card-container">
                    <Row>
                        <Col className="from-group" lg={6}>
                            <label className="font-weight-bolder mt-3">Fecha:</label>
                            <p className="mt-3">{client?.scheduledDate}</p>
                        </Col>
                        <Col className="from-group" lg={6}>
                            <label className="font-weight-bolder mt-3">Visita:</label>
                            <p className="mt-3">{client?.scheduledUser}</p>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            {files[0] !== undefined && (
                <Card>
                    <Card.Header className="d-flex align-items-center justify-content-between">
                        <h5 className="mb-0">Fotografías de visita a cliente</h5>
                    </Card.Header>
                    <Card.Body className={'border-top pro-det-edit collapse border-top pro-det-edit show'}>
                        <Row>
                            {files &&
                                files.map((file: any) => {
                                    if (
                                        file.type === 'bussinessPhoto1' ||
                                        file.type === 'bussinessPhoto2' ||
                                        file.type === 'bussinessPhoto3'
                                    ) {
                                        return (
                                            <Col className="customerProfile-files">
                                                {file.name === '' ? (
                                                    ''
                                                ) : (
                                                    <button
                                                        type="button"
                                                        className="btn btn-third btn-sm btn-border-radius float-center mt-2 doc-showBtn"
                                                        onClick={() => {
                                                            const blob = base64toBlob(file.file);
                                                            const urlAux = URL.createObjectURL(blob);
                                                            setUrl(urlAux);
                                                            setShowImageModal(!showImageModal);
                                                        }}
                                                    >
                                                        <i className={'feather icon-file-text'} />
                                                    </button>
                                                )}
                                                <p className="mt-3 align-middle doc-titleDocument">{_renderDescription(file.type)}</p>
                                            </Col>
                                        );
                                    }
                                })}
                        </Row>
                        <Modal size="xl" show={showImageModal} onHide={() => setShowImageModal(!showImageModal)}>
                            <Modal.Header closeButton className="approvalFlow-docsModal-header">
                                <Modal.Title as="h5">Fotografías de visita</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="d-flex flex-column approvalFlow-docsModal-body">
                                <img src={url} style={{ width: '100%', height: 'auto' }} alt="Imagen de documento" />
                                <div className="d-flex justify-content-end mt-3">
                                    <Button className="docs-infoBtn">
                                        <a className="text-white" download={url + '.jpg'} href={url}>
                                            Descargar
                                        </a>
                                    </Button>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </Card.Body>
                </Card>
            )}
        </>
    );
};

export default ScheduleInfo;
