import * as React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import moment from 'moment';
import { useSelector } from 'react-redux';

const QuoteInformation: React.FC = () => {
    // Format the above price dollar currency
    let pesosMX = Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
        useGrouping: true,
        maximumSignificantDigits: 3,
    });

    const credit = useSelector((state: any) => state.quoteReducer.credit);

    return (
        <>
            <Card className='mt-3'>
                <Card.Body className="d-flex align-items-center">
                    <h5 className="mb-0">Detalles de cotización de crédito</h5>
                </Card.Body>
                <Card.Body className='border-top pro-det-edit collapse show card-container'>
                    <Row>
                        <Col className="from-group" lg={4}>
                            <label className="font-weight-bolder mt-3">Folio:</label>
                            <p className="mt-3">{credit?.folio}</p>
                        </Col>
                        <Col className="from-group" lg={4}>
                            <label className="font-weight-bolder mt-3">Producto:</label>
                            <p className="mt-3">{credit.planRef === "1" ? "Kualinet Express" : "Kualinet Plus"}</p>
                        </Col>
                        <Col className="from-group" lg={4}>
                            <label className="font-weight-bolder mt-3">Cantidad Solicitada:</label>
                            <p className="mt-3">{pesosMX.format(Number(credit.amount))}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="from-group">
                            <label className="font-weight-bolder mt-3">Plazo a pagar:</label>
                            <p className="mt-3">{credit.monts} meses</p>
                        </Col>
                        <Col className="from-group">
                            <label className="font-weight-bolder mt-3">Pago mensual:</label>
                            <p className="mt-3">{pesosMX.format(Number(credit.montlyPayment))}</p>
                        </Col>
                        <Col className="from-group">
                            <label className="font-weight-bolder mt-3">Fecha de solicitud:</label>
                            <p className="mt-3">{moment(credit.dateRequested).format('LL')}</p>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};
export default QuoteInformation;
