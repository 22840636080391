import axios from 'axios';

const API_URL = process.env.REACT_APP_BASE_URL;

const options = {
    headers: {
        'Content-Type': 'application/json'
    }
};

const login = (data: any) => {
    return axios.post(`${API_URL}client/login`, data);
};

const signup = (data: any) => {
    return axios.post(`${API_URL}client/`, data);
};

const getClient = (id: string) => {
    return axios.get(`${API_URL}client/${id}`);
};

const editClient = (data: any) => {
    return axios.put(`${API_URL}client/`, data);
};

const stepBack = (userRef: any) => {
    return axios.put(`${API_URL}client/step-back/${userRef}`);
};

const validationIne = (payload: any) => {
    return axios.post(`${API_URL}client/validation-ine`, payload);
};

const validationAddress = (payload: any) => {
    return axios.post(`${API_URL}client/validation-address`, payload);
};

const restoreFlowInfo = (payload: any) => {
    return axios.post(`${API_URL}client/restore-flow-controller`, payload);
};

const saveFlowInfo = (payload: any) => {
    return axios.post(`${API_URL}client/save-flow-information`, payload);
};

const putUpdateSchedule = (payload: any) => {
    return axios.put(`${API_URL}client/updateSchedule`, payload);
};

const updateLocation = (payload: any) => {
    return axios.put(`${API_URL}client/updateLocation`, payload);
};

export const API = {
    login,
    signup,
    getClient,
    editClient,
    stepBack,
    validationIne,
    validationAddress,
    restoreFlowInfo,
    saveFlowInfo,
    putUpdateSchedule,
    updateLocation
};
