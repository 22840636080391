import storage from "redux-persist/lib/storage";

const authPersistConfig  = {
    key: 'authReducer',
    storage: storage,
    blacklist: ['errorStatus'],
};

export  const persistRules = {
    authPersistConfig
}