export enum QuoteStatus {
    inReview = 'Estados de cuenta pendientes de aprobación',

    approvedBankInfo = 'Comprobantes de domicilio pendientes de aprobación',
    approvedTaxInfo = 'Información fiscal aprobada',
    approvedAddressInfo = 'Identificación pendiente de ser aprobada',
    approvedIdInfo = 'Identificación oficial aprobada',
    
    pendingUploadBankFiles = 'Estados de cuenta pendientes de subir',
    pendingUploadAddressFiles = 'Comprobantes de domicilio pendientes de subir',
    pendingUploadIdFiles = 'Identificación pendiente de subir',
    
    requestTaxInfoAutomatic = 'Información fiscal solicitada automáticamente',
    requestTaxInfoManual = 'Información fiscal solicitada manualmente',
    
    reviewTaxInfo = 'Información fiscal en revisión',
    reviewTaxInfoAutomatic = 'Información fiscal automática cargada',
    completionOfTheApprovalFlow = 'Flujo de aprobación Finalizado',
    creditProposalPendingApproval = 'Propuesta de crédito pendiente de aprobar',
    ActivePortfolio = 'Cartera Activa ( la solicitud de crédito ha sido aprobada )',
    rejected = 'Solicitud rechazada',
    onBoarding= 'Prospecto creando solicitud'
}
