import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Table, Card, Col, Button, Modal, Row, Alert } from 'react-bootstrap';
import { sendAuthMail } from '../../../../flux/actions/mail-actions';
import { creditProposal, resetCreditProposal } from '../../../../flux/actions/creditProposal-action';
import { rejectQuote, getCredit, updateStatus } from '../../../../flux/actions/quote-actions';
import { getRole } from '../../../../flux/actions/role-actions';
import UserModel from '../../../../models/user-model';
import AmortizacionTable from '../../../../components/Amortization-table';
import moment from 'moment';

const CreditProposal: React.FC = () => {
    //const to get the params
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    const clientRef = (params as any)?.id;
    const user: UserModel = useSelector((state: any) => state.authReducer.user);
    const { successMessage, errorMessage } = useSelector((state: any) => state.creditProposalReducer);
    const quote: any = useSelector((state: any) => state.quoteReducer.credit);
    const { client } = useSelector((state: any) => state.clientReducer);
    const { role } = useSelector((state: any) => state.roleReducer);

    // const counter_Offer: any = useSelector((state: any) => state.CounterOfferReducer);
    //Estados de los modales
    const [showCancelRequest, setShowCancelRequest] = useState(false);
    const [showApproveRequest, setShowApproveRequest] = useState(false);
    const [showtableValidation, setShowtableValidation] = useState(false);
    const [showCreditProposal, setShowCreditProposal] = useState(false);

    //Modificacion Futura si la opcion elegida es true es 1 si es false sera 2
    const [confirmAgainstOffer, setConfirmAgainstOffer] = useState(false);
    const [creditProposalScreen, setCreditProposalScreen] = useState(false);
    const [table, setTable] = useState(false);
    const [interestRate, setInterestRate] = useState(0.016666);
    const [amount, setAmount] = useState(0);
    const [months, setMonts] = useState(0);
    const [permisson, setPermisson] = useState(false);

    const [errors, setErrors] = useState({
        amount: '',
        months: '',
        interestRate: ''
    });

    useEffect(() => {
        if (user.role !== undefined) {
            dispatch(getRole(user?.role));
        }
    }, []);

    useEffect(() => {
        dispatch(resetCreditProposal());
        if (Object.keys(user).length === 0) {
            history.push('/login');
        }
    }, []);

    useEffect(() => {
        role &&
            role?.permissions?.map((item: any) => {
                if (item?.permission === 'Propuesta de crédito') {
                    setPermisson(item?.state);
                }
            });
    }, []);

    function tableValidation() {
        const amountError =
            amount >= 50000 && amount <= 1000000 ? '' : 'El Monto no puede estar vacio o ser menor de $50,000 y mayor a $1,000,000';
        const monthsError = months >= 6 && months <= 24 ? '' : 'El plazo no puede estar vacío o ser menor a 6 meses y mayor 24 meses';
        const interestRateError = interestRate > 0 ? '' : 'La Tasa de interés no puede estar vacío o ser igual a 0';

        setErrors({ ...errors, amount: amountError, months: monthsError, interestRate: interestRateError });

        if (amountError || monthsError || interestRateError) return;
        setShowtableValidation(!showtableValidation);
        setTable(true);
    }

    function applicationApproved() {
        dispatch(
            updateStatus({
                clientRef,
                type: 'updateBothStates',
                adminStatus: 'activePortfolio',
                status: 'completionOfTheApprovalFlow'
            })
        );
        setShowApproveRequest(!showApproveRequest);
    }

    function sendCreditProposal() {
        const monthlyPayment = calculateQuote();
        const amountError =
            amount >= 50000 && amount <= 1000000 ? '' : 'El Monto no puede estar vacio o ser mayor de $50,000 y menor a $1,000,000';
        const monthsError = months >= 6 && months <= 24 ? '' : 'El plazo no puede estar vacío o ser mayor a 6 meses y menor 12 meses';

        setErrors({ ...errors, amount: amountError, months: monthsError });

        if (amountError || monthsError) return;

        dispatch(
            creditProposal({
                amount: amount,
                months: months,
                productType: client.taxRegime === 'PF' ? 1 : client.taxRegime === 'PM' ? 2 : null,
                interest: interestRate,
                clientRef
            })
        );
        dispatch(updateStatus({ type: 'clientStatus', status: 'confirmationCreditProposal', clientRef: clientRef }));
        dispatch(
            sendAuthMail({
                mailType: 4,
                email: client.email,
                name: client.names,
                lastName1: client.lastName1,
                lastName2: client.lastName2,
                interestRate,
                amount,
                months,
                monthlyPayment
            })
        );
        setConfirmAgainstOffer(true);
    }

    useEffect(() => {
        confirmAgainstOffer == true && successMessage == 'Propuesta de crédito generada correctamente' && dispatch(getCredit(clientRef));
        setConfirmAgainstOffer(false);
    }, [successMessage]);

    const calculateQuote = () => {
        const taxes = interestRate;
        const Ci = amount * taxes;
        const exponential = 1 - Math.pow(1 + taxes, -months);
        return new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2
        }).format(Ci / exponential);
    };

    function cancelRequest() {
        dispatch(rejectQuote({ client: clientRef }));
        setShowCancelRequest(!showCancelRequest);
        dispatch(
            sendAuthMail({
                mailType: 3,
                email: client.email,
                name: client.names,
                lastName1: client.lastName1,
                lastName2: client.lastName2,
                folio: quote.folio,
                amount: quote.amount
            })
        );
    }

    return !permisson ? (
        <div className="d-flex py-5 text-center flex-column justify-content-center align-items-center" style={{ height: '50vh' }}>
            <i className="feather icon-alert-octagon text-danger" style={{ fontSize: 60, marginBottom: 5 }} />
            <h2 className="text-center text-danger">Tu usuario no tiene permisos para generar una propuesta de crédito.</h2>
            <p style={{ fontSize: 16, fontWeight: 600 }}>
                Para continuar el proceso por favor espera a que otro usuario con permisos genere la propuesta de crédito.
            </p>
        </div>
    ) : (
        <>
            <Card>
                {!creditProposalScreen ? (
                    <>
                        <Card.Header className="approvalFlow-offer-header">
                            <Card.Title as="h5">Propuesta de crédito :</Card.Title>
                            <span className="d-block m-t-5 ml-1 offer-updateTime">
                                Actualizado hace{' '}
                                {moment().diff(client.updatedAt, 'days') !== 0
                                    ? moment().diff(client.updatedAt, 'days')
                                    : moment().diff(client.updatedAt, 'hours')}
                                {moment().diff(client.updatedAt, 'days') !== 0 ? ' dias' : ' horas'}
                            </span>
                        </Card.Header>
                        <Card.Header className="approvalFlow-offer-header pt-0">
                            <Card.Title as="h5">
                                Es posible realizar una propuesta de crédito o confirmar la solicitud del prospecto.
                            </Card.Title>
                            <p>
                                Una vez realizado el análisis de la información bancaria y fiscal, es posible realizar una propuesta de
                                crédito más adecuada a las capacidades del prospecto.
                            </p>
                        </Card.Header>
                        <Card.Footer className="approvalFlow-offer-footer pt-0">
                            <Card.Title as="h5">Realizar propuesta de crédito o confirmar crédito</Card.Title>
                            <Row className="mt-3 ml-1">
                                <Button
                                    className="btn-border-radius offer-successBtn"
                                    variant={'success'}
                                    onClick={() => setCreditProposalScreen(!creditProposalScreen)}
                                >
                                    Propuesta de crédito
                                </Button>
                                <Button variant={'danger'} className="ml-3 btn-border-radius offer-alertBtn" onClick={applicationApproved}>
                                    Confirmar
                                </Button>
                            </Row>
                        </Card.Footer>
                    </>
                ) : (
                    <>
                        <Card.Body className="approvalFlow-offer-body">
                            <Col className="d-flex flex-column justify-content-center align-items-center">
                                <Card.Title as="h5">Llenar datos de propuesta economica</Card.Title>
                                <p className="d-block m-t-5 col-sm-6">Tipo de producto:</p>
                                <select className="col-sm-6 js-status-multiple form-control mt-0 mb-3">
                                    <option>EXPRESS</option>
                                    {/* <option>EXPRESS PLUS</option> */}
                                </select>
                                {/* <div className="switch switch-info d-inline m-r-10 m-l-10">
                                        <Form.Control
                                            type="checkbox"
                                            id={`unchecked-info`}
                                            checked={product}
                                            onChange={() => setProduct(!product)}
                                        />
                                        <Form.Label htmlFor={`unchecked-info`} className="cr" />
                                    </div> */}
                                <p className="d-block mt-3 col-sm-6">Tasa de la propuesta de crédito :</p>
                                <input
                                    type="number"
                                    className="col-sm-6 form-control mt-0 "
                                    id="interest rate"
                                    placeholder="Tasa de interés"
                                    value={interestRate}
                                    onChange={(e: any) => {
                                        setInterestRate(e.target.value);
                                        setErrors({ ...errors, interestRate: '' });
                                    }}
                                />
                                {errors.interestRate && <p className="form-text text-left text-danger">{errors.interestRate}</p>}
                                <p className="d-block mt-3 col-sm-6">Monto de la propuesta de crédito :</p>
                                <input
                                    type="text"
                                    className="col-sm-6 form-control mt-0 "
                                    id="amount"
                                    placeholder="Monto en Pesos"
                                    prefix="$"
                                    maxLength={8}
                                    value={amount}
                                    onChange={(e: any) => {
                                        setAmount(e.target.value);
                                        setErrors({ ...errors, amount: '' });
                                    }}
                                />
                                {errors.amount && <p className="form-text text-left text-danger">{errors.amount}</p>}
                                <p className="d-block mt-4 col-sm-6">Plazo a pagar en meses:</p>
                                <input
                                    type="text"
                                    className="col-sm-6 form-control mt-0"
                                    data-inputmask="'alias': 'currency'"
                                    id="plazo"
                                    placeholder="Plazo en meses"
                                    maxLength={2}
                                    value={months}
                                    onChange={(e: any) => {
                                        setMonts(e.target.value);
                                        setErrors({ ...errors, months: '' });
                                    }}
                                />
                                {errors.months && <p className="form-text text-left text-danger">{errors.months}</p>}
                                <Button variant={'success'} className="mt-3 offer-successBtn" onClick={tableValidation}>
                                    Generar Tabla
                                </Button>
                            </Col>
                        </Card.Body>
                        {table && (
                            <Card.Body className="approvalFlow-offer-body">
                                <Card.Title as="h5">Tabla de Amortizacion</Card.Title>
                                <Table striped responsive hover>
                                    <thead className="text-center">
                                        <tr>
                                            <th key={1} className="text-white">
                                                PERIODO
                                            </th>
                                            <th key={2} className="text-white">
                                                VENCIMIENTO
                                            </th>
                                            <th key={3} className="text-white">
                                                CUOTA
                                            </th>
                                            <th key={4} className="text-white">
                                                SALDO INICIAL
                                            </th>
                                            <th key={5} className="text-white">
                                                INTERÉS
                                            </th>
                                            <th key={6} className="text-white">
                                                CAPITAL
                                            </th>
                                            <th key={7} className="text-white">
                                                SALDO FINAL
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>{AmortizacionTable(months, amount, interestRate)}</tbody>
                                </Table>
                            </Card.Body>
                        )}
                        {showtableValidation && (
                            <Card.Footer className="approvalFlow-offer-footer">
                                <Card.Title as="h5">Enviar propuesta de crédito</Card.Title>
                                <Row className="mt-3 ml-1">
                                    <Button
                                        className="btn-border-radius offer-successBtn"
                                        variant={'success'}
                                        onClick={() => setShowCreditProposal(!showCreditProposal)}
                                    >
                                        Confirmar
                                    </Button>
                                    <Button variant={'danger'} className="ml-3 btn-border-radius offer-alertBtn" onClick={cancelRequest}>
                                        No Aplica
                                    </Button>
                                </Row>
                            </Card.Footer>
                        )}
                    </>
                )}
            </Card>
            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <Modal show={showApproveRequest} onHide={() => setShowApproveRequest(!showApproveRequest)}>
                <Modal.Header closeButton>
                    <Modal.Title as="h5">Aprobar solicitud de crédito</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    El usuario cumple con los requisitos necesarios para la aprobación del crédito, APROBAR SOLICITUD DE CRÉDITO.
                    <br />
                    <br />
                    ¿Deseas continuar?
                </Modal.Body>
                <Modal.Footer>
                    <Row className="mt-3 mb-3">
                        <Button className="btn-border-radius" variant="danger" onClick={() => setShowApproveRequest(!showApproveRequest)}>
                            Cancelar
                        </Button>
                        <Button
                            className="ml-3 btn-border-radius"
                            variant="success"
                            onClick={() => {
                                setShowApproveRequest(!showApproveRequest);
                                dispatch(getCredit(clientRef));
                            }}
                        >
                            Aceptar
                        </Button>
                    </Row>
                </Modal.Footer>
            </Modal>
            <Modal show={showCancelRequest} onHide={() => setShowCancelRequest(!showCancelRequest)}>
                <Modal.Header closeButton>
                    <Modal.Title as="h5">El usuario NO cumple con los requisitos necesarios para la solicitud del crédito.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    La información proveida por el cliente no cumple con alguno de los requisitos para ser candidato a un crédito. Esta
                    cotización quedara CANCELADA
                    <br />
                    <br />
                    ¿Deseas continuar?
                </Modal.Body>
                <Modal.Footer>
                    <Row className="mt-3 mb-3">
                        <Button className="btn-border-radius" variant="danger" onClick={() => setShowCancelRequest(!showCancelRequest)}>
                            Cancelar
                        </Button>
                        <Button
                            className="ml-3 btn-border-radius"
                            variant="success"
                            onClick={() => {
                                dispatch(getCredit(clientRef));
                                setShowCancelRequest(!showCancelRequest);
                            }}
                        >
                            Aceptar
                        </Button>
                    </Row>
                </Modal.Footer>
            </Modal>
            <Modal show={showCreditProposal} onHide={() => setShowCreditProposal(!showCreditProposal)}>
                <Modal.Header closeButton>
                    <Modal.Title as="h5">Confirmar propuesta de crédito.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Se enviará la propuesta de crédito generada al cliente.
                    <br />
                    <br />
                    ¿Deseas continuar?
                </Modal.Body>
                <Modal.Footer>
                    <Row className="mt-3 mb-3">
                        <Button className="btn-border-radius" variant="danger" onClick={() => setShowCreditProposal(!showCreditProposal)}>
                            Cancelar
                        </Button>
                        <Button
                            className="ml-3 btn-border-radius"
                            variant="success"
                            onClick={() => {
                                sendCreditProposal();
                                setShowCreditProposal(!showCreditProposal);
                            }}
                        >
                            Aceptar
                        </Button>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default CreditProposal;
