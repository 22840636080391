// @flow
// import { AsyncStorage } from "react-native";
import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducers from '../redux_setup/rootReducer';
import thunk from "redux-thunk";
import  { composeWithDevTools } from 'redux-devtools-extension'

const persistConfig = {
    key: 'root',
    storage: storage,
    blacklist: ['authReducer'], //reducers that will not be persisted
};


const pReducer = persistReducer(persistConfig, reducers);
export const store = createStore(pReducer, composeWithDevTools(applyMiddleware(thunk)));
export const persist = persistStore(store);