import { Reducer } from 'redux';
import previusDataModel from '../../models/previus_data-model';
import PREVIUS_DATA_CONSTANTS from '../constants/previusData-constants';
import { PreviusDataAction } from '../types/PreviusData-types';

export interface PreviusDataReducerState {
    loading: boolean;
    success: boolean;
    successMessage: string;
    previusData: previusDataModel;
    error: boolean;
    errorMessage?: string;
    errorStatus?: number;
}

const initialState: PreviusDataReducerState = {
    loading: false,
    success: false,
    error: false,
    previusData: {} as previusDataModel,
    successMessage: '',
    errorMessage: '',
    errorStatus: 0,
}

export const previusDataReducer: Reducer<PreviusDataReducerState, PreviusDataAction> = (state = initialState, action: PreviusDataAction) => {
    switch (action.type) {
        //----------CREATE PREVIUS DATA-----------//
        case PREVIUS_DATA_CONSTANTS.POST_PREVIUS_DATA_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            }

        case PREVIUS_DATA_CONSTANTS.POST_PREVIUS_DATA_ERROR:
            return {
                ...state,
                success: false,
                successMessage: '',
                error: true,
                errorStatus: action.error.data.status,
                errorMessage: action.error.data.message,
                loading: false
            }

        case PREVIUS_DATA_CONSTANTS.POST_PREVIUS_DATA_SUCCESS:
            return {
                ...state,
                success: true,
                successMessage: action.data.message,
                errorMessage: '',
                errorStatus: 0,
                error: false,
                loading: false
            }

        //----------GET PREVIUS DATA-----------//
        case PREVIUS_DATA_CONSTANTS.GET_PREVIUS_DATA_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            }

        case PREVIUS_DATA_CONSTANTS.GET_PREVIUS_DATA_ERROR:
            return {
                ...state,
                success: false,
                successMessage: '',
                error: true,
                errorStatus: action.error.data.status,
                errorMessage: action.error.data.message,
                loading: false
            }

        case PREVIUS_DATA_CONSTANTS.GET_PREVIUS_DATA_SUCCESS:
            return {
                ...state,
                success: true,
                previusData: action.data,
                errorMessage: '',
                errorStatus: 0,
                error: false,
                loading: false
            }

// ------------- RESET PREVIUS DATA -------------//
        case PREVIUS_DATA_CONSTANTS.RESET_PREVIUS_DATA_STATUS:
            return {
                success: false,
                error: false,
                loading: false,
                errorStatus: 0,
                previusData: {} as previusDataModel,
                errorMessage: '',
                successMessage: '',
            }

        default:
            return state;
    }
}