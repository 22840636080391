import { Dispatch } from 'redux';
import { API } from '../api/users-api';
import FLUX_AUTH_CONSTANTS from '../constants/auth-constants';
import * as types from '../types/auth-types';

//---------------------------------- GET ALL USERS --------------------------------------------------
const getUsersBegin = (): types.IGetUsersBegin => {
    return {
        type: FLUX_AUTH_CONSTANTS.GET_USERS_BEGIN
    };
};

const getUsersError = (e: any): types.IGetUsersError => {
    return {
        type: FLUX_AUTH_CONSTANTS.GET_USERS_ERROR,
        error: e
    };
};

const getUsersSuccess = (data: any): types.IGetUsersSuccess => {
    return {
        type: FLUX_AUTH_CONSTANTS.GET_USERS_SUCCESS,
        data
    };
};

export const getUsers = () => {
    return (dispatch: Dispatch) => {
        dispatch(getUsersBegin());
        API.getUsers()
            .then((response: any) => {
                dispatch(getUsersSuccess(response.data));
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = '/login';
                }
                dispatch(getUsersError(error.response));
            });
    };
};

//---------------------------------- GET USER BY ID  --------------------------------------------------
const getUserBegin = (): types.IGetUserBegin => {
    return {
        type: FLUX_AUTH_CONSTANTS.GET_USER_BEGIN
    };
};

const getUserError = (e: any): types.IGetUserError => {
    return {
        type: FLUX_AUTH_CONSTANTS.GET_USER_ERROR,
        error: e
    };
};

const getUserSuccess = (data: any): types.IGetUserSuccess => {
    return {
        type: FLUX_AUTH_CONSTANTS.GET_USER_SUCCESS,
        data
    };
};

export const getUser = (id: any) => {
    return (dispatch: Dispatch) => {
        dispatch(getUserBegin());
        API.getUser(id)
            .then((response: any) => {
                dispatch(getUserSuccess(response.data));
            })
            .catch((error: any) => {
                dispatch(getUserError(error.response));
            });
    };
};

//---------------------------------- UPDATE USER INFORMATION --------------------------------------------------

const updateUserBeggin = (): types.IUpdateUserBegin => {
    return {
        type: FLUX_AUTH_CONSTANTS.UPDATE_USER_BEGIN
    };
};

const updateUserError = (e: any): types.IUpdateUserError => {
    return {
        type: FLUX_AUTH_CONSTANTS.UPDATE_USER_ERROR,
        error: e
    };
};

const updateUserSuccess = (data: any): types.IUpdateUserSuccess => {
    return {
        type: FLUX_AUTH_CONSTANTS.UPDATE_USER_SUCCESS,
        data
    };
};

export const updateUser = (data: any) => {
    return (dispatch: Dispatch) => {
        dispatch(updateUserBeggin());
        API.updateUser(data)
            .then((response: any) => {
                dispatch(updateUserSuccess(response.data));
            })
            .catch((error: any) => {
                dispatch(updateUserError(error.response));
            });
    };
};

/////////////// Renew Password ////////////////

const renewPasswordBegin = (): types.IRenewPasswordBegin => {
    return {
        type: FLUX_AUTH_CONSTANTS.RENEW_PASSWORD_BEGIN
    };
};

const renewPasswordError = (e: any): types.IRenewPasswordError => {
    return {
        type: FLUX_AUTH_CONSTANTS.RENEW_PASSWORD_ERROR,
        error: e
    };
};

const renewPasswordSuccess = (data: any): types.IRenewPasswordSuccess => {
    return {
        type: FLUX_AUTH_CONSTANTS.RENEW_PASSWORD_SUCCESS,
        data
    };
};

export const renewPassword = (data: any) => {
    return (dispatch: Dispatch) => {
        dispatch(renewPasswordBegin());
        API.renewPassword(data)
            .then((response: any) => {
                dispatch(renewPasswordSuccess(response.data));
            })
            .catch((error: any) => {
                dispatch(renewPasswordError(error.response));
            });
    };
};
