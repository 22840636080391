import { Reducer } from 'redux';
import LEGAL_REPRESENTATIVE from '../constants/legalRepresentative-constants';
import { LegalRepresentative } from '../types/legalRepresentative-types';

export interface LegalRepresentativeReducerState {
    loading: boolean;
    success: boolean;
    successMessage: string;
    legalRepresentative: {};
    error: boolean;
    errorMessage?: string;
    errorStatus?: number;
}

const initialState: LegalRepresentativeReducerState = {
    loading: false,
    success: false,
    legalRepresentative: {},
    error: false,
    successMessage: '',
    errorMessage: '',
    errorStatus: 0
};

export const legalRepresentativeReducer: Reducer<LegalRepresentativeReducerState, LegalRepresentative> = (state = initialState, action: LegalRepresentative) => {
    switch (action.type) {

        //----------UPDATE LEGAL REPRESENTATIVE-----------//
        case LEGAL_REPRESENTATIVE.UPDATE_LEGAL_REPRESENTATIVE_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            };

        case LEGAL_REPRESENTATIVE.UPDATE_LEGAL_REPRESENTATIVE_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                errorStatus: action.error.data.status,
                errorMessage: action.error.data.message,
                loading: false
            };

        case LEGAL_REPRESENTATIVE.UPDATE_LEGAL_REPRESENTATIVE_SUCCESS:
            return {
                ...state,
                success: true,
                successMessage: action.data.message,
                legalRepresentative: action.data,
                error: false,
                errorMessage: '',
                errorStatus: 0,
                loading: false
            };

        //----------GET LEGAL REPRESENTATIVE-----------//
        case LEGAL_REPRESENTATIVE.GET_LEGAL_REPRESENTATIVE_BEGIN:
            return {
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                legalRepresentative: {},
                errorMessage: '',
                successMessage: ''
            };

        case LEGAL_REPRESENTATIVE.GET_LEGAL_REPRESENTATIVE_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                legalRepresentative: {},
                errorStatus: action.error?.data.status,
                errorMessage: action.error.data.message,
                loading: false
            };

        case LEGAL_REPRESENTATIVE.GET_LEGAL_REPRESENTATIVE_SUCCESS:
            return {
                ...state,
                success: true,
                successMessage: action.data.message,
                legalRepresentative: action.data,
                error: false,
                errorMessage: '',
                errorStatus: 0,
                loading: false
            };
        
        //----------RESET LEGAL REPRESENTATIVE-----------//
        case LEGAL_REPRESENTATIVE.LEGAL_REPRESENTATIVE_RESET_STATUS:
            return {
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                legalRepresentative: {},
                errorMessage: '',
                successMessage: ''
            };

        default:
            return state;
    }
}