import axios from 'axios';

const API_URL = process.env.REACT_APP_BASE_URL;

const getRccPm = (payload: any) => {
    return axios.post(`${API_URL}creditCircle/get-rcc-pm`, payload);
}

const getRccFicoScore = (payload: any) => {
    return axios.post(`${API_URL}creditCircle/get-rcc-ficoScore`, payload);
}

export const API = {
getRccFicoScore,
getRccPm
}