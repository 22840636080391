import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCredit, rejectQuote, updateStatus } from '../../../../flux/actions/quote-actions';
import { getClient, validationIne } from '../../../../flux/actions/client-actions';
import { Card, Table, Row, Button, Modal, Form, Col } from 'react-bootstrap';
import moment from 'moment';
import { sendEmailDocumentsRequest } from '../../../../flux/actions/otp-actions';
import { getApprovalProcess } from '../../../../flux/actions/approvalProcess-actions';
import { getId, resetDocumentsStatus, requestId, getDocuments } from '../../../../flux/actions/documents-actions';
import { documentsDescription } from '../../../../constants/documentsDescription';
import { compareIdSelfie } from '../../../../flux/actions/nubariumApi-action';
import { sendAuthMail } from '../../../../flux/actions/mail-actions';
import Loader from '../../../../components/LoaderFile/Loader';

const ValidateId: React.FC = () => {
    //const to get the params
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    const clientRef = (params as any)?.id;

    const user = useSelector((state: any) => state.authReducer.user);
    const { documentValidation, loading, client } = useSelector((state: any) => state.clientReducer);
    const idComparision = useSelector((state: any) => state.NubariumReferenceReducer);
    const quote: any = useSelector((state: any) => state.quoteReducer.credit);
    const files = useSelector((state: any) => state.DocumentsReducer.flowDocuments);
    const { approvalProcess } = useSelector((state: any) => state.approvalProcessReducer);

    const [showDataId, setShowDataId] = useState(false);
    const [showCompareId, setShowCompareId] = useState(false);
    const [approved, setApproved] = useState<boolean[]>([]);
    const [url, setUrl] = useState<any>();

    const [requestModal, setRequestModal] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);
    const [showApprovalModal, setShowApprovalModal] = useState(false);
    const [showCancelRequest, setShowCancelRequest] = useState(false);
    const [fileIne, setFileIne] = useState<any>([]);

    useEffect(() => {
        dispatch(resetDocumentsStatus());
        if (Object.keys(user).length === 0) {
            history.push('/login');
        }
        dispatch(getApprovalProcess(clientRef));
        dispatch(getClient(clientRef));
        dispatch(getId(clientRef));
        dispatch(getDocuments(clientRef));
    }, []);
    useLayoutEffect(() => {
        let aux: any = [];
        setFileIne(
            files.filter((file: any, index: number) => {
                if (file.type === 'idFront' || file.type === 'idBack' || file.type === 'faceCapture') {
                    aux[index] = false;
                    return file;
                }
            })
        );
        setApproved(aux.filter((array: any) => array === false));
    }, [files]);

    function ApproveINEInfo() {
        let stepIndex = 0;
        const approvalProcessClient = approvalProcess.steps?.filter((step: any) => {
            return step.clientStatus !== 'generateCreditCircleNIP';
        });
        approvalProcess.steps.filter((step: any, index: number) => {
            if (step.adminStatus === quote.adminStatus) {
                stepIndex = index + 1;
                if (stepIndex < approvalProcess.steps.length) {
                    if (approvalProcess.steps[index].continuousApprovalFlow) {
                        dispatch(
                            updateStatus({
                                clientRef,
                                type: 'adminStatus',
                                status: approvalProcess.steps[stepIndex].adminStatus
                            })
                        );
                    } else {
                        dispatch(
                            updateStatus({
                                clientRef,
                                type: 'updateBothStates',
                                status: approvalProcessClient[stepIndex].clientStatus,
                                adminStatus: approvalProcess.steps[stepIndex].adminStatus
                            })
                        );
                    }
                } else if (stepIndex === approvalProcess.steps.length) {
                    dispatch(
                        updateStatus({
                            clientRef,
                            type: 'adminStatus',
                            status: 'creditProposal'
                        })
                    );
                }
            }
        });
        setTimeout(() => {
            dispatch(getCredit(clientRef));
        }, 1000);
        setShowApprovalModal(!showApprovalModal);
    }

    const handleSwitch = (e: any) => {
        let aux: any = [...approved];
        aux[Number(e.target.name)] = !aux[Number(e.target.name)];
        setApproved(aux);
    };

    const base64toBlob = (file: any) => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64
        var base64WithoutPrefix = file;
        if (file.includes('dataapplication/pdfbase64')) {
            base64WithoutPrefix = file.substr('dataapplication/pdfbase64'.length);
        }
        if (file.includes('dataapplication/pdfbase64')) {
            base64WithoutPrefix = file.substr('dataapplication/pdfbase64'.length);
        }
        if (file.includes('data:application/pdf;base64,')) {
            base64WithoutPrefix = file.substr('data:application/pdf;base64,'.length);
        }
        const bytes = atob(file);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: 'application/pdf' });
    };

    const requestDocs = () => {
        let auxDocs: any = [...fileIne];
        approved.forEach((isApproved, index) => {
            if (!isApproved) auxDocs[index].file = '';
        });
        const payload = {
            clientRef,
            status: 'uploadIdImages',
            approved: auxDocs
        };
        dispatch(requestId(payload));
        setRequestModal(!requestModal);
        dispatch(getCredit(clientRef));
        dispatch(sendEmailDocumentsRequest(client.email, client.names, client.lastName1, client.lastName2));
    };

    const _renderDescription = (type: string) => {
        switch (type) {
            case 'idFront':
                return documentsDescription.idFront;
            case 'idBack':
                return documentsDescription.idBack;
            case 'faceCapture':
                return documentsDescription.faceCapture;
        }
    };

    function cancelRequest() {
        dispatch(rejectQuote({ client: client.id }));
        setShowCancelRequest(!showCancelRequest);
        dispatch(sendAuthMail({ mailType: 3, email: client.email, name: client.names, lastName1: client.lastName1, lastName2: client.lastName2, folio: quote.folio, amount: quote.amount }));
    }

    return (
        <>
            <Card>
                <Card.Header className="approvalFlow-id-header">
                    <Card.Title as="h5">Verificación de INE:</Card.Title>
                    <span className="id-updateTime">
                        Actualizado hace{' '}
                        {moment().diff(client.updatedAt, 'days') !== 0
                            ? moment().diff(client.updatedAt, 'days')
                            : moment().diff(client.updatedAt, 'hours')}
                        {moment().diff(client.updatedAt, 'days') !== 0 ? ' días' : ' horas'}
                    </span>
                </Card.Header>
                {Object.keys(quote).length !== 0 && Object.values(fileIne).length == 0 ? (
                    <Card.Header className="approvalFlow-id-header">
                        <Card.Title as="h6">El prospecto está realizando la carga de su INE</Card.Title>
                    </Card.Header>
                ) : (
                    Object.keys(client).length !== 0 &&
                    Object.values(fileIne).length > 0 && (
                        <Card.Body className="approvalFlow-id-body">
                            <Card.Title as="h5">Esta es la INE cargada por el usuario</Card.Title>
                            <p>Es necesario realizar el análisis de la información</p>
                            {loading ? (
                                <div className="auth-wrapper align-items-center">
                                    <Loader />
                                </div>
                            ) : (
                                <div>
                                    <Table striped>
                                        <thead>
                                            <tr>
                                                <th>Ver</th>
                                                <th>Documento</th>
                                                <th>¿Aprobado?</th>
                                                <th>Validar INE</th>
                                                <th>Comparar INE con Selfie</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <>
                                                {fileIne &&
                                                    fileIne.map((file: any, index: number) => {
                                                        return (
                                                            <tr id={index.toString()}>
                                                                <td>
                                                                    {file.name === '' ? (
                                                                        ''
                                                                    ) : (
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-third btn-sm btn-border-radius float-center mt-2 id-tableBtn"
                                                                            onClick={() => {
                                                                                const blob = base64toBlob(file.file);
                                                                                const urlAux = URL.createObjectURL(blob);
                                                                                setUrl(urlAux);
                                                                                setShowImageModal(!showImageModal);
                                                                            }}
                                                                        >
                                                                            <i className={'feather icon-file-text'} />
                                                                        </button>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {/* Cambio de en encabezado segun el archivo requerido si se cumple la condicion ejecuta los diferentes encabezados */}
                                                                    <h6>{_renderDescription(file.type)}</h6>
                                                                    <p className="mt-3 align-middle">
                                                                        {/* Muestra el nombre del Archivo si existe si no envia mensaje de espera de documentos */}
                                                                        {file.name === ''
                                                                            ? 'En espera de que el cliente suba sus documentos'
                                                                            : file.name}
                                                                    </p>
                                                                </td>
                                                                <td>
                                                                    {file.name === '' ? (
                                                                        <Form.Group className="mt-3">
                                                                            <p className="switch switch-info d-inline m-r-5 m-l-5">
                                                                                En espera de que el cliente suba sus documentos
                                                                            </p>
                                                                        </Form.Group>
                                                                    ) : (
                                                                        <Form.Group>
                                                                            <Form.Label>NO</Form.Label>
                                                                            <div className="switch switch-info d-inline m-r-5 m-l-5">
                                                                                <Form.Control
                                                                                    type="checkbox"
                                                                                    id={`unchecked-info-${index}`}
                                                                                    name={index.toString()}
                                                                                    checked={approved[index]}
                                                                                    onChange={handleSwitch}
                                                                                />
                                                                                <Form.Label
                                                                                    htmlFor={`unchecked-info-${index}`}
                                                                                    className="cr"
                                                                                />
                                                                            </div>
                                                                            <Form.Label>SI</Form.Label>
                                                                        </Form.Group>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {index === 0 && (
                                                                        <Button
                                                                            variant={'third'}
                                                                            className="btn-border-radius id-tableBtn"
                                                                            data-bs-toggle="tooltip"
                                                                            title="Da click para validar el documento"
                                                                            onClick={() => {
                                                                                setShowDataId(true);
                                                                                dispatch(
                                                                                    validationIne({
                                                                                        id: files[0].file,
                                                                                        idReverso: files[1].file
                                                                                    })
                                                                                );
                                                                            }}
                                                                        >
                                                                            Validar
                                                                        </Button>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {index === 0 && (
                                                                        <Button
                                                                            variant={'third'}
                                                                            className="btn-border-radius id-tableBtn"
                                                                            data-bs-toggle="tooltip"
                                                                            title="Da click para realizar la comparación"
                                                                            onClick={() => {
                                                                                dispatch(
                                                                                    compareIdSelfie({
                                                                                        idFront: client.idFront,
                                                                                        faceCapture: client.faceCapture
                                                                                    })
                                                                                );
                                                                                setShowCompareId(true);
                                                                            }}
                                                                        >
                                                                            Comparar
                                                                        </Button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </>
                                        </tbody>
                                    </Table>
                                    {showDataId && (
                                        <Card className="fst-normal ml-8 p-3">
                                            <Card.Title as={'h5'} className="text-center mx-auto">
                                                Datos del Cliente ID
                                            </Card.Title>
                                            <Row className="mt-2">
                                                <Col>
                                                    <p className="mb-1">
                                                        <strong className="mr-1">Nombre:</strong>
                                                        {documentValidation?.nombres}
                                                    </p>
                                                </Col>
                                                <Col>
                                                    <p className="mb-1">
                                                        <strong className="mr-1">Primer Apellido:</strong>
                                                        {documentValidation?.primerApellido}
                                                    </p>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col>
                                                    <p className="mb-1">
                                                        <strong className="mr-1">Segundo Apellido:</strong>
                                                        {documentValidation?.segundoApellido}
                                                    </p>
                                                </Col>
                                                <Col>
                                                    <p className="mb-1">
                                                        <strong className="mr-1">Sexo:</strong>
                                                        {documentValidation?.sexo}
                                                    </p>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col>
                                                    <p className="mb-1">
                                                        <strong className="mr-1">Fecha de Nacimiento:</strong>
                                                        {documentValidation?.fechaNacimiento}
                                                    </p>
                                                </Col>
                                                <Col>
                                                    <p className="mb-1">
                                                        <strong className="mr-1">CURP:</strong>
                                                        {documentValidation?.curp}
                                                    </p>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col>
                                                    <p className="mb-1">
                                                        <strong className="mr-1">Clave Electoral:</strong>
                                                        {documentValidation?.claveElector}
                                                    </p>
                                                </Col>
                                                <Col>
                                                    <p className="mb-1">
                                                        <strong className="mr-1">Cic:</strong>
                                                        {documentValidation?.cic}
                                                    </p>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col>
                                                    <p className="mb-1">
                                                        <strong className="mr-1">Identificador Ciudadano:</strong>
                                                        {documentValidation?.identificadorCiudadano}
                                                    </p>
                                                </Col>
                                                <Col>
                                                    <p className="mb-1">
                                                        <strong className="mr-1">Tipo:</strong>
                                                        {documentValidation?.tipo}
                                                    </p>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col>
                                                    <p className="mb-1">
                                                        <strong className="mr-1">Subtipo:</strong>
                                                        {documentValidation?.subTipo}
                                                    </p>
                                                </Col>
                                                <Col>
                                                    <p className="mb-1">
                                                        <strong className="mr-1">Sección:</strong>
                                                        {documentValidation?.seccion}
                                                    </p>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col>
                                                    <p className="mb-1">
                                                        <strong className="mr-1">Registro:</strong>
                                                        {documentValidation?.registro}
                                                    </p>
                                                </Col>
                                                <Col>
                                                    <p className="mb-1">
                                                        <strong className="mr-1">Emisión:</strong>
                                                        {documentValidation?.emision}
                                                    </p>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col>
                                                    <p className="mb-1">
                                                        <strong className="mr-1">Vigencia:</strong>
                                                        {documentValidation?.vigencia}
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Card>
                                    )}

                                    {showCompareId && (
                                        <Card className="fst-normal ml-8 p-3">
                                            <Card.Title as={'h5'} className="text-center mx-auto">
                                                Respuesta de la comparación.
                                            </Card.Title>
                                            <Row className="mt-2">
                                                <Col>
                                                    <p className="mb-1">
                                                        <strong className="mr-1">Estado de la validación:</strong>
                                                        {idComparision.successMessage?.estatus}
                                                    </p>
                                                </Col>
                                                <Col>
                                                    <p className="mb-1">
                                                        <strong className="mr-1">Resultado:</strong>
                                                        {idComparision.successMessage?.mensaje}
                                                    </p>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col>
                                                    <p className="mb-1">
                                                        <strong className="mr-1">Similitud:</strong>
                                                        {idComparision.successMessage?.similitud || '0'}%
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Card>
                                    )}
                                </div>
                            )}
                            <Card.Footer className="approvalFlow-id-footer">
                                <Card.Title as="h5">¿Solicitar documentos a usuario?</Card.Title>
                                <Button
                                    className="btn-border-radius id-successBtn"
                                    variant={'success'}
                                    onClick={() => setRequestModal(!requestModal)}
                                >
                                    Solicitar
                                </Button>
                            </Card.Footer>
                            {approved.length === 3 && approved.indexOf(false) === -1 && (
                                <Card.Footer className="approvalFlow-id-footer">
                                    <Card.Title as="h5">¿El prospecto cumple con la información básica para continuar?</Card.Title>
                                    <Row className="mt-3 ml-3">
                                        <Button
                                            className="btn-border-radius id-successBtn"
                                            variant={'success'}
                                            onClick={() => setShowApprovalModal(!showApprovalModal)}
                                        >
                                            Si
                                        </Button>
                                        <Button
                                            variant={'danger'}
                                            className="ml-3 btn-border-radius id-alertBtn"
                                            onClick={() => {
                                                setShowCancelRequest(!showCancelRequest);
                                            }}
                                        >
                                            No
                                        </Button>
                                    </Row>
                                </Card.Footer>
                            )}
                        </Card.Body>
                    )
                )}
            </Card>
            <Modal show={requestModal} onHide={() => setRequestModal(!requestModal)}>
                <Modal.Header closeButton className="approvalFlow-idModal-header">
                    <Modal.Title as="h5">Solicitud de Documentos</Modal.Title>
                </Modal.Header>
                <Modal.Body className="approvalFlow-idModal-body">
                    <p>
                        Los archivos que se seleccionaron como NO aprobados se borrarán permanentemente del sistema
                        <br />
                        <br />
                        ¿Deseas continuar?
                    </p>
                </Modal.Body>
                <Modal.Footer className="approvalFlow-id-footer">
                    <Row className="mt-3 mb-3">
                        <Button className="btn-border-radius id-alertBtn" variant="danger" onClick={() => setRequestModal(!requestModal)}>
                            Cancelar
                        </Button>
                        <Button className="ml-3 btn-border-radius id-successBtn" variant="secondary" onClick={requestDocs}>
                            Solicitar Documentos
                        </Button>
                    </Row>
                </Modal.Footer>
            </Modal>
            <Modal show={showApprovalModal} onHide={() => setShowApprovalModal(!showApprovalModal)}>
                <Modal.Header closeButton className="approvalFlow-idModal-header">
                    <Modal.Title as="h5">El usuario cumple con la documentación inicial</Modal.Title>
                </Modal.Header>
                <Modal.Body className="approvalFlow-idModal-body">
                    <p>
                        Los archivos que subió el prospecto son los necesarios para CONTINUAR con el proceso del crédito
                        <br />
                        <br />
                        ¿Deseas continuar?
                    </p>
                </Modal.Body>
                <Modal.Footer className="approvalFlow-id-footer">
                    <Row className="mt-3 mb-3">
                        <Button
                            className="btn-border-radius id-alertBtn"
                            variant="danger"
                            onClick={() => setShowApprovalModal(!showApprovalModal)}
                        >
                            Cancelar
                        </Button>
                        <Button className="ml-3 btn-border-radius id-successBtn" variant="success" onClick={ApproveINEInfo}>
                            Aprobar
                        </Button>
                    </Row>
                </Modal.Footer>
            </Modal>
            <Modal show={showCancelRequest} onHide={() => setShowCancelRequest(!showCancelRequest)}>
                <Modal.Header closeButton className="approvalFlow-idModal-header">
                    <Modal.Title as="h5">El usuario NO cumple con la información para la solicitud del crédito</Modal.Title>
                </Modal.Header>
                <Modal.Body className="approvalFlow-idModal-body">
                    <p>
                        La información proveída por el cliente no cumple con alguno de los requisitos para ser candidato a un crédito. Esta
                        cotización quedará CANCELADA
                        <br />
                        <br />
                        ¿Deseas continuar?
                    </p>
                </Modal.Body>
                <Modal.Footer className="approvalFlow-id-footer">
                    <Row className="mt-3 mb-3">
                        <Button
                            className="btn-border-radius id-alertBtn"
                            variant="danger"
                            onClick={() => setShowCancelRequest(!showCancelRequest)}
                        >
                            Cancelar
                        </Button>
                        <Button className="ml-3 btn-border-radius id-successBtn" variant="success" onClick={cancelRequest}>
                            Aceptar
                        </Button>
                    </Row>
                </Modal.Footer>
            </Modal>
            <Modal size="xl" show={showImageModal} onHide={() => setShowImageModal(!showImageModal)}>
                <Modal.Header closeButton className="approvalFlow-idModal-header">
                    <Modal.Title as="h5">INE</Modal.Title>
                </Modal.Header>
                <Modal.Body className="approvalFlow-idModal-body">
                    <img src={url} style={{ width: '100%', height: 'auto' }} alt={'Documento de INE'} />
                </Modal.Body>
                <Modal.Footer className="approvalFlow-id-footer">
                    <div className="d-flex justify-content-end mt-3">
                        <Button className="btn-border-radius id-successBtn">
                            <a className="text-white" download={url + '.jpg'} href={url}>
                                Descargar
                            </a>
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default ValidateId;
