import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Modal, Button } from 'react-bootstrap';
import { getCredit, updateStatus } from '../../flux/actions/quote-actions';
import { getApprovalProcess, resetApprovalProcessStatus } from '../../flux/actions/approvalProcess-actions';
import ConfirmationCreditProposal from './ApprovalComponents/ConfirmationCreditProposal';
import InReview from '../../assets/images/success.png';
import SATLogin from './ApprovalComponents/SATLogin';
import ModalWarning from '../../components/ModalWarning';
import RequestedDocuments from './ApprovalComponents/RequestedDocuments';
import ScheduleDateCostumerView from './ApprovalComponents/ScheduleDateCostumerView';
import IdRequest from './ApprovalComponents/IdRequest';
import FaceCapture from './ApprovalComponents/FaceCapture';
import VideoRecord from './ApprovalComponents/VideoRecord';
import { refreshToken } from '../../flux/actions/refreshToken-action';

const MainPage = () => {
    //redux const
    const history = useHistory();
    const dispatch = useDispatch();
    const { client } = useSelector((state: any) => state.clientReducer);
    const { approvalProcess } = useSelector((state: any) => state.approvalProcessReducer);
    const quote: any = useSelector((state: any) => state.quoteReducer.credit);

    useEffect(() => {
        if (Object.keys(client)?.length === 0) {
            history.push('/login');
        }
        dispatch(getCredit(client.id));
        dispatch(getApprovalProcess(client.id));
        dispatch(
            refreshToken({
                token: localStorage.getItem('token'),
                refreshToken: localStorage.getItem('refreshToken')
            })
        );
    }, []);

    const setStatus = async () => {
        const process = await approvalProcess.steps?.filter((step: any) => {
           if (
                step.clientStatus !== 'generateCreditCircleNIP' &&
                step.clientStatus !== 'uploadRequiredDocuments' &&
                step.clientStatus !== 'scheduleDate' &&
                step.clientStatus !== 'uploadIdImages'
            ) {
                return step.clientStatus !== 'generateCreditCircleNIP';
            }
        });

        if (Object.keys(quote).length !== 0 && process !== undefined) {
            if (quote?.status === 'inReview' && Object.keys(process).length > 0) {
                dispatch(
                    updateStatus({
                        type: 'clientStatus',
                        clientRef: client.id,
                        status: process[0].clientStatus ?? 'inReview'
                    })
                );
            }
        }
    };

    useEffect(() => {
        setStatus();
    }, [quote, approvalProcess, client]);

    return (
        <Col className="d-flex flex-column justify-content-center">
            {/* <ModalWarning /> */}
            {Object.keys(quote).length !== 0 && quote.status === 'uploadRequiredDocuments' && <RequestedDocuments />}
            {Object.keys(quote).length !== 0 && quote.status === 'getCredentialsSATWS' && <SATLogin />}
            {Object.keys(quote).length !== 0 && quote.status === 'uploadIdImages' && <IdRequest />}
            {Object.keys(quote).length !== 0 && quote.status === 'lifeProofPending' && <FaceCapture />}
            {Object.keys(quote).length !== 0 && quote.status === 'scheduleDate' && <ScheduleDateCostumerView />}
            {Object.keys(quote).length !== 0 && quote.status === 'recordMessagePending' && <VideoRecord />}
            {Object.keys(quote).length !== 0 && quote.status === 'confirmationCreditProposal' && <ConfirmationCreditProposal />}
            {Object.keys(quote).length !== 0 &&
                (quote.status === 'validateDocuments' ||
                    quote.status === 'pendingApprovalSATWS' ||
                    quote.status === 'pendingApprovalCreditCircle' ||
                    quote.status === 'confirmLifeProof' ||
                    quote.status === 'confirmRecordMessage' ||
                    quote.status === 'confirmIdValidation' ||
                    quote.status === 'completionOfTheApprovalFlow' ||
                    quote.status === 'inReview') && (
                    <Card className="inReview">
                        <Row>
                            <Col>
                                <Card.Title as={'h2'}>Hemos recibido tu solicitud</Card.Title>
                                <div className="divider-title"></div>
                                <Card.Subtitle as={'h5'}>En breve te notificaremos para avanzar con el proceso.</Card.Subtitle>
                                <img src={InReview} alt=" tu solicitud Imagen" />
                            </Col>
                        </Row>
                    </Card>
                )}
        </Col>
    );
};
export default MainPage;
