import { Reducer } from 'redux';
import CIRCLE_CREDIT_CONSTANTS from '../constants/circleCredit-constants';
import { circleCreditAction } from '../types/circleCredit-types';

export interface circleCreditReducerState {
    loading: boolean;
    success: boolean;
    successMessage: string;
    error: boolean;
    errorMessage?: string;
    errorStatus?: number;
    creditReport?: {};
}

const initialState: circleCreditReducerState = {
    loading: false,
    success: false,
    error: false,
    successMessage: '',
    errorMessage: '',
    errorStatus: 0,
    creditReport: {}
}

export const circleCreditReducer: Reducer<circleCreditReducerState, circleCreditAction> = (state = initialState, action: circleCreditAction) => {
    switch (action.type) {
        //-----------------------------------------------//
        case CIRCLE_CREDIT_CONSTANTS.GET_RCC_PM_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                creditReport: {},
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            }

        case CIRCLE_CREDIT_CONSTANTS.GET_RCC_PM_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                errorStatus: action.error.data.status,
                errorMessage: action.error.data.message,
                loading: false
            }

        case CIRCLE_CREDIT_CONSTANTS.GET_RCC_PM_SUCCESS:
            return {
                ...state,
                success: true,
                successMessage: action.data.message,
                creditReport: action.data,
                error: false,
                errorMessage: '',
                errorStatus: 0,
                loading: false
            }
        
        //------------------------------------------//
        case CIRCLE_CREDIT_CONSTANTS.GET_RCC_FICOSCORE_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                creditReport: {},
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            }

        case CIRCLE_CREDIT_CONSTANTS.GET_RCC_FICOSCORE_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                errorStatus: action.error.data.status,
                errorMessage: action.error.data.message,
                loading: false
            }

        case CIRCLE_CREDIT_CONSTANTS.GET_RCC_FICOSCORE_SUCCESS:
            return {
                ...state,
                success: true,
                successMessage: action.data.message,
                creditReport: action.data,
                errorMessage: '',
                errorStatus: 0,
                loading: false
            }

        case CIRCLE_CREDIT_CONSTANTS.RESET_CIRCLE_CREDIT:
            return {
                success: false,
                error: false,
                loading: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: '',
                creditReport: {}
            }

        default:
            return state;
    }
} 