import { useEffect, useState, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Card, Modal, Alert } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { updateDocuments, uploadId } from '../../flux/actions/documents-actions';
import { documentsDescription } from '../../constants/documentsDescription';
import { getCredit } from '../../flux/actions/quote-actions';

interface Props {
    files: [];
    title: string;
    description: string;
    model: string;
}

const UploadFilesBuilder: FC<Props> = (p) => {
    //redux const
    const dispatch = useDispatch();
    const { approvalProcess } = useSelector((state: any) => state.approvalProcessReducer);
    const quote: any = useSelector((state: any) => state.quoteReducer.credit);
    const { client } = useSelector((state: any) => state.clientReducer);
    const { errorMessage } = useSelector((state: any) => state.DocumentsReducer);

    //local const
    const [documents, setDocuments] = useState<any[]>([]);
    const [confirmModal, setConfirmModal] = useState(false);
    const [errors, setErrors] = useState({ files: '' });
    const [filesRequestType, setFilesRequestType] = useState(['']);
    const [indexButon, setIndexButton] = useState<number>(0);

    //dropzone config
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        maxFiles: 1,
        noDrag: true,
        accept: filesRequestType
    });

    /*Validates if the document is empty and if it is empty adds null to each element 
    otherwise it splits the string and assigns the file information to the new array*/
    useEffect(() => {
        setDocuments(p.files);
    }, [p.files]);

    /*Add new files every time a new file is uploaded*/
    useEffect(() => {
        setErrors({ files: '' });
        const auxFile: any[] = [...documents];

        // eslint-disable-next-line array-callback-return
        acceptedFiles.map((file) => {
            const reader = new FileReader();

            reader.onload = () => {
                if (auxFile.findIndex((file) => file.file === '') !== -1) {
                    auxFile[indexButon] = { ...auxFile[indexButon], file: reader.result, name: file.name };
                }
                setDocuments(auxFile);
            };

            reader.readAsDataURL(file);

            reader.onerror = (error) => {
                console.log('algo salio mal', error);
            };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acceptedFiles]);

    /*Update voucher files of address statement of account and photograph of the ine in the database and it show the modal*/
    const postDocuments = async () => {
        let auxError = '';

        documents.forEach((info: any) => {
            if (info.file === '') {
                auxError = 'Favor de cargar todos los archivos solicitados';
            }
        });

        let stepIndex = 0;
        let nextStep = '';

        const flowApprovalProcess = approvalProcess?.steps?.filter((step: any) => {
            return step.clientStatus !== 'generateCreditCircleNIP';
        });

        if (flowApprovalProcess) {
            flowApprovalProcess.forEach((step: any, index: number) => {
                if (step.clientStatus === quote.status) {
                    stepIndex = index + 1;
                    if (stepIndex < flowApprovalProcess.length) {
                        nextStep = flowApprovalProcess[index].continuousApprovalFlow
                            ? flowApprovalProcess[stepIndex].clientStatus
                            : flowApprovalProcess[index].adminStatus;
                    } else if (stepIndex === flowApprovalProcess.length) {
                        nextStep = 'completionOfTheApprovalFlow';
                    }
                }
            });
        }

        setErrors({ ...errors, files: auxError });

        if (auxError !== '') return;

        const payload = {
            documents,
            flowStatus: nextStep,
            clientRef: client.id
        };

        p.model === 'docsRequested' ? dispatch(updateDocuments(payload)) : dispatch(uploadId(payload));
        setConfirmModal(!confirmModal);
    };

    // function to Upload all about the document in the right position
    const handleUpload = (e: any) => {
        const index = Number(e?.target?.name);
        setIndexButton(index);
    };

    /*Delete a file from the system*/
    const handleDelete = (e: any) => {
        const auxFiles = [...documents];
        const index = Number(e.target.name);
        auxFiles[index] = { ...auxFiles[index], name: '', file: '' };
        setDocuments(auxFiles);
    };

    //Render description of the documents in the screen
    const _renderDescription = (type: string) => {
        switch (type) {
            case 'bankReceipt1':
                return documentsDescription.bankReceipt1;
            case 'bankReceipt2':
                return documentsDescription.bankReceipt2;
            case 'bankReceipt3':
                return documentsDescription.bankReceipt3;
            case 'idFront':
                return documentsDescription.idFront;
            case 'idBack':
                return documentsDescription.idBack;
            case 'addressProof':
                return documentsDescription.addressProof;
            case 'bussinessAddressProof':
                return documentsDescription.bussinessAddressProof;
            case 'constitutiveAct':
                return documentsDescription.constitutiveAct;
            case 'actGrantingPowers':
                return documentsDescription.actGrantingPowers;
            case 'taxpayerIDCard':
                return documentsDescription.taxpayerIDCard;
            case 'certificateESignature':
                return documentsDescription.certificateESignature;
        }
    };

    // Update the status of the credit
    const statusUpdate = async () => {
        setTimeout(() => {
            dispatch(getCredit(client.id));
        }, 1000);
    };

    //Render the card of the documents
    const _renderCardToUploadFile = (document: any, index: number) => {
        if (document.file.includes('data:') || document.name === '') {
            return (
                <Col md={12} className="mt-4 mt-md-0" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Card>
                        <Card.Body>
                            {document.file === '' && (
                                <div className="d-flex">
                                    <i style={{ fontSize: 20, color: '#495057' }} className={'feather icon-upload-cloud'} />
                                    <Card.Title as="h5">{_renderDescription(document.type)}</Card.Title>
                                </div>
                            )}
                            {document.name !== '' ? (
                                <div className="d-flex">
                                    <i style={{ fontSize: 20, color: '#30C39E' }} className={'feather icon-check-circle'} />
                                    <Card.Text>{document.name}</Card.Text>
                                </div>
                            ) : (
                                <>
                                    <Button
                                        className="mt-3 uploadBtn"
                                        name={index.toString()}
                                        onClickCapture={(e) => {
                                            if (document?.type === 'idBack' || document?.type === 'idFront') {
                                                handleUpload(e);
                                                setFilesRequestType(['.png', '.jpeg', '.jpg']);
                                            } else {
                                                handleUpload(e);
                                                setFilesRequestType(['application/pdf', '.pdf']);
                                            }
                                        }}
                                    >
                                        Agregar Archivo
                                    </Button>
                                </>
                            )}
                            {document.name !== '' && (
                                <Button variant="danger" className="deleteBtn" name={index.toString()} onClick={handleDelete}>
                                    Eliminar Archivo
                                </Button>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            );
        } else {
            return (
                <Col md={12} className="mt-4 mt-md-0">
                    <Card>
                        <Card.Body>
                            <div className="d-flex">
                                <i style={{ fontSize: 20, color: '#30C39E' }} className={'feather icon-check-circle'} />
                                <Card.Title as="h5">{_renderDescription(document.type)}</Card.Title>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            );
        }
    };

    return (
        <>
            <Card className="text-center uploadFiles">
                <Card.Title as="h2" className="mt-3 uploadFiles-title">
                    {p.title}
                </Card.Title>
                <div className="divider-title"></div>
                <Card.Title as="h4" className="mt-3 uploadFiles-subtitle">
                    {p.description}
                </Card.Title>
                <p className="uploadFiles-text">(Archivo .pdf o imagen no mayor a 50 Mb)</p>
                <Card.Body className="uploadFiles-docs">
                    <Row className="d-flex flex-row align-items-center justify-content-center">
                        {documents.length !== 0 &&
                            documents.map((document: any, index: number) => _renderCardToUploadFile(document, index))}
                    </Row>
                    {errors.files && (
                        <Card.Header className="uploadFiles-error">
                            <Card.Title as="h4">{errors.files}</Card.Title>
                        </Card.Header>
                    )}
                    <Row className="d-flex flex-row align-items-center justify-content-center">
                        <Col className="d-flex flex-column align-items-center justify-content-center mt-4">
                            <Button
                                className="btn-border-radius uploadFiles-button"
                                variant="third"
                                size="lg"
                                onClick={() => {
                                    setConfirmModal(!confirmModal);
                                }}
                            >
                                <dt>Guardar Información</dt>
                            </Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            {errorMessage && (
                <Alert className="m-2 d-flex justify-content-between" style={{ position: 'relative' }} variant={'warning'}>
                    <strong className="m-auto">{errorMessage}.</strong>
                </Alert>
            )}
            <Modal show={confirmModal} onHide={() => setConfirmModal(!confirmModal)}>
                <Modal.Header closeButton className="uploadFiles-modal-header">
                    <Modal.Title as="h5">Confirmación de Actualización de Documentos</Modal.Title>
                </Modal.Header>
                <Modal.Body className="uploadFiles-modal-body">
                    <p>
                        Los archivos estan a punto de ser cargados, estas seguro que los archivos son los correctos?
                        <br />
                        <br />
                        ¿Deseas continuar?
                    </p>
                </Modal.Body>
                <Modal.Footer className="uploadFiles-modal-footer">
                    <Row className="mt-3 mb-3">
                        <Button className="btn-border-radius alertBtn" variant="danger" onClick={() => setConfirmModal(!confirmModal)}>
                            Cancelar
                        </Button>
                        <Button
                            className="ml-3 btn-border-radius successBtn"
                            variant="success"
                            onClick={() => {
                                postDocuments();
                                setConfirmModal(!confirmModal);
                                statusUpdate();
                            }}
                        >
                            Cargar Documentos
                        </Button>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default UploadFilesBuilder;
