import { Dispatch } from 'redux';
import { API } from '../api/unic-api';
import NUBARIUM_REFERENCE_CONSTANTS from '../constants/nubarium-constant';
import * as types from '../types/nubarium-types';

const postNubariumReferenceBeggin = (): types.IPostNubariumBegin => {
    return {
        type: NUBARIUM_REFERENCE_CONSTANTS.POST_NUBARIUM_REFERENCE_BEGIN
    };
};

const postNubariumReferenceError = (e: any): types.IPostNubariumError => {
    return {
        type: NUBARIUM_REFERENCE_CONSTANTS.POST_NUBARIUM_REFERENCE_ERROR,
        error: e
    };
};

const postNubariumReferenceSuccess = (data: any): types.IPostNubariumSuccess => {
    return {
        type: NUBARIUM_REFERENCE_CONSTANTS.POST_NUBARIUM_REFERENCE_SUCCESS,
        data
    };
};

export const idReference = (payload: Object) => {
    return (dispatch: Dispatch) => {
        dispatch(postNubariumReferenceBeggin());
        API.idReference(payload)
            .then((response: any) => {
                dispatch(postNubariumReferenceSuccess(response.data));
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = '/login';
                }
                dispatch(postNubariumReferenceError(error.response));
            });
    };
};

//----------------COMPARE ID VS SELFIE--------------------///
const compareIdSelfieBegin = (): types.ICompareIdBegin => {
    return {
        type: NUBARIUM_REFERENCE_CONSTANTS.COMPARE_ID_SELFIE_BEGIN
    };
};

const compareIdSelfieError = (e: any): types.ICompareIdError => {
    return {
        type: NUBARIUM_REFERENCE_CONSTANTS.COMPARE_ID_SELFIE_ERROR,
        error: e
    };
};

const compareIdSelfieSuccess = (data: any): types.ICompareIdSuccess => {
    return {
        type: NUBARIUM_REFERENCE_CONSTANTS.COMPARE_ID_SELFIE_SUCCESS,
        data
    };
};

export const compareIdSelfie = (payload: any) => {
    return (dispatch: Dispatch) => {
        dispatch(compareIdSelfieBegin());
        API.compareIdSelfie(payload)
            .then((Response: any) => {
                dispatch(compareIdSelfieSuccess(Response.data));
            })
            .catch((error: any) => {
                dispatch(compareIdSelfieError(error.response));
            });
    };
};

//----------------DOWNLOAD NUBARIUM MEDIA--------------------///
const downloadMediaBegin = (): types.IDownloadBegin => {
    return {
        type: NUBARIUM_REFERENCE_CONSTANTS.DOWNLOAD_MEDIA_BEGIN
    };
};

const downloadMediaError = (e: any): types.IDownloadError => {
    return {
        type: NUBARIUM_REFERENCE_CONSTANTS.DOWNLOAD_MEDIA_ERROR,
        error: e
    };
};

const downloadMediaSuccess = (data: any): types.IDownloadSuccess => {
    return {
        type: NUBARIUM_REFERENCE_CONSTANTS.DOWNLOAD_MEDIA_SUCCESS,
        data
    };
};

export const downloadMedia = (payload: any) => {
    return (dispatch: Dispatch) => {
        dispatch(downloadMediaBegin());
        API.downloadMedia(payload)
            .then((Response: any) => {
                dispatch(downloadMediaSuccess(Response.data));
            })
            .catch((error: any) => {
                dispatch(downloadMediaError(error.response));
            });
    };
};

//----------------RESET CLIENT STATUS --------------------------------------//
const resetStatus = () => {
    return {
        type: NUBARIUM_REFERENCE_CONSTANTS.RESET_NUBARIUM_STATUS
    };
};

export const resetClientStatus = () => {
    return (dispatch: Dispatch) => {
        dispatch(resetStatus());
    };
};
