import React, { useEffect, useState } from 'react';
import { Card, Modal, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { documentsDescription } from '../../../../constants/documentsDescription';
import SchedulePhotos from '../../../../components/Builders/SchedulePhotos';
import moment from 'moment';

const ScheduleDocuments: React.FC = () => {
    //redux const
    const { client } = useSelector((state: any) => state.clientReducer);
    const { bussinessInfo } = useSelector((state: any) => state.bussinessInfoReducer);
    const files = useSelector((state: any) => state.DocumentsReducer.flowDocuments);

    //local const
    const [showPDFModal, setShowPDFModal] = useState(false);
    const [url, setUrl] = useState<any>();

    //BUSINESS ADDRESS INFORMATION CONST
    const [zipCode, setZipCode] = useState(bussinessInfo.zipCode || '');
    const [municipalityBussiness, setMunicipalityBussiness] = useState(bussinessInfo.municipality || '');
    const [cityBussiness, setCityBussiness] = useState(bussinessInfo.state || '');
    const [stateBussiness, setStateBussiness] = useState(bussinessInfo.state || '');
    const [suburbBussiness, setSuburbBussiness] = useState(bussinessInfo.suburb || '');
    const [streetBussiness, setStreetBussiness] = useState(bussinessInfo.street || '');
    const [numIntBussiness, setNumIntBussiness] = useState(bussinessInfo.numInt || '');
    const [numExtBussiness, setNumExtBussiness] = useState(bussinessInfo.numExt || '');

    //Se guardan los index de la domicilio personal y la del negocio, ademas se asigna la información de la direccion segun el tipo de direccion
    useEffect(() => {
        if (Object.keys(bussinessInfo).length !== 0) {
            setZipCode(bussinessInfo.zipCode || '');
            setMunicipalityBussiness(bussinessInfo.municipality || '');
            setStateBussiness(bussinessInfo.state || '');
            setCityBussiness(bussinessInfo.city || '');
            setSuburbBussiness(bussinessInfo.suburb || '');
            setStreetBussiness(bussinessInfo.street || '');
            setNumIntBussiness(bussinessInfo.numInt || '');
            setNumExtBussiness(bussinessInfo.numExt || '');
        }
    }, [bussinessInfo]);

    const _renderDescription = (type: string) => {
        switch (type) {
            case 'addressProof':
                return documentsDescription.addressProof;
            case 'bussinessAddressProof':
                return documentsDescription.bussinessAddressProof;
        }
    };

    const base64toBlob = (file: any) => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64
        var base64WithoutPrefix = file;
        if (file.includes('dataapplication/pdfbase64')) {
            base64WithoutPrefix = file.substr('dataapplication/pdfbase64'.length);
        }
        if (file.includes('dataapplication/pdfbase64')) {
            base64WithoutPrefix = file.substr('dataapplication/pdfbase64'.length);
        }
        if (file.includes('data:application/pdf;base64,')) {
            base64WithoutPrefix = file.substr('data:application/pdf;base64,'.length);
        }
        const bytes = atob(file);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: 'application/pdf' });
    };

    return (
        <>
            <Card>
                <Card.Header className="approvalFlow-schedule-header">
                    <Card.Title as="h5">Información y documentos de vista:</Card.Title>
                    <p className="mt-2">Consulta la Información y documentos del domicilio y sube las fotografías de la vista.</p>
                    <span className="schedule-updateTime">
                        Actualizado hace{' '}
                        {moment().diff(client.updatedAt, 'days') !== 0
                            ? moment().diff(client.updatedAt, 'days')
                            : moment().diff(client.updatedAt, 'hours')}
                        {moment().diff(client.updatedAt, 'days') !== 0 ? ' días' : ' horas'}
                    </span>
                </Card.Header>
                <Card.Body className="approvalFlow-schedule-body">
                    <div className="d-flex flex-column mx-auto" style={{ width: '80%' }}>
                        <Card.Title as="h4" className="text-center schedule-titles">
                            Domicilio del Negocio
                        </Card.Title>
                        <Row className="mb-3">
                            <Col>
                                <p>
                                    Código Postal: <br /> <b>{zipCode}</b>
                                </p>
                            </Col>
                            <Col>
                                <p>
                                    Estado: <br /> <b>{stateBussiness}</b>
                                </p>
                            </Col>
                            <Col>
                                <p>
                                    Ciudad: <br /> <b>{cityBussiness}</b>
                                </p>
                            </Col>
                            <Col>
                                <p>
                                    Municipio: <br /> <b>{municipalityBussiness}</b>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                    Colonia: <br /> <b>{suburbBussiness}</b>
                                </p>
                            </Col>
                            <Col>
                                <p>
                                    Calle: <br /> <b>{streetBussiness}</b>
                                </p>
                            </Col>
                            <Col>
                                <p>
                                    Número Int.: <br /> <b>{numIntBussiness}</b>
                                </p>
                            </Col>
                            <Col>
                                <p>
                                    Número Ext.: <br /> <b>{numExtBussiness}</b>
                                </p>
                            </Col>
                        </Row>
                    </div>
                    <div className="d-flex flex-column mt-5">
                        <Card.Title as="h4" className="text-center schedule-titles">
                            Comprobantes de Domicilio
                        </Card.Title>
                        {files &&
                            files?.map((file: any) => {
                                if (file.type === 'addressProof' || file.type === 'bussinessAddressProof') {
                                    return (
                                        <Col className="customerProfile-files">
                                            {file.name === '' ? (
                                                ''
                                            ) : (
                                                <div className="schedule-addressDocs">
                                                    <p className="mt-3 align-middle">
                                                        <i className={'feather icon-download-cloud'} /> {_renderDescription(file.type)}
                                                    </p>
                                                    <button
                                                        type="button"
                                                        className="btn btn-third btn-sm btn-border-radius float-center mt-2 doc-showBtn"
                                                        onClick={() => {
                                                            const blob = base64toBlob(file.file);
                                                            const urlAux = URL.createObjectURL(blob);
                                                            setUrl(urlAux);
                                                            setShowPDFModal(!showPDFModal);
                                                        }}
                                                    >
                                                        Descargar
                                                    </button>
                                                </div>
                                            )}
                                        </Col>
                                    );
                                }
                            })}
                    </div>
                    <div className="d-flex flex-column mt-5">
                        <Card.Title as="h4" className="text-center schedule-titles">
                            Fotografías de negocio
                        </Card.Title>
                        {files && files?.length !== 0 && <SchedulePhotos />}
                    </div>
                </Card.Body>
                <Modal size="xl" show={showPDFModal} onHide={() => setShowPDFModal(!showPDFModal)}>
                    <Modal.Header closeButton className="approvalFlow-docsModal-header">
                        <Modal.Title as="h5">Documento</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <iframe src={url} title="Documentación" height="700rem" width="100%" />
                    </Modal.Body>
                </Modal>
            </Card>
        </>
    );
};

export default ScheduleDocuments;
