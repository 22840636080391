import { Reducer } from 'redux';
import mailModel from '../../models/mail-model';
import MAIL_CONSTANTS from '../constants/mail-constants';
import { MailAction } from '../types/mail-types';

export interface MailReducerState {
    loading: boolean;
    success: boolean;
    successMessage: string;
    mail: mailModel;
    error: boolean;
    errorMessage?: string;
    errorStatus?: number;
}

const initialState: MailReducerState = {
    loading: false,
    success: false,
    error: false,
    mail: {} as mailModel,
    successMessage: '',
    errorMessage: '',
    errorStatus: 0
};

export const mailReducer: Reducer<MailReducerState, MailAction> = (state = initialState, action: MailAction) => {
    switch (action.type) {
        //------------------ MAIL SEND -----------------------------
        case MAIL_CONSTANTS.MAIL_SEND_BEGIN:
            return {
                ...state,
                errorMessage: '',
                loading: true,
                loginError: false,
                errorStatus: 0
            };

        case MAIL_CONSTANTS.MAIL_SEND_ERROR:
            return {
                ...state,
                errorMessage: action.error?.errorMessage,
                errorStatus: action.error ? action.error.status : 0,
                loginError: true,
                loading: false
            };

        case MAIL_CONSTANTS.MAIL_SEND_SUCCESS:
            return {
                ...state,
                errorMessage: '',
                loginError: false,
                successMessage: '',
                loading: false,
                mail: action.data,
                errorStatus: 0
            };

        //--------------------- AUTH MAIL SEND -----------------------------
        case MAIL_CONSTANTS.AUTH_MAIL_SEND_BEGIN:
            return {
                ...state,
                errorMessage: '',
                loading: true,
                loginError: false,
                errorStatus: 0
            };

        case MAIL_CONSTANTS.AUTH_MAIL_SEND_ERROR:
            return {
                ...state,
                errorMessage: action?.error?.errorMessage,
                errorStatus: action.error ? action.error.status : 0,
                loginError: true,
                loading: false
            };

        case MAIL_CONSTANTS.AUTH_MAIL_SEND_SUCCESS:
            return {
                ...state,
                errorMessage: '',
                loginError: false,
                successMessage: '',
                loading: false,
                mail: action.data,
                errorStatus: 0
            };

        //--------------------- RESET MAIL SEND -----------------------------
        case MAIL_CONSTANTS.RESET_MAIL_STATUS:
            return {
                success: false,
                error: false,
                loading: false,
                mail: {} as mailModel,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            };

        default:
            return state;
    }
};
