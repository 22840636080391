enum AFFILIATE_CONSTANTS {
    POST_AFFILIATE_BEGIN = "POST_AFFILIATE_BEGIN",
    POST_AFFILIATE_ERROR = "POST_AFFILIATE_ERROR",
    POST_AFFILIATE_SUCCESS = "POST_AFFILIATE_SUCCESS",
    
    GET_AFFILIATE_BEGIN = "GET_AFFILIATE_BEGIN",
    GET_AFFILIATE_ERROR = "GET_AFFILIATE_ERROR",
    GET_AFFILIATE_SUCCESS = "GET_AFFILIATE_SUCCESS",

    RESET_AFFILIATE_STATUS = "RESET_AFFILIATE_STATUS"
};

export default AFFILIATE_CONSTANTS;