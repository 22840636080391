import React, { FC, useEffect, useState } from 'react';
import { Button, Col, Card, Row } from 'react-bootstrap';
import BuildForm from './BuildForm';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { BsWhatsapp } from 'react-icons/bs';
import Loader from '../../components/LoaderFile/Loader';
import { getDefaultFlow, getFlowById } from '../../flux/actions/flow-actions';
import { getCredit, resetQuoteStatus } from '../../flux/actions/quote-actions';
import { getClient, logout, stepBack } from '../../flux/actions/client-actions';
import logo from '../../assets/images/kualinetBlanco.png';
import logoMobile from '../../assets/images/logoMobile.png';
import { refreshToken } from '../../flux/actions/refreshToken-action';

var mobile = require('is-mobile');

const DefaultFlow: FC = () => {
    //Redux vars
    const history = useHistory();
    const dispatch = useDispatch();
    const client = useSelector((state: any) => state.clientReducer.client);
    const { flow, loading } = useSelector((state: any) => state.flowReducer);
    const { credit } = useSelector((state: any) => state.quoteReducer);
    const [flowType, setFlowType] = useState(0);

    useEffect(() => {
        dispatch(resetQuoteStatus());
        setTimeout(() => {
            if (credit._id === undefined) {
                history.push('/cotizador-principal');
            }
        }, 250);
    }, []);

    useEffect(() => {
        if (client && client.id) {
            dispatch(getCredit(client.id));
            dispatch(getDefaultFlow());
            dispatch(
                refreshToken({
                    token: localStorage.getItem('token'),
                    refreshToken: localStorage.getItem('refreshToken')
                })
            );
        } else {
            history.push('/login');
        }
    }, [dispatch, history]);

    useEffect(() => {
        if (Object.keys(flow).length > 0) {
            flow?.map((flow: any, index: number) => {
                if (flow.taxRegime == client.taxRegime) {
                    setFlowType(index);
                }
            });
            dispatch(
                refreshToken({
                    token: localStorage.getItem('token'),
                    refreshToken: localStorage.getItem('refreshToken')
                })
            );
        }
    }, [flow, client]);

    if (loading) {
        return (
            <div className="auth-wrapper align-items-center">
                <Loader />
            </div>
        );
    }

    return (
        <>
            <div className="auth-wrapper align-items-stretch onBoarding">
                <Col xs={12} className="onBoarding-container">
                    <Card>
                        <Card.Header className={'d-flex'}>
                            <Col md={12} className={'d-flex onBoarding-header-col'}>
                                <img className="onBoarding-header-logo" src={logo} alt="Logo empresa" />
                                <img className="onBoarding-header-logoMobile" src={logoMobile} alt="Logo empresa" />
                                <Card.Title className="mt-2" as={mobile() ? 'h4' : 'h3'}>
                                    {flow[flowType]?.name}
                                </Card.Title>
                                <Button className="btn-border-radius color-white" onClick={() => dispatch(logout())}>
                                    <b>
                                        <i className={'feather icon-log-out'} />
                                    </b>
                                </Button>
                            </Col>
                        </Card.Header>
                        <Card.Body>
                            {flow.length !== 0 && flow !== undefined && (
                                <>
                                    <BuildForm
                                        stepController={client.onBoardingStep}
                                        onContinue={() => {
                                            dispatch(getClient(client.id));
                                            dispatch(
                                                refreshToken({
                                                    token: localStorage.getItem('token'),
                                                    refreshToken: localStorage.getItem('refreshToken')
                                                })
                                            );
                                        }}
                                    />
                                    <Button
                                        className="btn-border-radius onBoarding-whatsapp-btn"
                                        style={{ background: '#20c997', borderColor: '#20c997' }}
                                        onClick={() =>
                                            window.open(
                                                `https://api.whatsapp.com/send?phone=524461442933&text=%C2%A1Hola!%20Soy%20${client.names}%20${client.lastName1}%20${client.lastName2},%20mi%20correo%20es%20${client.email}%20y%20necesito%20ayuda.`
                                            )
                                        }
                                    >
                                        <span>Soporte</span>
                                        <b>
                                            <BsWhatsapp className="onBoarding-whatsapp-icon" style={{ marginLeft: -9, marginTop: -2 }} />
                                        </b>
                                    </Button>
                                    {client.onBoardingStep > 0 && (
                                        <Button
                                            className="btn-border-radius color-white onBoarding-button backBtn"
                                            variant="third"
                                            onClick={() => {
                                                dispatch(stepBack(client.id));
                                                dispatch(getClient(client.id));
                                                dispatch(
                                                    refreshToken({
                                                        token: localStorage.getItem('token'),
                                                        refreshToken: localStorage.getItem('refreshToken')
                                                    })
                                                );
                                            }}
                                        >
                                            <b>
                                                <i className={'feather icon-arrow-left'} /> Regresar
                                            </b>
                                        </Button>
                                    )}
                                </>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        </>
    );
};
export default DefaultFlow;
