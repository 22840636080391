import React, { FC, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import validator from 'validator';
import '../../assets/scss/style.scss';
import { login, clearAuthLoading } from '../../flux/actions/auth-actions';
import logo from '../../assets/images/kualinetLogo.png';
import Slider from '../../components/Slider';

const AdminLogin: FC = () => {
    //Redux vars
    const history = useHistory();
    const dispatcher = useDispatch();
    const user = useSelector((state: any) => state.authReducer.user);
    const userReducer = useSelector((state: any) => state.authReducer);

    //local vars
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    //errors
    const [errors, setErrors] = useState({ password: "", email: "", login: "" });

    useEffect(() => {
        dispatcher(clearAuthLoading());
    }, [dispatcher]);

    useEffect(() => {
        if (userReducer.errorStatus) {
            setErrors({ ...errors, login: 'Usuario o contraseña inválidos' });
        }
    }, [userReducer.errorStatus]);

    //function to redirect when the user logged in the system
    useEffect(() => {
        if (Object.keys(user).length !== 0) {
            if (user && user.active === true) {
                history.push('/admin/prospectos');
            }
        }
    }, [history, user]);

    //main function to validate the inputs and the information, once is validated we request the login
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const errorEmail = validator.isEmail(email) ? '' : 'Correo inválido';
        const passwordErrors = validator.isStrongPassword(password) ? '' : 'Contraseña inválida';

        setErrors({
            email: errorEmail,
            password: passwordErrors,
            login: ''
        });

        if ( passwordErrors) return;
        dispatcher(login({ user: { email , password } }));

    };

    return (
        <>
            <div className="loginAdmin">
                <div className="loginAdmin-hero">
                    <Slider/>
                </div>
                <div className="loginAdmin-container">
                    <div className="loginAdmin-content">
                        <img src={logo} alt="logo" className="loginAdmin-logo" />
                        <h4 className="loginAdmin-title">Staff</h4>
                        <form className="loginAdmin-form" onSubmit={onSubmit}>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="email"
                                    placeholder="Correo electrónico o email"
                                    name='disabledCP'
                                    autoComplete="off"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value.trim());
                                        setErrors({ ...errors, email: ''});
                                    }}
                                />
                                {errors.email && <p className="form-text text-left text-danger">{errors.email}</p>}
                            </div>
                            <div className="form-group">
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    placeholder="Contraseña"
                                    name='disabledCP'
                                    autoComplete="off"
                                    onChange={(e) => {
                                        setPassword(e.target.value.trim());
                                        setErrors({ ...errors, password: '' });
                                    }}
                                />
                                {errors.password && (
                                    <p id="passwordHelpBlock" className="form-text text-left text-danger">
                                        {errors.password}
                                    </p>
                                )}
                                <NavLink to="/recuperar-contraseña" className="loginAdmin-resetPassword">¿Olvidaste tu contraseña?</NavLink>
                            </div>
                            <div className="form-group">
                                {errors.login && (
                                    <p id="loginHelpBlock" className="form-text text-left text-danger">
                                        {errors.login}
                                    </p>
                                )}
                                <button
                                    className="loginAdmin-button"
                                    type="submit"
                                >
                                    <b>Iniciar sesión</b>
                                </button>
                            </div>
                            <p className="loginAdmin-text">
                                ¿Aún no tienes cuenta con nosotros?
                            </p>
                            <NavLink to="/signup" className="loginAdmin-buttonSignup">
                                Crear cuenta
                            </NavLink>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};
export default AdminLogin;
