import { Reducer } from 'redux';
import { AuthAction } from '../types/auth-types';
import UserModel from '../../models/user-model';
import FLUX_AUTH_CONSTANTS from '../constants/auth-constants';

export interface AuthReducerState {
    backTo?: string;
    loading: boolean;
    errorMessage: string;
    errorStatus?: number;
    loginError: boolean;
    users: UserModel;
    user: UserModel;
    loadingUserData: boolean;
    success: boolean;
    successMessage: string;
    curpMessage: string;
}

const initialState: AuthReducerState = {
    backTo: undefined,
    loading: false,
    errorMessage: '',
    errorStatus: 0,
    loginError: false,
    users: {} as UserModel,
    user: {} as UserModel,
    loadingUserData: false,
    success: false,
    successMessage: '',
    curpMessage: ''
};

export const authReducer: Reducer<AuthReducerState, AuthAction> = (state = initialState, action: AuthAction) => {
    let errorMessage = 'ERROR';
    switch (action.type) {
        //------------------ LOGIN -----------------------------
        case FLUX_AUTH_CONSTANTS.LOGIN_BEGIN:
            return {
                ...state,
                errorMessage: '',
                loading: true,
                loginError: false,
                errorStatus: 0
            };

        case FLUX_AUTH_CONSTANTS.LOGIN_ERROR:
            errorMessage = 'ERROR';
            if (action.error && action.error.data) {
                errorMessage = action.error.data.errors ? action.error.data.errors.emailOrPassword : action.error.data.error;
            }
            return {
                ...state,
                errorMessage: errorMessage,
                errorStatus: action.error ? action.error.status : 0,
                loginError: true,
                loading: false
            };

        case FLUX_AUTH_CONSTANTS.LOGIN_SUCCESS:
            return {
                ...state,
                errorMessage: '',
                loginError: false,
                successMessage: 'Login exitoso',
                loading: false,
                user: action.data ? action.data : {},
                errorStatus: 0
            };
        //------------------ CREATE USER -----------------------------
        case FLUX_AUTH_CONSTANTS.CREATE_USER_BEGIN:
            return {
                ...state,
                errorMessage: '',
                loading: true,
                loginError: false,
                errorStatus: 0,
                curpMessage: ''
            };

        case FLUX_AUTH_CONSTANTS.CREATE_USER_ERROR:
            errorMessage = 'ERROR';
            return {
                ...state,
                errorMessage: action.error.data.errors?.email,
                errorStatus: action.error.status,
                curpMessage: action.error.data.errors?.CURP,
                loginError: true,
                loading: false
            };

        case FLUX_AUTH_CONSTANTS.CREATE_USER_SUCCESS:
            return {
                ...state,
                errorMessage: '',
                loginError: false,
                loading: false,
                user: action.data ? action.data.user : {},
                errorStatus: 0,
                successMessage: '',
                success: true
            };

        //------------------ LOGOUT -----------------------------
        case FLUX_AUTH_CONSTANTS.LOGOUT_SUCCESS:
            return {
                ...initialState
            };

        case FLUX_AUTH_CONSTANTS.CLEAR_AUTH_LOADING:
            return {
                ...state,
                loading: false,
                loadingUserData: false,
                errorMessage: '',
                errorStatus: 0,
                user: {} as UserModel,
                success: false,
                successMessage: '',
                curpMessage: ''
            };

        //------------------ USERS -----------------------------
        case FLUX_AUTH_CONSTANTS.GET_USERS_BEGIN:
            return {
                ...state,
                errorMessage: '',
                loading: true,
                loginError: false,
                errorStatus: 0
            };

        case FLUX_AUTH_CONSTANTS.GET_USERS_ERROR:
            errorMessage = 'ERROR';
            return {
                ...state,
                errorMessage: errorMessage,
                errorStatus: action.error ? action.error.status : 0,
                loginError: true,
                loading: false
            };

        case FLUX_AUTH_CONSTANTS.GET_USERS_SUCCESS:
            return {
                ...state,
                errorMessage: '',
                loginError: false,
                successMessage: 'Carga exitosa',
                loading: false,
                users: action.data ? action.data : [],
                errorStatus: 0
            };

        //------------------ GET USER BY ID -----------------------------
        case FLUX_AUTH_CONSTANTS.GET_USER_BEGIN:
            return {
                ...state,
                errorMessage: '',
                loading: true,
                successMessage: '',
                error: false,
                errorStatus: 0
            };

        case FLUX_AUTH_CONSTANTS.GET_USER_ERROR:
            return {
                ...state,
                errorMessage: action.error?.data.message,
                successMessage: '',
                errorStatus: action.error ? action.error.status : 400,
                error: true,
                loading: false
            };

        case FLUX_AUTH_CONSTANTS.GET_USER_SUCCESS:
            return {
                ...state,
                errorMessage: '',
                error: false,
                successMessage: action.data.message,
                loading: false,
                user: action.data,
                errorStatus: 0
            };

        //----------UPDATE USER INFORMATION-----------//
        case FLUX_AUTH_CONSTANTS.UPDATE_USER_BEGIN:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                errorStatus: 0,
                errorMessage: '',
                successMessage: ''
            };

        case FLUX_AUTH_CONSTANTS.UPDATE_USER_ERROR:
            return {
                ...state,
                success: false,
                error: true,
                errorStatus: action.error.data.status,
                errorMessage: action.error.data.message,
                successMessage: '',
                loading: false
            };

        case FLUX_AUTH_CONSTANTS.UPDATE_USER_SUCCESS:
            return {
                ...state,
                success: true,
                user: action.data,
                errorMessage: '',
                errorStatus: 0,
                error: false,
                loading: false
            };

        //---------- RENEW PASSWORD CLIENT-----------//
        case FLUX_AUTH_CONSTANTS.RENEW_PASSWORD_BEGIN:
            return {
                ...state,
                errorMessage: '',
                loading: true,
                loginError: false,
                errorStatus: 0
            };

        case FLUX_AUTH_CONSTANTS.RENEW_PASSWORD_ERROR:
            return {
                ...state,
                errorMessage: action.error.data.message,
                errorStatus: action.error.data.status,
                loginError: true,
                loading: false
            };

        case FLUX_AUTH_CONSTANTS.RENEW_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                successMessage: 'Contraseña cambiada correctamente'
            };

        default:
            return state;
    }
};
