import { Dispatch } from "redux";

import { API } from "../api/unic-api";
import APPROVAL_CRITERIA_CONSTANTS from "../constants/approvalCriteria-constants"
import * as types from '../types/approvalCriteria-types';

//---------------------------------- GET MORAL PERSON INFORMATION --------------------------------------------------
const postApprovalCriteriaBegin = (): types.IPostApprovalCriteriaBegin => {
    return {
        type: APPROVAL_CRITERIA_CONSTANTS.POST_APPROVAL_CRITERIA_BEGIN
    };
}

const postApprovalCriteriaError = (e: any): types.IPostApprovalCriteriaError => {
    return {
        type: APPROVAL_CRITERIA_CONSTANTS.POST_APPROVAL_CRITERIA_ERROR,
        error: e
    };
}

 export const postApprovalCriteriaSuccess = (data: any): types.IPostApprovalCriteriaSuccess => {
    return {
        type: APPROVAL_CRITERIA_CONSTANTS.POST_APPROVAL_CRITERIA_SUCCESS,
        data
    };
}

export const postApprovalCriteria = (payload: any) => {
    return (dispatch: Dispatch) => {
        dispatch(postApprovalCriteriaBegin());
        API.postApprovalCriteria(payload)
            .then((response: any) => {
                dispatch(postApprovalCriteriaSuccess(response.data));
            }).catch((error: any) => {
                if(error.status === 401){
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = "/login";
                }
                dispatch(postApprovalCriteriaError(error.response));
            });
    }
}

//---------------------------------- GET MORAL PERSON INFORMATION --------------------------------------------------
const getApprovalCriteriaBegin = (): types.IGetApprovalCriteriaBegin => {
    return {
        type: APPROVAL_CRITERIA_CONSTANTS.GET_APPROVAL_CRITERIA_BEGIN
    };
}

const getApprovalCriteriaError = (e: any): types.IGetApprovalCriteriaError => {
    return {
        type: APPROVAL_CRITERIA_CONSTANTS.GET_APPROVAL_CRITERIA_ERROR,
        error: e
    };
}

const getApprovalCriteriaSuccess = (data: any): types.IGetApprovalCriteriaSuccess => {
    return {
        type: APPROVAL_CRITERIA_CONSTANTS.GET_APPROVAL_CRITERIA_SUCCESS,
        data
    };
}

export const getApprovalCriteria = (flowRef: string) => {
    return (dispatch: Dispatch) => {
        dispatch(getApprovalCriteriaBegin());
        API.getApprovalCriteria(flowRef)
            .then((response: any) => {
                dispatch(getApprovalCriteriaSuccess(response.data));
            }).catch((error: any) => {
                if(error.status === 401){
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('persist:authReducer');
                    localStorage.removeItem('persist:root');
                    localStorage.removeItem('nubarium:redirected:FaceCapture');
                    localStorage.removeItem('nubarium:redirected:RecordMessage');
                    window.location.href = "/login";
                }
                dispatch(getApprovalCriteriaError(error.response));
            });
    }
}

const resetStatus = () => {
    return {
        type: APPROVAL_CRITERIA_CONSTANTS.RESET_APPROVAL_CRITERIA_STATUS
    }
}

export const resetApprovalCriteriaStatus = () => {
    return (dispatch: Dispatch) => {
        dispatch(resetStatus());
    }
}