import './StyleLoader.scss';

const Loader = () => {
    return (
        <>
            <div className="wrap">
                <div className="loader"> </div>
            </div>
        </>
    );
};

export default Loader;
