import React, { FC, useRef, useEffect } from 'react';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';

interface Props {
    personalList: Object[];
    bussinessList: Object[];
    affiliateList: Object[];
    documentsList: Object[];
    legalRepresentative: Object[];
    onAddStep: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | any;
    addStepOTP: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | any;
}

const RequestedInfo: FC<Props> = (p) => {
    const _renderInfoElement = (personalInfo: any, index: Number) => {
        if (personalInfo.importance >= 4) {
            return (
                <OverlayTrigger
                    placement="right"
                    overlay={
                        <Tooltip id={`tooltip-right`}>
                            {personalInfo.tooltip} | <b>Este campo tiene una importancia mayor o igual a 4</b>
                        </Tooltip>
                    }
                >
                    {personalInfo.type !== 'bussinessPhoto1' &&
                    personalInfo.type !== 'bussinessPhoto2' &&
                    personalInfo.type !== 'bussinessPhoto3' ? (
                        <li className="list-group-item border-top-0" onClick={() => p.onAddStep}>
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <p className="h5 mt-2 font-weight-bold">{personalInfo.name}</p>
                                <button
                                    type="button"
                                    name={personalInfo.model}
                                    id={index.toString()}
                                    className="btn btn-icon btn-third feather icon-plus"
                                    onClick={p.onAddStep}
                                />
                            </div>
                        </li>
                    ) : (
                        <></>
                    )}
                </OverlayTrigger>
            );
        } else {
            return (
                <OverlayTrigger placement="right" overlay={<Tooltip id={`tooltip-right`}>{personalInfo.tooltip}</Tooltip>}>
                    {personalInfo.type !== 'bussinessPhoto1' &&
                    personalInfo.type !== 'bussinessPhoto2' &&
                    personalInfo.type !== 'bussinessPhoto3' ? (
                        <li className="list-group-item border-top-0" onClick={() => p.onAddStep}>
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <p className="h5 mt-2 font-weight-bold">{personalInfo.name}</p>
                                <button
                                    type="button"
                                    name={personalInfo.model}
                                    id={index.toString()}
                                    className="btn btn-icon btn-third feather icon-plus"
                                    onClick={p.onAddStep}
                                />
                            </div>
                        </li>
                    ) : (
                        <></>
                    )}
                </OverlayTrigger>
            );
        }
    };

    const personalInfoList = useRef<HTMLUListElement>(null);
    const personalInfoListButton = useRef<HTMLButtonElement>(null);
    const bussinessInfoList = useRef<HTMLUListElement>(null);
    const bussinessInfoListButton = useRef<HTMLButtonElement>(null);
    const guaranteeInfoList = useRef<HTMLUListElement>(null);
    const guaranteeInfoListButton = useRef<HTMLButtonElement>(null);
    const legalRepresentativeListButton = useRef<HTMLButtonElement>(null);
    const legalRepresentativeList = useRef<HTMLUListElement>(null);
    const documentsInfoList = useRef<HTMLUListElement>(null);
    const documentsInfoListButton = useRef<HTMLButtonElement>(null);
    const otpList = useRef<HTMLUListElement>(null);
    const otpListButton = useRef<HTMLButtonElement>(null);
    const locationList = useRef<HTMLUListElement>(null);
    const locationButton = useRef<HTMLButtonElement>(null);

    const showList = (list: HTMLUListElement, button: HTMLButtonElement) => {
        if (list.style.display === 'block') {
            list.style.display = 'none';
            button.style.color = '#272733';
            button.children[0].classList.value = 'feather icon-chevron-down';
        } else {
            list.style.display = 'block';
            button.style.color = '#1274E7';
            button.children[0].classList.value = 'feather icon-chevron-up';
        }
    };

    const infoRequests = useRef<HTMLDivElement>(null);

    const changeClassScroll = (section: HTMLDivElement) => {
        if (window.scrollY > 81 && window.innerWidth > 992) {
            section.className = 'task-board-left request-info request-info-fixed card';
        } else {
            section.className = 'task-board-left request-info card';
        }
    };

    useEffect(() => {
        const onScroll: EventListener = (event: Event) => {
            if (null !== infoRequests.current) {
                changeClassScroll(infoRequests.current);
            }
        };

        const win: Window = window;
        win.addEventListener('scroll', onScroll);

        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return (
        <>
            <Card className="task-board-left request-info" ref={infoRequests}>
                <Card.Header>
                    <h4 className="request-info-title">Información solicitable</h4>
                </Card.Header>
                <Card.Body className="card-body">
                    <button
                        className="request-info-subtitle"
                        ref={personalInfoListButton}
                        onClick={() => {
                            if (null !== personalInfoList.current && null !== personalInfoListButton.current) {
                                showList(personalInfoList.current, personalInfoListButton.current);
                            }
                        }}
                    >
                        Información Personal
                        <i className="feather icon-chevron-down" />
                    </button>
                    <ul className="list-group list-group-flush" ref={personalInfoList}>
                        {p.personalList.map((personalInfo: any, index: number) => _renderInfoElement(personalInfo, index))}
                    </ul>
                    <button
                        className="mt-4 request-info-subtitle"
                        ref={bussinessInfoListButton}
                        onClick={() => {
                            if (null !== bussinessInfoList.current && null !== bussinessInfoListButton.current) {
                                showList(bussinessInfoList.current, bussinessInfoListButton.current);
                            }
                        }}
                    >
                        Información del Negocio
                        <i className="feather icon-chevron-down" />
                    </button>
                    <ul className="list-group list-group-flush" ref={bussinessInfoList}>
                        {p.bussinessList.map((bussinessInfo: any, index: number) => _renderInfoElement(bussinessInfo, index))}
                    </ul>
                    <button
                        className="mt-4 request-info-subtitle"
                        ref={guaranteeInfoListButton}
                        onClick={() => {
                            if (null !== guaranteeInfoList.current && null !== guaranteeInfoListButton.current) {
                                showList(guaranteeInfoList.current, guaranteeInfoListButton.current);
                            }
                        }}
                    >
                        Información del Aval
                        <i className="feather icon-chevron-down" />
                    </button>
                    <ul className="list-group list-group-flush" ref={guaranteeInfoList}>
                        {p.affiliateList.map((guaranteeInfo: any, index: number) => _renderInfoElement(guaranteeInfo, index))}
                    </ul>
                    <button
                        className="mt-4 request-info-subtitle"
                        ref={legalRepresentativeListButton}
                        onClick={() => {
                            if (null !== legalRepresentativeList.current && null !== legalRepresentativeListButton.current) {
                                showList(legalRepresentativeList.current, legalRepresentativeListButton.current);
                            }
                        }}
                    >
                        Información del Apoderado
                        <i className="feather icon-chevron-down" />
                    </button>
                    <ul className="list-group list-group-flush" ref={legalRepresentativeList}>
                        {p.legalRepresentative?.map((legalRepresentativeInfo: any, index: number) =>
                            _renderInfoElement(legalRepresentativeInfo, index)
                        )}
                    </ul>
                    <button
                        className="mt-4 request-info-subtitle"
                        ref={documentsInfoListButton}
                        onClick={() => {
                            if (null !== documentsInfoList.current && null !== documentsInfoListButton.current) {
                                showList(documentsInfoList.current, documentsInfoListButton.current);
                            }
                        }}
                    >
                        Documentos a solicitar
                        <i className="feather icon-chevron-down" />
                    </button>
                    <ul className="list-group list-group-flush" ref={documentsInfoList}>
                        {p.documentsList.map((documentsList: any, index: number) => _renderInfoElement(documentsList, index))}
                    </ul>
                    <button
                        className="mt-4 request-info-subtitle"
                        ref={otpListButton}
                        onClick={() => {
                            if (null !== otpList.current && null !== otpListButton.current) {
                                showList(otpList.current, otpListButton.current);
                            }
                        }}
                    >
                        Autorización OTP
                        <i className="feather icon-chevron-down" />
                    </button>
                    <ul className="list-group list-group-flush" ref={otpList}>
                        <OverlayTrigger placement="right" overlay={<Tooltip id={`tooltip-right`}>Autorización OTP</Tooltip>}>
                            <li className="list-group-item border-top-0" onClick={() => p.addStepOTP}>
                                <div className="d-flex flex-row justify-content-between align-items-center">
                                    <p className="h5 mt-2 font-weight-bold">Autorización OTP</p>
                                    <button
                                        type="button"
                                        name={'OTP'}
                                        id={'0'}
                                        className="btn btn-icon btn-third feather icon-plus"
                                        onClick={p.addStepOTP}
                                    />
                                </div>
                            </li>
                        </OverlayTrigger>
                    </ul>
                </Card.Body>
            </Card>
        </>
    );
};
export default RequestedInfo;
